import React from "react";
import BasicText from "../../atom/BasicText/BasicText";
import RiskIndicator from "../../molecule/Product/RiskIndicator";
import { Skeleton } from "@material-ui/lab";

const ProductTitleAndRisk = (props) => {
  const { product } = props;
  const { display_name, product_risk, display_code } = product;
  return Object.keys(product).length === 0 ? (
    <div className="mb-3">
      <Skeleton height="36px" width="80%" />
      <Skeleton height="10px" width="100%" className="mb-2" />
      <Skeleton height="18px" width="30%" />
    </div>
  ) : (
    <div className="mb-3">
      <BasicText className="fs-14 color-secondary font-weight-bold">
        {display_name} ({display_code})
      </BasicText>
      <hr className="divider-dotted mt-2 mb-3" />
      <div className="d-flex">
        <BasicText className="fs-10 gray-4 mr-3">Risiko Produk</BasicText>
        <RiskIndicator value={product_risk} height={12}/>
      </div>
    </div>
  );
};

export default ProductTitleAndRisk;
