import React from "react";
import { withRouter, useParams } from "react-router-dom";
// import textHelper from "../../helper/textHelper";
import TempMain from "../template/TempMain";
import pages from "../organism/ChangePage";
import textHelper from "../../helper/textHelper";

const ChangePage = (props) => {
  const [state, setState] = React.useState({
    page: "email",
    pinMode: "change",
  });
  const { page, pinMode } = state;
  const { id } = useParams();
  const { search } = props.location;
  const handleClickCancel = () => {
    props.history.replace("/profil/setting");
  };
  React.useEffect(() => {
    const arr = textHelper.parseParams(search);
    setState((state) => ({ ...state, page: id }));
    if (id === "pin" && arr !== "") {
      if (arr[0].hasOwnProperty("forgot")) {
        if (arr[0].forgot) {
          setState((state) => ({ ...state, pinMode: "forgot" }));
        }
      }
    }
  }, [id, search]);

  const img = {
    email: "emailChange",
    phone: "phoneChange",
    password: "passChange",
    pin: "passChange",
    bank: "bankChange",
    'add-bank': "bankChange",
    'update-bank': "bankChange",
    "periodic-invest": "periodicInvest",
  };
  const Pages = pages[page];
  return (
    <TempMain
      title="SETTING"
      data={{ img: img[page], menuValue: 1 }}
      showBottom={false}
      page="ProfileEdit"
      backgroundColor="#fff"
      desktopBackFunc={props.history.goBack}
      bottomNavValue={4}
    >
      <Pages onClickCancel={handleClickCancel} pinMode={pinMode} />
    </TempMain>
  );
};

export default withRouter(ChangePage);
