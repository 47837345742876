import more from "./more.svg";
import less from "./less.svg";
import dashboard from "./dashboard.svg";
import proto from "./porto.svg";
import product from "./product.svg";
import profile from "./profile.svg";
import txn from "./txn.svg";
import dana_likuid from "./dana_likuid.svg";
import goal_planner from "./goal_planner.svg";
import pilihan_kamu from "./pilihan_kamu.svg";
import point_kamu from "./point_kamu.svg";
import smart_plan from "./smart_plan.svg";
import profile_risk from "./profile_risk.svg";
import voucher from "./voucher.svg";
import ultraVoucher from "./ultra-voucher.jpeg";
export default {
  more,
  less,
  dashboard,
  proto,
  product,
  profile,
  txn,
  dana_likuid,
  goal_planner,
  pilihan_kamu,
  point_kamu,
  smart_plan,
  profile_risk,
  voucher,
  ultraVoucher,
};
