import pageToURL from "./pageToUrlHelper";

const processHelper = {
  findProcess: (label, processStack) => {
    if (Array.isArray(label)) {
      for (let i = 0; i < label.length; i++) {
        // console.log(
        //   "array " + i,
        //   processStack.includes(label[i]),
        //   processStack
        // );
        if (processStack.includes(label[i])) return true;
      }
      return false;
    } else return processStack?.includes?.(label) ?? false;
  },
  findResult: (label, resultStack) => {
    let data = {};
    for (let i = 0; i < resultStack.length; i++) {
      if (Array.isArray(label)) {
        for (let j = 0; j < label.length; j++) {
          if (resultStack[i].name === label[j]) {
            data = { ...resultStack[i].data, name: label[j] };
            break;
          }
        }
      } else {
        if (resultStack[i].name === label) {
          data = { ...resultStack[i].data, name: label };
          break;
        }
      }
    }
    return data;
  },
  findResultData: (label, resultDataStack) => {
    let data = {};
    Object.keys(resultDataStack).forEach((e) => {
      if (e === label) {
        data = { ...resultDataStack[e] };
      }
    });
    return data;
  },
  getId: (data) => {
    return data?.id ?? "";
  },
  convertTglFormat: (tanggal) => {
    let _tanggal = "";
    if (tanggal) {
      let tgl = tanggal.split("-");
      const last = tgl.length - 1;
      tgl.forEach((el, index) => {
        const _el = `${index !== last ? "-" : ""}${el}`;
        _tanggal = _el + _tanggal;
      });
    }
    return _tanggal;
  },
};

export const getNewAlert = (alert_user, key) => {
  // const alert_user = store.getState().profileReducer.alert;
  let type = key.toUpperCase();
  // console.log(alert_user);
  if (Array.isArray(alert_user) && alert_user.length > 0) {
    if (type) {
      const state = alert_user.filter((item) => item && item.type === type);
      return {
        length: state.length,
        ...state[state.length - 1],
      };
    } else {
      return {
        length: alert_user.length,
        ...alert_user[alert_user.length - 1],
      };
    }
  } else {
    return { length: 0 };
    // return {length: 0, message: 'alert_user', page: null};
  }
};

export const handleClickProduct = ({ screen, product_code }) => {
  const isProductPage = screen === "product_detail";
  return `${pageToURL[screen]}${isProductPage ? product_code : ""}`;
};

export function setObjFromPath(obj, is, value) {
  // console.log(obj, is, value);
  if (typeof is === "string") return setObjFromPath(obj, is.split("."), value);
  else if (is.length === 1 && value !== undefined) return (obj[is[0]] = value);
  else if (is.length === 0) return obj;
  else return setObjFromPath(obj[is[0]], is.slice(1), value);
}

export function handleCallBack(callback, condition = true, params = {}) {
  const isFunc = typeof callback === "function";
  const isObj = typeof callback === "object";
  if (condition && isFunc) {
    callback(params);
    return;
  }
  if (isObj) {
    switch (condition) {
      case true:
        if (typeof callback.success === "function") callback.success(params);
        return;
      case false:
        if (typeof callback.fail === "function") callback.fail(params);
        return;
      default:
        return;
    }
  }
}

export function temp_return_list({ data, meta, fullData, diff }, namePath) {
  const result = {
    data: fullData,
    meta,
  };
  if (diff) result.data = data;
  return { [namePath]: result };
}

export default processHelper;
