import { useCallback, useMemo, useState } from "react"
import useModal from "./useModal"

const useValidationPin = (callback = () => { }) => {
    const [params, setParams] = useState(null)
    const { modal, onClose } = useModal()

    const handleOpen = useCallback((params) => {
        setParams(params)
        onClose(true)
    }, [onClose])

    const validateResult = useCallback((status) => {
        if (status) {
            callback(params)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])
    const result = useMemo(() => ({ modal, onClose, validateResult, handleOpen }), [handleOpen, modal, onClose, validateResult])
    return result
}

export default useValidationPin