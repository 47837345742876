import React from "react";
import { Skeleton } from "@material-ui/lab";
import PropTypes from "prop-types";
import ScrollMenu from "react-horizontal-scrolling-menu";

function GenerateLoaderHorizontal(props) {
  const { count, size, variant, style } = props;
  const { width, height } = size;
  const items = [];
  for (let i = 0; i < count; i++) {
    items.push(
      <Skeleton
        variant={variant}
        height={height}
        key={i}
        width={width}
        className="mr-2"
      />
    );
  }
  return <ScrollMenu data={items} menuStyle={style} alignCenter={false} />;
}

GenerateLoaderHorizontal.propTypes = {
  count: PropTypes.number,
  size: PropTypes.object,
  variant: PropTypes.string
};

GenerateLoaderHorizontal.defaultProps = {
  count: 1,
  size: { width: 50, height: 50 },
  variant: "rect"
};

export default GenerateLoaderHorizontal;
