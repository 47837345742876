import React from "react";
import PropTypes from "prop-types";
import BasicText from "../../atom/BasicText/BasicText";
import textHelper from "../../../helper/textHelper";
import { makeStyles } from "@material-ui/styles";
import color from "../../../assets/theme/color";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import classNames from "classnames";
import { Skeleton } from "@material-ui/lab";
const useStyles = makeStyles((theme) => ({
  columnLabel: {
    backgroundColor: color.gray_8,
    color: color.primary,
  },
  navAndNab: {
    display: "flex",
    width: "100%",
    marginBottom: "10px",
    [theme.breakpoints.down(769)]: {
      display: "none",
    },
  },
}));

const PerformTable = (props) => {
  const classes = useStyles();
  const { product } = props;
  const {
    perform_3_m,
    perform_6_m,
    perform_1_y,
    perform_ytd,
    nab,
    nav_per_unit,
    nav_updated_at,
    product_log,
  } = product;
  const isUp =
    (product_log?.[0]?.nav_per_unit ?? 0) -
    (product_log?.[1]?.nav_per_unit ?? 0);
  return (
    <div className="w-100">
      <div className={classes.navAndNab}>
        {Object.keys(product).length === 0 ? (
          <div className="ml-2 mr-5">
            <Skeleton width="150px" height="18px" />
            <Skeleton width="60px" height="24px" />
          </div>
        ) : (
          <div className="ml-2 mr-5">
            <BasicText className="fs-10 gray-4 mb-1">
              {textHelper.printDateWithSlash(nav_updated_at)} NAV/Unit
            </BasicText>
            <BasicText className="fs-22 mb-1">
              {textHelper.printMoneyWithoutRp(nav_per_unit)}{" "}
              {isUp >= 0 ? (
                <ArrowDropUpIcon className="fs-26 color-primary" />
              ) : (
                <ArrowDropDownIcon className="fs-26 color-primary" />
              )}
            </BasicText>
          </div>
        )}
        {Object.keys(product).length === 0 ? (
          <div>
            <Skeleton width="73px" height="18px" />
            <Skeleton width="60px" height="24px" />
          </div>
        ) : (
          <div>
            <BasicText className="fs-10 gray-4 mb-2">Total AUM</BasicText>
            <BasicText className="fs-14 font-weight-semibold mb-1">
              {textHelper.printMoneyAbbrivation(nab)}
            </BasicText>
          </div>
        )}
      </div>
      {Object.keys(product).length === 0 ? (
        <div className="row mx-0 w-100">
          <div className="col-3 px-1">
            <Skeleton width="100%" height="47px" />
          </div>
          <div className="col-3 px-1">
            <Skeleton width="100%" height="47px" />
          </div>
          <div className="col-3 px-1">
            <Skeleton width="100%" height="47px" />
          </div>
          <div className="col-3 px-1">
            <Skeleton width="100%" height="47px" />
          </div>
        </div>
      ) : (
        <div className="row mx-0 w-100">
          <div className="col-3 px-1">
            <BasicText
              color="primary"
              className={classNames(
                classes.columnLabel,
                "fs-10 py-1 text-center mb-2"
              )}
            >
              3 Bln
            </BasicText>
            <BasicText className="fs-14 text-center gray-3 font-weight-medium">
              {textHelper.printPercent(perform_3_m)}
            </BasicText>
          </div>
          <div className="col-3 px-1">
            <BasicText
              color="primary"
              className={classNames(
                classes.columnLabel,
                "fs-10 py-1 text-center mb-2"
              )}
            >
              6 Bln
            </BasicText>
            <BasicText className="fs-14 text-center gray-3 font-weight-medium">
              {textHelper.printPercent(perform_6_m)}
            </BasicText>
          </div>
          <div className="col-3 px-1">
            <BasicText
              color="primary"
              className={classNames(
                classes.columnLabel,
                "fs-10 py-1 text-center mb-2"
              )}
            >
              1 Thn
            </BasicText>
            <BasicText className="fs-14 text-center gray-3 font-weight-medium">
              {textHelper.printPercent(perform_1_y)}
            </BasicText>
          </div>
          <div className="col-3 px-1">
            <BasicText
              color="primary"
              className={classNames(
                classes.columnLabel,
                "fs-10 py-1 text-center mb-2"
              )}
            >
              YTD
            </BasicText>
            <BasicText className="fs-14 text-center gray-3 font-weight-medium">
              {textHelper.printPercent(perform_ytd)}
            </BasicText>
          </div>
        </div>
      )}
    </div>
  );
};

PerformTable.propTypes = {
  product: PropTypes.object,
};

export default PerformTable;
