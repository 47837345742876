import { Button, Checkbox, FormControlLabel } from "@material-ui/core";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { string } from "yup";
import {
  fetch_regis_3,
  fetch_regis_4,
  fetch_re_regis,
  clear_register,
} from "../../../config/redux/actions/auth";
import { set_result } from "../../../config/redux/actions/state";
import processHelper from "../../../helper/processHelper";
import validation, { _isValid } from "../../../helper/validateHelper";
import BasicText from "../../atom/BasicText/BasicText";
import ButtonContained from "../../atom/Button/ButtonContained";
import DialogAlert from "../../molecule/Alert/DialogAlert";
import ContentType from "../../organism/Content/ContentType";
import DialogPin from "../../organism/PinInput/DialogPin";
import FormRegister from "../../organism/Register/FormRegister";
import SignatureComponent from "../../organism/Register/SignatureComponent";
import TempAuth from "../../template/TempAuth";
import { checkStepRegister } from "../../organism/Register/registerHelper";
import RouteLeavingConfirm from "../../../config/Router/RouteLeavingConfirm";

const pinValidation = validation.validatePin();

const dialogs = [
  { resultName: "no-sig-regis", alertAppear: "error" },
  { resultName: "register3", alertAppear: "error" },
  { resultName: "pin", alertAppear: "error" },
  { resultName: "pin_confirm", alertAppear: "error" },
];


const Title = () => {
  return (
    <div>
      <BasicText className="fs-14 font-weight-bolder color-primary mb-2">
        Upload tanda tangan sesuai KTP di kolom ini
      </BasicText>
      <BasicText className="fs-14 gray-4">
        PASTIKAN FOTO TANDA TANGAN BERADA DI DALAM BATAS AREA YANG DITENTUKAN
      </BasicText>
    </div>
  );
};

const ContentSubmit = ({
  checked,
  loading = false,
  onChange,
  onSubmit,
  onSyarat,
}) => {
  function _change(e) {
    onChange(e.target.checked);
  }
  const _disabled = loading || !checked;
  return (
    <div className="d-flex flex-column">
      <FormControlLabel
        control={
          <Checkbox
            id="s&k"
            checked={checked}
            size="small"
            className="mt-n2"
            onChange={_change}
          />
        }
        label={
          <BasicText className="fs-12 gray-5">
            Saya telah membaca dan menyetujui{" "}
            <Button
              onClick={onSyarat}
              className="gray-3 text-underline fs-12 color-secondary text-lowercase font-weight-light py-0 px-1"
              size="small"
            >
              syarat dan ketentuan
            </Button>
            pembukaan rekening transaksi online reksa dana di PT Syailendra
            Capital.
          </BasicText>
        }
        className="mb-4 pb-1 align-items-start"
        classes={{ label: "gray-5 font-family" }}
        labelPlacement="end"
      />
      <ButtonContained
        id="submit-register-5"
        text="LANJUT"
        disabled={_disabled}
        loading={loading}
        radius="6px"
        onClick={onSubmit}
        className="py-2 px-5 fs-14 align-self-center mt-3"
      />
    </div>
  );
};

class RegisSignature extends PureComponent {
  constructor (q) {
    super(q)
    this.state = {
      checked: false,
      modalPin: false,
      modalPinConfirm: false,
      pin: "",
      pin_confirm: "",
      syarat: false,
    };
    this.handleSyarat = window.addEventListener('keydown',this._handleSyaratFromKey,false)
  }
  

  componentDidMount = () => {
    const { register, location, history } = this.props;
    checkStepRegister(
      location.pathname,
      history.replace,
      register.registerStatus
    );
    window.scrollTo(0, 0);
  };

  componentDidUpdate = (prevProps) => {
    const { result_stack } = this.props;
    // const { registration_token } = register;
    if (result_stack.length !== prevProps.result_stack.length) {
      // const resName =
      //   registration_token === "re-register" ? "fetch-re-regis" : "register4";
      const _res = processHelper.findResult("register3", result_stack);
      // const _regis4 = processHelper.findResult(resName, result_stack);
      if (_res.name && _res.type === "success") this._handleClosePIN("pin");
      // else if (_regis4.name && _regis4.type === "success")
      //   this._successRegisPIN();
    }
  };

  componentWillUnmount() {
    window.removeEventListener('keydown',this._handleSyaratFromKey,false)
  }
  

  _handleCheck = (checked) => this.setState({ checked });

  _successRegisPIN = () => {
    this.props.clear_register();
  };

  _handleSubmit = () => {
    const { fetch_regis_3, set_result, register, fetch_re_regis } = this.props;
    const { _getImage } = this.refs.signature;
    const img = _getImage();
    if (img) {
      if (register.registration_token === "re-register") fetch_re_regis(img);
      else fetch_regis_3(img);
    } else
      set_result({
        name: "no-sig-regis",
        data: { type: "error", msg: "Anda Belum melakukan tanda tangan" },
      });
  };

  _handleClosePIN = (condition) => {
    console.log(condition);
    let key = "modalPinConfirm";
    if (condition === "pin") key = "modalPin";
    this.setState((state) => ({
      [key]: !state[key],
      pin: "",
      pin_confirm: "",
    }));
  };

  _handleSyarat = () => {
    this.setState((state) => ({ syarat: !state.syarat }));
  };

  _handleSyaratFromKey = (event) => {
    if(this.state.syarat && event.key === 'Escape') this._handleSyarat()
  }


  _setPin = (value) => {
    const { pin } = this.state;
    let thisCon = "pin";
    if (pin) {
      thisCon = "pin_confirm";
    }
    this._validatePin(value.pin, thisCon);
  };

  _validatePin = (value, condition) => {
    const { set_result, fetch_regis_4 } = this.props;
    const { pin } = this.state;
    const isPin = condition === "pin";
    const validate = isPin
      ? pinValidation
      : string()
        .required()
        .test(
          "password-match",
          "PIN yang anda masukkan tidak sesuai",
          function (value) {
            return pin === value;
          }
        );
    _isValid(validate, value)
      .then((e) => {
        if (isPin)
          this.setState({
            modalPin: false,
            pin: value,
            modalPinConfirm: true,
          });
        else if (condition === "pin_confirm") {
          fetch_regis_4({ pin, pin_confirm: value });
        }
      })
      .catch((err) => {
        set_result({ name: condition, data: { type: "error", msg: err } });
      });
  };

  render() {
    const { checked, modalPin, modalPinConfirm, syarat } = this.state;
    const { loading_stack } = this.props;
    const { findProcess } = processHelper;
    const loading = findProcess(["register3", "fetch-re-regis"], loading_stack);
    const dialogPin = [
      { modal: modalPin, title: "Buat PIN Kamu", key: "pin" },
      {
        modal: modalPinConfirm,
        title: "Konfirmasi PIN Kamu",
        key: "pin_confirm",
      },
    ];
    // const next = findResult("register4", result_stack).type !== "success";
    return (
      <TempAuth page="regisForm" data={{ active: 2, showStepper: true }}>
        {/* <Prompt
          when={next}
          message="Yakin Keluar dari Registrasi?, apa bila anda keluar maka anda akan mengulang reisgtrasi dari awal"
        /> */}
        <RouteLeavingConfirm
          acceptLeave={() => this.props.history.go(-2)}
          showConfirmation={false}
        />
        <FormRegister
          ref="formik"
          withButtonSubmit={false}
          loadingSubmit={processHelper.findProcess(
            "fetch-re-regis",
            loading_stack
          )}
          data={[
            [{ Children: Title }],
            [
              { children: <SignatureComponent ref="signature" /> },
              {
                Children: ContentSubmit,
                ChildrenProps: {
                  checked,
                  loading,
                  onChange: this._handleCheck,
                  onSubmit: this._handleSubmit,
                  onSyarat: this._handleSyarat,
                },
                cClass: "align-self-center",
              },
            ],
          ]}
        />
        {dialogs.map((item, index) => (
          <DialogAlert key={index} {...item} />
        ))}
        <DialogAlert 
          resultName={["register4","fetch-re-regis"]} 
          alertAppear="both" 
          submitIfSuccess={this._successRegisPIN}
        />
        {dialogPin.map((item) => (
          <DialogPin
            onSubmit={this._setPin}
            processName={"register4"}
            {...item}
            onClose={() => this._handleClosePIN(item.key)}
            withValidation
            needForgot={false}
          />
        ))}
        <ContentType
          display={syarat}
          contentKey="syarat_ketentuan"
          onClose={this._handleSyarat}
          style={{ top: 0, left: 0, zIndex: 21, height: "100vh" }}
        />
      </TempAuth>
    );
  }
}

const mapStateToProps = ({ stateReducer, authReducer }) => ({
  loading_stack: stateReducer.loading_stack,
  result_stack: stateReducer.result_stack,
  register: authReducer.register,
});
const mapDispatchToProps = {
  set_result,
  fetch_regis_3,
  fetch_regis_4,
  fetch_re_regis,
  clear_register,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RegisSignature)
);
