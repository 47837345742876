import React from "react";
import { SvgIcon } from "@material-ui/core";

const ColorIndicator = props => {
  return (
    <SvgIcon {...props}>
      <rect
        id="Rectangle_1317"
        data-name="Rectangle 1317"
        width="24"
        height="24"
      />
    </SvgIcon>
  );
};

export default ColorIndicator;
