import React, { useRef, useLayoutEffect, memo } from "react";
import usePrevious from "./Hook/usePrevious";
import { makeStyles } from "@material-ui/core";
import color from "../../../assets/theme/color";
import classNames from "classnames";

const useStyles = makeStyles({
  input: {
    border: "1px solid " + color.gray_6,
    padding: "13px 8px",
    textAlign: "center",
    width: "100%",
    borderRadius: 4,
    "&:focus": {
      outline: "none",
    },
  },
});

const SingleOTPInput = ({ id,focus, autoFocus, className, ...other }) => {
  const S = useStyles();
  const inputRef = useRef();
  const prevFocus = usePrevious(!!focus);
  useLayoutEffect(() => {
    if (inputRef.current) {
      if (focus && autoFocus) inputRef.current.focus();
      if (focus && autoFocus && focus !== prevFocus) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }
  }, [autoFocus, focus, prevFocus]);
  return (
    <input
      id={id}
      ref={inputRef}
      {...other}
      className={classNames(S.input, className)}
    />
  );
};

export default memo(SingleOTPInput);
