import TempLoginDesktop from "./TempLoginDesktop";
import TempDashboardDesktop from "./TempDashboardDesktop";
import TempProfileEditDesktop from "./TempProfileEditDesktop";
import TempDetailProductDesktop from "./TempDetailProductDesktop";
import TempProfileRiskDesktop from "./TempProfileRiskDesktop";
import TempSmartPlanDesktop from "./TempSmartPlanDesktop";
import TempGoalKamuDesktop from "./TempGoalKamuDesktop";
import TempRegis1Desktop from "./TempRegis1Desktop";
import TempRegisForm from "./TempRegisFormDesktop";
import TempProfileDesktop from "./TempProfileDesktop";
import TempPlannerLainnyaDesktop from "./TempPlannerLainnyaDesktop";
import TempRiskPage from "./TempRiskPage";
export default {
  Login: TempLoginDesktop,
  Dashboard: TempDashboardDesktop,
  ProfileEdit: TempProfileEditDesktop,
  detailProduct: TempDetailProductDesktop,
  profileRisk: TempProfileRiskDesktop,
  smartPlan: TempSmartPlanDesktop,
  goalsKamu: TempGoalKamuDesktop,
  plannerLainnya: TempPlannerLainnyaDesktop,
  regisInputPhone: TempRegis1Desktop,
  regisForm: TempRegisForm,
  profile: TempProfileDesktop,
  risk: TempRiskPage,
};
