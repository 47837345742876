import circleBased from "./circleBased.svg";
import circleBasedh from "./circleBasedh.svg";
import circleActive from "./circleActive.svg";
import circleActiveh from "./circleActiveh.svg";
import lineBased from "./lineBased.svg";
import lineBasedh from "./lineBasedh.svg";
import lineActive from "./lineActive.svg";
import lineActiveh from "./lineActiveh.svg";
import riskStep1 from "./riskstep1.svg";
import riskStep2 from "./riskstep2.svg";
import riskStep3 from "./riskStep3.svg";
import riskStep4 from "./riskStep4.svg";
import riskStep5 from "./riskStep5.svg";
export default {
  circleBased,
  circleBasedh,
  lineBased,
  lineBasedh,
  circleActive,
  circleActiveh,
  lineActive,
  lineActiveh,
  riskStep1,
  riskStep2,
  riskStep3,
  riskStep4,
  riskStep5
};
