import { createSelector } from "reselect";

export function getLoading(key) {
  return createSelector(getAllLoading, (all) => all.includes(key));
}

export function getResultSuccess(key) {
  return createSelector(getAllResult, (all) =>
    all.find((item) => item.name === key && item.data.type === "success")
  );
}

export function getResult(key) {
  return createSelector(getAllResult, (all) =>
    all.find((item) => item.name === key)
  );
}

//#region profile
export const getProfile = createSelector(
  (state) => state.profileReducer,
  (store) => store.profile
);
//#endregion

const getAllLoading = (state) => state.stateReducer.loading_stack;

const getAllResult = (state) => state.stateReducer.result_stack;
