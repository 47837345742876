import React from "react";
import { Grid, Divider } from "@material-ui/core";
import ButtonIcon from "../../atom/Button/ButtonIcon";
import { connect } from "react-redux";
import processHelper from "../../../helper/processHelper";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import icon from "../../../assets/img/icon/mainMenu";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import pageToURL from "../../../helper/pageToUrlHelper";
import mainMenu from "../../../assets/img/icon/mainMenu";

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    transition: "all 0.5s",
  },
  hide: {
    minHeight: 95,
    maxHeight: 205,
    [theme.breakpoints.up(768)]: {
      maxHeight: 165,
      minHeight: 83,
    },
  },
  show: {
    minHeight: "auto",
  },
  divider: {
    display: "none",
    [theme.breakpoints.up(768)]: {
      display: "block",
    },
  },
  loader: {
    width: 75,
    height: 78,
    [theme.breakpoints.up(768)]: {
      width: 170,
      height: 50,
    },
  },
}));

const menu = [
  {
    id: 1,
    name: "GOALS PLANNER",
    icon: "https://dev.syailendra.id/uploads/apps/menu/62342858798eb55d25a0187644986d6739623320_1584191173.png",
    icon_web:
      "https://dev.syailendra.id/uploads/apps/menu/4a70f7099db5ee8256fb94c38ca2a2266ed1dd16_1591101758.png",
    screen: "goals_planner",
    is_active: 1,
    ordered: 1,
    created_at: null,
    src_icon: "goal_planner",
  },
  {
    id: 2,
    name: "SMART PLAN",
    icon: "https://dev.syailendra.id/uploads/apps/menu/880b41872b8fb6e9736bab476b33315c0e45467f_1584199548.png",
    icon_web:
      "https://dev.syailendra.id/uploads/apps/menu/07269c0bd5aedb79cbc7affdecdc258c6975dbf5_1591101841.png",
    screen: "smart_plan",
    is_active: 1,
    ordered: 1,
    created_at: null,
    src_icon: "smart_plan",
  },
  {
    id: 3,
    name: "DANA LIKUID",
    icon: "https://dev.syailendra.id/uploads/apps/menu/62342858798eb55d25a0187644986d6739623320_1584191173.png",
    icon_web:
      "https://dev.syailendra.id/uploads/apps/menu/4a70f7099db5ee8256fb94c38ca2a2266ed1dd16_1591101758.png",
    screen: "product_detail",
    params: "MSDK",
    is_active: 1,
    ordered: 1,
    created_at: null,
    src_icon: "dana_likuid",
  },
  {
    id: 4,
    name: "PILIHAN KAMU",
    icon: "https://dev.syailendra.id/uploads/apps/menu/62342858798eb55d25a0187644986d6739623320_1584191173.png",
    icon_web:
      "https://dev.syailendra.id/uploads/apps/menu/4a70f7099db5ee8256fb94c38ca2a2266ed1dd16_1591101758.png",
    screen: "product_list",
    is_active: 1,
    ordered: 1,
    created_at: null,
    src_icon: "pilihan_kamu",
  },
  {
    id: 5,
    name: "REKSA DANA TERPROTEKSI",
    icon: "https://uat.yoinves.id/v3/api/scor-user/file/admin/file/Protected_1630393005.png",
    icon_web:
      "https://uat.yoinves.id/v3/api/scor-user/file/admin/file/Protected_1630393005.png",
    screen: "product_list",
    state: { initialType: "TP" },
    is_active: 1,
    ordered: 1,
    created_at: null,
    src_icon: "protected",
  },
  {
    id: 6,
    name: "POIN KAMU",
    icon: "https://dev.syailendra.id/uploads/apps/menu/62342858798eb55d25a0187644986d6739623320_1584191173.png",
    icon_web:
      "https://dev.syailendra.id/uploads/apps/menu/4a70f7099db5ee8256fb94c38ca2a2266ed1dd16_1591101758.png",
    screen: "coming_soon",
    is_active: 1,
    ordered: 1,
    created_at: null,
    src_icon: "point_kamu",
  },
  {
    id: 7,
    name: "ANOTHER FUND",
    icon: "https://dev.syailendra.id/uploads/apps/menu/62342858798eb55d25a0187644986d6739623320_1584191173.png",
    icon_web:
      "https://dev.syailendra.id/uploads/apps/menu/4a70f7099db5ee8256fb94c38ca2a2266ed1dd16_1591101758.png",
    screen: "coming_soon",
    is_active: 1,
    ordered: 1,
    created_at: null,
    src_icon: "goal_planner",
  },
  // {
  //   id: 8,
  //   name: "SESUAI PROFIL",
  //   icon: "https://dev.syailendra.id/uploads/apps/menu/62342858798eb55d25a0187644986d6739623320_1584191173.png",
  //   icon_web:
  //     "https://dev.syailendra.id/uploads/apps/menu/4a70f7099db5ee8256fb94c38ca2a2266ed1dd16_1591101758.png",
  //   screen: "profile_risk_result",
  //   is_active: 1,
  //   ordered: 1,
  //   created_at: null,
  //   src_icon: "profile_risk",
  // },
  {
    id: 8,
    name: "VOUCHERS",
    // icon: "https://dev.syailendra.id/uploads/apps/menu/62342858798eb55d25a0187644986d6739623320_1584191173.png",
    // icon_web:
    //   "https://dev.syailendra.id/uploads/apps/menu/4a70f7099db5ee8256fb94c38ca2a2266ed1dd16_1591101758.png",
    screen: "ultra_voucher",
    is_active: 1,
    ordered: 1,
    created_at: null,
    src_icon: "ultraVoucher",
  },
];

const Loader = () => {
  const classes = useStyles();
  return (
    <Grid container>
      {Array(6)
        .fill("")
        .map((_, i) => (
          <Grid item xs={4} className={i < 3 ? "my-3" : "mb-3"} key={i}>
            <Skeleton variant="rect" className={("mx-auto", classes.loader)} />
          </Grid>
        ))}
    </Grid>
  );
};

const MainMenu = (props) => {
  const classes = useStyles();
  const { loading_stack } = props;
  const [state, setState] = React.useState({
    show: false,
    moreExist: false,
    menu_container: [],
  });
  const _handleClickMenu = React.useCallback(
    (screen, title, params, state) => {
      let url = pageToURL[screen];
      if (params) {
        url += params;
      }
      props.history.push(url, screen === "coming_soon" ? { title } : state);
    },
    [props.history]
  );

  const { show, moreExist, menu_container } = state;
  const handleShow = (e) => {
    setState((state) => ({ ...state, show: e }));
  };
  React.useEffect(() => {
    let menu_list = [];
    let menu_container = [];
    let text = {};
    let iconUrl = "";
    let onPress = undefined;
    for (let i = 0; i < menu.length; i++) {
      text =
        menu.length > 6
          ? i === 5
            ? !show
              ? "MORE"
              : menu[i].name
            : menu[i].name
          : menu[i].name;
      if (menu.length > 6 && i === 5 && !show) {
        iconUrl = icon.more;
        onPress = () => handleShow(true);
      } else {
        iconUrl =
          mainMenu[menu[i].src_icon] || menu[i].icon_web || menu[i].icon;
        onPress = _handleClickMenu;
      }

      menu_list.push(
        <Grid
          item
          xs={4}
          className={i < 3 ? "my-3" : "mb-3"}
          key={menu_list.length}
        >
          <ButtonIcon
            id={`menu-${text?.replace(/ /g, "-").toLowerCase()}`}
            text={text}
            icon={iconUrl}
            alt={text}
            screen={menu[i].screen}
            params={menu[i].params}
            state={menu[i].state}
            onClick={onPress}
          />
        </Grid>
      );

      if (
        menu_list.length === 3 ||
        (menu_list.length < 3 && i === menu.length - 1)
      ) {
        const tmp = [...menu_list];
        if (tmp.length < 3 && i === menu.length - 1) {
          tmp.push(
            <Grid item xs={4} className="mb-3" key={tmp.length + "bl"}>
              <ButtonIcon
                id="menu-less"
                text="LESS"
                icon={icon.less}
                onClick={() => handleShow(false)}
              />
            </Grid>
          );
        }
        menu_container.push(
          <Grid container key={menu_container.length}>
            {tmp}
          </Grid>
        );
        if (i !== menu.length - 1) {
          menu_container.push(
            <div
              className={classNames("mb-3", classes.divider)}
              key={menu_container.length}
            >
              <Divider />
            </div>
          );
        }
        menu_list.length = 0;
      }
    }
    setState((state) => ({ ...state, menu_container }));
  }, [_handleClickMenu, classes.divider, moreExist, show]);
  return (
    <div
      className={classNames(classes.root, show ? classes.show : classes.hide)}
    >
      {processHelper.findProcess("set_menu", loading_stack) ? (
        <Loader />
      ) : (
        menu_container
      )}
    </div>
  );
};

const mapState = (state) => ({
  menu: state.optionReducer.menu,
  loading_stack: state.stateReducer.loading_stack,
});

export default compose(connect(mapState), withRouter)(MainMenu);
