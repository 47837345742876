import React, { useCallback, useRef, useState } from "react";
import TempMain from "../template/TempMain";
import TextInput from "../molecule/MaterialInput/TextInput";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../config/redux/selector";
import BasicButton from "../atom/Button/BasicButton";
import { gbl_act_lc } from "../../config/redux/actions/state";
import stateCallApi from "../../config/API/stateCallApi";
import DialogAlert from "../molecule/Alert/DialogAlert";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

const DeleteAccountPage = ({ history }) => {
  const dispatch = useDispatch();
  const reason = useRef("");
  const profile = useSelector(getProfile);
  const [disabled, setDisabled] = useState(true);
  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(
        gbl_act_lc({
          ...stateCallApi.deleteAccount,
          params: { reason: reason.current },
        })
      );
    },
    [dispatch]
  );

  return (
    <TempMain
      title="HAPUS ACCOUNT"
      page="profile"
      backgroundColor="#fff"
      showBottom={false}
      bottomNavValue={4}
      data={{ menuValue: 13 }}
    >
      <form className="d-flex flex-column p-4 py-5" onSubmit={onSubmit}>
        <div style={{ gap: 21 }} className="d-flex flex-column mb-5">
          <TextInput
            label="EMAIL"
            value={profile.email}
            InputLabelProps={{
              className: "Mui-focused MuiInputLabel-shrink",
            }}
            disabled
          />
          <TextInput
            label="ALASAN HAPUS AKUN"
            multiple
            onChange={(e) => {
              setDisabled(!e);
              reason.current = e;
            }}
          />
        </div>
        <BasicButton
          text="KIRIM"
          className="align-self-center py-2 px-5"
          type="submit"
          disabled={disabled}
        />
      </form>
      <DialogAlert
        alertAppear="both"
        resultName="delete_account"
        submitIfSuccess={history.goback}
      />
    </TempMain>
  );
};

export default withRouter(DeleteAccountPage);
