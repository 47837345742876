import React, { useCallback } from "react";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import * as yup from "yup";
import BasicText from "../../atom/BasicText/BasicText";
import GenerateForm from "../../template/generate/GenerateForm";
import { connect } from "react-redux";
import { change_number_props } from "../../../config/redux/actions/profile";
import processHelper from "../../../helper/processHelper";
import DialogAlert from "../../molecule/Alert/DialogAlert";
import DialogOtpInput from "../../page/Otp/DialogOtpInput";
import { validate_otp_props } from "../../../config/redux/actions/auth";
import DialogPin from "../PinInput/DialogPin";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import DialogOTPSelection from "../../molecule/Dialog/DialogOTPSelection";
import useModal from "../../atom/hooks/useModal";

const schema = yup.object().shape({
  phone_number: yup.string().required("Nomor harus diisi"),
});

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.up(768)]: {
      justifyContent: "start",
    },
    "& .btnNext": {
      fontSize: 14,
      fontWeight: 600,
      padding: "12px",
      flex: 4,
      marginRight: 20,
      [theme.breakpoints.down(769)]: {
        marginRight: 0,
        width: "100%",
      },
    },
    "& .btnCancel": {
      fontSize: 14,
      flex: 3,
      fontWeight: 600,
      padding: "12px 0",
      [theme.breakpoints.down(769)]: {
        display: "none",
      },
    },
  },
  container: {
    minHeight: "50vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.up(768)]: {
      minHeight: "60vh",
    },
  },
  inputText: {
    marginBottom: 8,
    maxWidth: 345,
  },
}));

const ChangePhone = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const modalSendTo = useModal();
  const {
    change_number_props,
    validate_otp_props,
    result_stack,
    onClickCancel,
  } = props;
  const [state, setState] = React.useState({
    modalPin: false,
    modalOtp: false,
  });
  const [form, setForm] = React.useState({
    phone_number: "",
  });
  const { modalPin, modalOtp } = state;
  const handleModal = useCallback((key, value) => {
    setState((prev) => ({ ...prev, [key]: value }));
  }, []);

  const formData = [
    {
      target: "phone_number",
      placeholder: "08123456789",
      marginClass: classNames(classes.inputText, "hide-arrow-inside"),
      onlyNum: true,
      inputClass: "fs-16",
      variant: "outlined",
      label: "MASUKAN NO HP YANG BARU",
      labelClass: "fs-14 mb-3 color-secondary",
      startIcon: PhoneIphoneIcon,
    },
  ];
  const handleSubmit = useCallback((e = {}) => {
    setForm((prev) => ({ ...prev, ...e }));
    modalSendTo.onClose(true);
    setState(prev => ({ ...prev, modalOtp: false }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResend = useCallback((cb, isChange) => {
    const delayInSecond = isChange ? 2.5 : 1.5
    if (isChange) handleSubmit()
    else handleModal('modalPin', true)
    setTimeout(cb, 1000 * delayInSecond)
  }, [handleModal, handleSubmit])

  const handleSubmitSendTo = useCallback(
    (otp_dst) => {
      setForm((prev) => ({ ...prev, otp_dst }));
      handleModal("modalPin", true);
    },
    [handleModal]
  );

  React.useEffect(() => {
    let data = processHelper.findResult(
      ["change_number", "validate_otp"],
      result_stack
    );
    if (Object.keys(data).length > 0) {
      if (data.name === "change_number") {
        setState((state) => ({ ...state, modalPin: false }));
        if (data.type === "success") {
          setState((state) => ({ ...state, modalOtp: true }));
        }
      } else if (data.name === "validate_otp") {
        setState((state) => ({ ...state, modalOtp: false }));
      }
    }
  }, [result_stack]);
  function _handleSuccess() {
    history.goBack();
  }

  return (
    <div>
      <BasicText className="fs-14 mb-4 font-weight-bold" color="primary">
        MASUKAN NO HP KAMU YANG BARU
      </BasicText>
      <GenerateForm
        formData={formData}
        initial={form}
        schema={schema}
        containerClass={classes.container}
        buttonClass="btnNext"
        buttonText="UBAH NOMOR HP"
        buttonCancelText="BATAL"
        buttonCancelClass="btnCancel"
        onClickCancel={onClickCancel}
        buttonContainerClass={classes.buttonContainer}
        onSubmit={handleSubmit}
      />
      <DialogOTPSelection
        {...modalSendTo}
        number={form.phone_number}
        onClickItem={handleSubmitSendTo}
      />
      <DialogPin
        modal={modalPin}
        onClose={(e) => handleModal("modalPin", e)}
        onSubmit={change_number_props}
        dataSubmit={form}
        processName="change_number"
      />
      <DialogOtpInput
        modal={modalOtp}
        onClose={(e) => handleModal("modalOtp", e)}
        onSubmit={validate_otp_props}
        processName="validate_otp"
        otpDst={form?.otp_dst}
        phoneNumber={form.phone_number}
        onResend={handleResend}
        dataSubmit={{
          type: "change_number_verify",
        }}
      />
      <DialogAlert resultName="change_number" buttonText="OK" />
      <DialogAlert
        resultName="validate_otp"
        alertAppear="both"
        buttonText="OK"
        submitIfSuccess={_handleSuccess}
      />
    </div>
  );
};

const mapState = (state) => ({
  result_stack: state.stateReducer.result_stack,
});

export default connect(mapState, { change_number_props, validate_otp_props })(
  ChangePhone
);
