import { makeStyles } from "@material-ui/styles";
import React, { useCallback } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import color from "../../../assets/theme/color";
import {
  del_acc_bank_props,
  set_priority_bank_props,
  set_profile,
} from "../../../config/redux/actions/profile";
import BasicText from "../../atom/BasicText/BasicText";
import ButtonContained from "../../atom/Button/ButtonContained";
import useModal from "../../atom/hooks/useModal";
import DialogAlert from "../../molecule/Alert/DialogAlert";
import AccountBankItem from "../../molecule/Bank/AccountBankItem";
import ContainerWithLoader from "../Container/ContainerWithLoader";
import DialogCustom from "../Container/DialogCustom";
const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    textAlign: "center",
    width: "100%",
    marginTop: 55,
    justifyContent: "center",
    [theme.breakpoints.up(768)]: {
      justifyContent: "start",
    },
    "& .btnCancel": {
      [theme.breakpoints.down(769)]: {
        display: "none",
      },
    },
  },
  container: {
    minHeight: "55vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.up(768)]: {
      minHeight: "60vh",
    },
  },
  deleteModal: {
    width: '100%',
    maxWidth: 375,
    height: 190
  },
}));

const ChangeBank = (props) => {
  const classes = useStyles();
  const { modal, onClose: handleModal } = useModal()
  const [state, setState] = React.useState({
    once: false,
    acc_bank: "",
  });
  const {
    bank_account,
    set_profile,
    set_priority_bank_props,
    del_acc_bank_props,
  } = props;
  const { once, acc_bank } = state;

  React.useEffect(() => {
    if (!once) {
      set_profile();
      setState((state) => ({ ...state, once: true }));
    }
  }, [bank_account, set_profile, once]);

  const onDeleteBank = useCallback(() => {
    handleModal(false)
    del_acc_bank_props(state.acc_bank);
  }, [del_acc_bank_props, state.acc_bank, handleModal])

  const handleClick = (event, id, prior) => {
  const { push, goBack } = props.history;
    switch (event) {
      case "prior":
        set_priority_bank_props({ id });
        setState({ ...state, acc_bank: id });
        return;
      case "delete":
        handleModal(true);
        setState({ ...state, acc_bank: id });
        return;
      case "add":
        push("/profil/setting/add-bank");
        return;
      case "update":
        const { account_number, behalf, bank_branch_input, bank_branch_id } =
          bank_account.find((item) => Number(item.id) === Number(id));
        push("/profil/setting/update-bank", {
          initial: {
            id,
            account_name: behalf,
            bank_id: bank_branch_id,
            account_number,
            bank_branch: bank_branch_input,
          },
        });
        return;
      default:
        goBack();
        break;
    }
  };

  const successDelete = useCallback(() => window.location.reload(), []);

  return (
    <div className={classes.container}>
      <div>
        <BasicText className="fs-14 mb-4 font-weight-bold" color="primary">
          UBAH REKENING BANK
        </BasicText>
        <ContainerWithLoader processName="set_profile" loadingHeight="70vh">
          {bank_account.map((el, id) => (
            <React.Fragment key={id}>
              <AccountBankItem
                name={el.bank.name}
                account_number={el.account_number}
                behalf={el.behalf}
                logo={el.bank.logo}
                id={el.id}
                prior={el.is_priority}
                onClick={handleClick}
                acc_bank={acc_bank}
                status={el.status}
              />
              <hr className={id === bank_account.length - 1 ? "d-none" : ""} />
            </React.Fragment>
          ))}
        </ContainerWithLoader>
      </div>
      <div className={classes.buttonContainer}>
        <ButtonContained
          id="button-add-rekening"
          backgroundColor={color.primary}
          text="TAMBAH REKENING LAIN"
          className="fs-14 font-weight-medium py-2 mr-3 flex-grow-1"
          radius="5px"
          onClick={() => handleClick("add")}
        />
        <ButtonContained
          id="button-cancel"
          backgroundColor={color.gray_5}
          text="BATAL"
          className="fs-14 font-weight-medium py-2 btnCancel flex-grow-1"
          radius="5px"
          onClick={() => handleClick("cancel")}
        />
      </div>
      <DialogCustom
        open={modal}
        handleClose={handleModal}
        text="HAPUS REKENING BANK INI?"
        buttonText="YA"
        onClick={onDeleteBank}
        onCancel={() => handleModal(false)}
        actionClass='p-0'
        textClass='h-100 cont-center fs-14 font-weight-bold'
        buttonClass='py-3 br-unset'
        dialogProps={{
          classes: {
            paper: classes.deleteModal
          }
        }}
      />
      <DialogAlert
        resultName="add_acc_bank"
        alertAppear="success"
        buttonText="OK"
      />
      <DialogAlert
        resultName="set_priority_bank"
        alertAppear="both"
        buttonText="OK"
      />
      <DialogAlert
        resultName="del_acc_bank"
        alertAppear="both"
        buttonText="OK"
        submitSuccessOnBlur
        submitIfSuccess={successDelete}
      />
    </div>
  );
};

const mapState = (state) => ({
  profile: state.profileReducer.profile,
  bank_account: state.profileReducer.bank_account,
});

export default compose(
  connect(mapState, {
    set_profile,
    set_priority_bank_props,
    del_acc_bank_props,
  }),
  withRouter
)(ChangeBank);
