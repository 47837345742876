import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Grid } from "@material-ui/core";
import BasicText from "../../atom/BasicText/BasicText";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import classNames from "classnames";
import ilustration from "../../../assets/img/ilustration";
import Indicator from "../../molecule/pinInput/Indicator";
import PinButton from "../../molecule/pinInput/PinButton";
import { connect } from "react-redux";
import { validate_pin_props } from "../../../config/redux/actions/auth";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import SnackAlert from "../../molecule/Alert/SnackAlert";
import processHelper from "../../../helper/processHelper";
import { remove_result_l } from "../../../config/redux/actions/state";
import PropTypes from "prop-types";
import TextInput from "../../molecule/MaterialInput/TextInput";
import ButtonContained from "../../atom/Button/ButtonContained";
import validation, { _isValid } from "../../../helper/validateHelper";
const mapState = (state) => {
  return {
    result_stack: state.stateReducer.result_stack,
    loading_stack: state.stateReducer.loading_stack,
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down(769)]: {
      backgroundColor: theme.palette.background.default,
      minHeight: "100vh",
      width: "auto",
    },
    backgroundColor: theme.palette.background.paper,
    width: 493,
  },
  appBar: {
    position: "relative",
    [theme.breakpoints.up(768)]: {
      display: "none",
    },
  },
  closeBar: {
    textAlign: "right",
    padding: "10px",
    [theme.breakpoints.down(769)]: {
      display: "none",
    },
  },
  ilustration: {
    backgroundColor: "#fff",
    paddingBottom: "15px",
  },
  inputMobile: {
    padding: 25,
    height: "60vh",
    [theme.breakpoints.up(768)]: {
      display: "none",
    },
  },
  inputDesktop: {
    padding: "0 5rem",
    [theme.breakpoints.down(769)]: {
      display: "none",
    },
  },
}));

function PinInput(props) {
  const {
    validateResult,
    history,
    onClickClose,
    result_stack,
    loading_stack,
    remove_result_l,
    onSubmit,
    dataSubmit,
    processName,
    title,
    withValidation,
    needForgot,
  } = props;
  const classes = useStyles();
  const [state, setState] = React.useState("");
  const [error, setError] = React.useState("");
  React.useEffect(() => {
    let data = processHelper.findResult("validate_pin", result_stack);
    if (Object.keys(data).length > 0) {
      if (data.type === "success") {
        validateResult(true);
        remove_result_l("validate_pin");
      } else {
        validateResult(false);
      }
    }
  }, [result_stack, history, validateResult, remove_result_l]);
  const handleClick = (e) => {
    if (e === "del") {
      if (state.length > 0) {
        setState(state.substring(0, state.length - 1));
      }
    } else if (e === "done") {
      if (onSubmit) {
        let data = { ...dataSubmit, pin: state };
        console.log(data);
        onSubmit(data);
      } else {
        props.validate_pin_props(state);
      }
    } else {
      if (state.length < 6) {
        setState(state + e);
      }
    }
  };
  const handleInput = (e) => {
    e = e.replace(/\D+/g, "");
    if (withValidation) {
      _isValid(validation.validatePin(), e)
        .then(() => setError(""))
        .catch((e) => setError(e))
        .then(() => setState(e));
    } else setState(e);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    handleClick("done");
  };
  const handleClose = () => {
    onClickClose();
  };
  const handleForgot = () => {
    history.push("/profil/setting/pin?forgot=true");
  };
  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} color="primary">
        <Toolbar className="w-100 container-body px-1">
          <Grid container className="w-100" justify="space-between">
            <Grid item className="my-auto pl-2">
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item className="my-auto text-center">
              <BasicText className="fs-14 font-weight-bold text-white">
                KONFIRMASI PIN
              </BasicText>
            </Grid>
            <Grid item className="my-auto text-right pr-1">
              <HelpOutlineIcon className="text-white fs-30" />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.closeBar}>
        <IconButton id="close-button" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <div
        className={classNames(
          classes.ilustration,
          "container-body text-center"
        )}
      >
        <img src={ilustration.pin} alt="" className="mb-3" />
        <BasicText className="fs-14 font-weight-bold color-primary">
          {title}
        </BasicText>
      </div>
      <div className={classNames(classes.inputMobile, "container-body")}>
        <div className="my-auto">
          <Indicator value={state.length} />
          {needForgot && (
            <p className="w-100 text-center color-secondary text-underline fs-12 mb-3">
              LUPA PIN
            </p>
          )}
          <PinButton
            onClick={handleClick}
            loading={processHelper.findProcess(processName, loading_stack)}
          />
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className={classNames(classes.inputDesktop, "mt-3")}>
          <TextInput
            id="input-pin"
            onChange={handleInput}
            value={state}
            passVisible={true}
            variant="outlined"
            type="password"
          />
          <BasicText
            className={classNames("fs-12 mb-1", needForgot ? "mb-3" : "mb-5")}
            style={{ color: "red" }}
          >
            {error && error}
          </BasicText>
          <div className="text-center">
            {needForgot && (
              <p
                id="link-forgot-pin"
                onClick={handleForgot}
                className="w-100 text-center color-secondary text-underline fs-12 mb-5 is-link"
              >
                LUPA PIN
              </p>
            )}
            <ButtonContained
              id="button-submit"
              text="LANJUT"
              className="px-5 py-3 font-weight-medium mb-5"
              radius="5px"
              disabled={state.length < 6}
              onClick={() => handleClick("done")}
              loading={processHelper.findProcess(processName, loading_stack)}
            />
          </div>
        </div>
      </form>
      {validateResult && <SnackAlert name={processName} />}
    </div>
  );
}

PinInput.propTypes = {
  validateResult: PropTypes.func,
  onClickClose: PropTypes.func,
  onSubmit: PropTypes.func,
  dataSubmit: PropTypes.any,
  processName: PropTypes.string,
  title: PropTypes.string,
  withValidation: PropTypes.bool,
  needForgot: PropTypes.bool,
};

PinInput.defaultProps = {
  processName: "validate_pin",
  title: "Konfirmasi PIN Kamu",
  needForgot: true,
};

export default compose(
  connect(mapState, { validate_pin_props, remove_result_l }),
  withRouter
)(PinInput);
