import * as types from "../actions/types";

const initial = {
  article: null,
  promo: null,
  content: "",
};

const articleReducer = (state = initial, action) => {
  switch (action.type) {
    case types.SET_ARTICLE_LIST:
      return { ...state, article: action.payload };
    case types.SET_PROMO_LIST:
      return { ...state, promo: action.payload };
    case types.SET_ARTICLE_CONTENT:
      return { ...state, content: action.payload };
    default:
      return state;
  }
};

export default articleReducer;
