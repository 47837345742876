import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import BasicText from "../../atom/BasicText/BasicText";
import classNames from "classnames";
import { version } from "../../../../package.json";
import config from "../../../config/constant/config";
import OJKText from "../../atom/OJKText";

const DesktopFooter = (props) => {
  const { width, className, maxWidth } = props;
  const useStyles = makeStyles({
    root: {
      backgroundColor: "#fff",
      position: "fixed",
      right: 0,
      bottom: 0,
      width: width ? width : "100%",
      "& .text-ojk": {
        marginBottom: "1px !important",
      },
      "& .subRoot": {
        padding: "15px 25px",
      },
    },
  });
  const classes = useStyles();
  return (
    <div className={classNames(classes.root, className)}>
      <div
        className="d-flex justify-content-between subRoot"
        style={{ maxWidth }}
      >
        <BasicText className="fs-10 color-primary my-auto copy-right-text">
          &copy; 2020 PT Syailendra Capital . All Right Reserved v0.52.13
        </BasicText>
        <div className="d-flex justify-content-end align-items-end">
          <OJKText />
        </div>
      </div>
      {/** if testing */}
      {config.FLAG_TEXT ? (
        <div
          style={{ backgroundColor: "red" }}
          className="text-center w-100 py-2"
        >
          <BasicText className="fs-12 text-white font-weight-bold">
            {config.FLAG_TEXT} - {version}
          </BasicText>
        </div>
      ) : null}
    </div>
  );
};

export default DesktopFooter;
