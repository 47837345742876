import React from "react";
import PropTypes from "prop-types";
import MenuProfileIcon from "../../../assets/img/icon/profile/MenuProfileIcon";
import BasicText from "../../atom/BasicText/BasicText";
import nav from "../../../assets/img/nav";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    cursor: "pointer",
    "&::before": {
      [theme.breakpoints.up(768)]: {
        content: `''`,
        display: "inline-block",
        width: "4px",
        position: "absolute",
        backgroundColor: theme.palette.common.white,
        height: "100%",
        transition: "background-color 0.3s"
      }
    },
    "& .contentButton svg": {
      color: theme.palette.secondary.light
    },
    "&:hover .contentButton svg,&.activeButton .contentButton svg": {
      [theme.breakpoints.up(768)]: {
        color: theme.palette.primary.main
      }
    },
    "&:hover::before": {
      backgroundColor: theme.palette.primary.main
    },
    "&.activeButton::before": {
      [theme.breakpoints.up(768)]: {
        content: `''`,
        display: "inline-block",
        width: "4px",
        position: "absolute",
        backgroundColor: theme.palette.primary.main,
        height: "100%"
      }
    },
    "& .contentButton": {
      display: "flex",
      width: "100%",
      alignItems: "flex-start",
      marginBottom: "1rem",
      [theme.breakpoints.up(768)]: {
        padding: "12px 0 6px 30px",
        marginBottom: 0
      },
      "& .contentInnerButton": {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        [theme.breakpoints.down(769)]: {
          paddingBottom: "1rem",
          borderBottom: "1px solid #dee2e6!important"
        },
        "& .contentInnerText": {
          fontSize: 14,
          [theme.breakpoints.up(768)]: {
            fontSize: 12
          }
        }
      }
    }
  },
  chevronIcon: {
    color: theme.palette.secondary.main,
    [theme.breakpoints.up(768)]: {
      display: "none"
    }
  }
}));

const ButtonProfile = props => {
  const classes = useStyles();
  const { icon, text, onClick, active } = props;
  const handleClick = () => {
    onClick();
  };
  return (
    <div className={classNames(classes.root, active ? "activeButton" : "")}>
      <div id={text.replace(/ /g,"-").toLowerCase()}  className="contentButton" onClick={handleClick}>
        {icon && <MenuProfileIcon icon={icon} className="mr-3" />}
        <div className="contentInnerButton">
          <BasicText className="contentInnerText" color="secondary">
            {text}
          </BasicText>
          <img src={nav.chevronRight} alt="" className={classes.chevronIcon} />
        </div>
      </div>
    </div>
  );
};

ButtonProfile.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func
};

ButtonProfile.defaultProps = {
  onClick: () => null
};

export default ButtonProfile;
