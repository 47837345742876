import { batch } from "react-redux";
import { createLogic } from "redux-logic";
import { decrypt, encrypt } from "../../../helper/EncryptHelper";
import processHelper from "../../../helper/processHelper";
import textHelper from "../../../helper/textHelper";
import { requestPostAxios } from "../../API/request";
import url from "../../API/url";
import {
  clear_register,
  fetch_regis_valid,
  log_out,
  register,
  set_auth,
  set_temp_regis,
} from "../actions/auth";
import { set_data_location, set_data_option_child } from "../actions/option";
import {
  remove_loading,
  set_loading,
  set_result,
  set_tmp_key,
  // gbl_act_lc,
} from "../actions/state";
import * as types from "../actions/types";
// import stateCallApi from "../../API/stateCallApi";
// import { store } from "../store";

export const loginLogic = createLogic({
  type: types.LOG_IN,
  latest: true,
  process({ action }, dispatch, done) {
    dispatch(set_loading("login"));
    requestPostAxios(url.AUTH.login, action.payload)
      .then((e) => {
        dispatch(set_auth({ token: e.data.token }));
        dispatch(set_tmp_key("success"));
        // dispatch(
        //   gbl_act_lc({
        //     ...stateCallApi.planner_list,
        //     callback: ({ data }) => {
        //       store.dispatch(set_auth({ isLogin: 1 }));
        //     },
        //   })
        // );
      })
      .catch((err) => {
        let error = {
          name: "login",
          data: { type: "error", msg: err.message },
        };
        dispatch(set_result(error));
      })
      .then(() => {
        dispatch(remove_loading("login"));
        done();
      });
  },
});

export const logoutLogic = createLogic({
  type: types.LOG_OUT_PROPS,
  latest: true,
  process({ action }, dispatch, done) {
    const key = "log-out-process";
    dispatch(set_loading(key));
    requestPostAxios(url.AUTH.logout, {})
      .then((e) => {
        dispatch(log_out());
        dispatch(
          set_result({
            name: "logout",
            data: { type: "success", msg: e.message },
          })
        );
      })
      .catch((err) => {
        dispatch(
          set_result({
            name: "logout",
            data: { type: "failed", msg: err.message },
          })
        );
      })
      .then(() => {
        dispatch(remove_loading(key));
        done();
      });
  },
});

export const validatePin = createLogic({
  type: types.VALIDATE_PIN_PROPS,
  latest: true,
  async process({ action }, dispatch, done) {
    dispatch(set_loading("validate_pin"));
    let data = {
      pin: action.payload,
    };
    await requestPostAxios(url.AUTH.validate_pin, data)
      .then((e) => {
        console.log(e);
        let success = {
          name: "validate_pin",
          data: { type: "success", msg: e.message },
        };
        dispatch(set_result(success));
      })
      .catch((err) => {
        console.log(err);
        let error = {
          name: "validate_pin",
          data: { type: "error", msg: err.message },
        };
        dispatch(set_result(error));
      })
      .then(() => {
        dispatch(remove_loading("validate_pin"));
        done();
      });
  },
});

export const changePass = createLogic({
  type: types.CHANGE_PASS_PROPS,
  latest: true,
  async process({ action }, dispatch, done) {
    dispatch(set_loading("change_pass"));
    // const { otp_code, new_password, new_password_confirm } = action.payload;
    // const data = {
    //   otp_code,
    //   new_password,
    //   new_password_confirm,
    //   otp_token: getState().authReducer.otp_token
    // };
    await requestPostAxios(url.AUTH.change_pass, action.payload)
      .then((e) => {
        console.log(e);
        let success = {
          name: "change_pass",
          data: { type: "success", msg: e.message },
        };
        dispatch(set_result(success));
      })
      .catch((err) => {
        console.log(err);
        let error = {
          name: "change_pass",
          data: { type: "error", msg: err.message },
        };
        dispatch(set_result(error));
      })
      .then(() => {
        dispatch(remove_loading("change_pass"));
        done();
      });
  },
});

export const requestOTP = createLogic({
  type: types.REQUEST_OTP_PROPS,
  latest: true,
  async process({ action }, dispatch, done) {
    dispatch(set_loading("request_otp"));
    const data = {
      need: action.payload,
    };
    await requestPostAxios(url.OTP.request_new_otp, data)
      .then((e) => {
        console.log(e);
        dispatch(set_auth({ otp_token: e.data.otp_token }));
        let success = {
          name: "request_otp",
          data: { type: "success", msg: e.message },
        };
        dispatch(set_result(success));
      })
      .catch((err) => {
        let error = {
          name: "request_otp",
          data: { type: "error", msg: err.message },
        };
        dispatch(set_result(error));
      })
      .then(() => {
        dispatch(remove_loading("request_otp"));
        done();
      });
  },
});

export const validateOTP = createLogic({
  type: types.VALIDATE_OTP_PROPS,
  latest: true,
  process({ getState, action }, dispatch, done) {
    dispatch(set_loading("validate_otp"));
    const { otp_code, type } = action.payload;
    const data = {
      otp_token: getState().authReducer.otp_token,
      otp_code: otp_code,
    };
    let url_s = "";
    if (type === "change_number_verify") {
      url_s = url.PROFILE.change_phone_verify;
    } else {
      url_s = url.AUTH.validate_otp;
    }
    console.log(data);
    requestPostAxios(url_s, data)
      .then((e) => {
        let success = {
          name: "validate_otp",
          data: { type: "success", msg: e.message },
        };
        dispatch(set_result(success));
      })
      .catch((err) => {
        let error = {
          name: "validate_otp",
          data: { type: "error", msg: err.message },
        };
        dispatch(set_result(error));
      })
      .then(() => {
        dispatch(remove_loading("validate_otp"));
        done();
      });
  },
});

export const resendOTP = createLogic({
  type: types.RESEND_OTP_PROPS,
  latest: true,
  process({ getState }, dispatch, done) {
    dispatch(set_loading("resend_otp"));
    const data = {
      otp_token: getState().authReducer.otp_token,
    };
    requestPostAxios(url.OTP.resend_otp, data)
      .then((e) => {
        console.log(e);
        dispatch(set_auth({ otp_token: e.data.otp_token.data }));
        let success = {
          name: "resend_otp",
          data: { type: "success", msg: e.message },
        };
        dispatch(set_result(success));
      })
      .catch((err) => {
        let error = {
          name: "resend_otp",
          data: { type: "error", msg: err.message },
        };
        dispatch(set_result(error));
      })
      .then(() => {
        dispatch(remove_loading("resend_otp"));
        done();
      });
  },
});

export const changePin = createLogic({
  type: types.CHANGE_PIN_PROPS,
  latest: true,
  process({ action }, dispatch, done) {
    dispatch(set_loading("change_pin"));
    requestPostAxios(url.AUTH.change_pin, action.payload)
      .then((e) => {
        console.log(e);
        let success = {
          name: "change_pin",
          data: { type: "success", msg: e.message },
        };
        dispatch(set_result(success));
      })
      .catch((err) => {
        let error = {
          name: "change_pin",
          data: { type: "error", msg: err.message },
        };
        dispatch(set_result(error));
      })
      .then(() => {
        dispatch(remove_loading("change_pin"));
        done();
      });
  },
});

export const forgotPin = createLogic({
  type: types.FORGOT_PIN_PROPS,
  latest: true,
  process({ action }, dispatch, done) {
    dispatch(set_loading("forgot_pin"));
    requestPostAxios(url.AUTH.forgot_pin, action.payload)
      .then((e) => {
        console.log(e);
        let success = {
          name: "forgot_pin",
          data: { type: "success", msg: e.message },
        };
        dispatch(set_result(success));
      })
      .catch((err) => {
        const error = {
          name: "forgot_pin",
          data: { type: "error", msg: err.message },
        };
        dispatch(set_result(error));
      })
      .then(() => {
        dispatch(remove_loading("forgot_pin"));
        done();
      });
  },
});

export const regisValidation = createLogic({
  type: types.FETCH_REGIS_VALIDATION,
  latest: true,
  validate({ action }, allow, reject) {
    const { value, type } = action.payload || {};
    if (value && type) {
      allow(action);
    } else reject(action);
  },
  process({ action }, dispatch, done) {
    const name = "validateRegis";
    dispatch(set_loading(name));
    requestPostAxios(url.AUTH.register_validation, action.payload)
      .then((res) => {
        const { getId } = processHelper;
        batch(() => {
          if (action.payload.type === "sid") {
            const { sid, birth_place, birth_date, ocr_format, user_account } =
              res.data.data;
            const {
              nik,
              nama,
              exact,
              kewarganegaraan,
              alamat,
              ktp_postal_code,
              mail_address,
              mail_postal_code,
            } = ocr_format;
            const tanggal_lahir = processHelper.convertTglFormat(birth_date);
            const _res = {
              registerStatus: "SID",
              sid,
              nik,
              nama,
              tempat_lahir: birth_place,
              tanggal_lahir,
              gender: getId(exact.gender),
              agama: getId(exact.religion),
              status_perkawinan: getId(exact.marriage),
              kewarganegaraan,
              alamat_ktp: alamat,
              provinsi_ktp: getId(exact.provinsi),
              kota_ktp: getId(exact.kota),
              kecamatan_ktp: getId(exact.kecamatan),
              desa_ktp: getId(exact.kelurahan),
              kodepos_ktp: ktp_postal_code,
              alamat_korespondensi: mail_address,
              provinsi_korespondensi: getId(exact.mail_provinsi),
              kota_korespondensi: getId(exact.mail_kota),
              kodepos_korespondensi: mail_postal_code,
              pendidikan: getId(exact.education),
              pekerjaan: getId(exact.job),
              pendapatan: getId(exact.income),
              sumber_pendapatan: getId(exact.source_income),
              tujuan: getId(exact.investment_objective),
              user_account: [],
            };
            user_account.forEach((item) => {
              _res.user_account.push({
                account_number: item.account_number,
                id: item.bank_id,
                name: item.bank.name,
              });
            });
            dispatch(register(_res));
            dispatch(
              set_data_option_child({
                key: "kecamatan_list",
                data: {
                  [_res.kota_ktp]: exact.kecamatan ? [exact.kecamatan] : [],
                },
              })
            );
            dispatch(
              set_data_option_child({
                key: "kelurahan_list",
                data: {
                  [_res.kecamatan_ktp]: exact.kelurahan
                    ? [exact.kelurahan]
                    : [],
                },
              })
            );
          } else {
            dispatch(
              set_result({ name, data: { type: "success", msg: res.message } })
            );
          }
          dispatch(remove_loading(name));
        });
      })
      .catch((err) => {
        batch(() => {
          dispatch(
            set_result({ name, data: { type: "error", msg: err.message } })
          );
          dispatch(remove_loading(name));
        });
      })
      .then(done);
  },
});

export const regisRequestOTP = createLogic({
  type: types.FETCH_REGIS_REQUEST_OTP,
  latest: true,
  validate({ action }, allow, reject) {
    const { phone_number } = action.payload || {};
    if (phone_number) allow(action);
    else reject(action);
  },
  process({ action }, dispatch, done) {
    const name = "r_request_otp";
    dispatch(set_loading(name));
    const { callback, ...params } = action.payload;
    requestPostAxios(url.AUTH.request_otp, {
      ...params,
      device_type: "web",
    })
      .then((res) => {
        const { otp_token, phone_number, referral_code } = res.data;
        const referal = referral_code === null ? "" : referral_code;
        batch(() => {
          dispatch(remove_loading(name));
          dispatch(
            set_auth({ otp_token: { otp_token, otp_dst: params.otp_dst } })
          );
          dispatch(
            register({
              no_hp: phone_number,
              referal,
            })
          );
          dispatch(
            set_result({
              name,
              data: {
                type: "success",
                msg: res.message || "Kode OTP telah di kirim ke nomor anda",
              },
            })
          );
        });
        callback?.() ?? console.log("");
      })
      .catch((err) => {
        batch(() => {
          dispatch(remove_loading(name));
          dispatch(
            set_result({ name, data: { type: "error", msg: err.message } })
          );
        });
      })
      .then(done);
  },
});
export const regisValidateOTP = createLogic({
  type: types.FETCH_REGIS_VALIDATE_OTP,
  latest: true,
  validate({ action, getState }, allow, reject) {
    const { otp_token: { otp_token } = {} } = getState().authReducer;
    const { payload } = action;
    if (otp_token) {
      action.payload = {
        otp_token,
        otp_code: payload,
      };
      allow(action);
    } else reject(action);
  },
  process({ action, getState }, dispatch, done) {
    const { referal } = getState().authReducer.register;
    const name = "r_validate_otp";
    dispatch(set_loading(name));
    requestPostAxios(url.AUTH.validate_otp, action.payload)
      .then((res) => {
        batch(() => {
          dispatch(
            register({ registration_token: res.data.registration_token })
          );
          dispatch(set_temp_regis({ referral_code: referal }));
          dispatch(remove_loading(name));
        });
      })
      .catch((err) => {
        batch(() => {
          dispatch(
            set_result({ name, data: { type: "error", msg: err.message } })
          );
          dispatch(remove_loading(name));
        });
      })
      .then(done);
  },
});

export const fetchOCR = createLogic({
  type: types.FETCH_DATA_OCR,
  latest: true,
  validate({ action }, allow, reject) {
    const { payload } = action;
    if (payload) {
      action.payload = {
        ktp: payload,
      };
      allow(action);
    } else reject(action);
  },
  process({ getState, action }, dispatch, done) {
    const { payload } = action;
    const { registerStatus, registration_token, no_hp } =
      getState().authReducer.register;
    const name = "fetch_ocr";
    dispatch(set_loading(name));
    requestPostAxios(url.unused.upload_ktp, payload)
      .then((res) => {
        const { data, exact } = res.data || {};
        const {
          nik,
          nama,
          lahir_tempat,
          lahir_full,
          kewarganegaraan,
          alamat,
          rt,
          rw,
          postal_code,
        } = data || {};
        const {
          gender,
          marriage,
          job,
          provinsi,
          kota,
          kecamatan,
          kelurahan,
          religion,
        } = exact || {};
        function _check(data = {}) {
          return data?.id ?? "";
        }
        let tanggal_lahir = processHelper.convertTglFormat(lahir_full);
        let _res = {
          url_ktp: res.data.url,
          registerStatus: "OCR",
          nik,
          nama,
          tempat_lahir: lahir_tempat,
          tanggal_lahir,
          gender: _check(gender),
          agama: _check(religion),
          status_perkawinan: _check(marriage),
          pekerjaan: _check(job),
          provinsi_ktp: _check(provinsi),
          kota_ktp: _check(kota),
          kecamatan_ktp: _check(kecamatan),
          desa_ktp: _check(kelurahan),
          kewarganegaraan,
          alamat_ktp: alamat,
          rt_ktp: rt,
          rw_ktp: rw,
          kodepos_ktp: postal_code,
        };
        if (registerStatus === "SID") {
          dispatch(clear_register({ registration_token, no_hp }));
        }
        // if (registerStatus !== "SID" || registerStatus.search("register") < 0) {
        //   _res = {
        //     ..._res,
        //     registerStatus: "OCR",
        //     nik,
        //     nama,
        //     tempat_lahir: lahir_tempat,
        //     tanggal_lahir,
        //     gender: _check(gender),
        //     agama: _check(religion),
        //     status_perkawinan: _check(marriage),
        //     pekerjaan: _check(job),
        //     provinsi_ktp: _check(provinsi),
        //     kota_ktp: _check(kota),
        //     kecamatan_ktp: _check(kecamatan),
        //     desa_ktp: _check(kelurahan),
        //     kewarganegaraan,
        //     alamat_ktp: alamat,
        //     rt_ktp: rt,
        //     rw_ktp: rw,
        //     kodepos_ktp: postal_code,
        //   };
        // }
        batch(() => {
          dispatch(set_data_location(_res.kota_ktp));
          dispatch(set_data_location(_res.kecamatan_ktp));
          dispatch(
            set_data_option_child({
              key: "kecamatan_list",
              data: { [_res.kota_ktp]: [kecamatan] },
            })
          );
          dispatch(
            set_data_option_child({
              key: "kelurahan_list",
              data: { [_res.kecamatan_ktp]: [kelurahan] },
            })
          );
          dispatch(register(_res));
          dispatch(
            set_result({
              name,
              data: { type: "success", msg: "Data Berhasil Didapatkan" },
            })
          );
          dispatch(remove_loading(name));
        });
      })
      .catch((err) => {
        batch(() => {
          dispatch(register({ url_ktp: err.error.url }));
          dispatch(
            set_result({ name, data: { type: "error", msg: err.message } })
          );
          dispatch(remove_loading(name));
        });
      })
      .then(done);
  },
});

export const fetchSID = createLogic({
  type: types.FETCH_DATA_SID,
  debounce: 700,
  latest: true,
  validate({ getState, action }, allow, reject) {
    const { registration_token, registerStatus } =
      getState().authReducer.register;
    const value = action.payload;
    if (
      registration_token &&
      value.length === 15 &&
      registerStatus?.search("register") < 0
    ) {
      const data = {
        registration_token,
        type: "sid",
        value,
      };
      action.payload = data;
      allow(action);
    } else reject(action);
  },
  process({ action }, dispatch, done) {
    dispatch(fetch_regis_valid(action.payload));
    done();
  },
});

export const register1 = createLogic({
  type: types.FETCH_REGIS_1,
  latest: true,
  validate({ getState, action }, allow, reject) {
    const { registration_token, url_ktp, sid } =
      getState().authReducer.register;
    const { newKtp, ...other } = action.payload;
    const isReRegNewKtp = registration_token === "re-register" && newKtp;
    if (registration_token) {
      action.payload = {
        ktp: isReRegNewKtp ? "same" : url_ktp,
        ...other,
        registration_token,
      };
      if (sid) action.payload.sid = sid;
      allow(action);
    } else reject(action);
  },
  process({ action }, dispatch, done) {
    const { payload } = action;
    const { provinsi_korespondensi, ...params } = payload;
    const name = "register1";
    if (payload.ktp) {
      dispatch(set_loading(name));
      requestPostAxios(url.AUTH.register_identity, params)
        .then((res) => {
          const {
            ktp_number,
            ktp_name,
            birth_place,
            birth_date,
            ktp_address,
            ktp_rtrw,
            ktp_province_id,
            ktp_city_id,
            ktp_kecamatan_id,
            ktp_kelurahan_id,
            ktp_postal_code,
            gender_id,
            marriage_id,
            religion_id,
            citizenship,
            mail_address,
            mail_country_id,
            mail_city_id,
            mail_postal_code,
          } = payload;
          batch(() => {
            dispatch(
              register({
                registerStatus: "register-1",
                nik: ktp_number,
                nama: ktp_name,
                tempat_lahir: birth_place,
                tanggal_lahir: birth_date,
                alamat_ktp: ktp_address,
                rt_ktp: ktp_rtrw.split("/")[0],
                rw_ktp: ktp_rtrw.split("/")[1],
                provinsi_ktp: ktp_province_id,
                kota_ktp: ktp_city_id,
                kecamatan_ktp: ktp_kecamatan_id,
                desa_ktp: ktp_kelurahan_id,
                kodepos_ktp: ktp_postal_code,
                gender: gender_id,
                status_perkawinan: marriage_id,
                agama: religion_id,
                kewarganegaraan: citizenship,
                alamat_korespondensi: mail_address,
                negara_korespondensi: mail_country_id,
                provinsi_korespondensi,
                kota_korespondensi: mail_city_id,
                kodepos_korespondensi: mail_postal_code,
              })
            );
            dispatch(set_temp_regis(payload));
            dispatch(
              set_result({ name, data: { type: "success", msg: res.message } })
            );
            dispatch(remove_loading(name));
          });
        })
        .catch((err) => {
          console.log(err);
          batch(() => {
            dispatch(
              set_result({ name, data: { type: "error", msg: err.message } })
            );
            dispatch(remove_loading(name));
          });
        })
        .then(done);
    } else {
      dispatch(
        set_result({
          name,
          data: {
            type: "error",
            msg: "Anda Belum mengUpload Foto KTP, Silakan Upload Foto KTP anda",
          },
        })
      );
      done();
    }
  },
});

export const register2 = createLogic({
  type: types.FETCH_REGIS_2,
  latest: true,
  validate({ getState, action }, allow, reject) {
    const { registration_token, no_hp } = getState().authReducer.register;
    if (registration_token) {
      action.payload = {
        ...action.payload,
        registration_token,
        phone_number: no_hp,
      };
      allow(action);
    } else reject(action);
  },
  process({ action }, dispatch, done) {
    const name = "register2";
    const { payload } = action;
    dispatch(set_loading(name));
    requestPostAxios(url.AUTH.register_profile, payload)
      .then((res) => {
        const { password, password_confirm, email } = payload;
        const gembok = email.split("@")[0];
        batch(() => {
          dispatch(remove_loading(name));
          dispatch(register({ registerStatus: "register-2" }));
          dispatch(
            set_temp_regis({
              ...payload,
              password: encrypt(password, gembok),
              password_confirm: encrypt(password_confirm, gembok),
            })
          );
          dispatch(
            set_result({ name, data: { type: "success", msg: res.message } })
          );
        });
      })
      .catch((err) => {
        console.log(err);
        batch(() => {
          dispatch(remove_loading(name));
          dispatch(
            set_result({ name, data: { type: "error", msg: err.message } })
          );
        });
      })
      .then(done);
  },
});

export const register3 = createLogic({
  type: types.FETCH_REGIS_3,
  latest: true,
  validate({ getState, action }, allow, reject) {
    const { registration_token } = getState().authReducer.register;
    const signature = action.payload;
    if (registration_token) {
      action.payload = { registration_token, signature };
      allow(action);
    } else reject(action);
  },
  process({ action }, dispatch, done) {
    const name = "register3";
    dispatch(set_loading(name));
    requestPostAxios(url.AUTH.register_ttd, action.payload)
      .then((res) => {
        batch(() => {
          dispatch(remove_loading(name));
          dispatch(register({ registerStatus: "register-3" }));
          dispatch(set_temp_regis(action.payload));
          dispatch(
            set_result({
              name,
              data: {
                type: "success",
                msg: res.message,
              },
            })
          );
        });
      })
      .catch((err) => {
        batch(() => {
          dispatch(remove_loading(name));
          dispatch(
            set_result({ name, data: { type: "error", msg: err.message } })
          );
        });
      })
      .then(done);
  },
});

export const registerPIN = createLogic({
  type: types.FETCH_REGIS_4,
  latest: true,
  validate({ getState, action }, allow, reject) {
    const { registration_token, params, selfie } =
      getState().authReducer.register;
    const { email, password, password_confirm } = params;
    const gembok = email.split("@")[0];
    if (registration_token) {
      action.payload = {
        ...params,
        selfie,
        password: decrypt(password, gembok),
        password_confirm: decrypt(password_confirm, gembok),
        ...action.payload,
      };
      allow(action);
    } else reject(action);
  },
  process({ action }, dispatch, done) {
    const name = "register4";
    dispatch(set_loading(name));
    requestPostAxios(url.AUTH.register_pin, action.payload)
      .then((res) => {
        let msg = "";
        res.data.message.forEach((item, index) => {
          msg = `${msg} ${index ? " | " : ""} ${item}`;
        });
        batch(() => {
          dispatch(remove_loading(name));
          dispatch(set_result({ name, data: { type: "success", msg } }));
          // dispatch(set_auth({ token: res.data.token, isLogin: 1 }));
          // dispatch(clear_register());
        });
      })
      .catch((err) => {
        batch(() => {
          dispatch(remove_loading(name));
          dispatch(
            set_result({ name, data: { type: "error", msg: err.message } })
          );
        });
      })
      .then(done);
  },
});

export const re_regis = createLogic({
  type: types.RE_REGIS,
  latest: true,
  process({ action, getState }, dispatch, done) {
    const { sid, email, phone_number, user_data_individu } = action.payload;
    const {
      path_ktp,
      path_selfie,
      card_number,
      first_name,
      middle_name,
      last_name,
      birth_place,
      birth_date,
      gender_id,
      religion_id,
      marriage_id,
      job_id,
      job_category_id,
      job_role_id,
      card_address,
      card_post_code,
      card_rtrw,
      card_kelurahan_id,
      card_kecamatan_id,
      card_city_id,
      card_province_id,
      citizenship,
      address,
      post_code,
      city_id,
      province_id,
      income_id,
      income_source_id,
      investment_objective_id,
      education_id,
      user_account,
      user_waris,
      profile_risk,
      profile_risk_point,
    } = user_data_individu;
    function _defaultNull(text) {
      return text || "";
    }
    const _res = {
      registerStatus: "re-register",
      registration_token: "re-register",
      sid,
      url_ktp: path_ktp,
      url_selfie: path_selfie,
      nik: card_number,
      nama: textHelper.printFullname({
        first_name,
        middle_name,
        last_name,
      }),
      tempat_lahir: _defaultNull(birth_place),
      tanggal_lahir: _defaultNull(birth_date),
      gender: _defaultNull(gender_id),
      agama: _defaultNull(religion_id),
      status_perkawinan: _defaultNull(marriage_id),
      alamat_ktp: _defaultNull(card_address),
      provinsi_ktp: _defaultNull(card_province_id),
      kota_ktp: _defaultNull(card_city_id),
      kecamatan_ktp: _defaultNull(card_kecamatan_id),
      desa_ktp: _defaultNull(card_kelurahan_id),
      rt_ktp: card_rtrw.split("/")[0],
      rw_ktp: card_rtrw.split("/")[1],
      kodepos_ktp: _defaultNull(card_post_code),
      alamat_korespondensi: _defaultNull(address),
      provinsi_korespondensi: _defaultNull(province_id),
      kota_korespondensi: _defaultNull(city_id),
      kodepos_korespondensi: _defaultNull(post_code),
      pendidikan: _defaultNull(education_id),
      pekerjaan: _defaultNull(job_id),
      bidang_usaha: _defaultNull(job_category_id),
      jabatan: _defaultNull(job_role_id),
      pendapatan: _defaultNull(income_id),
      sumber_pendapatan: _defaultNull(income_source_id),
      ahli_waris: _defaultNull(user_waris?.[0].name),
      hubungan: _defaultNull(user_waris?.[0].relation.id),
      tujuan: _defaultNull(investment_objective_id),
      profile_risk: {
        profile_risk_type: profile_risk,
        profile_risk_point,
        profile_risk_product: [],
        // profileReducer.profile_risk?.profile_risk_product,
      },
      kewarganegaraan: _defaultNull(citizenship),
      no_hp: parseInt(phone_number),
      email: _defaultNull(email),
      user_account,
    };
    dispatch(register(_res));
    done();
  },
});

export const submit_re_regis = createLogic({
  type: types.FETCH_RE_REGIS,
  latest: true,
  process({ getState, action }, dispatch, done) {
    const { params } = getState().authReducer.register;
    const name = "fetch-re-regis";
    dispatch(set_loading(name));
    requestPostAxios(url.AUTH.re_register, {
      ...params,
      signature: action.payload,
    })
      .then((res) => {
        batch(() => {
          dispatch(remove_loading(name));
          dispatch(
            set_result({ name, data: { type: "success", msg: res.message } })
          );
        });
      })
      .catch((err) => {
        batch(() => {
          dispatch(remove_loading(name));
          dispatch(
            set_result({ name, data: { type: "error", msg: err.message } })
          );
        });
      })
      .then(done);
  },
});

export const forgotPassword = createLogic({
  type: types.FETCH_FORGOT_PASS,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let { payload } = action;
    const { type } = payload;
    if (type) {
      if (type === "request") {
        payload.url = url.AUTH.forgot_pass;
      } else if (type === "validate") {
        const { otp_token } = getState().authReducer || {};
        payload.params.otp_token = otp_token;
        payload.url = url.AUTH.validate_forgot_pass;
      } else if (type === "change") {
        const { otp } = getState().authReducer;
        payload.params = { ...payload.params, ...otp };
        payload.url = url.AUTH.forgot_change_pass;
      }
      allow(action);
    } else reject(action);
  },
  process({ action }, dispatch, done) {
    const { url, params, type } = action.payload;
    const name = `change_pass_${type}`;
    dispatch(set_loading(name));
    requestPostAxios(url, params)
      .then((res) => {
        const { data } = res;
        console.log(res);
        batch(() => {
          if (type === "request") {
            dispatch(set_auth({ otp_token: data.otp_token, otp: params }));
            dispatch(
              set_result({
                name,
                data: {
                  type: "success",
                  msg: res.message || "Kode Berhasil dikirim ke email anda",
                },
              })
            );
          } else if (type === "validate") dispatch(set_auth({ otp: params }));
          else if (type === "change") {
            dispatch(set_auth({ otp_token: null, otp: {} }));
            dispatch(
              set_result({
                name,
                data: { type: "success", msg: "Password Berhasil diubah" },
              })
            );
          }
          dispatch(remove_loading(name));
        });
      })
      .catch((err) => {
        batch(() => {
          dispatch(
            set_result({ name, data: { type: "error", msg: err.message } })
          );
          dispatch(remove_loading(name));
        });
      })
      .then(done);
  },
});

export const reSendVerfication = createLogic({
  type: types.FETCH_RESEND_VERIF_EMAIL,
  latest: true,
  validate({ action }, allow, reject) {
    if (action.payload) {
      action.payload = { pin: action.payload };
      allow(action);
    } else reject(action);
  },
  process({ action }, dispatch, done) {
    const name = "resend-verif";
    dispatch(set_loading(name));
    requestPostAxios(url.AUTH.reSendVerfication, action.payload)
      .then((res) => {
        batch(() => {
          dispatch(remove_loading(name));
          dispatch(
            set_result({ name, data: { type: "success", msg: res.message } })
          );
        });
      })
      .catch((err) => {
        batch(() => {
          dispatch(remove_loading(name));
          dispatch(
            set_result({ name, data: { type: "error", msg: err.message } })
          );
        });
      })
      .then(done);
  },
});
