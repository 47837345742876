import { Grid, withStyles } from "@material-ui/core";
import { Formik, Form } from "formik";
import React, { createRef, PureComponent } from "react";
import { object } from "yup";
import processHelper from "../../../helper/processHelper";
import validation from "../../../helper/validateHelper";
import ButtonContained from "../../atom/Button/ButtonContained";
import ButtonLink from "../../atom/Button/ButtonLink";
import { withRouter } from "react-router-dom";
import InputOtp from "../../molecule/MaterialInput/InputOtp";
import ButtonLink2 from "../../atom/Button/ButtonLink2";
import { compose } from "redux";

const validationSchema = object().shape({
  otp: validation.validateOTP(),
});

const useStyles = (theme) => ({
  linkMethod: {
    marginTop: 10,
    marginBottom: 30,
    fontSize: 12,
    color: theme.palette.primary.main
  }
})

const initial = { otp: "" };

class InputRegis2 extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expired: false,
      once: true,
    };
    this.countDown = createRef();
  }
  componentDidUpdate = (prevProps, prevState) => {
    const { history, data } = this.props;
    const { otp_token, registration_token } = data || {};
    const { clearCount, setUlang } = this.countDown.current;
    if (otp_token && otp_token !== prevProps.data?.otp_token) {
      if (this.state.expired) this._setExpired();
      clearCount();
      setUlang();
    } else if (
      registration_token &&
      registration_token !== prevProps.data?.registration_token
    ) {
      history.replace("/register/identity");
    }
  };

  _handleReSend = (changeMethod) => {
    const cb = () => this.countDown.current?.resetOTP();
    this.props.resend(cb, changeMethod);
  };
  _handleSubmit = (data) => {
    if (this.state.once) {
      this.props.onSubmit(data.otp);
      this.setState({ once: false });
    }
  };
  _setExpired = () => {
    this.setState((state) => ({ expired: !state.expired }));
  };

  render() {
    const { loading, classes } = this.props;
    const { findProcess } = processHelper;
    const disabled =
      findProcess("r_request_otp", loading) ||
      findProcess("r_validate_otp", loading) ||
      this.state.expired;
    return (
      <Formik
        initialValues={initial}
        validationSchema={validationSchema}
        onSubmit={this._handleSubmit}
      >
        {({ values, handleChange, handleSubmit, errors }) => (
          <Form className="d-flex flex-column flex-grow-1 justify-content-between w-100 mt-1">
            <Grid>
              <InputOtp
                id="otp-regis"
                onChange={handleChange("otp")}
                value={values.otp}
                error={errors.otp}
                withCountdown
                ref={this.countDown}
                onTimeOut={this._setExpired}
              />
              <ButtonLink
                id="link-reset-otp"
                onClick={() => this._handleReSend()}
                loading={processHelper.findProcess("r_request_otp", loading)}
                disabled={disabled}
                text="KIRIM ULANG KODE OTP"
                className="gray-5"
                containClass="mt-4 pt-2"
              />
              <ButtonLink2
                id="method-lain"
                onClick={() => this._handleReSend(true)}
                loading={processHelper.findProcess("r_request_otp", loading)}
                disabled={disabled}
                text="GUNAKAN METODE LAIN"
                className={classes.linkMethod}
              />
            </Grid>
            <ButtonContained
              id="submit-register-2"
              text="VERIFIKASI"
              onClick={handleSubmit}
              radius="4px"
              type="submit"
              disabled={disabled}
              className="align-self-center"
              style={{ padding: "14px 36px", borderRadius: 6 }}
              loading={processHelper.findProcess("r_validate_otp", loading)}
            />
          </Form>
        )}
      </Formik>
    );
  }
}

InputRegis2.defaultProps = {
  loading: [],
};

export default compose(withRouter, withStyles(useStyles))(InputRegis2);
