import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import React from "react";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import ilustration from "../../assets/img/ilustration";
import stateCallApi from "../../config/API/stateCallApi";
import { set_user } from "../../config/redux/actions/auth";
import { dash_lc } from "../../config/redux/actions/dashboard";
import { set_menu_props } from "../../config/redux/actions/option";
import {
  set_trending_props,
  top_product,
} from "../../config/redux/actions/product";
import { gbl_act_lc, gbl_list_lc } from "../../config/redux/actions/state";
import processHelper, { handleClickProduct } from "../../helper/processHelper";
import DialogAlert from "../molecule/Alert/DialogAlert";
import CardUserGoals from "../organism/MainPage/DashboardList/cards/CardUserGoals";
import DashboardList from "../organism/MainPage/DashboardList/DashboardList";
import MainMenu from "../organism/MainPage/MainMenu";
import GenerateHorizontal from "../template/generate/GenerateHorizontal";
import TempMain from "../template/TempMain";
import PlannerList from "../organism/MainPage/PlannerList";
import GenerateLoaderHorizontal from "../template/generate/GenerateLoaderHorizontal";
import VoucherMerchantList from "../organism/MainPage/VoucherMerchantList";
import { withStyles } from "@material-ui/core";
import DialogUpdateMotherName from "../molecule/Dialog/DialogUpdateMotherName";

const dialogs = [
  // { resultName: "dashboard" },
  { resultName: "save_risk_result", alertAppear: "success" },
  { resultName: "register4", alertAppear: "success" },
  { resultName: "fetch-re-regis", alertAppear: "success" },
  { resultName: "set_profile", alertAppear: "error" },
];

const mapState = (state) => {
  return {
    authReducer: state.authReducer,
    top_products: state.productReducer.top_product,
    trending: state.productReducer.trending,
    article: state.articleReducer.article,
    menu: state.optionReducer.menu,
    onboarding: state.persistReducer.onboarding,
    ...state.optionReducer.dashboard,
    planner: state.goalReducer.planner.data,
    user_goals: state.goalReducer.user.data,
  };
};

const ButtonAddList = ({ iconProps, ...props }) => {
  return (
    <div
      id="add-btn-goal"
      style={{ width: 322, borderRadius: 4 }}
      className="h-100 bg-gray-7 cont-center is-link"
      {...props}
    >
      <AddCircleOutline className="fs-32 gray-4" {...iconProps} />
    </div>
  );
};

const ListGoals = ({ onClick }) => {
  const user_goals = useSelector((state) => state.goalReducer.user.data);
  const loading = useSelector((state) =>
    processHelper.findProcess(
      stateCallApi.goals_user_list.key,
      state.stateReducer.loading_stack
    )
  );
  if (loading) {
    return (
      <GenerateLoaderHorizontal size={{ width: 282, height: 159 }} count={2} />
    );
  }
  return (
    <GenerateHorizontal
      className="mx-n4"
      data={user_goals}
      options={{ spacing: 4, paddingStart: 24, paddingEnd: 24 }}
      render={CardUserGoals}
      component={{
        footer: <ButtonAddList onClick={onClick} />,
      }}
    />
  );
};

class MainPage extends React.PureComponent {
  state = {
    showAddPlanner: false,
    new_dash: [
      {
        title: "Info Produk dan Tips",
        key: "trending",
        data: "trending",
        loading: "dashboard",
        info: "Simulasi Kinerja Reksa Dana tidak mencerminkan return di masa depan dan tidak bersifat menjanjikan keuntungan",
      },
      // {
      //   title: "Video",
      //   key: "video_scor",
      //   data: "video_scor",
      //   loading: "dashboard",
      // },
      // {
      //   title: "Insight",
      //   key: "market_insight",
      //   data: "market_insight",
      //   loading: "dashboard",
      // },
    ],
  };

  handleGoToGoals = () => this.props.history.push("/create-planner");

  componentDidMount() {
    const {
      authReducer,
      history,
      set_user,
      // set_menu_props,
      // top_product,
      set_trending_props,
      // dash_lc,
      gbl_act_lc,
      gbl_list_lc,
    } = this.props;
    if (authReducer.isLogin === 1) {
      if (authReducer.token !== null) {
        set_user();
        gbl_act_lc(stateCallApi.porto_summary);
      }
    } else if (authReducer.isLogin !== 1) {
      history.replace("/onboarding");
    }
    // set_menu_props();
    // top_product();
    set_trending_props();
    // dash_lc();
    gbl_list_lc({
      ...stateCallApi.goals_user_list,
    });
    setTimeout(() => {
      gbl_act_lc({
        ...stateCallApi.planner_list,
        callback: ({ data }) => {
          this.setState({ showAddPlanner: data.length < 5 });
        },
      });
    }, 20);

    // if (this.props.article === null) {
    //   this.props.set_article("artikel");
    // }
  }

  handleClick = (value, name, addParams) => {
    const { push } = this.props.history;
    switch (name) {
      case "top_product":
        push("/detail-produk?product=" + value.code);
        break;
      // case "trending":
      //   push(value);
      //   break;
      case "planner-change":
        push(`/modify-planner?d=${value.id}`, {
          item: value,
        });
        break;
      case "planner-run":
        const { name, target_amount } = addParams;
        push("/goals-planner", {
          name,
          biayaTotal: target_amount,
          id_tab: value.goal_id,
        });
        break;
      case "market_insight":
      case "video_scor":
        window.open(value?.url, "_blank");
        break;
      case "trending":
        push(handleClickProduct(value));
        break;
      default:
        break;
    }
  };

  render() {
    const { authReducer, history, classes } = this.props;
    const { new_dash, showAddPlanner } = this.state;
    return (
      <TempMain
        backgroundColor="#fff"
        bottomNavValue={0}
        goBackButton={false}
        initialPage
        title="DASHBOARD"
        data={{
          isLogin: authReducer.isLogin,
          mainMenu: <MainMenu />,
          userGoal: <ListGoals onClick={this.handleGoToGoals} />,

          // topReksa: (
          //   <TopReksa
          //     loading_name="top_product"
          //     onClick={(e) => this.handleClick(e, "trending")}
          //   />
          // ),

          // trendingScroll: (
          //   <TrendingScroll onClick={(e) => this.handleClick(e, "trending")} />
          // ),

          // articleScroll: (
          //   <ArticleScroll
          //     loading_name="get_blogs"
          //     onClick={(e) => this.handleClick(e, "trending")}
          //   />
          // ),
          // knowledgeLink: (
          //   <KnowledgeLink onClick={(e) => this.handleClick(e, "trending")} />
          // ),
        }}
        page="Dashboard"
      >
        <div className="mx-4">
          <PlannerList
            data={this.props.planner}
            onClickItem={this.handleClick}
            withComponent={showAddPlanner}
            onClickAdd={this.handleGoToGoals}
          />
          {new_dash.map(({ key, data, ...other }) => (
            <DashboardList
              key={key}
              rowId={key}
              onClick={this.handleClick}
              data={this.props[data]}
              {...other}
            />
          ))}
          <div className={classes.bottomContent}>
            <VoucherMerchantList />
            <div>
              <p className="basic fs-14 fw-700 mb-3">Knowledge Center</p>
              <img
                id="knowledge-center"
                onClick={() => history.push("/knowledge-center")}
                width="100%"
                className="is-link"
                src={ilustration.knowledge}
                alt=""
              />
            </div>
            <div>
              <p className="basic fs-14 fw-700 mb-3">Komunitas</p>
              <img
                id="komunitas"
                onClick={() => history.push("/coming-soon")}
                width="100%"
                className="is-link"
                src={ilustration.comunity}
                alt=""
              />
            </div>
          </div>
        </div>

        {/* <SnackAlert name="dashboard" /> */}
        {dialogs.map((item, index) => (
          <DialogAlert key={index} {...item} />
        ))}
        <DialogUpdateMotherName />
        {/* <DialogAlert resultName="dashboard" buttonText="OK" /> */}
      </TempMain>
    );
  }
}

const useStyles = (theme) => ({
  bottomContent: {
    display: "grid",
    gridTemplateAreas: `
    "voucher voucher"
    "knowledge komunitas"
    `,
    gridTemplateColumns: "calc(50% - 15px) 1fr",
    gridTemplateRows: "1fr",
    gap: 30,
    "&>div:nth-child(1)": {
      gridArea: "voucher",
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    "&>div:nth-child(2)": {
      gridArea: "knowledge",
    },
    "&>div:nth-child(3)": {
      gridArea: "komunitas",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateAreas: `
      "knowledge knowledge"
      "voucher voucher"
      "komunitas komunitas"
      `,
    },
  },
});

export default compose(
  connect(mapState, {
    set_user,
    top_product,
    // set_article,
    set_menu_props,
    set_trending_props,
    dash_lc,
    gbl_act_lc,
    gbl_list_lc,
  }),
  withRouter,
  withStyles(useStyles)
)(MainPage);
