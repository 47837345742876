import React from "react";
import ContentFileAutoDebet from "../../organism/ContentFileAutoDebet";
import { useLocation } from "react-router-dom";

const StopAutoDebetFile = () => {
  const { state } = useLocation();
  return (
    <ContentFileAutoDebet
      title="PEMBERHENTIAN PENDEBETAN"
      data={state?.map(
        ({
          payment_logo,
          product_info: { fr_product_name } = {},
          documents: { document_cancel } = {},
        }) => ({
          icon: payment_logo,
          name: fr_product_name,
          path: document_cancel,
        })
      )}
      isCancel
    />
  );
};

export default StopAutoDebetFile;
