import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import stateCallApi from "../../config/API/stateCallApi";
import { gbl_act_lc } from "../../config/redux/actions/state";
import processHelper from "../../helper/processHelper";
import BasicText from "../atom/BasicText/BasicText";
import DialogAlert from "../molecule/Alert/DialogAlert";
import PaginationDot from "../molecule/PaginationDot/PaginationDot";
import CardDocument from "../molecule/Profile/CardDocument";
import TempMain from "../template/TempMain";
const { memo, useCallback, useEffect, useMemo, useState } = React;

const SkeletonDocument = () => (
  <>
    {Array(8)
      .fill("")
      .map((_, index) => (
        <Grid item xs={6} key={`skeleton-document-${index}`}>
          <Skeleton style={{ borderRadius: 8 }} height={74} />
        </Grid>
      ))}
  </>
);

const EmptyData = React.memo(() => {
  return (
    <div className="text-center w-100 my-5 cont-center" style={{ minHeight: 210 }}>
      <div>
        <BasicText className="fs-14 font-weight-bold color-secondary mb-2">
          Data Tidak Ditemukan
        </BasicText>
        <BasicText className="fs-14 text-center">
          Yuk investasi sekarang!
        </BasicText>
      </div>
    </div>
  );
}, () => true)

const DocumentPage = (props) => {
  const dispatch = useDispatch();
  const dataDocument = useSelector((state) => state.profileReducer.document_report.data)
  const loading = useSelector((state) =>
    processHelper.findProcess(
      ["document_report"],
      state.stateReducer.loading_stack
    )
  );

  useEffect(() => {
    if (!dataDocument?.length) dispatch(gbl_act_lc(stateCallApi.document_report))
  }, [dataDocument, dispatch]);

  const handleDocument = useCallback((item) => {
    const { url } = item
    window.open(url, "_self");
  }, []);

  return (
    <TempMain
      title="PROFIL"
      backgroundColor="white"
      showBottom={false}
      bottomNavValue={4}
      page="profile"
      data={{ menuValue: 3 }}
    >
      <div style={{ minHeight: "85%" }} className="d-flex flex-column flex">
        <p className="font-weight-bold basic color-primary fs-14 text-uppercase">
          DOCUMENT
        </p>
        <pre style={{ marginTop: 28 }} className="basic fs-14 w-65 mb-0">
          Lihat dan download dokumen setifikat dan autodebit kamu di Syailendra Capital.
        </pre>
        <Content
          data={dataDocument || []}
          loading={loading}
          once={Boolean(dataDocument?.length)}
          onClickItem={handleDocument}
        />
      </div>
    </TempMain>
  );
};

const Content = memo(({ data, loading, once, onClickItem }) => {
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (data.length) setPage(0)
  }, [data.length])

  const { pages, first, last } = useMemo(
    () => ({
      pages: Math.ceil(data.length / 8),
      first: 8 * page,
      last: 8 * page + 7,
    }),
    [data, page]
  );
  return (
    <>
      <div className="flex">
        <Grid
          container
          className="mt-4"
          alignItems="center"
          spacing={2}
          item
          lg={10}
          xs={12}
        >
          {loading || !once ? (
            <SkeletonDocument />
          ) : data.length > 0 ? (
            data
              .filter((_, index) => index >= first && index <= last)
              .map((item, index) => (
                <Grid item xs={6} key={`document-${index}`}>
                  <CardDocument
                    id={item?.title?.replace(/ /g,"-").toLowerCase() ?? item}
                    item={item}
                    onClick={() => onClickItem(item)}
                  />
                </Grid>
              ))
          ) : (
            <EmptyData />
          )}
        </Grid>
        {pages > 1 && (
          <Grid item xs={12} lg={10} className="mt-4 pt-3">
            <PaginationDot page={page} pages={pages} onChange={setPage} />
          </Grid>
        )}
        <DialogAlert resultName="document_report" alertAppear="error" />
      </div>
    </>
  );
});

export default DocumentPage;
