import { Tooltip } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import productImg from "../../../assets/img/icon/product";
import color from "../../../assets/theme/color";
import textHelper from "../../../helper/textHelper";
import CardProductCustom from "../../molecule/Product/CardProductCustom/CardProductCustom";
import { useHistory } from "react-router-dom";

const RowResult = ({ title, appendTitle, value, classes = {} }) => {
  return (
    <Grid
      container
      className={classNames("mb-2", classes.container)}
      alignItems="center"
    >
      <Grid item xs={6}>
        <p className={classNames(classes.title, "basic fs-12")}>{title}</p>
        {appendTitle}
      </Grid>
      <Grid item xs={6}>
        <p className={classNames(classes.value, "basic fs-14 text-right")}>
          {textHelper.printMoney(value)}
        </p>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "1.5rem",
    "&.order": {
      [theme.breakpoints.up(768)]: {
        marginBottom: 0,
      },
    },
    "&.invoice": {
      flexDirection: "column",
      [theme.breakpoints.up(768)]: {
        flexDirection: "row",
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
    },
    "& .border-prod-summ": {
      borderTop: "2px dotted " + theme.palette.secondary.main,
    },
    "& .summary-result": {
      "&.invoice": {
        marginTop: 21,
      },
      [theme.breakpoints.up("md")]: {
        maxWidth: 290,
      },
    },
    "& .name-prod": {},
  },
  productInvoice: {
    "& .content-name-product>hr": {
      display: "none",
    },
  },
  borderName: {
    borderBottom: `1px dotted ${color.secondary}`,
    paddingBottom: "10px",
    marginBottom: "10px",
  },
}));

const OrderResult = ({
  value,
  fee,
  detail,
  mode,
  radius,
  cashback,
  product,
  className,
}) => {
  const { push } = useHistory();
  const [tool, setTool] = React.useState(false);
  const classes = useStyles();
  const isInvoice = mode === "invoice";
  const handleTool = () => {
    if (!tool) {
      setTool(true);
      setTimeout(() => setTool(false), 2000);
    }
  };

  const handleDetail = useCallback(
    () => push(`/detail-produk?product=${product?.code}`),
    [product, push]
  );

  return (
    <Grid
      style={{ borderRadius: radius }}
      className={classNames(
        "card-custom shadow-card",
        classes.root,
        mode,
        className
      )}
    >
      {isInvoice && (
        <CardProductCustom
          items={product}
          className={classes.productInvoice}
          onClick={handleDetail}
          composition={[
            { render: "name", md: 6, className: "" },
            { render: "summary", md: 5 },
          ]}
        />
      )}

      {isInvoice && <hr className="border-prod-summ" />}
      <Grid container className={`align-items-end summary-result ${mode}`}>
        <Grid item xs={12} md={isInvoice ? 10 : 12}>
          <RowResult title="Total Pembelian" value={value} />
          <RowResult title="Total Biaya" value={fee} />
          {(isInvoice && cashback && (
            <RowResult
              appendTitle={
                <img
                  alt="cashback"
                  style={{ width: 16 }}
                  src={productImg.cashback}
                  className="mx-2 d-inline"
                />
              }
              title="Cash Back"
              classes={{ title: "d-inline" }}
              value={cashback}
            />
          )) ||
            ""}

          <RowResult
            title="Total Pembayaran"
            value={Number(fee + value)}
            classes={{
              container: "mt-4 mb-0",
              title: "font-weight-bold",
              value: "font-weight-semibold color-primary",
            }}
          />
        </Grid>
        {isInvoice && (
          <Grid
            item
            xs={12}
            md={2}
            className="text-right"
            style={{ paddingBottom: 6 }}
          >
            {detail && (
              <CopyToClipboard text={value} onCopy={handleTool}>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  open={tool}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title="sudah disalin"
                  arrow
                >
                  <span className="color-primary text-underline fs-12 is-link">
                    SALIN
                  </span>
                </Tooltip>
              </CopyToClipboard>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

OrderResult.propTypes = {
  value: PropTypes.any,
  fee: PropTypes.any,
  product_name: PropTypes.string,
  detail: PropTypes.bool,
  handleCheck: PropTypes.func,
  mode: PropTypes.string,
};

OrderResult.defaultProps = {
  value: 0,
  fee: 0,
  detail: true,
  handleCheck: () => null,
  mode: "order",
};

export default OrderResult;
