import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { get_voucher_detail } from "../../../config/redux/actions/voucher";
import useDataResult from "../../../hooks/useDataResult";

export default function (id) {
    const name = "get_voucher_detail-" + id;
    const dispatch = useDispatch();
    const data = useDataResult(name);

    const getData = useCallback(() => dispatch(
        get_voucher_detail({
            params: { id },
        })
    ), [dispatch, id])

    return { data, getData, name }
}