import React from "react";
import DialogCustom from "../../Container/DialogCustom";
import { makeStyles, Grid } from "@material-ui/core";
import ButtonContained from "../../../atom/Button/ButtonContained";
import classNames from "classnames";
const { forwardRef, useState, useImperativeHandle, useCallback } = React;

const useStyles = makeStyles((theme) => ({
  bubleFilter: {
    border: "1px solid " + theme.palette.secondary[4],
    padding: "6px 0",
    borderRadius: 14,
    display: "grid",
    placeItems: "center",
    "& p": { fontSize: 10 },
    "&.active": {
      backgroundColor: theme.palette.secondary[7],
    },
  },
}));

const filterTxnList = [
  {
    title: "Transaksi",
    key: "txn_type_code",
    data: [
      { title: "Semua", key: undefined },
      { title: "Pembelian", key: "S" },
      { title: "Penjualan", key: "R" },
      { title: "Konversi", key: "D" },
    ],
  },
  {
    title: "Status",
    key: "txn_status_code",
    data: [
      { title: "Semua", key: undefined },
      { title: "Berhasil", key: "APPROVED" },
      { title: "Pending", key: "WAITING" },
      { title: "Gagal", key: "DECLINED" },
    ],
  },
  {
    title: "Periode",
    key: "period",
    data: [
      { title: "1 Tahun", key: "1y" },
      { title: "6 Bulan", key: "6m" },
      { title: "3 Bulan", key: "3m" },
      { title: "1 Bulan", key: "1m" },
    ],
  },
];

const DialogFilterTxn = forwardRef(({ defaultValue, onSubmit }, ref) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [state, setState] = useState(defaultValue);

  const onOpen = useCallback(() => setOpen((prev) => !prev), []);

  const handleFilter = useCallback(
    (val, key) => setState((prev) => ({ ...prev, [key]: val })),
    []
  );

  const handleSubmit = useCallback(() => {
    onSubmit(state);
    onOpen();
  }, [onOpen, onSubmit, state]);

  useImperativeHandle(ref, () => ({ onOpen }));

  return (
    <DialogCustom
      title="Filter"
      open={open}
      showCloseButton
      handleClose={onOpen}
      dialogProps={{ classes: { paper: "m-1 w-100" } }}
      content={
        <div>
          {filterTxnList.map(({ title, key, data }) => (
            <div key={key} className="mb-3">
              <p className="basic fs-10 mb-2">{title}</p>
              <Grid container spacing={1}>
                {data.map((item, index) => (
                  <Grid item xs={3} key={item.key || index}>
                    <div
                      className={classNames(
                        classes.bubleFilter,
                        state[key] === item.key && "active"
                      )}
                      onClick={() => handleFilter(item.key, key)}
                    >
                      <p className="basic">{item.title}</p>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>
          ))}
          <div className="cont-center my-4">
            <ButtonContained
              text="Tampilkan"
              radius="6px"
              onClick={handleSubmit}
            />
          </div>
        </div>
      }
    ></DialogCustom>
  );
});

DialogFilterTxn.defaultProps = {
  defaultValue: {
    txn_type_code: undefined,
    txn_status_code: undefined,
    period: undefined,
  },
};

export default DialogFilterTxn;
