import React, { PureComponent } from "react";
import TempAuth from "../../template/TempAuth";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  regis_validate_otp,
  regis_request_otp,
} from "../../../config/redux/actions/auth";
import InputRegis2 from "../../organism/Register/InputRegis2";
import DialogAlert from "../../molecule/Alert/DialogAlert";
import { set_app_config } from "../../../config/redux/actions/option";
import DialogOTPSelection from "../../molecule/Dialog/DialogOTPSelection";
import { codeDstOtp } from "../../../helper/textHelper";

const dialogs = [
  { resultName: "r_request_otp", alertAppear: "both" },
  { resultName: "r_validate_otp", alertAppear: "both" },
];

class Regis2 extends PureComponent {
  constructor(q) {
    super(q);
    this.state = {
      modal: false,
    };
    this.data = {};
  }
  componentDidMount = () => {
    this.props.set_app_config({ params: { with_option: 1 } });
  };

  _handleModal = (modal) => this.setState({ modal });

  _onClickItem = (otp_dst) =>
    this.props.regis_request_otp({ ...this.data, otp_dst });

  _handleReSend = (callback, changeMethod) => {
    const {
      register,
      regis_request_otp,
      otp_token: { otp_dst },
    } = this.props;
    const { no_hp, referal } = register;
    const _res = { phone_number: no_hp, callback, otp_dst };
    if (referal) _res.referral_code = referal;
    this.data = _res;
    if (changeMethod) this._handleModal(true);
    else regis_request_otp(_res);
  };
  render() {
    const {
      regis_validate_otp,
      loading_stack,
      otp_token: { otp_token, otp_dst } = {},
      register,
    } = this.props;
    const { modal } = this.state;
    return (
      <TempAuth
        page="regisInputPhone"
        data={{
          title: "REGISTRASI",
          contentStyle: { width: "55%" },
          ilustration: {
            image: "regisValidateOtp",
            title: "Masukkan Kode OTP",
            subTitle: `KODE OTP DIKIRIM MELALUI ${codeDstOtp(
              otp_dst
            ).toUpperCase()} KE NOMOR ${register?.no_hp ?? "HP ANDA"}`,
          },
        }}
      >
        <InputRegis2
          resend={this._handleReSend}
          onSubmit={regis_validate_otp}
          loading={loading_stack}
          data={{ otp_token, registration_token: register.registration_token }}
        />
        {dialogs.map((item, index) => (
          <DialogAlert {...item} key={index} buttonText="OK" />
        ))}
        <DialogOTPSelection
          modal={modal}
          onClose={this._handleModal}
          onClickItem={this._onClickItem}
          number={register?.no_hp}
        />
      </TempAuth>
    );
  }
}

const mapStateToProps = ({ authReducer, stateReducer }) => ({
  register: authReducer.register,
  otp_token: authReducer.otp_token,
  loading_stack: stateReducer.loading_stack,
});

export default compose(
  connect(mapStateToProps, {
    regis_validate_otp,
    regis_request_otp,
    set_app_config,
  }),
  withRouter
)(Regis2);
