import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React from "react";
import { codeDstOtp } from "../../../helper/textHelper";
import SnackAlert from "../../molecule/Alert/SnackAlert";
import DialogHeader from "../../molecule/Header/DialogHeader";
import IlustrasiRegis1 from "../../molecule/Register/IlustrasiRegis1";
import FormOtp from "../../organism/Content/FormOtp";
import TempDialogFull from "../../template/TempDialogFull";

const useStyles = makeStyles((theme) => ({
  paperDialog: {
    width: "100%",
    maxWidth: 500,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 58,
  },
  content: {
    maxWidth: 250,
    alignSelf: "center",
  },
}));

const DialogOtpInput = (props) => {
  const classes = useStyles();
  const {
    modal,
    onClose,
    onSubmit,
    dataSubmit,
    processName,
    phoneNumber,
    onResend,
    otpDst,
  } = props;

  const handleSubmit = (otp_code) => {
    let tmp = { otp_code };
    if (dataSubmit) {
      tmp = { ...tmp, ...dataSubmit };
    }
    onSubmit(tmp);
  };
  return (
    <TempDialogFull
      modal={modal}
      onClose={onClose}
      paperClass={classes.paperDialog}
    >
      <div className={classes.container}>
        <DialogHeader
          title="KONFIRMASI OTP"
          onClickClose={() => onClose(false)}
        />
        <div className={classes.content}>
          <IlustrasiRegis1
            image="otp"
            title="Masukkan Kode OTP"
            subTitle={`KODE OTP DIKIRIM MELALUI ${codeDstOtp(
              otpDst
            ).toUpperCase()} KE NOMOR ${phoneNumber || "HP ANDA"}`}
          />
          <FormOtp
            onResend={onResend}
            onSubmit={handleSubmit}
            id="change-num"
            loading={processName}
          />
        </div>
      </div>
      <SnackAlert name="resend_otp" />
    </TempDialogFull>
  );
};

DialogOtpInput.propTypes = {
  modal: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  dataSubmit: PropTypes.object,
  processName: PropTypes.string,
  phoneNumber: PropTypes.any,
};

export default DialogOtpInput;
