import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import color from "../../../assets/theme/color";
import ButtonOutline from "../../atom/Button/ButtonOutline";
import { Grid } from "@material-ui/core";
import ButtonContained from "../../atom/Button/ButtonContained";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import BasicText from "../../atom/BasicText/BasicText";
import textHelper from "../../../helper/textHelper";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import processHelper from "../../../helper/processHelper";
import { Skeleton } from "@material-ui/lab";
import classNames from "classnames";

const mapState = state => {
  return {
    summary: state.portoReducer.summary,
    isLogin: state.authReducer.isLogin,
    loading_stack: state.stateReducer.loading_stack
  };
};

const useStyles = {
  root: {
    width: "100%",
    backgroundColor: color.primary,
    transition: "all 0.5s",
    overflow: "hidden"
  },
  show: {
    height: "180px"
  },
  hide: {
    height: "66px"
  },
  container: {
    height: "186px",
    width: "100%"
  },
  loader: {
    backgroundColor: "rgba(255,255,255,0.3)"
  }
};

class TopSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true
    };
  }
  linkTo = e => {
    if (e === "login") this.props.history.push("/masuk");
    else if (e === "porto") this.props.history.push("/portofolio");
  };
  showHide = () => {
    this.setState({ show: !this.state.show });
  };
  render() {
    const { classes, isLogin, summary, loading_stack } = this.props;
    const { show } = this.state;
    let loading = processHelper.findProcess("user_dashboard", loading_stack);
    return (
      <div
        className={classNames(classes.root, show ? classes.show : classes.hide)}
      >
        <div className={classes.container}>
          {isLogin === 0 ? (
            <Grid
              container
              className="px-lg-5 px-md-4 h-inherit"
              alignItems="center"
            >
              <Grid item xs className="text-center">
                <ButtonOutline
                  text="REGISTRASI"
                  borderColor="common"
                  className="px-4"
                  radius="5px"
                />
              </Grid>
              <Grid item xs className="text-center">
                <ButtonContained
                  text="LOGIN "
                  className="my-auto px-5 text-white"
                  backgroundColor="#fff"
                  transparent={true}
                  onClick={() => this.linkTo("login")}
                  radius="5px"
                />
              </Grid>
            </Grid>
          ) : (
            <div className="px-lg-5 px-md-4 px-4 h-inherit d-flex align-items-center">
              <div className="w-100">
                <div className="w-100 text-right mb-3">
                  <ButtonContained
                    text={show ? "HIDE" : "SHOW"}
                    className="fs-11 py-1 text-white"
                    radius="20px"
                    transparent={true}
                    Icon={show ? KeyboardArrowUpIcon : KeyboardArrowDownIcon}
                    backgroundColor="#ffffff"
                    onClick={this.showHide}
                  />
                </div>
                <BasicText className="fs-10 mb-1 text-white">
                  TOTAL BALANCE
                </BasicText>
                {loading ? (
                  <Skeleton
                    variant="text"
                    height={30}
                    classes={{ root: classes.loader }}
                    className="mb-3"
                  />
                ) : (
                  <BasicText className="fs-22 mb-3 font-weight-medium text-white">
                    {summary ? textHelper.printMoney(summary.value) : ""}
                  </BasicText>
                )}
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item xs={5}>
                    <BasicText className="fs-10 mb-1 text-white">
                      TOTAL EARNINGS
                    </BasicText>
                    {loading ? (
                      <Skeleton
                        variant="text"
                        classes={{ root: classes.loader }}
                      />
                    ) : (
                      <BasicText className="fs-14 font-weight-medium text-white">
                        {summary ? textHelper.printMoney(summary.gain) : ""}
                      </BasicText>
                    )}
                  </Grid>
                  <Grid item xs={3} className="text-center">
                    <BasicText className="mb-1 text-white fs-10">
                      RETURN
                    </BasicText>
                    {loading ? (
                      <Skeleton
                        variant="text"
                        classes={{ root: classes.loader }}
                      />
                    ) : (
                      <BasicText className="fs-14 font-weight-medium text-white">
                        {summary
                          ? textHelper.printPercent(summary.gain_percent)
                          : ""}
                      </BasicText>
                    )}
                  </Grid>
                  <Grid item xs={4} className="mt-auto text-right">
                    <p
                      className="fs-10 text-underline text-white mb-0"
                      onClick={() => this.linkTo("porto")}
                    >
                      LIHAT DETAIL
                    </p>
                  </Grid>
                </Grid>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default compose(
  connect(mapState),
  withRouter,
  withStyles(useStyles)
)(TopSummary);
