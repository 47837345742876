import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import TableFooter from "@material-ui/core/TableFooter";
import PortoTabelMenu from "../../molecule/Porto/PortoTabelMenu";
import textHelper from "../../../helper/textHelper";
import BasicText from "../../atom/BasicText/BasicText";
import illustration from "../../../assets/img/ilustration";
import { useSelector } from "react-redux";
import processHelper from "../../../helper/processHelper";
const StyledCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontSize: 13,
    padding: "8px!important",
    fontWeight: 600,
    lineHeight: "1rem",
  },
  body: {
    fontSize: 12,
    color: theme.palette.secondary.main,
  },
}))(TableCell);

const PortoTabel = (props) => {
  const { row, porto_detail_props, statement_props } = props;
  const loading = useSelector((state) =>
    processHelper.findProcess("porto_detail", state.stateReducer.loading_stack)
  );
  const [state, setState] = React.useState({
    date: textHelper.printDateSubtractFormat(undefined, 1, "months", "YYYY-MM"),
    dataValue: "",
    page: 0,
    rowPerPage: 5,
    once_state: true,
  });
  const { page, rowPerPage, date, once_state } = state;
  const handleChangePage = (event, newPage) => {
    setState((state) => ({ ...state, page: newPage }));
  };
  const handleChangeRowPerPage = (e) => {
    setState((state) => ({ ...state, rowPerPage: +e.target.value, page: 0 }));
  };
  const handleChange = (key, value) => {
    setState((state) => ({ ...state, [key]: value }));
    if (key === "date") {
      porto_detail_props(value);
    }
  };
  const handleDownload = () => {
    statement_props(date);
  };
  React.useEffect(() => {
    if (row.length > 0 && once_state) {
      setState((state) => ({ ...state, once_state: false }));
    }
  }, [row, once_state]);
  return (
    <div className="mt-4">
      <PortoTabelMenu
        onChange={handleChange}
        date={date}
        onDownload={handleDownload}
      />
      <TableContainer className="card-custom p-0 mb-4">
        <Table>
          <TableHead>
            <TableRow>
              <StyledCell align="center" rowSpan={2}>
                Tanggal <br /> Transaksi
              </StyledCell>
              <StyledCell align="center" rowSpan={2}>
                Produk
              </StyledCell>
              <StyledCell align="center" rowSpan={2}>
                Transaksi
              </StyledCell>
              <StyledCell align="center" rowSpan={2}>
                Unit
              </StyledCell>
              <StyledCell align="center" rowSpan={2}>
                Saldo Unit
              </StyledCell>
              <StyledCell align="center" rowSpan={2}>
                NAB/Unit
              </StyledCell>
              <StyledCell align="center" rowSpan={2}>
                Nilai <br /> Investasi
              </StyledCell>
              <StyledCell align="center" colSpan={2}>
                Hasil Investasi
              </StyledCell>
            </TableRow>
            <TableRow>
              <StyledCell align="center">
                Realized <br /> Gain/Loss
              </StyledCell>
              <StyledCell align="center">
                Unrealized <br /> Gain/Loss
              </StyledCell>
            </TableRow>
          </TableHead>
          <TableBody className="px-2">
            {loading ? (
              <TableRow>
                <StyledCell colSpan={9}>
                  <p className="basic color-gray-4 text-center fs-14 font-weight-semibold py-2">
                    ...loading
                  </p>
                </StyledCell>
              </TableRow>
            ) : row.length > 0 ? (
              row
                .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                .map((el, id) => (
                  <TableRow key={id}>
                    <StyledCell className="text-center">
                      {textHelper.printDate(el.date, "DD MMM YYYY")}
                    </StyledCell>
                    <StyledCell style={{ maxWidth: 150 }}>
                      {el.product}
                    </StyledCell>
                    <StyledCell className="text-center">
                      {el.txntype}
                    </StyledCell>
                    <StyledCell>{textHelper.printUnits(el.unit)}</StyledCell>
                    <StyledCell>
                      {textHelper.printUnits(el.saldounit)}
                    </StyledCell>
                    <StyledCell>
                      {textHelper.printMoneyWithoutRp(el.nav)}
                    </StyledCell>
                    <StyledCell>
                      {textHelper.printMoneyWithoutRp(el.invest)}
                    </StyledCell>
                    <StyledCell className="text-center">
                      {textHelper.printMoneyWithoutRp(el.realized)}
                    </StyledCell>
                    <StyledCell className="text-center">
                      {textHelper.printMoneyWithoutRp(el.unrealized)}
                    </StyledCell>
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <StyledCell colSpan={9} className="text-center py-3">
                  <img
                    src={illustration["emptyporto"]}
                    alt=""
                    className="mb-4"
                  />
                  <BasicText className="fs-14 font-weight-bold mb-2">
                    Tidak ada data
                  </BasicText>
                </StyledCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                id="pagination-btn"
                rowsPerPageOptions={[5, 10]}
                count={row.length}
                rowsPerPage={rowPerPage}
                page={page}
                labelRowsPerPage="Baris Per Halaman"
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PortoTabel;
