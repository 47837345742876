import React from "react";
import { makeStyles } from "@material-ui/core";
import ContainerDesktop from "../../organism/Container/ContainerDesktop";
import CardPortoMain from "../../organism/MainPage/CardPortoMain";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  mainMenu: {
    backgroundColor: "#fff",
    width: "100%",
    padding: "5px 35px",
    borderRadius: 4,
  },
}));

const TempDashboardDesktop = (props) => {
  const { children, data } = props;
  const {
    // isLogin,
    mainMenu,
    topReksa,
    trendingScroll,
    userGoal,
    // articleScroll,
    // knowledgeLink
  } = data;
  const classes = useStyles();
  return (
    <ContainerDesktop
      left={
        <div className="pt-4 px-4">
          {userGoal && <div className="mb-3">{userGoal}</div>}
          <div className={classNames(classes.mainMenu, "shadow-card")}>
            {mainMenu}
          </div>
          <div className="my-3">
            {topReksa}
            {trendingScroll}
          </div>
          {/* <div className="mb-3">{articleScroll}</div> */}
          {/* <div>{knowledgeLink}</div> */}
          {children}
        </div>
      }
      right={
        <div className="pt-lg-4 px-4">
          <CardPortoMain />
        </div>
      }
    />
  );
};

export default TempDashboardDesktop;
