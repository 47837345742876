import React from "react";
// import PropTypes from "prop-types";
import OtpInput from "../../organism/Otp/OtpInput";
import { connect } from "react-redux";
import {
  request_otp_props,
  validate_otp_props,
  resend_otp_props
} from "../../../config/redux/actions/auth";
import SnackAlert from "../../molecule/Alert/SnackAlert";
import TempMain from "../../template/TempMain";

class OtpInputPage extends React.Component {
  componentDidMount() {
    this.props.request_otp_props("change-password");
  }
  handleSubmit = e => {
    this.props.validate_otp_props(e);
  };
  handleResend = () => {
    this.props.resend_otp_props();
  };
  render() {
    return (
      <TempMain
        showBottom={false}
        backgroundColor="#fff"
        title="KONFIRMASI OTP"
        data={{ img: "otp" }}
      >
        <OtpInput
          onSubmit={this.handleSubmit}
          onResendClick={this.handleResend}
        />
        <SnackAlert
          name={["request_otp", "validate_otp", "resend_otp"]}
          alertAppear="both"
        />
      </TempMain>
    );
  }
}

OtpInputPage.propTypes = {};

export default connect(null, {
  request_otp_props,
  validate_otp_props,
  resend_otp_props
})(OtpInputPage);
