import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import color from "../../assets/theme/color";
import { chart_color_props } from "../../config/redux/actions/option";
import {
  clear_product_history_props,
  product_history_props,
  set_product,
} from "../../config/redux/actions/product";
import { isBookPeriodRDP, isOfferPeriodRDP } from "../../helper/RDPhelper";
import textHelper from "../../helper/textHelper";

import ButtonOutline from "../atom/Button/ButtonOutline";
import PerformTable from "../molecule/Product/PerformTable";
import PolicyAndGoals from "../molecule/Product/PolicyAndGoals";
import ProductDetailInfo from "../molecule/Product/ProductDetailInfo";
import ProductGraph from "../molecule/Product/ProductGraph";
import DialogCustom from "../organism/Container/DialogCustom";
import ProductAllocation from "../organism/DetailProduct/ProductAllocation";
import ProductTitleAndRisk from "../organism/DetailProduct/ProductTitleAndRisk";
import ThreeButtonDetail from "../organism/ProductPage/ThreeButtonDetail";
import TempMain from "../template/TempMain";

const modalAlerts = [
  {
    title: "Masuk",
    text: "Mohon masuk terlebih dahulu untuk melanjutkan proses ini.",
    buttonText: "MASUK SEKARANG",
  },
  {
    title: "SUSPEND INFO",
    text: "Mohon maaf untuk semetara waktu transaksi pembelian produk ini tidak dapat dilakukan karena terdapat pembatasan nilai penawaran unit penyertaan",
    buttonText: "MENGERTI",
  },
];

class DetailProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      productData: {},
      modalAlert: {},
      modalTnCProduct: false,
    };
  }
  componentDidMount() {
    const {
      product,
      location,
      set_product,
      product_history_props,
      chart_color_props,
    } = this.props;
    const { search } = location;
    window.scrollTo(0, 0);
    const arr = textHelper.parseParams(search);
    product_history_props(arr[0].product);
    chart_color_props();
    if (product.length > 0) {
      this.initialData(product);
    } else {
      set_product();
    }
  }
  componentDidUpdate(prevProps) {
    const { product } = this.props;
    if (prevProps.product !== product && product.length > 0) {
      this.initialData(product);
    }
  }
  componentWillUnmount() {
    this.props.clear_product_history_props();
  }
  initialData = (product) => {
    const { location } = this.props;
    const { search } = location;
    const arr = textHelper.parseParams(search);
    for (let i = 0; i < product.length; i++) {
      if (product[i].code === arr[0].product) {
        this.setState({ productData: product[i] });
        if (product[i].product_popup !== null)
          this.setState({ modalTnCProduct: true });
        break;
      }
    }
  };
  _checkFile = (obj) => {
    const { path } = obj || {};
    if (path) window.open(path, "_blank");
    else alert("File belum tersedia");
  };

  downloadFile = (e) => {
    const { product_file, product_ffs } = this.state.productData;
    if (e === "file") {
      this._checkFile(product_file);
    } else {
      this._checkFile(product_ffs);
    }
  };
  handleClick = (e) => {
    const { productData } = this.state;
    const { push, goBack } = this.props.history;
    switch (e) {
      case "/subscript":
        if (this.props.isLogin === 1) {
          if (productData.is_suspend) {
            this.handleModal(true, 1);
          } else
            push(e, {
              products: { [productData.code]: { amount: 100, type: "RG" } },
            });
        } else {
          this.handleModal(true);
        }
        return;
      case "back":
        return goBack();
      case "/book":
        push("/book", {
          products: { [productData.code]: { amount: 100 } },
        });
        return;
      default:
        return push(e + "?product=" + productData.code + "&type=RG");
    }
  };
  handleModal = (e, index = 0) => {
    this.setState({ modal: e, modalAlert: e ? modalAlerts[index] : null });
  };
  linkTo = () => {
    if (this.state.modalAlert.title === "SUSPEND INFO") this.handleModal(false);
    else this.props.history.replace("/masuk");
  };

  buttonMap = () => {
    const { is_rdp, product_rdp, can_subscript } = this.state.productData;
    if (is_rdp) {
      const isBookPeriod = isBookPeriodRDP(product_rdp);
      const isOfferPeriod = isOfferPeriodRDP(product_rdp);
      return [
        {
          text: "BOOKING",
          backgroundColor: color.primary,
          disabled: !(can_subscript && isBookPeriod),
          onClick: "/book",
        },
        { like: "beli", disabled: !(can_subscript && isOfferPeriod) },
        { like: "simulasi", backgroundColor: color.gray_4 },
      ];
    }
    return [
      "beli",
      { like: "simulasi", backgroundColor: color.gray_4 },
      "kembali",
    ];
  };

  render() {
    const { product_history, chart_color } = this.props;
    const { productData } = this.state;
    const { modal, modalAlert } = this.state;
    const data = {
      titleAndRisk: <ProductTitleAndRisk product={productData} />,
      perform: <PerformTable product={productData} />,
      allocation: Object.keys(productData).length > 0 && (
        <ProductAllocation
          product={productData}
          nab={productData.nab}
          chartColor={chart_color}
        />
      ),
      graph: Object.keys(productData).length > 0 && (
        <ProductGraph
          id="product_graph"
          history={product_history}
          product={productData}
          width="100%"
          height={158}
        />
      ),
      goals: <PolicyAndGoals product={productData} />,
      detailInfo: <ProductDetailInfo product={productData} />,
      twoButton: Object.keys(productData).length > 0 && (
        <div className="my-4 d-flex justify-content-start">
          <ButtonOutline
            id="prospektus"
            borderColor="secondary"
            radius="5px"
            className="fs-10 p-2 mr-2"
            text="PROSPEKTUS"
            onClick={() => this.downloadFile("file")}
          />
          <ButtonOutline
            id="ffs"
            borderColor="secondary"
            radius="5px"
            className="fs-10 p-2 ml-2"
            text="FUND FACT SHEET"
            onClick={() => this.downloadFile("ffs")}
          />
        </div>
      ),
      threeButton: Object.keys(productData).length > 0 && (
        <ThreeButtonDetail
          onClick={this.handleClick}
          code={productData?.display_code}
          is_suspend={productData.is_suspend}
          className="my-5"
          temp={this.buttonMap()}
        />
      ),
    };
    return (
      <TempMain
        title="PRODUK DETAIL"
        bottomNavValue={1}
        page="detailProduct"
        data={data}
      >
        <DialogCustom
          open={modal}
          handleClose={this.handleModal}
          {...modalAlert}
          onClick={this.linkTo}
        />
      </TempMain>
    );
  }
}

const mapState = (state) => ({
  product_history: state.productReducer.history,
  isLogin: state.authReducer.isLogin,
  product: state.productReducer.product,
  chart_color: state.optionReducer.chart_color,
});

export default compose(
  connect(mapState, {
    product_history_props,
    set_product,
    clear_product_history_props,
    chart_color_props,
  }),
  withRouter
)(DetailProduct);
