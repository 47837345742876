export default {
  primary: "#EF6431",
  primaryDark: "#4F2110",
  secondary: "#666666",
  secondaryLight: "#999999",
  secondaryLighter: "#b2b2b2",
  gray_1: "#333333",
  gray_2: "#4c4c4c",
  gray_3: "#666666",
  gray_4: "#999999",
  gray_5: "#b2b2b2",
  gray_6: "#cccccc",
  gray_7: "#e5e5e5",
  gray_8: "#f2f2f2",
  blue_1: "#005E83",
  blue_2: "#337E9C",
  blue_3: "#669EB5",
  blue_4: "#99BFCD",
  blue_5: "#B2CFDA",
  blue_6: "#CCDFE6",
  blue_7: "#E5EFF3",
  blue_8: "#EDF4F6",
  blue_9: "#F8FAFB",
  orange_1: "#EF6431",
  orange_2: "#F2835A",
  orange_3: "#F5A283",
  orange_4: "#F7B198",
  orange_5: "#F9C1AD",
  orange_6: "#FAD0C1",
  orange_7: "#FCE0D6",
  orange_8: "#FDEFEA",
  bg_disable_input: 'rgba(0,0,0,0.03)'
};
