import { createLogic } from "redux-logic";
import * as types from "../actions/types";
import {
  product_list,
  top_product_list,
  set_trending,
  product_type,
  product_history,
  clear_product_history,
} from "../actions/product";
import { set_loading, remove_loading, set_result } from "../actions/state";
import { requestAxios, requestValidate } from "../../API/request";
import url from "../../API/url";

export const products = createLogic({
  type: types.SET_PRODUCT,
  latest: true,
  validate({ getState, action }, allow, reject) {
    const { product } = getState().productReducer;
    if (product.length === 0) {
      allow(action);
    } else {
      reject(action);
    }
  },
  async process({ action }, dispatch, done) {
    dispatch(set_loading("product_list"));
    await requestAxios(url.PRODUCT.products, action.payload)
      .then((e) => {
        const { data } = e.data;
        // console.log(action.payload);
        if (action.payload) {
          dispatch(product_list(e.data));
        } else {
          let tmp = [];
          Object.keys(data).forEach((el) => {
            tmp.push(data[el]);
          });
          dispatch(product_list(tmp));
        }
      })
      .catch((err) => {
        let error = {
          name: "product_list",
          data: { type: "error", msg: err.message },
        };
        dispatch(set_result(error));
      })
      .then(() => {
        dispatch(remove_loading("product_list"));
        done();
      });
  },
});

export const topProduct = createLogic({
  type: types.SET_TOP_PRODUCT,
  latest: true,
  validate({ getState, action }, allow, reject) {
    const { top_product } = getState().productReducer;
    if (top_product.length === 0) {
      allow(action);
    } else {
      reject(action);
    }
  },
  process({ action }, dispatch, done) {
    dispatch(set_loading("top_product"));
    requestAxios(url.PRODUCT.top)
      .then((e) => {
        console.log(e);
        dispatch(top_product_list(e.data));
      })
      .catch((err) => {
        const error = {
          name: "top_product",
          data: { type: "error", msg: err.message },
        };
        dispatch(set_result(error));
      })
      .then(() => {
        dispatch(remove_loading("top_product"));
        done();
      });
  },
});

export const setTrending = createLogic({
  type: types.SET_TRENDING_PROPS,
  latest: true,
  validate({ getState, action }, allow, reject) {
    const { trending } = getState().productReducer;
    if (trending.length === 0) {
      allow(action);
    } else {
      reject(action);
    }
  },
  process({ action }, dispatch, done) {
    dispatch(set_loading("set_trending"));
    requestAxios(url.PRODUCT.trending)
      .then((e) => {
        console.log(e);
        dispatch(set_trending(e.data));
      })
      .catch((err) => {
        const error = {
          name: "set_trending",
          data: { type: "error", msg: err.message },
        };
        dispatch(set_result(error));
      })
      .then(() => {
        dispatch(remove_loading("set_trending"));
        done();
      });
  },
});

export const productType = createLogic({
  type: types.PRODUCT_TYPE_PROPS,
  latest: true,
  validate({ getState, action }, allow, reject) {
    const { product_type } = getState().productReducer;
    if (product_type.length === 0) {
      allow(action);
    } else {
      reject(action);
    }
  },
  process({ action }, dispatch, done) {
    dispatch(set_loading("product_type"));
    requestAxios(url.PRODUCT.product_type)
      .then((e) => {
        console.log(e);
        let tmp = [];
        Object.keys(e.data).forEach((el) => {
          tmp.push(e.data[el]);
        });
        dispatch(product_type(tmp));
      })
      .catch((err) => {
        const error = {
          name: "product_type",
          data: { type: "error", msg: err.message },
        };
        dispatch(set_result(error));
      })
      .then(() => {
        dispatch(remove_loading("product_type"));
        done();
      });
  },
});

export const productHistory = createLogic({
  type: types.PRODUCT_HISTORY_PROPS,
  latest: true,
  process({ action }, dispatch, done) {
    const { payload } = action;
    const isObject = typeof payload === "object";
    dispatch(set_loading("product_history"));
    let data = {
      code: payload,
    };
    if (isObject) data = payload;
    requestValidate(url.PRODUCT.history, data, (response) => {
      return Boolean(Object.keys(response).length);
    })
      .then((e) => {
        let res = e;
        if (payload.hasOwnProperty("code[]")) {
          let tmp = [];
          Object.keys(e.data).forEach((e2) => {
            tmp = [...tmp, e.data[e2]];
          });
          res = [...tmp];
        } else if (payload.hasOwnProperty("code")) {
          res = [res.data[payload.code]];
        }
        dispatch(product_history(res));
      })
      .catch((err) => {
        const error = {
          name: "product_history",
          data: { type: "error", msg: err.message },
        };
        dispatch(set_result(error));
      })
      .then(() => {
        dispatch(remove_loading("product_history"));
        done();
      });
  },
});

export const clearProductHistory = createLogic({
  type: types.CLEAR_PRODUCT_HISTORY_PROPS,
  // warnTimeout: 0,
  process({ getState, action }, dispatch, done) {
    dispatch(clear_product_history());
    done();
  },
});
