import React, { PureComponent } from "react";
import FormRegister from "../../organism/Register/FormRegister";
import { object, string } from "yup";
import validation from "../../../helper/validateHelper";
import { fetch_forgot_pass } from "../../../config/redux/actions/auth";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import processHelper from "../../../helper/processHelper";
import DialogAlert from "../../molecule/Alert/DialogAlert";

const validationSchema = object().shape({
  new_password: validation.validatePasswordRegister(),
  new_password_confirm: string()
    .required("Password harus diisi")
    .test(
      "password-match",
      "Password yang anda masukkan tidak sesuai",
      function (value) {
        return this.parent.new_password === value;
      }
    ),
});

const inputs = [
  { name: "new_password", id:"new_password", label: "Masukkan password baru kamu" },
  { name: "new_password_confirm",id: "new_password_confirm", label: "Konfirmasi password baru" },
];

class ForgotChangePass extends PureComponent {
  // componentDidUpdate = (prevProps, prevState) => {
  //   const { result_stack, history } = this.props;
  //   if (result_stack !== prevProps.result_stack) {
  //     const res = processHelper.findResult("change_pass_change", result_stack);
  //     if (res.name && res.type === "success") {
  //       history.goBack();
  //     }
  //   }
  // };

  _onSuccess = () => this.props.history.goBack();

  handleSubmit = (params) => {
    this.props.fetch_forgot_pass({ type: "change", params });
    console.log(params);
  };

  render() {
    const l_submit = processHelper.findProcess(
      "change_pass_change",
      this.props.loading_stack
    );
    const data = [];
    inputs.forEach((item, index) =>
      data.push({
        ...item,
        type: "password",
        labelClass: "font-weight-bold mb-2 pb-1",
        cClass: `mb-3 ${!index && "mt-5"}`,
      })
    );
    return (
      <FormRegister
        ref="form"
        className="flex-grow-1"
        textSubmit="UBAH PASSWORD"
        validation={validationSchema}
        loadingSubmit={l_submit}
        submitClass="w-inherit px-5 py-2 mt-auto"
        onSubmit={this.handleSubmit}
        data={data}
        submitOnEnter
      >
        <DialogAlert
          resultName="change_pass_change"
          alertAppear="error"
          // submitIfSuccess={this._onSuccess}
          // submitSuccessOnBlur
        />
      </FormRegister>
    );
  }
}

const mapStateToProps = ({ stateReducer }) => ({
  loading_stack: stateReducer.loading_stack,
  result_stack: stateReducer.result_stack,
});
const mapDispatchToProps = {
  fetch_forgot_pass,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ForgotChangePass)
);
