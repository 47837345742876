import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import stateCallApi from "../../../config/API/stateCallApi";
import { gbl_act_lc } from "../../../config/redux/actions/state";
import ContentFileAutoDebet from "../../organism/ContentFileAutoDebet";
const { useRef, useEffect } = React;

// const ids_base = [42167, 42168];

const AutoDebetFilePage = () => {
  const download = useRef([]);
  const { goBack, location, replace } = useHistory();
  const ids = useRef(location.state?.ids ?? []);
  const dispatch = useDispatch();
  const confirm_payment = useSelector(
    (state) => state.transactionReducer.confirm_payment_data
  );

  const handleClick = (id) => {
    let already = download.current || [];
    already = already.includes(id) ? already : [...already, id];
    download.current = already;
    if (already.length === ids.current.length)
      setTimeout(() => replace("/finish-confirm", ids.current), 10);
  };

  useEffect(() => {
    if (ids.current.length) {
      dispatch(
        gbl_act_lc({
          ...stateCallApi.txn_list_set_confiem_payment,
          callback: { fail: goBack },
          params: { "query[id][]": ids.current },
        })
      );
    }
  }, [dispatch, goBack]);

  return (
    <ContentFileAutoDebet
      data={confirm_payment?.data?.map(
        ({
          payment: {
            payment_type: { bank_branch } = {},
            transaction_auto_debet: { master_txn_id, document } = {},
          } = {},
          product: { fr_product_name } = {},
        } = {}) => ({
          icon: bank_branch?.bank?.logo,
          name: fr_product_name,
          path: document,
          txn_id: master_txn_id,
        })
      )}
      onClick={handleClick}
    />
  );
};

export default AutoDebetFilePage;
