import React, { PureComponent } from "react";
import TempMobile from "../template/TempMobile";
import { withRouter } from "react-router-dom";
import {
  set_article_content_props,
  set_article_content,
} from "../../config/redux/actions/article";
import { connect } from "react-redux";
import DialogAlert from "../molecule/Alert/DialogAlert";
import { compose } from "redux";
import { withStyles } from "@material-ui/core";
import textHelper from "../../helper/textHelper";
import BasicText from "../atom/BasicText/BasicText";
import { Skeleton } from "@material-ui/lab";

const useStyles = (theme) => ({
  containContent: {
    width: "100% !important",
    padding: "28px 30px",
    "& img": {
      width: "100% !important",
      height: "unset !important",
    },
  },
});

function SkeletonArticle() {
  function Tulang(props) {
    return <Skeleton animation="wave" {...props} />;
  }
  return (
    <div>
      <Tulang />
      <Tulang width="40%" className="mb-3" />
      <Tulang variant="rect" height={150} className="mb-4" />
      {[0, 1, 2].map((item) => (
        <div className="mb-2" key={item}>
          <Tulang />
          <Tulang width="65%" />
          <Tulang />
          <Tulang width="40%" />
        </div>
      ))}
    </div>
  );
}

class ArticlePage extends PureComponent {
  state = {
    image: "",
    title: "",
  };
  componentDidMount = () => {
    const { set_article_content_props, match, location } = this.props;
    const search = textHelper.parseParams(location.search);
    let id = 0;
    if (search) id = search[0].id;
    this.getImage(id);
    const { slug } = match.params || {};
    set_article_content_props({ slug, id });
  };
  componentDidUpdate = (prevProps) => {
    const { article, set_article_content_props, match, location } = this.props;
    const search = textHelper.parseParams(location.search);
    if (article !== prevProps.article && article.data.length) {
      let id = 0;
      if (search) id = search[0].id;
      const { slug } = match.params || {};
      this.getImage(id);
      set_article_content_props({ slug, id });
    }
  };
  getImage = (id) => {
    const { data } = this.props.article || {};
    if (Array.isArray(data)) {
      const index = data.findIndex((item) => item.id === parseInt(id));
      this.setState({
        image: data[index]?.image,
        title: data[index]?.title,
      });
    }
  };
  componentWillUnmount = () => {
    this.props.set_article_content("");
  };

  render() {
    const { content, classes } = this.props;
    const { title, image } = this.state;
    return (
      <TempMobile
        showBottom={false}
        backgroundColor="white"
        title="INFORMASI PENTING"
        isCloseButton
      >
        <div className={classes.containContent}>
          {content ? (
            <div>
              <BasicText className="fs-14 font-weight-bold color-secondary mb-3">
                {title}
              </BasicText>
              <img alt="" src={image} className="mb-4" />
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          ) : (
            <SkeletonArticle />
          )}
        </div>
        <DialogAlert alertAppear="error" resultName="article-content" submitIfError={() => this.props.history.push('/')}/>
      </TempMobile>
    );
  }
}

const mapStateToProps = ({ articleReducer }) => ({
  article: articleReducer.article,
  content: articleReducer.content,
});
const mapDispatchToProps = { set_article_content_props, set_article_content };

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(useStyles)
)(ArticlePage);
