import { Grid, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import parse from "html-react-parser";
import PropTypes from "prop-types";
import React, { memo, useRef, useCallback, useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import moreIcon from "../../../assets/icons/moreIcon.svg";
import color from "../../../assets/theme/color";
import { isBookPeriodRDP, isOfferPeriodRDP } from "../../../helper/RDPhelper";
import textHelper from "../../../helper/textHelper";
import BasicText from "../../atom/BasicText/BasicText";
import ButtonContained from "../../atom/Button/ButtonContained";
import PopOver from "../../atom/PopOver/PopOver";
import ThreeButtonProduct from "../../organism/ProductPage/ThreeButtonProduct";
import RiskIndicator from "./RiskIndicator";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "1.2rem 1rem",
    "&.radiusBottomUnset": {
      borderRadius: "4px 4px 0 0",
    },
  },
  riskPadding: {
    marginBottom: "0.75rem!important",
  },
}));

const CardProduct = (props) => {
  const classes = useStyles();
  const {
    items,
    className,
    needButton,
    isMobileDesign,
    radiusBottomUnset,
    forceTwoLine,
    appendContent,
    extraContent,
    heightRiskIndicator,
  } = props;
  const {
    perform_1_y,
    min_sub,
    product_risk,
    is_suspend,
    fr_product_name,
    is_rdp,
    display_code,
  } = items || {};

  const isMobileResolution = useMediaQuery({ maxWidth: 768 });
  const isMobile = isMobileDesign || isMobileResolution;

  const linkTo = useCallback(
    (link) => {
      props.onClick({ link, items });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );

  const OptionButton = useCallback(
    ({ className }) => (
      <IconButton
        id={`${display_code || ""}-detail`}
        onClick={() => linkTo("/detail-produk")}
        className={className}
      >
        <img src={moreIcon} alt="" />
      </IconButton>
    ),
    [display_code, linkTo]
  );

  const componentName = useMemo(() => {
    const labelProduct = forceTwoLine
      ? parse(textHelper.printProduct2Line(fr_product_name))
      : fr_product_name;
    return (
      <>
        <div className="d-flex justify-content-between align-items-center">
          <BasicText className={classNames("fs-12", isMobile && "w-75")}>
            {labelProduct}
          </BasicText>
          {isMobile ? <OptionButton className="p-0" /> : null}
        </div>
        <hr className="divider-dotted mt-2 mb-sm-2 mb-md-0" />
      </>
    );
  }, [forceTwoLine, fr_product_name, isMobile]);

  return (
    <div
      className={classNames(
        classes.root,
        className,
        "card-custom",
        radiusBottomUnset && "radiusBottomUnset"
      )}
    >
      {is_rdp ? (
        <RDPCard
          data={items}
          name={componentName}
          isMobile={isMobile}
          optionButton={<OptionButton />}
          onClickAction={linkTo}
          withButton={needButton}
        />
      ) : (
        <Grid container spacing={1}>
          {appendContent}
          <Grid
            item={true}
            xs={12}
            md={isMobile ? 12 : 3}
            container
            className={isMobile ? "" : "pr-md-4 my-auto"}
            direction="column"
          >
            <Grid container>
              <Grid item xs={isMobile ? 12 : 10}>
                {componentName}
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item={true}
            xs={12}
            md={isMobile ? 12 : 4}
            container
            className="mb-2 my-md-auto"
          >
            <Grid item xs={4}>
              <div className="d-flex">
                <BasicText className="fs-10 mb-2" colorLevel="light">
                  Kinerja 1 Tahun
                </BasicText>
                <PopOver title="Kinerja 1 Tahun" className="ml-1" size={12}>
                  Kinerja 1 Tahun tidak mencerminkan return dimasa depan
                </PopOver>
              </div>

              <BasicText className="fs-14 font-weight-semibold">
                {textHelper.printPercent(perform_1_y)}
              </BasicText>
            </Grid>
            <Grid item xs={4}>
              <BasicText className="fs-10 mb-2" colorLevel="light">
                Min. Pembelian
              </BasicText>
              <BasicText className="fs-14 font-weight-semibold">
                {textHelper.printMoney(min_sub)}
              </BasicText>
            </Grid>
            <Grid item xs={4}>
              <BasicText
                className={classNames("fs-10", classes.riskPadding)}
                colorLevel="light"
              >
                Risiko Produk
              </BasicText>
              <RiskIndicator
                value={product_risk}
                height={heightRiskIndicator || 12}
              />
            </Grid>
          </Grid>

          {needButton && (
            <Grid
              item={true}
              container
              xs={12}
              md={isMobile ? 12 : 4}
              className="my-auto"
            >
              <ThreeButtonProduct
                code={display_code}
                is_suspend={is_suspend}
                onClick={linkTo}
              />
            </Grid>
          )}
          {!isMobile ? (
            <Grid item xs={12} md={1} className="text-center d-none d-lg-block">
              <OptionButton />
            </Grid>
          ) : null}
          {extraContent}
        </Grid>
      )}
    </div>
  );
};

//#region RDP
const useStylesRDP = makeStyles((theme) => ({
  container: {
    display: "grid",
    gap: "16px",
    flex: 1,
    [theme.breakpoints.up(1024)]: {
      gridTemplateColumns: "repeat(7, 1fr)",
      gridTemplateRows: "repeat(2, 1fr)",
      "& .name": { gridArea: "1 / 1 / 2 / 3" },
      "& .return": { gridArea: "1 / 3 / 2 / 4" },
      "& .jatuhtempo": { gridArea: "1 / 4 / 2 / 5" },
      "& .min.pembelian": { gridArea: "1 / 5 / 2 / 6" },
      "& .pembagianreturn": { gridArea: "1 / 6 / 2 / 7" },
      "& .carapembagianreturn": { gridArea: "1 / 7 / 2 / 8" },
      "& .bookbuilding": { gridArea: "2 / 3 / 3 / 5" },
      "& .masapenawaran": { gridArea: "2 / 5 / 3 / 7" },
      "& .tanggalemisi": { gridArea: "2 / 7 / 3 / 8" },
      "& .risikoproduk": { gridArea: "2 / 1 / 3 / 2" },
      "& .action": { gridArea: "3 / 6 / 4 / 8" },
      "&.with-action": {
        gridTemplateRows: "repeat(3, 1fr)",
        "& .risikoproduk": { gridArea: "3 / 5 / 4 / 6" },
      },
    },
  },
}));

const RDPCard = memo(
  ({
    data: { product_rdp, min_sub, product_risk, code },
    data,
    name,
    isMobile,
    heightRiskIndicator,
    optionButton,
    onClickAction,
    withButton,
  }) => {
    const styles = useStylesRDP();
    const { canBook, canBuy } = useRDPState(data);

    const propsButton = useRef({
      className: "w-100 fs-11 py-2 font-weight-semibold",
      radius: "5px",
      backgroundColor: color.primary,
    }).current;

    const onBook = useCallback(() => onClickAction?.("/book"), [onClickAction]);
    const onBuy = useCallback(
      () => onClickAction?.("/subscript"),
      [onClickAction]
    );
    const onSimulasi = useCallback(
      () => onClickAction?.("/simulasi-produk"),
      [onClickAction]
    );

    return (
      <div className="d-flex align-items-start">
        <div
          className={classNames(styles.container, withButton && "with-action")}
        >
          <div className="name">{name}</div>
          <Row
            title="Risiko Produk"
            isMobile={isMobile}
            value={
              <RiskIndicator
                value={product_risk}
                height={heightRiskIndicator || 12}
              />
            }
          />
          <Row title="Return" value={product_rdp.return} isMobile={isMobile} />
          <Row
            title="Jatuh Tempo"
            value={textHelper.printDate(product_rdp.due_date)}
            isMobile={isMobile}
          />
          <Row
            title="Min. Pembelian"
            value={textHelper.printMoney(min_sub)}
            isMobile={isMobile}
          />
          <Row
            title="Pembagian Return"
            value={product_rdp.return_distribution}
            isMobile={isMobile}
          />
          <Row
            title="Cara Pembagian Return"
            value={product_rdp.return_distribution_method}
            isMobile={isMobile}
          />
          <Row
            title="Book Building"
            value={textHelper.printDateStartEnd(
              product_rdp?.book_period_start,
              product_rdp?.book_period_end
            )}
            isMobile={isMobile}
          />
          <Row
            title="Masa Penawaran"
            value={textHelper.printDateStartEnd(
              product_rdp.offer_period_start,
              product_rdp.offer_period_end
            )}
            isMobile={isMobile}
          />
          <Row
            title="Tanggal Emisi"
            value={textHelper.printDate(
              product_rdp.emission_date,
              "DD MMM YYYY"
            )}
            isMobile={isMobile}
          />
          {withButton && (
            <Grid container spacing={1} className="action">
              <Grid item xs>
                <ButtonContained
                  id={`${code}-book`}
                  text="BOOKING"
                  disabled={!canBook}
                  onClick={onBook}
                  {...propsButton}
                />
              </Grid>
              <Grid item xs>
                <ButtonContained
                  id={`${code}-beli`}
                  text="BELI"
                  disabled={!canBuy}
                  onClick={onBuy}
                  {...propsButton}
                />
              </Grid>
              <Grid item xs>
                <ButtonContained
                  id={`${code}-simulasi`}
                  text="SIMULASI"
                  {...propsButton}
                  backgroundColor={color.secondaryLight}
                  onClick={onSimulasi}
                />
              </Grid>
            </Grid>
          )}
        </div>
        {!isMobile && optionButton}
      </div>
    );
  }
);

const Row = memo(({ title, value, isMobile, className }) => {
  const id = title?.toLowerCase().replace(/\s/g, "") ?? "row";
  return (
    <div
      id={id}
      className={classNames(
        isMobile && "d-flex justify-content-between align-items-center",
        id,
        className
      )}
    >
      <BasicText className={`fs-10 ${!isMobile && "mb-2"}`} colorLevel="light">
        {title}
      </BasicText>
      {typeof value === "string" ? (
        <BasicText className="fs-14 font-weight-semibold">{value}</BasicText>
      ) : (
        value
      )}
    </div>
  );
});

export function useRDPState({ product_rdp, can_subscript }) {
  const isBookPeriod = isBookPeriodRDP(product_rdp);
  const isOfferPeriod = isOfferPeriodRDP(product_rdp);
  return {
    canBook: can_subscript && isBookPeriod,
    canBuy: can_subscript && isOfferPeriod,
  };
}
//#endregion

CardProduct.propTypes = {
  items: PropTypes.any,
  className: PropTypes.string,
  needButton: PropTypes.bool,
  radiusBottomUnset: PropTypes.bool,
};

CardProduct.defaultProps = {
  needButton: true,
  radiusBottomUnset: false,
  isSubs: false,
};

export default CardProduct;
