import React from "react";
import logo from "../../assets/img/logo";
import DesktopHeader from "../molecule/Header/DesktopHeader";
import Sidebar from "../molecule/Sidebar/Sidebar";
import DesktopFooter from "../molecule/Footer/DesktopFooter";
import { set_profile } from "../../config/redux/actions/profile";
import { connect } from "react-redux";
import textHelper from "../../helper/textHelper";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { useLocation } from "react-router-dom";
const TempDesktop = (props) => {
  const {
    children,
    navValue,
    title,
    set_profile,
    profile,
    isLogin,
    isInitial,
    backHeaderFunc,
  } = props;
  const [state, setState] = React.useState({
    name: null,
    profile_risk: null,
    once: true,
  });
  const divRef = React.useRef(null);
  const location = useLocation();
  const { name, profile_risk, once } = state;
  React.useEffect(() => {
    if (once) {
      divRef.current.scrollTo(0, 0);
      setState((state) => ({ ...state, once: false }));
    }
    if (Object.keys(profile).length > 0) {
      setState((state) => ({
        ...state,
        name: textHelper.printFullname(profile),
        profile_risk: profile.profile_risk.name,
      }));
    }
  }, [profile, set_profile, isLogin, once]);
  const handleClick = (e) => {
    props.history.push(e);
  };
  const isHideSidebar = location.state?.hideSidebar ?? false;
  return (
    <div className="position-relative" ref={divRef}>
      {!isHideSidebar && (
        <div className="sidebar">
          <div className="w-100 text-center pt-3 pb-2">
            <img
              id="logo"
              src={logo.logo}
              alt=""
              className="logo is-link"
              onClick={() => handleClick("/")}
            />
          </div>
          <Sidebar navValue={navValue} isInitialPage={isInitial} />
        </div>
      )}
      <div className={`section ${isHideSidebar ? "" : "with-sidebar"}`}>
        <DesktopHeader
          title={title}
          name={name}
          profile_risk={profile_risk}
          isLogin={isLogin}
          onClick={handleClick}
          withBack={Boolean(backHeaderFunc)}
          onBack={backHeaderFunc}
        />
        <div className="content">{children}</div>
        <DesktopFooter width="inherit" maxWidth={1130} />
      </div>
    </div>
  );
};

const mapState = (state) => ({
  profile: state.profileReducer.profile,
  isLogin: state.authReducer.isLogin,
});

export default compose(
  connect(mapState, { set_profile }),
  withRouter
)(TempDesktop);
