import React, { memo } from "react";
import CardProduct from "../../molecule/Product/CardProduct";
import { useSelector } from "react-redux";
import ContainerWithLoader from "../Container/ContainerWithLoader";
import BasicText from "../../atom/BasicText/BasicText";
import { useMemo } from "react";
import {
  isBookPeriodRDP,
  isCloseOfferPeriodRDP,
  isOfferPeriodRDP,
} from "../../../helper/RDPhelper";

const ProductList = (props) => {
  const { code, onClick } = props;
  const products = useSelector((state) => state.productReducer.product);
  const product_type = useSelector(
    (state) => state.productReducer.product_type
  );

  const title = useMemo(
    () =>
      product_type.reduce((acc, { id, name }) => ({ ...acc, [id]: name }), {}),
    [product_type]
  );

  const state = useMemo(() => {
    if (!products.length) return {};
    return product_type.reduce((acc, { id }) => {
      const productsByType = products.filter(
        ({ product_type_id }) => product_type_id === id
      );
      let dataPerType = [
        {
          key: "",
          title: "",
          data: productsByType.filter(({ is_syaria }) => is_syaria !== 1),
        },
        {
          key: "syariah",
          title: "Syariah",
          data: productsByType.filter(({ is_syaria }) => is_syaria === 1),
        },
      ];
      if (id === "TP") {
        const filterRdp =
          (action) =>
          ({ product_rdp }) =>
            action(product_rdp);

        const renderTitle = (text) => (
          <span className="fs-13 gray-4 font-weight-normal">({text})</span>
        );

        dataPerType = [
          {
            key: "book building",
            title: renderTitle("Book Building"),
            data: productsByType.filter(filterRdp(isBookPeriodRDP)),
          },
          {
            key: "masa penawaran",
            title: renderTitle("Dalam Masa Penawaran"),
            data: productsByType.filter(filterRdp(isOfferPeriodRDP)),
          },
          {
            key: "penawaran berakhir",
            title: renderTitle("Masa Penawaran Berakhir"),
            data: productsByType.filter(filterRdp(isCloseOfferPeriodRDP)),
          },
        ];
      }

      return {
        ...acc,
        [id]: dataPerType,
      };
    }, {});
  }, [product_type, products]);

  const handlerClick = React.useCallback((data) => onClick(data), [onClick]);

  const isDataAvailable = state[code];

  return (
    <div className="px-3 px-md-0">
      <ContainerWithLoader processName="product_list" loadingHeight="50vh">
        <>
          {isDataAvailable?.map(({ title: label, data, key }) => {
            return (
              !!data.length && (
                <List
                  key={code + key}
                  title={
                    <>
                      {title[code]} {label}
                    </>
                  }
                  products={data}
                  onClick={handlerClick}
                />
              )
            );
          })}
        </>
      </ContainerWithLoader>
    </div>
  );
};

const List = memo(
  ({ title, products, onClick }) => {
    return (
      <>
        <BasicText className="fs-14 font-weight-bold mb-3" color="primary">
          {title}
        </BasicText>
        {Array.isArray(products) &&
          products.map((product) => (
            <CardProduct
              key={product.code}
              items={product}
              forceTwoLine
              className="mb-3"
              onClick={onClick}
              heightRiskIndicator={12}
            />
          ))}
      </>
    );
  },
  (p, n) => p.title === n.title
);

export default ProductList;
