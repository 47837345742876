import Grid from "@material-ui/core/Grid";
import Slider from "@material-ui/core/Slider";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import textHelper from "../../../helper/textHelper";
import BasicText from "../../atom/BasicText/BasicText";
import TextInput from "./TextInput";

const useStyles = makeStyles((theme) => ({
  input: (props) => ({
    "& input": {
      padding: "10px",
      "&.MuiInputBase-inputAdornedStart": {
        paddingLeft: 0,
      },
      fontSize: 14,
      color: props.color || theme.palette.secondary.light,
    },
  }),
  slider: {
    "& .MuiSlider-thumb": {
      width: 7,
      height: 7,
      marginTop: -2,
    },
  },
  sliderLabel: {},
}));

const SliderWithInput = (props) => {
  const {
    id,
    text,
    minValue,
    maxValue,
    maxInput,
    stepSlider,
    value,
    sliderClass,
    onChange,
    adormentContent,
    adormentPlace,
    placeholder,
    textInputClass,
    sliderLabelClass,
    disabled,
    unlimited,
    xsTitle,
    xsInput,
    containerClass,
    itemInputClass,
    itemLabelClass,
    otherPropsInput,
    color,
    style,
  } = props;
  const classes = useStyles({ color });
  const handleSliderChange = (e, newValue) => {
    if (e.cancelable) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (newValue === "") {
      onChange("");
    } else {
      if (parseFloat(newValue) <= parseFloat(maxValue)) {
        onChange(newValue);
      }
    }
  };
  const handleInputChange = (e) => {
    let input = e.replace(/[.]+/g, "").replace(",", ".").trim();
    if (input === "") {
      onChange(input);
    } else if (
      unlimited ||
      parseFloat(input) <= parseFloat(maxInput || maxValue)
    ) {
      onChange(input);
    } else {
      onChange(maxInput || maxValue);
    }
  };
  const propsId = id && id + "-";
  return (
    <Grid container className={containerClass} style={style}>
      <Grid
        item={true}
        container
        xs={xsTitle || 6}
        direction="column"
        justify="center"
        className={itemLabelClass}
      >
        <BasicText
          className={classNames(sliderLabelClass, classes.sliderLabel)}
        >
          {text}
        </BasicText>
        <div className="pl-1 pr-4">
          <Slider
            id={`${propsId}slider`}
            min={minValue}
            max={maxValue}
            step={stepSlider}
            value={parseFloat(value)}
            onChange={handleSliderChange}
            className={classNames(sliderClass, classes.slider)}
            disabled={disabled}
          />
        </div>
      </Grid>
      <Grid item xs={xsInput || 6} className={itemInputClass}>
        <TextInput
          {...otherPropsInput}
          id={`${propsId}input`}
          inputClass={classNames(classes.input, textInputClass)}
          variant="outlined"
          placeholder={placeholder}
          value={textHelper.printMoneyInput(value)}
          startIcon={
            adormentContent && adormentPlace === "start" && adormentContent
          }
          endIcon={
            adormentContent && adormentPlace === "end" && adormentContent
          }
          onChange={handleInputChange}
          disabled={disabled}
        />
        {/* {errors && (
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => (
              <BasicText style={{ color: "red" }}>{message}</BasicText>
            )}
          />
        )} */}
      </Grid>
    </Grid>
  );
};

SliderWithInput.propTypes = {
  text: PropTypes.string,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  maxInput: PropTypes.number,
  stepSlider: PropTypes.number,
  value: PropTypes.any,
  sliderClass: PropTypes.string,
  onChange: PropTypes.func,
  adormentContent: PropTypes.any,
  adormentPlace: PropTypes.string,
  placeholder: PropTypes.string,
  sliderLabelClass: PropTypes.string,
};

SliderWithInput.defaultProps = {
  sliderLabelClass: "font-weight-bold fs-14",
  stepSlider: 1000,
  minValue: 100000,
  maxValue: 10000000,
  onChange: (e) => console.log(e),
};

export default SliderWithInput;
