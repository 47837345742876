import classNames from "classnames";
import React from "react";
import { useMediaQuery } from "react-responsive";
import CardProduct from "../Product/CardProduct";

const ListProduct = ({ data, onClick, className }) => {
  const isDesktop = useMediaQuery({ minWidth: 769 });
  return data.map((el, index) => (
    <CardProduct
      key={index}
      items={el.product}
      needButton={false}
      className={classNames(isDesktop ? "mt-3 pt-2" : "mt-2 br-8", className)}
      onClick={onClick}
      isMobileDesign
      heightRiskIndicator={8}
    />
  ));
};

export default ListProduct;
