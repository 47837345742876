import React from "react";
import useStyles from "./useStyles";
import classNames from "classnames";
import { Grid } from "@material-ui/core";
import ButtonLink2 from "../../../atom/Button/ButtonLink2";
import parse from "html-react-parser";
import textHelper from "../../../../helper/textHelper";
import txnSuccess from "../../../../assets/icons/txnSuccess.svg";
import txnFailed from "../../../../assets/icons/txnFailed.svg";

const ProductTxn = ({ data, onClick }) => {
  const classes = useStyles();
  const {
    txn_reff,
    fr_transaction_name_retail,
    txn_type_code,
    product,
    fr_traded_at_full,
    fr_amount,
    txn_status_code,
  } = data;
  const isDeclined = txn_status_code === "DECLINED";
  return (
    <>
      <Grid 
        container 
        className={classes.borderDot} 
        alignItems="center" 
        style={{ paddingBottom: 11, marginBottom: 11 }} 
      >
        <Grid xs={5} md={4} item>
          <p className="fs-12 basic font-weight-semibold mr-5">
            {parse(textHelper.printProduct2Line(product?.fr_product_name))}
          </p>    
        </Grid>
        <Grid item xs={5} md={6}>
          <p className="basic fs-12 mr-auto w-fit">Nomor Transaksi: {txn_reff}</p>
        </Grid>
        <Grid item  xs={2} container justify="flex-end">
          <div
            className={classNames(
              classes.flag,
              txn_type_code,
              "align-self-center"
            )}
          >
            {fr_transaction_name_retail}
          </div>
        </Grid>
    </Grid>

    <Grid container alignItems="center">
      <Grid xs={5} item md={4} container alignItems="center">
        <p style={{ width: 105 }} className="basic fs-10 gray-4 mr-3">
          {fr_traded_at_full}
        </p>
        <img alt="fail" src={isDeclined ? txnFailed : txnSuccess} />
      </Grid>
      <Grid item xs={7} md={6} container alignItems="center">
        <p className="basic fs-12 ml-auto ml-md-0">Total</p>
        <p className="basic fs-14 font-weight-bold color-primary ml-5 pl-5">
          {fr_amount}
        </p>
      </Grid>
      <Grid item xs={12} md={2} container justify="flex-end">
        <ButtonLink2
          id={`detail-${txn_reff}`}
          text="DETAIL"
          className="fs-12 text-underline is-link gray-5"
          onClick={() => onClick("detail")}
        />
      </Grid>
    </Grid>
    </>
  );
};

export default ProductTxn;
