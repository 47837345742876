import Card from "@material-ui/core/Card";
import classNames from "classnames";
import React from "react";
import GroupingTxn from "./GroupingTxn";
import ProductTxn from "./ProductTxn";
import useStyles from "./useStyles";
const { useCallback } = React;

const CardTxn = ({ className, data, onClick }) => {
  const classes = useStyles();

  const handleClick = useCallback(
    (key) => {
      const { txn_reff, id, transactions } = data;
      let txn_id = id;
      if (!txn_reff) txn_id = transactions.map(({ id }) => id);
      switch (key) {
        case "detail":
          let path = "transaksi/detail";
          if (Array.isArray(txn_id))
            txn_id.forEach(
              (id, index) => (path = `${path}${index ? "&" : "?"}id=${id}`)
            );
          else path = `${path}?id=${id}`;
          onClick(path);
          break;
        case "confirm-payment":
          onClick("/confirm-payment", txn_id);
          break;
        default:
          break;
      }
    },
    [data, onClick]
  );
  const { is_expired, expired_text } = data.expired || {};
  return (
    <>
      {data.expired && (
        <Card className={classNames(classes.notice, "shadow-card")}>
          <p className="color-white basic fs-14 font-weight-bold">PENTING!</p>
          <p className="color-white basic fs-10">
            {is_expired === false ? (
              <>
                Transaksi kamu akan diproses setelah kamu{" "}
                <b>mengupload bukti transfer</b>, dan akan{" "}
              </>
            ) : (
              ""
            )}
            <span className={is_expired === false ? "font-weight-bold" : ""}>
              {expired_text}.
            </span>
          </p>
        </Card>
      )}
      <Card className={classNames(classes.root, className, "shadow-card")}>
        {data.total_txn ? (
          <GroupingTxn data={data} onClick={handleClick} />
        ) : (
          <ProductTxn data={data} onClick={handleClick} />
        )}
      </Card>
    </>
  );
};

CardTxn.defaultProps = {
  data: {},
};

export default CardTxn;
