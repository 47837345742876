import { Grid, IconButton, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import moreIcon from "../../../../assets/icons/moreIcon.svg";
import ContentName from "./ContentName";
import PointSummaryCardProduct from "./PointSummaryCardProduct";
const { createContext, memo } = React;

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    "& .icon-detail": {
      position: "absolute",
      right: 3, //14,
      top: "calc(50% - 24px)", //9,
      [theme.breakpoints.down("md")]: {
        top: -10,
        right: -21,
      },
    },
  },
}));

export const CardProductContext = createContext({
  fr_product_name: "",
  fr_perform_1_y: "",
  min_sub_formatted: "",
  display_name: "",
  perform_1_y: "",
  min_sub: 0,
  product_risk: "",
  display_code: "",
  is_suspend: "",
});

const CardProductCustom = memo(
  ({ items, className, onClick, composition }) => {
    const classes = useStyles();
    const AvailableComponent = {
      name: ContentName,
      summary: PointSummaryCardProduct,
    };
    return (
      <CardProductContext.Provider value={items}>
        <div className={classNames(classes.root, className)}>
          <Grid container spacing={3}>
            {composition.map(({ render, ...other }, id) => {
              const Render =
                typeof render === "string"
                  ? AvailableComponent[render]
                  : render;
              return (
                <Grid key={id} item xs={12} {...other}>
                  <Render data={items} />
                </Grid>
              );
            })}
            <IconButton
              id={`${items.code}-more`}
              className="icon-detail"
              onClick={onClick}
            >
              <img src={moreIcon} alt="" />
            </IconButton>
          </Grid>
        </div>
      </CardProductContext.Provider>
    );
  },
  (prev, next) => prev.items.id === next.items.id
);

CardProductCustom.defaultProps = {
  items: {},
  className: "",
  onClick: () => {},
  composition: [
    { render: "name", md: 4 },
    { render: "summary", md: 6 },
  ],
};

export default CardProductCustom;
