import React from "react";
import TempDialogFull from "../../template/TempDialogFull";
import PinInput from "./PinInput";
import PropTypes from "prop-types";

const DialogPin = (props) => {
  const {
    modal,
    onClose,
    validateResult,
    onSubmit,
    dataSubmit,
    processName,
    title,
    withValidation,
    needForgot,
  } = props;
  const handleClose = (e) => {
    onClose(e);
  };
  return (
    <TempDialogFull modal={modal} onClose={handleClose}>
      <PinInput
        processName={processName}
        validateResult={validateResult}
        onClickClose={() => handleClose(false)}
        onSubmit={onSubmit}
        dataSubmit={dataSubmit}
        needForgot={needForgot}
        title={title}
        withValidation={withValidation}
      />
    </TempDialogFull>
  );
};

DialogPin.propTypes = {
  modal: PropTypes.bool,
  onClose: PropTypes.func,
  validateResult: PropTypes.func,
  onSubmit: PropTypes.func,
  dataSubmit: PropTypes.object,
  processName: PropTypes.string,
  needForgot: PropTypes.bool,
};

export default DialogPin;
