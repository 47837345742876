import React from "react";
import PropTypes from "prop-types";
import MediaQuery from "../organism/MediaQuery/MediaQuery";
import desktop from "./Desktop";
import mobile from "./Mobile";

const TempAuth = props => {
  const { children, page, data } = props;
  const DesktopPage = desktop[page];
  const MobilePage = mobile[page];
  return (
    <div>
      <MediaQuery device="mobile">
        <MobilePage data={data}>{children}</MobilePage>
      </MediaQuery>
      <MediaQuery device="desktop">
        <DesktopPage data={data}>{children}</DesktopPage>
      </MediaQuery>
    </div>
  );
};

TempAuth.propTypes = {
  page: PropTypes.string,
  data: PropTypes.object
};

export default TempAuth;
