import parse from "html-react-parser";
import React from "react";
import { useLocation } from "react-router-dom";

const DetailKnowledge = () => {
  const { state } = useLocation();
  return (
    <div
      style={{
        padding: "41px 68px 34px 39px",
      }}
    >
      <p className="fs-14 font-weight-bold color-secondary mb-4">
        {state?.title}
      </p>
      <div className="fs-12 color-secondary">{parse(state?.content ?? "")}</div>
    </div>
  );
};

export default DetailKnowledge;
