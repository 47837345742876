import React, { useImperativeHandle } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import color from "../../../assets/theme/color";
import classNames from "classnames";
import BasicButton from "../../atom/Button/BasicButton";
import PanoramaIcon from "@material-ui/icons/Panorama";
import { useDropzone } from "react-dropzone";
import { CircularProgress } from "@material-ui/core";
import { forwardRef } from "react";

const BoxUpload = forwardRef((props, ref) => {
  const [state, setState] = React.useState({
    url: null,
    file: null,
    reject: false,
  });
  useImperativeHandle(ref, () => ({
    setImage: (url) => setState({ ...state, url }),
  }));
  const { getRootProps, getInputProps, open, acceptedFiles, rejectedFiles } =
    useDropzone({
      noClick: true,
      noKeyboard: true,
      maxSize: 6000000,
      accept: "image/*",
    });
  const { marginClass, onChange, loading, error, id } = props;
  // console.log(props);
  React.useEffect(() => {
    if (error && !state.reject) {
      handleReject();
    } else if (!error && state.reject) {
      handleReject();
    }
    if (acceptedFiles.length !== 0) {
      acceptedFiles.forEach((e) => {
        setState((state) => {
          return { ...state, url: URL.createObjectURL(e), file: e };
        });
        onChange(e);
      });
    }
    if (rejectedFiles.length > 0) {
      setState((state) => {
        return { ...state, reject: true, url: null, file: null };
      });
    } else {
      setState((state) => {
        return { ...state, reject: false };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles, rejectedFiles, error]);

  function handleReject() {
    setState({ ...state, reject: !state.reject });
  }

  const { url, reject } = state;
  const useStyles = makeStyles({
    box: {
      width: "100%",
      border: `1px solid ${reject ? "red" : color.gray_6}`,
      height: "165px",
      borderRadius: "5px",
      backgroundColor: "#fff",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    image: {
      height: "calc(100% - 30px)",
      maxWidth: "calc(100% - 30px)",
      "&.loading": {
        opacity: 0.4,
      },
    },
    loading: {
      position: "absolute",
    },
  });
  const classes = useStyles();
  console.log(state);
  return (
    <div className={marginClass}>
      <div {...getRootProps({ className: classNames(classes.box, "mb-1") })}>
        <input id={id} {...getInputProps()} />
        {url && (
          <img
            src={url}
            alt={url}
            className={classNames(classes.image, loading && "loading")}
          />
        )}
        {loading && <CircularProgress size={20} className={classes.loading} />}
      </div>
      {reject && (
        <span style={{ color: "red", fontSize: "13px" }}>
          {error || "File yang dikirim harus Gambar dengan ukuran maks 5MB"}
        </span>
      )}
      <div className="w-100 text-right">
        <BasicButton
          id="button-upload"
          variant="text"
          disabled={loading}
          text="UPLOAD"
          className="color-secondary"
          icon={<PanoramaIcon className="color-primary" />}
          onClick={open}
        />
      </div>
    </div>
  );
});

BoxUpload.propTypes = {
  marginClass: PropTypes.string,
  loading: PropTypes.bool,
};

BoxUpload.defaultProps = {
  loading: false,
  id: 'upload-photo'
};

export default BoxUpload;
