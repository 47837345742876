import React from "react";
import PropTypes from "prop-types";
import textHelper from "../../../helper/textHelper";
import BasicText from "../../atom/BasicText/BasicText";

class Countdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: 0,
      count: null,
    };
  }
  componentDidMount() {
    this.setUlang();
  }
  componentWillUnmount() {
    clearInterval(this.state.count);
  }
  setUlang = () => {
    this.setState((state) => ({ ...state, time: this.props.countTime }));
    this.countDown();
  };
  countDown = () => {
    let count = setInterval(this.checkTime, 1000);
    this.setState((state) => ({ ...state, count }));
  };
  checkTime = () => {
    const { time } = this.state;

    if (time === 0) {
      this.clearCount();
    } else {
      this.setState((state) => ({ ...state, time: state.time - 1 }));
    }
  };
  clearCount = () => {
    clearInterval(this.state.count);
    this.props.onTimeout(true);
  };
  render() {
    const { time } = this.state;
    const { className } = this.props;
    return (
      <BasicText className={className}>
        {textHelper.secondTomin(time)}
      </BasicText>
    );
  }
}

Countdown.propTypes = {
  onTimeOut: PropTypes.func,
  countTime: PropTypes.number,
};

Countdown.defaultProps = {
  onTimeout: () => {},
};

export default Countdown;
