import { connect } from "react-redux";
import React, { Component } from "react";
import { log_out } from "../../config/redux/actions/auth";
import MenuProfile from "../organism/ProfilePage/MenuProfile";
import ShortProfiles from "../organism/ProfilePage/ShortProfiles";
import TempMain from "../template/TempMain";

class ProfilePage extends Component {
  render() {
    return (
      <TempMain
        title="PROFIL"
        backgroundColor="#fff"
        bottomNavValue={4}
        initialPage
      >
        <ShortProfiles />
        <div className="px-4 pt-4 margin-main">
          <MenuProfile
            marginClass="mb-5"
            logoutModal={() => this.props.log_out()}
          />
        </div>
      </TempMain>
    );
  }
}

export default connect(null, { log_out })(ProfilePage);
