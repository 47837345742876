import { Grid, withStyles } from "@material-ui/core";
import classNames from "classnames";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { risk_result_by_point_props } from "../../../config/redux/actions/profile";
import {
  remove_result_l,
  gbl_act_lc,
} from "../../../config/redux/actions/state";
import processHelper from "../../../helper/processHelper";
import ButtonContained from "../../atom/Button/ButtonContained";
import ButtonLink2 from "../../atom/Button/ButtonLink2";
import CardRiskIndicator from "../../molecule/Profile/CardRiskIndicator";
import CardRiskProduct2 from "../../molecule/Profile/CardRiskProduct2";
import CardRiskScore from "../../molecule/Profile/CardRiskScore";
import pageToURL from "../../../helper/pageToUrlHelper";
import stateCallApi from "../../../config/API/stateCallApi";

const useStyles = (theme) => ({
  indicator: {
    width: "100%",
    "& img": {
      width: "100%",
    },
  },
  button: {
    padding: "15px 48px",
  },
});

class ContentResult extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      profile_risk: props.profile_risk.profile_risk_point,
    };
  }

  componentDidMount = () => {
    this.props.gbl_act_lc(stateCallApi.profile_risk_base_point);
  };

  // componentDidUpdate = (prevProps, prevState) => {
  //   const { profile_risk, result_stack, remove_result_l } = this.props;
  //   const updateRisk =
  //     profile_risk.profile_risk_point &&
  //     profile_risk !== prevProps.profile_risk;
  //   if (updateRisk) this.setState({ profile_risk });
  //   else if (result_stack !== prevProps.result_stack) {
  //     const res = processHelper.findResult("risk-by-point", result_stack);
  //     const resultRisk = res.name && res.type === "success";
  //     if (resultRisk) {
  //       this.setState({ profile_risk: res.data });
  //       remove_result_l("risk-by-point");
  //     }
  //   }
  // };

  _onChange = (profile_risk) => this.setState({ profile_risk });

  _onSubmit = () => {
    const { onSubmit, base_robo_alc } = this.props;
    const index = Math.max(
      base_robo_alc.findIndex(
        ({ id }) => Number(id) === Number(this.state.profile_risk)
      ),
      0
    );
    onSubmit(base_robo_alc[index]);
  };

  _onSurvey = () => {
    const { onSurvey, history } = this.props;
    if (onSurvey) onSurvey();
    else history.push(pageToURL.survey_risk);
  };

  render() {
    const {
      className,
      chart_color,
      onChange,
      propsButton,
      classes,
      loading_stack,
      base_robo_alc,
    } = this.props;
    const { profile_risk } = this.state;
    const { profile_risk_type, profile_risk_product } =
      base_robo_alc[
        base_robo_alc.findIndex(
          ({ profile_risk_point }) =>
            Number(profile_risk_point) === Number(profile_risk)
        )
      ] || {};
    const loading = processHelper.findProcess("risk-by-point", loading_stack);

    return (
      <Grid
        container
        alignItems="center"
        direction="column"
        className={className}
      >
        <CardRiskScore
          className="shadow-none"
          score={profile_risk}
          onChange={onChange ? this._onChange : null}
          loading={loading}
        />
        <CardRiskIndicator
          className={classNames("shadow-none", classes.indicator)}
          profileRiskType={profile_risk_type}
        />
        <CardRiskProduct2
          className="w-100 mb-5"
          profileRiskProduct={profile_risk_product}
          chartColor={chart_color}
          loading={loading}
        />
        <ButtonContained
          id="submit-profile-risk"
          text="SIMPAN"
          onClick={this._onSubmit}
          radius="6px"
          disabled={loading}
          className={classes.button}
          {...propsButton}
        />
        <div className="d-flex justify-content-center">
          <ButtonLink2
            id="link-ulangi-pertanyaan"
            text="ULANGI PERTANYAAN"
            className="mb-0 text-underline color-secondary fs-12 mt-3"
            onClick={this._onSurvey}
          />
        </div>
      </Grid>
    );
  }
}

ContentResult.defaultProps = {
  onSubmit: () => {},
  profile_risk: {},
  chart_color: [],
};

const mapStateToProps = ({
  optionReducer,
  profileReducer,
  stateReducer,
  goalReducer,
}) => ({
  chart_color: optionReducer.chart_color,
  profile_risk: profileReducer.profile_risk,
  loading_stack: stateReducer.loading_stack,
  result_stack: stateReducer.result_stack,
  base_robo_alc: goalReducer.base_robo_alc,
});
const mapDispatchToProps = {
  risk_result_by_point_props,
  remove_result_l,
  gbl_act_lc,
};
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStyles(useStyles)
)(ContentResult);
