import React from "react";
import InputText from "./InputText";
import InputTimePicker from "./InputTimePicker";
import { ErrorMessage } from "formik";
import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import SelectsInput from "./SelectsInput";

const useStyles = makeStyles({
  containError: {
    color: "red",
    fontSize: ".75rem",
    position: "relative",
  },
});
const inputs = { InputText, InputTimePicker, SelectsInput };

const RenderInput = ({ typeInput, name, cClass, cEClass, ...otherProps }) => {
  const classes = useStyles();
  const Component = inputs[typeInput];
  return (
    <div className={classNames(cClass, classes.containError)}>
      <Component {...otherProps} />
      {name && (
        <span className={classNames(cEClass, "errorInput")}>
          <ErrorMessage name={name} />
        </span>
      )}
    </div>
  );
};

RenderInput.defaultProps = {
  typeInput: "InputText",
};

export default RenderInput;
