import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  card: {
    padding: "16px 26px 34px 38px",

    "& .header-card": {
      borderBottom: "1px solid " + theme.palette.secondary[6],
      paddingBottom: 10,
    },

    "& .body-card": {
      "& .product": {
        "& .container-item-product": {
          borderBottom: `2px dotted ${theme.palette.secondary[4]}`,
        },
        "& .item-product": {
          [theme.breakpoints.down("md")]: {
            borderBottom: `1px solid ${theme.palette.secondary[6]}`,
          },
          padding: "12px 0",
          "&>p": {
            "&.name": {
              maxWidth: 175,
              marginRight: "auto",
            },
            "&.allocation": {
              marginLeft: 60,
            },
          },
        },
        "& .content-summary": {
          marginTop: 13,
        },
      },
      "& .content-action": {
        justifyContent: "flex-end",
        [theme.breakpoints.down("md")]: {
          justifyContent: "center",
        },
      },
    },
    "& img.payment-method": {
      height: 25,
    },

    // width: "100%",
    // minWidth: 365,
    // padding: 12,
    // "& .lists-porto": {
    //
    //   "& .item-porto": {
    //     display: "flex",
    //     alignItems: "center",
    //     borderBottom: "1px solid " + theme.palette.secondary[6],
    //     justifyContent: "space-between",
    //     padding: "5px 0",
    //   },
    // },
  },
}));
