import React from "react";
import { CardProductContext } from "./CardProductCustom";
import classNames from "classnames";
import parse from "html-react-parser";
import textHelper from "../../../../helper/textHelper";
const { useContext } = React;

const ContentName = () => {
  const { fr_product_name } = useContext(CardProductContext);
  return (
    <div className="content-name-product">
      <p className={classNames("basic fs-12")}>
        {parse(textHelper.printProduct2Line(fr_product_name))}
      </p>
      <hr className="divider-dotted mt-1 mt-md-2 mb-0" />
    </div>
  );
};

export default ContentName;
