import * as types from "./types";
import { temp_return_list } from "../../../helper/processHelper";

export const goals_rd = (payload) => ({
  type: types.GOALS_RD,
  payload,
});
export const goals_list_rd = (response) => {
  const payload = temp_return_list(response, "list");
  return {
    type: types.GOALS_RD,
    payload,
  };
};
export const goals_user_rd = (response) => {
  const payload = temp_return_list(response, "user");
  return {
    type: types.GOALS_RD,
    payload,
  };
};
