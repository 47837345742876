import * as types from "./types";

export const set_loading = (payload) => {
  return {
    type: types.SET_LOADING_STACK,
    payload,
  };
};
export const remove_loading = (payload) => {
  return {
    type: types.REMOVE_LOADING_STACK,
    payload,
  };
};
export const set_result_props = (payload) => {
  return {
    type: types.SET_RESULT_PROPS,
    payload,
  };
};
export const set_result = (payload) => {
  return {
    type: types.SET_RESULT,
    payload,
  };
};
export const set_result_data = (payload) => {
  return {
    type: types.SET_RESULT_DATA,
    payload,
  };
};
export const remove_result_l = (payload) => {
  return {
    type: types.REMOVE_RESULT_L,
    payload,
  };
};
export const remove_result = (payload) => {
  return {
    type: types.REMOVE_RESULT,
    payload,
  };
};

export const gbl_act_lc = (payload) => ({
  type: types.GLOBAL_ACTION_LC,
  payload,
});

export const gbl_list_lc = (payload) => ({
  type: types.GLOBAL_LIST_ACTION_LC,
  payload,
});

export const encrypt_data_lc = payload => ({
  type: types.ENCRYPT_DATA_LC,
  payload
})

export const set_tmp_key = payload => ({
  type: types.SET_TMP_KEY,
  payload
})

export const remove_tmp_key = () => ({
  type: types.SET_TMP_KEY,
  payload: ""
})