import React from "react";
import MainHeader from "../molecule/Header/MainHeader";
import { makeStyles } from "@material-ui/styles";
import MobileBottom from "../molecule/Footer/MobileBottom";
import PropTypes from "prop-types";
const TempMobile = (props) => {
  const {
    backgroundColor,
    children,
    bottomNavValue,
    showBottom,
    goBackButton,
    title,
    isCloseButton,
    withSearch,
    functionBack,
  } = props;
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: backgroundColor || theme.palette.background.default,
      minHeight: "100vh",
      paddingTop: "56px",
    },
  }));
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <MainHeader
        goBack={goBackButton}
        title={title}
        isCloseButton={isCloseButton}
        isRightSearch={withSearch}
        functionBack={functionBack}
      />
      <div className="container-body">{children}</div>
      {showBottom && <MobileBottom value={bottomNavValue} />}
    </div>
  );
};

TempMobile.propTypes = {
  backgroundColor: PropTypes.string,
  bottomNavValue: PropTypes.number,
  showBottom: PropTypes.bool,
  goBackButton: PropTypes.bool,
  title: PropTypes.string,
};

TempMobile.defaultProps = {
  showBottom: true,
  goBackButton: true,
  withSearch: false,
};
export default TempMobile;
