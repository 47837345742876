import { CircularProgress, Grid, withStyles } from "@material-ui/core";
import classNames from "classnames";
import React, { Component, createRef, Fragment, memo } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { compose } from "redux";
import colors from "../../assets/theme/color";
import stateCallApi from "../../config/API/stateCallApi";
import { set_product } from "../../config/redux/actions/product";
import { gbl_act_lc } from "../../config/redux/actions/state";
import {
  booking_lc,
  check_bonus_props,
  order_data,
} from "../../config/redux/actions/transaction";
import processHelper from "../../helper/processHelper";
import textHelper from "../../helper/textHelper";
import IconRobo from "../atom/IconRobo/IconRobo";
import CardCashBack from "../molecule/CardCashBack";
import ContentSelectGoals from "../molecule/ContentSelectGoals";
import DialogTnC from "../molecule/Dialog/DialogTnC";
import CardProductCustom from "../molecule/Product/CardProductCustom/CardProductCustom";
import ContainerWithLoader from "../organism/Container/ContainerWithLoader";
import DialogCustom from "../organism/Container/DialogCustom";
import CheckButtonOrder from "../organism/Order/CheckButtonOrder";
import SubscriptInput from "../organism/Order/SubscriptInput";
import TempMain from "../template/TempMain";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { ic_discount } from "../../assets/icons";
import { useEffect } from "react";
import { check_voucher } from "../../config/redux/actions/voucher";
import { getLoading } from "../../config/redux/selector";
import { Skeleton } from "@material-ui/lab";
import useDataResponse from "../../hooks/useDataResponse";
import { useMemo } from "react";

const mapState = (state) => {
  return {
    loading_stack: state.stateReducer.loading_stack,
    product: state.productReducer.product,
    bonus_sub: state.transactionReducer.bonus_sub,
    goals: state.goalReducer.user.data.filter(
      ({ is_follow_robo }) => !is_follow_robo
    ),
  };
};

const styles = (theme) => ({
  contentSide: {
    [theme.breakpoints.up(768)]: {
      maxWidth: 420,
      marginLeft: 16,
    },
    [theme.breakpoints.down(768)]: {
      margin: "0 10px",
    },
  },
  modalBookContent: {
    color: theme.palette.secondary[4] + " !important",
    maxWidth: 360,
    fontSize: 16,
    padding: "0 50px 0 32px",
    "& ol": {
      marginLeft: 18,
    },
  },
  modalBookTitle: {
    color: theme.palette.primary.main + " !important",
  },
  cardProductMulti: {
    borderBottom: "1px dashed " + theme.palette.secondary[3],
    paddingBottom: 20,
    marginBottom: 20,
  },
});

const CardProductRobo = ({ data, isFirst }) => {
  const { product_type, fr_product_name, amount, color } = data;
  return (
    <div
      className="d-flex w-100 py-4"
      style={{ borderTop: isFirst ? "none" : `1px solid ${colors?.gray_7}` }}
    >
      <div
        style={{ width: 16, height: 16, backgroundColor: color }}
        className="mr-2"
      />
      <div>
        <p className="basic text-uppercase">{product_type?.name}</p>
        <p className="basic fs-10 gray-4">{fr_product_name}</p>
      </div>
      <p className="ml-auto basic font-weight-semibold">{amount}%</p>
    </div>
  );
};

const ContentCardAmount = ({ totalAmount, amount, className }) => {
  return (
    <div
      className={`d-flex justify-content-between align-items-center ${className}`}
    >
      <p className="basic fs-14 font-weight-bold mr-3">Nominal Pembelian</p>
      <p className="basic fs-13 font-weight-semibold">
        {textHelper.printMoney(totalAmount * (amount / 100))}
      </p>
    </div>
  );
};

const CardPromo = memo(
  ({ id, variant, product_code, amount, onUpdateStatus = () => {} }) => {
    const name = "check_voucher-" + id;
    const styleCard =
      variant === "multi" ? "py-4 px-3 bg-gray-7" : "bg-white p-3";
    const { push, location } = useHistory();
    const dispatch = useDispatch();
    const response = useDataResponse(name);
    const loading = useSelector(getLoading(name));
    const goToPromo = () =>
      push("/profil/promo", {
        subState: { ...location.state, total_amount: amount },
        product_code,
      });

    useEffect(() => {
      if (id) {
        dispatch(
          check_voucher({ params: { voucher_id: id, amount, product_code } })
        );
      }
    }, [amount, dispatch, id, product_code]);

    useEffect(() => {
      onUpdateStatus(product_code, !!response?.data?.bonus_amount);
    }, [onUpdateStatus, product_code, response]);

    const textPromo = useMemo(() => {
      const data = response?.data;
      if (data) {
        if (data.fr_bonus_amount) {
          let getterText = "mendapatkan";
          if (data.reward_type === "RANDOM") {
            getterText = "berpotensi dapat";
          }
          return `Kamu ${getterText} bonus ${data.fr_bonus_amount}`;
        }
        return response.msg;
      }
      return "Makin untung dengan promo";
    }, [response]);

    return (
      <div
        className={`d-flex align-items-center rounded-2 w-100 is-link shadow-card ${styleCard}`}
        onClick={goToPromo}
      >
        <img src={ic_discount} alt="" />
        {loading ? (
          <Skeleton variant="text" className="flex mx-2" />
        ) : (
          <span className="flex mx-2 fs-12 font-weight-semibold gray-4">
            {textPromo}
          </span>
        )}

        {loading ? (
          <CircularProgress size={20} />
        ) : (
          <ChevronRight className="color-primary" />
        )}
      </div>
    );
  }
);

class OrderToBuy extends Component {
  constructor(props) {
    super(props);
    this.input = createRef();
    this.once = false;
    const {
      state: {
        total_amount,
        user_goal_id,
        user_planner_id,
        is_follow_robo,
        promo,
      } = {},
      pathname,
    } = props.location;
    this.is_robo = user_planner_id || is_follow_robo;
    this.promoStatus = promo ? Object.keys(promo) : [];
    this.isBooking = pathname === "/book";
    this.state = {
      modalMin: false,
      modalBook: false,
      each_product_value: {},
      value: total_amount || false,
      check: false,
      min_sub: "",
      fee_sub: 0,
      productActiveValue: "",
      productData: [],
      bonus: {},
      syaratLink: null,
      user_goal_id: user_goal_id || undefined,
      totalAmount: total_amount || 0,
    };
    this.refTnC = createRef();
  }
  componentDidMount() {
    const { product, location, check_bonus_props, gbl_act_lc } = this.props;
    check_bonus_props(Object.keys(location.state?.products ?? {}));
    if (product.length === 0) this.props.set_product();
    else this.initialData();

    if (!this.is_robo) {
      gbl_act_lc({
        ...stateCallApi.goals_user_sub,
        callback: (data) => {
          const newState = { goals: data };
          if (this.state.user_goal_id === undefined)
            newState.user_goal_id = data[0].id;
          this.setState(newState);
        },
      });
    }
    this.once = true;
  }

  componentDidUpdate(prevProps) {
    const { product } = this.props;
    if (
      prevProps.product !== product &&
      product.length &&
      !prevProps.product.length
    ) {
      this.initialData();
    }
  }

  onShowProductPopup = (products = []) => {
    const productWithPopup = products.filter(
      ({ product_popup }) => product_popup
    );
    if (productWithPopup[0]) {
      this.refTnC.current?.onShow(productWithPopup[0].product_popup) ?? isNaN();
    }
  };

  initialData = () => {
    const {
      product = {},
      location: { state },
    } = this.props;
    const { products, product_variety } = state;
    const tmp = Object.keys(products).map((key) => ({
      ...products[key],
      ...product.find(({ code }) => String(code) === String(key)),
    }));
    this.onShowProductPopup(tmp);
    this.is_RDP = tmp[0]?.is_rdp;
    this.setState({
      productData: tmp,
      syaratLink: product_variety === "CG" ? "syarat_subCG" : "syarat_sub",
      min_sub: tmp
        .map(({ min_sub }) => Number(min_sub))
        .reduce((a, b) => a + b, 0),
    });
  };

  handleValue = (value) => this.setState({ value });

  onCheck = (check) => this.setState({ check });

  onBooking = () => {
    const { booking_lc, history } = this.props;
    const { productData, totalAmount } = this.state;
    this.handleModalBook(false);
    booking_lc(
      { amount: totalAmount, product_code: productData[0]?.code },
      (data) => history.replace("/finish-book", { ids: [data?.booking_id] })
    );
  };

  onSuccessSubmit = () => {
    const {
      order_data,
      history,
      location: { state },
    } = this.props;
    const { productData, bonus, user_goal_id } = this.state;
    const { value } = this.input.current;
    const { total_amount, promo, ...other } = state;
    const params = {
      ...other,
      product: productData,
      total_amount: value,
      bonus,
      promo: this.promoStatus.reduce((acc, code) => {
        acc[code] = promo[code];
        return acc;
      }, {}),
    };
    if (!this.is_robo && !state.product_variety)
      params["user_goal_id"] = user_goal_id;
    order_data(params);
    history.push("/payment");
  };

  onCloseModalSkipPromo = () => this.setState({ modalSkipPromo: false });

  onSubmit = () => {
    const {
      location: { state },
    } = this.props;
    if (this.input.current.validate()) this.setState({ modalMin: true });
    else if (this.isBooking) {
      this.handleModalBook(true);
    } else if (
      Object.keys(state?.promo ?? {}).length !== this.promoStatus.length
    ) {
      this.setState({ modalSkipPromo: true });
    } else {
      this.onSuccessSubmit();
    }
  };

  handleDetailClick = (code) =>
    this.props.history.push("/detail-produk?product=" + code);

  handleModal = (e) => this.setState({ modalMin: e });

  handleModalBook = (e) => this.setState({ modalBook: e });

  handleBonusChange = (data) => {
    this.setState({ bonus: data });
  };

  handleGoalsId = (user_goal_id) => this.setState({ user_goal_id });

  handleUpdateStatusPromo = (code, statusPromo) => {
    if (statusPromo && !this.promoStatus.includes(code)) {
      this.promoStatus.push(code);
    } else if (!statusPromo) {
      this.promoStatus = this.promoStatus.filter((item) => item !== code);
    }
  };

  render() {
    const {
      loading_stack,
      location: { state },
      history,
      classes,
      bonus_sub,
    } = this.props;
    const {
      value,
      check,
      modalMin,
      modalBook,
      modalSkipPromo,
      productData,
      min_sub,
      syaratLink,
      user_goal_id,
      totalAmount,
      goals,
    } = this.state;
    const titlePage = this.isBooking ? "PEMESANAN" : "PEMBELIAN";
    const is_smartPlan = state.product_variety === "CG";
    const true_condition = this.is_robo || is_smartPlan || user_goal_id;
    const is_check_promo = processHelper.findProcess(
      "check_voucher",
      loading_stack
    );
    const can_submit = !(check && value && true_condition && !is_check_promo);
    const with_select_goal = this.once && !this.is_robo;
    const list_product_code = Object.keys(state.products);
    return (
      <TempMain title={titlePage}>
        <ContainerWithLoader
          processName={["product_list", "set_content", "check_bonus"]}
          loadingHeight="80vh"
        >
          <div className="margin-main pt-3 px-3 pt-md-4 px-md-4">
            {with_select_goal && (
              <ContentSelectGoals
                onChange={this.handleGoalsId}
                value={user_goal_id}
                goal_list={goals}
                isSmartPlan={is_smartPlan}
                isRDP={this.is_RDP}
              />
            )}
            <Fragment>
              <div
                className={`bg-white shadow-card ${this.is_robo && "p-2 pt-3"}`}
                style={{ borderRadius: "4px 4px 0 0" }}
              >
                {this.is_robo && (
                  <div className="d-flex align-items-center ml-2">
                    <p className="basic fs-12">Rekomendasi ROBO</p>
                    <IconRobo className="ml-2" />
                  </div>
                )}
                <div className={classNames(classes.contentSide, "py-2")}>
                  {this.is_robo &&
                    productData.map((data, index) => (
                      <CardProductRobo
                        key={index}
                        data={data}
                        isFirst={!index}
                      />
                    ))}
                </div>
              </div>
              <SubscriptInput
                ref={this.input}
                products={productData}
                onStatus={this.handleValue}
                initialState={state?.total_amount}
                getValue={(value) =>
                  this.setState((state) => ({ ...state, totalAmount: value }))
                }
                classContainInput="d-flex align-items-start flex-column-reverse flex-md-row"
                sideContent={
                  !this.is_robo && (
                    <CardProductCustom
                      items={productData[0]}
                      className="mb-3 mb-md-0 flex ml-0 ml-md-5"
                      composition={[
                        { render: "name", md: 4 },
                        { render: "summary", md: 7 },
                      ]}
                      onClick={() =>
                        this.handleDetailClick(productData[0].code)
                      }
                    />
                  )
                }
              >
                {this.is_robo &&
                  productData.map((data, index) => (
                    <div className="mb-3 card-custom" key={index}>
                      <Grid
                        container
                        justify="space-between"
                        alignItems="center"
                        className={classNames(
                          "flex-md-row-reverse",
                          classes.cardProductMulti
                        )}
                      >
                        <Grid item xs={12} md={8}>
                          <CardProductCustom
                            key={index}
                            items={data}
                            onClick={() => this.handleDetailClick(data?.code)}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={3}
                          className="mt-2 pt-1 mt-md-0 pt-md-0"
                        >
                          <ContentCardAmount
                            totalAmount={totalAmount}
                            amount={data?.amount}
                            className="mr-3"
                          />
                        </Grid>
                      </Grid>
                      <CardPromo
                        variant="multi"
                        product_code={data.code}
                        id={state.promo?.[data.code]}
                        amount={totalAmount * (data?.amount / 100)}
                        onUpdateStatus={this.handleUpdateStatusPromo}
                      />
                    </div>
                  ))}
              </SubscriptInput>
              {!this.is_robo && (
                <Grid container spacing={4} className="mt-1">
                  <Grid item xs={12} lg={4} md={6}>
                    <CardPromo
                      id={state.promo?.[list_product_code[0]]}
                      product_code={list_product_code[0]}
                      amount={totalAmount}
                      onUpdateStatus={this.handleUpdateStatusPromo}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={4} className="mt-2 mb-n4">
                <CardCashBack
                  data={productData}
                  cashback={bonus_sub}
                  totalAmount={totalAmount}
                  getData={this.handleBonusChange}
                />
              </Grid>
            </Fragment>
            {syaratLink && (
              <CheckButtonOrder
                rootClass="mb-2 mb-md-0 bg-transparent mt-5"
                handleBack={history.goBack}
                handleCheck={this.onCheck}
                handleSubmit={this.onSubmit}
                buttonContainerClass="pl-0 pl-md-5"
                radiusTopUnset={true}
                syaratLink={syaratLink}
                loadingNext={processHelper.findProcess(
                  "set_payment",
                  loading_stack
                )}
                prosLink={
                  productData.length > 0
                    ? productData[0].product_file
                      ? productData[0].product_file.path
                      : "/"
                    : "/"
                }
                disabledNext={can_submit}
              />
            )}
          </div>
        </ContainerWithLoader>
        <DialogCustom
          open={modalMin}
          handleClose={this.handleModal}
          onClick={() => this.handleModal(false)}
          buttonText="OK"
          title="PERINGATAN"
          text={`Minimal pembelian Rp${textHelper.printMoneyInput(min_sub)}`}
        />
        <DialogCustom
          open={modalBook}
          handleClose={this.handleModalBook}
          onClick={this.onBooking}
          buttonText="OK"
          title="PENTING"
          titleClass={classNames(
            classes.modalBookTitle,
            "fs-16 font-weight-bold mb-3 text-center"
          )}
          textClass={classes.modalBookContent}
          text={
            <>
              saya SETUJU:
              <ol>
                <li>Melakukan pembelian pada saat MASA PENAWARAN;</li>
                <li>Pembelian tidak dapat dibatalkan;</li>
                <li>
                  Manajer Investasi tidak akan memberikan bunga atau hasil
                  investasi dana yang saya transfer hingga TANGGAL EMISI reksa
                  dana terproteksi.
                </li>
              </ol>
            </>
          }
        />
        <DialogCustom
          open={modalSkipPromo}
          handleClose={this.onCloseModalSkipPromo}
          onClick={() =>
            this.setState({ modalSkipPromo: false }, this.onSuccessSubmit)
          }
          onCancel={this.onCloseModalSkipPromo}
          buttonTextCancel="UBAH"
          buttonText="LANJUT"
          title="MINIMAL PEMBELIAN"
          text="Nominal pembelian kurang dari ketentuan promo. Lanjutkan tanpa menggunakan promo?"
        />
        <DialogTnC ref={this.refTnC} />
      </TempMain>
    );
  }
}

export default compose(
  connect(mapState, {
    set_product,
    check_bonus_props,
    order_data,
    gbl_act_lc,
    booking_lc,
  }),
  withRouter,
  withStyles(styles)
)(OrderToBuy);
