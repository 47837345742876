import * as types from "../actions/types";
import { requestAxios, requestPostAxios } from "../../API/request";
import url from "../../API/url";
import { createLogic } from "redux-logic";
import {
  get_data_option,
  set_menu,
  chart_color,
  header_search,
  set_data_option_child,
} from "../actions/option";
import { set_loading, remove_loading, set_result } from "../actions/state";
import { batch } from "react-redux";
import { register } from "../actions/auth";
import textHelper from "../../../helper/textHelper";

export const getOption = createLogic({
  type: types.SET_OPTION_PROPS,
  latest: true,
  validate({ getState, action }, allow, reject) {
    const { optionReducer } = getState();
    if (action.payload !== "") {
      if (optionReducer[action.payload].length === 0) {
        allow(action);
      } else {
        reject(action);
      }
    } else {
      allow(action);
    }
  },
  process({ action }, dispatch, done) {
    dispatch(set_loading("get_option"));
    let data = {};
    if (action.payload !== "") {
      data = {
        "param[]": action.payload,
      };
    }
    requestAxios(url.OPTION.data, data)
      .then((e) => {
        console.log(e);
        dispatch(get_data_option(e.data));
      })
      .catch((err) => {
        const error = {
          name: "get_option",
          data: { type: "error", msg: err.message },
        };
        dispatch(set_result(error));
      })
      .then(() => {
        dispatch(remove_loading("get_option"));
        done();
      });
  },
});

export const setMenu = createLogic({
  type: types.SET_MENU_PROPS,
  latest: true,
  validate({ getState, action }, allow, reject) {
    const { menu } = getState().optionReducer;
    if (menu.length === 0) {
      allow(action);
    } else {
      reject(action);
    }
  },
  process({ action }, dispatch, done) {
    dispatch(set_loading("set_menu"));
    requestAxios(url.CONTENT.menu_list)
      .then((e) => {
        console.log(e);
        dispatch(set_menu(e.data));
      })
      .catch((err) => {
        const error = {
          name: "set_menu",
          data: { type: "error", msg: err.message },
        };
        dispatch(set_result(error));
      })
      .then(() => {
        dispatch(remove_loading("set_menu"));
        done();
      });
  },
});

export const faq = createLogic({
  type: types.SET_FAQ,
  latest: true,
  validate({ getState, action }, allow, reject) {
    const type = action.payload;
    const { optionReducer } = getState();
    if (type && optionReducer[type || "faq"]?.length > 0) reject(action);
    else allow(action);
  },
  process({ action }, dispatch, done) {
    const name = action.payload || "faq";
    console.log(name);
    dispatch(set_loading(name));
    requestAxios(url.CONTENT.faq)
      .then((res) => {
        const { data } = res;
        let _res = {};
        data.forEach((el) => {
          _res[el.name] = el.faq;
        });
        dispatch(get_data_option(_res));
      })
      .catch((err) =>
        dispatch(
          set_result({ name, data: { type: "error", msg: err.message } })
        )
      )
      .then(() => {
        dispatch(remove_loading(name));
        done();
      });
  },
});

export const fetchAppConfig = createLogic({
  type: types.SET_APP_CONFIG,
  latest: true,
  validate({ getState, action }, allow, reject) {
    const { config } = getState().optionReducer;
    const { all, params } = action.payload || {};
    let _params = params;
    if (!params && Object.keys(config).length && !all) reject(action);
    else {
      if (all) _params = { with_option: 1, with_dashboard: 1, with_menu: 1 };
      action.payload = _params;
      allow(action);
    }
  },
  process({ getState, action }, dispatch, done) {
    const { payload } = action;
    const name = "app_config";
    dispatch(set_loading(name));
    requestAxios(url.CONTENT.config, payload)
      .then((res) => {
        const { config, menu, option_data, dashboard } = res.data;
        batch(() => {
          if (menu) {
            dispatch(set_menu(menu));
          }
          if (config) {
            const _res = {
              dashboard: config.dashboard_display,
              main: config.main_display,
              system: config.system,
              suport: config.other,
            };
            dispatch(get_data_option({ config: _res }));
          }
          if (option_data) {
            dispatch(get_data_option(option_data));
          }
          if (dashboard) {
          }
        });
      })
      .catch((err) => {
        dispatch(
          set_result({ name, data: { type: "error", msg: err.message } })
        );
      })
      .then(() => {
        dispatch(remove_loading(name));
        done();
      });
  },
});
export const getGoals = createLogic({
  type: types.SET_GOALS,
  latest: true,
  process({ action }, dispatch, done) {
    const name = "goals";
    dispatch(set_loading(name));
    requestAxios(url.GOALS.goals_list)
      .then((res) => {
        let _res = [];
        _res[name] = res.data;
        dispatch(get_data_option(_res));
      })
      .catch((err) => {
        dispatch(
          set_result({ name, data: { type: "error", msg: err.message } })
        );
      })
      .then(() => {
        dispatch(remove_loading(name));
        done();
      });
  },
});

export const chartColor = createLogic({
  type: types.CHART_COLOR_PROPS,
  latest: true,
  process({ action }, dispatch, done) {
    dispatch(set_loading("chart_color"));
    requestAxios(url.CONTENT.chart_color)
      .then((resp) => {
        dispatch(chart_color(Array.isArray(resp.data) ? resp.data : []));
      })
      .catch((err) => {
        const error = {
          name: "chart_color",
          data: { type: "error", msg: err.message },
        };
        dispatch(set_result(error));
      })
      .then(() => {
        dispatch(remove_loading("chart_color"));
        done();
      });
  },
});

export const dataLocation = createLogic({
  type: types.SET_DATA_LOCATION,
  validate({ action }, allow, reject) {
    const id = action.payload;
    if (action.payload) {
      action.payload = { id };
      allow(action);
    } else reject(action);
  },
  process({ action }, dispatch, done) {
    const name = "set-data-location";
    dispatch(set_loading(name));
    requestAxios(url.OPTION.data_by_location_id, action.payload)
      .then((res) => {
        const { data, meta } = res.data;
        const { search_type, parent } = meta;
        let key = search_type.toLowerCase().replace(/ /g, "_");
        batch(() => {
          dispatch(remove_loading(name));
          if (key === "city_list") {
            dispatch(get_data_option({ [key]: data }));
          } else
            dispatch(
              set_data_option_child({ key, data: { [parent.id]: data } })
            );
        });
      })
      .catch((err) => {
        batch(() => {
          dispatch(remove_loading(name));
          dispatch(
            set_result({ name, data: { type: "error", msg: err.message } })
          );
        });
      })
      .then(done);
  },
});

export const headerSearch = createLogic({
  type: types.HEADER_SEARCH_PROPS,
  process({ action }, dispatch, done) {
    const { mode, value } = action.payload;
    if (mode === "mode") {
      dispatch(header_search({ key: "headerMode", value }));
    } else {
      dispatch(header_search({ key: "headerSearch", value }));
    }
    done();
  },
});

export const setContent = createLogic({
  type: types.CONTENT,
  latest: true,
  validate({ getState, action }, allow, reject) {
    const { optionReducer } = getState();
    const { payload } = action;
    const arr = [
      "syarat_ketentuan",
      "syarat_sub",
      "syarat_subCG",
      "syarat_sub1",
      "syarat_sub2",
      "syarat_sub3",
      "syarat_sub4",
      "syarat_sw",
      "syarat_red",
      "kebijakan_privasi",
      "jenis_reksadana",
      "alokasi",
      "about_app",
    ];
    if (arr.indexOf(payload) !== -1) {
      if (optionReducer[payload] === "") {
        allow(action);
      } else {
        reject(action);
      }
    } else {
      reject(action);
    }
  },
  process({ action }, dispatch, done) {
    dispatch(set_loading("set_content"));
    const { payload } = action;
    const data = {
      name: payload,
    };
    requestAxios(url.CONTENT.type, data)
      .then((e) => {
        console.log(e);
        dispatch(get_data_option({ [payload]: e.message }));
      })
      .catch((err) => {
        dispatch(
          set_result({
            name: "set_content",
            data: { type: "error", msg: err.message },
          })
        );
      })
      .then(() => {
        dispatch(remove_loading("set_content"));
        done();
      });
  },
});
export const rate_us = createLogic({
  type: types.FETCH_RATE_US,
  latest: true,
  process({ action }, dispatch, done) {
    const { payload } = action;
    const name = payload ? "rate-us" : "get-rate-us";
    const func = payload ? requestPostAxios : requestAxios;
    dispatch(set_loading(name));
    func(url.other.rate_us, payload)
      .then((res) => {
        batch(() => {
          dispatch(remove_loading(name));
          if (payload) {
            dispatch(
              set_result({ name, data: { type: "success", msg: res.message } })
            );
          }
          dispatch(get_data_option({ rate: payload || res.data }));
        });
      })
      .catch((err) => {
        batch(() => {
          dispatch(remove_loading(name));
          dispatch(
            set_result({ name, data: { type: "error", msg: err.message } })
          );
        });
      })
      .then(done);
  },
});

export const ins_benefit = createLogic({
  type: types.FETCH_INS_BENEFIT,
  latest: true,
  process({ action }, dispatch, done) {
    const name = "ins-benefit";
    dispatch(set_loading(name));
    requestAxios(url.PRODUCT.tiering, action.payload)
      .then((res) => {
        batch(() => {
          dispatch(remove_loading(name));
          dispatch(get_data_option({ ins_benefit: res.data.ins_benefit }));
        });
      })
      .catch((err) => {
        batch(() => {
          dispatch(remove_loading(name));
          dispatch(
            set_result({ name, data: { type: "error", msg: err.message } })
          );
        });
      })
      .then(done);
  },
});

export const ins_info = createLogic({
  type: types.GET_INSURANCE_INFO,
  latest: true,
  process({ action }, dispatch, done) {
    const name = "insurance_info";
    dispatch(set_loading(name));
    requestAxios(url.PRODUCT.insurance_info, action.payload)
      .then((res) => {
        batch(() => {
          dispatch(remove_loading(name));
          dispatch(get_data_option({ ins_info: res.data }));
        });
      })
      .catch((err) => {
        batch(() => {
          dispatch(remove_loading(name));
          dispatch(
            set_result({ name, data: { type: "error", msg: err.message } })
          );
        });
      })
      .then(done);
  },
});

export const upload = createLogic({
  type: types.UPLOAD,
  latest: true,
  process({ action }, dispatch, done) {
    const { file, key } = action.payload;
    const data = {
      image: file,
    };
    const loadName = "upload";
    dispatch(set_loading(loadName));
    requestPostAxios(url.AUTH.upload_selfie, data)
      .then((resp) => {
        dispatch(register({ [key]: resp.data.url }));
      })
      .catch((err) => {
        dispatch(
          set_result({
            name: loadName,
            data: { type: "error", msg: err.message },
          })
        );
      })
      .then(() => {
        dispatch(remove_loading(loadName));
        done();
      });
  },
});

export const fetchRestrictImage = createLogic({
  type: types.FETCH_RESTRICT_IMAGE,
  latest: true,
  process({ getState, action }, dispatch, done) {
    let { urlImg, key } = action.payload;
    let token = getState().authReducer.token;
    const loadName = "fetch_restrict_image";
    urlImg = urlImg + "/" + textHelper.printRandomTime() + ".jpg";
    dispatch(set_loading(loadName));
    fetch(urlImg, {
      headers: { Authorization: token },
    })
      .then((resp) => resp.blob())
      .then(async (e) => {
        // console.log(e);
        dispatch(register({ [key]: URL.createObjectURL(e) }));
        // let img = await requestPostAxios(url.AUTH.upload_selfie, {
        //   image: e,
        // })
        //   .then((resp) => {
        //     console.log(resp);
        //     return resp.data.url;
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //     return "failed";
        //   });
        // if (img !== "failed") dispatch(register({ [key]: img }));
        // else throw img;
      })
      // .catch((err) => {
      //   dispatch(
      //     set_result({ name: loadName, data: { type: "error", msg: err } })
      //   );
      // })
      .then(() => {
        dispatch(remove_loading(loadName));
        done();
      });
  },
});
