import React from "react";
import { SvgIcon } from "@material-ui/core";

const icons = {
  faq: (
    <path
      id="Path_943"
      data-name="Path 943"
      width="24"
      height="24"
      d="M8,0a8,8,0,1,0,8,8A8.024,8.024,0,0,0,8,0ZM7.92,13.28a1.12,1.12,0,1,1,1.12-1.12A1.094,1.094,0,0,1,7.92,13.28Zm2.16-6.24L9.2,8a.977.977,0,0,0-.32.72.973.973,0,0,1-.96.96.924.924,0,0,1-.96-.96A1.785,1.785,0,0,1,7.6,7.28l.72-.88a1.816,1.816,0,0,0,.56-1.12A.839.839,0,0,0,8,4.4a.941.941,0,0,0-.96.72,1.045,1.045,0,0,1-.96.56A.839.839,0,0,1,5.2,4.8a.87.87,0,0,1,.08-.4A2.666,2.666,0,0,1,8,2.72a2.592,2.592,0,0,1,2.8,2.56A2.625,2.625,0,0,1,10.08,7.04Z"
    />
  ),
  codePromo: (
    <>
      <rect
        id="Rectangle_554"
        data-name="Rectangle 554"
        width="16"
        height="16"
        fill="none"
      />
      <path
        id="Path_148"
        data-name="Path 148"
        d="M.267,10.133C.133,9.867,0,9.733,0,9.467A1.018,1.018,0,0,1,.267,8.8L8.933.133A.131.131,0,0,1,9.067,0h5.867A1.018,1.018,0,0,1,15.6.267a.95.95,0,0,1,.4.8V6.933c0,.133,0,.133-.133.267L7.2,15.867A5.775,5.775,0,0,1,6.533,16a1.018,1.018,0,0,1-.667-.267ZM11.467,2.4a1.646,1.646,0,0,0-.533,1.067,1.514,1.514,0,0,0,.4,1.067,1.622,1.622,0,0,0,2.133,0,1.514,1.514,0,0,0,.4-1.067A1.437,1.437,0,0,0,13.6,2.4a1.622,1.622,0,0,0-2.133,0Z"
      />
    </>
  ),
  file: (
    <path
      id="document001_-_E0AD"
      data-name="document001 - E0AD"
      d="M-2.838-24.2V-30.59H-8.5v16.331H3.478V-24.2H-2.838Zm.627-6.306V-24.9H3.4Z"
      transform="translate(8.503 30.59)"
    />
  ),
  glosarium: (
    <>
      <rect
        id="Rectangle_176"
        data-name="Rectangle 176"
        width="16"
        height="16"
        fill="none"
      />
      <path
        id="Path_99"
        data-name="Path 99"
        d="M15.9,14.5l-3.3-3.3A6.847,6.847,0,0,0,14,7,6.957,6.957,0,0,0,7,0,6.957,6.957,0,0,0,0,7a6.957,6.957,0,0,0,7,7,6.847,6.847,0,0,0,4.2-1.4l3.3,3.3ZM2,7A4.951,4.951,0,0,1,7,2a4.951,4.951,0,0,1,5,5,4.951,4.951,0,0,1-5,5A4.951,4.951,0,0,1,2,7Z"
      />
    </>
  ),
  logout: (
    <>
      <rect
        id="Rectangle_570"
        data-name="Rectangle 570"
        width="16"
        height="16"
        fill="none"
      />
      <path
        id="Path_151"
        data-name="Path 151"
        d="M1.655,1.655v12.69H9.563V16H0V0H9.563V1.655ZM9.011,13.241,7.724,11.954l2.943-2.943h-6.8V7.172h6.989L7.724,4.23,9.011,2.943l4.966,4.966Z"
        transform="translate(2)"
      />
    </>
  ),
  person: (
    <path
      id="Union_1"
      data-name="Union 1"
      d="M0,370v-2c0-2.2,3.6-4,8-4s8,1.8,8,4v2Zm4-12a4,4,0,1,1,4,4A4,4,0,0,1,4,358Z"
      transform="translate(0 -354)"
    />
  ),
  referralCode: (
    <path
      id="Icon_awesome-boxes"
      data-name="Icon awesome-boxes"
      d="M15.556,8H13.333v2.667l-.889-.592-.889.592V8H9.333a.446.446,0,0,0-.444.444v5.333a.446.446,0,0,0,.444.444h6.222A.446.446,0,0,0,16,13.778V8.444A.446.446,0,0,0,15.556,8ZM4.889,6.222h6.222a.446.446,0,0,0,.444-.444V.444A.446.446,0,0,0,11.111,0H8.889V2.667L8,2.075l-.889.592V0H4.889a.446.446,0,0,0-.444.444V5.778A.446.446,0,0,0,4.889,6.222ZM6.667,8H4.444v2.667l-.889-.592-.889.592V8H.444A.446.446,0,0,0,0,8.444v5.333a.446.446,0,0,0,.444.444H6.667a.446.446,0,0,0,.444-.444V8.444A.446.446,0,0,0,6.667,8Z"
    />
  ),
  securitytips: (
    <>
      <path
        id="Path_4021"
        data-name="Path 4021"
        d="M10571.443-1204.056h-4.19l-.6,7.108.6,8.334a10.557,10.557,0,0,0,6.84-9.882v-2.911A3.616,3.616,0,0,1,10571.443-1204.056Z"
        transform="translate(-10560.415 1204.056)"
      />
      <path
        id="Path_4022"
        data-name="Path 4022"
        d="M10456.1-1204.056a3.617,3.617,0,0,1-2.65,2.649v2.911a10.559,10.559,0,0,0,6.839,9.882v-15.441Z"
        transform="translate(-10453.454 1204.056)"
      />
      <path
        id="Path_4025"
        data-name="Path 4025"
        d="M10569.4-1066.146h.9v2.158h-.9Z"
        transform="translate(-10563.012 1073.742)"
        fill="#fff"
      />
      <path
        id="Path_4026"
        data-name="Path 4026"
        d="M10577.469-1066.146h.461v2.158h-.461Z"
        transform="translate(-10570.638 1073.742)"
        fill="#fff"
      />
      <path
        id="Path_4027"
        data-name="Path 4027"
        d="M10567.253-1112.252l-.6,1.5.6,1.5a1.5,1.5,0,0,0,1.5-1.5A1.5,1.5,0,0,0,10567.253-1112.252Z"
        transform="translate(-10560.415 1117.309)"
        fill="#fff"
      />
      <path
        id="Path_4028"
        data-name="Path 4028"
        d="M10550.449-1110.759a1.5,1.5,0,0,0,1.5,1.5v-2.992A1.5,1.5,0,0,0,10550.449-1110.759Z"
        transform="translate(-10545.106 1117.312)"
        fill="#fff"
      />
    </>
  ),
  setting: (
    <>
      <rect
        id="Rectangle_193"
        data-name="Rectangle 193"
        width="16"
        height="16"
        fill="none"
      />
      <path
        id="Path_104"
        data-name="Path 104"
        d="M267.5,10a2,2,0,1,0-2-2A2.006,2.006,0,0,0,267.5,10Zm-4.1-6.4a5.64,5.64,0,0,1,2.4-1.3l.8-2.3h2l.8,2.3a6.983,6.983,0,0,1,2.4,1.3l2.4-.5,1,1.8-1.6,1.8a5.7,5.7,0,0,1,.1,1.3c0,.4-.1.9-.1,1.3l1.6,1.8-1,1.8-2.4-.5a5.64,5.64,0,0,1-2.4,1.3l-.8,2.3h-2l-.8-2.3a6.983,6.983,0,0,1-2.4-1.3l-2.4.5-1-1.8,1.6-1.8a5.7,5.7,0,0,1-.1-1.3c0-.4.1-.9.1-1.3L260,4.9l1-1.8Z"
        transform="translate(-259)"
        fillRule="evenodd"
      />
    </>
  ),
  voucher: (
    <path
      id="Icon_awesome-ticket-alt"
      data-name="Icon awesome-ticket-alt"
      d="M3.556,7.167h8.889V12.5H3.556ZM14.667,9.833A1.333,1.333,0,0,0,16,11.167v2.667a1.333,1.333,0,0,1-1.333,1.333H1.333A1.333,1.333,0,0,1,0,13.833V11.167A1.333,1.333,0,0,0,1.333,9.833,1.333,1.333,0,0,0,0,8.5V5.833A1.333,1.333,0,0,1,1.333,4.5H14.667A1.333,1.333,0,0,1,16,5.833V8.5A1.333,1.333,0,0,0,14.667,9.833ZM13.333,6.944a.667.667,0,0,0-.667-.667H3.333a.667.667,0,0,0-.667.667v5.778a.667.667,0,0,0,.667.667h9.333a.667.667,0,0,0,.667-.667Z"
      transform="translate(0 -4.5)"
    />
  ),
  download: (
    <g id="Export" transform="translate(16.178) rotate(90)">
      <rect
        id="Rectangle_570"
        data-name="Rectangle 570"
        width="16"
        height="16"
        fill="none"
      />
      <path
        id="Path_151"
        data-name="Path 151"
        d="M16.28.178v16H14.625v-16ZM6.638,13.29,5.35,12,8.293,9.06h-6.8V7.221H8.477L5.35,4.279,6.638,2.991,11.6,7.957Z"
        transform="translate(-1)"
      />
    </g>
  ),
  profileCircle: (
    <g id="Profile" transform="translate(0.951 0.721)">
      <circle
        id="Ellipse_96"
        data-name="Ellipse 96"
        cx="16"
        cy="16"
        r="16"
        transform="translate(-0.951 -0.721)"
        fill="#fff"
      />
      <g
        id="Symbol_6_1"
        data-name="Symbol 6 – 1"
        transform="translate(10.782 11.012)"
      >
        <path
          id="Union_1"
          data-name="Union 1"
          d="M0,9V7.875c0-1.238,2.025-2.25,4.5-2.25S9,6.637,9,7.875V9ZM2.25,2.25A2.25,2.25,0,1,1,4.5,4.5,2.25,2.25,0,0,1,2.25,2.25Z"
          transform="translate(0.267 0.267)"
          fill="#ef6431"
        />
      </g>
    </g>
  ),
  notification: (
    <path
      id="Path_4055"
      data-name="Path 4055"
      d="M11.809-2.736,10.145-5.519,6.412-3.279,6.48-7.42H3.289l.034,4.141L-.411-5.519-2.04-2.736,1.66-.7l-3.7,2L-.411,4.087l3.734-2.24L3.289,5.987H6.48L6.412,1.88l3.734,2.206L11.809,1.3l-3.7-2Z"
      transform="translate(2.04 7.42)"
    />
  ),
  rateUs: (
    <>
      <path
        id="Path_157"
        data-name="Path 157"
        d="M8,0l2.5,5,5.5.8L12,9.7l.9,5.5L8,12.6,3.1,15.2,4,9.7,0,5.8,5.5,5Z"
      />
      <rect
        id="Rectangle_1449"
        data-name="Rectangle 1449"
        width="16"
        height="16"
        transform="translate(0 0)"
        fill="none"
      />
    </>
  ),
  contactUs: (
    <g
      id="Group_2759"
      data-name="Group 2759"
      transform="translate(-1099 -573.907)"
    >
      <g
        id="Group_2759-2"
        data-name="Group 2759"
        transform="translate(1099 573.907)"
      >
        <rect
          id="Rectangle_1855"
          data-name="Rectangle 1855"
          width="15.438"
          height="10.375"
          transform="translate(15.438 10.375) rotate(-180)"
        />
        <path
          id="Path_4205"
          data-name="Path 4205"
          d="M-726.7,531.993v4.1l5.369-3.442Z"
          transform="translate(729.219 -522.723)"
        />
        <rect
          id="Rectangle_1856"
          data-name="Rectangle 1856"
          width="10.301"
          height="1.17"
          transform="translate(2.517 2.878)"
          fill="#fff"
        />
        <rect
          id="Rectangle_1857"
          data-name="Rectangle 1857"
          width="6.263"
          height="1.17"
          transform="translate(2.517 5.924)"
          fill="#fff"
        />
      </g>
    </g>
  ),
  notifInfo: (
    <g id="Group_2107" data-name="Group 2107" transform="translate(0 0)">
      <circle
        id="Ellipse_1062"
        data-name="Ellipse 1062"
        cx="12.211"
        cy="12.211"
        r="12.211"
        fill="#ef6431"
      />
      <path
        id="Path_4168"
        data-name="Path 4168"
        d="M14075.418,332.215a12.211,12.211,0,0,0,12.211,12.211V320A12.211,12.211,0,0,0,14075.418,332.215Z"
        transform="translate(-14075.418 -320.004)"
        fill="#f5a283"
      />
      <rect
        id="Rectangle_1779"
        data-name="Rectangle 1779"
        width="1.949"
        height="10.094"
        transform="translate(10.262 5.152)"
        fill="#fce0d6"
      />
      <rect
        id="Rectangle_1780"
        data-name="Rectangle 1780"
        width="1.949"
        height="3.546"
        transform="translate(10.299 16.367)"
        fill="#fce0d6"
      />
      <rect
        id="Rectangle_1781"
        data-name="Rectangle 1781"
        width="1.949"
        height="10.094"
        transform="translate(12.211 5.152)"
        fill="#f9c3af"
      />
      <rect
        id="Rectangle_1782"
        data-name="Rectangle 1782"
        width="1.949"
        height="3.546"
        transform="translate(12.248 16.367)"
        fill="#f9c3af"
      />
    </g>
  ),
  notifSuccess: (
    <>
      <g id="Group_2020" data-name="Group 2020" transform="translate(0)">
        <path
          id="Path_4169"
          data-name="Path 4169"
          d="M12.213,0A12.213,12.213,0,1,1,0,12.213,12.213,12.213,0,0,1,12.213,0Z"
          fill="#666"
        />
        <path
          id="Path_4029"
          data-name="Path 4029"
          d="M10623.663-1172.484a12.213,12.213,0,0,0,12.214,12.213V-1184.7A12.213,12.213,0,0,0,10623.663-1172.484Z"
          transform="translate(-10623.663 1184.697)"
          fill="#999"
        />
      </g>
      <g
        id="Group_2021"
        data-name="Group 2021"
        transform="translate(4.492 6.496)"
      >
        <path
          id="Path_4030"
          data-name="Path 4030"
          d="M10659.763-1152.83l-9.232,9.446-6.21-5.406,1.858-2.2,4.352,3.573,7.151-7.4Z"
          transform="translate(-10644.32 1154.818)"
          fill="#e5e5e5"
        />
        <path
          id="Path_4031"
          data-name="Path 4031"
          d="M10687.559-1152.834l-7.721,7.9v-4.046l5.639-5.837Z"
          transform="translate(-10672.115 1154.82)"
          fill="#ccc"
        />
      </g>
    </>
  ),
  starRating: (
    <path
      id="Path_4203"
      data-name="Path 4203"
      d="M133.7,39.035a.922.922,0,0,0-.744-.627L123.2,36.991l-4.36-8.834a.921.921,0,0,0-1.652,0l-4.36,8.835-9.749,1.417a.921.921,0,0,0-.511,1.571l7.054,6.876-1.665,9.71a.921.921,0,0,0,1.337.971l8.72-4.585,8.72,4.585a.921.921,0,0,0,1.337-.971l-1.666-9.71,7.055-6.876A.922.922,0,0,0,133.7,39.035Z"
      transform="translate(-102.293 -27.643)"
    />
  ),
  mail: (
    <>
      <rect
        id="Rectangle_117"
        data-name="Rectangle 117"
        width="16"
        height="16"
        fill="none"
      />
      <path
        id="Path_50"
        data-name="Path 50"
        d="M14,2H2L8,7ZM0,2A2.006,2.006,0,0,1,2,0H14a2.006,2.006,0,0,1,2,2v8a2.006,2.006,0,0,1-2,2H2a2.006,2.006,0,0,1-2-2Z"
        transform="translate(0 2)"
        fill="#999"
      />
    </>
  ),
  clock: (
    <path
      id="Path_13"
      data-name="Path 13"
      d="M2.4,2.4A7.263,7.263,0,0,1,8,0a7.263,7.263,0,0,1,5.6,2.4A7.263,7.263,0,0,1,16,8a7.263,7.263,0,0,1-2.4,5.6A7.263,7.263,0,0,1,8,16a7.263,7.263,0,0,1-5.6-2.4A7.984,7.984,0,0,1,0,8,7.263,7.263,0,0,1,2.4,2.4Zm9.2,9.2.933-.933L9.2,7.333,8,2H6.667V8a1.21,1.21,0,0,0,.4.933.466.466,0,0,0,.267.133Z"
      fill="#999"
    />
  ),
  check_list: (
    <g id="check-2" transform="translate(1.414 1.414)">
      <path
        id="Path_871"
        data-name="Path 871"
        d="M1,8.2l3.467,3.467L13.133,3"
        transform="translate(-1 -3)"
        fill="none"
        stroke="#ef6431"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </g>
  ),
};

const MenuProfileIcon = (props) => {
  const { className, icon, style, ...other } = props;
  if (typeof props.icon !== "string") {
    return icon;
  }
  return (
    <SvgIcon className={className} style={style} {...other}>
      {icons[icon]}
    </SvgIcon>
  );
};
export default MenuProfileIcon;
