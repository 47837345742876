import config from "../constant/config";

export const url = config.URL;
const base_url = url + "apiv2/";
const base_url_1 = url + "v3/api/retail/";
const base_url_1_gbl = url + "v3/api/";

export default {
  url: url,
  AUTH: {
    request_otp: base_url + "register/request_otp",
    validate_otp: base_url + "register/validate_otp",
    register_identity: base_url + "register/identity",
    register_profile: base_url + "register/profile",
    register_ttd: base_url + "register/sign",
    register_pin: base_url + "register/pin",
    register_validation: base_url + "register/validation",
    re_register: base_url + "register/reset",
    forgot_pass: base_url + "auth-forgot-passwordv",
    validate_forgot_pass: base_url + "auth-validate-password",
    forgot_change_pass: base_url + "auth-change-passwordv",
    change_pass: base_url + "auth-change-pwd",
    change_pin: base_url + "auth-change-pin",
    forgot_pin: base_url + "auth-forgot-pin",
    validate_pin: base_url + "auth-validate-pin",
    login: base_url + "auth-login",
    logout: base_url_1_gbl + "auth/logout",
    upload_ktp: base_url + "auth-upload-ktp",
    upload_selfie: base_url + "uploads/selfie",
    reSendVerfication: base_url + "auth-resend-email",
    delete_account: base_url_1 + "delete-account",
    update_mother_name: base_url + "update-mother-name",
  },
  BLOG: {
    list: base_url + "blogs",
    content: base_url + "blog",
  },
  PROFILE: {
    user_bank: {
      update: base_url_1_gbl + "user/bank-account/update",
      delete: base_url_1_gbl + "user/bank-account/remove",
      add: base_url + "create-user-bank",
      get: base_url + "get-user-bank",
      update_priority: base_url + "set-user-bank",
    },
    user_waris: {
      update: base_url + "update-user-waris",
      add: base_url + "create-user-waris",
      delete: base_url + "delete-user-waris",
      get: base_url + "get-user-waris",
    },
    profile_risk: {
      update: base_url_1 + "update-profile-risk",
      // update2: base_url + "profile-risk",
      result_by_point: base_url_1 + "profile-risk/point",
      result: base_url_1 + "profile-risk/answer",
      get: base_url_1 + "profile-risk",
      get_survey: base_url + "profile-risk-survey",
      recommend_product: base_url + "profile-risk-hasil/product",
    },
    user_profile: base_url + "user-profile",
    update_profile: base_url + "update-user-profile",
    user_dashboard: base_url + "user-dashboardv2",
    new_dashboard: base_url_1 + "dashboard",
    change_phone: base_url + "auth-change-phonev",
    change_phone_verify: base_url + "auth-verify-phonev",
    change_email: base_url + "auth-change-email",
    tax_report: base_url_1 + "tax",
    dividend_report: base_url_1 + "transaction/rdp-report",
    dividend_report_download: base_url_1 + "transaction/rdp-report-download",
    voucher: {
      list: base_url_1 + "voucher/list",
      detail: base_url_1 + "voucher/detail",
      check: base_url_1 + "voucher/check",
    },
    document_report: base_url_1 + "user/documents",
  },
  PRODUCT: {
    products: base_url + "products",
    top: base_url + "products/top",
    log: base_url + "product-log",
    compare: base_url + "compare-product",
    history: base_url + "products/history",
    product_type: base_url + "products/type",
    trending: base_url + "products/trending",
    query_product: base_url + "list/products",
    tiering: base_url + "products/insurance/tiering",
    insurance_info: base_url_1_gbl + "custody/product/insurance-info",
  },
  OPTION: {
    data: base_url + "data",
    bank: base_url + "data/bank",
    data_by_location_id: base_url + "data/city",
  },
  CART: {
    list: base_url + "cart",
    delete: base_url + "cart-delete",
  },
  VOUCHER: {
    voucher_validae: base_url + "voucher-validate",
    my_voucher: base_url + "my-voucher",
    voucher_list: base_url + "vouchers",
    buy_voucher: base_url + "voucher-buy",
    merchant: {
      list: base_url_1 + "voucher-merchant",
      detail: base_url_1 + "voucher-merchant",
      categories: base_url_1 + "voucher-merchant/merchant-category",
      redeem: base_url_1 + "voucher-merchant/redeem",
    },
  },
  TXN: {
    check_txn_point: base_url + "txn-sy-point",
    // subscript_multiple: base_url + "txn-subscripts",
    tier_subs: base_url + "transactions/promo",
    validate_redeem: base_url + "txn-redeem-validate",
    validate_switch: base_url + "txn-switch-validate",
    available_payment: base_url + "available-payment-new",
    history: base_url + "transactions",
    history_count: base_url + "transactions-count",
    // confirm_payment: base_url + "txn-confirm-payment",
    confirm_payment: base_url_1 + "payment/confirm",

    booking: base_url_1 + "transaction/rdp-booking",
    redeem: base_url_1 + "transaction/redeem",
    switch: base_url_1 + "transaction/switch",
    subscript: base_url_1 + "transaction/subscript",
    grouping_list: base_url_1 + "transaction/groups",
    list: base_url_1 + "transaction",
  },
  PORTO: {
    list: base_url + "portfolios-new",
    summary: base_url + "portfolios_summary",
    statement_list: base_url + "portfolios/statements",
    account_statement: base_url + "portfolios/statements_download",
    porto_detail: base_url + "portfolios/detail",
  },
  OTP: {
    request_new_otp: base_url + "request-new-otp",
    resend_otp: base_url + "resend-otp",
    validate_otp: base_url + "validate-otpv",
  },
  POINT: {
    history: base_url + "",
  },
  NOTIF: {
    list: base_url + "notifications",
    delete: base_url + "notification-delete",
    click: base_url + "notification-click",
  },
  CONTENT: {
    faq: base_url + "faq",
    type: base_url + "content",
    menu_list: base_url + "content/menu",
    first_news: base_url + "content/popup",
    config: base_url + "content/config",
    chart_color: base_url + "chart/color",
    crypt: base_url_1_gbl + "crypt",
  },
  GOALS: {
    goals_list: base_url + "goals",
    goals_param: base_url + "goals/param",
    add_goals: base_url_1 + "crypt",
  },
  GOAL: {
    list: base_url_1 + "goals",
    icons: base_url_1 + "goals/icon",
    porto_summary: base_url_1 + "goals/user/portofolio-summary",
    user: base_url_1 + "goals/user",
    user_all_sub: base_url_1 + "goals/user/name-only",
    user_sub: base_url_1 + "goals/user/regular-goals",
    user_add: base_url_1 + "goals/user/add",
    user_update: base_url_1 + "goals/user/update",
    user_delete: base_url_1 + "goals/user/delete",
    user_switch: base_url_1 + "goals/user/switch",
    user_track: base_url_1 + "goals/user/track",
    user_track_recalculate: base_url_1 + "goals/user/track/recalculate",
    planner: base_url_1 + "goals/planner/user",
    planner_add_bulk: base_url_1 + "goals/planner/add-bulk",
    planner_add_single: base_url_1 + "goals/planner/add-single",
    planner_update: base_url_1 + "goals/planner/update",
    planner_delete: base_url_1 + "goals/planner/delete",
    planner_replace: base_url_1 + "goals/planner/replace",
    monthly_notif: base_url_1 + "goals/user/send-notif-monthly",
    goal_robo_alc: base_url_1 + "goals/robo-allocations",
    periodic_add: base_url_1 + "goals/user/add-periodic-investment",
    periodic_update: base_url_1 + "goals/user/update-periodic-investment",
    periodic_list: base_url_1 + "goals/user/list-periodic",
    periodic_delete: base_url_1 + "goals/user/delete-periodic-investment",
    update_periodic_invest:
      base_url_1 + "goals/user/update-periodic-investment",
    periodic_notif: base_url_1 + "goals/user/send-notif-periodic-investment",
  },
  unused: {
    upload_ktp: base_url + "ocrv2",
  },
  other: {
    rate_us: base_url + "rate-us",
    popup_banner: base_url_1 + "banners",
  },
  PARTNER: {
    ultra_voucher: base_url_1_gbl + "partner/ultravoucher",
  },
};
