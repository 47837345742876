import { Grid, makeStyles } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React from "react";
import BasicText from "../../atom/BasicText/BasicText";
import SelectsInput from "../MaterialInput/SelectsInput";
import * as flag from "../../../assets/img/flag";

const numberIds = [
  {
    negara: "Indonesia",
    code_negara: "IDN",
    name: "+62",
    id: "62",
    image: "indonesia",
  },
  {
    negara: "United States America",
    code_negara: "USA",
    name: "+1",
    id: "1",
    image: "us",
  },
];

const useStyles = makeStyles({
  contain: {
    width: 106,
  },
  containD: {
    width: 120,
  },

  containSelect: {
    width: 33,
    height: 21.3,
    paddingLeft: 8,
    paddingRight: "13px !important",
    display: "flex",
  },
  iconArrow: {
    position: "absolute",
    left: -22,
  },
  flag: {
    width: 36,
    marginRight: 23,
  },
});

const ArrowSelect = () => {
  const classes = useStyles();
  return <ExpandMore fontSize="small" className={classes.iconArrow} />;
};

const SelectedNumberId = ({ value, onChange,id }) => {
  const classes = useStyles();
  return (
    <Grid container alignItems="center" className={classes.containD} item>
      <img className={classes.flag} src={flag[numberIds.find((item) => item.id === value)?.image]} alt="" />
      <SelectsInput
        id={`select-code-number-${id}`}
        value={value}
        onChange={onChange}
        IconComponent={ArrowSelect}
        data={numberIds}
        variant="outlined"
        renderItem={(el) => (
          <BasicText className="fs-18 gray-5 mx-auto">{el.name}</BasicText>
        )}
        className={classes.containSelect}
      />
    </Grid>
  );
};

export default SelectedNumberId;
