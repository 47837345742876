import React from "react";
import TopSummary from "../../organism/MainPage/TopSummary";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
const useStyles = makeStyles(theme => ({
  section2: {
    backgroundColor: theme.palette.background.default
  }
}));

const TempDashboardMobile = props => {
  const classes = useStyles();
  const { children, data } = props;
  const {
    isLogin,
    mainMenu,
    topReksa,
    trendingScroll
    // articleScroll,
    // knowledgeLink
  } = data;
  return (
    <div className="margin-main">
      <TopSummary isLogin={isLogin} />
      <div className="">{mainMenu}</div>
      <div className={classNames(classes.section2, "px-4 py-3 mb-3")}>
        {topReksa}
        {trendingScroll}
      </div>
      {/* <div className="px-4 mb-3">{articleScroll}</div>
      <div className="px-4">{knowledgeLink}</div> */}
      {children}
    </div>
  );
};

export default TempDashboardMobile;
