import { createLogic } from "redux-logic";
import * as types from "../actions/types";
import { requestAxios } from "../../API/request";
import url from "../../API/url";
import { set_loading, remove_loading, set_result } from "../actions/state";
import { set_article_list } from "../actions/article";
import { portofolio_summary } from "../actions/portofolio";
import { set_profile_data } from "../actions/profile";
import { get_data_option } from "../actions/option";

export const userLogic = createLogic({
  type: types.SET_USER,
  latest: true,
  async process({ action }, dispatch, done) {
    dispatch(set_loading("user_dashboard"));
    await requestAxios(url.PROFILE.user_dashboard)
      .then((e) => {
        const {
          user_type,
          can_txn,
          syailendra_point,
          notification_count,
          my_voucher_count,
          register_progress,
          portfolio_summary,
          alert,
          txn_count,
        } = e.data;
        console.log(e);
        const _profile = {
          user_type,
          can_txn,
          syailendra_point,
          notification_count,
          my_voucher_count,
          regis_status: register_progress,
          alert,
          txn_count: txn_count.waiting,
          is_show_porto: register_progress.is_show_porto || true,
        };
        localStorage.setItem("txn_count", txn_count.waiting);
        dispatch(portofolio_summary(portfolio_summary));
        dispatch(set_profile_data(_profile));
      })
      .catch((err) => {
        let error = {
          name: "dashboard",
          data: { type: "error", msg: err.message },
        };
        dispatch(set_result(error));
      })
      .then(() => {
        dispatch(remove_loading("user_dashboard"));
        done();
      });
  },
});

export const getBlogs = createLogic({
  type: types.SET_ARTICLE,
  latest: true,
  process({ action }, dispatch, done) {
    dispatch(set_loading("get_blogs"));
    const data = {
      type: action.payload,
    };
    requestAxios(url.BLOG.list, data)
      .then((e) => {
        dispatch(set_article_list(e.data));
      })
      .catch((err) => {
        let error = {
          name: "get_blogs",
          data: { type: "error", msg: err.message },
        };
        dispatch(set_result(error));
      })
      .then(() => {
        dispatch(remove_loading("get_blogs"));
        done();
      });
  },
});

export const dash = createLogic({
  type: types.DASH_LC,
  latest: true,
  process({ action }, dispatch, done) {
    dispatch(set_loading("dashboard"));
    requestAxios(url.PROFILE.new_dashboard)
      .then(({ data }) => {
        dispatch(get_data_option({ dashboard: data }));
      })
      .catch((err) => {
        let error = {
          name: "dashboard",
          data: { type: "error", msg: err.message },
        };
        dispatch(set_result(error));
      })
      .finally(() => {
        dispatch(remove_loading("dashboard"));
        done();
      });
  },
});
