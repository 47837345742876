import React from 'react'
import useModal from '../../atom/hooks/useModal'
import useProfileRiskData from '../../atom/hooks/useProfileRiskData'
import DialogCustom from '../../organism/Container/DialogCustom'
import TempMain from '../../template/TempMain'
import { useHistory } from "react-router-dom";

const RiskPage = () => {
    const { push } = useHistory()
    const [chartColor, profileRisk] = useProfileRiskData()
    const { modal, onClose } = useModal()
    const handleSubmit = () => push('/profil-risiko/change', { point: profileRisk.profile_risk_point })
    return (
        <TempMain
            bottomNavValue={4}
            title="PROFIL"
            page="risk"
            data={{
                chartColor,
                profileRisk,
                button: {
                    text: "UBAH",
                    onClick: () => onClose(true)
                }
            }}
        >
            <DialogCustom
                open={modal}
                handleClose={onClose}
                title={<span className="color-primary font-weight-bold mt-4">PENTING</span>}
                text={
                    <p className="mb-3">
                        Perubahan Profil risiko kamu tidak<br />
                        sesuai dengan produk reksa dana yang<br /> kamu miliki.
                    </p>
                }
                buttonText="MENGERTI"
                onClick={handleSubmit}
                className="default-dialog"
            />
        </TempMain>
    )
}

export default RiskPage
