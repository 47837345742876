import React from "react";
import "../styles.css";
import { useMemo } from "react";
import CardTrending from "../../../../molecule/Trending/CardTrending";
import CardGoalsPlanner from "./CardGoalsPlanner";
import CardInsight from "./CardInsight";

const CardCollection = ({ render, item, onClickItem, style }) => {
  const dfprops = useMemo(
    () => ({
      item,
      className: `list-content ${render}`,
    }),
    [item, render]
  );

  return (
    <>
      {render === "market_insight" ? (
        <CardInsight {...dfprops} />
      ) : render === "video_scor" ? (
        <img
          src={`https://img.youtube.com/vi/${item.yt_id}/0.jpg`}
          alt={item.url}
          className={dfprops.className}
          onDragStart={(e) => e.preventDefault()}
          style={style}
        />
      ) : render === "planner" ? (
        <CardGoalsPlanner style={style} {...dfprops} onClick={onClickItem} />
      ) : render === "trending" ? (
        <CardTrending
          style={style}
          items={item}
          className={dfprops.className}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default CardCollection;
