import { createLogic } from "redux-logic";
import * as types from "../actions/types";
import {
  remove_result,
  set_result,
  set_loading,
  remove_loading,
  set_tmp_key,
} from "../actions/state";
import { setObjFromPath, handleCallBack } from "../../../helper/processHelper";
import url from "../../API/url";
import { requestPostAxios, requestAxios } from "../../API/request";
import { batch } from "react-redux";
import { isEqual } from "lodash";
import { encrypt } from "../../../helper/EncryptHelper";
import { generateId } from "../../../helper/textHelper";

const getUrl = (path) => {
  return setObjFromPath(url, path);
};

export function handleErrorGlobal(response, callback, dispatch, key) {
  handleCallBack(callback, false, response);
  batch(() => {
    dispatch(
      set_result({
        name:
          response.status === 401 || response.status === 403 ? "log-out" : key,
        data: { type: "error", msg: response.message || response.messages },
      })
    );
    dispatch(remove_loading(key));
  });
}

export const removeError = createLogic({
  type: types.REMOVE_RESULT_L,
  process({ getState, action }, dispatch, done) {
    let result_stack = getState().stateReducer.result_stack;
    result_stack = result_stack.filter((e) => e.name !== action.payload);
    dispatch(remove_result(result_stack));
    done();
  },
});

export const setResult = createLogic({
  type: types.SET_RESULT_PROPS,
  process({ action }, dispatch, done) {
    const { name, type, msg, ...other } = action.payload;
    let result = {
      name,
      data: { type, msg, ...other },
    };
    dispatch(set_result(result));
    done();
  },
});

function handleOutsideValidate({ getState, action }, validate = true) {
  let validation = validate;
  if (typeof validate === "function") validation = validate(getState, action); // harus return
  return validation;
}

export const global_action = createLogic({
  type: types.GLOBAL_ACTION_LC,
  validate({ getState, action }, allow, reject) {
    const loading_stack = getState().stateReducer.loading_stack;
    const { path, validate, key } = action.payload;
    let Url = getUrl(path);
    let _validation = handleOutsideValidate({ getState, action }, validate);
    if (
      Url === undefined ||
      _validation === false ||
      loading_stack.includes(key || "gbl_act")
    ) {
      reject(action);
      return;
    }
    action.payload.url = Url;
    allow(action);
  },
  process({ action }, dispatch, done) {
    const {
      key = "gbl_act",
      appear,
      msg = "msg sukses belom di set, cek gbl_act",
      params,
      method,
      url,
      callback,
      redux_action,
      redux_path,
    } = action.payload;
    const requestMethod = method === "post" ? requestPostAxios : requestAxios;
    dispatch(set_loading(key));
    requestMethod(url, params)
      .then(({ data }) => {
        batch(() => {
          if (redux_action) dispatch(redux_action({ [redux_path]: data }));
          dispatch(remove_loading(key));
        });
        handleCallBack(callback, true, data);
        if (appear)
          dispatch(
            set_result({
              name: key,
              data: {
                type: "success",
                msg: msg,
              },
            })
          );
      })
      .catch((response) => {
        handleErrorGlobal(response, callback, dispatch, key);
      })
      .finally(done);
  },
});

/**
 * @param {String} key * // key loading
 * @param {String} path * // path url
 * @param {Object} params opt //params without page, perpage
 * @param {Function} callback opt // func for local state
 * @param {Function} redux_action  opt // func action reducer
 * @param {String} pathOldData opt if action ready
 * @param {Object} option opt // meta data
 * @param {*} validate opt // validation this logic // true = pass, false = block
 */

export const global_action_list = createLogic({
  type: types.GLOBAL_LIST_ACTION_LC,
  latest: true,
  validate({ getState, action }, allow, reject) {
    const { key, validate, option, params, path } = action.payload;
    const { current_page, last_page } = option || {};
    const loading_stack = getState().stateReducer.loading_stack;
    const Url = getUrl(path);
    const validation = handleOutsideValidate({ getState, action }, validate);
    if (option?.diff && !isEqual(params, option?.diff)) {
      action.payload.diff = true;
      allow(action);
      return;
    }
    if (
      loading_stack.includes(key) ||
      !validation ||
      current_page > last_page ||
      Url === undefined
    ) {
      reject(action);
      return;
    }
    action.payload.url = Url;
    allow(action);
  },
  process({ getState, action }, dispatch, done) {
    const {
      key = "fetching-list",
      params,
      callback,
      redux_action,
      option = {},
      pathOldData,
      url,
      diff,
    } = action.payload;
    dispatch(set_loading(key));
    const requestParams = {
      "pagination[perpage]": option.per_page || 10,
      "pagination[page]": option.current_page || 1,
      ...params,
    };
    requestAxios(url, requestParams)
      .then((res) => {
        const { data, ...meta } = res.data;
        const result = {
          meta: { ...meta, diff: params },
          data,
          diff: diff || false,
        };
        if (Boolean(pathOldData)) {
          const oldData =
            setObjFromPath(getState(), `${pathOldData}.data`) || [];
          const fullData = [...oldData, ...data];
          result.fullData = fullData.filter(
            (item, index) =>
              fullData.findIndex(({ id }) => item.id === id) === index
          );
        }
        handleCallBack(callback, true, result);
        batch(() => {
          dispatch(remove_loading(key));
          if (redux_action) dispatch(redux_action(result));
        });
      })
      .catch((response) => handleErrorGlobal(response, callback, dispatch, key))
      .finally(done);
  },
});

export const encrypt_data = createLogic({
  type: types.ENCRYPT_DATA_LC,
  latest: true,
  validate({ action }, allow, reject) {
    const { redirect } = action.payload || {};
    if (!redirect) {
      reject(action);
      return;
    }
    allow(action);
  },
  process({ action }, dispatch, done) {
    const key = generateId(8);
    const encryptData = encrypt(JSON.stringify(action.payload), key);
    dispatch(set_tmp_key(key));
    window.open(`/generator?d=${encryptData}`);
    done();
  },
});
