import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import classNames from "classnames";
import ButtonContained from "../../atom/Button/ButtonContained";
const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    paddingBottom: 20,
    marginBottom: 20,
    [theme.breakpoints.down(769)]: {
      display: "none"
    }
  },
  buttonContainer: {
    borderBottom: `1px solid ${theme.palette.background.dark}`
  },
  buttonTab: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.secondary.main,
    margin: "0 0 0 60px",
    position: "relative",
    "&.activeTab": {
      color: theme.palette.secondary.main,
      borderBottom: "none",
      "&::before": {
        content: `''`,
        width: "100%",
        height: "2px",
        backgroundColor: theme.palette.primary.main,
        position: "absolute",
        bottom: 0
      }
    },
    "&:hover": {
      backgroundColor: theme.palette.common.white
    },
    "&::before": {
      content: `''`,
      width: "100%",
      height: "2px",
      backgroundColor: theme.palette.common.white,
      position: "absolute",
      bottom: 0,
      transition: "background-color 0.3s"
    },
    "&:hover::before": {
      backgroundColor: theme.palette.primary.main
    }
  }
}));

const PortoTabs = props => {
  const classes = useStyles();
  const [active, setActive] = React.useState(0);
  const onClick = e => {
    setActive(e);
    props.onChange(e);
  };
  return (
    <div className={classNames(classes.container, " w-100")}>
      <div className={classes.buttonContainer}>
        <ButtonContained
          id="tab-saldo"
          text="SALDO PORTOFOLIO"
          className={classNames(
            classes.buttonTab,
            "fs-12 py-3 px-0 font-weight-semibold",
            active === 0 ? "activeTab" : ""
          )}
          onClick={() => onClick(0)}
          disableRipple={true}
        />
        <ButtonContained
          id="tab-detail"
          text="DETAIL PORTOFOLIO"
          className={classNames(
            classes.buttonTab,
            "fs-12 py-3 px-0 font-weight-semibold",
            active === 1 ? "activeTab" : ""
          )}
          onClick={() => onClick(1)}
          disableRipple={true}
        />
      </div>
    </div>
  );
};

export default PortoTabs;
