import axios from "axios";
import { store } from "../redux/store";
// import { log_out } from "../redux/actions/auth";
import { set_result } from "../redux/actions/state";
function status(status) {
  return true;
}

function handleDataToParams(data) {
  let params = "?";
  Object.keys(data).forEach((el, i) => {
    if (Array.isArray(data[el])) {
      data[el].forEach((el2, i2) => {
        params += el + "=" + el2;
        if (i2 + 1 !== data[el].length) {
          params += "&";
        }
      });
    } else {
      params += el + "=" + data[el];
    }
    if (Object.keys(data).length !== i + 1) {
      params += "&";
    }
  });
  return params;
}

export const requestAxios = async (url, data) => {
  if (data) {
    const params = handleDataToParams(data);
    url += params;
  }
  let { token } = store.getState().authReducer;
  console.log("call: ", url);
  return axios({
    url: url,
    method: "get",
    headers: { Authorization: token },
    "server-key": "mJV31gXb0MBAUcKgK4uZvlkh5JiCRq1q",
    validateStatus: status,
  })
    .then((response) => {
      if (response.data.hasOwnProperty("status")) {
        if (response.data.status === 401 || response.data.status === 403) {
          // const err = {
          //   status: 401,
          //   message: response.data.message
          // };
          // throw err;
          throw response.data;
        } else if (!response.data.status) {
          throw response.data;
        } else if (response.data.status === true) {
          return response.data;
        }
      } else {
        throw response.data;
      }
    })
    .catch((err) => {
      if (err.status === 401 || err.status === 403) {
        store.dispatch(
          set_result({
            name: "auth",
            data: { type: "error", msg: "Sesi anda telah habis" },
          })
        );
        // store.dispatch(log_out());
      }
      throw err;
    });
};

export const requestPostAxios = async (url, data) => {
  let formData = new FormData();
  if (data !== null) {
    Object.keys(data).forEach((el) => {
      if (Array.isArray(data[el])) {
        data[el].forEach((el2) => {
          formData.append(el, el2);
        });
      } else {
        formData.append(el, data[el]);
      }
    });
  } else {
    const err = { message: "require data to post" };
    throw err.message;
  }
  console.log("call: ", url, " data:", formData.values());
  let { token } = store.getState().authReducer;
  return axios
    .post(url, formData, {
      headers: { Authorization: token },
      validateStatus: status,
      "server-key": "mJV31gXb0MBAUcKgK4uZvlkh5JiCRq1q",
    })
    .then((response) => {
      if (response.data.status === 401) {
        throw response.data;
      } else if (!response.data.status) {
        throw response.data;
      } else if (response.data.status) {
        return response.data;
      }
    })
    .catch((err) => {
      if (err.status === 401 || err.status === 403) {
        store.dispatch(
          set_result({
            name: "auth",
            data: { type: "error", msg: "Sesi anda telah habis" },
          })
        );
        // store.dispatch(log_out());
      }
      throw err;
    });
};

export function requestValidate(url, data, validate) {
  if (data) {
    const params = handleDataToParams(data);
    url += params;
  }
  let { token } = store.getState().authReducer;
  return axios({
    url,
    method: "get",
    headers: { Authorization: token },
    "server-key": "mJV31gXb0MBAUcKgK4uZvlkh5JiCRq1q",
    validateStatus: status,
  })
    .then((response) => {
      if (!validate(response.data)) {
        if (!response.data?.error?.message) {
          throw response;
        } else {
          // eslint-disable-next-line no-throw-literal
          throw { error: { msg: "data tidak di temukan" } };
        }
      }
      return response.data;
    })
    .catch((err) => {
      throw err;
    });
}
