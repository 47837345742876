import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  root: {
    maxWidth: 440,
    margin: "16px 0",
    padding: "0 32px",
    "& .header-form": {
      padding: "16px 0",
    },
  },
  contentForm: {
    borderTop: `1px solid ${theme.palette.secondary[7]}`,
    padding: "12px 24px 21px",
    "& .input-form-periodic-invest": {},
  },
  contentCal: {
    border: `1px solid ${theme.palette.secondary[6]}`,
    "&.month": {
      fontSize: 10,
      color: "#7f7f7f",
      minWidth: "unset",
      "&.active": {
        borderColor: theme.palette.primary.main,
      },
      "&.Mui-disabled": {
        opacity: "0.6",
      },
    },
  },
  buttonClose: {
    position: "absolute",
    right: -16,
    top: -16,
  },
  contentLoading: {
    position: "absolute",
    width: "100%",
    height: "100%",
    margin: "0 -32px",
    backgroundColor: "rgba(255,255,255,0.5)",
    zIndex: 1,
  },
}));
