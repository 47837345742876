import { chart, stockChart, dateFormat } from "highcharts/highstock";

const chartHelper = {
  createProChart: (id, { data, time }) => {
    let series = data.map((el) => ({
      name: el.name,
      data: el.data,
      lineColor: el.color,
      marker: {
        enabled: false,
        symbol:
          "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgOSA5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8Y2lyY2xlIGN4PSI0LjUiIGN5PSI0LjUiIHI9IjQiIGZpbGw9IndoaXRlIiBzdHJva2U9IiNFRjY0MzEiLz4KPC9zdmc+Cg==)",
        fillColor: el.color,
      },
    }));
    chart(id, {
      title: {
        text: "",
      },
      chart: {
        height: 150,
      },
      credits: {
        enabled: false,
      },
      yAxis: {
        title: {
          text: "",
        },
        labels: {
          formatter: function () {
            if (this.value > 0) {
              return this.value / 1000000 + " jt";
            } else {
              return 0;
            }
          },
        },
      },
      xAxis: {
        categories: time,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          label: {
            connectorAllowed: false,
          },
        },
      },
      series: series,
    });
  },
  createPiChart: (colors, data, id) => {
    chart(id, {
      chart: {
        backgroundColor: null,
        // plotBorderWidth: null,
        // plotShadow: false,
        margin: [0, 0, 0, 0],
        spacingTop: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        spacingRight: 0,
        type: "pie",
      },
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          size: "100%",
          colors: colors,
          borderColor: null,
          cursor: "pointer",
          dataLabels: {
            enabled: false,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
        },
      },
      series: [
        {
          name: "Alokasi",
          colorByPoint: true,
          innerSize: "40%",
          data: data,
        },
      ],
    });
  },
  createStockChart: (data, id) => {
    stockChart(id, {
      colors: ["#FF9655", "#FF9655"],
      navigator: {
        enabled: false,
      },
      scrollbar: {
        enabled: false,
      },

      xAxis: {
        gapGridLineWidth: 0,
      },
      credits: {
        enabled: false,
      },
      styledMode: true,
      rangeSelector: {
        buttons: [
          {
            type: "month",
            count: 1,
            text: "1M",
          },
          {
            type: "month",
            count: 3,
            text: "3M",
          },
          {
            type: "month",
            count: 6,
            text: "6M",
          },
          {
            type: "ytd",
            text: "YTD",
          },
          {
            type: "year",
            count: 1,
            text: "1Y",
          },
          {
            type: "all",
            text: "All",
          },
        ],
        verticalAlign: "bottom",
        selected: 1,
        inputEnabled: false,
        buttonPosition: {
          align: "center",
        },
        buttonSpacing: 10,
        buttonTheme: {
          // styles for the buttons
          fill: "none",
          stroke: "none",
          "stroke-width": 0,
          r: 12,
          width: 34,
          height: 17,
          align: "center",
          style: {
            color: "#999999",
            // fontWeight: 'bold',
            horizontalAlign: "center",
            align: "center",
            fontSize: "10px",
            padding: "20px",
            top: "30",
            verticalAlign: "center",
            y: 50,
          },
          states: {
            hover: {
              fill: "#F7B198",
              style: {
                color: "white",
              },
            },
            select: {
              fill: "#F7B198",
              // height: 200,
              style: {
                align: "center",
                color: "white",
                fontSize: "10px",
                textAlign: "center",
                top: "30",
                padding: "20px",
                verticalAlign: "center",
              },
            },
            // disabled: { ... }
          },
        },
        inputBoxBorderColor: "gray",
        inputBoxWidth: 300,
        inputBoxHeight: 400,
        labelStyle: {
          display: "none",
          color: "silver",
          fontWeight: "bold",
        },
      },
      chart: {
        animation: true,
      },
      exporting: {
        enabled: false,
      },
      series: [
        {
          name: "NAV/Unit",
          type: "areaspline",
          data: data,
          gapSize: 5,
          tooltip: {
            valueDecimals: 2,
          },
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
            stops: [
              [0, "#FF9655"],
              [1, "#FFFFFF"],
            ],
          },
          threshold: null,
        },
      ],
    });
  },
  createSimulasiChart: (data, id) => {
    const optionData = [
      {
        lineColor: "#99BFCD",
        marker: {
          fillColor: "#99BFCD",
        },
      },
      {
        lineColor: "#EF6431",
        marker: {
          symbol:
            "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgOSA5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8Y2lyY2xlIGN4PSI0LjUiIGN5PSI0LjUiIHI9IjQiIGZpbGw9IndoaXRlIiBzdHJva2U9IiNFRjY0MzEiLz4KPC9zdmc+Cg==)",
          radius: 4,
          fillColor: "#EF6431",
        },
      },
    ];
    let series = [];
    data.forEach((el, index) => {
      series.push({
        ...el,
        ...optionData[index],
      });
    });
    chart(id, {
      title: { text: "" },
      subtitle: {},
      yAxis: {
        labels: {
          formatter: function () {
            if (this.value > 0) {
              return this.value / 1000000 + "Jt";
            } else {
              return 0;
            }
          },
        },
        title: { text: "" },
      },
      xAxis: {
        labels: {
          formatter: function () {
            var tooltip = dateFormat("%m/%y", new Date(this.value + " UTC"));
            return tooltip;
            // return 'Y' + this.value;
          },
          step: 20,
        },
        categories: ["06/30/2019"],
      },
      legend: {
        layout: "vertical",
        align: "right",
        verticalAlign: "middle",
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        formatter: function (d) {
          var tooltip = "";
          // var tooltip = "Waktu : "
          tooltip += dateFormat("%b %e, %Y", new Date(this.x + " UTC"));

          [1, 0].forEach((val) => {
            let point = this.points[val];

            let money = String(point.y);
            if (money === null) {
              return "0";
            } else {
              let data = money.toString().split(".");
              let koma = data[1] ? "," + data[1].substring(0, 2) : "";
              money =
                data[0].toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.") +
                koma;
            }

            tooltip +=
              '<br><span style="color:' +
              point.series.userOptions.lineColor +
              '">' +
              point.series.userOptions.name +
              "</span>: <b>Rp. " +
              money +
              "</b>";
          });
          return tooltip;
        },
        // pointFormat:
        //   '<span style="color:{series.color}">{series.name}</span>: <b>Rp. {point.y}</b> ({point.change})<br/>',
        valueDecimals: 2,
        split: true,
      },
      series,
      // [
      //   {
      //     name: "Modal",
      //     lineColor: "#99BFCD",
      //     data: [0],
      //     marker: {
      //       fillColor: "#99BFCD",
      //     },
      //   },
      //   {
      //     name: "Hasil Investasi",
      //     lineColor: "#EF6431",
      //     data: [0],
      //     marker: {
      //       symbol:
      //         "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgOSA5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8Y2lyY2xlIGN4PSI0LjUiIGN5PSI0LjUiIHI9IjQiIGZpbGw9IndoaXRlIiBzdHJva2U9IiNFRjY0MzEiLz4KPC9zdmc+Cg==)",
      //       radius: 4,
      //       fillColor: "#EF6431",
      //     },
      //   },
      // ],
      responsive: {
        rules: [
          {
            condition: { maxWidth: 500 },
            chartOptions: {
              legend: {
                layout: "horizontal",
                align: "center",
                verticalAlign: "bottom",
              },
            },
          },
        ],
      },
    });
  },
};

export default chartHelper;
