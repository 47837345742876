import React from "react";
import MediaQuery from "../organism/MediaQuery/MediaQuery";
import TempMobile from "./TempMobile";
import TempDesktop from "./TempDesktop";
import PropTypes from "prop-types";
import mobile from "./Mobile";
import desktop from "./Desktop";
import DialogAlert from "../molecule/Alert/DialogAlert";
import { connect } from "react-redux";
import { log_out } from "../../config/redux/actions/auth";
const TempMain = (props) => {
  const {
    children,
    childrenRight,
    backgroundColor,
    bottomNavValue,
    showBottom,
    goBackButton,
    title,
    page,
    data,
    withSearch,
    isCloseButton,
    initialPage,
    desktopBackFunc,
  } = props;
  const MobilePage = mobile[page];
  const DesktopPage = desktop[page];
  return (
    <>
      <MediaQuery device="mobile">
        <TempMobile
          backgroundColor={backgroundColor}
          bottomNavValue={bottomNavValue}
          showBottom={showBottom}
          goBackButton={goBackButton}
          title={title}
          withSearch={withSearch}
          isCloseButton={isCloseButton}
          isInitial={initialPage}
          functionBack={desktopBackFunc}
        >
          {page ? (
            <MobilePage data={data} childrenRight={childrenRight}>
              {children}
            </MobilePage>
          ) : (
            children
          )}
        </TempMobile>
      </MediaQuery>
      <MediaQuery device="desktop">
        <TempDesktop
          navValue={bottomNavValue}
          isInitial={initialPage}
          title={title}
          backHeaderFunc={desktopBackFunc}
        >
          {page ? (
            <DesktopPage data={data} childrenRight={childrenRight}>
              {children}
            </DesktopPage>
          ) : (
            children
          )}
        </TempDesktop>
      </MediaQuery>
      <DialogAlert resultName="auth" submitIfError={props.log_out} />
    </>
  );
};

TempMain.propTypes = {
  backgroundColor: PropTypes.string,
  bottomNavValue: PropTypes.number,
  showBottom: PropTypes.bool,
  goBackButton: PropTypes.bool,
  title: PropTypes.string,
  data: PropTypes.object,
  page: PropTypes.string,
  withSearch: PropTypes.bool,
  isCloseButton: PropTypes.bool,
  initialPage: PropTypes.bool,
};

TempMain.defaultProps = {
  initialPage: false,
};

export default connect(null, { log_out })(TempMain);
