import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import stateCallApi from "../../../config/API/stateCallApi";
import { gbl_act_lc } from "../../../config/redux/actions/state";
import useStateFetch from "../../atom/hooks/useStateFetch";
import DialogAlert from "../../molecule/Alert/DialogAlert";
import TabCustom from "../../molecule/Tabs/TabCustom";
import TempMain from "../../template/TempMain";
import CardPeriodic from "./CardPeriodic";
import processHelper from "../../../helper/processHelper";
import { Skeleton } from "@material-ui/lab";
import ContainerWithLoader from "../../organism/Container/ContainerWithLoader";
const { useCallback } = React;

const BASE_TAB = [
  { value: "manual", label: "manual" },
  { value: "auto_debet", label: "auto debet" },
];
const loading_delete = "periodic_delete";
//

const DetailPeriodicPage = () => {
  const {
    push,
    location: { state },
  } = useHistory();
  const [active, setActive] = useStateFetch("manual");
  const dispatch = useDispatch();
  const loading = useSelector((state) =>
    processHelper.findProcess("periodic-list", state.stateReducer.loading_stack)
  );
  const [data] = useStateFetch({ manual: [], auto_debet: [] }, (callback) => {
    if (state?.id) {
      dispatch(
        gbl_act_lc({
          ...stateCallApi.periodic_list,
          redux_action: null,
          params: {
            "find[id]": state.id,
          },
          callback: (data) => {
            if (!data.manual.length) setActive("auto_debet");
            callback(data);
          },
        })
      );
    }
  });

  //   const {
  //     location: { state },
  //     push,
  //     replace,
  //   } = useHistory();
  //   const [active, setActive] = useState(state?.is_auto_debet ?? 0);
  //   const loading = useSelector((state) =>
  //     processHelper.findProcess(
  //       "update-periodic",
  //       state.stateReducer.loading_stack
  //     )
  //   );

  const handleClick = useCallback(
    (action, { is_auto_debet, id, allocations } = {}) => {
      switch (action) {
        case "delete":
          if (is_auto_debet) push("/file-stop-auto-debet", allocations);
          else {
            dispatch(
              gbl_act_lc({
                ...stateCallApi.periodic_delete,
                params: {
                  "periodic_invest_id[]": [id],
                },
              })
            );
          }
          break;
        default:
          push("/coming-soon");
          break;
      }
    },
    [push, dispatch]
  );

  //   const handleClickCard = useCallback(
  //     (action, item) => {
  //       const {
  //         id,
  //         is_active,
  //         invest_cycle,
  //         make_payment_on,
  //         is_continous,
  //         end_date,
  //         is_auto_debet,
  //       } = item;
  //       const params = {
  //         id,
  //         is_active,
  //         invest_cycle,
  //         make_payment_on,
  //         is_continous,
  //         is_auto_debet,
  //       };
  //       if (!Number(is_continous)) params.end_date = end_date;
  //       switch (action) {
  //         case "berhenti":
  //           dispatch(
  //             gbl_act_lc({
  //               ...stateCallApi.update_periodic,
  //               params: { ...params, is_active: 0 },
  //               callback: (data) => {
  //                 console.log(data);
  //                 replace("/profil/setting/detail-periodic-invest", {
  //                   ...state,
  //                   ...data,
  //                 });
  //               },
  //             })
  //           );
  //           break;
  //         case "ubah":
  //           push("/coming-soon");
  //           break;
  //         default:
  //           return;
  //       }
  //     },
  //     [dispatch, push, replace, state]
  //   );
  return (
    <TempMain title="SETTING" bottomNavValue={4}>
      <div className="p-md-4">
        <TabCustom
          data={BASE_TAB}
          className="mb-3 shadow-md-card rounded-1"
          active={active}
          onChange={setActive}
        />
        <ContainerWithLoader processName={loading_delete}>
          {loading && <Skeleton width="100%" height="300px" />}
          {!loading && Boolean(data[active]?.length) ? (
            data[active].map((item, index) => (
              <CardPeriodic
                key={item.id || index}
                isFollowRobo={data.is_follow_robo}
                item={item}
                typePayment={
                  BASE_TAB.find(({ value }) => value === active).label
                }
                onClick={handleClick}
                className={`mt-${index ? 3 : 0}`}
              />
            ))
          ) : (
            <div
              className="cont-center"
              style={{ height: "calc(100vh - 270px)" }}
            >
              <p className="basic font-weight-bold fs-14 gray-4">
                data tidak ditemukan
              </p>
            </div>
          )}
        </ContainerWithLoader>
      </div>
      {/* {loading ? (
          <p className="basic fs-14">loading...</p>
        ) : Number(state?.is_auto_debet ?? 0) === Number(active) ? (
          <CardDetail item={state} onClick={handleClickCard} />
        ) : (
          <p className="basic fs-14">data tidak ditemukan</p>
        )} */}
      <DialogAlert
        resultName={loading_delete}
        alertAppear="both"
        submitIfSuccess={() => window.location.reload()}
      />
      <DialogAlert resultName="update-periodic" alertAppear="error" />
    </TempMain>
  );
};

export default DetailPeriodicPage;
