import React from "react";
import { Card, Grid } from "@material-ui/core";
import BasicText from "../../atom/BasicText/BasicText";
import ColorIndicator from "../../../assets/img/icon/porto/ColorIndicator";

const CardProductRecom = props => {
  const { profileRiskProduct, color } = props;
  return (
    <Card className="p-3 mb-3">
      <BasicText className="fs-14 font-weight-bold color-secondary mb-2">
        Rekomendasi produk:
      </BasicText>
      {profileRiskProduct.map((el, id) => (
        <Grid container key={id} spacing={1}>
          <Grid item={true} container xs={9}>
            <div className="d-flex">
              <ColorIndicator
                style={{ margin: 1, fontSize: 13, color: color[id] }}
              />
              <BasicText className="gray-4 ml-1 text-lowercase">
                {el.product.name}
              </BasicText>
            </div>
          </Grid>
          <Grid item xs={3} className="text-center">
            <BasicText className="gray-4 font-weight-bold">
              {el.bobot}%
            </BasicText>
          </Grid>
        </Grid>
      ))}
    </Card>
  );
};

export default CardProductRecom;
