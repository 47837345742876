import React from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";

const FieldInputPeriodic = ({ label, onClick, name, value, data, onOpen }) => {
  return (
    <div className="mb-1">
      <p className="basic fs-12 mb-1">{label}</p>
      <FormControl className="w-100">
        <Select
          value={value}
          onOpen={onOpen}
          onChange={(event) => onClick(event, name)}
          IconComponent={() => (
            <KeyboardArrowDown className="color-primary mr-1" />
          )}
          variant="outlined"
          displayEmpty
          className="rounded-0"
          classes={{
            select: "gray-3 fs-14 py-2",
          }}
        >
          {data.map((el, id) => (
            <MenuItem key={id} value={el.value} {...el.otherProps}>
              {el.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

FieldInputPeriodic.defaultProps = {
  data: [],
  onClick: () => {},
  onOpen: () => {},
};

export default React.memo(FieldInputPeriodic);
