import { CircularProgress, makeStyles } from "@material-ui/core";
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import color from "../../../assets/theme/color";
import validation from "../../../helper/validateHelper";
import BasicText from "../../atom/BasicText/BasicText";
import InputText from "../MaterialInput/InputText";

const isValid = validation.validateSID();

const useStyles = makeStyles({
  contain: {
    border: "1px solid " + color.gray_6,
    borderRadius: 4,
    padding: "9px 20px 12px 15px",
    position: "relative",
  },
  loading: {
    position: "absolute",
    right: 21,
    top: 50,
  },
});

const SIDinput = forwardRef(({ onChange, loading, disabled }, ref) => {
  const S = useStyles();
  const [state, setState] = useState({ sid: "", error: "" });
  useImperativeHandle(ref, () => ({ _handleChange }));
  const _handleChange = useCallback(
    function (sid) {
      isValid
        .validate(sid)
        .then(() => setState({ sid, error: "" }))
        .catch((err) => setState({ sid, error: err.message }));
      onChange(sid);
    },
    [onChange]
  );
  return (
    <div className={S.contain}>
      <InputText
        id="input-sid"
        variant="standard"
        disabled={disabled || loading}
        label="NOMOR SID"
        labelClass="mb-1"
        containClass="mb-2"
        onChange={_handleChange}
        value={state.sid}
        error={state.error !== ""}
        helperText={state.error}
      />
      {loading && <CircularProgress size={20} className={S.loading} />}
      <BasicText className="fs-10 gray-5 w-90">
        Isi nomor SID kamu, jika sudah terdaftar sebagai nasabah PT. Syailendra
        Capital.
      </BasicText>
    </div>
  );
});

SIDinput.defaultProps = {
  onChange: () => {},
};

export default SIDinput;
