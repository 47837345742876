import React from "react";
import { makeStyles } from "@material-ui/styles";
import BasicText from "../../atom/BasicText/BasicText";
import classNames from "classnames";
const useStyles = makeStyles({
  stepper: {
    "& li": {
      listStyle: "none",
      position: "relative",
      minHeight: 80,
      "&::before": {
        content: '""',
        position: "absolute",
        width: 10,
        height: 10,
        left: -22,
        top: 3,
        borderRadius: "50%",
        backgroundColor: "#666666",
      },
      "&::after": {
        content: '""',
        position: "absolute",
        width: 4,
        height: "calc(100% - 15px)",
        backgroundColor: "#666",
        left: -19,
        top: 15,
      },
      "&.active": {
        "&::before": {
          backgroundColor: "white",
          border: "4px solid #ef6431",
        },
        "&::after": {
          backgroundColor: "#ef6431",
        },
      },
      "&:last-child": {
        minHeight: "unset",
        "& .subtitle": {
          height: "unset",
        },
        "&::after": {
          display: "none",
        },
      },
    },
  },
});
const VerticalStepper = (props) => {
  const { step, text, subText } = props;
  const classes = useStyles();

  return (
    <ol className={classes.stepper}>
      {Array(3)
        .fill("")
        .map((_, index) => {
          if (!text[index]) {
            return null;
          }
          return (
            <li
              key={index}
              className={classNames("ml-2", step >= index && "active")}
            >
              <div>
                <BasicText
                  className={classNames(
                    "fs-12 color-secondary mb-1 title",
                    step === index && "font-weight-bold"
                  )}
                >
                  {text[index]}
                </BasicText>
                <BasicText
                  className={classNames(
                    "fs-10 ellipsis2row subtitle",
                    step < index && "hidden"
                  )}
                >
                  {subText[index]}
                </BasicText>
              </div>
            </li>
          );
        })}
    </ol>
  );
};

export default VerticalStepper;
