import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import debounce from "lodash/debounce";
import React, { useCallback } from "react";
import color from "../../../assets/theme/color";
import textHelper from "../../../helper/textHelper";
import ButtonContained from "../../atom/Button/ButtonContained";
import CheckInput from "../../molecule/MaterialInput/CheckInput";
import SliderWithInput from "../../molecule/MaterialInput/SliderWithInput";
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 22,
    [theme.breakpoints.up(768)]: {
      borderRadius: "4px 4px 0 0",
      paddingBottom: 0,
      marginBottom: 0,
    },
    "& .subRoot": {
      [theme.breakpoints.up(768)]: {
        // maxWidth: 420,
        width: "49%",
      },
    },
  },
  buttonRecommend: {
    width: "calc((100% / 4) - 5px)",
  },
}));
const SellInput = ({ portoData, onChange }) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    value: "",
    maxValue: 1000000,
    minValue: 0,
    recommend: ["50000", "100000", "500000", "1000000"],
    isAll: 0,
  });
  const { value, maxValue, minValue, recommend, isAll } = state;

  const handleChange = React.useCallback(debounce(onChange, 300), []);

  React.useEffect(() => {
    const { product, value } = portoData;
    const { min_red } = product;
    setState((state) => ({
      ...state,
      minValue: parseFloat(min_red),
      maxValue: value,
    }));
  }, [portoData]);

  const handleInputChange = useCallback(
    (value) => {
      const valueNumber = Number(value);
      const isSliderMax = parseFloat(valueNumber) === maxValue;
      const valIsAll = isSliderMax ? 1 : 0;
      setState((state) => ({ ...state, value: valueNumber, isAll: valIsAll }));
      handleChange(valueNumber, portoData, valIsAll);
    },
    [handleChange, maxValue, portoData]
  );

  return (
    <div className={classNames(classes.root)}>
      <div className="subRoot">
        <SliderWithInput
          id="redeem"
          adormentContent={() => <span className="fs-14 gray-4">Rp</span>}
          adormentPlace="start"
          value={value}
          minValue={minValue}
          maxValue={maxValue}
          stepSlider={1000}
          text="Nominal Unit Dijual"
          onChange={handleInputChange}
          sliderClass="pb-0"
          placeholder="00"
        />
        <div className="w-100 justify-content-end d-flex mb-2 mt-1">
          <CheckInput
            reverse={true}
            label="Jual semua"
            className="align-items-center mr-n1"
            colorCheck="secondary"
            checkClass="py-0"
            forceFalse={parseFloat(value) < parseFloat(maxValue)}
            onChange={(isChecked) =>
              handleInputChange(isChecked ? maxValue : 0)
            }
            id="all"
            state={isAll}
            forceSet={true}
          />
        </div>
        <div className="d-flex w-100 justify-content-between">
          {recommend.map((rec, id) => {
            const isSelected = Number(value) === Number(rec);
            const isAvailable = parseFloat(maxValue) < parseFloat(rec);
            return (
              <ButtonContained
                id={`${rec}-btn`}
                key={id}
                backgroundColor={isSelected ? color.gray_3 : color.gray_4}
                text={textHelper.printMoneyWithoutRp(rec)}
                className={classNames("fs-12 py-1", classes.buttonRecommend)}
                onClick={() => handleInputChange(rec)}
                disabled={isAvailable ? true : false}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SellInput;
