import React from "react";
import BasicText from "./BasicText";
import classNames from "classnames";

const LabelInput = ({ text, className, IconMaterial, textClass, children }) => {
  if (text) {
    const withOther = IconMaterial || children;
    return (
      <div
        className={classNames(
          "d-flex align-items-center",
          withOther && className
        )}
      >
        {IconMaterial && (
          <IconMaterial className="fs-16 color-secondary mr-2" />
        )}
        <BasicText
          children={text}
          style={{ fontSize: 14 }}
          className={classNames(
            "color-secondary",
            !withOther ? className : textClass
          )}
        />
        {children}
      </div>
    );
  } else return null;
};

export default LabelInput;
