import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import BasicHeader from "../../molecule/Header/BasicHeader";
import BasicText from "../../atom/BasicText/BasicText";
import LoginIlustrate from "../../molecule/Ilustration/LoginIlustrate";
import DesktopFooter from "../../molecule/Footer/DesktopFooter";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    // border: "1px solid #D4EBFC",
    width: 907,
    minHeight: 555,
  },
  form: {
    backgroundColor: "#fff",
    padding: "20px 30px 20px 30px",
    display: "flex",
    flexDirection: "column",
  },
  illustration: {
    backgroundColor: theme.palette.background.dark,
  },
  contentWidth: {
    width: 288,
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
}));

const TempLoginDesktop = (props) => {
  const { children, data } = props;
  const { title, ilustration } = data;
  const classes = useStyles();
  return (
    <div
      className="background-desktop"
      style={{ minHeight: "calc(100vh - 45px)" }}
    >
      <div className="container-center-desktop">
        <Grid container className={classes.card}>
          <Grid item xs={6} className={classes.form}>
            <BasicHeader marginClass="mb-5 pb-4" title={title} />
            <BasicText className="font-weight-bold fs-14 mb-4" color="primary">
              {ilustration.title}
            </BasicText>
            <div className={classes.contentWidth}>{children}</div>
          </Grid>
          <Grid
            xs={6}
            item={true}
            container
            justify="center"
            alignItems="center"
            className={classes.illustration}
          >
            <LoginIlustrate title={title} />
          </Grid>
        </Grid>
      </div>
      <DesktopFooter />
    </div>
  );
};

TempLoginDesktop.propTypes = {
  data: PropTypes.object,
};

TempLoginDesktop.defaultProps = {
  data: {},
};

export default TempLoginDesktop;
