import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { useMemo } from "react";
import moreIcon from "../../../assets/icons/moreIcon.svg";
import MovePortoIcon from "../../../assets/icons/movePortoIcon.svg";
import color from "../../../assets/theme/color";
import { isDueDateRDP } from "../../../helper/RDPhelper";
import textHelper from "../../../helper/textHelper";
import BasicText from "../../atom/BasicText/BasicText";
import ButtonContained from "../../atom/Button/ButtonContained";
import RiskIndicator from "../../molecule/Product/RiskIndicator";

const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
  },
  badge: {
    zIndex: 0,
    backgroundColor: theme.palette.primary.main,
    position: "absolute",
    top: -9,
    left: 0,
    width: 67,
    height: 76,
    borderRadius: "8px",
  },
  card_desktop: {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    "&::before": {
      content: `''`,
      display: "inline-block",
      backgroundColor: theme.palette.primary.main,
      width: 6,
      height: "100%",
      position: "absolute",
      borderRadius: "5px 0 0 5px",
    },
  },
  letterSpacing: {
    letterSpacing: "-0.5px",
  },
}));

const CardPorto = (props) => {
  const { items, onClick } = props;
  const {
    fr_value,
    fr_initial_amount,
    fr_unrealized_gain,
    fr_unrealized_gain_percent,
    product_variety_code,
    product: {
      fr_product_name,
      can_switch,

      product_risk,
      code,
      is_rdp,
      product_rdp,
      can_subscript,
      can_redem,
    },
  } = items;
  const isSmartPlan = product_variety_code === "CG";
  const handleClick = (e) => onClick({ key: e, items });
  const classes = useStyles();

  const txtCard = useMemo(() => {
    const createClass = (valueClass, root) => ({
      root: "d-flex justify-content-between align-items-center mb-1 " + root,
      title: "my-auto fs-10",
      value: valueClass || "fs-14",
    });
    let res = [
      {
        title: "Nilai Saat Ini",
        value: fr_value,
        className: createClass("fs-14 font-weight-semibold color-primary"),
      },
      {
        title: "Investasi Awal",
        value: fr_initial_amount,
        className: createClass(),
      },
      {
        title: "Total Earnings",
        value: fr_unrealized_gain,
        className: createClass(),
      },
      {
        title: "Return",
        value: fr_unrealized_gain_percent,
        className: createClass("fs-14 font-weight-semibold color-primary"),
      },
    ];
    if (product_rdp) {
      res = [
        ...res,
        {
          title: "Jatuh Tempo",
          value: textHelper.printDate(product_rdp?.due_date),
          className: createClass("fs-12 font-weight-semibold", "mt-2"),
        },
        {
          title: "Pembagian Return",
          value: product_rdp?.return_distribution,
          className: createClass("fs-12 font-weight-semibold"),
        },
        {
          title: "Tanggal Emisi",
          value: textHelper.printDate(product_rdp?.emission_date),
          className: createClass("fs-12 font-weight-semibold mb-2"),
        },
      ];
    }
    return res;
  }, [
    fr_initial_amount,
    fr_unrealized_gain,
    fr_unrealized_gain_percent,
    fr_value,
    product_rdp,
  ]);

  const btnCard = useMemo(() => {
    const res = [
      {
        text: "TOP UP",
        type: "sub",
        disabled: !!is_rdp || !can_subscript,
      },
      {
        text: "JUAL",
        type: "red",
        backgroundColor: color.gray_6,
        disabled: Boolean(!can_redem || (is_rdp && !isDueDateRDP(product_rdp))),
      },
    ];
    if (!is_rdp) {
      res.push({
        text: "KONVERSI",
        type: "sw",
        backgroundColor: color.secondaryLight,
        disabled: can_switch === 0 || isSmartPlan,
      });
    }
    return res;
  }, [can_redem, can_subscript, can_switch, isSmartPlan, is_rdp, product_rdp]);

  return (
    <div className="position-relative">
      <div className={classNames(classes.card)}>
        <Grid container spacing={1} className="mb-1">
          <Grid item xs={7}>
            <BasicText className="fs-12">{fr_product_name}</BasicText>
          </Grid>
          <Grid item xs={4} className="mt-auto">
            {can_switch === 0 || isSmartPlan ? null : (
              <BasicText
                id={`switch-${code}`}
                onClick={() => handleClick("switch-porto")}
                className="fs-8 color-primary is-link"
              >
                Alihkan Portofolio <img src={MovePortoIcon} alt="icon" />
              </BasicText>
            )}
          </Grid>
          <Grid item xs={1} className="mt-auto text-right">
            <img
              id={`detail-${code}`}
              onClick={() => handleClick("detail")}
              className="is-link"
              src={moreIcon}
              alt=""
            />
          </Grid>
        </Grid>
        <div className="d-flex mb-2">
          <BasicText className="fs-11 mr-3">Risiko Produk</BasicText>
          <RiskIndicator value={Number(product_risk)} height={8} />
        </div>
        {txtCard.map((el, id) => (
          <div key={id} className={el.className?.root}>
            <BasicText className={el.className?.title}>{el.title}</BasicText>
            <BasicText className={el.className?.value}>{el.value}</BasicText>
          </div>
        ))}
        <Grid container spacing={1} className="mt-1" justifyContent="center">
          {btnCard.map((el, id) => (
            <Grid key={id} item xs={4}>
              <ButtonContained
                id={`${el.type}-${code}`}
                className="w-100 fs-11 font-weight-semibold"
                backgroundColor={el.backgroundColor}
                disabled={el.disabled}
                text={el.text}
                radius="6px"
                onClick={() => handleClick(el.type)}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

CardPorto.propTypes = {
  items: PropTypes.object,
};

export default CardPorto;
