import * as Yup from "yup";

const validation = {
  validatePhone: (text = "Harus terdiri dari 8-14 karakter") => {
    return Yup.number()
      .test("len", text, (val) => {
        if (val) {
          if (val.toString().length >= 8 && val.toString().length <= 13)
            return true;
          else return false;
        } else {
          return false;
        }
      })
      .required("Nomor harus diisi")
      .typeError("Nomor Salah");
  },
  validateEmail: (key = "Email") => {
    return Yup.string()
      .email(`${key} tidak sesuai format`)
      .required(`${key} harus di isi`);
  },
  validateOTP: (text = "OTP harus 4 digit") => {
    return Yup.string()
      .test("len", text, (val) => {
        if (val) {
          if (val.length === 4 && !isNaN(Number(val))) return true;
          else return false;
        } else {
          return false;
        }
      })
      .required("otp harus diisi")
      .typeError("otp harus berupa angka");
  },
  validateRTRW: () => {
    return Yup.string()
      .test("len", 'RT/RW tidak sesuai format (ex : "01/10")', (val) => {
        const _val = val !== undefined ? val.split("/") : [];
        if (_val.length === 2) {
          let _res = true;
          _val.forEach((el) => {
            if (!el && _res) _res = false;
          });
          return _res;
        } else return false;
      })
      .required("RT/RW wajib di isi");
  },
  validatePin: () => {
    return Yup.string()
      .min(6, "Minimal 6 Angka")
      .test("len", "PIN harus terdiri dari 6 angka", (val) => {
        if (val) {
          if (val.length === 6 && parseInt(val)) return true;
          else return false;
        } else {
          return false;
        }
      })
      .required("PIN harus diisi")
      .typeError("PIN harus berupa angka");
  },
  validateNoRek: () => {
    return Yup.string()
      .test("len", "No.Rekening harus berupa angka", (val) => {
        if (val && Number(val)) return true;
        else return false;
      })
      .min(8, "No. Rekening min. 8 digit")
      .required("No. Rekening harus diisi")
      .typeError("No.Rekening harus berupa angka");
  },
  validateNIK: () => {
    return Yup.number()
      .required("NIK harus diisi")
      .test("len", "Harus terdiri dari 16 karakter", (val) => {
        if (val) {
          if (val.toString().length === 16) return true;
          else return false;
        } else {
          return false;
        }
      })
      .typeError("Masukkan harus berupa angka");
  },
  validatePos: () => {
    return Yup.number()
      .required("Kode Pos KTP harus diisi")
      .test("len", "Harus terdiri dari 5 karakter", (val) => {
        if (val) {
          if (val.toString().length === 5) return true;
          else return false;
        } else {
          return false;
        }
      })
      .typeError("Masukkan harus berupa angka");
  },
  validateSID: () => {
    const text = "SID tidak sesuai";
    return Yup.string().test("len", text, (val) => {
      if (val.length === 15 || val === "") return true;
      else return false;
    });
  },
  require: (text, key) => {
    return Yup.string().required(text || `${key} harus diisi`);
  },
  validatePasswordRegister: () => {
    return Yup.string()
      .min(8, "Panjang minimal 8 karakter")
      .matches(/[a-z]/, "Setidaknya harus ada satu huruf kecil")
      .matches(/[A-Z]/, "Setidaknya harus ada satu huruf kapital")
      .matches(/[0-9]/, "Harus menggunakan karakter numerik/angka")
      .required("Password harus diisi");
  },
};
const Options = {
  strict: false,
  abortEarly: false,
  stripUnknown: false,
  recursive: true,
};
// jangan pengaruhin text input, takut lag
export const _isValid = async (schema, data, allError) => {
  let _res = {};
  await schema
    .validate(data, Options)
    .then((e) => {
      _res = false;
    })
    .catch((err) => {
      let _data = {};
      if (typeof data === "string") {
        const { errors, message } = err;
        if (errors.length > 1) {
          _data = "";
          if (allError) {
            errors.forEach((item, index) => {
              _data = `${_data} ${index ? "|" : ""} ${item}`;
            });
          } else _data = errors[errors.length - 1];
        } else _data = message;
      } else {
        for (let i = 0; i < err.inner.length; i++) {
          _data[err.inner[i].path] = err.inner[i].message;
        }
      }
      _res = _data;
    });
  if (_res === false) return false;
  else throw _res;
};

export const _isFieldValid = async (schema, data) => {
  const _res = await schema
    .validate(data, Options)
    .then((e) => true)
    .catch((err) => false);
  return _res;
};

export function requireKey(key) {
  return validation.require(null, key);
}

export default validation;
