// import React from "react";
import { useMediaQuery } from "react-responsive";

const MediaQuery = ({ children, device, minWidth }) => {
  const devices = {
    desktop: { minWidth: minWidth + 1 || 769 },
    mobile: { maxWidth: minWidth || 768 },
  };
  const condition = useMediaQuery(devices[device]);
  switch (device) {
    case "desktop":
      switch (condition) {
        case true:
          return children;
        default:
          return null;
      }
    case "mobile":
      switch (condition) {
        case true:
          return children;
        default:
          return null;
      }
    default:
      return null;
  }
};

export default MediaQuery;
