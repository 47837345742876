import React from "react";
import ContainerDesktop from "../../organism/Container/ContainerDesktop";
import CardSimulation from "../../organism/ProfileRiskPage/CardSimulation";
import { Skeleton } from "@material-ui/lab";
import { Divider } from "@material-ui/core";

const TempProfileRiskDesktop = (props) => {
  const { children, data } = props;
  const { cardProfileRisk, product_list, profile_risk, onSubscript } = data;
  return (
    <ContainerDesktop
      right={
        Object.keys(profile_risk).length > 0 ? (
          <div className="pt-4 px-4">
            {cardProfileRisk}
            {product_list}
          </div>
        ) : (
          <div className="pt-4 px-4">
            <div className="card-custom">
              <Skeleton height="32px" width="100%" className='mb-3'/>
              <Skeleton height="18px" width="100%" />
              <Skeleton height="18px" width="100%" />
              <Divider className="my-3" />
              <Skeleton height="18px" width="100%" className='mb-2'/>
              <Skeleton height="150px" width="100%" className='mb-2'/>
            </div>
          </div>
        )
      }
      left={
        Object.keys(profile_risk).length > 0 ? (
          <div className="pt-4 px-4">
            <CardSimulation
              profileRiskProduct={profile_risk.profile_risk_product}
              onSubscript={onSubscript}
            />
            {children}
          </div>
        ) : (
          <div className="pt-4 px-4">
            <div className="card-custom">
              <Skeleton height="18px" width="30%" className="mb-4" />
              <Skeleton height="40px" width="70%" className="mb-2" />
              <Skeleton height="40px" width="70%" className="mb-2" />
              <Skeleton height="40px" width="70%" className="mb-4" />
              <Skeleton height="18px" width="50%" className="mb-2" />
              <Skeleton height="18px" width="60%" />
              <Skeleton height="300px" width="100%" className="mb-2" />
            </div>
          </div>
        )
      }
    />
  );
};

export default TempProfileRiskDesktop;
