import React from "react";
import { Grid, TextField } from "@material-ui/core";

const OtpInputNumber = (props, ref) => {
  const input1 = React.useRef(null);
  const input2 = React.useRef(null);
  const input3 = React.useRef(null);
  const input4 = React.useRef(null);
  const [state, setState] = React.useState({
    focus: 1,
    1: "",
    2: "",
    3: "",
    4: "",
  });
  const handleChange = ({ target: { value, name } }) => {
    if (value !== "") {
      setState((state) => ({ ...state, focus: parseInt(name) + 1 }));
    }
    setState((state) => ({ ...state, [name]: value }));
  };
  React.useEffect(() => {
    const { focus } = state;
    switch (focus) {
      case 1:
        input1.current.focus();
        break;
      case 2:
        input2.current.focus();
        break;
      case 3:
        input3.current.focus();
        break;
      case 4:
        input4.current.focus();
        break;
      default:
        break;
    }
  }, [state]);
  React.useImperativeHandle(ref, () => ({
    getValue: () => {
      return state["1"] + state["2"] + state["3"] + state["4"];
    },
  }));
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            onKeyPress={({ target, nativeEvent }) => console.log(nativeEvent)}
            value={state["input1"]}
            variant="outlined"
            onChange={handleChange}
            inputRef={input1}
            name="1"
            inputProps={{ maxLength: "1", className: "text-center" }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            value={state["input2"]}
            onChange={handleChange}
            inputRef={input2}
            variant="outlined"
            name="2"
            inputProps={{ maxLength: "1", className: "text-center" }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            value={state["input3"]}
            onChange={handleChange}
            inputRef={input3}
            variant="outlined"
            name="3"
            inputProps={{ maxLength: "1", className: "text-center" }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            value={state["input4"]}
            onChange={handleChange}
            inputRef={input4}
            variant="outlined"
            name="4"
            inputProps={{ maxLength: "1", className: "text-center" }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default React.forwardRef(OtpInputNumber);
