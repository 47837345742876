import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { memo } from "react";
import SwipeableViews from "react-swipeable-views";
import TabsPanel from "../../molecule/Tabs/TabsPanel";

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up("lg")]: {
      marginRight: 3,
    },
  },
  imgContainer: {
    height: 63,
    width: 68,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  imgItemFaq: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
}));

const ContentComponent = memo(
  ({ data, onClick, idGroup }) => {
    const classes = useStyles();
    return (
      <div style={{ padding: 16 }}>
        <Grid container spacing={4}>
          {data.map((el, id) => (
            <Grid
              id={`${el.id}-btn`}
              className={classes.container}
              item
              xs={6}
              lg={2}
              md={3}
              key={id}
            >
              <div
                className="is-link d-flex flex-column align-items-center text-center"
                onClick={() => onClick(el, idGroup)}
              >
                <div className={classes.imgContainer}>
                  <img
                    src={el.icon}
                    alt="icon"
                    className={classes.imgItemFaq}
                  />
                </div>
                <p className="basic ws fs-10 font-weight-medium mt-1 text-capitalize">
                  {el.title?.toLowerCase()}
                </p>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    );
  },
  (p, n) => p.data === n.data
);

const FaqMenuList = (props) => {
  const { knowledgeCenter, faq, value, onChange, onClick, search, mode } =
    props;
  const [state, setState] = React.useState({
    mergeContent: [],
  });
  const { mergeContent } = state;
  React.useEffect(() => {
    if (mode === "search") {
      let tmp = [...knowledgeCenter, ...faq];
      tmp = tmp.filter((el) => el.title.toLowerCase().indexOf(search) !== -1);
      setState((state) => ({ ...state, mergeContent: tmp }));
    }
  }, [search, mode, knowledgeCenter, faq]);
  const handleChange = (e) => {
    onChange(e);
  };
  const handleClick = (e, title) => {
    onClick(e, title);
  };

  const isNormal = mode === "normal";
  return (
    <div>
      {isNormal ? (
        <SwipeableViews
          style={{ overflow: "unset" }}
          index={value}
          onChangeIndex={handleChange}
        >
          <TabsPanel value={value} index={0}>
            <ContentComponent
              data={knowledgeCenter}
              onClick={handleClick}
              idGroup="KNOWLEDGE CENTER"
            />
          </TabsPanel>
          <TabsPanel value={value} index={1}>
            <ContentComponent
              data={faq}
              onClick={handleClick}
              idGroup="F.A.Q REKSADANA"
            />
          </TabsPanel>
        </SwipeableViews>
      ) : (
        <ContentComponent
          data={mergeContent}
          onClick={handleClick}
          idGroup="KNOWLEDGE CENTER"
        />
      )}
    </div>
  );
};

export default FaqMenuList;
