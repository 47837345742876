import { Button, makeStyles } from "@material-ui/core";
import ChevronRight from "@material-ui/icons/ChevronRight";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import MenuProfileIcon from "../../../assets/img/icon/profile/MenuProfileIcon";
import ilustration from "../../../assets/img/ilustration";
import color from "../../../assets/theme/color";
import {
  resend_verif_email,
  re_regis,
} from "../../../config/redux/actions/auth";
import processHelper, { getNewAlert } from "../../../helper/processHelper";
import BasicText from "../../atom/BasicText/BasicText";
import useCompareDidUpdate from "../../atom/hooks/useCompareDidUpdate";
import DialogAlert from "../../molecule/Alert/DialogAlert";
import DialogPin from "../PinInput/DialogPin";
import { useMemo } from "react";
import {
  profile_risk_props,
  set_profile,
} from "../../../config/redux/actions/profile";
import { useCallback } from "react";

const baseIcon = {
  email: "mail",
  syailendra: "clock",
  ksei: "person",
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
  },
  contentTop: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "21px 0",
    borderBottom: "1px solid #E8ECEF",
  },
  contentVerif: { padding: "23px 30px" },
  containCheck: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 26,
    width: 26,
    borderRadius: 13,
    border: "1px solid " + color.primary,
  },
  checkList: {
    width: 13,
    height: 12,
  },
  contentMsg: {
    borderTop: "2px dotted " + color.gray_4,
    borderBottom: "2px dotted " + color.gray_4,
    padding: "10.5px 0 8.5px",
    margin: "22px 0 27.5px",
  },
}));

const RowVerification = ({ icon, text, value }) => {
  const S = useStyles();
  return (
    <div className="d-flex align-items-center" style={{ marginBottom: 10 }}>
      <MenuProfileIcon
        icon={icon}
        viewBox="0 0 16 16"
        style={{ height: 16, width: 16, color: color.gray_4 }}
      />
      <BasicText
        className="fs-12 flex-grow-1 pr-1 pl-3 text-uppercase"
        colorLevel="light"
      >
        verifikasi {text}
      </BasicText>
      <div className={S.containCheck}>
        {value && (
          <MenuProfileIcon
            icon="check_list"
            viewBox="0 0 12.1 11.6"
            className={S.checkList}
          />
        )}
      </div>
    </div>
  );
};

const ActionVerif = ({ verifStatus, resend, result, alert }) => {
  const dispatch = useDispatch();
  const { email, ksei } = verifStatus || {};
  const [modal, setmodal] = useState(false);
  function _resend({ pin }) {
    resend(pin);
  }
  useEffect(() => {
    if (Object.keys(alert).length) dispatch(profile_risk_props());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (result.type === "success") setmodal(false);
  }, [result]);

  const handleReReg = useCallback(
    ({ pin }) => {
      dispatch(
        set_profile({
          pin,
          callback: (data) => {
            dispatch(re_regis(data));
            setmodal((prev) => !prev);
          },
        })
      );
    },
    [dispatch]
  );

  return Object.keys(alert).length > 1 ? (
    <div className="text-center">
      <Button
        className="fs-12 font-weight-semibold color-primary"
        // endIcon={<ChevronRight />}
        // onClick={() => history.push(pageToURL.coming_soon)}
        // onClick={() => dispatch(re_regis())}
        onClick={() => setmodal(true)}
      >
        {alert.button}
      </Button>
      <DialogPin
        modal={modal}
        onSubmit={handleReReg}
        processName="set_profile"
        onClose={() => setmodal(!modal)}
      />
    </div>
  ) : email ? (
    <BasicText
      className="fs-12 font-weight-semibold, text-center"
      color="primary"
    >
      {ksei ? "SELAMAT BERINVESTASI!" : "VERIFIKASI SELANJUTNYA MASIH DIPROSES"}
    </BasicText>
  ) : (
    <div className="text-center">
      <Button
        className="fs-12 font-weight-semibold color-primary"
        endIcon={<ChevronRight />}
        onClick={() => setmodal(true)}
      >
        {"kirim ulang email verifikasi"}
      </Button>
      <DialogPin
        modal={modal}
        onSubmit={_resend}
        processName="resend-verif"
        onClose={() => setmodal(!modal)}
      />
      <DialogAlert resultName="resend-verif" alertAppear="both" />
    </div>
  );
};

const CardStatusRegister = ({
  regis_status,
  alert,
  resend_verif_email,
  result_stack,
  registration_token,
}) => {
  const history = useHistory();
  const { message, verification } = regis_status;
  const isUpdate = useCompareDidUpdate(registration_token);
  const _verif = verification || {};
  const S = useStyles();
  let data = useMemo(
    () => processHelper.findResult("resend-verif", result_stack),
    [result_stack]
  );
  useEffect(() => {
    if (isUpdate) history.push("/register/identity");
  }, [history, isUpdate]);
  return (
    <div className={S.root}>
      <div className={S.contentTop}>
        <img src={ilustration.statusRegister} alt="" className="mb-3 pb-1" />
        <BasicText
          className="fs-14 font-weight-bold text-center"
          color="primary"
        >
          {message?.header_message}
        </BasicText>
      </div>
      <div className={S.contentVerif}>
        {Object.keys(_verif).map((key) => (
          <RowVerification
            icon={baseIcon[key]}
            key={key}
            text={key}
            value={_verif[key]}
          />
        ))}
        <div className={S.contentMsg}>
          <BasicText
            className={classNames("fs-12", alert.length && "color-primary")}
            colorLevel="light"
          >
            {alert?.description ?? message?.message}
          </BasicText>
        </div>
        <ActionVerif
          verifStatus={verification}
          resend={resend_verif_email}
          result={data}
          alert={alert}
        />
      </div>
    </div>
  );
};

CardStatusRegister.defaultProps = {
  data: {},
};

const mapStateToProps = ({ profileReducer, stateReducer, authReducer }) => ({
  regis_status: profileReducer.regis_status,
  alert: getNewAlert(profileReducer.alert, "REGIST"),
  result_stack: stateReducer.result_stack,
  registration_token: authReducer.register,
});
const mapDispatchToProps = {
  resend_verif_email,
};
export default connect(mapStateToProps, mapDispatchToProps)(CardStatusRegister);
