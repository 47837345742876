import React from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import classNames from "classnames";
import makeStyles from "@material-ui/styles/makeStyles";
import color from "../../../assets/theme/color";
import PropTypes from "prop-types";
import textHelper from "../../../helper/textHelper";

function ButtonContained(props) {
  const {
    onClick,
    className,
    type,
    text,
    loading,
    disabled,
    backgroundColor,
    transparent,
    href,
    radius,
    Icon,
    disableRipple,
    disableElevation,
    iconContent,
    name,
    id,
    ...other
  } = props;
  const useStyles = makeStyles({
    root: {
      backgroundColor: transparent
        ? textHelper.hexToRGBA(backgroundColor, 0.3)
        : backgroundColor,
      borderRadius: radius ? radius : "unset",
      // opacity: transparent ? "0.5" : "unset",
      position: "relative",
      textTransform: "none",
      transition: "all 0.5s",
      "&:hover": {
        backgroundColor: transparent
          ? textHelper.hexToRGBA(backgroundColor, 0.3)
          : backgroundColor,
        opacity: 1,
      },
    },
    buttonProgress: {
      color: color.primaryDark,
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  });
  const classes = useStyles();
  return (
    <Button
      id={id}
      type={type}
      variant="contained"
      color="primary"
      className={classNames(classes.root, className)}
      onClick={() => onClick(name)}
      disabled={disabled || loading}
      href={href}
      startIcon={Icon && <Icon />}
      disableElevation={disableElevation}
      disableRipple={disableRipple}
      {...other}
    >
      {text || iconContent}
      {loading && (
        <CircularProgress size={20} className={classes.buttonProgress} />
      )}
    </Button>
  );
}

ButtonContained.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
  // text: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  backgroundColor: PropTypes.string,
  transparent: PropTypes.bool,
  href: PropTypes.string,
  radius: PropTypes.string,
  disableRipple: PropTypes.bool,
  disableElevation: PropTypes.bool,
  iconContent: PropTypes.any,
  name: PropTypes.string,
};

ButtonContained.defaultProps = {
  onClick: () => null,
  className: "",
  type: "button",
  text: "",
  loading: false,
  disabled: false,
  disableRipple: false,
  disableElevation: true,
  name: "",
};

export default ButtonContained;
