import React from "react";
import TextInput from "../../molecule/MaterialInput/TextInput";
import PropTypes from "prop-types";
import { InputLabel } from "@material-ui/core";
import SelectsInput from "../../molecule/MaterialInput/SelectsInput";
import ButtonContained from "../../atom/Button/ButtonContained";
import color from "../../../assets/theme/color";
const Options = {
  strict: false,
  abortEarly: false,
  stripUnknown: false,
  recursive: true,
};
function GenerateForm(props) {
  const {
    formData,
    initial,
    schema,
    onSubmit,
    loading,
    buttonText,
    buttonCancelText,
    containerClass,
    buttonClass,
    buttonCancelClass,
    formContainerClass,
    buttonContainerClass,
    onClickCancel,
    containerOther,
  } = props;
  const [state, setState] = React.useState(initial);
  const [error, setError] = React.useState({ all: true });
  const [focus, setFocus] = React.useState({});

  const _onChange = async (value, target, validation) => {
    let data = { ...state };
    let _value = value;
    if (formData.find((formItem) => formItem.target === target).onlyNum) {
      _value = _value.match(/\d/g)?.join("") ?? "";
    }
    setState((state) => ({ ...state, [target]: _value }));
    data[target] = _value;
    await schema
      .validate(data, Options)
      .then((e) => {
        setError({ all: false });
      })
      .catch((err) => {
        let data = error;
        if (Object.keys(data).length !== err.inner) {
          data = {};
          for (let i = 0; i < err.inner?.length ?? 0; i++) {
            data[err.inner[i].path] = err.inner[i].message;
          }
        }
        setError({ ...data, all: true });
      });
  };
  const _onFocus = (where) => {
    let data = focus;
    data[where] = true;
    setFocus({ ...focus, ...data });
  };
  const _onSubmit = () => {
    onSubmit(state);
  };
  const hancleClickCancel = () => {
    onClickCancel();
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <form className={containerClass} onSubmit={handleSubmit}>
      <div className={formContainerClass}>
        {formData.map((el, id) => (
          <div key={id} className={el.marginClass}>
            {el.label && (
              <InputLabel className={el.labelClass}>
                {el.labelIcon && el.labelIcon}
                {el.label}
              </InputLabel>
            )}
            {el.type === "select" ? (
              <SelectsInput
                name={el.target}
                onChange={_onChange}
                data={el.data}
                inputClass={el.inputClass}
                value={state[el.target]}
                defaultValue={el.defaultValue}
                variant={el.variant}
              />
            ) : (
              <TextInput
                id={`input-${el.label.replace(/ /g,'-').toLowerCase()}`}
                placeholder={el.placeholder}
                type={el.type}
                variant={el.variant}
                startIcon={el.startIcon}
                onChange={(e) => _onChange(e, el.target, el.validate)}
                onFocus={() => _onFocus(el.target)}
                error={error[el.target] && focus[el.target] ? true : false}
                errText={focus[el.target] && error[el.target]}
                value={state[el.target]}
                inputClass={el.inputClass}
                passVisible={el.passVisible}
                disabled={el.disabled}
              />
            )}
          </div>
        ))}
        {containerOther}
      </div>
      <div className={buttonContainerClass}>
        <ButtonContained
          id="submit-form-button"
          text={buttonText}
          className={buttonClass}
          onClick={_onSubmit}
          disabled={error.all}
          loading={loading}
          radius="5px"
          type="submit"
        />
        {buttonCancelText && (
          <ButtonContained
            id="cancel-form-button"
            backgroundColor={color.gray_5}
            className={buttonCancelClass}
            text={buttonCancelText}
            radius="5px"
            onClick={hancleClickCancel}
          />
        )}
      </div>
    </form>
  );
}

GenerateForm.propTypes = {
  formData: PropTypes.array,
  initial: PropTypes.object,
  schema: PropTypes.any,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonClass: PropTypes.string,
  containerClass: PropTypes.string,
  formContainerClass: PropTypes.string,
  buttonContainerClass: PropTypes.string,
  containerOther: PropTypes.any,
  buttonCancelClass: PropTypes.string,
  buttonCancelText: PropTypes.string,
  onClickCancel: PropTypes.func,
};

export default GenerateForm;
