import * as types from "./types";

export const set_product = payload => {
  return {
    type: types.SET_PRODUCT,
    payload
  };
};
export const product_list = payload => {
  return {
    type: types.SET_PRODUCT_LIST,
    payload
  };
};
export const top_product = payload => {
  return {
    type: types.SET_TOP_PRODUCT,
    payload
  };
};
export const top_product_list = payload => {
  return {
    type: types.SET_TOP_PRODUCT_LIST,
    payload
  };
};
export const set_trending_props = () => {
  return {
    type: types.SET_TRENDING_PROPS
  };
};
export const set_trending = payload => {
  return {
    type: types.SET_TRENDING,
    payload
  };
};
export const product_type_props = () => {
  return {
    type: types.PRODUCT_TYPE_PROPS
  };
};
export const product_type = payload => {
  return {
    type: types.PRODUCT_TYPE,
    payload
  };
};
export const product_history_props = payload => {
  return {
    type: types.PRODUCT_HISTORY_PROPS,
    payload
  };
};
export const product_history = payload => {
  return {
    type: types.PRODUCT_HISTORY,
    payload
  };
};
export const clear_product_history_props = () => ({
  type: types.CLEAR_PRODUCT_HISTORY_PROPS
});
export const clear_product_history = () => ({
  type: types.CLEAR_PRODUCT_HISTORY
});
