import classNames from "classnames";
import React from "react";
import BasicText from "../../atom/BasicText/BasicText";
const { lazy, Suspense, useCallback, useRef } = React;
const ScrollMenu = lazy(() => import("react-horizontal-scrolling-menu"));

const GenerateHorizontal = ({
  data,
  loading,
  render,
  onClickItem,
  options,
  active,
  className,
  propsLoader,
  component,
  innerWrapperClass,
  ...other
}) => {
  const handleSelect = useCallback((key) => onClickItem(key), [onClickItem]);
  const menuRef = useRef();

  const renderMenu = useCallback(
    ({
      data = [],
      render: Render,
      active,
      options = {},
      component: { footer, header },
    }) => {
      const list = data.map((item, index) => {
        const { key, className, paddingStart, paddingEnd, itemProps } = options;
        const isFirst = index === 0 && { marginLeft: paddingStart };
        const isLast = index === data.length - 1 &&
          paddingEnd && {
            marginRight: `${paddingEnd}px !important`,
          };
        const id = item[key || "id"] || index;
        return (
          <Render
            item={item}
            key={id.toString()}
            index={id}
            active={String(id) === String(active)}
            className={classNames(className, `item-${index}`)}
            style={isFirst || isLast || {}}
            {...itemProps}
          />
        );
      });
      if (footer) list.push(footer);
      if (header) list.unshift(header);
      return list;
    },
    []
  );

  const menu = renderMenu({ data, render, active, options, component });

  const { spacing } = options;
  const isDataReady = data.length !== 0 && !loading;

  if (loading) {
    return <BasicText>...Loading</BasicText>;
  }

  return (
    <Suspense fallback={<BasicText>...Loading</BasicText>}>
      {isDataReady ? (
        <ScrollMenu
          ref={menuRef}
          data={menu}
          onSelect={handleSelect}
          hideArrows
          hideSingleArrow
          menuClass={className}
          selected={active?.toString()}
          itemClass={`menu-item-wrapper pr-${spacing || "1"}`}
          scrollToSelected
          alignCenter={false}
          wheel={false}
          innerWrapperClass={classNames(
            "d-flex align-items-stretch",
            innerWrapperClass
          )}
          {...other}
        />
      ) : (
        <BasicText>data tidak di temukan</BasicText>
      )}
    </Suspense>
  );
};

GenerateHorizontal.defaultProps = {
  onClickItem: () => {},
  data: [],
  render: () => {
    return <div />;
  },
  options: {},
  propsLoader: {},
  component: {},
};

export default GenerateHorizontal;
