import { useCallback, useState } from 'react'

const usePointProfileRisk = (initial = 1) => {
    const [point, setPoint] = useState(initial)

    const handleAdd = useCallback(() => setPoint(prev => prev + 1), [])

    const handleMinus = useCallback(() => setPoint(prev => prev - 1), [])

    return [point, handleAdd, handleMinus]
}

export default usePointProfileRisk
