import React from "react";
import { Email } from "@material-ui/icons";
import * as yup from "yup";
import BasicText from "../../atom/BasicText/BasicText";
import GenerateForm from "../../template/generate/GenerateForm";
import DialogPin from "../PinInput/DialogPin";
import { change_email_props } from "../../../config/redux/actions/profile";
import { connect } from "react-redux";
import DialogAlert from "../../molecule/Alert/DialogAlert";
import processHelper from "../../../helper/processHelper";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.up(768)]: {
      justifyContent: "space-between",
    },
    "& .btnNext": {
      fontSize: 14,
      fontWeight: 600,
      padding: "12px",
      flex: 4,
      marginRight: 20,
      [theme.breakpoints.down(769)]: {
        marginRight: 0,
        width: "100%",
      },
    },
    "& .btnCancel": {
      fontSize: 14,
      flex: 3,
      fontWeight: 600,
      padding: "12px 0",
      [theme.breakpoints.down(769)]: {
        display: "none",
      },
    },
  },
  inputText: {
    marginBottom: 8,
    maxWidth: 345,
  },
  container: {
    minHeight: "50vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.up(768)]: {
      minHeight: "60vh",
    },
  },
}));

const schema = yup.object().shape({
  email: yup.string().email("Email tidak valid").required("Email harus diisi"),
});

const ChangeEmail = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const formData = [
    {
      target: "email",
      placeholder: "username@email.com",
      marginClass: classes.inputText,
      type: "text",
      inputClass: "fs-16",
      variant: "outlined",
      label: "MASUKAN ALAMAT EMAIL BARU",
      labelClass: "fs-14 mb-3 color-secondary",
      startIcon: Email,
    },
  ];
  const { change_email_props, result_stack, onClickCancel } = props;
  React.useEffect(() => {
    let data = processHelper.findResult("change_email", result_stack);
    if (Object.keys(data).length > 0) {
      setState((state) => ({ ...state, modal: false }));
    }
  }, [result_stack]);
  const [form, setForm] = React.useState({
    email: "",
  });
  const [state, setState] = React.useState({
    modal: false,
  });
  const handleSubmit = (e) => {
    setForm({ ...form, ...e });
    handleModal(true);
  };
  const handleModal = (e) => {
    setState({ ...state, modal: e });
  };
  function _handleSuccess() {
    history.goBack();
  }
  const { modal } = state;
  return (
    <div>
      <BasicText className="fs-14 mb-4 font-weight-bold" color="primary">
        MASUKAN ALAMAT EMAIL KAMU YANG BARU
      </BasicText>
      <GenerateForm
        formData={formData}
        initial={form}
        schema={schema}
        containerClass={classes.container}
        buttonClass="btnNext"
        buttonText="KIRIM VERIFIKASI EMAIL"
        buttonCancelText="BATAL"
        buttonCancelClass="btnCancel"
        onClickCancel={onClickCancel}
        buttonContainerClass={classes.buttonContainer}
        onSubmit={handleSubmit}
      />
      <DialogPin
        modal={modal}
        onClose={handleModal}
        onSubmit={change_email_props}
        dataSubmit={form}
        processName="change_email"
      />
      <DialogAlert
        resultName="change_email"
        alertAppear="both"
        buttonText="OK"
        submitIfSuccess={_handleSuccess}
      />
    </div>
  );
};

const mapState = (state) => ({ result_stack: state.stateReducer.result_stack });

export default connect(mapState, { change_email_props })(ChangeEmail);
