import React from "react";
import { Grid } from "@material-ui/core";
import ButtonContained from "../../atom/Button/ButtonContained";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import color from "../../../assets/theme/color";
const PinButton = props => {
  const handleClick = e => {
    props.onClick(e);
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={4}>
        <ButtonContained
          id="button-1"
          backgroundColor="#fff"
          className="color-secondary fs-22 w-100 py-2"
          text="1"
          disableElevation={false}
          radius="10px"
          onClick={() => handleClick("1")}
        />
      </Grid>
      <Grid item xs={4}>
        <ButtonContained
          id="button-2"
          backgroundColor="#fff"
          className="color-secondary fs-22 w-100 py-2"
          text="2"
          disableElevation={false}
          radius="10px"
          onClick={() => handleClick("2")}
        />
      </Grid>
      <Grid item xs={4}>
        <ButtonContained
          id="button-3"
          backgroundColor="#fff"
          className="color-secondary fs-22 w-100 py-2"
          text="3"
          disableElevation={false}
          radius="10px"
          onClick={() => handleClick("3")}
        />
      </Grid>
      <Grid item xs={4}>
        <ButtonContained
          id="button-4"
          backgroundColor="#fff"
          className="color-secondary fs-22 w-100 py-2"
          text="4"
          disableElevation={false}
          radius="10px"
          onClick={() => handleClick("4")}
        />
      </Grid>
      <Grid item xs={4}>
        <ButtonContained
          id="button-5"
          backgroundColor="#fff"
          className="color-secondary fs-22 w-100 py-2"
          text="5"
          disableElevation={false}
          radius="10px"
          onClick={() => handleClick("5")}
        />
      </Grid>
      <Grid item xs={4}>
        <ButtonContained
          id="button-6"
          backgroundColor="#fff"
          className="color-secondary fs-22 w-100 py-2"
          text="6"
          disableElevation={false}
          radius="10px"
          onClick={() => handleClick("6")}
        />
      </Grid>
      <Grid item xs={4}>
        <ButtonContained
          id="button-7"
          backgroundColor="#fff"
          className="color-secondary fs-22 w-100 py-2"
          text="7"
          disableElevation={false}
          radius="10px"
          onClick={() => handleClick("7")}
        />
      </Grid>
      <Grid item xs={4}>
        <ButtonContained
          id="button-8"
          backgroundColor="#fff"
          className="color-secondary fs-22 w-100 py-2"
          text="8"
          disableElevation={false}
          radius="10px"
          onClick={() => handleClick("8")}
        />
      </Grid>
      <Grid item xs={4}>
        <ButtonContained
          id="button-9"
          backgroundColor="#fff"
          className="color-secondary fs-22 w-100 py-2"
          text="9"
          disableElevation={false}
          radius="10px"
          onClick={() => handleClick("9")}
        />
      </Grid>
      <Grid item xs={4}>
        <ButtonContained
          id="button-delete"
          backgroundColor="#fff"
          className="color-secondary fs-22 w-100 h-100"
          iconContent={<ArrowBackIcon className="color-secondary" />}
          disableElevation={false}
          radius="10px"
          onClick={() => handleClick("del")}
        />
      </Grid>
      <Grid item xs={4}>
        <ButtonContained
          id="button-0"
          backgroundColor="#fff"
          className="color-secondary fs-22 w-100 py-2"
          text="0"
          disableElevation={false}
          radius="10px"
          onClick={() => handleClick("0")}
        />
      </Grid>
      <Grid item xs={4}>
        <ButtonContained
          id="button-done"
          backgroundColor={color.secondary}
          className="color-secondary fs-22 w-100 py-2 text-white"
          text="Done"
          disableElevation={false}
          radius="10px"
          onClick={() => handleClick("done")}
          loading={props.loading}
        />
      </Grid>
    </Grid>
  );
};

export default PinButton;
