import PropTypes from "prop-types";
import React from "react";
import chartHelper from "../../../helper/chartHelper";

const PortoChart = (props) => {
  const { summary, category, id, containerClass } = props;
  const [state, setState] = React.useState({
    colors: [],
    data: [],
    once_state: true,
  });
  const { colors, data, once_state } = state;
  React.useEffect(() => {
    if (
      Object.keys(summary).length > 0 &&
      Object.keys(summary.chart).length > 0 &&
      once_state
    ) {
      const { chart } = summary;
      chart[category].forEach((e) => {
        setState((state) => {
          let colors = [...state.colors, e.color_web || e.color];
          let data = [...state.data, { name: e.name, y: e.y }];
          return { ...state, colors, data, once_state: false };
        });
      });
    }
    if (colors.length > 0 && data.length > 0) {
      chartHelper.createPiChart(colors, data, id);
    } else {
      chartHelper.createPiChart(
        ["#E5E5E5E5"],
        [{ name: "Empty", y: 4941036823.306697 }],
        id
      );
    }
  }, [summary, colors, data, once_state, category, id]);

  return <div id={id} className={containerClass}></div>;
};

PortoChart.propTypes = {
  summary: PropTypes.object,
  category: PropTypes.string,
  id: PropTypes.string,
  containerClass: PropTypes.string,
};

export default PortoChart;
