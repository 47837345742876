import React, { memo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gbl_act_lc } from "../../../config/redux/actions/state";
import stateCallApi from "../../../config/API/stateCallApi";
import BasicText from "../../atom/BasicText/BasicText";
import { makeStyles } from "@material-ui/core";
import GenerateHorizontal from "../../template/generate/GenerateHorizontal";
import { useHistory } from "react-router-dom";
import { selectorGetVoucherMerchant } from "../../../config/redux/reducer/optionReducer";

const VoucherList = () => {
  const { push } = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const vouchers = useSelector(selectorGetVoucherMerchant);

  const renderItem = useCallback(
    ({ item, style, render, onClickItem }) => (
      <div key={item.id} style={style} className={render} onClick={onClickItem}>
        <div className={classes.item}>
          <img src={item.merchant.logo_url} width="100%" height={124} alt="" />
          <BasicText
            className="fs-13 mt-3 text-wrap"
            color="secondary"
            colorLevel="main"
          >
            {item.title}
          </BasicText>
        </div>
      </div>
    ),
    [classes.item]
  );

  useEffect(() => {
    dispatch(gbl_act_lc(stateCallApi.voucherMerchantList));
  }, [dispatch]);

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mb-3">
        <BasicText
          className="fs-14 fw-700 basic"
          color="secondary"
          colorLevel="light"
        >
          Privilage
        </BasicText>
        <a className={classes.linkSeeAll} href="/voucher-merchant">
          Lihat Semua
        </a>
      </div>
      <GenerateHorizontal
        className="mx-n4"
        data={vouchers}
        render={renderItem}
        onClickItem={(id) => push(`/voucher-merchant/detail?id=${id}`)}
        options={{ spacing: 4, paddingStart: 24, paddingEnd: 24 }}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  list: {
    display: "flex",
    gap: 24,
    overflowX: "auto",
  },
  item: {
    width: 164,
  },
  linkSeeAll: {
    textDecoration: "unset !important",
    fontWeight: "700",
    fontSize: 13,
    color: `${theme.palette.primary.main} !important`,
  },
}));

export default memo(VoucherList);
