import React, { useState } from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  Button,
  TextField,
  withStyles,
  InputAdornment,
  //   Select,
  MenuItem,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import id from "date-fns/locale/id";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import ButtonContained from "../../atom/Button/ButtonContained";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import color from "../../../assets/theme/color";
import textHelper from "../../../helper/textHelper";
const TextFieldCustom = withStyles((theme) => ({
  root: {
    width: 130,
    marginRight: "1rem",
    "& .MuiInputBase-root": {
      "&::before,&:hover::before,&::after": {
        borderBottom: "unset!important",
      },
      "& input": {
        fontSize: 12,
        color: theme.palette.secondary.main,
        fontWeight: 600,
        textTransform: "uppercase",
        padding: "7px 0 5px",
      },
    },
  },
}))(TextField);

const SelectCustom = withStyles((theme) => ({
  root: {
    width: 70,
    marginRight: "1rem",
    "& .MuiInputBase-root": {
      "&::before,&:hover::before,&::after": {
        borderBottom: "unset!important",
      },
      "& .MuiSelect-root": {
        fontSize: 12,
        color: theme.palette.secondary.main,
        fontWeight: 600,
        textTransform: "uppercase",
        padding: "7px 0 5px",
      },
    },
  },
}))(TextField);
const PortoTabelMenu = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [state, setState] = useState({
    data: [
      { id: "pdf", name: "PDF" },
      { id: "xls", name: "XLS" },
    ],
    dataValue: "pdf",
  });
  const { date, onDownload } = props;
  const { dataValue, data } = state;
  const handleChange = (key, e) => {
    let value = "";
    if (key === "dataValue") {
      value = e.target.value;
    } else {
      value = e;
    }
    if (key !== "date") setState((state) => ({ ...state, [key]: value }));
    else value = textHelper.printDate(value, "YYYY-MM");
    props.onChange(key, value);
  };

  return (
    <div className="d-flex align-items-center mb-2 justify-content-end">
      <Button
        id="periode-btn"
        onClick={() => setIsOpen(true)}
        className="fs-12 color-primary p-0 mr-2"
      >
        <ExpandMoreIcon /> LIHAT PERIODE
      </Button>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={id}>
        <DatePicker
          views={["year", "month"]}
          open={isOpen}
          onClose={() => setIsOpen(false)}
          format="MMMM yyyy"
          value={date}
          maxDate={textHelper.printDateSubtractFormat(
            undefined,
            1,
            "months",
            ""
          )}
          onChange={(e) => handleChange("date", e)}
          TextFieldComponent={TextFieldCustom}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <CalendarTodayIcon className="fs-16 gray-4" />
              </InputAdornment>
            ),
          }}
        />
      </MuiPickersUtilsProvider>
      <SelectCustom
        id="extention-btn"
        value={dataValue}
        onChange={(e) => handleChange("dataValue", e)}
        select
        InputProps={{
          startAdornment: (
            <InputAdornment>
              <InsertDriveFileIcon className="fs-16 gray-4 mr-1" />
            </InputAdornment>
          ),
        }}
        SelectProps={{
          IconComponent: ExpandMoreIcon,
        }}
      >
        {data.map((el, id) => (
          <MenuItem
            id={`${el.id}-btn`}
            value={el.id}
            key={id}
            className="fs-12"
          >
            {el.name}
          </MenuItem>
        ))}
      </SelectCustom>
      <ButtonContained
        id="download-btn"
        text="DOWNLOAD"
        backgroundColor={color.secondaryLight}
        className="py-0 px-2 fs-12"
        radius="10px"
        onClick={onDownload}
      />
    </div>
  );
};

export default PortoTabelMenu;
