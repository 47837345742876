import { makeStyles } from "@material-ui/styles";
import React from "react";
import ContainerDesktopCard from "../../organism/Container/ContainerDesktopCard";

const useStyles = makeStyles((theme) => ({
  allocationAndPerform: {
    display: "grid",
    gridTemplateColumns: "50% 50%",
    [theme.breakpoints.down(1140)]: {
      display: "flex",
      flexDirection: "column-reverse",
    },
  },
  threeButton: {
    display: "grid",
    gridTemplateColumns: "65% 1px",
    [theme.breakpoints.down(1025)]: {
      gridTemplateColumns: "100%",
    },
    "& .threeButtonCenter": {
      width: 431,
      margin: "0 auto",
    },
  },
  bottom: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 11,
    "& .threeButtonCenter": {
      width: 392,
    },
  },
}));

const TempDetailProductDesktop = (props) => {
  const { data, children } = props;
  const {
    titleAndRisk,
    perform,
    allocation,
    graph,
    goals,
    detailInfo,
    twoButton,
    threeButton,
  } = data;
  const classes = useStyles();
  return (
    <ContainerDesktopCard
      left={
        <div className="px-4 py-3">
          {titleAndRisk}
          {goals}
          <div className={classes.allocationAndPerform}>
            {allocation}
            <div className="mb-3">{perform}</div>
          </div>
          {graph}
          <div className={classes.bottom}>
            <div className="threeButtonCenter">{threeButton}</div>
          </div>
          {children}
        </div>
      }
      right={
        <div className="px-4 py-3">
          {detailInfo}
          {twoButton}
        </div>
      }
      // bottom={
      //   <div className={classes.threeButton}>
      //     <div className="threeButtonCenter mb-3">{threeButton}</div>
      //     <div className="d-flex justify-content-center">
      //       <Divider orientation="vertical" />
      //     </div>
      //     {children}
      //   </div>
      // }
    />
  );
};

export default TempDetailProductDesktop;
