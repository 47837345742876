import React from "react";
import { makeStyles } from "@material-ui/core";
import MenuGoals from "../../molecule/GoalKamu/MenuGoals";
import color from "../../../assets/theme/color";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";
import GenerateHorizontal from "../../template/generate/GenerateHorizontal";

const useStyles = makeStyles({
  contain: {
    borderBottom: "1px solid " + color.gray_7,
    "& .row-contain": {
      marginBottom: 2,
    },
  },
});

const MenuGroupGoals = ({ data, onChange, selected }) => {
  const isDesktop = useMediaQuery({ minWidth: 769 });
  const S = useStyles();
  return (
    <GenerateHorizontal
      className={classNames(
        S.contain,
        isDesktop ? "mb-4" : "border-0 px-0 bg-white"
      )}
      data={data.map((e, key) => ({ ...e, key }))}
      onClickItem={onChange}
      render={MenuGoals}
      active={selected}
      innerWrapperClass="row-contain"
      options={{ key: "key", spacing: 0, paddingStart: 25, paddingEnd: 25 }}
    />
  );
};

MenuGroupGoals.defaultProps = {
  data: [],
};

export default MenuGroupGoals;
