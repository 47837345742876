import React from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import classNames from "classnames";
import makeStyles from "@material-ui/styles/makeStyles";
import color from "../../../assets/theme/color";
import PropTypes from "prop-types";
const useStyles = makeStyles({
  root: {
    borderRadius: "5px",
    position: "relative",
    textTransform: "none",
    color: "#fff"
  },
  buttonProgress: {
    color: color.primaryDark,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

function BasicButton(props) {
  const {
    id,
    onClick,
    className,
    type,
    text,
    loading,
    disabled,
    variant,
    icon
  } = props;
  const classes = useStyles();
  return (
    <Button
      id={id}
      type={type}
      variant={variant}
      color="primary"
      className={classNames(classes.root, className)}
      onClick={() => onClick()}
      startIcon={icon}
      disabled={disabled || loading}
    >
      {text}
      {loading && (
        <CircularProgress size={20} className={classes.buttonProgress} />
      )}
    </Button>
  );
}

BasicButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.string
};

BasicButton.defaultProps = {
  onClick: () => null,
  className: "",
  type: "button",
  text: "",
  loading: false,
  disabled: false,
  variant: "contained"
};

export default BasicButton;
