import React, { useCallback, forwardRef } from "react";
import useStyles from "./useStyles";
import classNames from "classnames";
import { ErrorMessage } from "@hookform/error-message";
import BasicText from "../../../atom/BasicText/BasicText";

const InputGlobal = forwardRef(
  (
    {
      id,
      label,
      inputProps,
      classes,
      className,
      adornment,
      onChange,
      name,
      errors,
      input: Input,
    },
    ref
  ) => {
    const classesComponent = useStyles();

    const handleClick = useCallback(
      ({ target }) => {
        onChange(target.value);
      },
      [onChange]
    );
    return (
      <div className={classNames(classesComponent.root, className)}>
        <p className={classNames("basic label", classes.label)}>{label}</p>
        <div className={classNames(classes.container_input, "container-input")}>
          {adornment.start}
          {Input ? (
            <Input
              {...inputProps}
              id={`${id}-input`}
              name={name}
              onChange={onChange}
              className={classNames(classes.input, "input")}
            />
          ) : (
            <input
              id={`${id}-input`}
              ref={ref}
              name={name}
              {...inputProps}
              onChange={handleClick}
              className={classNames(classes.input, "input")}
            />
          )}
          {adornment.end}
        </div>
        {errors && (
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => (
              <BasicText style={{ color: "red" }}>{message}</BasicText>
            )}
          />
        )}
      </div>
    );
  }
);

InputGlobal.defaultProps = {
  adornment: {},
  classes: {},
  onChange: () => {},
};

export default InputGlobal;
