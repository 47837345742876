import * as types from "./types";

export const portofolio_list = (payload) => {
  return {
    type: types.SET_PORTOFOLIO_LIST,
    payload,
  };
};
export const portofolio_summary = (payload) => {
  return {
    type: types.SET_PORTOFOLIO_SUMMARY,
    payload,
  };
};
export const portofolio_list_props = (payload) => {
  return {
    type: types.SET_PORTOFOLIO_LIST_PROPS,
    payload,
  };
};
export const portofolio_summary_props = () => {
  return {
    type: types.SET_PORTOFOLIO_SUMMARY_PROPS,
  };
};
export const porto_detail_props = (payload) => ({
  type: types.PORTO_DETAIL_PROPS,
  payload,
});
export const porto_detail = (payload) => ({
  type: types.PORTO_DETAIL,
  payload,
});
export const statement_props = (payload) => ({
  type: types.STATEMENT_PROPS,
  payload,
});
export const statement = (payload) => ({
  type: types.STATEMENT,
  payload,
});
export const statement_list = () => ({
  type: types.STATEMENT_LIST_PROPS,
});
export const open_statement = (payload) => ({
  type: types.OPEN_STATEMENT,
  payload,
});
