import React from "react";
import icon from "../../../assets/img/icon/product_risk";
import { makeStyles } from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles({
  img: {
    marginRight: 4.5,
  },
  h8: { height: 8 },
  h12: { height: 12 },
});

const RiskIndicator = props => {
  const { value, classCont, height } = props;
  const classes = useStyles();
  return (
    <div
      className={classNames(
        "d-flex",
        classCont,
        Number(height) === 8 ? classes.h8 : classes.h12
      )}
    >
      <img
        src={value >= 1 ? icon.on : icon.off}
        alt=""
        className={classes.img}
      />
      <img
        src={value >= 2 ? icon.on : icon.off}
        alt=""
        className={classes.img}
      />
      <img
        src={value >= 3 ? icon.on : icon.off}
        alt=""
        className={classes.img}
      />
      <img
        src={value >= 4 ? icon.on : icon.off}
        alt=""
        className={classes.img}
      />
      <img
        src={value >= 5 ? icon.on : icon.off}
        alt=""
        className={classes.img}
      />
    </div>
  );
};

export default RiskIndicator;
