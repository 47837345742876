import React from "react";
import ContainerWithLoader from "../../organism/Container/ContainerWithLoader";

const TempProfileMobile = props => {
  const { children } = props;
  return (
    <ContainerWithLoader processName="set_profile" loadingHeight="70vh">
      {children}
    </ContainerWithLoader>
  );
};

export default TempProfileMobile;
