import React from "react";
import { makeStyles } from "@material-ui/core";
import color from "../../../assets/theme/color";
import MenuIcon from "../../../assets/img/icon/profile/MenuProfileIcon";
import BasicText from "../../atom/BasicText/BasicText";
import classNames from "classnames";
import textHelper from "../../../helper/textHelper";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  cotain: {
    backgroundColor: color.gray_8,
    padding: 21,
    display: "flex",
    alignItems: "center",
    borderRadius: 8,
  },
  icon: {
    width: 12,
    height: 17,
    color: color.gray_3,
  },
  title: {
    fontSize: "12px !important",
    fontWeight: "bold !important",
    marginBottom: "2px !important",
    textTransform: "uppercase",
  },
  date: {
    fontSize: '10px !important',
    marginTop: '8px !important',
  }
});
const CardDocument = ({ className, item, onClick,id }) => {
  const S = useStyles();
  const { getDateMonthYear } = textHelper;
  return (
    <div
      id={id}
      className={classNames(S.cotain, "is-link", className)}
      onClick={() => onClick(item)}
    >
      <MenuIcon icon="file" className="color-secondary mt-2" />
      <div className="mx-3 flex-grow-1">
        <BasicText className={S.title}>{item.name}</BasicText>
        <BasicText className="fs-12" colorLevel="light">
          {item.desc}
        </BasicText>
        {item.date && <BasicText className={S.date} colorLevel="light">
          {getDateMonthYear(item.date)}
        </BasicText>}
      </div>
      <MenuIcon icon="download" className="color-secondary mt-2" />
    </div>
  );
};

CardDocument.propTypes = {
  item: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  onClick: PropTypes.func,
};

CardDocument.defaultProps = {
  onClick: () => { },
};

export default CardDocument;
