import React from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import TempAuth from "../../template/TempAuth";
import PageInputEmail from "./ForgotPageInputEmail";
import ValidatePage from "./ForgotValidatePage";
import ForgotChangePass from "./ForgotChangePass";
import { connect } from "react-redux";
import { PureComponent } from "react";

function _getEndPoint(pathname) {
  return pathname.split("/")[2];
}

// const hidden = ["validate", "change"];

const HiddenRoute = ({ children, otp_token, ...other }) => (
  <Route
    {...other}
    render={({ location }) => {
      const { pathname } = location;
      const page = _getEndPoint(pathname);
      if (page && otp_token) return children;
      else return <Redirect to="/masuk" />;
    }}
  />
);

class ForgotPass extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ilustrations: {
        undefined: {
          image: "forgotPass1",
          title: "Masukkan email yang terdaftar untuk verifikasi password kamu",
          titleClass: "mb-4",
        },
        validate: {
          image: "forgotPass2",
          imageClass: "align-self-start mt-5",
          title: "Masukkan kode verifikasi",
        },
      },
    };
  }
  render() {
    const { match, location, otp_token, otp } = this.props;
    const { path } = match;
    const { pathname } = location;
    const page = _getEndPoint(pathname);
    const isValidate = page === "validate";
    return (
      <TempAuth
        page="regisInputPhone"
        data={{
          title: "LUPA PASSWORD",
          ilustration: {
            ...this.state.ilustrations[page],
            subTitle: isValidate ? (
              <span className="fs-12 w-80">
                Kode verifikasi telah dikirimkan melalui email ke{" "}
                <span className="font-weight-bolder">
                  {otp.identity || "surname@email.com"}
                </span>
              </span>
            ) : undefined,
          },
        }}
      >
        <Switch>
          <Route exact path={path}>
            <PageInputEmail />
          </Route>
          <HiddenRoute otp_token={otp_token} exact path={`${path}/validate`}>
            <ValidatePage />
          </HiddenRoute>
          <HiddenRoute otp_token={otp_token} exact path={`${path}/change`}>
            <ForgotChangePass />
          </HiddenRoute>
        </Switch>
      </TempAuth>
    );
  }
}

const mapStateToProps = ({ authReducer }) => ({
  otp_token: authReducer.otp_token,
  otp: authReducer.otp,
});

export default withRouter(connect(mapStateToProps)(ForgotPass));
