import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import illustration from "../../../assets/img/ilustration";
import stateCallApi from "../../../config/API/stateCallApi";
import { gbl_act_lc } from "../../../config/redux/actions/state";
import BasicText from "../../atom/BasicText/BasicText";
import DialogAlert from "../../molecule/Alert/DialogAlert";
import ContainerWithLoader from "../../organism/Container/ContainerWithLoader";
import PortoAssetChart from "../../organism/Porto/PortoAssetChart";
import PortoList from "../../organism/Porto/PortoList";
import PortoSummary from "../../organism/Porto/PortoSummary";
import TempMain from "../../template/TempMain";

const PortoGoalsPage = () => {
  const {
    location: { search },
    replace,
    push,
  } = useHistory();
  const dispatch = useDispatch();
  const [data, setdata] = useState({});
  const [track, settrack] = useState({});
  const idGoal = new URLSearchParams(search).get("goals");

  useEffect(() => {
    const { goal_code, product_variety_code } = data?.goal || {};
    const isHaveIdGoal = idGoal !== null;
    const isDanaTabunganAndSmartplan =
      goal_code === "DT" || product_variety_code === "CG";
    const isHaveData = Object.keys(data).length !== 0;
    if (!isHaveIdGoal) replace("/portofolio");
    if (isHaveIdGoal && !isHaveData) {
      dispatch(
        gbl_act_lc({
          ...stateCallApi.goals_user,
          params: { "find[id]": idGoal },
          redux_action: undefined,
          callback: (data) => setdata(data),
        })
      );
    } else {
      if (!isDanaTabunganAndSmartplan)
        dispatch(
          gbl_act_lc({
            ...stateCallApi.goals_user_track,
            params: { user_goal_id: idGoal },
            redux_action: undefined,
            callback: (data) => settrack(data),
          })
        );
    }
  }, [data, dispatch, idGoal, replace, search]);

  const handleClick = ({ key, items }) => {
    const { user_goal_id, product_variety_code, product } = items;
    const { code, can_switch } = product;
    const { ref_goals_icon, goal_name, target_amount, fr_target_date, goal } =
      data;
    const isSmartplan = product_variety_code === "CG";
    const isSwitchable = can_switch === 1;

    switch (key) {
      case "red":
        push("/redeem", { porto: items });
        break;
      case "sub":
        const payload = {
          products: {
            [code]: {
              amount: 100,
              type: product_variety_code,
            },
          },
        };
        if (isSmartplan) payload["product_variety"] = product_variety_code;
        else payload["user_goal_id"] = user_goal_id;
        push("/subscript?", payload);
        break;
      case "sw":
        if (isSwitchable && !isSmartplan) push("/switch", items);
        break;
      case "switch-porto":
        push(`/switch-porto`, {
          ...items,
          ref_goals_icon,
          goal_name,
          target_amount,
          fr_target_date,
          goal,
        });
        break;
      case "detail":
        push(`detail-produk?product=${code}`);
        break;
      default:
        console.log("Error onClick");
        break;
    }
  };

  const handleRecalculate = () => {
    dispatch(
      gbl_act_lc({
        ...stateCallApi.goals_user_track_recalculate,
        appear: true,
        msg: "Recalculate berhasil dilakukan",
        params: { user_goal_id: idGoal },
      })
    );
  };

  return (
    <TempMain title="PORTOFOLIO" bottomNavValue={3}>
      <div className="pt-md-4 px-md-4 margin-main">
        <ContainerWithLoader
          loading={Object.keys(data).length === 0}
          processName={["goals-user"]}
          loadingHeight="50vh"
        >
          {Object.keys(data).length !== 0 ? (
            <>
              <PortoSummary summary={data} title="0" />
              {data?.portofolio.length === 0 ? (
                <div className="card-custom h-70vh d-flex flex-column justify-content-center align-items-center mt-3">
                  <img
                    src={illustration["emptyporto"]}
                    alt=""
                    className="mb-4"
                  />
                  <BasicText className="fs-14 font-weight-bold color-secondary mb-2">
                    Belum ada Portofolio
                  </BasicText>
                  <BasicText className="fs-14 text-center">
                    Yuk investasi sekarang!
                  </BasicText>
                </div>
              ) : (
                <div className="pt-3 margin-main px-4 px-md-0">
                  <PortoAssetChart
                    onClick={handleRecalculate}
                    summary={data}
                    track={track}
                  />
                  <PortoList onClick={handleClick} data={data} />
                </div>
              )}
            </>
          ) : (
            <BasicText className="fs-14 font-weight-bold color-secondary mb-2">
              Tidak Ada Data
            </BasicText>
          )}
          <DialogAlert
            resultName="goal-track-recalculate"
            alertAppear="both"
            buttonText="OK"
            submitIfSuccess={() => window.location.reload()}
          />
        </ContainerWithLoader>
      </div>
    </TempMain>
  );
};

export default PortoGoalsPage;
