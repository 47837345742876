import { IconButton, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import parse from "html-react-parser";
import React, { useMemo } from "react";
import moreIcon from "../../../assets/icons/moreIcon.svg";
import textHelper from "../../../helper/textHelper";
import BasicText from "../../atom/BasicText/BasicText";
import RiskIndicator from "../../molecule/Product/RiskIndicator";
import RowTotal from "../../molecule/Sell/RowTotal";
import MediaQuery from "../MediaQuery/MediaQuery";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: 8,
    "& .boxLeft": {
      flex: 1,
      marginRight: 17,
      minWidth: "30%",
    },
    "& .boxRight": {
      minWidth: "50%",
      justifyContent: "space-between",
      "& .contentBox": {
        marginRight: 9,
        marginLeft: 9,
      },
      "& .btnMore": {
        marginRight: -21,
        marginTop: -8,
        alignSelf: "flex-start",
      },
    },
  },
}));

const PortoConclusion = ({ marginClass, data, children, borderBottom }) => {
  const classes = useStyles();
  const { push } = useHistory();
  const classes2 = useStyles();
  const {
    fr_value,
    fr_initial_amount,
    fr_unrealized_gain,
    fr_unrealized_gain_percent,
    fr_unit,
    product,
    product_code,
  } = data;

  const summary_txn = useMemo(
    () => [
      {
        title: "Nilai Saat Ini",
        children: fr_value,
        valueClass: "color-primary",
      },
      { title: "Investasi Awal", children: fr_initial_amount },
      { title: "Total Earnings", children: fr_unrealized_gain },
      {
        title: "Return",
        children: fr_unrealized_gain_percent,
        valueClass: "color-primary",
      },
      {
        title: "Risiko Produk",
        children: (
          <RiskIndicator
            value={product?.product_risk || 0}
            classCont={"mt-2"}
            height={12}
          />
        ),
        titleClass: "text-left",
      },
    ],
    [
      fr_initial_amount,
      fr_unrealized_gain,
      fr_unrealized_gain_percent,
      fr_value,
      product,
    ]
  );

  return (
    <React.Fragment>
      <MediaQuery device="desktop" minWidth={950}>
        <div className={classNames(classes.root, "card-custom", marginClass)}>
          <div className="boxLeft">
            <BasicText className="fs-12">
              {Object.keys(data).length > 0 &&
                parse(
                  textHelper.printProduct2Line(
                    `${data.product.display_name} (${data.product.display_code})`
                  )
                )}
            </BasicText>
            <hr className="divider-dotted" style={{ marginTop: 10 }} />
            {children ? (
              children
            ) : (
              <>
                <RowTotal title={"Total Unit"}>{fr_unit}</RowTotal>
                <RowTotal title={"Investasi Awal"}>
                  {fr_initial_amount}
                </RowTotal>
              </>
            )}
          </div>
          <div className="container-row boxRight">
            {summary_txn.map(
              ({ title, children, titleClass, valueClass }, id) => (
                <div className={"contentBox"} key={id}>
                  <BasicText
                    className={classNames(
                      "fs-10 gray-4 mb-2",
                      titleClass || "text-center"
                    )}
                  >
                    {title}
                  </BasicText>
                  {typeof children === "string" ? (
                    <BasicText
                      className={classNames(
                        "fs-14 font-weight-semibold  mb-1 text-center",
                        valueClass || "gray-3"
                      )}
                    >
                      {children}
                    </BasicText>
                  ) : (
                    children
                  )}
                </div>
              )
            )}
            <IconButton
              id={`${product_code}-more`}
              className="btnMore"
              aria-label="more"
              onClick={() => push(`/detail-produk?product=${product_code}`)}
            >
              <img src={moreIcon} alt="" />
            </IconButton>
          </div>
        </div>
      </MediaQuery>
      <MediaQuery device="mobile" minWidth={950}>
        <div
          className={classNames(
            marginClass,
            classes2.root,
            "d-block card-custom mb-2"
          )}
        >
          <div
            className={"d-flex justify-content-between align-items-center mb-3"}
          >
            <BasicText className="fs-12">
              {Object.keys(data).length > 0 &&
                parse(
                  textHelper.printProduct2Line(
                    `${data.product.display_name} (${data.product.display_code})`
                  )
                )}
            </BasicText>
            <div>
              <BasicText className={classNames("fs-10 gray-4 mb-2 text-left")}>
                Risiko Produk
              </BasicText>
              <RiskIndicator
                value={product?.product_risk || 0}
                classCont={"mt-2"}
              />
            </div>
          </div>
          <div>
            {summary_txn.map(
              ({ title, children }, id) =>
                id < 4 && (
                  <RowTotal title={title} key={id}>
                    {children}
                  </RowTotal>
                )
            )}
          </div>
          <hr className="divider-dotted mb-0" />
          <RowTotal title={"Total Unit"}>{fr_unit}</RowTotal>
          <RowTotal title={"Investasi Awal"}>{fr_initial_amount}</RowTotal>
        </div>
      </MediaQuery>
    </React.Fragment>
  );
};

PortoConclusion.defaultProps = {
  data: {},
  borderBottom: true,
};

export default PortoConclusion;
