import React, { useCallback } from "react";
import useStyles from "./useStyles";
import classNames from "classnames";
import parse from "html-react-parser";
import textHelper from "../../../../helper/textHelper";
import ButtonLink2 from "../../../atom/Button/ButtonLink2";
import ButtonContained from "../../../atom/Button/ButtonContained";
import { useMediaQuery } from "react-responsive";
import Grid from "@material-ui/core/Grid";
import { Card } from "@material-ui/core";
import color from "../../../../assets/theme/color";
import { isOfferPeriodRDP } from "../../../../helper/RDPhelper";

const CardBooking = ({ data = {}, onClick }) => {
  const classes = useStyles();
  const isDesktop = useMediaQuery({ minWidth: 769 });
  const {
    id,
    txn_type_code,
    user_goal,
    created_at,
    amount,
    txn_reff,
    product,
  } = data;
  const isOfferPeriode = isOfferPeriodRDP(product.rdp);

  const handleClick = useCallback(
    (action) => () => {
      onClick(action, data);
    },
    [data, onClick]
  );

  console.log("CardBooking", data, id % 3);
  return (
    <>
      <Card className={classNames(classes.root, "shadow-card mb-3")}>
        <div
          className={classNames(
            "d-flex align-items-center pb-2 mb-2",
            classes.borderDot
          )}
        >
          <img
            alt=" "
            src={
              user_goal?.ref_goals_icon?.icon_web_path ||
              user_goal?.ref_goals_icon?.icon_path
            }
            className={classes.icon_goals}
          />
          <p className="basic font-weight-semibold text-uppercase mr-auto">
            {user_goal?.goal.name || "user_goal?.goal_name"}
          </p>
          <div className={classNames(classes.flag, txn_type_code)}>
            {"Pemesanan"}
          </div>
        </div>

        <div className="d-flex">
          <p className="basic fs-10 gray-4 mr-auto">
            {textHelper.printDateTimeShortMonth(created_at)}
          </p>
          <p className="fs-12 basic mr-5 pr-md-4 pr-0">Total</p>
          <p className="basic fs-14 font-weight-bold color-primary">
            {`Rp${textHelper.printMoneyInput(amount)}`}
          </p>
        </div>

        <div className="mt-2">
          <div key={product.display_code}>
            <div className={classNames(classes.borderTitle, "pb-1 pt-2")}>
              <Grid container alignItems="center" spacing={3}>
                <Grid item xs={12} md={3}>
                  <p className="basic fs-12 font-weight-semibold">
                    {parse(
                      textHelper.printProduct2Line(product.fr_product_name)
                    )}
                  </p>
                </Grid>
                <Grid item xs={12} md={3}>
                  <p className="basic fs-12 mr-auto">
                    Nomor Transaksi: {txn_reff}
                  </p>
                </Grid>
                <Grid item xs={12} md={3} className="d-flex align-items-center">
                  {/* {txn_status_code === "APPROVED" && (
                            <>
                            <p className="basic fs-12 gray-4">
                                Konfirmasi Bank Kustodian
                            </p>
                            <img
                                alt=""
                                src={IconTxnGroupSuccess}
                                className="ml-3"
                                onDragStart={(e) => e.preventDefault()}
                            />
                            </>
                        )} */}
                </Grid>
                {isDesktop && (
                  <Grid className="text-right" item xs={12} md={3}>
                    <p className="basic fs-12 gray-4">
                      {/* {'fr_transaction_status'} */}
                      Pesanan Berhasil
                    </p>
                  </Grid>
                )}
              </Grid>
            </div>
            {!isDesktop && (
              <p className="basic fs-12 gray-4 mt-1">
                {/* {fr_transaction_status} */}
                Pesanan Berhasil
              </p>
            )}
          </div>
        </div>

        <div
          style={{ paddingTop: 15 }}
          className={classNames(
            "d-flex align-items-start justify-content-end pb-1"
          )}
        >
          {/* {show_upload_button && ( */}
          <div className="mx-auto">
            {isOfferPeriode && (
              <>
                <ButtonContained
                  id={`confirm-payment-${id}`}
                  style={{
                    width: 130,
                    marginRight: 15,
                    backgroundColor: color.gray_4,
                  }}
                  text="TOP UP"
                  radius="6px"
                  onClick={handleClick("TopUp")}
                />
                <ButtonContained
                  id={`confirm-payment-${id}`}
                  style={{ width: 130 }}
                  text="BELI"
                  radius="6px"
                  onClick={handleClick("Beli")}
                />
              </>
            )}
          </div>

          <ButtonLink2
            id={`detail-${id}`}
            text="DETAIL"
            className="fs-12 text-underline is-link color-secondary"
            onClick={() => onClick(`transaksi/detail?id=${id}`, "booking")}
          />
        </div>
      </Card>
      {isOfferPeriode && (
        <Grid container className="mb-3">
          <Grid item xs={6}>
            <div
              className="d-flex"
              style={{
                backgroundColor: color.gray_4,
                borderRadius: 4,
                padding: 16,
              }}
            >
              <p className="basic fs-14 color-white w-90 m-auto text-center fw-700">
                PENTING!
              </p>
              <p className="basic fs-10 color-white">
                Kamu sudah bisa melakukan pembelian produk Reksa Dana
                Terproteksi. Klik tombol BELI pada halaman Booking untuk proses
                pembelian dan pembayaran produk!
              </p>
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default CardBooking;
