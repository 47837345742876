import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import ButtonContained from "../../atom/Button/ButtonContained";
import color from "../../../assets/theme/color";
import pageToURL from "../../../helper/pageToUrlHelper";
import classNames from "classnames";

const base = {
  beli: {
    text: "BELI",
    backgroundColor: color.primary,
    onClick: "/subscript",
  },
  benchmark: {
    text: "BENCHMARK",
    backgroundColor: color.gray_6,
    onClick: pageToURL.coming_soon,
  },
  simulasi: {
    text: "SIMULASI",
    backgroundColor: color.secondary,
    onClick: "/simulasi-produk",
  },
  kembali: {
    text: "KEMBALI",
    backgroundColor: color.gray_6,
    onClick: "back",
  },
};

const useStyles = makeStyles({
  button: {
    padding: "11px 0",
    fontSize: 14,
    width: "100%",
    borderRadius: 6,
  },
});

const ThreeButtonDetail = (props) => {
  const { temp, onClick, className, is_suspend,code } = props;
  const S = useStyles();
  const handleClick = (e) => {
    onClick(e);
  };
  return (
    <Grid
      container
      spacing={2}
      className={classNames(
        "justify-content-between justify-content-md-around",
        className
      )}
    >
      {temp.map((item, index) => {
        let _props = {};
        if (Object.keys(base).includes(item))
          _props = {
            ...base[item],
            onClick: () => handleClick(base[item].onClick),
          };
        if (item === "beli" && is_suspend)
          _props.backgroundColor = color.gray_6;
        else if (typeof item === "object") {
          const { like, onClick, ...other } = item;
          _props = {
            ...base[like],
            ...other,
            onClick: () => handleClick(onClick || base[like].onClick),
          };
        }
        return (
          index < 3 && (
            <Grid item xs={4} key={index}>
              <ButtonContained id={`${code && `${code}-`}${_props.text}`} className={S.button} radius="5px" {..._props} />
            </Grid>
          )
        );
      })}
    </Grid>
  );
};

ThreeButtonDetail.defaultProps = {
  temp: ["beli", "benchmark", "simulasi"],
  code : ""
};

export default ThreeButtonDetail;
