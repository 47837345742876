import * as types from "../actions/types";

const initial = {
  list: { data: [] },
  user: { data: [] },
  planner: { data: [] },
  base_robo_alc: [],
  porto_summary: {},
};

const goalReducer = (state = initial, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GOALS_RD:
      return { ...state, ...payload };
    case types.LOG_OUT:
      return initial;
    default:
      return state;
  }
};

export default goalReducer;
