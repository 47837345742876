import React from "react";
import icon from "../../../assets/img/icon/stepper";
import { makeStyles } from "@material-ui/core";
import BasicText from "../../atom/BasicText/BasicText";
import classNames from "classnames";
const useStyles = makeStyles({
  containerStep: {
    display: "flex",
    position: "relative",
    width: "641.6px",
    marginBottom: "20.9px"
  },
  stepActive: {
    position: "absolute",
    display: "flex",
    width: "641.6px",
    top: -3
  },
  textContainer: {
    width: 313
  }
});

const HorizontalStepper = props => {
  const { step, text, subText } = props;
  const classes = useStyles();
  return (
    <div className="py-3">
      <div className={classes.containerStep}>
        <div className={classes.stepActive}>
          <img
            src={icon.circleActiveh}
            alt=""
            className={step >= 0 ? "d-block" : "d-none"}
          />
          <img
            src={icon.lineActiveh}
            alt=""
            className={step >= 1 ? "d-block" : "d-none"}
          />
          <img
            src={icon.circleActiveh}
            alt=""
            className={step >= 1 ? "d-block" : "d-none"}
          />
          <img
            src={icon.lineActiveh}
            alt=""
            className={step >= 2 ? "d-block" : "d-none"}
          />
          <img
            src={icon.circleActiveh}
            alt=""
            className={step >= 2 ? "d-block" : "d-none"}
          />
        </div>
        <img src={icon.circleBasedh} alt="" />
        <img src={icon.lineBasedh} alt="" />
        <img src={icon.circleBasedh} alt="" />
      </div>
      <div className="d-flex">
        <div className={classes.textContainer}>
          <BasicText
            className={classNames(
              "fs-14 mb-1",
              step === 0 && "font-weight-bold"
            )}
            color="secondary"
          >
            {text[0]}
          </BasicText>
          {step >= 0 && (
            <BasicText
              className="fs-12 ellipsis2row"
              color="secondary"
              colorLevel="light"
            >
              {subText[0]}
            </BasicText>
          )}
        </div>
        <div className={classes.textContainer}>
          <BasicText
            className={classNames(
              "fs-14 mb-1",
              step === 1 && "font-weight-bold"
            )}
            color="secondary"
          >
            {text[1]}
          </BasicText>
          {step >= 1 && (
            <BasicText
              className="fs-12 ellipsis2row"
              color="secondary"
              colorLevel="light"
            >
              {subText[1]}
            </BasicText>
          )}
        </div>
        <div>
          <BasicText
            className={classNames(
              "fs-14 mb-1",
              step === 2 && "font-weight-bold"
            )}
            color="secondary"
          >
            {text[2]}
          </BasicText>
          {step >= 2 && (
            <BasicText
              className="fs-12 ellipsis2row"
              color="secondary"
              colorLevel="light"
            >
              {subText[2]}
            </BasicText>
          )}
        </div>
      </div>
    </div>
  );
};

export default HorizontalStepper;
