import React from "react";
import chartHelper from "../../../helper/chartHelper";
import { isEqual } from "lodash";

const ProductRecommendChart = (props) => {
  const {
    chartColor,
    profileRiskProduct,
    containerClass,
    update,
    idChart,
  } = props;
  const [state, setState] = React.useState({
    once_state: true,
    data: [],
  });
  const { once_state, data } = state;
  React.useEffect(() => {
    // if (
    //   profileRiskProduct &&
    //   profileRiskProduct.length > 0 &&
    //   once_state &&
    //   chartColor.length
    // ) {
    //   profileRiskProduct.forEach((e) => {
    //     setState((state) => ({
    //       ...state,
    //       data: [...state.data, { name: e.product.name, y: e.bobot }],
    //     }));
    //   });
    // }
    // console.log(update, once_state, state.data, profileRiskProduct);
    // if (data.length > 0 && chartColor.length > 0 && once_state) {
    //   chartHelper.createPiChart(chartColor, data, "profileRiskChart");
    //   setState((state) => ({ ...state, once_state: false }));
    // }
    let _data = [];
    if (profileRiskProduct.length) {
      profileRiskProduct.forEach((e) => {
        _data.push({ name: e.product.name, y: Number(e.allocation) });
      });
    }
    const create = _data.length && chartColor.length && once_state;
    const _update =
      update && !isEqual(_data, data) && !once_state && chartColor;
    if (create || _update) {
      chartHelper.createPiChart(chartColor, _data, idChart);
      setState({ data: _data, once_state: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileRiskProduct, once_state, data, chartColor]);
  return <div id={idChart} className={containerClass}></div>;
};

ProductRecommendChart.defaultProps = {
  idChart: "profileRiskChart",
  profileRiskProduct: [],
};

export default ProductRecommendChart;
