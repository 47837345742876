import React from "react";
import { useMediaQuery } from "react-responsive";
import classNames from "classnames";
import makeStyles from "@material-ui/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import BasicText from "../../atom/BasicText/BasicText";

const useStyles = makeStyles({
  containContent: {
    borderRadius: 8,
    marginBottom: 7,
    padding: "12px 14px 18px 17px",
  },
  mb1: {
    marginBottom: ".25rem !important",
  },
});

const TitleContent = ({ children, className }) => (
  <BasicText className={classNames("fs-14 font-weight-bold mb-3", className)}>
    {children}
  </BasicText>
);

const ContainerContentLevel1 = ({
  children,
  withDivider,
  className,
  titleClass,
  title,
  titleOnTopCardMobile = false,
  md = 8,
  containClass,
}) => {
  const S = useStyles();
  const isDesktop = useMediaQuery({ minWidth: 769 });
  const isDividerVisible = isDesktop ? withDivider : false;
  const Container = isDesktop ? Grid : Card;
  const isTitleOnTop = !isDesktop ? titleOnTopCardMobile : false;
  return (
    <Grid className={containClass}>
      {isTitleOnTop && (
        <TitleContent className={classNames("ml-3", S.mb1, titleClass)}>
          {title}
        </TitleContent>
      )}
      <Container className={!isDesktop ? S.containContent : ""}>
        <Grid xs={12} md={md} item className={classNames("px-sm-4", className)}>
          {!isTitleOnTop && (
            <TitleContent className={titleClass}>{title}</TitleContent>
          )}
          {children}
        </Grid>
        {isDividerVisible && <Divider style={{ margin: "37px 0" }} />}
      </Container>
    </Grid>
  );
};

export default ContainerContentLevel1;
