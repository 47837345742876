import React, { PureComponent } from "react";
import { simulateInvest } from "../../../helper/calculateSimulasi";
import BasicText from "../../atom/BasicText/BasicText";
import ButtonContained from "../../atom/Button/ButtonContained";
import ChartSimulasi from "../../molecule/Chart/ChartSimulasi";
import ContainerContentLevel1 from "../../molecule/Container/ContainerContentLevel1";
import ContainerLevel1 from "../../molecule/Container/ContainerLevel1";
import DialogSimulasi from "../../molecule/Dialog/DialogSimulasi";
import GroupSliderInput from "../../molecule/SmartPlan/GroupSliderInput";
import RowResultInput from "../../molecule/SmartPlan/RowResultInput";

class SimulasiSmartPlan extends PureComponent {
  state = {
    firstInvest: 2500000, // Investasi Awal
    monthlyInvest: 100000, // Investasi Bulanan
    timeInvest: 1, // Jangka Waktu Investasi
    timeInvestType: "year", // Tipe Jangka Waktu Invest
    predictInvestValue: 0, // Perkiraan nilai investasi akhir
    normalInvestValue: 0,
    maxYear: 5,
    chartData: {
      categories: [],
      data: {
        modal: [],
        invest: [],
      },
    },
  };
  componentDidUpdate = (prevProps) => {
    const { history } = this.props;
    if (history.length && history !== prevProps.history) {
      this._handleTypeTime();
    }
  };

  _handleTypeTime = (timeInvestType = "year") => {
    const { multiple, history } = this.props;
    let { timeInvest } = this.state;
    let maxYear;
    if (history) {
      if (multiple) {
        let minLogs = history[0].length;
        if (minLogs > history[1].length) {
          minLogs = history[1].length;
        }
        maxYear = minLogs - 1;
      } else {
        maxYear = history[0].length - 1;
      }
    }
    if (timeInvestType === "year") maxYear = Math.floor(maxYear / 12);
    if (timeInvest > maxYear) timeInvest = maxYear;
    this.setState({ timeInvestType, maxYear }, () =>
      this._handleState(timeInvest, "timeInvest")
    );
  };

  _handleChangeState = (value, keyState) => {
    this.setState({ [keyState]: parseFloat(value) }, () =>
      this._handleState(value, keyState)
    );
  };

  _handleState = (value, keyState) => {
    const {
      timeInvest,
      firstInvest,
      monthlyInvest,
      timeInvestType,
    } = this.state;
    const { history } = this.props;
    let data = {
      log: history,
      durasiInvest: timeInvest,
      firstInvest,
      monthlyInvest,
      multiple: null,
      timeInvestType,
    };
    let res = simulateInvest({
      ...data,
      [keyState === "timeInvest" ? "durasiInvest" : keyState]: parseFloat(
        value
      ),
    });
    let _res = {
      [keyState]: !value ? value : parseFloat(value),
    };
    if (res.investResult) {
      _res.predictInvestValue = res.investResult.totalInvestReturn;
      _res.normalInvestValue = res.investResult.totalInvest;
      _res.chartData = res.chartData;
    }
    this.setState(_res);
  };

  _handleInvest = () => {
    const { firstInvest, monthlyInvest } = this.state;
    this.props.handleInvest(
      parseFloat(firstInvest) + parseFloat(monthlyInvest)
    );
  };

  ins_benefit_calc = (tiering = [], amount) => {
    let benefit = 0;
    tiering.forEach((val) => {
      if (amount >= val.min_sub) {
        benefit = val.benefit_amount;
        return benefit;
      }
    });
    return benefit;
  };

  render() {
    const { suport } = this.props;
    const {
      timeInvest,
      timeInvestType,
      firstInvest,
      monthlyInvest,
      maxYear,
      chartData,
      predictInvestValue,
    } = this.state;
    const sliders = [
      {
        title: "Jangka Waktu",
        value: timeInvest,
        max: timeInvestType === "month" ? maxYear - 5 : maxYear,
        min: 1,
        keyState: "timeInvest",
        typeTimeChange: this._handleTypeTime,
        textInputClass: "pr-4",
      },
      {
        title: "Investasi Awal",
        value: firstInvest,
        max: 10000000,
        step: 10000,
        keyState: "firstInvest",
      },
      {
        title: "Investasi Bulanan",
        step: 10000,
        value: monthlyInvest,
        keyState: "monthlyInvest",
      },
    ];
    const dataChart = [
      { name: "Modal", data: chartData.data.modal },
      { name: "Hasil Investasi", data: chartData.data.invest },
    ];
    return (
      <ContainerLevel1 DesktopClass="pt-5 pb-4">
        <ContainerContentLevel1
          withDivider
          title="Simulasi SMART Plan"
          titleClass="mb-4"
          titleOnTopCardMobile
        >
          <GroupSliderInput data={sliders} onChange={this._handleState} />
        </ContainerContentLevel1>
        <ContainerContentLevel1
          withDivider
          title="Hasil Simulasi"
        >
          <RowResultInput
            title="Nilai Investasi Kamu"
            value={predictInvestValue}
            inputClass="font-weight-semibold color-white"
            textInputClass="textInputResultGoalKamu"
          />
          <BasicText
            style={{ marginTop: 43 }}
            className={"fs-14 font-weight-bold mb-3"}
          >
            Benefit SmartPlan
          </BasicText>
          {suport.map((_, index) => (
            <RowResultInput
              key={index}
              index={index + 1}
              title={_.benefit_name}
              className="mb-4"
              isNumber={false}
              value={this.ins_benefit_calc(
                _.ins_benefit_tiering,
                predictInvestValue
              )}
            />
          ))}
        </ContainerContentLevel1>
        <ContainerContentLevel1
          title="Kalkulasi SMART Plan"
          md={11}
          className="mb-3"
        >
          <ChartSimulasi categories={chartData.categories} data={dataChart} />
          <DialogSimulasi>
            <DialogSimulasi.Disclimer />
          </DialogSimulasi>
        </ContainerContentLevel1>
        <ButtonContained
          text="INVESTASI SEKARANG"
          className="mt-5 mb-3 align-self-center default-py-button px-4"
          radius="6px"
          onClick={this._handleInvest}
        />
      </ContainerLevel1>
    );
  }
}

SimulasiSmartPlan.defaultProps = {
  changeTypeTime: () => { },
  suport: [],
};

export default SimulasiSmartPlan;
