import React, { forwardRef, useImperativeHandle } from "react";
import BasicText from "../../atom/BasicText/BasicText";
import DialogCustom from "../../organism/Container/DialogCustom";
import { useState } from "react";

export const TypesContent = () => {
  return (
    <div>
      <BasicText className="fs-10 gray-3 font-weight-bold mb-2">
        Reksa Dana Saham
      </BasicText>
      <BasicText className="fs-10 gray-3 mb-3">
        Memberikan potensi pertumbuhan nilai investasi jangka panjang yang
        menarik melalui investasi dalam Efek bersifat Ekuitas yang
        diperdagangkan di Bursa Efek Indonesia.
      </BasicText>
      <BasicText className="fs-10 gray-3 font-weight-bold mb-2">
        Reksa Dana Campuran
      </BasicText>
      <BasicText className="fs-10 gray-3 mb-3">
        Memberikan potensi pertumbuhan nilai investasi dalam jangka menengah dan
        panjang melalui alokasi aktif pada beragam jenis instrumen investasi
        mengikuti tren pada pasar saham.
      </BasicText>
      <BasicText className="fs-10 gray-3 font-weight-bold mb-2">
        Reksa Dana Index
      </BasicText>
      <BasicText className="fs-10 gray-3 mb-3">
        Memberikan potensi tingkat pertumbuhan investasi jangka panjang yang
        optimum pada Efek bersifat ekuitas yang diperdagangkan di Bursa Efek
        Indonesia yang mengikuti kinerja indeks yang telah ditentukan.
      </BasicText>
      <BasicText className="fs-10 gray-3 font-weight-bold mb-2">
        Reksa Dana Pendapatan Tetap
      </BasicText>
      <BasicText className="fs-10 gray-3 mb-3">
        Bertujuan untuk memperoleh pertumbuhan investasi dalam jangka waktu
        menengah melalui alokasi strategis pada obligasi yang diterbitkan oleh
        Pemerintah Republik Indonesia dan korporasi Indonesia.
      </BasicText>
      <BasicText className="fs-10 gray-3 font-weight-bold mb-2">
        Reksa Dana Pasar Uang
      </BasicText>
      <BasicText className="fs-10 gray-3 mb-3">
        Bertujuan untuk mendapatkan imbal hasil dengan tingkat likuiditas yang
        tinggi untuk memenuhi kebutuhan finansial dalam waktu yang singkat.
      </BasicText>
    </div>
  );
};

export const AllocContent = () => {
  return (
    <div>
      <BasicText className="mb-3 fs-10 gray-3">
        Sistem kami secara otomatis akan membagi investasi kamu ke dalam dua
        atau lebih jenis reksa dana yang berbeda tergantung dari hasil analisa
        profil risiko kamu
      </BasicText>
      <BasicText className="mb-3 fs-10 gray-3">
        Jika rekomendasi alokasi kami untuk kamu adalah 70% saham dan 30% pasar
        uang maka ketika kamu berinvestasi sebesar Rp10.000.000, investasi kamu
        akan dibagi menjadi:
      </BasicText>
      <BasicText className="mb-3 fs-10 gray-3">
        Saham Rp7.000.000 Pasar Uang Rp3.000.000
      </BasicText>
    </div>
  );
};

export const ModalType = forwardRef((props, ref) => {
  const [state, setstate] = useState({
    open: false,
    info: "",
  });
  useImperativeHandle(ref, () => ({
    handleOpen,
    handleClose,
  }));
  function handleOpen(info) {
    setstate({ open: true, info });
  }
  function handleClose(open) {
    setstate({ ...state, open });
  }
  return (
    <DialogCustom
      open={state.open}
      showCloseButton={true}
      handleClose={handleClose}
      backgroundColor="#fff"
      textColorClass="gray-3"
      title={state.info === "types" ? "JENIS REKSADANA" : "ALOKASI"}
      content={state.info === "types" ? <TypesContent /> : <AllocContent />}
    />
  );
});
