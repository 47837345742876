import * as types from "../actions/types";

const initial = {
  country_list: [],
  education_list: [],
  gender_list: [],
  income_list: [],
  income_source_list: [],
  investment_objective_list: [],
  marriage_list: [],
  job_list: [],
  job_role_list: [],
  job_category_list: [],
  religion_list: [],
  waris_list: [],
  nationality_list: [],
  bank_list: [],
  city_list: [],
  province_list: [],
  menu: [],
  help_center: [],
  faq: [],
  kecamatan_list: {},
  kelurahan_list: {},
  knowledge_center: [],
  glossary: [],
  config: {},
  chart_color: [],
  goals: [],
  headerMode: "normal",
  headerSearch: "",
  syarat_ketentuan: "",
  syarat_sub: "",
  syarat_subCG: "",
  syarat_sub1: "",
  syarat_sub2: "",
  syarat_sub3: "",
  syarat_sub4: "",
  syarat_sw: "",
  syarat_red: "",
  kebijakan_privasi: "",
  jenis_reksadana: "",
  alokasi: "",
  about_app: "",
  rate: {},
  ins_benefit: [],
  dashboard: {},
  ins_info: "",
  voucher_merchant: [],
};

const optionReducer = (state = initial, action) => {
  const { payload, type } = action;
  switch (type) {
    case types.GET_DATA_OPTION:
      return { ...state, ...payload };
    case types.SET_MENU:
      return { ...state, menu: payload };
    case types.CHART_COLOR:
      return { ...state, chart_color: payload };
    case types.HEADER_SEARCH:
      const { key, value } = payload;
      return { ...state, [key]: value };
    case types.SET_DATA_OPTION_CHILD:
      return {
        ...state,
        [payload.key]: { ...state[payload.key], ...payload.data },
      };
    default:
      return state;
  }
};

export const selectorGetVoucherMerchant = (state) => state.optionReducer.voucher_merchant

export default optionReducer;
