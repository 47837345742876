import React from "react";
import BasicText from "../../../../atom/BasicText/BasicText";

const CardInsight = ({ item, className, onClick }) => {
  return (
    <div className={className} onClick={onClick}>
      <img
        src={item.image}
        alt="syailendra-insight"
        onDragStart={(e) => e.preventDefault()}
      />
      <BasicText className="summary">
        {item.fr_summary.replace("&nbsp;", "")}
      </BasicText>
    </div>
  );
};

export default CardInsight;
