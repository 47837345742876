import React from "react";
import Grid from "@material-ui/core/Grid";
import textHelper from "../../../../helper/textHelper";
import RiskIndicator from "../RiskIndicator";
import { CardProductContext } from "./CardProductCustom";
const { useContext, lazy, Suspense } = React;
const PopOver = lazy(() => import("../../../atom/PopOver/PopOver"));

const ItemSummary = ({ title, value, explanation }) => (
  <Grid item xs={4}>
    <div className="d-flex">
      <p className="basic fs-10 mb-2 gray-4">{title}</p>
      <Suspense fallback="">
        {explanation && (
          <PopOver title={title} className="ml-1" size={12}>
            {explanation}
          </PopOver>
        )}
      </Suspense>
    </div>
    {typeof value === "string" ? (
      <p className="basic fs-14 font-weight-semibold">{value}</p>
    ) : (
      value
    )}
  </Grid>
);

const PointSummaryCardProduct = () => {
  const {
    perform_1_y,
    min_sub,
    product_risk,
    fr_perform_1_y,
    min_sub_formatted,
    is_rdp,
    product_rdp,
  } = useContext(CardProductContext);
  const { printPercent, printMoney } = textHelper;
  return (
    <Grid container spacing={3}>
      {is_rdp ? (
        <ItemSummary title="Return" value={product_rdp?.return ?? "0%"} />
      ) : (
        <ItemSummary
          title="Kinerja 1 Tahun"
          explanation="Kinerja 1 Tahun tidak mencerminkan return dimasa depan"
          value={fr_perform_1_y || printPercent(perform_1_y)}
        />
      )}
      <ItemSummary
        title="Min. Pembelian"
        value={min_sub_formatted || printMoney(min_sub)}
      />
      <ItemSummary
        title="Risiko Produk"
        value={<RiskIndicator value={product_risk} height={12} />}
      />
    </Grid>
  );
};

export default PointSummaryCardProduct;
