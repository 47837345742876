import HelpIcon from "@material-ui/icons/Help";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import ilustration from "../../../assets/img/ilustration";
import DialogCustom from "../../organism/Container/DialogCustom";
import ContentType from "../../organism/Content/ContentType";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "75% 1px 25%",
  },
  contentBox: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: 700,
    margin: "0 auto",
    "& .childrenWidth": {
      width: 383,
    },
  },
  logoutModal: {
    width: 375,
  },
}));
const TempProfileEditDesktop = (props) => {
  const classes = useStyles();
  const { children, data } = props;
  const { /*menuValue*/ img } = data;
  const [modal, setModal] = React.useState(false);
  const [display, setDisplay] = React.useState(false);
  const handleClose = () => {
    document.getElementsByTagName("body")[0].style.overflowY = "visible";
    setDisplay(false);
  };
  // const handleOpen = () => {
  //   document.getElementsByTagName("body")[0].style.overflowY = "hidden";
  //   window.scrollTo(0, 0);
  //   setDisplay(true);
  // };
  const handleModal = (e) => {
    setModal(e);
  };
  // const openModal = (e) => {
  //   handleModal(true);
  // };
  const handleModalClick = () => {
    props.log_out();
  };
  return (
    <div className="pt-4 px-4 margin-main">
      <div className={classes.root}>
        <div className="p-4 pb-5">
          <div className={classes.contentBox}>
            <div className={img !== "" ? "childrenWidth" : ""}>{children}</div>
            {img !== "" && (
              <div className="text-center">
                <img src={ilustration[img]} alt="" />
              </div>
            )}
          </div>
        </div>

        {/* <div className="d-flex justify-content-center">
          <Divider orientation="vertical" />
        </div>

        <div className="pt-3">
          <MenuProfile
            marginClass="mb-4"
            menuValue={menuValue}
            glossary={handleOpen}
            logoutModal={openModal}
          />
        </div> */}
      </div>
      <ContentType
        display={display}
        contentKey="glossary"
        onClose={handleClose}
      />
      <DialogCustom
        open={modal}
        handleClose={handleModal}
        title={<HelpIcon className="color-primary fs-40" />}
        titleClass="text-center mb-3"
        text="Apakah Anda akan keluar?"
        buttonText="OK"
        onClick={handleModalClick}
        onCancel={() => handleModal(false)}
        className={classes.logoutModal}
        actionClass="p-0"
        textClass="fs-14 text-center mb-3"
        buttonClass="py-3 br-unset"
      />
    </div>
  );
};

export default TempProfileEditDesktop;
