import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import DialogCustom from "../../organism/Container/DialogCustom";

const DialogUpdateMotherName = () => {
  const { push } = useHistory();
  const updateMotherNameStatus = useSelector(
    (state) => state.profileReducer.updateMotherNameStatus
  );

  return (
    <DialogCustom
      maxWidth="xs"
      title="Update Nama Ibu Kandung"
      text="Mohon maaf atas ketidaknyamanannya, kami memerlukan pembaruan nama ibu Anda. Terima kasih atas kerjasama Anda."
      showCloseButton={false}
      buttonText="Perbaharui"
      onClick={() => push("/profil/update/mother-name")}
      open={updateMotherNameStatus}
    />
  );
};

export default DialogUpdateMotherName;
