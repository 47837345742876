import React from "react";
import { connect } from "react-redux";
import { risk_result_props } from "../../config/redux/actions/profile";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import processHelper from "../../helper/processHelper";
import { remove_result_l } from "../../config/redux/actions/state";
import ContentResult from "../organism/ProfileRiskPage/ContentResult";
import TempAuth from "../template/TempAuth";
import { chart_color_props } from "../../config/redux/actions/option";
// import TempMain from "../template/TempMain";
// import CardRiskScore from "../molecule/Profile/CardRiskScore";
// import CardRiskIndicator from "../molecule/Profile/CardRiskIndicator";
// import ContainerWithLoader from "../organism/Container/ContainerWithLoader";
// import CardRiskProduct from "../molecule/Profile/CardRiskProduct";
// import ButtonContained from "../atom/Button/ButtonContained";
// import DialogAlert from "../molecule/Alert/DialogAlert";

const RiskResultPage = (props) => {
  const {
    profile_risk,
    loading_stack,
    result_stack,
    remove_result_l,
    chart_color_props,
  } = props;
  const [state, setState] = React.useState({
    scores: profile_risk.profile_risk_point,
    change: false,
    didMount: false,
  });
  const { change } = state;
  React.useEffect(() => {
    const _state = props.location.state;
    if (!state.didMount) {
      chart_color_props();
      window.scrollTo(0, 0);
    }
    if (_state) {
      setState((state) => ({ ...state, change: true }));
    }
    if (Object.keys(profile_risk).length === 0) {
      props.history.goBack();
    }
    let data = processHelper.findResult("save_risk_result", result_stack);
    console.log(data);
    if (Object.keys(data).length > 0) {
      if (data.type === "success") {
        remove_result_l("save_risk_result");
        props.history.goBack();
      }
    }
  }, [
    profile_risk,
    props.history,
    result_stack,
    remove_result_l,
    props.location,
    chart_color_props,
    state.didMount,
  ]);
  const handleClick = (e, risk) => {
    const { location, history, isLogin, risk_result_props } = props;
    const fromRegister = location.state?.fromRegister ?? false;
    console.log(e, risk);
    if (e === "save") {
      if (!fromRegister && isLogin === 1) {
        const { profile_risk_point } = risk;
        risk_result_props({
          point: profile_risk_point,
          mode: "save_risk_result",
        });
      } else history.goBack();
      //   history.goBack();
      // } else {
      //   console.log(fromRegister);
      // risk_result_props({
      //   point: scores,
      //   mode: "save_risk_result",
      // });
      // }
    } else if (e === "survey") {
      history.replace("/survey-risiko", { fromRegister });
    }
  };
  // const handleChange = (e) => {
  //   if (!change) {
  //     setState((state) => ({ ...state, change: true }));
  //   }
  //   setState((state) => ({ ...state, scores: e }));
  // };

  return (
    <TempAuth page="regisForm" data={{ title: "PROFIL RISIKO" }}>
      <ContentResult
        className="px-3 pt-4 bg-white"
        onSubmit={(risk) => handleClick("save", risk)}
        onSurvey={() => handleClick("survey")}
        propsButton={{
          loading: processHelper.findProcess("save_risk_result", loading_stack),
          text: "LANJUT",
          disabled: !change,
        }}
      />
    </TempAuth>
  );

  // return (
  //   <TempMain title="PROFIL RISIKOMU" showBottom={false}>
  //     <ContainerWithLoader processName="profile_risk" loadingHeight="80vh">
  //       {Object.keys(profile_risk).length && (
  //         <div className="px-3 pt-4 margin-main">
  //           <CardRiskScore score={scores} onChange={handleChange} />
  //           <CardRiskIndicator
  //             profileRiskType={profile_risk.profile_risk_type}
  //           />
  //           <CardRiskProduct
  //             profileRiskProduct={profile_risk.profile_risk_product}
  //             chartColor={chart_color}
  //           />
  //           <div className="text-center">
  //             <ButtonContained
  //               text="SIMPAN"
  //               className="px-5 py-3 mb-3"
  //               radius="5px"
  //               onClick={() => handleClick("save")}
  //               loading={processHelper.findProcess(
  //                 "save_risk_result",
  //                 loading_stack
  //               )}
  //               disabled={!change}
  //             />
  //             <p
  //               className="mb-0 text-underline color-secondary fs-12"
  //               onClick={() => handleClick("survey")}
  //             >
  //               ULANGI PERTANYAAN
  //             </p>
  //           </div>
  //         </div>
  //       )}
  //       <DialogAlert resultName="save_risk_result" buttonText="OK" />
  //     </ContainerWithLoader>
  //   </TempMain>
  // );
};

const mapState = (state) => ({
  profile_risk: state.profileReducer.profile_risk,
  loading_stack: state.stateReducer.loading_stack,
  result_stack: state.stateReducer.result_stack,
  chart_color: state.optionReducer.chart_color,
  isLogin: state.authReducer.isLogin,
});

export default compose(
  connect(mapState, { risk_result_props, remove_result_l, chart_color_props }),
  withRouter
)(RiskResultPage);
