import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import classNames from "classnames";
import { isEqual } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { object } from "yup";
import { set_app_config } from "../../../config/redux/actions/option";
import { fetch_update_profile } from "../../../config/redux/actions/profile";
import { set_result } from "../../../config/redux/actions/state";
import processHelper from "../../../helper/processHelper";
import textHelper from "../../../helper/textHelper";
import validation from "../../../helper/validateHelper";
import BasicText from "../../atom/BasicText/BasicText";
import ButtonContained from "../../atom/Button/ButtonContained";
import DialogAlert from "../../molecule/Alert/DialogAlert";
import FormRegister from "../Register/FormRegister";
import { _getNameIndex } from "../Register/registerHelper";

const canEditable = [
  "province_id",
  "religion_id",
  "marriage_id",
  "job_id",
  "education_id",
  "income_id",
  "source_income_id",
  "mail_address",
  "mail_city_id",
  "mail_postal_code",
  "job_role_id",
  "job_category_id",
];

function _require(key) {
  return validation.require(null, key);
}

function _isNull(value) {
  return value || "";
}

const validationSchema = object().shape({
  religion_id: _require("Agama"),
  marriage_id: _require("Status Perkawinan"),
  job_id: _require("Pekerjaan"),
  education_id: _require("Pendidikan"),
  income_id: _require("Pendapatan"),
  source_income_id: _require("Sumber Pendapatan"),
  mail_address: _require("Alamat Korespondensi"),
  province_id: _require("Provinsi Korespondensi"),
  mail_city_id: _require("Kota Korespondensi"),
  mail_postal_code: _require("Kode POS Korespondensi"),
  job_role_id: _require("Jabatan"),
  job_category_id: _require("Bidang Pekerjaan"),
});

// const useStyles = makeStyles((theme) => ({
//   oneColumn: {
//     [theme.breakpoints.up(768)]: {
//       display: "grid",
//       gridTemplateColumns: "0.5fr",
//     },
//   },
//   fullRow: {
//     [theme.breakpoints.up(768)]: {
//       width: "100%",
//     },
//   },
//   twoColumn: {
//     [theme.breakpoints.up(768)]: {
//       display: "grid",
//       gridTemplateColumns: "0.5fr 0.5fr",
//       gridColumnGap: "5%",
//     },
//   },
//   twoButton: {
//     display: "flex",
//     [theme.breakpoints.down(769)]: {
//       display: "none",
//     },
//   },
// }));

const CheckBoxKorespondensi = ({ onChange, value, disabled }) => {
  function _handleCheck(e) {
    const value = e.target.checked || false;
    onChange(value);
  }
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={value}
          onChange={_handleCheck}
          size="small"
          className="p-0 mx-2"
          disabled={disabled}
          color="primary"
        />
      }
      label="Sama dengan KTP"
      className="mb-0"
      classes={{ label: "fs-12 gray-3 font-family" }}
      labelPlacement="start"
    />
  );
};

const Title = ({ text, className }) => {
  return (
    <BasicText
      className={classNames("fs-14 font-weight-bold", className)}
      color="primary"
    >
      {text}
    </BasicText>
  );
};

const ButtonAction = ({
  editable,
  triggerSubmit,
  handleEditable,
  handleClose,
  loading,
}) => {
  return (
    <div className={"mt-4 pt-3"}>
      <ButtonContained
        id="button-ubah"
        text={editable ? "SIMPAN" : "UBAH"}
        className="fs-14 font-weight-medium px-5 py-2 mr-3"
        radius="5px"
        name="change"
        onClick={editable ? triggerSubmit : handleEditable}
        loading={loading}
      />
      <ButtonContained
        id="button-cancel"
        text="BATAL"
        className="fs-14 font-weight-medium px-5 py-2"
        radius="5px"
        backgroundColor="#b2b2b2"
        name="cancel"
        onClick={editable ? handleEditable : handleClose}
        disabled={loading}
      />
    </div>
  );
};

class IdentityProfile extends React.PureComponent {
  state = {
    initial: {},
    disabled: [],
    editable: false,
    kecamatan: [],
    desa: [],
    isKorespondensiSame: false,
    city_korespondensi: [],
    fieldRekening: [],
  };
  componentDidMount = () => {
    // this.props.set_app_config({ params: { with_option: 1 } });
    this.initialForm();
  };

  componentDidUpdate = (prevProps) => {
    const { profile } = this.props;
    if (profile !== prevProps.profile) {
      this.initialForm();
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { result_stack } = this.props;
    if (result_stack !== prevProps.result_stack) {
      const res = processHelper.findResult("update-profile", result_stack);
      if (res.type === "success") {
        this.initialForm();
        this._handleEditable();
      }
    }
  };

  initialForm = () => {
    const { profile } = this.props;
    const Regional = { kecamatan: "card_kecamatan", desa: "card_kelurahan" };
    const fieldRekening = [];
    if (
      !Object.keys(this.state.initial).length &&
      Object.keys(profile).length
    ) {
      const {
        card_number,
        birth_place,
        birth_date,
        card_address,
        // card_province_id,
        // card_city_id,
        // card_kecamatan_id,
        // card_kelurahan_id,
        card_rtrw,
        card_post_code,
        religion_id,
        marriage_id,
        citizenship,
        job_id,
        job_category_id,
        job_role_id,
        education_id,
        income_id,
        income_source_id,
        investment_objective_id,
        user_account,
        address,
        post_code,
        province_id,
        city_id,
        card_province,
        card_city,
        card_kecamatan,
        card_kelurahan,
      } = profile;
      const initial = {
        card_number,
        name: textHelper.printFullname(profile),
        ttl: `${birth_place}/${birth_date}`,
        card_address,
        card_province_id: _isNull(card_province?.name),
        card_city_id: _isNull(card_city?.name),
        card_kecamatan_id: _isNull(card_kecamatan?.name),
        card_kelurahan_id: _isNull(card_kelurahan?.name),
        card_rtrw: _isNull(card_rtrw),
        card_post_code: _isNull(card_post_code),
        religion_id: _isNull(religion_id),
        marriage_id: _isNull(marriage_id),
        citizenship: _isNull(citizenship),
        job_id: _isNull(job_id),
        job_category_id: _isNull(job_category_id),
        job_role_id: _isNull(job_role_id),
        education_id: _isNull(education_id),
        income_id: _isNull(income_id),
        source_income_id: _isNull(income_source_id),
        investment_objective_id: _isNull(investment_objective_id),
        mail_address: _isNull(address),
        mail_postal_code: _isNull(post_code),
        province_id: _isNull(province_id),
        mail_city_id: city_id,
      };
      user_account.forEach((_, index) => {
        const i = _getNameIndex(index);
        const name = `nama_bank${i}`;
        const no_rek = `no_rek${i}`;
        initial[name] = _.bank.name;
        initial[no_rek] = _.account_number;
        fieldRekening.push([
          { name, id: `input_nama_rekening${i}`, label: `NAMA REKENING ${i.replace("_","")}` },
          { name: no_rek, id: `input_no_rekening${i}`, label: `NO. REKENING ${i.replace("_","")}` },
        ]);
      });
      let disabled = [];
      Object.keys(initial).forEach((_) => {
        disabled.push(_);
      });
      this._trigger(province_id);
      const _res = {
        initial,
        disabled,
        fieldRekening,
        isKorespondensiSame: card_address === address,
      };
      Object.keys(Regional).forEach((key) => {
        if (profile[Regional[key]]) _res[key] = [profile[Regional[key]]];
      });
      this.setState(_res);
    }
  };

  _handleEditable = () => {
    const editable = !this.state.editable;
    if (editable) {
      window.scrollTo(0, 0);
      this.setState(({ disabled }) => {
        return {
          editable,
          disabled: disabled.filter((_) => canEditable.indexOf(_) < 0),
        };
      });
    } else {
      const { setValues } = this.refs.formik;
      const { initial } = this.state;
      const { card_address, address } = this.props.profile;
      this.setState(({ disabled }) => ({
        disabled: [...disabled, ...canEditable],
        editable,
        isKorespondensiSame: card_address === address,
      }));
      setValues(initial);
    }
  };

  _handleSubmit = (data) => {
    const { fetch_update_profile, set_result } = this.props;
    if (!isEqual(data, this.state.initial)) {
      const _res = {};
      Object.keys(data).forEach((key) => {
        if (canEditable.indexOf(key) > 0) _res[key] = data[key];
      });
      fetch_update_profile(_res);
    } else {
      set_result({
        name: "update-profile",
        data: {
          type: "error",
          msg: "Data Baru Tidak Boleh Sama dengan Sebelumnya",
        },
      });
    }
  };

  handleAddressKorespondensi = (isKorespondensiSame) => {
    if (isKorespondensiSame) {
      const {
        card_address,
        card_province_id,
        card_city_id,
        card_post_code,
      } = this.state.initial;
      const { setValues, getValues } = this.refs.formik;
      const base = {
        mail_address: card_address,
        province_id: card_province_id,
        mail_city_id: card_city_id,
        mail_postal_code: card_post_code,
      };
      setValues({ ...getValues(), ...base });
    }
    this.setState({ isKorespondensiSame });
  };

  _triggerSubmit = () => this.refs.formik.handleSubmit();

  _trigger = (value, name) => {
    const city_korespondensi = this.props.optionReducer.city_list.filter(
      (_) => _.zone_province_id === value
    );
    this.setState({ city_korespondensi });
  };

  render() {
    const { onClick, optionReducer, loading_stack } = this.props;
    const {
      province_list,
      // city_list,
      religion_list,
      marriage_list,
      nationality_list,
      job_list,
      job_role_list,
      job_category_list,
      education_list,
      income_list,
      income_source_list,
      investment_objective_list,
    } = optionReducer;
    const {
      initial,
      disabled,
      // kecamatan,
      // desa,
      editable,
      isKorespondensiSame,
      city_korespondensi,
      fieldRekening,
    } = this.state;
    return (
      <div>
        <Title text="IDENTITAS" className="mb-3 pt-4" />
        {Object.keys(initial).length ? (
          <FormRegister
            ref="formik"
            initial={initial}
            validation={validationSchema}
            onSubmit={this._handleSubmit}
            functionTrigger={this._trigger}
            loadingSubmit={processHelper.findProcess(
              "update-profile",
              loading_stack
            )}
            defaultInputProps={{
              cClass: "mb-lg-n1 mx-3 mx-md-0 align-self-end",
              disabledStyle: editable,
            }}
            withButtonSubmit={false}
            data={[
              [{ name: "card_number",id: "input_card_number",  label: "NIK" }],
              [
                { name: "name",id: "input_name", label: "NAMA LENGKAP SESUAI KTP" },
                { name: "ttl",id: "input_ttl", label: "TEMPAT/TANGGAL LAHIR" },
              ],
              { name: "card_address",id: "input_card_address", label: "ALAMAT SESUAI KTP" },
              [
                {
                  name: "card_province_id",
                  id: "input_card_province_id",
                  label: "PROVINSI",
                  // typeInput: "SelectsInput",
                  // data: province_list,
                },
                {
                  name: "card_city_id",
                  id: "input_card_city_id",
                  label: "KABUPATEN/KOTA",
                  // typeInput: "SelectsInput",
                  // data: city_list,
                },
              ],
              [
                {
                  name: "card_kecamatan_id",
                  id: "input_card_kecamatan_id",
                  label: "KECAMATAN",
                  // typeInput: "SelectsInput",
                  // data: kecamatan,
                },
                {
                  name: "card_kelurahan_id",
                  id: "input_card_kelurahan_id",
                  label: "KELURAHAN/DESA",
                  // typeInput: "SelectsInput",
                  // data: desa,
                },
              ],
              [
                { name: "card_rtrw",id: "input_card_rtrw", label: "RT/RW" },
                { name: "card_post_code",id: "input_card_post_code", label: "KODE POS" },
              ],
              {
                name: "mail_address",
                id: "input_mail_address",
                label: "ALAMAT KORESPONDENSI",
                componentSideLabel: !isKorespondensiSame && (
                  <CheckBoxKorespondensi
                    onChange={this.handleAddressKorespondensi}
                    value={isKorespondensiSame}
                    disabled={!editable}
                  />
                ),
                withInput: !isKorespondensiSame,
                propsContainLabel: { justify: "space-between" },
                children: isKorespondensiSame && (
                  <CheckBoxKorespondensi
                    onChange={this.handleAddressKorespondensi}
                    value={isKorespondensiSame}
                    disabled={!editable}
                  />
                ),
              },
              !isKorespondensiSame && [
                {
                  name: "province_id",
                  id: "input_province_id",
                  label: "PROVINSI KORESPONDENSI",
                  typeInput: "SelectsInput",
                  data: province_list,
                  trigger: true,
                },
                {
                  name: "mail_city_id",
                  id: "input_mail_city_id",
                  label: "KABUPATEN/KOTA KORESPONDENSI",
                  typeInput: "SelectsInput",
                  data: city_korespondensi,
                },
              ],
              !isKorespondensiSame && [
                {
                  name: "mail_postal_code",
                  id: "input_mail_postal_code",
                  label: "KODE POS KORESPONDENSI",
                },
              ],
              [
                {
                  name: "religion_id",
                  id: "input_religion_id",
                  label: "AGAMA",
                  typeInput: "SelectsInput",
                  data: religion_list,
                },
                {
                  name: "marriage_id",
                  id: "input_marriage_id",
                  label: "STATUS PERKAWINAN",
                  typeInput: "SelectsInput",
                  data: marriage_list,
                },
              ],
              [
                {
                  name: "citizenship",
                  id: "input_citizenship",
                  label: "KEWARGANEGARAAN",
                  typeInput: "SelectsInput",
                  data: nationality_list,
                  valueItem: "name",
                },
                {
                  name: "job_id",
                  id: "input_job_id",
                  label: "PEKERJAAN",
                  typeInput: "SelectsInput",
                  data: job_list,
                },
              ],
              [
                {
                  name: "job_category_id",
                  id: "input_job_category_id",
                  label: "BIDANG USAHA TEMPAT BEKERJA",
                  typeInput: "SelectsInput",
                  data: job_category_list,
                },
                {
                  name: "job_role_id",
                  id: "input_job_role_id",
                  label: "JABATAN",
                  typeInput: "SelectsInput",
                  data: job_role_list,
                },
              ],
              [
                {
                  name: "education_id",
                  id: "input_education_id",
                  label: "PENDIDIKAN",
                  typeInput: "SelectsInput",
                  data: education_list,
                },
                {
                  name: "income_id",
                  id: "input_income_id",
                  label: "PENDAPATAN",
                  typeInput: "SelectsInput",
                  data: income_list,
                },
              ],
              [
                {
                  name: "source_income_id",
                  id: "input_source_income_id",
                  label: "SUMBER PENDAPATAN",
                  typeInput: "SelectsInput",
                  data: income_source_list,
                },
                {
                  name: "investment_objective_id",
                  id: "input_investment_objective_id",
                  label: "TUJUAN INVESTASI",
                  typeInput: "SelectsInput",
                  data: investment_objective_list,
                },
              ],
              {
                children: <Title text="INFORMASI REKENING BANK" />,
                cClass: "mt-2 pt-1 mb-n2",
              },
              ...fieldRekening,
              {
                Children: ButtonAction,
                ChildrenProps: {
                  editable,
                  triggerSubmit: this._triggerSubmit,
                  handleEditable: this._handleEditable,
                  handleClose: onClick,
                  loading: processHelper.findProcess(
                    "update-profile",
                    loading_stack
                  ),
                },
              },
            ]}
            disabledInputs={disabled}
          />
        ) : (
          ""
        )}
        <DialogAlert resultName="update-profile" alertAppear="both" />
      </div>
    );
  }
}

// const IdentityProfile = (props) => {
//   const { profile, onClick } = props;
//   const [initial, setInitial] = useState({});
//   const [disabled, setDisabled] = useState([]);
//   const { province_list } = useStore().getState().optionReducer;
//   const { result_stack } = useStore().getState().stateReducer;
//   useEffect(() => {
//     console.log(profile);
//     if (!Object.keys(initial).length && profile.user_id) {

//     } else if()
//   }, [profile, initial]);
//   const classes = useStyles();
//   const handleClick = (e) => {
//     onClick(e);
//   };
//
// return (
//   Object.keys(profile).length > 0 && (
//     <div className="pt-4 px-4">
//       <div className="mb-4">
//         <BasicText className="fs-14 font-weight-bold mb-3" color="primary">
//           IDENTITAS
//         </BasicText>
//         <div className={classNames("mb-3", classes.oneColumn)}>
//           <BasicText className="fs-14 mb-2" color="secondary">
//             NIK
//           </BasicText>
//           <TextInput
//             variant="outlined"
//             value={profile.card_number}
//             disabled={true}
//           />
//         </div>
//         <div className={classes.twoColumn}>
//           <div className="mb-3">
//             <BasicText className="fs-14 mb-2" color="secondary">
//               NAMA LENGKAP SESUAI KTP
//             </BasicText>
//             <TextInput
//               variant="outlined"
//               inputClass="text-capitalize"
//               value={textHelper.printFullname(profile)}
//               disabled={true}
//             />
//           </div>
//           <div className="mb-3">
//             <BasicText className="fs-14 mb-2" color="secondary">
//               TEMPAT/TANGGAL LAHIR
//             </BasicText>
//             <TextInput
//               variant="outlined"
//               value={
//                 profile.birth_place +
//                 " " +
//                 textHelper.printDate(profile.birth_date)
//               }
//               disabled={true}
//             />
//           </div>
//         </div>
//         <div className={classNames("mb-3", classes.fullRow)}>
//           <BasicText className="fs-14 mb-2" color="secondary">
//             ALAMAT SESUAI KTP
//           </BasicText>
//           <TextInput
//             variant="outlined"
//             value={profile.card_address}
//             disabled={true}
//           />
//         </div>
//         <div className={classNames("mb-3", classes.fullRow)}>
//           <BasicText className="fs-14 mb-2" color="secondary">
//             ALAMAT KORESPONDENSI
//           </BasicText>
//           <TextInput
//             variant="outlined"
//             value={profile.address}
//             disabled={true}
//           />
//         </div>
//         <div className={classes.twoColumn}>
//           <div className="mb-3">
//             <BasicText className="fs-14 mb-2" color="secondary">
//               AGAMA
//             </BasicText>
//             <TextInput
//               variant="outlined"
//               value={profile.religion ? profile.religion.name : ""}
//               disabled={true}
//             />
//           </div>
//           <div className="mb-3">
//             <BasicText className="fs-14 mb-2" color="secondary">
//               STATUS PERKAWINAN
//             </BasicText>
//             <TextInput
//               variant="outlined"
//               value={profile.marriage ? profile.marriage.name : ""}
//               disabled={true}
//             />
//           </div>
//         </div>
//         <div className={classes.twoColumn}>
//           <div className="mb-3">
//             <BasicText className="fs-14 mb-2" color="secondary">
//               PEKERJAAN
//             </BasicText>
//             <TextInput
//               variant="outlined"
//               value={profile.job ? profile.job.name : ""}
//               disabled={true}
//             />
//           </div>
//           <div className="mb-3">
//             <BasicText className="fs-14 mb-2" color="secondary">
//               KEWARGANEGARAAN
//             </BasicText>
//             <TextInput variant="outlined" value="INDONESIA" disabled={true} />
//           </div>
//         </div>
//         <div className={classes.twoColumn}>
//           <div className="mb-3">
//             <BasicText className="fs-14 mb-2" color="secondary">
//               BIDANG USAHA TEMPAT BEKERJA
//             </BasicText>
//             <TextInput variant="outlined" value="-" disabled={true} />
//           </div>
//           <div className="mb-3">
//             <BasicText className="fs-14 mb-2" color="secondary">
//               JABATAN
//             </BasicText>
//             <TextInput variant="outlined" value="-" disabled={true} />
//           </div>
//         </div>
//         <div className={classes.twoColumn}>
//           <div className="mb-3">
//             <BasicText className="fs-14 mb-2" color="secondary">
//               PENDIDIKAN
//             </BasicText>
//             <TextInput
//               variant="outlined"
//               value={profile.education ? profile.education.name : ""}
//               disabled={true}
//             />
//           </div>
//           <div className="mb-3">
//             <BasicText className="fs-14 mb-2" color="secondary">
//               PENDAPATAN
//             </BasicText>
//             <TextInput
//               variant="outlined"
//               value={profile.income ? profile.income.name : ""}
//               disabled={true}
//             />
//           </div>
//         </div>
//         <div className={classes.twoColumn}>
//           <div className="mb-3">
//             <BasicText className="fs-14 mb-2" color="secondary">
//               SUMBER PENDAPATAN
//             </BasicText>
//             <TextInput
//               variant="outlined"
//               value={profile.income_source ? profile.income_source.name : ""}
//               disabled={true}
//             />
//           </div>
//           <div className="mb-3">
//             <BasicText className="fs-14 mb-2" color="secondary">
//               TUJUAN INVESTASI
//             </BasicText>
//             <TextInput variant="outlined" value="-" disabled={true} />
//           </div>
//         </div>
//       </div>
//       <div>
//         <BasicText className="fs-14 font-weight-bold mb-3" color="primary">
//           INFORMASI REKENING BANK
//         </BasicText>
//         <div className={classes.twoColumn}>
//           <div className="mb-3">
//             <BasicText className="fs-14 mb-2" color="secondary">
//               REKENING BANK
//             </BasicText>
//             <TextInput
//               variant="outlined"
//               value={textHelper.userAccountPriority(
//                 profile.user_account,
//                 "number"
//               )}
//               disabled={true}
//             />
//           </div>
//           <div className="mb-3">
//             <BasicText className="fs-14 mb-2" color="secondary">
//               NAMA PEMILIK REKENING
//             </BasicText>
//             <TextInput
//               variant="outlined"
//               value={textHelper.userAccountPriority(
//                 profile.user_account,
//                 "behalf"
//               )}
//               disabled={true}
//             />
//           </div>
//         </div>
//       </div>
//       <div className={classNames("mt-3", classes.twoButton)}>
//         <ButtonContained
//           text="UBAH"
//           className="fs-14 font-weight-medium px-5 py-2 mr-3"
//           radius="5px"
//           name="change"
//           onClick={handleClick}
//         />
//         <ButtonContained
//           text="BATAL"
//           className="fs-14 font-weight-medium px-5 py-2"
//           radius="5px"
//           backgroundColor="#b2b2b2"
//           name="cancel"
//           onClick={handleClick}
//         />
//       </div>
//     </div>
//   )
// );
// };

IdentityProfile.defaultProps = {
  onClick: () => {},
};

const mapStateToProps = ({ profileReducer, stateReducer, optionReducer }) => ({
  profile: profileReducer.profile,
  result_stack: stateReducer.result_stack,
  loading_stack: stateReducer.loading_stack,
  optionReducer,
});

const mapDispatchToProps = { set_app_config, fetch_update_profile, set_result };

export default connect(mapStateToProps, mapDispatchToProps)(IdentityProfile);
