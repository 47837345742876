import React from "react";
import Radio from "@material-ui/core/Radio";
import Grid from "@material-ui/core/Grid";
import BasicText from "../../atom/BasicText/BasicText";
import makeStyles from "@material-ui/styles/makeStyles";
import classNames from "classnames";
import textHelper from "../../../helper/textHelper";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "1rem",
    [theme.breakpoints.up(768)]: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      // paddingBottom: 0,
      // marginBottom: 0
    },
  },
  box: {
    backgroundColor: theme.palette.background.default,
    padding: "16px",
    marginTop: 15,
    width: "100%",
    [theme.breakpoints.up(768)]: {
      width: 467,
      marginTop: 0,
    },
  },
  check: {
    maxWidth: 395,
    alignItems: "flex-start",
  },
  buttonContainer: {
    marginTop: "1.5rem",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.up(768)]: {
      justifyContent: "start",
    },
    "& .btnNext": {
      fontSize: 13,
      fontWeight: 700,
      padding: "0.75rem 2rem",
      marginRight: "1rem",
      [theme.breakpoints.down(769)]: {
        marginRight: 0,
      },
    },
    "& .btnCancel": {
      fontSize: 13,
      fontWeight: 700,
      padding: "0.75rem 2rem",
      [theme.breakpoints.down(769)]: {
        display: "none",
      },
    },
  },
  radioRoot: {
    color: theme.palette.secondary.main,
    padding: 10,
    marginTop: "-10px",
    marginLeft: "-10px",
    width: 40,
    height: 40,
  },
}));

const RadioAccount = (props) => {
  const { id, name, behalf, account_number, selected, onChange } = props;
  const handleChange = (value) => {
    onChange(value);
  };
  const classes = useStyles();
  const base = [
    {
      text: name,
      className: "fs-14 gray-3 mb-1 font-weight-medium",
    },
    {
      text: textHelper.printCapitalize(behalf),
      className: "fs-12 gray-4 text-capitalize",
    },
    {
      text: account_number,
      className: "fs-12 gray-4",
    },
  ];
  return (
    <div className="mb-2 d-flex">
      <Radio
        id={`${id}-radio`}
        checked={String(selected) === String(id)}
        value={id}
        inputProps={{ "aria-label": id }}
        onChange={(e) => handleChange(e.target.value)}
        className=""
        color="primary"
        size="small"
        classes={{ root: classes.radioRoot }}
      />
      <div className="w-100">
        {base.map((el) => (
          <BasicText
            key={el.text}
            className={classNames(el.className, "is-link")}
            onClick={() => handleChange(id)}
            style={{ width: "max-content" }}
          >
            {el.text}
          </BasicText>
        ))}
      </div>
    </div>
  );
};

const SellBankAccount = (props) => {
  const { data } = props;
  const [state, setState] = React.useState({
    selected: props.user_account,
  });
  const { selected } = state;
  const handleChange = (e) => {
    setState((state) => ({ ...state, selected: e }));
    props.onChange(e);
  };
  const classes = useStyles();
  return (
    <div className={classNames(classes.root, "card-custom")}>
      <Grid container>
        <Grid item xs={12} md={5} className="w-100">
          <BasicText className="fs-14 font-weight-bold mb-3">
            Rekening Tujuan
          </BasicText>
          {data.map((el, i) => (
            <RadioAccount
              key={i}
              id={el.id}
              onChange={handleChange}
              selected={selected}
              name={el.bank.name}
              behalf={el.behalf}
              account_number={el.account_number}
            />
          ))}
        </Grid>
        <Grid item xs={12} md={7} container justify="flex-end">
          <div
            className={classNames(
              classes.box,
              "d-flex justify-content-center align-items-center w-100"
            )}
          >
            {/* <BasicText className="fs-12 mb-2" color="primary">
              Dana kamu akan diterima selambat-lambatnya 7 hari kerja setelah
              transaksi selesai.
            </BasicText> */}
            <BasicText
              className="fs-12 gray-3 text-center"
              color="primary"
              style={{ width: 210 }}
            >
              Dana kamu akan diterima selambat-lambatnya 7 hari kerja setelah
              transaksi selesai.
            </BasicText>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SellBankAccount;
