import React from "react";
import PropTypes from "prop-types";
import icon from "../../../assets/img/icon/pin";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  img: {
    marginRight: 18
  }
});

const Indicator = props => {
  const { value } = props;
  const classes = useStyles();
  return (
    <div className="d-flex justify-content-center mb-4">
      <img
        src={value >= 1 ? icon.pinInputActive : icon.pinInput}
        alt=""
        className={classes.img}
      />
      <img
        src={value >= 2 ? icon.pinInputActive : icon.pinInput}
        alt=""
        className={classes.img}
      />
      <img
        src={value >= 3 ? icon.pinInputActive : icon.pinInput}
        alt=""
        className={classes.img}
      />
      <img
        src={value >= 4 ? icon.pinInputActive : icon.pinInput}
        alt=""
        className={classes.img}
      />
      <img
        src={value >= 5 ? icon.pinInputActive : icon.pinInput}
        alt=""
        className={classes.img}
      />
      <img src={value >= 6 ? icon.pinInputActive : icon.pinInput} alt="" />
    </div>
  );
};

Indicator.propTypes = {
  value: PropTypes.number
};

export default Indicator;
