import React from "react";
import SliderWithInputText from "../MaterialInput/SliderWithInputText";
import { Grid } from "@material-ui/core";

const GroupSliderInput = ({
  data,
  classTextInput,
  onChange,
  defaultProps,
  ...other
}) => {
  function _handleChange(e, key) {
    onChange(e, key);
  }
  return (
    <Grid {...other}>
      {data.map((slider, index) => {
        return (
          <SliderWithInputText
            {...slider}
            key={index}
            className={classTextInput}
            onChange={(e) => _handleChange(e, slider.keyState)}
            {...defaultProps}
          />
        );
      })}
    </Grid>
  );
};

GroupSliderInput.defaultProps = {
  data: [],
  onChange: () => {},
};

export default GroupSliderInput;
