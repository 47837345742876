import { Card, Grid, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import React, { useEffect } from "react";
import BasicText from "../../atom/BasicText/BasicText";
import ButtonContained from "../../atom/Button/ButtonContained";
import DesktopFooter from "../../molecule/Footer/DesktopFooter";
import GoalIcon from "../../molecule/GoalIcon";
import ilustration from "../../../assets/img/ilustration";
import BasicHeader from "../../molecule/Header/BasicHeader";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "70%",
    display: "flex",
    padding: 37,
    paddingTop: 24,
    flexDirection: "column",
    "& .item-goal .container-image.checked": {
      backgroundColor: theme.palette.secondary[8],
      borderRadius: 4,
    },
  },
  iconArea: {
    width: "80% !important",
    margin: "auto",
  },
  btnRoot: {
    margin: "25px auto",
    "& .Mui-disabled": {
      backgroundColor: theme.palette.primary.lighter,
      color: "white",
    },
  },
  btn: {
    width: 155,
    height: 48,
    borderRadius: "6px !important",
    margin: "10px",
  },
}));

const ReferanceInvestPage = ({
  item,
  checked,
  onChecked,
  onSubmit,
  loading,
}) => {
  const classes = useStyles();
  const {
    location: { state },
    replace,
  } = useHistory();

  useEffect(() => {
    if (!state?.status ?? false) replace("/");
  }, [replace, state]);

  return (
    <div className="background-desktop">
      <Card
        className={classNames(
          "container-center-desktop no-shadow",
          classes.root
        )}
      >
        <BasicHeader title="" />
        <Grid container className="mt-4">
          <Grid
            item
            xs={4}
            className="d-flex flex-column justify-content-between"
          >
            <div>
              <img alt="" src={ilustration.ReferanceInvest} />
              <BasicText
                className="fs-18 font-weight-medium gray-3 mt-2"
                style={{ minWidth: 260 }}
              >
                Pilih rencana investasi sesuai <br /> dengan #goalskamu
              </BasicText>
            </div>
            <BasicText className="gray-5 fs-12">
              Masih belum ada rencana{" "}
              <span
                id="skip-btn"
                className="is-link text-underline ml-3"
                onClick={() => onSubmit(true)}
                disabled={loading}
              >
                SKIP
              </span>
            </BasicText>
          </Grid>
          <Grid item xs={8}>
            <div className="d-flex flex-column">
              <Grid
                container
                spacing={3}
                className={classNames(classes.iconArea)}
              >
                {item.map((el, id) => (
                  <Grid key={id} item xs={4}>
                    <GoalIcon
                      item={el}
                      checked={checked.includes(el.id)}
                      onChecked={onChecked}
                      withCheck={true}
                    />
                  </Grid>
                ))}
              </Grid>
              <div className={classes.btnRoot}>
                <ButtonContained
                  id="lanjut-btn"
                  className={classes.btn}
                  text="LANJUT"
                  onClick={onSubmit}
                  disabled={!checked.length}
                  loading={loading}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Card>

      <DesktopFooter />
    </div>
  );
};

export default ReferanceInvestPage;
