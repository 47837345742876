import React from "react";
import { Card, makeStyles } from "@material-ui/core";
import BasicText from "../../atom/BasicText/BasicText";
import profile_risk from "../../../assets/img/icon/profile_risk";
import ProfileRiskNameDesc from "./ProfileRiskNameDesc";
import classNames from "classnames";
import color from "../../../assets/theme/color";

const useStyles = makeStyles({
  contain: {
    borderBottom: "1px solid " + color.gray_6,
    paddingBottom: 63,
    marginBottom: 45,
  },
});

const CardRiskIndicator = (props) => {
  const S = useStyles();
  const { profileRiskType, className } = props;
  return (
    <Card className={classNames("pt-3", S.contain, className)}>
      <BasicText className="fs-14 font-weight-bold color-secondary mb-3">
        Indikasi Profil
      </BasicText>
      <img src={profile_risk.riskIndicator2} alt="" className="mb-3" />
      <ProfileRiskNameDesc profileRiskType={profileRiskType} />
    </Card>
  );
};

export default CardRiskIndicator;
