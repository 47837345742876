import React from "react";
import useStyles from "./useStyles";
import SliderWithInput from "../../../molecule/MaterialInput/SliderWithInput";
import CheckInput from "../../../molecule/MaterialInput/CheckInput";
import ButtonContained from "../../../atom/Button/ButtonContained";
import color from "../../../../assets/theme/color";
import classNames from "classnames";
import textHelper from "../../../../helper/textHelper";
import { Grid } from "@material-ui/core";
const { useState, useCallback } = React;

const InputTxn = ({
  id,
  initial,
  min,
  max,
  title,
  recommend,
  disabled,
  all,
  onChange,
  value,
  onIsAll,
  children,
  className,
  labelCheck,
  BtnRecProps,
  CheckInputProps,
  SliderWithInputProps,
}) => {
  const classes = useStyles();
  const [isAll, setisAll] = useState(0);

  const handleInputChange = useCallback(
    (value) => {
      const valueNumber = Number(value);
      const isSliderMax = parseFloat(valueNumber) === all;
      const valIsAll = isSliderMax ? 1 : 0;
      setisAll(valIsAll);
      onChange(valueNumber);
      onIsAll(valIsAll);
    },
    [all, onChange, onIsAll]
  );

  return (
    <div className={classNames(classes.root, className?.root)}>
      <SliderWithInput
        id={id}
        adormentContent={() => <span className="fs-14 gray-4">Rp</span>}
        adormentPlace="start"
        value={value}
        minValue={min}
        maxValue={max}
        stepSlider={1000}
        text={title}
        onChange={handleInputChange}
        sliderClass="pb-0"
        placeholder="00"
        disabled={disabled}
        {...SliderWithInputProps}
      />
      {children}
      <div className="w-100 justify-content-end d-flex mb-2 mt-1">
        <CheckInput
          reverse={true}
          label={labelCheck || "Jual semua"}
          className="align-items-center mr-n1"
          colorCheck="secondary"
          checkClass="py-0"
          onChange={(isChecked) => handleInputChange(isChecked ? all : 0)}
          id="all"
          disabled={disabled}
          state={isAll}
          forceSet={true}
          {...CheckInputProps}
        />
      </div>
      <Grid container spacing={1}>
        {Array.isArray(recommend) &&
          recommend.map((rec, id) => {
            const isSelected = Number(value) === Number(rec);
            const isAvailable = parseFloat(max) < parseFloat(rec);
            return (
              <Grid item xs={3} key={id}>
                <ButtonContained
                  id={`${rec}-btn`}
                  backgroundColor={isSelected ? color.gray_3 : color.gray_4}
                  text={textHelper.printMoneyWithoutRp(rec)}
                  className={classNames("fs-12 w-100", classes.buttonRecommend)}
                  onClick={() => handleInputChange(rec)}
                  disabled={disabled || isAvailable ? true : false}
                  {...BtnRecProps}
                />
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
};

InputTxn.defaultProps = {
  initial: "",
  max: 1000000,
  min: 0,
  onChange: () => {},
  onIsAll: () => {},
};

export default InputTxn;
