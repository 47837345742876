import { makeStyles } from "@material-ui/core";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import DialogCustom from "../Container/DialogCustom";
import ContentResult from "../ProfileRiskPage/ContentResult";

const useStyles = makeStyles({
  container: {
    width: 765,
  },
  contain: {
    padding: "64px 65px 40px",
  },
});

const ModalProfileRisk = forwardRef(
  ({ onSubmit, onSubmitBlur, ...other }, ref) => {
    const S = useStyles();
    const [state, setState] = useState({
      visible: false,
    });
    useImperativeHandle(ref, () => ({
      handleVisible,
    }));
    function handleVisible() {
      setState({ ...state, visible: !state.visible });
    }
    function _onSubmit(data) {
      onSubmitBlur && handleVisible();
      onSubmit(data);
    }
    const { visible } = state;
    return (
      <DialogCustom
        open={visible}
        backgroundColor="white"
        maxWidth="md"
        className={S.container}
        handleClose={handleVisible}
        content={
          <ContentResult
            className={S.contain}
            onSubmit={_onSubmit}
            {...other}
            onChange
          />
        }
      />
    );
  }
);

ModalProfileRisk.defaultProps = {
  onSubmit: () => { },
};

export default ModalProfileRisk;
