import { Grid, withStyles } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import illustration from "../../assets/img/ilustration";
import stateCallApi from "../../config/API/stateCallApi";
import {
  porto_detail_props,
  statement_props,
} from "../../config/redux/actions/portofolio";
import { gbl_act_lc, gbl_list_lc } from "../../config/redux/actions/state";
import textHelper from "../../helper/textHelper";
import BasicText from "../atom/BasicText/BasicText";
import DialogAlert from "../molecule/Alert/DialogAlert";
import PortoTabs from "../molecule/Porto/PortoTabs";
import TabsPanel from "../molecule/Tabs/TabsPanel";
import ContainerWithLoader from "../organism/Container/ContainerWithLoader";
import CardUserGoals from "../organism/MainPage/DashboardList/cards/CardUserGoals";
import PortoAssetChart from "../organism/Porto/PortoAssetChart";
import PortoSummary from "../organism/Porto/PortoSummary";
import PortoTabel from "../organism/Porto/PortoTabel";
import TempMain from "../template/TempMain";
import { AddCircleOutline } from "@material-ui/icons";
import classNames from "classnames";
class PortoPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tab: 0,
    };
  }

  componentDidMount() {
    const { porto_detail_props, gbl_act_lc, gbl_list_lc } = this.props;
    porto_detail_props(
      textHelper.printDateSubtractFormat(undefined, "months", 1, "YYYY-MM")
    );
    gbl_list_lc(stateCallApi.goals_user_list);
    gbl_act_lc(stateCallApi.porto_summary);
  }

  handleChangeTabs = (e) => {
    this.setState({ tab: e });
  };

  handleLoadMore = () => {
    const {
      gbl_list_lc,
      goals: { meta },
    } = this.props;
    const current_page = meta.current_page + 1;
    gbl_list_lc({
      ...stateCallApi.goals_user_list,
      option: { ...meta, current_page },
    });
  };

  handleCardGoals = (key) => {
    const { push } = this.props.history;
    push(`/portofolio-goals?goals=${key}`);
  };

  render() {
    const {
      row,
      porto_detail_props,
      statement_props,
      goals,
      porto_summary,
      history: { push },
      classes,
    } = this.props;
    const { data } = goals;
    return (
      <TempMain title="PORTOFOLIO" bottomNavValue={3} initialPage>
        <div className="pt-md-4 px-md-4 margin-main">
          <PortoTabs onChange={this.handleChangeTabs} />
          <TabsPanel index={0} value={this.state.tab}>
            <ContainerWithLoader
              processName={["goals-user", "porto_summary"]}
              loadingHeight="50vh"
            >
              {Array.isArray(data) && data.length > 0 ? (
                <>
                  <PortoSummary summary={porto_summary} title="1" />
                  <div className="pt-3 px-3 margin-main">
                    <PortoAssetChart summary={porto_summary} />
                    {data.length ? (
                      <Grid container spacing={3}>
                        {data.map((el, id) => (
                          <Grid item key={id} xs={12} md={6} lg={4}>
                            <CardUserGoals item={el} />
                          </Grid>
                        ))}
                        <Grid
                          item
                          xs={12}
                          md={6}
                          lg={4}
                          style={{
                            height: 171,
                          }}
                        >
                          <div
                            className={classNames(
                              "h-100 w-100 d-flex flex-column align-items-center justify-content-center is-link rounded-3 bg-gray-7",
                              classes.btnAddPorto
                            )}
                            onClick={() => push("/create-planner")}
                          >
                            <AddCircleOutline className="fs-32 gray-4" />
                            <BasicText className="fs-16">
                              Tambah Portofolio
                            </BasicText>
                          </div>
                        </Grid>
                      </Grid>
                    ) : (
                      <BasicText>KOSONG</BasicText>
                    )}
                  </div>
                </>
              ) : (
                <div className="card-custom h-70vh d-flex flex-column justify-content-center align-items-center">
                  <img
                    src={illustration["emptyporto"]}
                    alt=""
                    className="mb-4"
                  />
                  <BasicText className="fs-14 font-weight-bold color-secondary mb-2">
                    Belum ada Portofolio
                  </BasicText>
                  <BasicText className="fs-14 text-center">
                    Yuk investasi sekarang!
                  </BasicText>
                </div>
              )}
            </ContainerWithLoader>
          </TabsPanel>
          <TabsPanel index={1} value={this.state.tab}>
            <PortoTabel
              row={row}
              porto_detail_props={porto_detail_props}
              statement_props={statement_props}
            />
            <DialogAlert
              resultName={["porto_detail", "statement"]}
              alertAppear="error"
            />
          </TabsPanel>
        </div>
      </TempMain>
    );
  }
}

const useStyles = (theme) => ({
  btnAddPorto: {
    gap: 10,
    [theme.breakpoints.down("md")]: {
      backgroundColor: "transparent !important",
      "&::before": {
        content: '""',
        width: 4,
        height: 4,
        borderRadius: "50%",
        backgroundColor: theme.palette.primary.main,
        marginBottom: 10,
      },
    },
    "& p": {
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
  },
});

const mapDispatchToProps = {
  porto_detail_props,
  statement_props,
  gbl_act_lc,
  gbl_list_lc,
};

const mapState = (state) => ({
  row: state.portoReducer.porto_detail,
  goals: state.goalReducer.user,
  porto_summary: state.goalReducer.porto_summary,
});

export default compose(
  connect(mapState, mapDispatchToProps),
  withRouter,
  withStyles(useStyles)
)(PortoPage);
