import React, { memo } from "react";
import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import ButtonContained from "../../atom/Button/ButtonContained";

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up(768)]: {
      backgroundColor: "#fff",
      paddingBottom: "20px",
    },
  },
  buttonContainer: {
    display: "flex",
    [theme.breakpoints.up(768)]: {
      borderBottom: `1px solid ${theme.palette.background.dark}`,
    },
  },
  buttonTab: {
    backgroundColor: "#fff",
    color: theme.palette.secondary.main,
    margin: "0 0 0 80px",
    position: "relative",
    [theme.breakpoints.down(769)]: {
      color: theme.palette.common.white,
      flex: 1,
      backgroundColor: theme.palette.secondary.light,
      fontWeight: 700,
      margin: 0,
    },
    "&.activeTab": {
      backgroundColor: theme.palette.background.default,
      borderBottom: "none",
      color: theme.palette.secondary.main,
      [theme.breakpoints.up(768)]: {
        backgroundColor: "#fff",
        "&::before": {
          content: `''`,
          width: "100%",
          height: "2px",
          backgroundColor: theme.palette.primary.main,
          position: "absolute",
          bottom: 0,
        },
      },
    },
    "&:hover": {
      backgroundColor: theme.palette.common.white,
    },
    "&::before": {
      [theme.breakpoints.up(768)]: {
        content: `''`,
        width: "100%",
        height: "2px",
        backgroundColor: theme.palette.common.white,
        position: "absolute",
        bottom: 0,
        transition: "background-color 0.3s",
      },
    },
    "&:hover::before": {
      [theme.breakpoints.up(768)]: {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}));

const TabCustom = ({ onChange, data, active, className }) => {
  const classes = useStyles();
  return (
    <div className={classNames(classes.container, "w-100", className)}>
      <div className={classes.buttonContainer}>
        {data.map((item, index) => {
          const key = item?.value ?? index;
          const text = item?.label ?? item;
          return (
            <ButtonContained
              id={text.replace(/ /g,'-').toLowerCase()}
              text={text}
              key={key}
              className={classNames(
                classes.buttonTab,
                "fs-12 py-3 px-md-0 font-weight-semibold text-uppercase",
                `item-tab item-tab-${index} item-tab-${key}`,
                active === key ? "activeTab" : ""
              )}
              onClick={() => onChange(key)}
              disableRipple={true}
            />
          );
        })}
      </div>
    </div>
  );
};

TabCustom.defaultProps = {
  data: [],
  onChange: () => {},
  active: 0,
};

export default memo(TabCustom, (prev, next) => prev.active === next.active);
