import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import classNames from "classnames";
import parse from "html-react-parser";
import React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router";
import ColorIndicator from "../../../../assets/img/icon/porto/ColorIndicator";
import chartHelper from "../../../../helper/chartHelper";
import textHelper from "../../../../helper/textHelper";
import ButtonInfo from "../../../atom/Button/ButtonInfo";
import CardProduct from "../../Product/CardProduct";
import { ModalType } from "../../Profile/TypeContent";
import useStyles from "./useStyles";
const { useCallback, useEffect, useRef } = React;

export const DetailGraph = ({ data = [], colors = [], className }) => {
  const classes = useStyles();
  const modalRef = useRef();
  const TitleDetail = ({
    xs,
    id,
    title,
    justify = "flex-start",
    className,
  }) => (
    <Grid item xs={xs} container alignItems="center" justify={justify}>
      <ButtonInfo
        title={title}
        onClick={() => modalRef.current?.handleOpen(id)}
        classes={{ root: className, icon: "fs-15", buttonIcon: "ml-n2" }}
      />
    </Grid>
  );

  const ItemDetail = ({ id, color, name, alokasi }) => {
    return (
      <Grid
        id={id}
        container
        alignItems="center"
        className={classNames(classes.rowDetail, "detail-row")}
      >
        <Grid container item xs={9} alignItems="flex-start">
          <ColorIndicator style={{ color, fontSize: 16 }} />
          <p className="detail-name">
            {parse(textHelper.printProduct2Line(name))}
          </p>
        </Grid>
        <Grid item xs={3}>
          <p className="detail-allocation">{alokasi}%</p>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className={className}>
      <Grid container>
        <TitleDetail title="Jenis Reksa Dana" xs={7} keyId="types" />
        <TitleDetail
          title="Alokasi"
          xs={5}
          keyId="alokasi"
          justify="flex-end"
          className="mr-n3"
        />
      </Grid>
      {data.map(({ allocation, product, product_code }, index) => (
        <ItemDetail
          id={product_code}
          key={product_code}
          name={product?.fr_product_name || ""}
          alokasi={allocation}
          color={colors[index]}
        />
      ))}
      <ModalType ref={modalRef} />
    </div>
  );
};

const ListProduct = ({ data, onClick }) => {
  const isDesktop = useMediaQuery({ minWidth: 769 });
  return data.map((el, index) => (
    <CardProduct
      key={index}
      items={el.product}
      needButton={false}
      className={isDesktop ? "mt-3" : "mt-2 br-8"}
      onClick={onClick}
      isMobileDesign
    />
  ));
};

const GraphAllocation = ({ idChart, data }) => {
  const classes = useStyles();
  const { push } = useHistory();
  const chart_color = useSelector((state) => state.optionReducer.chart_color);
  const handleClickProduct = useCallback(
    ({ link, items: product }) => {
      push(`${link}?product=${product.code}`, { product });
    },
    [push]
  );

  const createGraph = useCallback(() => {
    chartHelper.createPiChart(
      chart_color,
      data.map(({ product_code, allocation }) => ({
        name: product_code,
        y: Number(allocation),
      })),
      idChart
    );
  }, [chart_color, data, idChart]);

  useEffect(() => {
    createGraph();
  }, [createGraph]);
  return (
    <div>
      <Card className={classNames("shadow-card", classes.container_graph)}>
        <p className="color-secondary fs-12">Rekomendasi Produk:</p>
        <div id={idChart} className={classes.chart} />
        <DetailGraph data={data} colors={chart_color} />
      </Card>
      <ListProduct data={data} onClick={handleClickProduct} />
    </div>
  );
};

GraphAllocation.defaultProps = {
  idChart: "chart-risk",
  data: [],
};

export default GraphAllocation;
