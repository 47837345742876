import React, { useState } from "react";
import { Grid, makeStyles, Dialog } from "@material-ui/core";
import KeunggulanSmartPlan from "../../molecule/SmartPlan/KeunggulanSmartPlan";
import GraphAlocationProduct from "../../molecule/SmartPlan/GraphAlocationProduct";
import ButtonContained from "../../atom/Button/ButtonContained";
import color from "../../../assets/theme/color";
import classNames from "classnames";
import MainHeader from "../../molecule/Header/MainHeader";

const buttons = [
  {
    text: "INVESTASI SEKARANG",
    backgroundColor: color.primary,
    style: { flex: 2 },
  },
  {
    text: "SIMULASI",
    backgroundColor: color.secondary,
    style: { flex: 1, marginLeft: 5 },
  },
];

const useStyles = makeStyles({
  button: {
    paddingBottom: "11px !important",
    paddingTop: "11px !important",
  },
  containSimulasi: {
    backgroundColor: color.blue_8,
    padding: "95px 15px 20px",
  },
});

const TempSmartPlanMobile = ({ data }) => {
  const { contentProduct, simulasi, handleInvest, pushPage } = data || {};
  function _handleSimulasi() {
    setDialog(!dialog);
  }
  const ActionButtons = [handleInvest, _handleSimulasi];
  const [dialog, setDialog] = useState(false);
  const S = useStyles();
  return (
    <Grid className="mx-3 my-4">
      <KeunggulanSmartPlan />
      <GraphAlocationProduct {...contentProduct} force pushPage={pushPage} />
      <Grid
        container
        alignItems="center"
        justify="space-between"
        className="mt-4"
      >
        {buttons.map((button, i) => (
          <ButtonContained
            key={i}
            {...button}
            radius="8px"
            className={classNames("ellipsis1row py-2", S.button)}
            onClick={ActionButtons[i]}
          />
        ))}
      </Grid>
      <Dialog fullScreen open={dialog} onClose={_handleSimulasi}>
        <MainHeader
          goBack
          functionBack={_handleSimulasi}
          title="SMART PLAN"
          isCloseButton
        />
        <Grid className={S.containSimulasi} style={{ paddingTop: 80 }}>
          {simulasi}
        </Grid>
      </Dialog>
    </Grid>
  );
};

export default TempSmartPlanMobile;
