import { debounce } from "lodash";
import React, { memo, useState } from "react";
import { useCallback } from "react";
import { QrReader } from "react-qr-reader";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { gbl_act_lc } from "../../config/redux/actions/state";
import stateCallApi from "../../config/API/stateCallApi";
import {
  Button,
  CircularProgress,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import BasicText from "../atom/BasicText/BasicText";
import ilus from "../../assets/img/ilustration";
import classNames from "classnames";
import SnackAlert from "../molecule/Alert/SnackAlert";
import { Close } from "@material-ui/icons";
import TempMain from "../template/TempMain";
import parse from "html-react-parser";

const VoucherMerchantScanPage = ({ location, history }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [qrInfo, setQrInfo] = useState(null);
  const dispatch = useDispatch();

  const onScan = useCallback(
    debounce((result) => {
      if (loading) return;
      if (!!result) {
        setLoading(true);
        dispatch(
          gbl_act_lc({
            ...stateCallApi.voucherMerchantScan,
            params: {
              merchant_voucher_id: new URLSearchParams(location.search).get(
                "merchant-id"
              ),
              qr_code: result.getText(),
            },
            callback: {
              success: (data) => {
                setQrInfo(data);
                setLoading(false);
              },
              fail: () => setLoading(false),
            },
          })
        );
      }
    }, 500),
    [dispatch]
  );

  const goToHome = useCallback(() => history.replace("/"), [history]);

  return (
    <TempMain
      bottomNavValue={0}
      showBottom={false}
      goBackButton={!!qrInfo}
      isCloseButton={true}
      title={qrInfo ? "Berhasil" : ""}
      desktopBackFunc={goToHome}
    >
      {qrInfo ? (
        <>
          <div className="p-4 d-flex flex-column align-items-center bg-white pb-5">
            <img
              src={qrInfo.logo_url}
              alt=""
              width="80px"
              height="80px"
              className="rounded"
            />
            <BasicText className="mt-5 fs-15 fw-700 gray-4 mb-5">
              Yeay, scan berhasil!
            </BasicText>
          </div>
          <div className="m-3 d-flex flex-column">
            <div className="card-custom px-4 py-3 mb-3">
              <BasicText className="fs-12 mb-1">
                Merchant: <b>{qrInfo.merchant_name}</b>
              </BasicText>
              <BasicText className="fs-10 gray-4">
                {qrInfo.redeemed_at}
              </BasicText>
            </div>
            <div className="card-custom p-4 mb-5 basic fs-12 fw-600 gray-4">
              {parse(qrInfo.message)}
            </div>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className="align-self-center"
              onClick={goToHome}
            >
              kembali ke dashboard
            </Button>
          </div>
        </>
      ) : (
        <div
          className={classNames(
            classes.cover,
            "d-flex justify-content-center align-items-stretch"
          )}
        >
          <div className={classes.container}>
            <QrReader
              onResult={onScan}
              constraints={{ facingMode: "user" }}
              className={classes.scanner}
              scanDelay={500}
            />
            <div className={classes.coverScan}>
              <div
                className={classNames(
                  classes.headerCover,
                  "d-flex align-items-center"
                )}
              >
                <IconButton onClick={history.goBack}>
                  <Close className="color-white" />
                </IconButton>
                <BasicText className="fs-14 fw-700 color-white text-center flex">
                  Scan QR Code
                </BasicText>
              </div>
              <img
                src={ilus.backgroundScan}
                alt=""
                width="100%"
                height="100%"
              />
              <BasicText
                className={classNames(
                  classes.textScan,
                  "fs-15 fw-700 text-center color-white text-center"
                )}
              >
                Arahkan kamera anda ke kode QR merchant
              </BasicText>
            </div>

            {loading && (
              <div
                className={classNames(
                  classes.contentLoading,
                  "text-center card-custom p-4"
                )}
              >
                <CircularProgress />
                <BasicText>Memeriksa...</BasicText>
              </div>
            )}
            <SnackAlert name="voucher-merchant-redeem" />
          </div>
        </div>
      )}
    </TempMain>
  );
};

const useStyles = makeStyles((theme) => ({
  cover: {
    backgroundColor: "rgba(0,0,0,0.25)",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1101,
  },
  container: {
    width: "100%",
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      width: "55%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "50%",
    },
  },
  scanner: {
    height: "100dvh",
    position: "relative",
    "&>div": {
      position: "initial !important",
      paddingTop: "unset !important",
    },
    "& video": {
      objectFit: "cover",
    },
  },
  textScan: {
    position: "absolute",
    bottom: "20%",
    left: 24,
    right: 24,
  },
  contentLoading: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "min(80%, 414px)",
    zIndex: 2,
  },
  coverScan: {
    position: "absolute",
    zIndex: 1,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    "&>img": {
      objectFit: "cover",
    },
  },
  headerCover: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 48,
  },
}));

export default withRouter(memo(VoucherMerchantScanPage));
