/**
 * Auth
 */
export const SET_AUTH = "SET_AUTH";
export const LOG_IN = "LOG_IN";
export const LOG_OUT_PROPS = "LOG_OUT_PROPS";
export const LOG_OUT = "LOG_OUT";
export const REGISTER = "REGISTER";
export const SYAIACC = "SYAIACC";
export const CLEAR_REGISTER = "CLEAR_REGISTER";
export const VALIDATE_PIN_PROPS = "VALIDATE_PIN_PROPS";
export const CHANGE_PASS_PROPS = "CHANGE_PASS_PROPS";
export const REQUEST_OTP_PROPS = "REQUEST_OTP_PROPS";
export const RESEND_OTP_PROPS = "RESEND_OTP_PROPS";
export const VALIDATE_OTP_PROPS = "VALIDATE_OTP_PROPS";
export const CHANGE_PIN_PROPS = "CHANGE_PIN_PROPS";
export const FORGOT_PIN_PROPS = "FORGOT_PIN_PROPS";
export const FETCH_REGIS_VALIDATION = "FETCH_REGIS_VALIDATION";
export const FETCH_REGIS_REQUEST_OTP = "FETCH_REGIS_REQUEST_OTP";
export const FETCH_REGIS_VALIDATE_OTP = "FETCH_REGIS_VALIDATE_OTP";
export const FETCH_DATA_OCR = "FETCH_DATA_OCR";
export const FETCH_DATA_SID = "FETCH_DATA_SID";
export const FETCH_FORGOT_PASS = "FETCH_FORGOT_PASS";
export const FETCH_REGIS_1 = "FETCH_REGIS_IDENTITY";
export const FETCH_REGIS_2 = "FETCH_REGIS_PROFILE";
export const FETCH_REGIS_3 = "FETCH_REGIS_SIGNATURE";
export const FETCH_REGIS_4 = "FETCH_REGIS_PIN";
export const SET_TEMP_REGIS = "SET_TEMP_REGIS";
export const FETCH_RESEND_VERIF_EMAIL = "FETCH_RESEND_VERIF_EMAIL";
export const RE_REGIS = "RE_REGIS";
export const FETCH_RE_REGIS = "FETCH_RE_REGIS";

/**
 * loading state
 */
export const SET_LOADING_STACK = "SET_LOADING_STACK";
export const REMOVE_LOADING_STACK = "REMOVE_LOADING_STACK";
export const SET_RESULT_PROPS = "SET_RESULT_PROPS";
export const SET_RESULT = "SET_RESULT";
export const SET_RESULT_DATA = "SET_RESULT_DATA";
export const REMOVE_RESULT_L = "REMOVE_RESULT_L";
export const REMOVE_RESULT = "REMOVE_RESULT";
export const SET_TMP_KEY = 'SET_TMP_KEY'

/**
 * Product
 */
export const SET_PRODUCT = "SET_PRODUCT";
export const SET_TOP_PRODUCT = "SET_TOP_PRODUCT";
export const SET_PRODUCT_LIST = "SET_PRODUCT_LIST";
export const SET_TOP_PRODUCT_LIST = "SET_TOP_PRODUCT_LIST";
export const SET_TRENDING_PROPS = "SET_TRENDING_PROPS";
export const SET_TRENDING = "SET_TRENDING";
export const PRODUCT_TYPE_PROPS = "PRODUCT_TYPE_PROPS";
export const PRODUCT_TYPE = "PRODUCT_TYPE";
export const PRODUCT_HISTORY_PROPS = "PRODUCT_HISTORY_PROPS";
export const PRODUCT_HISTORY = "PRODUCT_HISTORY";
export const CLEAR_PRODUCT_HISTORY_PROPS = "CLEAR_PRODUCT_HISTORY_PROPS";
export const CLEAR_PRODUCT_HISTORY = "CLEAR_PRODUCT_HISTORY";

/**
 * Cart
 */
export const SET_CART = "SET_CART";

/**
 * Portofolio
 */
export const SET_PORTOFOLIO_LIST_PROPS = "SET_PORTOFOLIO_LIST_PROPS";
export const SET_PORTOFOLIO_LIST = "SET_PORTOFOLIO_LIST";
export const SET_PORTOFOLIO_SUMMARY_PROPS = "SET_PORTOFOLIO_SUMMARY_PROPS";
export const SET_PORTOFOLIO_SUMMARY = "SET_PORTOFOLIO_SUMMARY";
export const PORTO_DETAIL_PROPS = "PORTO_DETAIL_PROPS";
export const PORTO_DETAIL = "PORTO_DETAIL";
export const STATEMENT_LIST_PROPS = "STATEMENT_LIST_PROPS";
export const STATEMENT_PROPS = "STATEMENT_PROPS";
export const STATEMENT = "STATEMENT";
export const OPEN_STATEMENT = "OPEN_STATEMENT";

/**
 * Transaction
 */
export const SET_TXN_RD = "SET_TXN_RD";
export const SET_PAYMENT_LIST = "SET_PAYMENT_LIST";
export const SET_TRANSACTION = "SET_TRANSACTION";
export const SET_TRANSACTION_LIST = "SET_TRANSACTION_LIST";
export const SET_SUBSCRIPT = "SET_SUBSCRIPT";
export const SET_SUBSCRIPT_PROPS = "SET_SUBSCRIPT_PROPS";
export const SET_REDEEM_PROPS = "SET_REDEEM_PROPS";
export const SET_SWITCH_PROPS = "SET_SWITCH_PROPS";
export const SET_PAYMENT_PROPS = "SET_PAYMENT_PROPS";
export const SET_PAYMENT = "SET_PAYMENT";
export const GET_INVOICE_PROPS = "GET_INVOICE_PROPS";
export const GET_INVOICE = "GET_INVOICE";
export const SET_PROOF_PROPS = "SET_PROOF_PROPS";
export const SET_PROOF = "SET_PROOF";
export const GET_HISTORY_PROPS = "GET_HISTORY_PROPS";
export const GET_HISTORY = "GET_HISTORY";
export const CHECK_BONUS_PROPS = "CHECK_BONUS_PROPS";
export const CHECK_BONUS = "CHECK_BONUS";
export const ORDER_DATA = "ORDER_DATA";
export const CONFIRM_PAYMENT_DATA = "CONFIRM_PAYMENT_DATA";

/**
 * Profile
 */
export const SET_PROFILE = "SET_PROFILE";
export const SET_PROFILE_DATA = "SET_PROFILE_DATA";
export const SET_RISK_PROFILE_QUESTIONS = "SET_RISK_PROFILE_QUESTIONS";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const GET_ACC_BANK_PROPS = "GET_ACC_BANK_PROPS";
export const GET_ACC_BANK = "GET_ACC_BANK";
export const ADD_ACC_BANK_PROPS = "ADD_ACC_BANK_PROPS";
export const DEL_ACC_BANK_PROPS = "DEL_ACC_BANK_PROPS";
export const CHANGE_NUMBER_PROPS = "CHANGE_NUMBER_PROPS";
export const CHANGE_EMAIL_PROPS = "CHANGE_EMAIL_PROPS";
export const SET_PRIORITY_BANK_PROPS = "SET_PRIORITY_BANK_PROPS";
export const PROFILE_RISK_PROPS = "PROFILE_RISK_PROPS";
export const PROFILE_RISK = "PROFILE_RISK";
export const RISK_SURVEY_PROPS = "RISK_SURVEY_PROPS";
export const RISK_SURVEY = "RISK_SURVEY";
export const RISK_RESULT_PROPS = "RISK_RESULT_PROPS";
export const RISK_RESULT_BY_POINT_PROPS = "RISK_RESULT_BY_POINT_PROPS";
export const FETCH_UPDATE_PROFILE = "FETCH_UPDATE_PROFILE";
/**
 * Voucher
 */
export const SET_VOUCHER = "SET_VOUCHER";
export const SET_MYVOUCHER = "SET_MYVOUCHER";

/**
 * Article & Blog
 */
export const SET_ARTICLE = "SET_ARTICLE";
export const SET_ARTICLE_LIST = "SET_ARTICLE_LIST";
export const SET_PROMO = "SET_PROMO";
export const SET_PROMO_LIST = "SET_PROMO_LIST";
export const SET_ARTICLE_CONTENT = "SET_ARTICLE_CONTENT";
export const SET_ARTICLE_CONTENT_PROPS = "SET_ARTICLE_CONTENT_PROPS";

/**
 * Notification
 */
export const SET_NOTIF = "SET_NOTIF";
export const FETCH_NOTIF_LIST = "FETCH_NOTIF_LIST";

/**
 * dashboard
 */
export const SET_USER = "SET_USER";
export const DASH_LC = "DASH_LC";
/**
 * option
 */
export const SET_OPTION_PROPS = "SET_OPTION_PROPS";
export const GET_DATA_OPTION = "GET_DATA_OPTION";
export const SET_MENU_PROPS = "SET_MENU_PROPS";
export const SET_MENU = "SET_MENU";
export const SET_FAQ = "SET_FAQ";
export const SET_DATA_OPTION_CHILD = "SET_DATA_OPTION_CHILD";
export const SET_APP_CONFIG = "SET_APP_CONFIG";
export const SET_GOALS = "SET_GOALS";
export const CHART_COLOR_PROPS = "CHART_COLOR_PROPS";
export const CHART_COLOR = "CHART_COLOR";
export const HEADER_SEARCH_PROPS = "HEADER_SEARCH_PROPS";
export const HEADER_SEARCH = "HEADER_SEARCH";
export const SET_DATA_LOCATION = "SET_DATA_LOCATION";
export const CONTENT = "CONTENT";
export const FETCH_RATE_US = "FETCH_RATE_US";
export const FETCH_GET_RATE_US = "FETCH_GET_RATE_US";
export const FETCH_INS_BENEFIT = "FETCH_INS_BENEFIT";
export const GET_INSURANCE_INFO = "GET_INSURANCE_INFO";

/**
 * persist
 */

export const SET_PERSIST_REDUCER = "SET_PERSIST_REDUCER";
export const UPLOAD = "UPLOAD";
export const FETCH_RESTRICT_IMAGE = "FETCH_RESTRICT_IMAGE";

// global logic
export const GLOBAL_ACTION_LC = "GLOBAL_ACTION_LC";
export const GLOBAL_LIST_ACTION_LC = "GLOBAL_LIST_ACTION_LC";
export const ENCRYPT_DATA_LC = "ENCRYPT_DATA"

//goals
export const GOALS_RD = "GOALS_RD";

export const VOUCHER_LIST = "VOUCHER_LIST"
export const VOUCHER_DETAIL = "VOUCHER_DETAIL"
export const VOUCHER_CHECK = "VOUCHER_CHECK"