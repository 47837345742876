import ArrowForwardOutlined from "@material-ui/icons/ArrowForwardOutlined";
import { withStyles } from "@material-ui/styles";
import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import stateCallApi from "../../config/API/stateCallApi";
import { gbl_act_lc } from "../../config/redux/actions/state";
import { get_invoice_props } from "../../config/redux/actions/transaction";
import ButtonContained from "../atom/Button/ButtonContained";
import ContainerWithLoader from "../organism/Container/ContainerWithLoader";
import DetailTxn from "../organism/Txn/DetailTxn";
import TempMain from "../template/TempMain";
import Card from "@material-ui/core/Card";
import ChevronRightRounded from "@material-ui/icons/ChevronRightRounded";
import PopOver from "../atom/PopOver/PopOver";
import { Grid, useTheme } from "@material-ui/core";
import { memo } from "react";
import DetailBooking from "../organism/Txn/DetailBooking";
import color from "../../assets/theme/color";

const useStyles = (theme) => ({
  summaryTxn: {
    [theme.breakpoints.down("sm")]: {
      boxShadow: "unset !important",
      marginTop: -24,
      marginLeft: -24,
      marginRight: -24,
    },
  },
  buttonClose: {
    [theme.breakpoints.down(769)]: {
      display: "none",
    },
  },
  containPeriodInvest: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme.palette.secondary[6],
    padding: 16,
  },
  cardReject: {
    backgroundColor: theme.palette.secondary[4],
    padding: "15px 24px",
  },
});

const SummaryTxn = memo(({ summary }) => {
  const theme = useTheme();
  const { txn_type_code, payment, switch_product } = summary;
  const classTxt = "basic fs-14";

  if (txn_type_code === "R") {
    return (
      <div className="d-flex align-items-end">
        <div>
          <p className={`${classTxt} font-weight-bold`}>
            Rekening Penerima Dana
          </p>
          <p className={classTxt}>{payment?.bank.name}</p>
          <p className={classTxt}>
            NO REK{" "}
            <span className="font-weight-bold">{payment?.account_number}</span>
          </p>
        </div>
        <div className="d-flex ml-5">
          <p className="basic fs-12 gray-4">Penting</p>
          <PopOver style={{ width: 86, paddingLeft: 56, marginLeft: -56 }}>
            Dana kamu akan diterima selambat-lambatnya 7 hari kerja setelah
            transaksi selesai. Nama dan nomor rekening harus sama dengan nama
            dan nomor rekening di buku atau bukti rekening bank investor.
            Perbedaan nama dan nomor rekening dapat berdampak pada kegagalan
            transaksi penjualan. Segala risiko yang terjadi yang diakibatkan
            oleh perbedaan tersebut merupakan tanggung jawab investor.
          </PopOver>
        </div>
      </div>
    );
  }

  if (txn_type_code === "D") {
    const styleBox = {
      border: `1px solid ${theme.palette.secondary.main}`,
      padding: "6px 50px",
    };
    return (
      <>
        <p className={`${classTxt} font-weight-bold mb-3`}>
          Rekening Penerima Dana
        </p>
        <div className="d-flex mb-1">
          <div style={styleBox}>
            <p className={classTxt}>{switch_product.from}</p>
          </div>
          <ArrowForwardOutlined color="primary" className="my-auto mx-3" />
          <div style={styleBox}>
            <p className={classTxt}>{switch_product.to}</p>
          </div>
        </div>
      </>
    );
  }

  if (!summary.payment?.method) {
    return null;
  }
  return (
    <div className="d-flex align-items-center">
      <p className={`${classTxt} font-weight-bold`}>Metode Pembayaran</p>
      <img
        alt={summary.payment.method.name ?? ""}
        src={summary.payment.method.logo}
        height="35px"
        className="ml-4"
      />
    </div>
  );
});

class DetailTxnPage extends React.Component {
  constructor(props) {
    super(props);
    this.headerTitle = {
      S: "DETAIL TRANSAKSI PEMBELIAN",
      R: "DETAIL TRANSAKSI PENJUALAN",
      D: "DETAIL TRANSAKSI PENUKARAN",
    };

    this.state = {
      data: { data: [], summary: {} },
      dataBooking: {},
      id: null,
      withPeriodic: false,
    };
  }
  // summary.txn_type_code === "S"
  componentDidMount() {
    const { location, gbl_act_lc } = this.props;
    const { search } = location;
    const ids = new URLSearchParams(search).getAll("id");
    console.log("dev detailTxn", this.props, location.state === "booking");
    if (location.state === "booking") {
      gbl_act_lc({
        ...stateCallApi.booking_list,
        params: { "find[id]": ids },
        callback: (data) => this.setState({ dataBooking: data }),
        // validate: !Array.isArray(this.state.BOOKING),
      });
    } else {
      gbl_act_lc({
        ...stateCallApi.txn_list,
        params: { "query[id][]": ids },
        callback: (data) => {
          // const isWaiting = data.data
          //   .map(({ txn_status_code }) => txn_status_code)
          //   .filter((status) => status === "WAITING").length;
          const newState = { data };
          // withPeriodic
          // && Boolean(isWaiting)
          if (data.summary.txn_type_code === "S") {
            newState["withPeriodic"] = true;
          }
          if (
            data.summary.txn_type_code === "S" &&
            data.data.some(
              ({ txn_status_code }) => txn_status_code === "REJECT"
            )
          ) {
            newState["isRejectTxn"] = true;
          }
          this.setState(newState);
        },
        redux_action: undefined,
      });
    }
  }

  handleClose = () => this.props.history.replace("/transaksi");

  handlePeriodicInvest = () => {
    const { history } = this.props;
    const { data } = this.state;
    history.push("/invoice", { ...data, periodicState: true });
  };

  render() {
    const { isRejectTxn } = this.state;
    const { data, summary } = this.state.data || {};
    const { classes, location } = this.props;
    const isShowPeriodic = Boolean(summary.show_periodic);
    const isBookingDetail = location.state === "booking";
    console.log("detail txn", { data, summary, state: this.state });
    return (
      <TempMain
        title={
          isBookingDetail
            ? "DETAIL TRANSAKSI PEMESANAN"
            : data !== null
            ? this.headerTitle[summary?.txn_type_code]
            : ""
        }
        showBottom={false}
        bottomNavValue={2}
      >
        <div className="p-4">
          <ContainerWithLoader
            processName={["txn-list", "get_invoice"]}
            loadingHeight="calc(100vh - 72px - 67px)"
          >
            {isBookingDetail ? (
              ""
            ) : (
              <div
                className={classNames(
                  "card-custom py-3 mb-4",
                  classes.summaryTxn
                )}
              >
                <SummaryTxn summary={summary} />
              </div>
            )}

            <div>
              {data?.map((item) => (
                <DetailTxn data={item} key={item.id} className="mb-3" />
              ))}
              {Object.keys(this.state.dataBooking).length ? (
                <DetailBooking data={this.state.dataBooking} className="mb-3" />
              ) : (
                ""
              )}
            </div>
            {/* Content investasi berkala */}
            {isShowPeriodic && (
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Card
                    className={classNames(
                      classes.containPeriodInvest,
                      "is-link"
                    )}
                    onClick={this.handlePeriodicInvest}
                  >
                    <p className="basic font-weight-bold fs-14">
                      Jadikan Pembelian ini Investasi Berkala
                    </p>
                    <ChevronRightRounded color="primary" />
                  </Card>
                </Grid>
              </Grid>
            )}

            {isRejectTxn && (
              <Grid container className="mt-3">
                <Grid item xs={12} md={6}>
                  <div
                    className={classNames("card-custom", classes.cardReject)}
                  >
                    <Grid container alignItems="center">
                      <Grid item xs={4} className="text-center">
                        <p className="fs-14 color-white font-weight-bold mb-0">
                          PENTING!
                        </p>
                      </Grid>
                      <Grid item xs={8}>
                        <p className="mb-0 color-white fs-10">
                          Proses pengembalian dana ke rekening kamu akan
                          dilakukan pada hari kerja <b>maks 2x24jam</b>
                        </p>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            )}

            {isBookingDetail && (
              <Grid container className="mb-3">
                <Grid item xs={6}>
                  <div
                    className="d-flex"
                    style={{
                      backgroundColor: color.gray_4,
                      borderRadius: 4,
                      padding: 16,
                    }}
                  >
                    <p className="basic fs-14 color-white w-90 m-auto text-center fw-700">
                      PENTING!
                    </p>
                    <p className="basic fs-10 color-white">
                      Kamu sudah berhasil melakukan proses booking (pemesanan),
                      klik menu Transaksi untuk melihat Detail Transaksi
                      Pemesanan-mu!
                    </p>
                  </div>
                </Grid>
              </Grid>
            )}

            <ButtonContained
              id="button-close"
              text="TUTUP"
              radius="5px"
              className={classNames(
                "default-py-button px-5 mt-4 mb-5 fs-12 ml-2 fs-14 font-weight-semibold",
                classes.buttonClose
              )}
              onClick={this.handleClose}
            />
          </ContainerWithLoader>
        </div>
      </TempMain>
    );
  }
}

const mapState = (state) => ({ invoice: state.transactionReducer.invoice });

export default compose(
  connect(mapState, { get_invoice_props, gbl_act_lc }),
  withRouter,
  withStyles(useStyles)
)(DetailTxnPage);
