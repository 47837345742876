import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  root: {},
  areaInvest: {
    paddingLeft: 14,
    display: "flex",
    alignItems: "center",
    height: "30px",
    backgroundColor: "#999999",
    color: "white",
  },
}));
