import * as types from "../actions/types";
import { createLogic } from "redux-logic";
import { requestAxios, requestPostAxios } from "../../API/request";
import {
  get_acc_bank,
  set_profile_data,
  profile_risk,
  risk_survey,
} from "../actions/profile";
import url from "../../API/url";
import { set_loading, remove_loading, set_result } from "../actions/state";
import { set_auth } from "../actions/auth";
import { batch } from "react-redux";
import { handleErrorGlobal } from "./stateLogic";

export const getAccBank = createLogic({
  type: types.GET_ACC_BANK_PROPS,
  latest: true,
  async process({ action }, dispatch, done) {
    dispatch(set_loading("get_acc_bank"));
    await requestAxios(url.PROFILE.user_bank.get)
      .then((e) => {
        console.log(e);
        let tmp = [];
        Object.keys(e.data).forEach((el) => {
          tmp.push(e.data[el]);
        });
        dispatch(get_acc_bank(tmp));
      })
      .catch((err) => {
        let error = {
          name: "get_acc_bank",
          data: { type: "error", msg: err.message },
        };
        dispatch(set_result(error));
      })
      .then(() => {
        dispatch(remove_loading("get_acc_bank"));
        done();
      });
  },
});

export const setProfile = createLogic({
  type: types.SET_PROFILE,
  latest: true,
  async process({ action }, dispatch, done) {
    const { pin, callback } = action.payload || {};
    dispatch(set_loading("set_profile"));
    const request = pin ? requestPostAxios : requestAxios;
    await request(url.PROFILE.user_profile, pin && { pin })
      .then((e) => {
        const { data } = e;
        const {
          user_data_individu,
          email,
          phone_number,
          sid,
          has_filled_mother_name,
        } = data;
        dispatch(
          set_profile_data({
            sid,
            profile: { ...user_data_individu, email, phone_number },
            bank_account: user_data_individu.user_account,
            updateMotherNameStatus: !has_filled_mother_name,
          })
        );
        if (typeof callback === "function") {
          callback(data);
        }
      })
      .catch((err) => {
        let error = {
          name: "set_profile",
          data: { type: "error", msg: err.message },
        };
        dispatch(set_result(error));
      })
      .then(() => {
        dispatch(remove_loading("set_profile"));
        done();
      });
  },
});

export const addAccBank = createLogic({
  type: types.ADD_ACC_BANK_PROPS,
  latest: true,
  process({ action }, dispatch, done) {
    console.log(action.payload);
    dispatch(set_loading("add_acc_bank"));
    requestPostAxios(url.PROFILE.user_bank.add, action.payload)
      .then((e) => {
        console.log(e);
        const success = {
          name: "add_acc_bank",
          data: { type: "success", msg: e.message },
        };
        dispatch(set_result(success));
      })
      .catch((err) => {
        const error = {
          name: "add_acc_bank",
          data: { type: "error", msg: err.message },
        };
        dispatch(set_result(error));
      })
      .then(() => {
        dispatch(remove_loading("add_acc_bank"));
        done();
      });
  },
});

export const delAccBank = createLogic({
  type: types.DEL_ACC_BANK_PROPS,
  latest: true,
  process({ action }, dispatch, done) {
    dispatch(set_loading("del_acc_bank"));
    const data = {
      id: action.payload,
    };
    requestPostAxios(url.PROFILE.user_bank.delete, data)
      .then((res) => {
        const success = {
          name: "del_acc_bank",
          data: { type: "success", msg: res.message },
        };
        dispatch(set_result(success));
      })
      .catch((err) => {
        const error = {
          name: "del_acc_bank",
          data: { type: "error", msg: err.message },
        };
        dispatch(set_result(error));
      })
      .then(() => {
        dispatch(remove_loading("del_acc_bank"));
        done();
      });
  },
});

export const changeNumber = createLogic({
  type: types.CHANGE_NUMBER_PROPS,
  latest: true,
  process({ action }, dispatch, done) {
    dispatch(set_loading("change_number"));
    requestPostAxios(url.PROFILE.change_phone, action.payload)
      .then((e) => {
        console.log(e);
        dispatch(set_auth({ otp_token: e.data.otp_token }));
        const success = {
          name: "change_number",
          data: { type: "success", msg: e.message },
        };
        dispatch(set_result(success));
      })
      .catch((err) => {
        const error = {
          name: "change_number",
          data: { type: "error", msg: err.message },
        };
        dispatch(set_result(error));
      })
      .then(() => {
        dispatch(remove_loading("change_number"));
        done();
      });
  },
});

export const changeEmail = createLogic({
  type: types.CHANGE_EMAIL_PROPS,
  latest: true,
  process({ action }, dispatch, done) {
    dispatch(set_loading("change_email"));
    requestPostAxios(url.PROFILE.change_email, action.payload)
      .then((e) => {
        const success = {
          name: "change_email",
          data: { type: "success", msg: e.message },
        };
        dispatch(set_result(success));
      })
      .catch((err) => {
        const error = {
          name: "change_email",
          data: { type: "error", msg: err.message },
        };
        dispatch(set_result(error));
      })
      .then(() => {
        dispatch(remove_loading("change_email"));
        done();
      });
  },
});

export const setPriorityBank = createLogic({
  type: types.SET_PRIORITY_BANK_PROPS,
  latest: true,
  process({ action }, dispatch, done) {
    dispatch(set_loading("set_priority_bank"));
    requestPostAxios(url.PROFILE.user_bank.update_priority, action.payload)
      .then((res) => {
        const success = {
          name: "set_priority_bank",
          data: { type: "success", msg: "Berhasil dijadikan utama" },
        };
        dispatch(set_result(success));
        dispatch(
          set_profile_data({
            bank_account: res.data,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        const error = {
          name: "set_priority_bank",
          data: { type: "error", msg: err.message },
        };
        dispatch(set_result(error));
      })
      .then(() => {
        dispatch(remove_loading("set_priority_bank"));
        done();
      });
  },
});

export const profileRisk = createLogic({
  type: types.PROFILE_RISK_PROPS,
  latest: true,
  process({ action }, dispatch, done) {
    dispatch(set_loading("profile_risk"));
    requestAxios(url.PROFILE.profile_risk.get)
      .then((e) => {
        console.log(e);
        dispatch(profile_risk(e.data));
      })
      .catch((err) => {
        handleErrorGlobal(err, undefined, dispatch, "profile_risk");
      })
      .then(() => {
        dispatch(remove_loading("profile_risk"));
        done();
      });
  },
});

export const riskSurvey = createLogic({
  type: types.RISK_SURVEY_PROPS,
  latest: true,
  process({ action }, dispatch, done) {
    dispatch(set_loading("risk_survey"));
    requestAxios(url.PROFILE.profile_risk.get_survey)
      .then((e) => {
        console.log(e);
        dispatch(risk_survey(e.data));
      })
      .catch((err) => {
        console.log(err);
        const error = {
          name: "risk_survey",
          data: { type: "error", msg: err.message },
        };
        dispatch(set_result(error));
      })
      .then(() => {
        dispatch(remove_loading("risk_survey"));
        done();
      });
  },
});

export const riskResult = createLogic({
  type: types.RISK_RESULT_PROPS,
  latest: true,
  process({ action }, dispatch, done) {
    const { point, mode } = action.payload;
    const modeUpdate = mode === "save_risk_result";
    dispatch(set_loading(mode));
    let _url = "";
    let data = {};
    if (mode === "risk_result") {
      _url = url.PROFILE.profile_risk.result;
      data = { ...point };
    } else if (modeUpdate) {
      _url = url.PROFILE.profile_risk.get;
      data = { profile_risk_point: point };
    }
    requestPostAxios(_url, data)
      .then((e) => {
        console.log(e);
        if (mode === "risk_result" || modeUpdate) {
          dispatch(profile_risk(e.data));
        }
        let success = {
          name: mode,
          data: {
            type: "success",
            msg: modeUpdate ? "Profile Risk Berhasil di Simpan" : e.message,
          },
        };
        dispatch(set_result(success));
      })
      .catch((err) => {
        console.log(err);
        let error = {
          name: mode,
          data: { type: "error", msg: err.message },
        };
        dispatch(set_result(error));
      })
      .then(() => {
        dispatch(remove_loading(mode));
        done();
      });
  },
});

export const riskResultByPoint = createLogic({
  type: types.RISK_RESULT_BY_POINT_PROPS,
  latest: true,
  debounce: 700,
  validate({ action }, allow, reject) {
    let value = action.payload;
    if (value && value > 1) {
      value = value < 3 ? 3 : value;
      action.payload = { profile_risk_point: value };
      allow(action);
    } else reject(action);
  },
  process({ action }, dispatch, done) {
    const name = "risk-by-point";
    dispatch(set_loading(name));
    requestPostAxios(url.PROFILE.profile_risk.result_by_point, action.payload)
      .then((res) => {
        batch(() => {
          // dispatch(profile_risk(res.data));
          dispatch(
            set_result({ name, data: { type: "success", data: res.data } })
          );
          dispatch(remove_loading(name));
        });
      })
      .catch((err) => {
        batch(() => {
          dispatch(remove_loading(name));
          dispatch(
            set_result({ name, data: { type: "error", msg: err.message } })
          );
        });
      })
      .then(done);
  },
});

export const update_profile = createLogic({
  type: types.FETCH_UPDATE_PROFILE,
  latest: true,
  process({ action }, dispatch, done) {
    const name = "update-profile";
    dispatch(set_loading(name));
    requestPostAxios(url.PROFILE.update_profile, action.payload)
      .then((res) => {
        batch(() => {
          dispatch(remove_loading(name));
          dispatch(
            set_result({ name, data: { type: "success", msg: res.message } })
          );
        });
      })
      .catch((err) => {
        batch(() => {
          dispatch(remove_loading(name));
          dispatch(
            set_result({ name, data: { type: "error", msg: err.message } })
          );
        });
      })
      .then(done);
  },
});
