import React from "react";
import { makeStyles } from "@material-ui/core";
import color from "../../../assets/theme/color";

import BasicText from "../../atom/BasicText/BasicText";
import textHelper from "../../../helper/textHelper";
import MenuProfileIcon from "../../../assets/img/icon/profile/MenuProfileIcon";

const useStyles = makeStyles({
  contain: {
    display: "flex",
    borderBottom: "1px solid " + color.gray_6,
    marginBottom: 18,
    paddingBottom: 13,
  },
});

const CardNotif = ({ item }) => {
  const S = useStyles();
  return (
    <div className={S.contain}>
      <MenuProfileIcon
        icon={
          item?.notif_type_code.search("success") >= 0
            ? "notifSuccess"
            : "notifInfo"
        }
        viewBox="0 0 24.2 24.2"
      />
      <div className="mr-4 pr-3 ml-3">
        <BasicText className="fs-12 mb-1" colorLevel="light">
          {textHelper.getTglNotif(item.created_at)}
        </BasicText>
        <BasicText className="fs-14">{item.content}</BasicText>
      </div>
    </div>
  );
};

CardNotif.defaultProps = {
  item: {},
};

export default CardNotif;
