import classNames from "classnames";
import React from "react";
import ButtonContained from "../../../../atom/Button/ButtonContained";
import ButtonLink2 from "../../../../atom/Button/ButtonLink2";
import textHelper from "../../../../../helper/textHelper";
import "../styles.css";
const { useState, useCallback } = React;

const BASE_TITLE = ["nama portofolio", "perkiraan Keperluan Biaya"];

const CardGoalsPlanner = ({ item, className, onClick, style }) => {
  const { key, target_amount, name, goal, ref_goals_icon } = item;
  const [state, setState] = useState({
    name,
    target_amount,
  });

  const handleClick = useCallback(
    (append) => onClick(key, append, state),
    [key, onClick, state]
  );

  const handleChange = useCallback(({ target }, key) => {
    let val = target.value;
    if (key === "target_amount") {
      val = val.replace(/[^\d]/g, "");
    }
    setState((prev) => ({ ...prev, [key]: val }));
  }, []);
  return (
    <div
      className={classNames(
        className,
        "temp-card d-flex flex-column list-content planner"
      )}
      style={style}
    >
      <div className="d-flex align-items-start">
        <img
          src={
            ref_goals_icon?.icon_web_path ||
            ref_goals_icon?.icon_path ||
            goal.icon_web
          }
          alt=" "
          onDragStart={(e) => e.preventDefault()}
        />
        <div className="flex mb-1">
          <div className="d-flex justify-content-between align-items-center contain-name pb-1 mb-2">
            <p className="basic fs-14 gray-4 font-weight-medium text-uppercase">
              {goal.name}
            </p>
            <ButtonLink2
              id={`ubah-${goal.goal_code}`}
              text="UBAH"
              className="basic fs-10"
              onClick={() => handleClick("change")}
            />
          </div>
          {Object.keys(state).map((key, index) => (
            <div key={key} className={`planner-input ${index && "mt-1"}`}>
              <label className="d-block">{BASE_TITLE[index]}</label>
              <input
                id={`${goal.goal_code}-${key}-input`}
                value={
                  key === "target_amount"
                    ? textHelper.printMoney(state[key])
                    : state[key]
                }
                onChange={(e) => handleChange(e, key)}
              />
            </div>
          ))}
        </div>
      </div>
      <ButtonContained
        id={`${goal.goal_code}-plan-btn`}
        text="RENCANAKAN"
        radius="4px"
        onClick={() => handleClick("run")}
        className="align-self-center mt-2"
        classes={{ label: "font-weight-bold fs-10" }}
      />
    </div>
  );
};

CardGoalsPlanner.defaultProps = {
  item: {},
};

export default CardGoalsPlanner;
