import { CircularProgress } from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ic_promo } from "../../../assets/icons";
import { get_vouchers } from "../../../config/redux/actions/voucher";
import { getLoading } from "../../../config/redux/selector";
import useDataResult from "../../../hooks/useDataResult";
import ContainerWithLoader from "../../organism/Container/ContainerWithLoader";
import TempMain from "../../template/TempMain";
import useDetailPromo from "../PromoDetailPage/useDetailPromo";
import "./styles.css";
import useSubsWithPromo from "./useSubsWithPromo";

const PromoPage = () => {
  const name = "get_voucher_list";
  const dispatch = useDispatch();
  const {
    push,
    location: { state },
  } = useHistory();
  const { path } = useRouteMatch();
  const result = useDataResult(name);
  const createState = useSubsWithPromo();

  const goTo = useCallback(
    (action, { id, code, product_code, min_sub }) => {
      switch (action) {
        case "detail":
          push(`${path}/${code}`, { id, ...state });
          break;
        case "subs":
          push("/subscript", createState(id, product_code, min_sub));
          break;
        default:
          break;
      }
    },
    [createState, path, push, state]
  );

  useEffect(() => {
    dispatch(
      get_vouchers(
        state?.product_code && { params: { product_code: state?.product_code } }
      )
    );
  }, [dispatch, state]);

  return (
    <TempMain
      title="PROFIL"
      backgroundColor="white"
      showBottom={false}
      bottomNavValue={4}
      page="profile"
      data={{ menuValue: 4 }}
    >
      <p className="fs-14 font-weight-bold color-primary mb-4 pb-1">
        KODE PROMO
      </p>
      <ContainerWithLoader processName={name} loadingHeight="100%">
        {Array.isArray(result) && !!result.length ? (
          result.map((item, index) => (
            <div key={item.id} className="row row-cols-1 row-cols-lg-2">
              <div className="col pb-4" key={index}>
                <CardPromo item={item} key={item.code} onClick={goTo} />
              </div>
            </div>
          ))
        ) : (
          <div className="cont-center h-100 w-100 gray-4">
            voucher tidak tersedia
          </div>
        )}
      </ContainerWithLoader>
    </TempMain>
  );
};

const CardPromo = memo(
  ({ item: { name, voucher_flag, fr_end_date, id } = {}, item, onClick }) => {
    const { name: nameAction, data, getData } = useDetailPromo(id);
    const loading = useSelector(getLoading(nameAction));
    const goToDetail = () => onClick?.("detail", item);

    useEffect(() => {
      if (data) {
        onClick("subs", data);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
      <div className="border border-1 rounded-3 is-link">
        <div
          className="d-flex align-items-start card-body p-3"
          onClick={goToDetail}
        >
          <img src={ic_promo} alt="" sizes="32" className="mt-2" />
          <div
            type={voucher_flag}
            className="flex-1 ml-n1 pl-3 color-secondary testingbefore card-content"
          >
            <h6 className="font-weight-bold pt10">{name}</h6>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between p-3 px-4 fs-12 font-weight-medium gray-4">
          Exp {fr_end_date}
          <span
            className="color-primary font-weight-bold text-decoration-none is-link"
            onClick={getData}
          >
            {loading ? <CircularProgress size={20} /> : "Gunakan Sekarang"}
          </span>
        </div>
      </div>
    );
  },
  ({ code }) => code
);

export default memo(PromoPage);
