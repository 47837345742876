import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { Close, Search } from "@material-ui/icons";
import logo from "../../../assets/img/logo";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import PropTypes from "prop-types";
import BasicText from "../../atom/BasicText/BasicText";
import { withRouter } from "react-router-dom";
import Searchbox from "../MaterialInput/Searchbox";
import { connect } from "react-redux";
import { compose } from "redux";
import { header_search_props } from "../../../config/redux/actions/option";
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  header: {
    backgroundColor: theme.palette.primary.dark
  }
}));

const MainHeader = props => {
  const [state, setState] = React.useState({
    mode: "normal"
  });
  const { mode } = state;
  const classes = useStyles();
  const {
    title,
    goBack,
    history,
    isCloseButton,
    isRightSearch,
    functionBack
  } = props;
  const linkTo = () => {
    history.goBack();
  };
  function _goToHelp() {
    history.push("/help-center");
  }
  const handleMode = e => {
    setState(state => ({ ...state, mode: e }));
    props.header_search_props({ mode: "mode", value: e });
    if (e === "normal") {
      handleSearch("");
    }
  };
  const handleSearch = e => {
    props.header_search_props({ mode: "search", value: e });
  };
  const ButtonBack = isCloseButton ? Close : ChevronLeftIcon;
  const RightIcon = isRightSearch ? Search : HelpOutlineIcon;
  return (
    <div className={classes.root}>
      <AppBar color="primary">
        <Toolbar className="w-100 container-body px-1">
          {mode === "normal" ? (
            <Grid container className="w-100" justify="space-between">
              <Grid item className="my-auto pl-1">
                {goBack && (
                  <ButtonBack
                    className="text-white fs-32"
                    onClick={functionBack || linkTo}
                  />
                )}
              </Grid>
              <Grid item className="my-auto text-center">
                {title ? (
                  <BasicText className="fs-14 font-weight-bold text-white">
                    {title}
                  </BasicText>
                ) : (
                  <img
                    src={logo.logoOnlyInverse}
                    alt=""
                    style={{ height: "20px" }}
                  />
                )}
              </Grid>
              <Grid item className="my-auto text-right pr-1">
                <RightIcon
                  className="text-white fs-26"
                  onClick={
                    isRightSearch ? () => handleMode("search") : _goToHelp
                  }
                />
              </Grid>
            </Grid>
          ) : (
            <div className="w-100 px-4">
              <Searchbox onClear={handleMode} onChange={handleSearch} />
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

MainHeader.propTypes = {
  title: PropTypes.string,
  goBack: PropTypes.bool
};

MainHeader.defaultProps = {
  goBack: true
};

export default compose(
  withRouter,
  connect(null, { header_search_props })
)(MainHeader);
