import { Grid } from "@material-ui/core";
import ArrowDownwardOutlinedIcon from "@material-ui/icons/ArrowDownwardOutlined";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import parse from "html-react-parser";
import React from "react";
import textHelper from "../../../helper/textHelper";
import BasicText from "../../atom/BasicText/BasicText";
import HorizontalStepper from "../../molecule/Stepper/HorizontalStepper";
import VerticalStepper from "../../molecule/Stepper/VerticalStepper";

const useStyles = makeStyles((theme) => ({
  stepperBorder: {
    paddingBottom: "22px",
    "& .vertical": {
      [theme.breakpoints.up(769)]: {
        display: "none",
      },
    },
    "& .horizontal": {
      [theme.breakpoints.down(769)]: {
        display: "none",
      },
    },
    [theme.breakpoints.up(768)]: {
      backgroundColor: theme.palette.common.white,
    },
    // marginBottom: "23px"
    borderBottom: `2px dotted ${theme.palette.secondary[3]}`,
  },
  boxDetail: {
    [theme.breakpoints.up(768)]: {
      backgroundColor: theme.palette.common.white,
    },
  },
  continerArrow: {
    display: "grid",
    placeItems: "center",
    width: "100%",
    backgroundColor: theme.palette.secondary[8],
    marginTop: 30,
    padding: "4px 0",
  },
}));

const BoxDetail = ({ className, data, dataKey, type_code }) => {
  const {
    product,
    fr_transaction_name_retail,
    fr_amount,
    frr_nav_settle,
    frr_units,
  } = data;
  return (
    <div className="mt-4">
      <div className="w-fit mb-2 pb-1">
        <p className="basic fs-12 font-weight-semibold">
          {parse(textHelper.printProduct2Line(product.fr_product_name))}
        </p>
      </div>
      <Grid container>
        <Grid item xs={12} md={6}>
          <div className="d-flex justify-content-between w-100">
            <div className="d-flex flex-column justify-content-between">
              <BasicText className="fs-12 my-auto" color="secondary">
                Total {fr_transaction_name_retail}
              </BasicText>
              <BasicText className="fs-12 my-auto" color="secondary">
                NAV
              </BasicText>
              <BasicText className="fs-12 my-auto" color="secondary">
                Jumlah Unit
              </BasicText>
            </div>
            <div className="ml-md-3 d-flex flex-column justify-content-between align-items-end">
              <p className="basic fs-14 my-2 font-weight-bold color-primary">
                {fr_amount}
              </p>
              <p className="basic fs-14 my-2 font-weight-bold color-primary">
                {frr_nav_settle}
              </p>
              <p className="fs-14 my-2 font-weight-bold basic color-primary">
                {frr_units}
              </p>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const DetailTxn = (props) => {
  const { data, className } = props;
  const classes = useStyles();
  const [state, setState] = React.useState({
    step: 0,
    type_code: "S",
  });

  React.useEffect(() => {
    if (data !== null) {
      const { txn_status_code, txn_type_code } = data;
      if (txn_status_code === "WAITING") {
        setState((state) => ({ ...state, step: 0 }));
      } else if (txn_status_code === "PROCESSED") {
        setState((state) => ({ ...state, step: 1 }));
      } else if (
        txn_status_code === "APPROVED" ||
        txn_status_code === "DECLINED"
      ) {
        setState((state) => ({ ...state, step: 2 }));
      }
      setState((state) => ({ ...state, type_code: txn_type_code }));
    }
  }, [data]);

  const { step, type_code } = state;
  const stepslabel = textHelper.txnProgress(type_code, data);
  const { txn_reff, fr_traded_at_full, fr_amount } = data;
  return (
    <>
      <Grid className="card-custom mb-3" container>
        <Grid item xs={12} md={6}>
          <p className="basic fs-12 mb-1">
            Nomor Transaksi: <b>{txn_reff}</b>
          </p>
          <div className="d-flex justify-content-between">
            <p className="basic fs-10 gray-4">{fr_traded_at_full}</p>
            <p className="basic fs-12 color-primary">{fr_amount}</p>
          </div>
        </Grid>
      </Grid>

      <div className={classNames(className, "card-custom")}>
        <div
          className={classNames(
            "p-3",
            classes.stepperBorder,
            type_code === "D" && "mt-md-3 mt-0"
          )}
        >
          <div className="vertical">
            <VerticalStepper
              step={step}
              text={stepslabel.label}
              subText={stepslabel.subLabel}
            />
          </div>
          <div className="horizontal">
            <HorizontalStepper
              step={step}
              text={stepslabel.label}
              subText={stepslabel.subLabel}
            />
          </div>
        </div>

        {data !== null && type_code !== "D" ? (
          <BoxDetail
            className="p-3"
            data={data}
            dataKey="product"
            type_code={type_code}
          />
        ) : (
          <div>
            <BoxDetail
              data={data}
              className="p-3"
              dataKey="product"
              type_code={type_code}
            />
            <Grid container>
              <Grid item xs={12} md={6}>
                <div className={classes.continerArrow}>
                  <ArrowDownwardOutlinedIcon fontSize="small" color="primary" />
                </div>
              </Grid>
            </Grid>

            <BoxDetail
              data={data?.destination_txn}
              className="p-3"
              dataKey="product_to"
              type_code={type_code}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default DetailTxn;
