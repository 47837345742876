import React from "react";
import BasicHeader from "../../molecule/Header/BasicHeader";
import LoginIlustrate from "../../molecule/Ilustration/LoginIlustrate";

const TempLoginMobile = (props) => {
  const { children, data } = props;
  const { headerText, title } = data;
  return (
    <div className="container-body px-4">
      <BasicHeader title={headerText} />
      <LoginIlustrate title={title} />
      {children}
    </div>
  );
};

TempLoginMobile.defaultProps = {
  data: {},
};

export default TempLoginMobile;
