import React from "react";
import TempMain from "../TempMain";
import IlustrasiRegis1 from "../../molecule/Register/IlustrasiRegis1";
import { makeStyles } from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles({
  contain: {
    display: "flex",
    flexDirection: "column",
    height: "82vh",
  },
});

const TempRegis1Mobile = ({ children, data }) => {
  const { title, ilustration } = data || {};
  const S = useStyles();
  return (
    <TempMain
      title={title || "REGISTRASI"}
      backgroundColor="white"
      showBottom={false}
    >
      <div className={classNames("mx-4 mt-3", S.contain)}>
        <IlustrasiRegis1 className="mb-4" {...ilustration} />
        {children}
      </div>
    </TempMain>
  );
};

TempRegis1Mobile.defaultProps = {
  data: {},
};

export default TempRegis1Mobile;
