import Grid from "@material-ui/core/Grid";
import { Form, Formik } from "formik";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { object } from "yup";
import ButtonContained from "../../atom/Button/ButtonContained";
import RenderInputRegister from "./RenderInputRegister";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  button: {
    alignSelf: "center",
    marginTop: 48,
    width: "26.4%",
    padding: "14px 0",
    borderRadius: "6px !important",
  },
});

const FormRegister = forwardRef(
  (
    {
      initial,
      onSubmit,
      validation,
      data,
      functionTrigger,
      disabledInputs,
      loadingSubmit,
      withButtonSubmit,
      idSubmit,
      textSubmit,
      submitClass,
      className,
      children,
      submitOnEnter,
      defaultInputProps,
    },
    ref
  ) => {
    const S = useStyles();
    const formikRef = useRef();
    const isDesktop = useMediaQuery({ minWidth: 769 });
    useImperativeHandle(ref, () => {
      const {
        handleSubmit,
        setValues,
        setTouched,
        setFieldValue,
        setFieldTouched,
        validateForm,
      } = formikRef.current;
      return {
        handleSubmit,
        setFieldValue,
        getValues,
        setValues,
        validateForm,
        setFieldTouched,
        setTouched,
      };
    });
    function getValues() {
      return formikRef.current.values;
    }
    function _handleSubmit(data) {
      onSubmit(data);
    }
    return (
      <Formik
        innerRef={formikRef}
        initialValues={initial}
        onSubmit={_handleSubmit}
        validationSchema={validation}
      >
        {({ handleSubmit, errors }) => {
          const disabledButton = Object.keys(errors).length ? true : false;
          return (
            <Form className={classNames("d-flex flex-column", className)}>
              <>
                {data.map((item, index) => {
                  if (Array.isArray(item)) {
                    return (
                      <Grid
                        key={index}
                        container
                        alignItems="stretch"
                        className="my-0"
                        spacing={isDesktop ? 3 : 0}
                      >
                        {item.map((it, i) => {
                          const disabled =
                            disabledInputs.indexOf(it?.name) > -1;
                          return (
                            <RenderInputRegister
                              key={i}
                              item={{ disabled, ...defaultInputProps, ...it }}
                              containProps={{ xs: 6 }}
                              onTrigger={functionTrigger}
                            />
                          );
                        })}
                      </Grid>
                    );
                  } else if (typeof item === "object") {
                    const disabled = disabledInputs.indexOf(item?.name) > -1;
                    return (
                      <Grid style={{ padding: "12px 0" }} key={index}>
                        <RenderInputRegister
                          item={{ disabled, ...defaultInputProps, ...item }}
                          onTrigger={functionTrigger}
                        />
                      </Grid>
                    );
                  } else return null;
                })}
              </>
              {withButtonSubmit && (
                <ButtonContained
                  id={`submit-${idSubmit}`}
                  text={textSubmit}
                  onClick={handleSubmit}
                  disabled={disabledButton}
                  className={classNames(S.button, submitClass)}
                  radius="4px"
                  type={submitOnEnter && "submit"}
                  loading={loadingSubmit}
                />
              )}
              {children}
            </Form>
          );
        }}
      </Formik>
    );
  }
);

FormRegister.defaultProps = {
  data: [],
  initial: {},
  onSubmit: () => {},
  validation: object().shape({}),
  disabledInputs: [],
  withButtonSubmit: true,
  textSubmit: "LANJUT",
};

export default FormRegister;
