import DateFnsUtils from "@date-io/date-fns";
import { Card, Grid } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import color from "../../../assets/theme/color";
import stateCallApi from "../../../config/API/stateCallApi";
import { gbl_act_lc } from "../../../config/redux/actions/state";
import { simulate } from "../../../helper/calculateSimulasi";
import processHelper from "../../../helper/processHelper";
import textHelper from "../../../helper/textHelper";
import LabelInput from "../../atom/BasicText/LabelInput";
import ButtonContained from "../../atom/Button/ButtonContained";
import DialogAlert from "../../molecule/Alert/DialogAlert";
import InputGlobal from "../../molecule/MaterialInput/InputGlobal/InputGlobal";
import SliderWithInput from "../../molecule/MaterialInput/SliderWithInput";
import TempMain from "../../template/TempMain";
import AddPlannerRiskPage from "../AddPlannerRiskPage";
import useStyles from "./useStyles";

const TargetDateInput = ({
  register,
  name,
  setValue,
  value,
  ...otherProps
}) => {
  const [state, setState] = useState(
    () => new Date(new Date().setFullYear(new Date().getFullYear() + 1))
  );
  const [once, setonce] = useState(true);

  const handleChange = useCallback(
    (val) => {
      setValue(name, textHelper.printDate(val, "MM/YYYY"));
      setState(val);
    },
    [name, setValue]
  );

  useEffect(() => {
    register(name);
    if (once) {
      if (typeof value === "string") {
        const res = value.split("/");
        handleChange(new Date(res[1], res[0] - 1));
      } else
        handleChange(
          value ||
            new Date(new Date().setFullYear(new Date().getFullYear() + 1))
        );
      setonce(false);
    }
  }, [handleChange, name, once, register, state, value]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        className="w-100"
        onChange={handleChange}
        format="MM/yyyy"
        minDate={new Date()}
        value={state}
        inputProps={{ className: "border-0", ...otherProps }}
        InputProps={{
          disableUnderline: true,
        }}
        views={["year", "month"]}
      />
    </MuiPickersUtilsProvider>
  );
};

const AddPlannerPage = ({ type = "planner" }) => {
  const { push, location, goBack } = useHistory();
  const {
    state: { id, ref_goals_icon, ...otherState },
  } = location;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [img, setimg] = useState(null);
  const [firstAmount, setfirstAmount] = useState(100000);
  const [investMonth, setinvestMonth] = useState(100000);
  const [robo, setrobo] = useState({});
  const [icon, seticon] = useState(null);
  const [valueSlider, setvalueSlider] = useState(10000000);
  const { getMMYYYY, printDateFormat } = textHelper;
  const loading = useSelector((state) =>
    processHelper.findProcess("add-planner", state.stateReducer.loading_stack)
  );
  const {
    register,
    errors,
    handleSubmit,
    setValue,
    getValues,
    setError,
    watch,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      target_date: printDateFormat(new Date(), "MM/YYYY"),
    },
    criteriaMode: "firstError",
    shouldFocusError: true,
  });

  const plannerSubmit = useCallback(
    (e) => {
      const { state } = location;
      const isRobo =
        robo.is_follow_robo === 1
          ? { first_amount: firstAmount, monthly_amount: investMonth }
          : {};
      let params = {
        ...e,
        goal_id: state.id,
        ...robo,
        ...isRobo,
      };
      dispatch(
        gbl_act_lc({
          ...stateCallApi.planner_add_single,
          params,
          callback: (data) => {
            if (robo.is_follow_robo === 0) {
              push("/portofolio-goals?goals=" + data.id);
            } else push("/");
          },
        })
      );
    },
    [dispatch, firstAmount, investMonth, location, push, robo]
  );
  const goalSubmit = useCallback(
    (e) => {
      const param = {
        id,
        goal_name: e.name,
        target_amount: e.target_amount,
        target_date:
          typeof e.target_date === "string"
            ? e.target_date
            : getMMYYYY(e.target_date),
        ref_goals_icon_id: ref_goals_icon.id,
      };

      if (icon) {
        push("/modify-goal-icon", { ...location.state, ...e });
      } else {
        dispatch(
          gbl_act_lc({
            ...stateCallApi.goals_user_update,
            appear: true,
            msg: "Sukses update goal",
            params: { ...param },
          })
        );
      }
    },
    [dispatch, getMMYYYY, icon, id, location.state, push, ref_goals_icon]
  );

  const handleSlider = (value) => {
    const name = "target_amount";
    if (isNaN(value) || !value)
      setError(name, {
        type: "not-null",
        message: `${name.replace("_", " ")} tidak boleh kosong`,
      });
    setvalueSlider(value);
    setValue(name, value);
  };

  const base = [
    {
      label: "Nama Portofolio",
      key: "name",
      inputProps: {
        placeholder: "Dana Tabungan",
        style: {
          color: color.gray_3,
          fontSize: 14,
          height: 24,
        },
      },
    },
    {
      khusus: true,
      text: "Target Investasi",
      key: "target_amount",
      adormentPlace: "start",
      adormentContent: () => <p className="basic gray-4">Rp </p>,
      onChange: handleSlider,
      value: valueSlider,
      minValue: 100000,
      maxValue: 50000000,
      unlimited: true,
      stepSlider: 1000,
      itemLabelClass: classes.paddinGridItem,
      itemInputClass: classes.paddinGridItem,
      sliderLabelClass: "fs-12 gray-3",
      textInputClass: classNames("gray-3", classes.textInput),
      errors: errors,
    },
    {
      label: "Waktu Mewujudkan Impian",
      key: "target_date",
      input: TargetDateInput,
      inputProps: {
        style: {
          color: color.gray_3,
          fontSize: 12,
        },
      },
    },
  ];

  const condition = {
    planner: {
      title: "RENCANA INVESTASI LAINNYA",
      btnText: "LANJUT",
      changeIcon: false,
      onSubmit: plannerSubmit,
    },
    goal: {
      title: "UBAH PORTOFOLIO",
      btnText: "LANJUT",
      changeIcon: true,
      onSubmit: goalSubmit,
    },
  };

  useEffect(() => {
    if (type === "goal" && img === null) {
      if (location.state) {
        base.forEach((el) => {
          register(el.key);
          setValue(el.key, otherState[el.key]);
          if (el.key === "target_amount") handleSlider(otherState[el.key]);
        });
        setimg(ref_goals_icon?.icon_web_path || ref_goals_icon?.icon_path);
      } else {
        push("/portofolio");
      }
    }
    if (type === "planner" && img === null) {
      if (location.state) {
        base.forEach((el) => {
          register(el.key);
          if (el.key === "target_amount") handleSlider(valueSlider);
        });
        setimg(ref_goals_icon?.icon_web_path || ref_goals_icon?.icon_path);
      } else {
        push("/portofolio");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    base,
    getValues,
    img,
    location,
    otherState,
    valueSlider,
    push,
    ref_goals_icon,
    register,
    setValue,
    type,
  ]);

  const handleChangefirstAmount = useCallback(
    (e) => {
      setfirstAmount(Number(e));
    },
    [setfirstAmount]
  );

  useEffect(() => {
    setinvestMonth(
      simulate({
        ekspekReturn: 10,
        biayaTotal: watch("target_amount"),
        dateFinish: watch("target_date")
          .toString()
          .split("/")
          .map((el, id) => `${el}${!id ? `/01` : ""}`)
          .join("/"),
        investAwal: firstAmount,
      })
    );
  }, [firstAmount, getValues, watch]);

  return (
    <TempMain
      title={condition[type].title}
      page={type === "planner" ? "plannerLainnya" : ""}
      bottomNavValue={type === "planner" ? 0 : 3}
      desktopBackFunc={goBack}
      childrenRight={
        <AddPlannerRiskPage
          onChangeFirst={(e) => handleChangefirstAmount(e)}
          valueFirst={firstAmount}
          valueMonth={investMonth}
          getRobo={setrobo}
        />
      }
    >
      <Card className={classNames(classes.root, "m-4 p-4 shadow-card")}>
        <form
          className={classNames("d-flex flex-column", classes.form)}
          onSubmit={handleSubmit(condition[type].onSubmit)}
        >
          <div className="d-flex align-items-end">
            <img
              src={img}
              alt="icon"
              style={{ width: "80px" }}
              className={classes.img}
            />
            {condition[type].changeIcon ? (
              <ButtonContained
                id="edit-btn"
                className={classNames("fs-10", classes.btnEdit)}
                text="EDIT"
                type="submit"
                onClick={() => seticon(true)}
              />
            ) : null}
          </div>
          {/* <Grid container spacing={3} style={{ width: 500 }}> */}
          {base.map((item, id) =>
            item.khusus ? (
              <SliderWithInput
                style={{ width: 500, marginLeft: -12 }}
                id="target-amount"
                key={id}
                name={item.key}
                {...item}
              />
            ) : (
              <Grid key={id} container spacing={3} style={{ width: 500 }}>
                <Grid
                  item
                  xs={6}
                  key={item.key}
                  className="d-flex align-content-center"
                >
                  <LabelInput text={item.label} className="fs-12" />
                </Grid>
                <Grid item xs={6} key={id}>
                  <InputGlobal
                    {...item}
                    id={item.key}
                    label={null}
                    ref={register({ required: "Harus di Isi" })}
                    inputProps={
                      item.input
                        ? {
                            register,
                            setValue,
                            setError,
                            value: otherState[item.key] || null,
                            ...item.inputProps,
                          }
                        : item.inputProps
                    }
                    name={item.key}
                    className="mb-3"
                    errors={errors}
                  />
                </Grid>
              </Grid>
            )
          )}
          {/* </Grid> */}

          <ButtonContained
            id={`${condition[type].btnText.toLowerCase()}-btn`}
            type="submit"
            loading={loading}
            text={condition[type].btnText}
            className={classNames(classes.btnLanjut)}
            onClick={() => seticon(null)}
          />
        </form>
      </Card>
      <DialogAlert
        resultName="goals-user-update"
        alertAppear="both"
        submitIfSuccess={() => push(`/portofolio-goals?goals=${id}`)}
      />
    </TempMain>
  );
};

AddPlannerPage.defaultProps = {};

export default AddPlannerPage;
