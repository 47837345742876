import { createLogic } from "redux-logic";
import url from "../../API/url";
import { requestAxios } from "../../API/request";
import { set_loading, remove_loading, set_result } from "../actions/state";
import {
  portofolio_list,
  portofolio_summary,
  porto_detail,
  statement,
} from "../actions/portofolio";
import * as types from "../actions/types";
import { batch } from "react-redux";

export const portofolioList = createLogic({
  type: types.SET_PORTOFOLIO_LIST_PROPS,
  latest: true,
  process({ action }, dispatch, done) {
    dispatch(set_loading("portofolio_list"));
    requestAxios(url.PORTO.list, action.payload)
      .then((e) => {
        console.log(e);
        const { data } = e;
        if (action.payload) {
          dispatch(portofolio_list(data));
        } else {
          let tmp = [];
          Object.keys(data).forEach((el) => {
            tmp.push(data[el]);
          });
          dispatch(portofolio_list(tmp));
        }
      })
      .catch((err) => {
        const error = {
          name: "portofolio_list",
          data: { type: "error", msg: err.message },
        };
        dispatch(set_result(error));
      })
      .then(() => {
        dispatch(remove_loading("portofolio_list"));
        done();
      });
  },
});

export const portoSummary = createLogic({
  type: types.SET_PORTOFOLIO_SUMMARY_PROPS,
  latest: true,
  process({ action }, dispatch, done) {
    dispatch(set_loading("porto_summary"));
    requestAxios(url.PORTO.summary)
      .then((e) => {
        console.log(e);
        dispatch(portofolio_summary(e.data.data));
      })
      .catch((err) => {
        const error = {
          name: "porto_summary",
          data: { type: "error", msg: err.message },
        };
        dispatch(set_result(error));
      })
      .then(() => {
        dispatch(remove_loading("porto_summary"));
        done();
      });
  },
});

export const portoDetail = createLogic({
  type: types.PORTO_DETAIL_PROPS,
  latest: true,
  process({ action }, dispatch, done) {
    dispatch(set_loading("porto_detail"));
    requestAxios(url.PORTO.porto_detail, { date: action.payload })
      .then((e) => {
        console.log(e);
        const { data } = e.data;
        let tmp = [];
        data.forEach((el) => {
          const data = {
            date: el.date,
            product: el.product_name + ` (${el.product_code})`,
            txntype: el.transaction_name,
            unit: el.unit_txn,
            saldounit: el.unit_total,
            nav: el.nav,
            invest: el.amount,
            realized: el.realized_gain,
            unrealized: el.unrealized_gain,
          };
          tmp.push(data);
        });
        dispatch(porto_detail(tmp));
      })
      .catch((err) => {
        const error = {
          name: "porto_detail",
          data: { type: "error", msg: err.message },
        };
        dispatch(set_result(error));
      })
      .then(() => {
        dispatch(remove_loading("porto_detail"));
        done();
      });
  },
});

export const estatementList = createLogic({
  type: types.STATEMENT_LIST_PROPS,
  latest: true,
  process({ action }, dispatch, done) {
    const name = "statement-list";
    dispatch(set_loading(name));
    requestAxios(url.PORTO.statement_list)
      .then((res) => {
        console.log(res);
        batch(() => {
          dispatch(remove_loading(name));
          dispatch(statement(res.data));
        });
      })
      .catch((err) => {
        batch(() => {
          dispatch(remove_loading(name));
          dispatch(
            set_result({ name, data: { type: "error", msg: err.message } })
          );
        });
      })
      .then(done);
  },
});

export const openStatement = createLogic({
  type: types.OPEN_STATEMENT,
  latest: true,
  validate({ getState, action }, allow, reject) {
    const { token } = getState().authReducer;
    const date = action.payload;
    if (token) {
      action.payload = { date, key: token };
      allow(action);
    } else reject(action);
  },
  process({ action }, dispatch, done) {
    const name = "open-statement";
    const { payload } = action;
    dispatch(set_loading(name));
    let link = url.PORTO.account_statement + "?";
    Object.keys(payload).forEach((el, index) => {
      link = `${link}${index ? "&" : ""}${el}=${payload[el]}`;
    });
    window.open(link, "_blank");
    done();
  },
});

// TODO: API BARU?
export const estatement = createLogic({
  type: types.STATEMENT_PROPS,
  latest: true,
  process({ action, getState }, dispatch, done) {
    dispatch(set_loading("statement"));
    const _url = `${url.PORTO.account_statement}?date=${action.payload}`;

    fetch(_url, {
      headers: { Authorization: getState().authReducer.token },
    })
      .then((res) => res.blob())
      .then((res) => {
        const urlFile = URL.createObjectURL(res);
        window.open(urlFile, "_blank");
      })
      .catch((err) => {
        if (err.status === 401 || err.status === 403) {
          dispatch(
            set_result({
              name: "auth",
              data: { type: "error", msg: "Sesi anda telah habis" },
            })
          );
        } else {
          const error = {
            name: "statement",
            data: { type: "error", msg: err.message },
          };
          dispatch(set_result(error));
        }
      })
      .finally(() => {
        dispatch(remove_loading("statement"));
        done();
      });
  },
});
