import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Grid from "@material-ui/core/Grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import makeStyles from "@material-ui/styles/makeStyles";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { useMediaQuery } from "react-responsive";
import BasicText from "../../atom/BasicText/BasicText";
import RadioInput from "../../molecule/MaterialInput/RadioInput";
const useStyles = makeStyles({
  expansionPanelSummary: {
    padding: "0 18px",
  },
  expansionRounded: {
    borderRadius: "8px!important",
  },
  cardListPayment: {
    minHeight: 312,
    display: "flex",
    flexDirection: "column",
  },
});

const PaymentList = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState({});
  const { data, marginClass } = props;
  const condition = useMediaQuery({ minWidth: 769 });
  const elm = [];
  const keys = Object.keys(data);
  const onChange = (e, el) => {
    setValue((value) => ({ ...value, [el]: e }));
    props.onChange(e, el);
  };
  // console.log(data);
  if (!condition) {
    keys.forEach((el, id) => {
      elm.push(
        <ExpansionPanel
          key={id}
          defaultExpanded={true}
          classes={{ rounded: classes.expansionRounded }}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={"panel" + id + "-content"}
            id={"panel" + id + "-header"}
            classes={{ root: classes.expansionPanelSummary }}
          >
            <BasicText>{el}</BasicText>
          </ExpansionPanelSummary>
          {Object.keys(data[el]).map(
            (el2, id2) =>
              el2 !== "code" && (
                <div key={id2} className="px-3 pb-2 mb-2">
                  <BasicText className="fs-15 mb-2 text-capitalize">
                    {el2.replace("_", " ")}
                  </BasicText>
                  <RadioInput
                    dataRadio={data[el][el2]}
                    onChange={(e) => onChange(e, data[el]["code"])}
                    value={parseInt(value[data[el]["code"]])}
                    type="list"
                  />
                  <BasicText className="fs-11 mb-2">
                    {el2 === "virtual_account"
                      ? "Verifikasi otomatis tanpa upload bukti pembayaran"
                      : "Verifikasi dengan upload bukti pembayaran"}
                  </BasicText>
                </div>
              )
          )}
        </ExpansionPanel>
      );
    });
  } else {
    keys.forEach((el1, id1) => {
      const subKeys = Object.keys(data[el1]);
      let cond2 = false;
      if (keys.length === 1) {
        cond2 = true;
      }
      elm.push(
        <div className="mb-4" key={id1}>
          {!cond2 && (
            <BasicText className="fs-14 font-weight-bold mb-3">{el1}</BasicText>
          )}
          <Grid container spacing={3}>
            {subKeys.map(
              (el2, id2) =>
                el2 !== "code" && (
                  <Grid item xs={12} md={4} lg={3} key={id2}>
                    <Grid
                      className={classNames(
                        "card-custom py-3",
                        classes.cardListPayment
                      )}
                    >
                      <BasicText className="fs-14 mb-3 text-uppercase">
                        {el2.replace("_", " ")}
                      </BasicText>
                      <RadioInput
                        dataRadio={data[el1][el2]}
                        onChange={(e) => onChange(e, data[el1]["code"])}
                        value={parseInt(value[data[el1]["code"]])}
                        type="list"
                        containerClass="flex"
                      />
                      <BasicText className="fs-11 my-2">
                        {el2 === "virtual_account"
                          ? "Verifikasi otomatis tanpa upload bukti pembayaran"
                          : "Verifikasi dengan upload bukti pembayaran"}
                      </BasicText>
                    </Grid>
                  </Grid>
                )
            )}
          </Grid>
        </div>
      );
    });
  }
  return <div className={marginClass}>{elm}</div>;
};

PaymentList.propTypes = {
  data: PropTypes.object,
};

export default PaymentList;
