import icon from "../../../assets/img/icon/profile/personCircle.svg";
import React from "react";
// import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import BasicText from "../../atom/BasicText/BasicText";
import ButtonContained from "../../atom/Button/ButtonContained";
import { useHistory } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up(768)]: {
      borderRadius: 5,
      border: `1px solid ${theme.palette.common.border}`,
    },
  },
  risk: {
    padding: "0 8px",
    fontSize: 10,
    marginBottom: 4,
    fontWeight: 300,
    [theme.breakpoints.up(768)]: {
      marginBottom: "auto",
      marginTop: "auto",
      fontSize: 16,
      padding: "0 12px",
    },
  },
  riskDisplay1: {
    [theme.breakpoints.up(768)]: {
      display: "none",
    },
  },
  riskDisplay2: {
    [theme.breakpoints.down(769)]: {
      display: "none",
    },
  },
  buttonDisplay: {
    [theme.breakpoints.down(769)]: {
      display: "none",
    },
  },
}));

const ShortProfile = (props) => {
  const history = useHistory();
  const { name, profile_risk, sid, onDetailClick, detailState } = props;
  const classes = useStyles();
  const handleDetail = () => {
    onDetailClick();
  };
  return (
    <div className={classNames(classes.root, "p-4")}>
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <img src={icon} alt="" />
          <div className="ml-3 my-auto">
            {name!=='' || sid!=='' ? (
              <>
                <BasicText
                  className="fs-14 font-weight-bold mb-1"
                  color="secondary"
                >
                  {name}
                </BasicText>
                <ButtonContained
                  id="profile-risk-route"
                  text={`Investor ${profile_risk || ""}`}
                  className={classNames(classes.risk, classes.riskDisplay1)}
                  radius="8px"
                  onClick={() =>
                    history.push("/profil-risiko", { from: "profile" })
                  }
                />
                <BasicText id="sid" className="fs-12" color="secondary">
                  SID {sid || "-"}
                </BasicText>
              </>
            ) : (
              <>
                <Skeleton width={200} height={18} className="mb-1" />
                <Skeleton width={200} height={18} className="mb-1" />
              </>
            )}
          </div>
        </div>
        <ButtonContained
          id="button-profile-risk"
          text={`Investor ${profile_risk || ""}`}
          className={classNames(classes.risk, classes.riskDisplay2)}
          radius="12px"
          onClick={() => history.push("/profil-risiko", { from: "profile" })}
        />
      </div>
      <BasicText
        id="detail-profile"
        className={classNames(
          "fs-12 text-underline mt-3 ml-5 is-link",
          classes.buttonDisplay,
          detailState && "d-none"
        )}
        color="primary"
        onClick={handleDetail}
      >
        DETAIL
      </BasicText>
    </div>
  );
};

ShortProfile.propTypes = {};

export default ShortProfile;
