import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import {
  product_history_props,
  set_product,
  clear_product_history_props,
} from "../../config/redux/actions/product";
import CardProduct from "../molecule/Product/CardProduct";
// import ContentSimulasi from "../organism/SimulasiProduk/ContentSimulasi";
import TempMain from "../template/TempMain";
import textHelper from "../../helper/textHelper";
import CardSimulation from "../organism/ProfileRiskPage/CardSimulation";
import DialogAlert from "../molecule/Alert/DialogAlert";
import { isOfferPeriodRDP } from "../../helper/RDPhelper";

export class SimulasiProduct extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      productData: {},
    };
  }
  componentDidMount() {
    const { product_history_props, location, product, set_product } =
      this.props;
    const arr = textHelper.parseParams(location.search);
    console.log(arr);
    product_history_props({
      "code[]": arr[0].product,
      display: "all",
      type: "monthly",
    });
    if (product.length > 0) {
      this.initialData(product);
    } else {
      set_product();
    }
  }
  componentDidUpdate(prevProps) {
    const { product } = this.props;
    if (prevProps.product !== product && product.length > 0) {
      this.initialData(product);
    }
  }
  componentWillUnmount() {
    this.props.clear_product_history_props();
  }
  handleClick = (e, data) => {
    const { productData } = this.state;
    // console.log(`data`, data);
    let tmp = {
      products: {
        [productData.code]: {
          amount: 100,
          type: "RG",
          amount_value:
            parseFloat(data.firstInvest) + parseFloat(data.monthlyInvest),
        },
      },
      total_amount:
        parseFloat(data.firstInvest) + parseFloat(data.monthlyInvest),
    };
    this.props.history.push("/subscript", tmp);
  };
  initialData = (product) => {
    const { location } = this.props;
    const { search } = location;
    const arr = textHelper.parseParams(search);
    for (let i = 0; i < product.length; i++) {
      if (product[i].code === arr[0].product) {
        this.setState({ productData: product[i] });
        break;
      }
    }
  };
  _handleLogin = () => {
    this.props.history.replace("/masuk");
  };
  _handleFromCard = ({ link, items }) => {
    const product = items;
    this.props.history.push(`${link}?product=${product.code}`);
  };
  handleBack = () => {
    this.props.history.goBack();
  };
  render() {
    const { product_history } = this.props;
    const { productData } = this.state;
    const can_sub =
      productData.can_subscript &&
      (!productData.is_rdp || isOfferPeriodRDP(productData?.product_rdp));
    return (
      <TempMain title="SIMULASI" bottomNavValue={1}>
        <div className="mx-3 mt-4" style={{ paddingBottom: "100px" }}>
          <CardProduct
            items={productData}
            needButton={false}
            className="mb-4 mx-0 br-8"
            onClick={this._handleFromCard}
            heightRiskIndicator={12}
          />
          {/* <ContentSimulasi
            history={product_history}
            goBack={history.goBack}
            handleLogin={this._handleLogin}
            push={history.push}
            isLogin={isLogin}
            product={productData}
          /> */}
          <CardSimulation
            containerClass="w-65"
            onClickback={this.handleBack}
            onSubscript={this.handleClick}
            is_suspend={productData?.is_suspend}
            history={product_history}
            disabled_sub={!can_sub}
          />
          <DialogAlert resultName="" alertAppear="error" />
        </div>
      </TempMain>
    );
  }
}

const mapState = ({ productReducer, authReducer }) => ({
  product_history: productReducer.history,
  isLogin: authReducer.isLogin,
  product: productReducer.product,
});

export default compose(
  connect(mapState, {
    product_history_props,
    set_product,
    clear_product_history_props,
  }),
  withRouter
)(SimulasiProduct);
