import { Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import classNames from "classnames";
import React from "react";
import ColorIndicator from "../../../assets/img/icon/porto/ColorIndicator";
import BasicText from "../../atom/BasicText/BasicText";

const Title = ({ title, handleClick, xs, className, id }) => {
  return (
    <Grid item xs={xs} container alignItems="center" className={className}>
      <BasicText className="fs-12 gray-5">{title}</BasicText>
      <IconButton id={`${id}-popOver`} onClick={handleClick} size="small">
        <InfoIcon className="fs-14 gray-5" />
      </IconButton>
    </Grid>
  );
};

const NameWithAllocation = ({
  color,
  classes = {},
  display_name,
  display_code,
  allocation,
}) => {
  return (
    <Grid container spacing={1}>
      <Grid item={true} container xs={9}>
        <div className="d-flex">
          <ColorIndicator style={{ margin: 1, fontSize: 13, color }} />
          <BasicText
            colorLevel="light"
            className={classNames(
              "fs-10 ml-1 font-weight-medium",
              classes.name
            )}
          >
            {display_name?.toLowerCase()} ({display_code})
          </BasicText>
        </div>
      </Grid>
      <Grid item xs={3} className="text-center">
        <BasicText
          className={classNames("font-weight-bold fs-14", classes.allocation)}
          color="primary"
          colorLevel="main"
        >
          {allocation}%
        </BasicText>
      </Grid>
    </Grid>
  );
};

const TypeWithAllocation = ({
  color,
  classes = {},
  type,
  productName,
  allocation,
  lastIndex,
}) => {
  return (
    <Grid container spacing={1} className={lastIndex ? "" : "mb-2"}>
      <Grid item={true} container xs={9}>
        <div className="d-flex">
          <ColorIndicator style={{ margin: 1, fontSize: 16, color }} />
          <div className="ml-2">
            <BasicText className={classNames("fs-13 basic", classes.name)}>
              {type}
            </BasicText>
            <BasicText className="fs-10 gray-4 basic">{productName}</BasicText>
          </div>
        </div>
      </Grid>
      <Grid item xs={3} className="text-right pr-2">
        <BasicText
          className={classNames(
            "font-weight-bold fs-13 gray-3",
            classes.allocation
          )}
          colorLevel="main"
        >
          {allocation}%
        </BasicText>
      </Grid>
    </Grid>
  );
};

const RecomColorDesc = (props) => {
  const {
    color,
    profileRiskProduct,
    data,
    onClick,
    nameClass,
    alokasiClass,
    className,
  } = props;
  const handleClick = (e) => {
    onClick(e);
  };
  const titles = [
    {
      title: "Jenis Reksadana",
      handleClick: () => handleClick("types"),
      xs: 7,
    },
    {
      title: "Alokasi",
      handleClick: () => handleClick("alloc"),
      xs: 5,
      className: "d-flex justify-content-end",
    },
  ];
  return (
    <div style={{ marginBottom: 16 }} className={className}>
      <Grid container className="mb-2">
        {titles.map((item, index) => (
          <Title id={index} key={index} {...item} />
        ))}
      </Grid>
      <Divider className="mb-2 gray-7" />
      {Object.keys(data).length
        ? data.profile_risk_product.map((el, id) => {
            const {
              allocation,
              product: {
                fr_product_name,
                product_type: { name },
              },
            } = el;
            return (
              <TypeWithAllocation
                key={id}
                lastIndex={id + 1 === data.profile_risk_product.length}
                color={color[id]}
                classes={{
                  name: nameClass,
                  allocation: alokasiClass,
                }}
                type={name}
                productName={fr_product_name}
                allocation={allocation}
              />
            );
          })
        : profileRiskProduct.map((el, id) => (
            <NameWithAllocation
              key={id}
              color={color[id]}
              display_name={el.product.display_name}
              display_code={el.product.display_code}
              classes={{ name: nameClass, allocation: alokasiClass }}
              allocation={el.allocation}
            />
          ))}
    </div>
  );
};

RecomColorDesc.defaultProps = {
  profileRiskProduct: [],
  onClick: () => {},
  nameClass: "text-uppercase font-weight-medium",
  data: {},
};

export default RecomColorDesc;
