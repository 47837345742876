import React from "react";
import { makeStyles, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import { content, set_faq } from "../../../config/redux/actions/option";
import parse from "html-react-parser";
import PropTypes from "prop-types";
import BasicText from "../../atom/BasicText/BasicText";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.css";
import Glossary from "./Glossary";
import Fade from "@material-ui/core/Fade";
import classNames from "classnames";
const baseTitle = {
  syarat_ketentuan: "syarat & ketentuan",
  kebijakan_privasi: "kebijakan privasi",
  about_app: "tentang YO! Inves",
  glossary: "GLOSSARY",
};

const useStyles = makeStyles((theme) => ({
  canvasModal: {
    width: "100vw",
    height: "calc(100vh - 65px)",
    overflowY: "hidden",
    backgroundColor: "#fff",
    position: "absolute",
    top: 65,
    left: -220,
    zIndex: 1,
    "& .canvasContent": {
      // marginRight: 194,
      marginLeft: 250,
      "& .subContent": {
        width: 1035,
        paddingBottom: 50,
      },
    },
  },
  btnGlossary: {
    minWidth: "22px!important",
    padding: "0 0.25rem",
    fontSize: 14,
    fontWeight: 700,
    marginRight: 6,
  },
}));

const ContentType = (props) => {
  const {
    content,
    optionData,
    contentKey,
    display,
    onClose,
    style,
    set_faq,
  } = props;
  const classes = useStyles();
  React.useEffect(() => {
    if (contentKey === "glossary") set_faq(contentKey);
    else content(contentKey);
  }, [contentKey, optionData, content, set_faq]);
  const title = baseTitle[contentKey];
  return (
    <Fade in={display}>
      <div
        style={style}
        className={classNames(classes.canvasModal, !display && "d-none")}
      >
        <div className="text-right p-3 ">
          <IconButton
            id="button-close"
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon className="color-secondary" />
          </IconButton>
        </div>
        <div className="canvasContent">
          <SimpleBar style={{ height: "85vh" }}>
            <BasicText
              color="primary"
              className="fs-14 font-weight-bold mb-4 pb-3 text-uppercase"
            >
              {title}
            </BasicText>
            <div className="subContent color-secondary">
              {contentKey !== "glossary" ? (
                optionData[contentKey] !== "" ? (
                  parse(optionData[contentKey])
                ) : (
                  ""
                )
              ) : (
                <Glossary glossary={optionData[contentKey]} />
              )}
            </div>
          </SimpleBar>
        </div>
      </div>
    </Fade>
  );
};

const mapState = (state) => ({
  optionData: state.optionReducer,
});

ContentType.propTypes = {
  contentKey: PropTypes.string,
  display: PropTypes.bool,
  onClose: PropTypes.func,
  style: PropTypes.object,
};

export default connect(mapState, { content, set_faq })(ContentType);
