import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  root: {
    borderRadius: "8px !important",
    height: "70vh",
  },
  form: {
    // width: "50%",
    minWidth: "525px",
    height: "100%",
    maxWidth: "630px",
  },
  btnEdit: {
    borderRadius: "8px !important",
    marginBottom: 10,
    height: 13,
    width: 41,
    backgroundColor: theme.palette.primary[5],
  },
  btnLanjut: {
    borderRadius: "6px !important",
    width: "200px",
    height: "50px",
    margin: "auto",
    fontWeight: "600",
  },
  img: {
    marginRight: 10,
    marginBottom: 10,
    borderRadius: 4,
    backgroundColor: theme.palette.secondary[8],
    padding: 8,
  },
  // styleSliderInput: {
  paddinGridItem: {
    padding: "12px !important",
  },
  textInput: {
    height: 44,
    color: theme.palette.secondary.lighter,
  },
  // },
}));
