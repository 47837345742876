import { CircularProgress, Card, Grid, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { useSelector } from "react-redux";
import React, { useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import BasicText from "../../atom/BasicText/BasicText";
import ButtonContained from "../../atom/Button/ButtonContained";
import CloseButton from "../../atom/CloseButton";
import DesktopFooter from "../../molecule/Footer/DesktopFooter";
import GoalIcon from "../../molecule/GoalIcon";
import BasicHeader from "../../molecule/Header/BasicHeader";
import processHelper from "../../../helper/processHelper";
import { useMediaQuery } from "react-responsive";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  root: {
    width: "70%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    "& #content-planner": {
      padding: 37,
      paddingTop: 24,
    },
    "& .btn-close": {
      position: "absolute",
      top: 25,
      right: "10%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      backgroundColor: "transparent",
      marginBottom: 150,
      "& #content-planner": {
        backgroundColor: "white",
      },
    },
    "& .item-goal .container-image.checked": {
      backgroundColor: theme.palette.secondary[8],
      borderRadius: 4,
    },
  },
  iconArea: {
    marginTop: 36,
    [theme.breakpoints.up("sm")]: {
      margin: "36px auto",
      width: "80% !important",
    },
  },
  btnRoot: {
    margin: "25px auto",
    marginBottom: 0,
    "& .Mui-disabled": {
      backgroundColor: theme.palette.primary.lighter,
      color: "white",
    },
  },
  btn: {
    width: 155,
    height: 48,
    borderRadius: "6px !important",
    margin: "10px",
  },
}));

const CreateModifyPlannerPage = ({
  item,
  checked,
  onChecked,
  onSubmit,
  type,
  onDelete,
  loading,
}) => {
  const { path } = useRouteMatch();
  const {
    goBack,
    push,
    location: { state },
  } = useHistory();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isModPlanner = path === "/modify-planner";
  const classes = useStyles();
  const { goal_id, ref_goals_icon_id } = state?.item ?? {};
  const loadingIcon = useSelector((state) =>
    processHelper.findProcess("goals", state.stateReducer.loading_stack)
  );
  useEffect(() => {
    if (!state && isModPlanner) push("/");
    else if (isModPlanner) {
      onChecked(goal_id, ref_goals_icon_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const btnBase = {
    modify: [
      {
        text: "GANTI",
        props: {
          onClick: onSubmit,
          disabled: checked[0] === goal_id,
          loading: loading,
        },
      },
      {
        text: "HAPUS",
        props: {
          onClick: onDelete,
          disabled: checked[0] !== goal_id,
          loading: loading,
        },
      },
    ],
    create: [
      {
        text: "OK",
        props: {
          onClick: onSubmit,
          disabled: !checked.length,
          loading: loading,
        },
      },
    ],
  };
  return (
    <div className={classNames("background-desktop", classes.container)}>
      <Card className={classNames("no-shadow", classes.root)}>
        <CloseButton
          id="close-btn"
          className="p-0 btn-close"
          onClick={goBack}
        />
        <div id="content-planner">
          {!isMobile && <BasicHeader />}
          <BasicText
            className="fs-18 font-weight-medium gray-3 mt-2"
            style={{ minWidth: 260 }}
          >
            Pilih rencana investasi sesuai <br /> dengan #goalskamu
          </BasicText>
          {loadingIcon ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: 200 }}
            >
              <CircularProgress size={20} />
            </div>
          ) : (
            <Grid
              container
              spacing={3}
              className={classNames(classes.iconArea)}
            >
              {item.map((el, id) => (
                <Grid key={id} item xs={3} sm={4} md={2}>
                  <GoalIcon
                    item={el}
                    checked={checked.includes(el.id)}
                    onChecked={onChecked}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </div>

        <div className={classes.btnRoot}>
          {btnBase[type].map((el, id) => (
            <ButtonContained
              id={`${el.text.toLowerCase()}-btn`}
              className={classes.btn}
              text={el.text}
              key={id}
              {...el.props}
            />
          ))}
        </div>
      </Card>
      <DesktopFooter />
    </div>
  );
};

export default CreateModifyPlannerPage;
