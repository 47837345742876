import React from "react";
import { Grid, withStyles, TextField } from "@material-ui/core";
import BasicText from "../../atom/BasicText/BasicText";
import textHelper from "../../../helper/textHelper";
import classNames from "classnames";

const CustomTextField = withStyles(theme => ({
  root: {
    width: "100%",
    "& input": {
      padding: "9px 0 9px 6px",
      color: theme.palette.secondary[3],
      fontSize: 14,
      fontFamily: "Montserrat",
    },
    "& .MuiInputBase-adornedStart": {
      color: theme.palette.secondary[3],
      fontSize: 14,
      "& input": {
        paddingLeft: 0,
      }
    },
  },
}))(TextField);

const RowResultInput = ({
  title,
  value,
  className,
  textInputClass,
  inputClass,
  endAdornment,
  isNumber,
  index
}) => {
  return (
    <Grid
      container
      alignItems="center"
      className={classNames("mb-3", className)}
    >
      <Grid xs={6} item>
        <BasicText className="fs-12 color-secondary">{index ? `${index}. ` : ''}{title}</BasicText>
      </Grid>
      <Grid xs={6} item>
        <CustomTextField
          className={textInputClass}
          value={
            isNumber ? textHelper.printMoneyWithoutRp(value, false) : value
          }
          variant="outlined"
          disabled
          inputProps={{ className: inputClass }}
          InputProps={{
            startAdornment: (
              <span className={inputClass}>Rp</span>
            ),
            endAdornment,
          }}
        />
      </Grid>
    </Grid>
  );
};

RowResultInput.defaultProps = {
  title: "title",
  isNumber: true,
};

export default RowResultInput;
