import { makeStyles } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router'
import ButtonContained from '../../atom/Button/ButtonContained'
import GraphProductRisk from '../../organism/ProfileRiskPage/GraphProductRisk'

const useStyles = makeStyles(theme => ({
    con_button: {
        display: 'flex',
        marginTop: 35,
        marginLeft: 38,
        '& .button': {
            width: 191,
            height: 48,
            '&#cancel': {
                marginLeft: 30,
                backgroundColor: theme.palette.secondary[5],
            },
        },
        [theme.breakpoints.down("md")]: {
            marginLeft: 0,
            justifyContent: "center",
            '& .button#cancel': {
                display: 'none',
            }
        }
    }
}))

const TempRiskPage = ({ children, data }) => {
    const { goBack } = useHistory()
    const classes = useStyles()
    const { chartColor, profileRisk, button } = data
    return (
        <div className="p-4">
            {children}
            <GraphProductRisk
                chartColor={chartColor}
                profileProduct={profileRisk?.profile_risk_product}
                profileDetail={profileRisk?.profile_risk_type}
            />
            <div className={classes.con_button}>
                <ButtonContained
                    radius="6px"
                    className="button"
                    id="primary"
                    {...button}
                />
                <ButtonContained
                    radius="6px"
                    className="button"
                    id="cancel"
                    text="CANCEL"
                    onClick={goBack}
                />
            </div>
        </div>
    )
}

TempRiskPage.defaultProps = {
    data: {}
}

export default TempRiskPage
