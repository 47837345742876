import React, { forwardRef, memo, useImperativeHandle, useRef } from "react";
import DialogCustom from "../../organism/Container/DialogCustom";
import parse, { domToReact } from "html-react-parser";
import BasicText from "../../atom/BasicText/BasicText";

const TnCProduct = ({ tncData }) => {
  const value = tncData?.description || "";
  const options = {
    replace: (domNode) => {
      if (domNode.attribs && domNode.name === "ol") {
        return (
          <ol style={{ paddingLeft: 20 }}>
            {domToReact(domNode.children, options)}
          </ol>
        );
      }
    },
  };
  return (
    <div className="p-3">
      <BasicText className="mb-3 fs-14 fw-700">SYARAT DAN KETENTUAN</BasicText>
      <div>{parse(value, options)}</div>
      <BasicText
        className="my-3 fs-14 color-primary is-link"
        onClick={() => window.open(tncData?.path, "_blank")}
      >
        LIHAT LEBIH LANJUT
      </BasicText>
    </div>
  );
};

const DialogTnC = forwardRef((props, ref) => {
  const [state, setState] = React.useState(false);
  const product_popup = useRef(null);

  const handleState = (val, data) =>
    setState((prev) => {
      const res = typeof val === "boolean" ? val : !prev;
      product_popup.current = res ? data : null;
      return res;
    });

  useImperativeHandle(
    ref,
    () => ({
      onClose: (data) => handleState(false, data),
      onShow: (data) => handleState(true, data),
    }),
    []
  );

  return (
    <DialogCustom
      open={state}
      handleClose={handleState}
      onClick={handleState}
      showCloseButton
      title=""
      textClass="fs-12"
      text={<TnCProduct tncData={product_popup.current} />}
      buttonText="SETUJU"
    />
  );
});

export default memo(DialogTnC, () => true);
