import React from "react";
import ContainerWithLoader from "../Container/ContainerWithLoader";
import CardPorto from "../../molecule/Porto/CardPorto";
import BasicText from "../../atom/BasicText/BasicText";
import { Card, Grid, LinearProgress, makeStyles } from "@material-ui/core";
import classNames from "classnames";

const CardBadge = ({ children, product_type, className }) => {
  return (
    <div>
      <div className="position-relative mt-4">
        <div className={className}>
          <BasicText className="mb-1 fw-500 fs-10" style={{ color: "white" }}>
            {product_type}
          </BasicText>
        </div>
        {children}
      </div>
    </div>
  );
};

const PortoList = (props) => {
  const {
    data: { portofolio, is_follow_robo },
    onClick,
  } = props;
  const list = portofolio;
  const useStyles = makeStyles((theme) => ({
    card: {
      position: "relative",
      borderRadius: "0 8px 8px 8px",
    },
    badge: {
      zIndex: 0,
      backgroundColor: theme.palette.primary.main,
      position: "absolute",
      top: -21,
      left: 0,
      padding: "5px 17px",
      minWidth: 156,
      height: 76,
      borderRadius: "8px",
    },
    card_desktop: {
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      borderRadius: 5,
      "&::before": {
        content: `''`,
        display: "inline-block",
        backgroundColor: theme.palette.primary.main,
        width: 6,
        height: "100%",
        position: "absolute",
        borderRadius: "5px 0 0 5px",
      },
    },
    letterSpacing: {
      letterSpacing: "-0.5px",
    },
    targetProgress: {
      width: "100%",
      borderRadius: 4,
      overflow: "hidden",
      backgroundColor: theme.palette.secondary[7],
    },
  }));
  const classes = useStyles();

  return (
    <ContainerWithLoader processName="portofolio_list" loadingHeight="50vh">
      <Grid container spacing={3}>
        {list.map((el, id) => {
          const {
            info: { product_type, target, allocation },
            portofolio_sub,
          } = el;
          const progressGoals = [
            [
              {
                xs: 2,
                children: "Target",
              },
              {
                xs: 2,
                children: (
                  <BasicText className="mr-2 text-right fs-10">
                    {target}%
                  </BasicText>
                ),
              },
              {
                xs: 8,
                children: (
                  <div className="d-flex align-items-center h-100">
                    <LinearProgress
                      className={classes.targetProgress}
                      variant="determinate"
                      value={target}
                    />
                  </div>
                ),
              },
            ],
            [
              {
                xs: 2,
                children: "Alokasi",
              },
              {
                xs: 2,
                children: (
                  <BasicText className="mr-2 text-right fs-10">
                    {allocation}%
                  </BasicText>
                ),
              },
              {
                xs: 8,
                children: (
                  <div className="d-flex align-items-center h-100">
                    <LinearProgress
                      className={classes.targetProgress}
                      variant="determinate"
                      value={allocation}
                    />
                  </div>
                ),
              },
            ],
          ];
          return (
            <Grid key={id} item xs={12} md={6} lg={4}>
              <CardBadge
                product_type={product_type}
                className={classNames(classes.badge, "shadow-card")}
              >
                <Card
                  key={id}
                  className={classNames("mb-3 shadow-card", classes.card)}
                >
                  <div className="m-3 mb-4">
                    {is_follow_robo ? (
                      <Grid container className="mb-2 border-bottom pb-2">
                        {progressGoals.map((el) => {
                          return el.map((el2, id) => {
                            return (
                              <Grid key={id} item xs={el2.xs}>
                                {typeof el2.children === "string" ? (
                                  <BasicText className="fs-12">
                                    {el2.children}
                                  </BasicText>
                                ) : (
                                  el2.children
                                )}
                              </Grid>
                            );
                          });
                        })}
                      </Grid>
                    ) : null}
                    {portofolio_sub.map((el2, id2) => (
                      <CardPorto key={id2} items={el2} onClick={onClick} />
                    ))}
                  </div>
                </Card>
              </CardBadge>
            </Grid>
          );
        })}
      </Grid>
    </ContainerWithLoader>
  );
};

export default PortoList;
