import React, { PureComponent } from "react";
import { RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import classNames from "classnames";

const useStyles = (theme) => ({
  radio: {
    "& .MuiFormControlLabel-root": {
      marginLeft: "-3px",
    },
    "& .MuiFormControlLabel-root .MuiButtonBase-root": {
      padding: "1px",
    },
    "& .MuiTypography-root": {
      fontSize: "14px",
    },
  },
  radioRoot: {
    color: theme.palette.primary.main,
  },
  image: {
    height: 40,
    marginLeft: 10,
    marginRight: 20,
  },
});

class RadioInput extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      initial: [{ id: 0, name: "Pilih" }],
    };
  }
  _changeHandler = (e) => {
    this.setState({ value: e.target.value });
    this.props.onChange(e.target.value);
  };
  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props;
    if (prevProps.value !== value && value !== null) {
      this.setState({ value });
    }
  }

  render() {
    const { classes, dataRadio, type, containerClass, labelClass } = this.props;
    const { value, initial } = this.state;
    return (
      <RadioGroup
        aria-label="position"
        name="position"
        value={value}
        onChange={this._changeHandler}
        className={classNames(classes.radio, containerClass)}
      >
        {dataRadio
          ? dataRadio.map((el, i) => {
              if (type === "label") {
                return (
                  <FormControlLabel
                    key={i}
                    value={el.code}
                    label={el.name}
                    labelPlacement="end"
                    control={
                      <Radio
                        color="primary"
                        classes={{ root: classes.radioRoot }}
                      />
                    }
                  />
                );
              } else {
                console.log(el.image);
                return (
                  <FormControlLabel
                    key={i}
                    value={el.code}
                    className={labelClass}
                    label={
                      el.image && (
                        <img
                          src={el.image}
                          alt={el.name}
                          className={classes.image}
                        />
                      )
                    }
                    labelPlacement="end"
                    control={
                      <Radio
                        color="primary"
                        classes={{ root: classes.radioRoot }}
                      />
                    }
                  />
                );
              }
            })
          : initial.map((el, i) => (
              <FormControlLabel
                key={i}
                value={el.code}
                label={el.name}
                labelPlacement="end"
                control={<Radio color="primary" />}
              />
            ))}
      </RadioGroup>
    );
  }
}

RadioInput.propTypes = {
  dataRadio: PropTypes.array,
  type: PropTypes.string,
  containerClass: PropTypes.string,
};

RadioInput.defaultProps = {
  type: "label",
};

export default withStyles(useStyles)(RadioInput);
