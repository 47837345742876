import { createMuiTheme } from "@material-ui/core";
import color from "./color";
export const theme = createMuiTheme({
  palette: {
    primary: {
      main: color.orange_1,
      light: color.orange_2,
      lighter: color.orange_3,
      4: color.orange_4,
      5: color.orange_5,
      6: color.orange_6,
      7: color.orange_7,
      8: color.orange_8,
    },
    secondary: {
      main: color.gray_3,
      light: color.gray_4,
      lighter: color.gray_5,
      1: color.gray_1,
      2: color.gray_2,
      3: color.gray_3,
      4: color.gray_4,
      5: color.gray_5,
      6: color.gray_6,
      7: color.gray_7,
      8: color.gray_8,
    },
    background: {
      dark: color.gray_7,
      default: color.gray_8,
      paper: "#fff",
      disabledInput: 'rgba(0,0,0,0.03)',
    },
    common: {
      main: "#fff",
      white: "#fff",
      black: "#000",
      border: color.gray_6,
      border_1: "#E8ECEF"
    },
  },
});
