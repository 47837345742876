import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import stateCallApi from "../../config/API/stateCallApi";
import url from "../../config/API/url";
import { statement_list } from "../../config/redux/actions/portofolio";
import { gbl_act_lc } from "../../config/redux/actions/state";
import processHelper from "../../helper/processHelper";
import BasicText from "../atom/BasicText/BasicText";
import DialogAlert from "../molecule/Alert/DialogAlert";
import PaginationDot from "../molecule/PaginationDot/PaginationDot";
import CardEStatement from "../molecule/Profile/CardEStatement";
import TabCustom from "../molecule/Tabs/TabCustom";
import TempMain from "../template/TempMain";
import classNames from "classnames";
// import illustration from "../../assets/img/ilustration";
const { memo, useCallback, useEffect, useMemo, useRef, useState } = React;

const useStyles = makeStyles((theme) => ({
  dots: {
    width: 13,
    height: 13,
    borderRadius: 4,
    cursor: "pointer",
  },
  tabContainer: {
    [theme.breakpoints.up(769)]: {
      marginLeft: -38,
      marginTop: -24,
      marginBottom: 24,
    },
  },
  subTitle: {
    [theme.breakpoints.up("md")]: {
      marginTop: 28,
      width: "65%",
    },
  },
}));

const tabs = [
  {
    title: "E Statement Bulanan",
    subTitle:
      "Lihat dan download laporan portofolio dan rincian transaksi kamu di Syailendra Capital yang diterbitkan setiap bulannya.",
    srcData: "statement",
  },
  {
    title: "Laporan Pajak",
    subTitle:
      "Lihat dan download laporan pajak dan ringkasan portofolio kamu di Syailendra Capital.\n\nReksa Dana bukan objek pajak akan tetapi perlu untuk dilaporkan di Surat Pajak Tahunan kamu.",
    srcData: "tax",
  },
  {
    title: "Laporan Dividend",
    subTitle:
      "Lihat dan download laporan dividend dan ringkasan portofolio kamu di Syailendra Capital.",
    srcData: "dividend",
  },
];

const SkeletonStatement = () => (
  <>
    {Array(8)
      .fill("")
      .map((_, index) => (
        <Grid item xs={6} key={`skeleton-statement-${index}`}>
          <Skeleton style={{ borderRadius: 8 }} height={74} />
        </Grid>
      ))}
  </>
);

const EmptyData = React.memo(
  () => {
    return (
      <div
        className="text-center w-100 my-5 cont-center"
        style={{ minHeight: 210 }}
      >
        <div>
          {/* <img src={illustration["emptyporto"]} alt="" className="mb-4" /> */}
          <BasicText className="fs-14 font-weight-bold color-secondary mb-2">
            Data Tidak Ditemukan
          </BasicText>
          <BasicText className="fs-14 text-center">
            Yuk investasi sekarang!
          </BasicText>
        </div>
      </div>
    );
  },
  () => true
);

const LaporanPage = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [tab, setTab] = useState(0);
  const token = useSelector((state) => state.authReducer.token);
  const { title, subTitle, srcData } = tabs[tab];
  const data_tab = useSelector((state) => ({
    statement: state.portoReducer.statement,
    tax: state.profileReducer.tax_report,
    dividend: state.profileReducer.dividend_report,
  }));

  const isFetch = useRef({
    statement: Boolean(data_tab["statement"].length),
    tax: Boolean(data_tab["tax"].length),
    dividend: Boolean(data_tab["dividend"].length),
  });
  const loading = useSelector((state) =>
    processHelper.findProcess(
      ["statement-list", "tax_report", "dividend_report"],
      state.stateReducer.loading_stack
    )
  );

  useEffect(() => {
    if (!isFetch.current[srcData] && !data_tab[srcData].length) {
      isFetch.current[srcData] = true;
      const fetchAPI = [
        statement_list(),
        gbl_act_lc(stateCallApi.tax_report),
        gbl_act_lc(stateCallApi.dividend_report),
      ];
      dispatch(fetchAPI[tab]);
      // dispatch(tab ? gbl_act_lc(stateCallApi.tax_report) : statement_list());
    }
  }, [data_tab, dispatch, srcData, tab]);

  const handleTab = useCallback((index) => setTab(index), []);

  const handleTax = useCallback(
    ({ year }) => {
      dispatch(
        gbl_act_lc({
          ...stateCallApi.tax_report,
          redux_action: null,
          //TODO: harus ada halaman encode toekn dulu
          callback: ({ url }) => window.open(`${url}?key=${token}`, "_blank"),
          params: { year },
        })
      );
    },
    [dispatch, token]
  );

  const handleDividend = useCallback(
    ({ code }) => {
      dispatch(
        gbl_act_lc({
          ...stateCallApi.dividend_report_download,
          callback: ({ data }) => window.open(`${data}?key=${token}`, "_blank"),
          params: { product_code: code },
        })
      );
    },
    [dispatch, token]
  );

  const handleStatement = useCallback(
    async (date) => {
      const _url = await fetch(`${url.PORTO.account_statement}?date=${date}`, {
        headers: { Authorization: token },
      })
        .then((res) => res.blob())
        .then((res) => URL.createObjectURL(res));
      window.open(_url, "_blank");
    },
    [token]
  );

  const handleOnClickTab = [handleStatement, handleTax, handleDividend];

  return (
    <TempMain
      title="PROFIL"
      backgroundColor="white"
      showBottom={false}
      bottomNavValue={4}
      page="profile"
      data={{ menuValue: 2 }}
    >
      <TabCustom
        data={tabs.map(({ title }) => title)}
        active={tab}
        onChange={handleTab}
        className={classes.tabContainer}
      />
      <div
        style={{ minHeight: "85%" }}
        className="d-flex flex-column flex p-4 p-md-0"
      >
        <p className="font-weight-bold basic color-primary fs-14 text-uppercase d-none d-md-block">
          {title}
        </p>
        <pre className={classNames("basic fs-14 mb-0", classes.subTitle)}>
          {subTitle}
        </pre>
        <Content
          type={srcData}
          data={data_tab[srcData]}
          loading={loading}
          once={isFetch.current[srcData]}
          onClickItem={handleOnClickTab[tab]}
        />
      </div>
    </TempMain>
  );
};

const Content = memo(({ type, data, loading, once, onClickItem }) => {
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (data.length) setPage(0);
  }, [data.length]);

  const { pages, first, last } = useMemo(
    () => ({
      pages: Math.ceil(data.length / 8),
      first: 8 * page,
      last: 8 * page + 7,
    }),
    [data, page]
  );

  return (
    <>
      <Grid
        container
        className="mt-4"
        alignItems="center"
        spacing={2}
        item
        lg={10}
        xs={12}
      >
        {loading || !once ? (
          <SkeletonStatement />
        ) : data.length > 0 ? (
          data
            .filter((_, index) => index >= first && index <= last)
            .map((item, index) => (
              <Grid item xs={12} md={6} key={`statement-${index}`}>
                <CardEStatement
                  id={item?.title?.replace(/ /g, "-").toLowerCase() ?? item}
                  item={item}
                  onClick={() => onClickItem(item)}
                />
              </Grid>
            ))
        ) : (
          <EmptyData />
        )}
      </Grid>
      {pages > 1 && (
        <Grid item xs={12} lg={10} className="mt-4 pt-3">
          <PaginationDot page={page} pages={pages} onChange={setPage} />
        </Grid>
      )}
      <DialogAlert resultName="statement" alertAppear="error" />
    </>
  );
});

export default LaporanPage;
