import * as types from "../actions/types";

const initial = {
  hide_modal_login: false,
  text_modal_login: [
    "Gunakan jaringan internet yang menggunakan kata kunci dan tidak disarankan menggunakan akses wi-fi di tempat umum.",
    "Ganti password secara berkala dengan kombinasi karakter huruf dan angka yang unik dan tidak mudah ditebak.",
    "Selalu waspada terhadap upaya penipuan dari oknum yang mengaku sebagai karyawan Syailendra yang menanyakan data pribadi termasuk kode akses.",
    "Jika menerima pesan yang menyatakan bahwa aplikasi/sertifikat/layanan tidak sah, jangan melanjutkan akses perbankan dan segera hubungi kami.",
    "Jika kehilangan handphone, segera hubungi kami untuk melakukan pengamanan akun.",
  ],
  onboarding: false,
};

const persistReducer = (state = initial, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_PERSIST_REDUCER:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export default persistReducer;
