import React from "react";
import GraphAllocation from "../../molecule/SmartPlan/GraphAllocation/GraphAllocation";
import KeunggulanSmartPlan from "../../molecule/SmartPlan/KeunggulanSmartPlan";
import ContainerDesktop from "../../organism/Container/ContainerDesktop";

const TempSmartPlanDesktop = ({ data, children }) => {
  const { contentProduct, simulasi, pushPage } = data;
  console.log(contentProduct, 'test', pushPage, "dataTemp")
  return (
    <ContainerDesktop
      left={<div className="mx-sm-4 my-4">{simulasi}</div>}
      right={
        <div className="mx-sm-4 my-4">
          <KeunggulanSmartPlan />
          <GraphAllocation
            {...contentProduct}
          />
        </div>
      }
    />
  );
};

TempSmartPlanDesktop.defaultProps = {
  data: {},
};

export default TempSmartPlanDesktop;
