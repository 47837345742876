import {
  Card,
  CircularProgress,
  Grid,
  LinearProgress,
} from "@material-ui/core";
import classNames from "classnames";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import arrowBtn from "../../../../assets/icons/arrowBtn.png";
import iconOffTarget from "../../../../assets/icons/iconOffTarget.svg";
import iconOnTarget from "../../../../assets/icons/iconOnTarget.svg";
import iconTarget from "../../../../assets/icons/iconTarget.svg";
import chartHelper from "../../../../helper/chartHelper";
import processHelper from "../../../../helper/processHelper";
import textHelper from "../../../../helper/textHelper";
import BasicText from "../../../atom/BasicText/BasicText";
import ButtonContained from "../../../atom/Button/ButtonContained";

const CardProgressGoals = ({ onClick, summary, track }) => {
  const isTrackAvailable = Object.keys(track).length;
  const loading = useSelector((state) =>
    processHelper.findProcess(
      "goal-track-recalculate",
      state.stateReducer.loading_stack
    )
  );
  const { fr_total_portofolio } = summary;
  const {
    target_amount,
    is_on_target,
    target_monthly_amount,
    time_info,
    actual_monthly_amount,
    progress,
    fr_periodic_monthly_amount,
    line_color,
    line_color_actual,
  } = track;

  const progressGoals = {
    onTarget: {
      id: "on",
      enabled: false,
      target: "On Target",
      classNameTxt: "color-primary",
      icon: iconOnTarget,
      btn: (
        <span className="basic">
          Jadikan Pembelian Ini Invest Berkala
          <img src={arrowBtn} alt="" className="ml-3" />
        </span>
      ),
      classNameBtn: {
        backgroundColor: "#ccc",
        color: "#666",
        fontSize: 12,
        fontWeight: "700",
        height: 60,
      },
      onClick: () => console.log("onTarget"),
    },
    offTarget: {
      id: "off",
      enabled: true,
      target: "Off Target",
      classNameTxt: "gray-4",
      icon: iconOffTarget,
      btn: "REKALKULASI",
      classNameBtn: {
        backgroundColor: "primary",
        color: "#fff",
        fontWeight: "600",
        fontSize: 11,
      },
      onClick: () => onClick(),
    },
  };

  const txtInvest = [
    {
      title: "Target Investasi",
      amount: textHelper.printMoney(target_amount),
      className: "fw-600",
    },
    {
      title: "Investasi Bulanan",
      amount: fr_periodic_monthly_amount,
      color: "color-primary",
    },
  ];

  const prog = is_on_target ? "onTarget" : "offTarget";
  const isTarget = progressGoals[prog];
  const isOnTarget = prog === "onTarget";

  useEffect(() => {
    if (isTrackAvailable)
      chartHelper.createProChart("graphProg", {
        data: [
          {
            name: "Target",
            data: target_monthly_amount?.map((amount) => Math.round(amount)),
            color: line_color,
          },
          {
            name: "Actual",
            data: actual_monthly_amount?.map((amount) => Math.round(amount)),
            color: line_color_actual,
          },
        ],
        time: time_info.map((date) => textHelper.printDateFormat(date, "MMM")),
      });
  }, [
    actual_monthly_amount,
    isTrackAvailable,
    is_on_target,
    line_color,
    line_color_actual,
    target_monthly_amount,
    time_info,
    track,
  ]);

  return (
    <Card className="mb-3 p-3 shadow-card">
      {isTrackAvailable ? (
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <div className="border-bottom d-flex align-items-center mb-2">
              <img src={isTarget.icon} alt="icon target" className="m-2" />
              <BasicText
                className={classNames(
                  "ml-3 fs-12 fw-600",
                  isTarget.classNameTxt
                )}
              >
                {isTarget.target}
              </BasicText>
            </div>
            <div className="d-flex" style={{ height: "83%" }}>
              <img
                src={iconTarget}
                alt="Icon Target"
                style={{ height: "26px" }}
                className="m-2"
              />
              <div className="d-flex flex-column justify-content-between">
                {txtInvest.map((data, id) => (
                  <div className="ml-2" key={id}>
                    <BasicText className="mb-2 fs-12">{data.title}</BasicText>
                    <BasicText
                      className={classNames(
                        data.className,
                        "fs-14",
                        isOnTarget && data.color ? "color-primary" : "gray-3"
                      )}
                    >
                      {data.amount}
                    </BasicText>
                  </div>
                ))}
              </div>
            </div>
          </Grid>
          <Grid item xs={7}>
            <div className="mb-3">
              <BasicText className="mb-2 fs-12">Investasi Saat Ini</BasicText>
              <BasicText className="fs-14">
                {fr_total_portofolio}
              </BasicText>
            </div>
            <div id="graphProg" className="mb-3"></div>
            <div className="d-flex align-items-center mb-3">
              <BasicText className="mr-2 fs-12">Progres</BasicText>
              <BasicText className="mr-2 fs-10">{progress}%</BasicText>
              <LinearProgress
                className="w-100"
                variant="determinate"
                value={progress}
              />
            </div>
            <div className="d-flex justify-content-center">
              {isTarget.enabled && (
                <ButtonContained
                  id={`${isTarget.id}-btn`}
                  text={isTarget.btn}
                  loading={loading}
                  style={isTarget.classNameBtn}
                  radius={6}
                  onClick={isTarget.onClick}
                />
              )}
            </div>
          </Grid>
        </Grid>
      ) : (
        <div className="d-flex justify-content-center">
          <CircularProgress />
        </div>
      )}
    </Card>
  );
};

export default CardProgressGoals;
