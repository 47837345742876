import { Card, Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import ProductRecommendChart from '../../molecule/Profile/ProductRecommendChart'
import { DetailGraph } from '../../molecule/SmartPlan/GraphAllocation/GraphAllocation'
import classNames from "classnames";

const useStyles = makeStyles(theme => {
    const isDesktop = theme.breakpoints.up("lg")
    return {
        container: {
            padding: "13px 31px 16px 30px",
            [isDesktop]: { padding: "46px 0 66px 38px" }
        },
        content_chart: {
            paddingRight: 0
        },
        chart: {
            height: 187,
            marginTop: 20,
            [isDesktop]: {
                marginLeft: 36,
            }
        },
        detail_chart: {
            "& .detail-row": {
                "& .detail-name": {
                    fontSize: 13,
                    fontWeight: 500,
                },
                "& .detail-allocation": {
                    color: theme.palette.secondary.main,
                    textAlign: "right",
                }
            },
            [isDesktop]: {
                marginTop: 95,
                paddingLeft: 61
            }
        }
    }
})

const GraphProductRisk = ({ chartColor, profileProduct, profileDetail: { name, desc } }) => {
    const classes = useStyles()
    return (
        <Card className={classNames(classes.container, 'shadow-card')} >
            <Grid container alignItems="flex-start">
                <Grid item xs={12} lg={3} className={classes.content_chart}>
                    <div className="ml-4">
                        <p className="text-center fs-26 font-weight-medium color-primary mb-0 text-uppercase">
                            {name}
                        </p>
                        <p className="basic mb-4 text-center">
                            {desc}
                        </p>
                    </div>
                    <p className="basic fs-14 font-weight-bold">
                        Rekomendasi produk:
                    </p>
                    <ProductRecommendChart
                        containerClass={classes.chart}
                        chartColor={chartColor}
                        profileRiskProduct={profileProduct}
                    />
                </Grid>
                <Grid item xs={12} lg={5} >
                    <DetailGraph colors={chartColor} data={profileProduct} className={classes.detail_chart} />
                </Grid>
            </Grid>
        </Card>
    )
}

GraphProductRisk.defaultProps = {
    chartColor: [],
    profileProduct: [],
    profileDetail: {}
}

export default GraphProductRisk
