import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "64% 1% 35%",
    minHeight: "100vh",
    paddingBottom: "4rem",
    [theme.breakpoints.down(1025)]: {
      gridTemplateColumns: "100%",
    },
  },
}));

const ContainerDesktop = (props) => {
  const classes = useStyles();
  const { right, left } = props;
  return (
    <div className={classes.root}>
      {left}
      <div className="d-flex justify-content-center">
        <Divider orientation="vertical" light />
      </div>
      {right}
    </div>
  );
};

export default ContainerDesktop;
