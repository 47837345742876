import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import stateCallApi from "../../../config/API/stateCallApi";
// import { gbl_act_lc } from "../../../config/redux/actions/state";
import { decrypt } from "../../../helper/EncryptHelper";
// import useStyles from "./useStyles";

const CryptPage = (props) => {
  const {
    location: { search },
  } = useHistory();
  // const token = useSelector((state) => state.authReducer.token);
  const key = useSelector((state) => state.stateReducer.tmp_key);
  // const dispatch = useDispatch();
  useEffect(() => {
    console.log("key", key);
    if (key) {
      const paramsUrl = new URLSearchParams(search).get("d");
      const data = JSON.parse(decrypt(paramsUrl), key);
      console.log(data);
      //   const { redirect, params } = data;
    }
    // const { openUrl, params } = state || {}
    // dispatch(gbl_act_lc({
    // 	...stateCallApi.crypt,
    // 	params: { type: 'encrypt', data: token },
    // 	callback: ({ result }) => {
    // 		if (openUrl) {
    // 			let link = `${openUrl}?key=${result}`
    // 			if (Object.keys(params).length) {
    // 				Object.keys(params).map((key, index) => {
    // 					if (index) link += '&'
    // 					link += `${key}=${params[key]}`
    // 				})
    // 			}
    // 			window.location.replace(link)
    // 		}
    // 		console.log(result)
    // if (typeof onCallback === 'function') onCallback(result)
    // }
    // }))
  }, [key, search]);
  // const classes = useStyles();
  // if (!key)
  // 	return <Redirect to="/" />
  return null;
};

CryptPage.defaultProps = {};

export default CryptPage;
