import React from "react";
import { Grid /*makeStyles*/ } from "@material-ui/core";
import ButtonContained from "../../atom/Button/ButtonContained";
import color from "../../../assets/theme/color";
import pageToURL from "../../../helper/pageToUrlHelper";
import classNames from "classnames";

// const base = {
//   beli: {
//     text: "BELI",
//     backgroundColor: color.primary,
//     onClick: "/subscript"
//   },
//   benchmark: {
//     text: "BENCHMARK",
//     backgroundColor: color.gray_6,
//     onClick: pageToURL.coming_soon
//   },
//   simulasi: {
//     text: "SIMULASI",
//     backgroundColor: color.secondary,
//     onClick: "/simulasi-produk"
//   },
//   kembali: {
//     text: "KEMBALI",
//     backgroundColor: color.gray_6,
//     onClick: "back"
//   }
// };

// const useStyles = makeStyles({
//   button: {
//     padding: "12px 0",
//     fontSize: 14,
//     width: "100%"
//   }
// });

const ThreeButtonProduct = (props) => {
  const { /*temp,*/ onClick, className, is_suspend,code } = props;
  // const S = useStyles();
  const handleClick = (e) => {
    onClick(e);
  };
  const suspended = is_suspend === 1;
  return (
    <Grid
      container
      spacing={1}
      className={classNames(
        "justify-content-between justify-content-md-around",
        className
      )}
    >
      {/* {temp.map((item, index) => {
        let _props = {};
        if (Object.keys(base).indexOf(item) >= 0)
          _props = {
            ...base[item],
            onClick: () => handleClick(base[item].onClick)
          };
        else if (typeof item === "object") {
          const { like, onClick, ...other } = item;
          _props = {
            ...base[like],
            ...other,
            onClick: () => handleClick(onClick || base[like].onClick)
          };
        }
        return (
          index < 3 && (
            <Grid item xs={4} key={index}>
              <ButtonContained className={S.button} radius="5px" {..._props} />
            </Grid>
          )
        );
      })} */}
      <Grid item xs={4} className="text-center">
        <ButtonContained
          id={`${code}-beli`}
          text="BELI"
          className="w-100 fs-11 py-2 font-weight-semibold"
          radius="5px"
          backgroundColor={suspended ? color.gray_6 : color.primary}
          onClick={() => handleClick("/subscript")}
        />
      </Grid>
      <Grid item xs={4} className="text-center">
        <ButtonContained
          id={`${code}-benchmark`}
          text="BENCHMARK"
          className="w-100 fs-11 py-2 font-weight-semibold"
          radius="5px"
          backgroundColor={color.gray_6}
          onClick={() =>
            handleClick(pageToURL.coming_soon, { title: "BENCHMARK" })
          }
        />
      </Grid>
      <Grid item xs={4} className="text-center">
        <ButtonContained
          id={`${code}-simulasi`}
          text="SIMULASI"
          className="w-100 fs-11 py-2 font-weight-semibold"
          radius="5px"
          backgroundColor={color.secondaryLight}
          onClick={() => handleClick("/simulasi-produk")}
        />
      </Grid>
    </Grid>
  );
};

ThreeButtonProduct.defaultProps = {
  temp: ["beli", "benchmark", "simulasi"],
  code : ""
};

export default ThreeButtonProduct;
