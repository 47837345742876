import { createLogic } from "redux-logic";
import url from "../../API/url";
import { requestAxios } from "../../API/request";
import * as types from "../actions/types";
import { batch } from "react-redux";
import { set_loading, remove_loading, set_result } from "../actions/state";
import { set_notif } from "../actions/notification";

const notificationList = createLogic({
  type: types.FETCH_NOTIF_LIST,
  latest: true,
  validate({ getState, action }, allow, done) {
    const { per_page } = getState().notifReducer;
    action.payload = { ...action.payload, per_page };
    allow(action);
  },
  process({ action }, dispatch, done) {
    const name = "notif-list";
    dispatch(set_loading(name));
    requestAxios(url.NOTIF.list, action.payload)
      .then((res) => {
        batch(() => {
          dispatch(remove_loading(name));
          dispatch(set_notif(res.data.data));
        });
      })
      .catch((err) => {
        batch(() => {
          dispatch(remove_loading(name));
          dispatch(
            set_result({ name, data: { type: "error", msg: err.message } })
          );
        });
      })
      .then(done);
  },
});

export default [notificationList];
