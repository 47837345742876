import EmailIcon from "@material-ui/icons/Email";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { object } from "yup";
import { fetch_forgot_pass } from "../../../config/redux/actions/auth";
import processHelper from "../../../helper/processHelper";
import validation from "../../../helper/validateHelper";
import DialogAlert from "../../molecule/Alert/DialogAlert";
import FormRegister from "../../organism/Register/FormRegister";

const validationSchema = object().shape({
  identity: validation.validateEmail("Email"),
});

class PageInputEmail extends PureComponent {
  componentDidUpdate(prevProps) {
    const { otp_token, history } = this.props;
    if (otp_token && otp_token !== prevProps.otp_token)
      history.replace("/forgot-password/validate");
  }

  _handleSubmit = (params) => {
    this.props.fetch_forgot_pass({ type: "request", params });
  };
  render() {
    const loading = processHelper.findProcess(
      "change_pass_request",
      this.props.loading_stack
    );
    return (
      <FormRegister
        ref="form"
        className="justify-content-between flex-grow-1"
        validation={validationSchema}
        initial={{ identity: "" }}
        textSubmit="KIRIM VERIFIKASI EMAIL"
        onSubmit={this._handleSubmit}
        loadingSubmit={loading}
        submitClass="w-inherit default-py-button px-3 mt-0"
        data={[
          {
            name: "identity",
            id : "identity",
            type: "email",
            placeholder: "username@email.com",
            label: "Email",
            iconLabel: EmailIcon,
            labelClass: "mb-3",
          },
        ]}
      >
        <DialogAlert alertAppear="both" resultName="change_pass_request" />
      </FormRegister>
    );
  }
}

const mapStateToProps = ({ stateReducer, authReducer }) => ({
  loading_stack: stateReducer.loading_stack,
  otp_token: authReducer.otp_token,
});
const mapDispatchToProps = {
  fetch_forgot_pass,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PageInputEmail)
);
