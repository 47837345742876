import React from "react";
import ilus from "../../../assets/img/ilustration";
import BasicText from "../../atom/BasicText/BasicText";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";

const IlustrasiRegis1 = ({
  className,
  image,
  imageClass,
  title,
  titleClass,
  subTitle,
  subTitleClass,
}) => {
  const isDesktop = useMediaQuery({ minWidth: 769 });
  return (
    <div
      className={classNames(
        "d-flex flex-column align-self-start",
        !isDesktop && "w-100",
        className
      )}
    >
      {image && (
        <img
          src={ilus[image]}
          alt=""
          className={classNames("mb-3 align-self-center", imageClass)}
        />
      )}
      {title && (
        <BasicText
          className={classNames(
            "fs-14 font-weight-bold color-primary",
            titleClass
          )}
        >
          {title}
        </BasicText>
      )}
      {subTitle && (
        <BasicText
          className={classNames("fs-14 gray-5", subTitleClass, title && "mt-1")}
        >
          {subTitle}
        </BasicText>
      )}
    </div>
  );
};

export default IlustrasiRegis1;
