import * as types from "./types";

export const set_txn_rd = (payload) => ({
  type: types.SET_TXN_RD,
  payload,
});

export const set_subscript = (payload) => {
  return {
    type: types.SET_SUBSCRIPT,
    payload,
  };
};
export const set_subscript_props = (payload) => {
  return {
    type: types.SET_SUBSCRIPT_PROPS,
    payload,
  };
};
export const set_redeem_props = (payload) => {
  return {
    type: types.SET_REDEEM_PROPS,
    payload,
  };
};
export const set_switch_props = (payload) => {
  return {
    type: types.SET_SWITCH_PROPS,
    payload,
  };
};
export const check_bonus_props = (payload) => ({
  type: types.CHECK_BONUS_PROPS,
  payload,
});
export const check_bonus = (payload) => ({ type: types.CHECK_BONUS, payload });
export const set_payment_props = (payload) => {
  return {
    type: types.SET_PAYMENT_PROPS,
    payload,
  };
};
export const set_payment = (payload) => {
  return {
    type: types.SET_PAYMENT,
    payload,
  };
};
export const get_invoice_props = (payload) => {
  return {
    type: types.GET_INVOICE_PROPS,
    payload,
  };
};
export const get_invoice = (payload) => {
  return {
    type: types.GET_INVOICE,
    payload,
  };
};
export const set_proof_props = (payload) => {
  return {
    type: types.SET_PROOF_PROPS,
    payload,
  };
};
export const set_proof = (payload) => {
  return {
    type: types.SET_PROOF,
    payload,
  };
};
export const get_history_props = (payload) => {
  return {
    type: types.GET_HISTORY_PROPS,
    payload,
  };
};
export const get_history = (payload) => {
  return {
    type: types.GET_HISTORY,
    payload,
  };
};
export const order_data = (payload) => ({ type: types.ORDER_DATA, payload });
export const confirm_payment_data = (payload) => ({
  type: types.CONFIRM_PAYMENT_DATA,
  payload,
});

export const booking_lc = (params = {}, callback = () => { }) => ({
  type: types.GLOBAL_ACTION_LC,
  payload: {
    path: 'TXN.booking',
    key: 'booking-rdp',
    method: 'post',
    params,
    callback

  }
})
