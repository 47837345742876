import { makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import * as yup from "yup";
import color from "../../../assets/theme/color";
import stateCallApi from "../../../config/API/stateCallApi";
import { set_option_props } from "../../../config/redux/actions/option";
import {
  add_acc_bank_props,

} from "../../../config/redux/actions/profile";
import { gbl_act_lc } from "../../../config/redux/actions/state";
import processHelper from "../../../helper/processHelper";
import textHelper from "../../../helper/textHelper";
import BasicText from "../../atom/BasicText/BasicText";
import DialogAlert from "../../molecule/Alert/DialogAlert";
import GenerateForm from "../../template/generate/GenerateForm";
const { memo, useCallback, useEffect, useMemo } = React

const schema = yup.object().shape({
  account_name: yup.string().required(),
  bank_id: yup.string().required("Pilihan bank harus diisi"),
  account_number: yup
    .string()
    .test("only-num", "Format Tidak Sesuai", (val) => !val.match(/[A-z]/g))
    .required("Nomor rekening harus diisi"),
  bank_branch: yup.string().required("Cabang bank harus diisi"),
});

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.up(768)]: {
      justifyContent: "start",
    },
    "& .btnNext": {
      fontSize: 14,
      fontWeight: 500,
      padding: "12px 0",
      flex: 10,
      marginRight: "1rem",
      [theme.breakpoints.down(769)]: {
        marginRight: 0,
        width: "100%",
      },
    },
    "& .btnCancel": {
      fontSize: 14,
      fontWeight: 500,
      padding: "12px 0",
      flex: 8,
      [theme.breakpoints.down(769)]: {
        display: "none",
      },
    },
  },
  inputText: {
    marginBottom: "1.5rem",
    maxWidth: 345,
  },
  container: {
    minHeight: "50vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.up(768)]: {
      minHeight: "60vh",
    },
  },
}));

const INITIAL_FORM = {
  account_name: "",
  bank_id: "",
  account_number: "",
  bank_branch: "",
}

const useRedux = (resultName) => {
  const bank_list = useSelector(state => state.optionReducer.bank_list)
  const profile = useSelector(state => state.profileReducer.profile)
  const loading = useSelector(state => processHelper.findProcess(resultName, state.stateReducer.loading_stack))
  const loading_bank_list = useSelector(state => processHelper.findProcess("get_option", state.stateReducer.loading_stack))
  return { bank_list, profile, loading, loading_bank_list }
}

const Loading = () => (
  <>
    {[0, 1, 2, 3].map(key => (
      <div key={key}>
        <Skeleton variant="text" width="40%" className="mb-2" />
        <Skeleton variant="rect" height={52} width="90%" className="mb-3" />
      </div>
    ))}
  </>
)

const ContentPage = ({ resultName, title, actionSubmit, initial = INITIAL_FORM }) => {
  // #region - const
  const classes = useStyles();
  const dispatch = useDispatch()
  const { goBack } = useHistory()
  const { bank_list, profile, loading, loading_bank_list } = useRedux(resultName)
  const formData = useMemo(() => [
    {
      target: "account_name",
      type: 'text',
      label: "Nama Pemilik Bank"
    },
    {
      target: "bank_id",
      type: "select",
      data: bank_list,
      label: "Nama Bank",
    },
    {
      target: "account_number",
      onlyNum: true,
      inputClass: "hide-arrow-inside",
      label: "Nomor Rekening",
    },
    {
      target: "bank_branch",
      type: "text",
      label: "Cabang Bank",
    },
  ].map(({ inputClass, ...other }) => ({
    ...other,
    inputClass: `${inputClass} fs-16 gray-4`,
    marginClass: classes.inputText,
    labelClass: "fs-14 font-weight-light",
    variant: "outlined",
  })), [bank_list, classes.inputText])
  // #endregion

  const handleSubmit = useCallback((params) => {
    if (!params.account_name) {
      params["account_name"] = textHelper.printFullname(profile)
    }
    dispatch(actionSubmit(params))
  }, [actionSubmit, dispatch, profile])

  useEffect(() => {
    dispatch(set_option_props('bank_list'))
  }, [dispatch])

  return (
    <div>
      <BasicText className="fs-14 mb-4 font-weight-bold" color="primary">
        {title}
      </BasicText>
      {loading_bank_list ?
        <Loading /> :
        <GenerateForm
          formData={formData}
          initial={initial}
          schema={schema}
          containerClass={classes.container}
          buttonClass="btnNext"
          buttonText="SIMPAN"
          buttonCancelText="BATAL"
          buttonCancelClass="btnCancel"
          onClickCancel={goBack}
          buttonContainerClass={classes.buttonContainer}
          onSubmit={handleSubmit}
          loading={loading}
          containerOther={
            <div
              className="w-100 text-center py-3 gray-4 fs-12 my-4"
              style={{ backgroundColor: color.gray_8, padding: "0 80px" }}
            >
              Nama Pemilik Rekening harus sesuai KTP yang didaftarkan
            </div>
          }
        />}

      <DialogAlert
        resultName={resultName}
        alertAppear="both"
        buttonText="OK"
        submitSuccessOnBlur
        submitIfSuccess={goBack}
      />
    </div>
  )
}

const AddBankAcc = () => {
  const { state: { initial = {} } = {} } = useLocation()
  const { id } = useParams()
  const isUpdate = id === 'update-bank'

  const propsComponent = isUpdate ? {
    title: "UBAH REKENING BANK",
    resultName: stateCallApi.updateBank.key,
    actionSubmit: (params) => gbl_act_lc({ ...stateCallApi.updateBank, params }),
    initial
  } : {
    title: "TAMBAH REKENING BANK",
    resultName: "add_acc_bank",
    actionSubmit: add_acc_bank_props
  }

  return <ContentPage {...propsComponent} />
    ;
};

export default memo(AddBankAcc, () => false);
