import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import classNames from "classnames";
import React from "react";
import { useMediaQuery } from "react-responsive";
import LabelInput from "../../atom/BasicText/LabelInput";
import { makeStyles } from "@material-ui/styles";
const { createRef, useEffect, useState } = React;

const useStyles = makeStyles({
  input: {
    "& input": {
      padding: "15px 5px",
      fontFamily: "Montserrat",
      fontSize: 14,
    },
    "& legend>span": {
      display: "none",
    },
  },
  disabled: {
    //disabled
    "&.Mui-disabled fieldset": {
      background: "rgba(0,0,0,0.03)",
    },
  },
  stylePass: {
    fontWeight: "900",
    letterSpacing: 1.5,
  },
});

const VisibileIcon = ({ visible }) => {
  const Component = !visible ? VisibilityOff : Visibility;
  return <Component className="gray-5" />;
};

const InputText = ({
  containClass,
  variant,
  label,
  labelClass,
  onChange,
  value,
  type,
  className,
  EndIcon,
  StartIcon,
  startIconClass,
  isFull,
  propsContainLabel,
  componentSideLabel,
  iconLabel,
  labelInput,
  disabledStyle,
  withInput,
  labelChild,
  textLabelClass,
  contentInputClass,
  ...other
}) => {
  const refInput = createRef();
  const classes = useStyles();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  let cursor = 0;

  const [state, setState] = useState({
    visiblePass: false,
    isPassword: false,
  });

  function inputHandler(e) {
    const { selectionStart, value } = e.target || {};
    cursor = selectionStart;
    onChange(value);
  }

  function setVisible() {
    setState({ ...state, visiblePass: !state.visiblePass });
  }

  function _setCursor() {
    refInput.current.selectionStart = cursor;
    refInput.current.selectionEnd = cursor;
  }

  useEffect(() => {
    if (type === "password" && !state.isPassword)
      setState({ isPassword: true });
    withInput && _setCursor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const { visiblePass, isPassword } = state;
  const typePass = visiblePass ? "text" : type;
  const withEndAdorment = isPassword || EndIcon;
  const stylePass = !visiblePass && value !== "" && type === "password";
  return (
    <div className={containClass}>
      <Grid container alignItems="flex-start" {...propsContainLabel}>
        <LabelInput
          text={label}
          textClass={textLabelClass}
          className={labelClass}
          IconMaterial={iconLabel}
          children={labelChild}
        />
        {!isMobile && componentSideLabel}
      </Grid>
      {withInput && (
        <TextField
          variant={variant}
          className={contentInputClass}
          ref={refInput}
          label={labelInput}
          onChange={inputHandler}
          value={value}
          type={isPassword ? typePass : type}
          fullWidth={isFull}
          inputProps={{
            className: classNames(
              "hide-arrow",
              stylePass && classes.stylePass,
              className
            ),
          }}
          InputProps={{
            className: classNames(
              classes.input,
              disabledStyle && classes.disabled
            ),
            startAdornment: StartIcon && (
              <InputAdornment>
                <StartIcon
                  className={classNames(
                    "fs-18 mr-2 color-secondary",
                    startIconClass
                  )}
                />
              </InputAdornment>
            ),
            endAdornment: withEndAdorment && (
              <InputAdornment style={{marginRight: -12}}>
                {isPassword ? (
                  <IconButton onClick={setVisible} id={`visibility${!visiblePass ? "-off" : ""}`}>
                    <VisibileIcon visible={visiblePass} />
                  </IconButton>
                ) : (
                  <EndIcon />
                )}
              </InputAdornment>
            ),
          }}
          {...other}
        />
      )}
      {componentSideLabel && (
        <Grid
          container
          justify="flex-end"
          alignItems="flex-start"
          className="mt-1"
        >
          {isMobile && componentSideLabel}
        </Grid>
      )}
    </div>
  );
};

InputText.defaultProps = {
  variant: "outlined",
  isFull: true,
  onChange: () => { },
  value: "",
  disabledStyle: true,
  withInput: true,
};

export default InputText;
