import React from "react";
import * as yup from "yup";
import GenerateForm from "../../template/generate/GenerateForm";
import { connect } from "react-redux";
import {
  change_pin_props,
  forgot_pin_props,
} from "../../../config/redux/actions/auth";
import processHelper from "../../../helper/processHelper";
import DialogAlert from "../../molecule/Alert/DialogAlert";
import BasicText from "../../atom/BasicText/BasicText";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
// import SnackAlert from "../../molecule/Alert/SnackAlert";

const mapState = (state) => ({
  loading_stack: state.stateReducer.loading_stack,
});

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.up(768)]: {
      justifyContent: "start",
    },
    "& .btnNext": {
      fontSize: 14,
      fontWeight: 600,
      padding: "12px",
      flex: 1,
      marginRight: 20,
      [theme.breakpoints.down(769)]: {
        marginRight: 0,
        width: "100%",
      },
    },
    "& .btnCancel": {
      fontSize: 14,
      flex: 1,
      fontWeight: 600,
      padding: "12px 0",
      [theme.breakpoints.down(769)]: {
        display: "none",
      },
    },
  },
  inputText: {
    marginBottom: "1.5rem",
    maxWidth: 345,
  },
  container: {
    minHeight: "55vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.up(768)]: {
      minHeight: "60vh",
    },
  },
}));
const ChangePin = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const {
    change_pin_props,
    loading_stack,
    onClickCancel,
    pinMode,
    forgot_pin_props,
  } = props;
  const schema = yup.object().shape(
    pinMode === "change"
      ? {
          old_pin: yup
            .string()
            .min(6, "Min. 6 karakter")
            .max(6, "Maks. 6 karakter")
            .required("Pin saat ini harus diisi"),
          pin: yup
            .string()
            .min(6, "Min. 6 karakter")
            .max(6, "Maks. 6 karakter")
            .required("Pin baru harus diisi"),
          pin_confirm: yup
            .string()
            .oneOf([yup.ref("pin"), null], "Pin tidak sama"),
        }
      : {
          password: yup
            .string()
            .min(8, "Min. 8 karakter")
            .required("Password baru harus diisi"),
          pin: yup
            .string()
            .min(6, "Min. 6 karakter")
            .max(6, "Maks. 6 karakter")
            .required("Pin baru harus diisi"),
          pin_confirm: yup
            .string()
            .oneOf([yup.ref("pin"), null], "Pin tidak sama"),
        }
  );
  const [state, setState] = React.useState({
    [pinMode === "change" ? "old_pin" : "password"]: "",
    pin: "",
    pin_confirm: "",
  });
  const formData = [
    pinMode === "change"
      ? {
          target: "old_pin",
          marginClass: classes.inputText,
          type: "password",
          inputClass: "fs-16",
          label: "PIN SAAT INI",
          labelClass: "fs-14",
          variant: "outlined",
          passVisible: true,
        }
      : {
          target: "password",
          marginClass: classes.inputText,
          type: "password",
          inputClass: "fs-16",
          label: "PASSWORD",
          labelClass: "fs-14 color-secondary",
          passVisible: true,
          variant: "outlined",
        },
    {
      target: "pin",
      marginClass: classes.inputText,
      type: "password",
      inputClass: "fs-16",
      label: "PIN BARU",
      labelClass: "fs-14",
      variant: "outlined",
      passVisible: true,
    },
    {
      target: "pin_confirm",
      marginClass: classes.inputText,
      type: "password",
      inputClass: "fs-16",
      label: "KONFIRMASI PIN BARU",
      labelClass: "fs-14",
      variant: "outlined",
      passVisible: true,
    },
  ];
  const handleSubmit = (e) => {
    setState((state) => ({ ...state, ...e }));
    if (pinMode === "change") change_pin_props(e);
    else forgot_pin_props(e);
  };
  function _handleSuccess() {
    history.goBack();
  }
  return (
    <div>
      <BasicText className="fs-14 mb-4 font-weight-bold" color="primary">
        UBAH PIN
      </BasicText>
      <GenerateForm
        formData={formData}
        initial={state}
        schema={schema}
        containerClass={classes.container}
        buttonClass="btnNext"
        buttonText="UBAH PIN"
        buttonCancelText="BATAL"
        buttonCancelClass="btnCancel"
        onClickCancel={onClickCancel}
        buttonContainerClass={classes.buttonContainer}
        onSubmit={handleSubmit}
        loading={processHelper.findProcess(
          pinMode === "change" ? "change_pin" : "forgot_pin",
          loading_stack
        )}
      />
      {/* <SnackAlert name="change_pin" alertAppear="both" /> */}
      <DialogAlert
        resultName={pinMode === "change" ? "change_pin" : "forgot_pin"}
        alertAppear="both"
        buttonText="OK"
        submitIfSuccess={_handleSuccess}
      />
    </div>
  );
};

export default connect(mapState, { change_pin_props, forgot_pin_props })(
  ChangePin
);
