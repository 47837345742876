import React from "react";
import PropTypes from "prop-types";
import ButtonProfile from "../../molecule/Profile/ButtonProfile";
import { withRouter } from "react-router-dom";
import pageToURL from "../../../helper/pageToUrlHelper";
import Close from "@material-ui/icons/Close";

const MenuProfile = (props) => {
  const { marginClass, menuValue, logoutModal } = props;
  const { coming_soon } = pageToURL;
  const dataButton = {
    identity: [
      {
        icon: "person",
        text: "DATA DIRI",
        link: "/profil/data-profil",
        id: 0,
      },
      { icon: "setting", text: "SETTING", link: "/profil/setting", id: 1 },
      { icon: "file", text: "E-STATEMENT", link: "/profil/estatement", id: 2 },
      { icon: "file", text: "DOCUMENT", link: "/profil/document", id: 3 },
    ],
    promo: [
      {
        icon: "codePromo",
        text: "KODE PROMO",
        link: "/profil/promo",
        params: { title: "KODE PROMO" },
        id: 4,
      },
      {
        icon: "voucher",
        text: "VOUCHER",
        link: coming_soon,
        params: { title: "VOUCHER" },
        id: 5,
      },
      {
        icon: "referralCode",
        text: "KODE MARKETING",
        link: coming_soon,
        params: { title: "KODE MARKETING" },
        id: 6,
      },
    ],
    info: [
      {
        icon: "faq",
        text: "FAQ REKSA DANA",
        link: "/knowledge-center",
        params: { tab: 1 },
        id: 12,
      },
      { icon: "glosarium", text: "GLOSSARY", link: "/profil/glossary", id: 6 },
      {
        icon: "securitytips",
        text: "TIPS KEAMANAN",
        link: "/profil/tips-keamanan",
        id: 8,
      },
      {
        icon: "notification",
        text: "NOTIFIKASI",
        link: "/profil/notification",
        id: 9,
      },
      {
        icon: "rateUs",
        text: "RATE US",
        link: "/profil/rate-us",
        id: 10,
      },
      {
        icon: <Close style={{ marginRight: 22 }} className="fs-20" />,
        text: "HAPUS AKUN",
        link: "/profil/delete-account",
        id: 13,
      },
    ],
    auth: [
      {
        icon: "contactUs",
        text: "CONTACT US",
        link: coming_soon,
        id: 11,
      },
      { icon: "logout", text: "LOG OUT", link: "logout", id: 12 },
    ],
  };
  const handleClick = (e, params) => {
    if (e === "logout") {
      logoutModal(props.log_out);
    } else if (e === "glossary") {
      props.glossary();
    } else {
      props.history.push(e, params);
    }
  };
  return Object.keys(dataButton).map((key, id1) => (
    <div className={marginClass} key={id1}>
      {dataButton[key].map((e, id2) => (
        <ButtonProfile
          icon={e.icon}
          text={e.text}
          key={id1 + "" + id2}
          active={e.id === menuValue}
          onClick={() => handleClick(e.link, e.params)}
        />
      ))}
    </div>
  ));
};

MenuProfile.propTypes = {
  marginClass: PropTypes.string,
  menuValue: PropTypes.number,
  glossary: PropTypes.func,
};

MenuProfile.defaultProps = {
  onClick: () => null,
};

export default withRouter(MenuProfile);
