import React from "react";
import BasicText from "../../atom/BasicText/BasicText";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import icon from "../../../assets/img/icon/mainMenu";
import { useSelector, useDispatch } from "react-redux";
import { Badge, withStyles } from "@material-ui/core";
import { set_profile_data } from "../../../config/redux/actions/profile";
const Sidebar = (props) => {
  const [state, setState] = React.useState(true);
  const { navValue, isInitialPage } = props;
  const menu = [
    {
      title: "DASHBOARD",
      icon: icon.dashboard,
      id: 0,
    },
    {
      title: "PRODUK",
      icon: icon.product,
      id: 1,
    },
    {
      title: "TRANSAKSI",
      icon: icon.txn,
      id: 2,
    },
    {
      title: "PORTOFOLIO",
      icon: icon.proto,
      id: 3,
    },
    {
      title: "PROFILE",
      icon: icon.profile,
      id: 4,
    },
  ];
  const handleClick = (e) => {
    if (e !== navValue || !isInitialPage) {
      switch (e) {
        case 0:
          props.history.push("/");
          break;
        case 1:
          props.history.push("/produk");
          break;
        case 2:
          props.history.push("/transaksi");
          break;
        case 3:
          props.history.push("/portofolio");
          break;
        case 4:
          props.history.push("/profil/data-profil");
          break;
        default:
          props.history.push("/");
          break;
      }
    }
  };
  const txn_count = useSelector((state) => state.profileReducer.txn_count);
  const dispatch = useDispatch();
  const StyledBadge = withStyles((theme) => ({
    badge: {
      left: 0,
      top: 12,
    },
  }))(Badge);
  React.useEffect(() => {
    if (state && txn_count === null) {
      dispatch(
        set_profile_data({ txn_count: parseInt(localStorage.getItem("txn_count")) })
      );
      setState(false);
    }
  }, [state, dispatch, txn_count]);
  return (
    <>
      {menu.map((el, id) => (
        <div
          className={classNames("menu", navValue === el.id ? "active" : "")}
          key={id}
          id={`sidebar-${el.title}`}
          onClick={() => handleClick(id)}
        >
          <div className="px-4 py-3 d-flex">
            <div className="menu-icon">
              <img src={el.icon} alt="" />
            </div>
            <BasicText className="fs-12 ml-2 my-auto">{el.title}</BasicText>
            {el.title === "TRANSAKSI" && (
              <StyledBadge badgeContent={txn_count || 0} color="primary" />
            )}
          </div>
        </div>
      ))}
    </>
  );
};

Sidebar.propTypes = {
  navValue: PropTypes.number,
};

// Sidebar.defaultProps = {
//   navValue: 0
// };

export default withRouter(Sidebar);
