import classNames from "classnames";
import React from "react";
import BasicText from "../../../atom/BasicText/BasicText";

const RowTotal = ({ title, children, ...otherProps }) => (
  <div className={otherProps.classesRoot}>
    <BasicText className={classNames(otherProps.classesTitle)}>
      {title}
    </BasicText>
    <BasicText className={classNames(otherProps.classesChildren)}>
      {children}
    </BasicText>
  </div>
);

RowTotal.defaultProps = {
  classesRoot: "d-flex justify-content-between align-items-center mt-2",
  classesTitle: "fs-10 gray-4 text-left",
  classesChildren: "fs-14 gray-3 text-center",
};

export default RowTotal;
