import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "../reducer/authReducer";
import stateReducer from "../reducer/stateReducer";
import productReducer from "../reducer/productReducer";
import articleReducer from "../reducer/articleReducer";
import portoReducer from "../reducer/portoReducer";
import profileReducer from "../reducer/profileReducer";
import transactionReducer from "../reducer/transactionReducer";
import optionReducer from "../reducer/optionReducer";
import persistReducers from "../reducer/persistReducer";
import notifReducer from "../reducer/notifReducer";
import goalReducer from "../reducer/goalReducer";
import { createLogicMiddleware } from "redux-logic";
import logic from "../logic";

const logicMiddleware = createLogicMiddleware(logic);

const rootReducer = combineReducers({
  authReducer: authReducer,
  stateReducer: stateReducer,
  productReducer: productReducer,
  articleReducer: articleReducer,
  portoReducer: portoReducer,
  profileReducer: profileReducer,
  transactionReducer: transactionReducer,
  optionReducer: optionReducer,
  persistReducer: persistReducers,
  goalReducer,
  notifReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["authReducer", "persistReducer"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(
  persistedReducer,
  compose(
    applyMiddleware(logicMiddleware),
    composeWithDevTools(applyMiddleware())
  )
);

let persistor = persistStore(store);
export { store, persistor };
