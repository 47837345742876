import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/styles/withStyles";
// import color from "../../../assets/theme/color";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import BasicText from "../../atom/BasicText/BasicText";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { connect } from "react-redux";
import { remove_result_l } from "../../../config/redux/actions/state";
import processHelper from "../../../helper/processHelper";
import { makeStyles } from "@material-ui/core";
import { log_out } from "../../../config/redux/actions/auth";

const mapState = (state) => {
  return {
    result_stack: state.stateReducer.result_stack,
  };
};

const useStyles = makeStyles({
  root: {
    "& .MuiDialog-container .MuiPaper-root": {
      maxWidth: 375,
    },
  },
  button: {
    padding: "26px 0",
    width: "100%",
    fontSize: 14,
    fontWeight: "bold",
  },
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: "37px 66px 30px",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: 0,
    justifyContent: "center",
  },
}))(MuiDialogActions);

const DialogAlert = ({
  buttonText,
  result_stack,
  resultName,
  alertAppear,
  remove_result_l,
  submitIfSuccess,
  submitSuccessOnBlur,
  submitIfError,
  maxWidth,
  log_out,
}) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    open: false,
    message: "",
    status: true,
  });
  const { open, message, status } = state;

  const handleModal = (e) => {
    setState((state) => ({ ...state, open: e }));
  };

  function _handleClickSubmit() {
    if (state.name === "log-out") log_out();
    if (submitIfSuccess && state.status) {
      submitIfSuccess();
      submitSuccessOnBlur && handleModal(false);
    } else if (submitIfError) {
      submitIfError();
      handleModal(false);
    } else handleModal(false);
  }

  React.useEffect(() => {
    let label = [resultName, "log-out"];
    if (Array.isArray(resultName)) label = [...resultName, "log-out"];
    let data = processHelper.findResult(label, result_stack);
    if (Object.keys(data).length > 0) {
      if (
        (alertAppear === "error" && data.type === "error") ||
        (alertAppear === "success" && data.type === "success") ||
        alertAppear === "both"
      ) {
        setState((state) => {
          let status = true;
          if (data.type === "error") status = false;
          return {
            ...state,
            name: data.name,
            message: data.msg,
            open: true,
            status,
          };
        });
      }
      remove_result_l(data.name);
    }
  }, [result_stack, resultName, alertAppear, remove_result_l]);

  return (
    <Dialog
      open={open}
      onClose={_handleClickSubmit}
      aria-labelledby="customized-dialog-title"
      className={classes.root}
      fullWidth
      maxWidth={maxWidth}
    >
      <DialogContent dividers>
        <div className="text-center w-100">
          {status ? (
            <CheckCircleIcon className="color-primary fs-50 mb-3" />
          ) : (
            <CancelIcon className="color-primary fs-50 mb-3" />
          )}
        </div>
        <BasicText colorLevel="light" className="fs-14 text-center">
          {message}
        </BasicText>
      </DialogContent>
      <DialogActions>
        <Button
          id="submit-modal"
          autoFocus
          onClick={_handleClickSubmit}
          color="secondary"
          className={classes.button}
        >
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DialogAlert.propTypes = {
  buttonText: PropTypes.string,
  resultName: PropTypes.any,
  alertAppear: PropTypes.string,
};

DialogAlert.defaultProps = {
  alertAppear: "error",
  buttonText: "OK",
  maxWidth: "xs",
};

export default connect(mapState, { remove_result_l, log_out })(DialogAlert);
