import React from "react";
import PropTypes from "prop-types";

const TabsPanel = props => {
  const { value, index, children, ...other } = props;
  return (
    <div
      className={props.className}
      hidden={value !== index}
      id={`tabs-panel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};

TabsPanel.propTypes = {
  value: PropTypes.number,
  index: PropTypes.number,
  className: PropTypes.string
};

export default TabsPanel;
