import React from "react";
import TempMain from "../../template/TempMain";
import ilustration from "../../../assets/img/ilustration";
import classNames from "classnames";
import parse from "html-react-parser";
import textHelper from "../../../helper/textHelper";
import ButtonContained from "../../atom/Button/ButtonContained";
import useStyles from "./useStyles";

const ContentFileAutoDebet = ({ data, title, isCancel, onClick }) => {
  const classes = useStyles();

  const handleSubmit = (path, txn_id) => {
    window.open(path, "_blank");
    onClick(txn_id, path);
  };
  return (
    <TempMain title={title}>
      <div className="p-4">
        <div className="card-custom container-center-basic">
          <img src={ilustration.downloadFile} alt="" className="mb-4" />
          <p className="basic text-center fs-14 font-weight-bold w-90 mb-4 text-capitalize color-primary">
            silakan cetak file
            <br />
            {isCancel && "Pemberhentian"} surat kuasa pendebetan
          </p>
          <p
            className="basic text-center fs-14 mb-5 gray-4"
            style={{ maxWidth: 460 }}
          >
            Tandatangani Surat Kuasa di atas materai Rp6.000, dan kirimkan
            sesuai alamat pengiriman dalam surat kuasa.
          </p>
          {data.map((data, index) => {
            const { icon, name, txn_id, path } = data || {};
            return (
              <div
                key={index}
                className={classNames(classes.rowFile, index && "multi")}
              >
                <div className="d-flex align-items-start">
                  <img alt="bank" src={icon} style={{ height: 15 }} />
                  <p className="basic mx-3 fs-12">
                    {parse(textHelper.printProduct2Line(name))}
                  </p>
                </div>

                <ButtonContained
                  radius="6px"
                  text="DOWNLOAD"
                  classes={{ label: "fs-11" }}
                  className="fs-14 font-weight-medium default-px-button default-py-button small"
                  onClick={() => handleSubmit(path, txn_id)}
                />
              </div>
            );
          })}
        </div>
      </div>
    </TempMain>
  );
};

ContentFileAutoDebet.defaultProps = {
  data: [],
  onClick: () => {},
  isCancel: false,
  title: "PEMBAYARAN",
};

export default React.memo(ContentFileAutoDebet);
