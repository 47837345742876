import { makeStyles } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import illustration from "../../../assets/img/ilustration";
import color from "../../../assets/theme/color";
import stateCallApi from "../../../config/API/stateCallApi";
import { gbl_list_lc } from "../../../config/redux/actions/state";
import BasicText from "../../atom/BasicText/BasicText";
import CardTxn from "../../molecule/Txn/CardTxn/index";
import ContentFilter from "./ContentFilter";
import ContainerWithLoader from "../Container/ContainerWithLoader";
import classNames from "classnames";
import CardBooking from "../../molecule/Txn/CardTxn/CardBooking";
const { useCallback, useMemo } = React;
// import { confirm_payment_data } from "../../../config/redux/actions/transaction";

const useStyles = makeStyles((theme) => ({
  contentTitle: {
    padding: "14px 0 13px 26px",
    borderRadius: 4,
    marginBottom: 15,
    backgroundColor: theme.palette.secondary.main,
  },
}));
const loading = [
  "txn-group-PROCESSED",
  "txn-group-APPROVED",
  "txn-group-DECLINED",
  "txn-group-WAITING",
  "txn-list",
  "booking-list",
];

const HistoryList = ({
  tab,
  BOOKING,
  WAITING,
  PROCESSED,
  APPROVED,
  DECLINED,
}) => {
  const classes = useStyles();
  const { push } = useHistory();
  const history = useSelector((state) => state.transactionReducer.history);
  const dispatch = useDispatch();
  const isHistory = tab === 3;
  const { data, meta } = history || {};
  const [state, setState] = React.useState({
    txn_type_code: undefined,
    txn_status_code: undefined,
    period: undefined,
  });

  const data_use = useMemo(() => {
    switch (tab) {
      case 0:
        return BOOKING;
      case 1:
        return [
          { title: "Dalam Proses", data: PROCESSED, color: color.gray_4 },
          { title: "Berhasil", data: APPROVED, color: color.secondary },
          { title: "Gagal", data: DECLINED, color: color.gray_6 },
        ];
      case 2:
        return WAITING;
      case 3:
        return data;
      default:
        break;
    }
  }, [APPROVED, BOOKING, DECLINED, PROCESSED, WAITING, data, tab]);

  const isEmpty = useMemo(() => {
    if (tab === 1)
      return !data_use
        .map(({ data }) => data?.length ?? 0)
        .reduce((a, b) => a + b, 0);
    return data_use?.length === 0 ?? true;
  }, [data_use, tab]);

  const handleFetchList = useCallback(
    (props, option) => {
      const params = {};
      Object.keys(props)
        .filter((key) => props[key])
        .forEach((key) => (params[`query[${key}]`] = props[key]));
      dispatch(gbl_list_lc({ ...stateCallApi.txn_list, params, option }));
    },
    [dispatch]
  );

  const handleFilter = useCallback(
    (newState) => {
      setState(newState);
      handleFetchList(newState);
    },
    [handleFetchList]
  );

  const handlePagination = useCallback(
    (e, current) => {
      if (Number(meta?.current_page) !== Number(current))
        handleFetchList(state, { ...meta, current_page: current });
    },
    [handleFetchList, meta, state]
  );

  const handleClick = useCallback((link, state) => push(link, state), [push]);

  const handleBooking = useCallback(
    (action, data) => {
      if (action.includes("/")) {
        return push(action, data);
      }
      if (!data) {
        return;
      }
      const { product, amount } = data;
      handleClick("/subscript", {
        products: {
          [product.code]: {
            amount: 100,
          },
          amount,
        },
      });
    },
    [handleClick, push]
  );

  return (
    <>
      {isHistory && <ContentFilter onSubmit={handleFilter} />}
      <ContainerWithLoader processName={loading} loadingHeight="50vh">
        {isEmpty && (
          <div className="card-custom h-70vh d-flex flex-column justify-content-center align-items-center">
            <img src={illustration["emptytxn"]} alt="" className="mb-4" />
            <BasicText className="fs-14 font-weight-bold color-secondary mb-2">
              Belum ada transaksi
            </BasicText>
            <BasicText className="fs-14 text-center">
              Tidak ada transaksi dalam proses Yuk investasi sekarang!
            </BasicText>
          </div>
        )}

        {!isEmpty && [2, 3].includes(tab)
          ? data_use?.map((item) => (
              <CardTxn
                data={item}
                key={item.id}
                onClick={handleClick}
                className="mb-3"
              />
            ))
          : tab === 0
          ? data_use?.map((item) => (
              <CardBooking
                data={item}
                key={item.id}
                onClick={handleBooking}
                className="mb-3"
              />
            ))
          : data_use.map(({ title, data, color }) =>
              data?.length ? (
                <div key={title}>
                  <div
                    style={{ backgroundColor: color }}
                    className={classNames(classes.contentTitle, "shadow-card")}
                  >
                    <p className="basic fs-14 font-weight-bold color-white">
                      {title}
                    </p>
                  </div>
                  {data?.map((item) => (
                    <CardTxn
                      data={item}
                      key={item.id}
                      className="mb-3"
                      onClick={handleClick}
                    />
                  ))}
                </div>
              ) : null
            )}
      </ContainerWithLoader>

      {isHistory && (
        <div className="cont-center">
          <Pagination
            count={meta?.last_page ?? 1}
            page={meta?.current_page ?? 1}
            onChange={handlePagination}
          />
        </div>
      )}
    </>
  );
};

export default HistoryList;
