import React from "react";
import { Card, CircularProgress } from "@material-ui/core";
import BasicText from "../../atom/BasicText/BasicText";
import color from "../../../assets/theme/color";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
const useStyles = makeStyles({
  score: {
    width: 60,
    height: 40,
    border: "1px solid",
    borderColor: color.primary,
  },
  buttonArrowContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
});

const CardRiskScore = (props) => {
  const { score, onChange, className, loading } = props;
  const [scores, setScores] = React.useState(score);
  const handleScores = (e) => {
    let result = scores;
    if (!loading) {
      if (e === "inc") {
        if (Number(result) < 10) {
          result = result + 1;
        }
      } else {
        if (Number(result) > 1) {
          result = result - 1;
        }
      }
      onChange(result);
      setScores(result);
    }
  };
  const classes = useStyles();
  return (
    <Card className={classNames("px-4 py-3 mb-2", className)}>
      <div className="d-flex align-items-center">
        <BasicText className="fs-14 font-weight-bold color-secondary mr-4 my-auto">
          Skor Kamu:
        </BasicText>
        <div
          className={classNames(
            classes.score,
            "d-flex justify-content-center align-items-center mr-3"
          )}
        >
          <BasicText className="fs-28 color-primary font-weight-medium">
            {scores}
          </BasicText>
        </div>
        {onChange && (
          <div className={classes.buttonArrowContainer}>
            <ExpandLessIcon
              id="button-add-score"
              className="color-primary is-link"
              onClick={() => handleScores("inc")}
            />
            <ExpandMoreIcon
              id="button-min-score"
              className="color-primary is-link"
              onClick={() => handleScores("dec")}
            />
          </div>
        )}
        {loading && <CircularProgress size={16} className="ml-2" />}
      </div>
    </Card>
  );
};

CardRiskScore.defaultProps = {
  loading: false,
};

export default CardRiskScore;
