import React, { useEffect } from "react";
import { connect, useStore, useDispatch } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { clear_register } from "../redux/actions/auth";
import { useLastLocation } from "react-router-last-location";
import { set_profile } from "../redux/actions/profile";
const mapState = (state) => {
  return {
    onboarding: state.persistReducer.onboarding,
    authReducer: state.authReducer,
    order: state.transactionReducer.order_data,
    confirm_payment: state.transactionReducer.confirm_payment_data,
  };
};

const PrivateRoute = (props) => {
  const { registerStatus } = useStore().getState().authReducer.register;
  const dispatch = useDispatch();
  useEffect(() => {
    if (registerStatus) {
      dispatch(clear_register());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const condition = useMediaQuery({ maxWidth: 768 });
  const { children, authReducer, onboarding, order, confirm_payment, ...rest } =
    props;
  const { isLogin } = authReducer;
  const prev = useLastLocation();

  useEffect(() => {
    dispatch(set_profile());
  }, [dispatch]);

  return (
    <Route
      {...rest}
      render={({ location, history }) => {
        const { pathname } = location;
        if (isLogin === 1) {
          if (pathname === "/profil") {
            if (condition) {
              return children;
            } else {
              return <Redirect to="/profil/data-profil" />;
            }
          } else if (
            pathname === "/subscript" ||
            pathname === "/redeem" ||
            pathname === "/switch" ||
            pathname === "/invoice" ||
            pathname === "/payment" ||
            pathname === "/confirm-payment" ||
            pathname === "/finish-confirm" ||
            pathname === "/finish-redeem" ||
            pathname === "/finish-switch"
          ) {
            if (pathname === "/invoice") {
              if (prev !== null) {
                if (
                  prev.pathname !== "/payment" &&
                  prev.pathname !== "/confirm-payment" &&
                  prev.pathname !== "/transaksi/detail"
                )
                  return <Redirect to="/" />;
              }
            }
            if (pathname === "/payment") {
              if (Object.keys(order).length === 0) return <Redirect to="/" />;
              else return children;
            }
            if (pathname === "/finish-confirm") {
              if (
                prev !== null &&
                prev.pathname !== "/confirm-payment" &&
                prev.pathname !== "/file-auto-debet"
              )
                return <Redirect to={prev.pathname} />;
              else return children;
            }
            if (pathname === "/finish-redeem") {
              if (prev !== null && prev.pathname !== "/redeem")
                return <Redirect to={prev.pathname} />;
              else return children;
            }
            if (pathname === "/finish-switch") {
              if (prev !== null && prev.pathname !== "/switch")
                return <Redirect to={prev.pathname} />;
              else return children;
            }
            //THERE
            if (pathname === "/confirm-payment") {
              if (Object.keys(confirm_payment).length !== 0 || location.state)
                return children;
              else return <Redirect to="/" />;
            }
            if (history.location.state === undefined)
              return <Redirect to="/" />;
            else return children;
          } else {
            return children;
          }
        } else {
          // if (onboarding) {
          //   return <Redirect to="/masuk" />;
          // } else {
          //   return <Redirect to="/onboarding" />;
          // }
          return <Redirect to="/onboarding" />;
        }
      }}
    />
  );
};

export default connect(mapState)(PrivateRoute);
