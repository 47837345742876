import React, { Component } from "react";
import { connect } from "react-redux";
import color from "../../assets/theme/color";
import BasicText from "../atom/BasicText/BasicText";
import ButtonContained from "../atom/Button/ButtonContained";
import {
  risk_survey_props,
  risk_result_props,
} from "../../config/redux/actions/profile";
import SurveyQuestion from "../organism/RiskSurveyPage/SurveyQuestion";
import processHelper from "../../helper/processHelper";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import {
  remove_result_l,
  set_result_props,
} from "../../config/redux/actions/state";
import TempAuth from "../template/TempAuth";
import { Skeleton } from "@material-ui/lab";
// import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
// import { Card } from "@material-ui/core";
// import RiskSurveyStepper from "../molecule/Profile/RiskSurveyStepper";
// import DialogAlert from "../molecule/Alert/DialogAlert";

const Load = () => {
  return (
    <div style={{ marginBottom: 54 }}>
      <Skeleton width="70%" className="mb-3" />
      <Skeleton width="38%" />
      <Skeleton width="45%" />
      <Skeleton width="30%" />
      <Skeleton width="44%" />
    </div>
  );
};

class RiskSurveyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      value: {},
    };
  }
  componentDidMount() {
    this.props.risk_survey_props();
  }
  componentDidUpdate(prevProps, prevState) {
    const { result_stack, history, location } = this.props;
    if (result_stack !== prevProps.result_stack) {
      let data = processHelper.findResult("risk_result", result_stack);
      if (Object.keys(data).length > 0) {
        if (data.type === "success") {
          this.props.remove_result_l("risk_result");
          const fromRegister = location.state?.fromRegister ?? false;
          history.replace("/hasil-profil-risiko", {
            change: true,
            fromRegister,
          });
          // if (location.state?.from === "register") {
          //   const { dataRisk } = data;
          //   set_result_props({ name: "risk_register", dataRisk });
          //   history.goBack();
          // } else history.replace("/hasil-profil-risiko", { change: true });
        }
      }
    }
  }

  handleNext = () => {
    const { value } = this.state;
    // if (step < 5) {
    //   this.setState({ step: step + 1 });
    // } else {
    let result = {};
    Object.keys(value).forEach((e1, id) => {
      let key = "answer[" + (id + 1) + "]";
      Object.keys(value[e1]).forEach((e2) => {
        result = { ...result, [key]: value[e1][e2] };
      });
    });
    this.props.risk_result_props({ point: result, mode: "risk_result" });
    // }
  };

  handleChangeQuestion = (e) => {
    this.setState({ value: { ...this.state.value, ...e } });
  };
  render() {
    const { value } = this.state;
    const { risk_survey, loading_stack } = this.props;
    const loadSurvey = processHelper.findProcess("risk_survey", loading_stack);
    return (
      <TempAuth page="regisForm" data={{ title: "PROFIL RISIKO" }}>
        <BasicText
          color="primary"
          className="text-center font-weight-medium"
          style={{ marginTop: 60 }}
        >
          Kuesioner ini merupakan bagian yang penting dari proses pembukaan
          rekening investasi
        </BasicText>
        <BasicText
          color="common"
          colorLevel="white"
          className="py-3 text-center mt-3 font-weight-bold"
          style={{
            backgroundColor: color.gray_6,
          }}
        >
          Kenali profil risikomu dengan menjawab pertanyaan di bawah ini.
        </BasicText>
        <div style={{ marginTop: 70 }}>
          {loadSurvey
            ? Array(4)
                .fill("")
                .map((_, index) => <Load key={index} />)
            : risk_survey.map((item, index) => (
                <div key={index} style={{ marginBottom: 54 }}>
                  <SurveyQuestion
                    risk_survey={risk_survey}
                    step={index + 1}
                    value={value}
                    onChange={this.handleChangeQuestion}
                  />
                </div>
              ))}
        </div>
        <div className="w-100 text-center">
          <ButtonContained
            id="submit-survey-risk"
            text="LIHAT HASIL"
            radius="5px"
            className="px-5 py-3 mb-5"
            onClick={this.handleNext}
            disabled={Object.keys(value).length !== risk_survey.length}
            loading={processHelper.findProcess("risk_result", loading_stack)}
          />
        </div>
      </TempAuth>
    );

    // return (
    //   <div style={{ backgroundColor: color.gray_8, minHeight: "100vh" }}>
    //     <div className="container-body margin-main">
    //       <div className="w-100 py-2 px-2">
    //         <ChevronLeftIcon className="fs-32 color-primary" />
    //       </div>
    //       <div className="px-3">
    //         <BasicText className="text-center fs-12 font-weight-medium color-primary">
    //           Kuesioner ini merupakan bagian yang penting
    //         </BasicText>
    //         <BasicText className="text-center fs-12 font-weight-medium color-primary mb-3">
    //           dari proses pembukaan rekening investasi.
    //         </BasicText>
    //         <div
    //           className="px-3 py-2 mb-4"
    //           style={{ backgroundColor: color.blue_4, borderRadius: 5 }}
    //         >
    //           <BasicText className="fs-12 font-weight-bold text-white">
    //             Kenali profil risikomu dengan menjawab pertanyaan di bawah ini.
    //           </BasicText>
    //         </div>
    //         <Card className="px-3 py-4 mb-4" style={{ minHeight: 365 }}>
    //           <RiskSurveyStepper step={step} />
    //           {risk_survey.length > 0 && (
    //             <SurveyQuestion
    //               risk_survey={risk_survey}
    //               step={step}
    //               value={value}
    //               onChange={this.handleChangeQuestion}
    //             />
    //           )}
    //         </Card>
    //         <div className="w-100 text-center">
    //           <ButtonContained
    //             text={step < 5 ? "LANJUT" : "SELESAI"}
    //             radius="5px"
    //             className="px-5 py-3"
    //             onClick={this.handleNext}
    //             disabled={value.hasOwnProperty(step - 1 + "") ? false : true}
    //             loading={processHelper.findProcess(
    //               "risk_result",
    //               loading_stack
    //             )}
    //           />
    //         </div>
    //         <DialogAlert resultName="risk_result" buttonText="OK" />
    //       </div>
    //     </div>
    //   </div>
    // );
  }
}

const mapStateToProps = (state) => ({
  risk_survey: state.profileReducer.risk_survey,
  result_stack: state.stateReducer.result_stack,
  loading_stack: state.stateReducer.loading_stack,
});

const mapDispatchToProps = {
  risk_survey_props,
  risk_result_props,
  remove_result_l,
  set_result_props,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(RiskSurveyPage);
