import React from "react";
import ButtonContained from "../../atom/Button/ButtonContained";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up(768)]: {
      backgroundColor: "#fff",
      paddingBottom: "20px",
    },
  },
  buttonContainer: {
    display: "flex",
    [theme.breakpoints.up(768)]: {
      borderBottom: `1px solid ${theme.palette.background.dark}`,
    },
  },
  buttonTab: {
    backgroundColor: "#fff",
    color: theme.palette.secondary.main,
    margin: "0 0 0 80px",
    position: "relative",
    [theme.breakpoints.down(769)]: {
      color: theme.palette.common.white,
      flex: 1,
      backgroundColor: theme.palette.secondary.light,
      fontWeight: 700,
      margin: 0,
    },
    "&.activeTab": {
      backgroundColor: theme.palette.background.default,
      borderBottom: "none",
      color: theme.palette.secondary.main,
      [theme.breakpoints.up(768)]: {
        backgroundColor: "#fff",
        "&::before": {
          content: `''`,
          width: "100%",
          height: "2px",
          backgroundColor: theme.palette.primary.main,
          position: "absolute",
          bottom: 0,
        },
      },
    },
    "&:hover": {
      backgroundColor: theme.palette.common.white,
    },
    "&::before": {
      [theme.breakpoints.up(768)]: {
        content: `''`,
        width: "100%",
        height: "2px",
        backgroundColor: theme.palette.common.white,
        position: "absolute",
        bottom: 0,
        transition: "background-color 0.3s",
      },
    },
    "&:hover::before": {
      [theme.breakpoints.up(768)]: {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}));

const TxnTabs = (props) => {
  const classes = useStyles();
  const onClick = (e) => {
    props.onChange(e);
  };
  return (
    <div
      className={classNames(classes.container, " w-100 shadow-card rounded-1")}
    >
      <div className={classes.buttonContainer}>
        <ButtonContained
          id="tab-order"
          text="BOOKING"
          className={classNames(
            classes.buttonTab,
            "fs-12 py-3 px-md-0 font-weight-semibold",
            props.tab === 0 ? "activeTab" : ""
          )}
          onClick={() => onClick(0)}
          disableRipple={true}
        />
        <ButtonContained
          id="tab-order"
          text="ORDER"
          className={classNames(
            classes.buttonTab,
            "fs-12 py-3 px-md-0 font-weight-semibold",
            props.tab === 1 ? "activeTab" : ""
          )}
          onClick={() => onClick(1)}
          disableRipple={true}
        />
        <ButtonContained
          id="tab-pending"
          text="PENDING"
          className={classNames(
            classes.buttonTab,
            "fs-12 py-3 px-md-0 font-weight-semibold",
            props.tab === 2 ? "activeTab" : ""
          )}
          onClick={() => onClick(2)}
          disableRipple={true}
        />
        <ButtonContained
          id="tab-history"
          text="HISTORY"
          className={classNames(
            classes.buttonTab,
            "fs-12 py-3 px-md-0 font-weight-semibold",
            props.tab === 3 ? "activeTab" : ""
          )}
          onClick={() => onClick(3)}
          disableRipple={true}
        />
      </div>
    </div>
  );
};

export default TxnTabs;
