import * as types from "./types";

export const set_voucher = payload => {
  return {
    type: types.SET_VOUCHER,
    payload
  };
};
export const set_myvoucher = payload => {
  return {
    type: types.SET_MYVOUCHER,
    payload
  };
};

export const get_vouchers = payload => ({
  type: types.VOUCHER_LIST,
  payload
})

export const get_voucher_detail = payload => ({
  type: types.VOUCHER_DETAIL,
  payload
})

export const check_voucher = payload => ({
  type: types.VOUCHER_CHECK,
  payload
})
