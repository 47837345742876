import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { chart_color_props } from "../../config/redux/actions/option";
import {
  profile_risk_props,
  risk_result_props,
} from "../../config/redux/actions/profile";
import SimulasiGoalKamu from "../organism/GoalKamu/SimulasiGoalKamu";
import TempMain from "../template/TempMain";
import { gbl_act_lc } from "../../config/redux/actions/state";
import stateCallApi from "../../config/API/stateCallApi";
import GraphAlocationProduct from "../molecule/SmartPlan/GraphAlocationProduct";

export class GoalKamuPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      profile_risk_point: 3,
    };
  }

  componentDidMount = () => {
    const {
      gbl_act_lc,
      profile_risk,
      profile_risk_props,
      chart_color,
      chart_color_props,
    } = this.props;
    window.scrollTo(0, 0);
    gbl_act_lc(stateCallApi.planner_list);
    gbl_act_lc(stateCallApi.profile_risk_base_point);
    if (Object.keys(profile_risk).length === 0) profile_risk_props();
    if (!chart_color.length) chart_color_props();
  };

  handleSubmit = (params, goalId, investAwal) => {
    const { history, gbl_act_lc } = this.props || {};
    gbl_act_lc({
      ...stateCallApi.planner_update,
      params: { ...params, profile_risk_point: this.state.profile_risk_point },
      callback: (data) => {
        let tmp = {
          user_planner_id: goalId,
          products: {},
          total_amount: investAwal,
        };
        data.allocations.forEach((el) => {
          tmp.products[el.product_code] = {
            amount: el.allocation,
          };
        });
        history.push("/subscript", tmp);
      },
    });
  };

  handleChangeRisk = (data) => {
    this.props.risk_result_props({
      point: data.profile_risk_point,
      mode: "save_risk_result",
    });
  };

  render() {
    const { goals, profile_risk, location } = this.props;
    const dataOption = { id: "graph-goals" };
    return (
      <TempMain
        title="GOALS PLANNER"
        data={dataOption}
        page="goalsKamu"
        showBottom={false}
        bottomNavValue={1}
        childrenRight={
          <GraphAlocationProduct
            {...dataOption}
            force
            riskPoint={this.state.profile_risk_point}
            getData={(e) =>
              this.setState({ profile_risk_point: e.profile_risk_point })
            }
          />
        }
      >
        <SimulasiGoalKamu
          goals={goals}
          data={dataOption}
          getRiskGoals={(e) => {
            this.setState({ profile_risk_point: Number(e) });
          }}
          locationState={location.state}
          handleSubmit={this.handleSubmit}
          ekspekReturn={profile_risk?.expektasi_return ?? undefined}
        />
      </TempMain>
    );
  }
}

const mapStateToProps = ({ optionReducer, profileReducer, goalReducer }) => ({
  goals: goalReducer.planner.data,
  profile_risk: profileReducer.profile_risk,
  chart_color: optionReducer.chart_color,
});

export default compose(
  connect(mapStateToProps, {
    gbl_act_lc,
    profile_risk_props,
    chart_color_props,
    risk_result_props,
  }),
  withRouter
)(GoalKamuPage);
