import React from "react";
import BasicText from "../../atom/BasicText/BasicText";
import CardProductRecom from "./CardProductRecom";
import CardSimulation from "./CardSimulation";
import colorAssets from "../../../assets/theme/color";
const ContentSimulation = props => {
  const { profileRiskData, color, onSubscript } = props;
  return (
    <div
      className="px-3 py-4"
      style={{ backgroundColor: colorAssets.secondaryLight }}
    >
      <BasicText className="fs-14 font-weight-bold color-secondary mb-3 px-2">
        Simulasi Investasi
      </BasicText>
      <CardProductRecom
        profileRiskProduct={profileRiskData.profile_risk_product}
        color={color}
      />
      <CardSimulation
        profileRiskProduct={profileRiskData.profile_risk_product}
        onSubscript={onSubscript}
      />
    </div>
  );
};

export default ContentSimulation;
