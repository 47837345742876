import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React from "react";
import LabelInput from "../../atom/BasicText/LabelInput";

const useStyles = makeStyles(theme => ({
  input: {
    "& input": {
      padding: "15px 0 15px 15px",
      fontFamily: "Montserrat",
      fontSize: 14,
    },
    "& .Mui-disabled.MuiOutlinedInput-root fieldset": {
      backgroundColor: theme.palette.background.disabledInput
    }
  },
}));

const InputTimePicker = ({
  containClass,
  className,
  onChange,
  inputVariant,
  label,
  labelClass,
  isFull,
  value,
  ...other
}) => {
  const classes = useStyles();
  function handleChange(date) {
    onChange(date.toString());
  }
  return (
    <div className={containClass}>
      <LabelInput text={label} className={labelClass} />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          id="date-input"
          value={value}
          onChange={handleChange}
          className={classes.input}
          inputProps={{ className }}
          inputVariant={inputVariant}
          fullWidth={isFull}
          {...other}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

InputTimePicker.defaultProps = {
  onChange: () => {},
  inputVariant: "outlined",
  isFull: true,
  value: undefined,
};

export default InputTimePicker;
