import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import stateCallApi from "../../../config/API/stateCallApi";
import { gbl_act_lc, gbl_list_lc } from "../../../config/redux/actions/state";
import processHelper from "../../../helper/processHelper";
import DialogAlert from "../../molecule/Alert/DialogAlert";
import DialogCustom from "../../organism/Container/DialogCustom";
import ChangeIconPage from "../ChangeIconPage";
import CreateModifyPlannerPage from "../CreateModifyPlannerPage";
import ReferanceInvestPage from "../ReferanceInvestPage";
const { useState, useEffect, useCallback } = React;

const ModifyPlannerPage = (props) => {
  const { path } = useRouteMatch();
  const {
    push,
    replace,
    location: { search, state },
  } = useHistory();
  const [delPlanner, setdelPlanner] = useState(false);
  const [modalDel, setmodalDel] = useState(false);
  const isRef = path === "/reference-invest";
  const isAddPlanner = path === "/create-planner";
  const isModPlanner = path === "/modify-planner";
  const isGoal = path === "/modify-goal-icon";
  const { load_key, paramsApi, max } = useMemo(() => {
    const paramsApi =
      stateCallApi[
        `planner_${
          isRef || isAddPlanner ? "add_bulk" : delPlanner ? "delete" : "replace"
        }`
      ];
    return {
      paramsApi,
      load_key: paramsApi.key,
      max: isRef ? 3 : 1,
    };
  }, [delPlanner, isAddPlanner, isRef]);

  const dispatch = useDispatch();
  const [checked, setChecked] = useState([]);
  const [icon, seticon] = useState(null);
  const goal = useSelector((state) => state.goalReducer.list.data);
  const loading = useSelector(({ stateReducer }) =>
    processHelper.findProcess(load_key, stateReducer.loading_stack)
  );

  const isOtherGoal = useCallback(
    (_id) => {
      return goal.find(({ id }) => id === _id)?.goal_code === "OT";
    },
    [goal]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      gbl_list_lc({
        ...stateCallApi.goals_list,
        params: { "sort[field]": "ordered", "sort[sort]": "asc" },
        option: { per_page: 6 * (isRef ? 1 : 2) },
      })
    );
  }, [dispatch, isRef]);

  const fetching = useCallback(
    (other) => {
      dispatch(gbl_act_lc({ ...paramsApi, ...other }));
    },
    [dispatch, paramsApi]
  );

  const handleSubmitRef = useCallback(
    (isSkip) => {
      const params = {};
      if (isSkip) params["is_skip"] = 1;
      else params["goal_id[]"] = checked;
      fetching({ params, callback: () => push("/") });
    },
    [checked, fetching, push]
  );

  useEffect(() => {
    if (isRef) return () => fetching({ params: { is_skip: 1 } });
  }, [fetching, isRef]);

  const handleChecked = useCallback(
    (id) => {
      setChecked((prev) => {
        if (prev.includes(id)) return prev.filter((prev_id) => prev_id !== id);
        else if (prev.length < max) return [...prev, id];
        return prev;
      });
    },
    [max]
  );

  const handleClick = useCallback(
    (id, ref_goals_icon) => {
      if (isRef) handleChecked(id);
      else {
        setChecked([id]);
        seticon(ref_goals_icon);
      }
    },
    [handleChecked, isRef]
  );

  const handleDelete = useCallback(() => {
    setdelPlanner(true);
    setmodalDel(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitMod = useCallback(() => {
    if (isAddPlanner) {
      if (isOtherGoal(checked[0]))
        push("/create-planner/plan", { id: checked[0], ref_goals_icon: icon });
      else {
        fetching({
          params: { "goal_id[]": [checked[0]] },
          callback: () => push("/"),
        });
      }
    } else if (isGoal) {
      const { ref_goal_icon, ...otherState } = state;
      replace("/change-goal", { ...otherState, ref_goals_icon: icon });
    } else if (isModPlanner) {
      if (delPlanner) {
        const id = new URLSearchParams(search).get("d");
        fetching({
          params: { id },
          callback: () => {
            setdelPlanner(false);
            push("/");
          },
        });
      } else if (isOtherGoal(checked[0]))
        push("/create-planner/plan", { id: checked[0], ref_goals_icon: icon });
      else {
        const id = new URLSearchParams(search).get("d");
        const data =
          goal[goal.findIndex(({ id }) => Number(checked[0]) === Number(id))];
        fetching({
          params: { user_planner_id: id, goal_id: data.id },
          callback: () => push("/"),
        });
      }
    } else {
      console.log("Submit Planner");
    }
  }, [
    checked,
    delPlanner,
    fetching,
    goal,
    icon,
    isAddPlanner,
    isGoal,
    isModPlanner,
    isOtherGoal,
    push,
    replace,
    search,
    state,
  ]);

  return (
    <div>
      {isGoal ? (
        <ChangeIconPage
          item={goal}
          onChecked={handleClick}
          onSubmit={handleSubmitMod}
          checked={checked}
          loading={loading}
        />
      ) : isAddPlanner || isModPlanner ? (
        <CreateModifyPlannerPage
          item={goal}
          onChecked={handleClick}
          onSubmit={handleSubmitMod}
          onDelete={handleDelete}
          checked={checked}
          type={isAddPlanner ? "create" : "modify"}
          loading={loading}
        />
      ) : isRef ? (
        <ReferanceInvestPage
          item={goal}
          onChecked={handleClick}
          onSubmit={(e) => handleSubmitRef(e)}
          checked={checked}
          loading={loading}
        />
      ) : null}
      <DialogAlert alertAppear="error" resultName={load_key} />
      <DialogCustom
        open={modalDel}
        handleClose={() => setmodalDel(false)}
        text="HAPUS GOALS PLANNER INI?"
        buttonText="YA"
        buttonTextCancel="BATAL"
        onCancel={() => {
          setmodalDel(false);
          setdelPlanner(false);
        }}
        onClick={handleSubmitMod}
      />
    </div>
  );
};

ModifyPlannerPage.defaultProps = {};

export default ModifyPlannerPage;
