import ChangeEmail from "./ChangeEmail";
import ChangePhone from "./ChangePhone";
import ChangePassword from "./ChangePassword";
import ChangePin from "./ChangePin";
import ChangeBank from "./ChangeBank";
import AddBankAcc from "./AddBankAcc";
import PeriodicInvest from "./PeriodicInvest";
export default {
  email: ChangeEmail,
  phone: ChangePhone,
  password: ChangePassword,
  pin: ChangePin,
  bank: ChangeBank,
  'add-bank': AddBankAcc,
  'update-bank': AddBankAcc,
  "periodic-invest": PeriodicInvest,
};
