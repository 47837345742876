import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import BasicText from "../../atom/BasicText/BasicText";
import PortoChart from "../../molecule/Porto/PortoChart";
import PortoColorDesc from "../../molecule/Porto/PortoColorDesc";
import CardProgressGoals from "./CardProgressGoals";
import CardProgressGoalsNR from "./CardProgressGoalsNR";
const useStyles = makeStyles((theme) => ({
  chartSize: {
    width: 120,
    height: 120,
    [theme.breakpoints.up(768)]: {
      width: 140,
      height: 140,
    },
  },
  chartContainer: {
    width: 120,
    [theme.breakpoints.up(768)]: {
      width: 140,
    },
  },
  colorLegend: {
    width: 208,
    marginTop: "auto",
    marginBottom: "auto",
    marginLeft: 20,
  },
}));

const PortoAssetChart = ({ onClick, summary, track }) => {
  const classes = useStyles();
  const {
    location: { pathname },
  } = useHistory();

  const isPortoPage = pathname === "/portofolio";
  return (
    Object.keys(summary).length > 0 && (
      <div className="mb-4 border-bottom-custom pb-2">
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <BasicText className="fs-12 font-weight-bold color-secondary mb-2">
              Alokasi Produk
            </BasicText>
            <div className="d-flex">
              <div className={classes.chartContainer}>
                <PortoChart
                  category="product"
                  containerClass={classes.chartSize}
                  id="product"
                  summary={summary}
                />
              </div>
              <div className={classes.colorLegend}>
                <PortoColorDesc summary={summary} category="product" />
              </div>
            </div>
          </Grid>
          {isPortoPage ? null : (
            <Grid item xs={12} md={8}>
              {summary.is_follow_robo ? (
                <CardProgressGoals
                  onClick={onClick}
                  summary={summary}
                  track={track}
                />
              ) : (
                  summary.goal?.goal_code === "DT" ||
                  summary.goal?.product_variety_code === "CG"
                    ? false
                    : true
                ) ? (
                <CardProgressGoalsNR track={track} />
              ) : null}
            </Grid>
          )}
        </Grid>
      </div>
    )
  );
};

export default PortoAssetChart;
