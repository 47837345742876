import React from "react";
import { Button, makeStyles, CircularProgress } from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles({
  contain: {
    padding: 0,
    textTransform: "none",
    fontWeight: "400",
    textDecoration: "underline !important",
  },
});

const ButtonLink2 = ({
  text,
  loading,
  disabled,
  onClick,
  className,
  ...other
}) => {
  const S = useStyles();
  const isDisabled = loading || disabled;
  return (
    <Button
      onClick={onClick}
      className={classNames(S.contain, className)}
      disabled={isDisabled}
      {...other}
    >
      {text}
      {loading && (
        <CircularProgress size={14} color="secondary" className="ml-2" />
      )}
    </Button>
  );
};

ButtonLink2.defaultProps = {
  onClick: () => {},
  loading: false,
  disabled: false,
  text: "text",
};

export default ButtonLink2;
