import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { fetch_forgot_pass } from "../../../config/redux/actions/auth";
import processHelper from "../../../helper/processHelper";
import BasicText from "../../atom/BasicText/BasicText";
import ButtonContained from "../../atom/Button/ButtonContained";
import ButtonLink2 from "../../atom/Button/ButtonLink2";
import DialogAlert from "../../molecule/Alert/DialogAlert";
import InputOtp from "../../molecule/MaterialInput/InputOtp";

const dialogs = [
  { resultName: "change_pass_request", alertAppear: "both" },
  { resultName: "change_pass_validate", alertAppear: "error" },
];

class ValidatePage extends PureComponent {
  state = {
    otp_code: "",
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { otp_token, otp, history } = this.props;
    if (otp_token && otp_token !== prevProps.otp_token) this._resendSuccess();
    else if (otp.otp_token && otp !== prevProps.otp)
      history.replace("/forgot-password/change");
  };

  _resendSuccess = () => {
    const { clearCount, setUlang, resetOTP } = this.refs.otp;
    resetOTP();
    clearCount();
    setUlang();
  };

  _handleChangeOTP = (otp_code) => {
    this.setState({ otp_code });
  };

  _resend = () => {
    const { otp, fetch_forgot_pass } = this.props;
    if (otp.identity) fetch_forgot_pass({ type: "request", params: otp });
  };

  _handleSubmit = () => {
    this.props.fetch_forgot_pass({ type: "validate", params: this.state });
  };

  render() {
    const { loading_stack } = this.props;
    const { findProcess } = processHelper;
    const l_resend = findProcess("change_pass_request", loading_stack);
    const l_submit = findProcess("change_pass_validate", loading_stack);
    const d_submit = this.refs.otp?.error === "" ? false : true;
    console.log(this.refs.otp);
    return (
      <div className="d-flex flex-column flex-grow-1 justify-content-between">
        <div>
          <BasicText className="fs-12" style={{ marginTop: 46 }}>
            Kode Verifikasi
          </BasicText>
          <InputOtp
            id="otp-input"
            ref="otp"
            underline
            validation
            value={this.state.otp_code}
            onChange={this._handleChangeOTP}
            withCountdown
          />
          <ButtonLink2
            text="KIRIM ULANG KODE VERIFIKASI"
            className="fs-12 gray-5 mt-5 mb-3"
            onClick={this._resend}
            loading={l_resend}
          />
        </div>
        <ButtonContained
          text="VERIFIKASI"
          className="align-self-center px-5 py-2"
          radius="4px"
          loading={l_submit}
          disabled={d_submit}
          onClick={this._handleSubmit}
        />
        {dialogs.map((item, index) => (
          <DialogAlert key={index} {...item} />
        ))}
      </div>
    );
  }
}

const mapStateToProps = ({ stateReducer, authReducer }) => ({
  loading_stack: stateReducer.loading_stack,
  otp_token: authReducer.otp_token,
  otp: authReducer.otp,
});
const mapDispatchToProps = {
  fetch_forgot_pass,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ValidatePage)
);
