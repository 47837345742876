import React, { Component } from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  withStyles,
} from "@material-ui/core";
import PropTypes from "prop-types";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

const useStyles = {
  inputDesk: {
    "& input": {
      paddingTop: "15px",
      paddingBottom: "15px",
    },
  },
};

class TextInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pass: false,
    };
    this.nameRef = React.createRef();
    this.cursor = 0;
  }
  _inputHandler = (e) => {
    this.cursor = e.target.selectionStart;
    this.props.onChange(e.target.value);
  };
  componentDidUpdate() {
    this._setCursorPositions();
  }
  _setCursorPositions = () => {
    this.nameRef.current.selectionStart = this.cursor;
    this.nameRef.current.selectionEnd = this.cursor;
  };
  _handleVisible = () => {
    this.setState({ pass: !this.state.pass });
  };
  render() {
    const {
      id,
      marginClass,
      placeholder,
      label,
      type,
      startIcon: StartIcon,
      endIcon: EndIcon,
      onFocus,
      error,
      errText,
      inputClass,
      passVisible,
      variant,
      value,
      disabled,
      classes,
      onKeyDown,
      ...otherProps
    } = this.props;
    const { pass } = this.state;
    const { inputDesk, otherClass } = classes || {};
    return (
      <div className={marginClass}>
        <TextField
          {...otherProps}
          id={id}
          error={error}
          helperText={errText}
          placeholder={placeholder}
          variant={variant}
          label={label}
          ref={this.nameRef}
          onChange={this._inputHandler}
          value={value}
          type={passVisible ? (pass ? "text" : type) : type}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          fullWidth
          disabled={disabled}
          className={inputDesk}
          classes={otherClass}
          InputProps={{
            className: inputClass,
            startAdornment: StartIcon && (
              <InputAdornment>
                <StartIcon className="fs-18 mr-2 gray-5" />
              </InputAdornment>
            ),
            endAdornment: (passVisible || EndIcon) && (
              <InputAdornment>
                {passVisible ? (
                  <IconButton id={`visible-${pass ? "on" : "off"}`} onClick={this._handleVisible}>
                    {pass ? (
                      <VisibilityOffIcon className="gray-5" />
                    ) : (
                      <VisibilityIcon className="gray-5" />
                    )}
                  </IconButton>
                ) : (
                  EndIcon && <EndIcon />
                )}
              </InputAdornment>
            ),
          }}
        />
      </div>
    );
  }
}

TextInput.propTypes = {
  marginClass: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  errText: PropTypes.string,
  variant: PropTypes.string,
  passVisible: PropTypes.bool,
  disabled: PropTypes.bool,
  onKeyDown: PropTypes.func,
};

TextInput.defaultProps = {
  marginClass: "",
  placeholder: "",
  disabled: false,
  type: "text",
  onChange: () => null,
  error: false,
  errText: "",
  passVisible: false,
  variant: "standard",
};

export default withStyles(useStyles)(TextInput);
