import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import  KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router';
import profile_risk from '../../../assets/img/icon/profile_risk';
import stateCallApi from '../../../config/API/stateCallApi';
import { risk_result_props } from '../../../config/redux/actions/profile';
import { gbl_act_lc } from '../../../config/redux/actions/state';
import processHelper from '../../../helper/processHelper';
import useChartColors from '../../atom/hooks/useChartColors';
import usePointProfileRisk from '../../atom/hooks/usePointProfileRisk';
import DialogAlert from '../../molecule/Alert/DialogAlert';
import TempMain from '../../template/TempMain';
import useStyles from './useStyles';

const Arrow = ({icon : Icon, onClick,disabled}) => {
    return (
        <IconButton className="p-0 d-block" onClick={onClick} disabled={disabled}>
            <Icon color="primary" />
        </IconButton>
    )
}

const PointComponent = ({point,handleAdd, handleSub}) => {
    const classes = useStyles()
    return (
        <div className={classes.contentPoint}>
            <div className="point cont-center">
                <span className="fs-28 font-weight-medium">{point}</span>
            </div>
            <div>
                <Arrow icon={KeyboardArrowUp} onClick={handleAdd} disabled={point >= 10} />
                <Arrow icon={KeyboardArrowDown} onClick={handleSub} disabled={point <= 1} />
            </div>
        </div>
    )
}

const RiskChangePage = () => {
    const keySave = "save_risk_result"
    const classes = useStyles()
    const dispatch = useDispatch()
    const { location : {state}, goBack } = useHistory()
    const chartColor = useChartColors()
    const base_robo_alc = useSelector(state => state.goalReducer.base_robo_alc)
    const [point, handleAdd, handleSub] = usePointProfileRisk(state?.point)
    const loading = useSelector(state => processHelper.findProcess(keySave, state.stateReducer.loading_stack))
    React.useEffect(() => {
        dispatch(gbl_act_lc(stateCallApi.profile_risk_base_point))
    }, [dispatch])

    const profileRisk = base_robo_alc.find(({profile_risk_point}) => Number(profile_risk_point) === Number(point))

    const handleSubmit = () => {
        if(point !== state?.point) dispatch(risk_result_props({point,mode : "save_risk_result"}))
        else goBack()
    }
    return (
        <TempMain
            bottomNavValue={4}
            title="PROFIL"
            page="risk"
            data={{
                chartColor,
                profileRisk,
                button: {
                    text: 'SIMPAN',
                    loading,
                    onClick: handleSubmit
                }
            }}
        >
            <Card className="shadow-card mb-3">
                <Grid container alignItems="center" className={classes.containPoint}>
                    <p className="basic fs-14 font-weight-bold">
                        Skor Kamu: 
                    </p>
                    <PointComponent 
                        point={point} 
                        handleAdd={handleAdd} 
                        handleSub={handleSub}
                    />
                </Grid>
            </Card>
            <Card className="shadow-card mb-3">
                <div className={classes.containDetail}>
                <p className="fs-14 basic mb-4 pb-2 font-weight-bold">
                    Indikasi Profil
                </p>
                <Grid container alignItems="center" >
                    <Grid item md={4} xs={12}>
                    <img src={profile_risk.riskIndicator2} alt="" className="w-100" />
                    </Grid>
                    <Grid md={1} className="spacing"/>
                    <Grid item md={3} xs={12} className="text-center detail-risk">
                        <p className="text-uppercase basic fs-26 font-weight-medium color-primary">
                            {profileRisk?.profile_risk_type.name}
                        </p>
                        <p className="basic">{profileRisk?.profile_risk_type.desc}</p>
                    </Grid>
                </Grid>
                </div>
                
            </Card>
            <DialogAlert resultName={keySave} alertAppear="both" submitIfSuccess={goBack}  />
        </TempMain>
    )
}

export default RiskChangePage
