import Card from "@material-ui/core/Card";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import textHelper from "../../../helper/textHelper";
import BasicText from "../../atom/BasicText/BasicText";
import product from "../../../assets/img/icon/product";
import { useMemo } from "react";
import { useCallback } from "react";

const CardCashBack = ({ data = [], cashback = {}, getData, totalAmount }) => {
  const [state, setstate] = useState(
    Object.keys(cashback).reduce((res, code) => ({ ...res, [code]: false }), {})
  );
  const [cb, setcb] = useState({});

  const cashbackAvailable = useMemo(
    () =>
      Object.keys(cashback)
        .filter((code) => cashback[code].length)
        .reduce((res, code) => ({ ...res, [code]: cashback[code] }), {}),
    [cashback]
  );

  const sendData = useCallback(
    (state) => {
      const data = Object.keys(state)
        .filter((key) => state[key])
        .reduce((res, code) => ({ ...res, [code]: cb[code] }), {});
      getData(data);
    },
    [cb, getData]
  );

  const handleChecked = useCallback(
    (event, code) => {
      if (cb[code]) {
        let state = {};
        setstate((prev) => {
          state = { ...prev, [code]: event.target.checked };
          return state;
        });
        sendData(state);
      }
    },
    [cb, sendData]
  );

  useEffect(() => {
    const tempData = {};
    Object.keys(cashbackAvailable).forEach((code) => {
      const dataSelect = data.find((item) => code === item.code);
      let sub = totalAmount * (dataSelect?.amount / 100);
      for (let itemCb of cashbackAvailable[code]) {
        if (itemCb.min_sub <= sub) {
          tempData[code] = {
            ...itemCb,
            fr_product_name: dataSelect.fr_product_name,
            bonus: Math.min(
              (itemCb.benefit_percentage / 100) * sub,
              itemCb.max_amount
            ),
          };
          break;
        }
      }
    });
    setcb(tempData);

    let sendData = {};
    Object.keys(state).forEach(
      (el) => state[el] && (sendData = { ...sendData, [el]: tempData[el] })
    );
    getData(sendData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalAmount]);
  return Object.keys(cashbackAvailable).map((el, id) => (
    <Grid key={id} item xs={12} lg={4} md={6}>
      <Card className="d-flex justify-content-between align-items-center p-3 shadow-card h-100">
        <div className="d-flex w-100">
          <Switch
            id={`${el}-cashback`}
            checked={state[el] || false}
            onChange={(event) => handleChecked(event, el)}
            color="primary"
            name="checkedB"
            inputProps={{ "aria-label": "primary checkbox" }}
            className="mt-n2"
          />
          <div className="flex">
            <div className="d-flex align-items-center">
              <p className="basic fs-12 font-weight-bold">Cash Back</p>
              <img src={product.cashback} alt="cashback" className="mx-2" />
              <BasicText className="ml-auto fs-14 font-weight-semibold color-primary">
                Rp.{" "}
                {state[el] ? textHelper.printMoneyWithoutRp(cb[el]?.bonus) : 0}
              </BasicText>
            </div>
            <p className="basic fs-10 gray-4 mt-2">{cb[el]?.fr_product_name}</p>
          </div>
        </div>
      </Card>
    </Grid>
  ));
};

export default CardCashBack;
