const ENV_NAME = "production";
const URL = "https://yoinves.id/";
const URL_CORP = "https://corporate.yoinves.id";
const FLAG_TEXT = null;

export default {
  ENV_NAME,
  URL,
  URL_CORP,
  FLAG_TEXT,
};
