import textHelper from "./textHelper";

export function isBookPeriodRDP(product_rdp = {}) {
  const { book_period_start, book_period_end } = product_rdp;
  return isOnPeriod(book_period_start, book_period_end);
}

export function isOfferPeriodRDP(rdp = {}) {
  const { offer_period_start, offer_period_end } = rdp;
  return isOnPeriod(offer_period_start, offer_period_end);
}

export function isCloseOfferPeriodRDP(rdp = {}) {
  const { offer_period_end } = rdp;
  return textHelper.isAfterDate(new Date(), offer_period_end);
}

export function isDueDateRDP(rdp = {}) {
  const { due_date } = rdp;
  return textHelper.isAfterDate(new Date(), due_date);
}

export function isOnPeriod(dateStart, dateEnd) {
  const current = new Date();
  return (
    textHelper.isAfterDate(current, dateStart) &&
    textHelper.isAfterDate(dateEnd, current)
  );
}
