import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import DesktopFooter from "../../molecule/Footer/DesktopFooter";
import BasicHeader from "../../molecule/Header/BasicHeader";
import IlustrasiRegis1 from "../../molecule/Register/IlustrasiRegis1";

const useStyles = makeStyles({
  container_center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    marginBottom: 98,

  },
  card: {
    width: 555,
    borderRadius: 4,
    padding: "28px 41px 47px",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: 520,
    maxHeight: 630,
    // height: "78vh",
    // marginBottom: 60,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    width: "70%",
    alignSelf: "center",
  },
  rowTitle: {
    paddingBottom: 13,
  },
  logo: {
    height: 28,
    cursor: "pointer",
  },
});

const TempRegis1Desktop = ({ children, data }) => {
  const { title, ilustration, contentStyle } = data;
  const S = useStyles();
  return (
    <div className="background-desktop">
      <div className={S.container_center}>
        <Grid className={S.card}>
          <Grid
            container
            alignItems="center"
            justify="space-between"
            className={S.rowTitle}
          >
            <BasicHeader title={title} />
          </Grid>
          <Grid className={S.content} style={contentStyle}>
            <IlustrasiRegis1 className="my-3 w-100" {...ilustration} />
            {children}
          </Grid>
        </Grid>
      </div>
      <DesktopFooter />
    </div>
  );
};

TempRegis1Desktop.defaultProps = {
  data: {},
};

export default TempRegis1Desktop;
