import React from "react";
import PropTypes from "prop-types";
import chartHelper from "../../../helper/chartHelper";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import BasicText from "../../atom/BasicText/BasicText";
import { PlayArrow } from "@material-ui/icons";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import processHelper from "../../../helper/processHelper";
const ProductGraph = (props) => {
  const { history, id, width, height, product } = props;
  const { nav_per_unit } = product;
  const loading = useSelector(state => processHelper.findProcess('product_history', state.stateReducer.loading_stack))

  const useStyles = makeStyles((theme) => ({
    graph: {
      width,
      height,
      [theme.breakpoints.up(768)]: {
        height: 400,
      },
    },
    nav: {
      [theme.breakpoints.up(768)]: {
        display: "none",
      },
    },
    arrowUp: {
      transform: "rotate(-90deg)",
    },
    arrowDown: {
      transform: "rotate(90deg)",
    },
  }));
  const classes = useStyles();

  React.useEffect(() => {
    if (history.length > 0) {
      chartHelper.createStockChart(history, id);
    }
  }, [history, id]);

  const dataNotReady = !loading && !history.length
  return (
    <div>
      <div className="mb-3">
        <BasicText color="primary" className="mb-2 fs-14 font-weight-bold">
          Performa Produk
        </BasicText>
        <div className={classes.nav}>
          <BasicText className="fs-10 gray-4 mb-1">Nav</BasicText>
          {/* <BasicText className="fs-22 gray-3 mb-1">{nav_per_unit}</BasicText> */}
          <Grid container alignItems="center" className="mb-2">
            <BasicText className="fs-22 gray-3">{nav_per_unit}</BasicText>
            <PlayArrow
              color="primary"
              className={classNames(
                "ml-1",
                nav_per_unit < 0 ? classes.arrowDown : classes.arrowUp
              )}
            />
          </Grid>
          <BasicText className="fs-10 gray-4">
            Angka NAV di atas adalah nilai bersih setiap unit reksa dana.
            Pantaulah secara berkala untuk mengetahui kinerja reksa danamu.
          </BasicText>
        </div>
      </div>
      {dataNotReady ?
        <p className="basic text-center font-weight-medium">
          Data tidak di temukan
        </p>
        : <div id={id} className={classNames(classes.graph, "mb-2")}></div>}

    </div>
  );
};

ProductGraph.propTypes = {
  id: PropTypes.string,
  history: PropTypes.array,
};

export default ProductGraph;
