import React from "react";
import BasicText from "./BasicText/BasicText";

const OJKText = () => {
  return (
    <BasicText className="fs-10 color-secondary text-ojk">
      PT. Syailendra Capital berizin dan diawasi oleh Otoritas Jasa Keuangan
    </BasicText>
  );
};

export default OJKText;
