import React from "react";
import PropTypes from "prop-types";
import BasicText from "../../atom/BasicText/BasicText";
import classNames from "classnames";
import { CopyToClipboard } from "react-copy-to-clipboard";
import makeStyles from "@material-ui/styles/makeStyles";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "1rem",
    [theme.breakpoints.up(768)]: {
      marginBottom: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      paddingBottom: 0,
    },
  },
}));

const CardTransfer = (props) => {
  const classes = useStyles();
  const { payment } = props;
  const [tool, setTool] = React.useState(false);
  const handleTool = () => {
    if (!tool) {
      setTool(true);
      setTimeout(() => setTool(false), 2000);
    }
  };
  const {
    payment_type: {
      bank_branch: {
        bank: { name, logo },
      },
    },
    account_name,
  } = payment;

  const account_number = React.useMemo(() => {
    let str = payment.account_number;
    let str2 = str.toString().split("-").join("");
    return str2;
  }, [payment.account_number]);
  return (
    <div className={classNames("card-custom", classes.root)}>
      <BasicText className="fs-14 mb-1">
        Silahkan melakukan pembayaran ke rekening tujuan a.n{" "}
        <span className="font-weight-bold">{account_name}</span>
      </BasicText>
      <div className="d-flex align-items-center" style={{ maxWidth: 360 }}>
        <img src={logo} alt={name} style={{ height: 24, marginRight: 10 }} />
        <BasicText className="fs-14 d-inline ml-auto mr-4">
          <span className="font-weight-bold">{account_number}</span>
        </BasicText>
        <CopyToClipboard text={account_number} onCopy={handleTool}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            open={tool}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="sudah disalin"
            arrow
          >
            <span className="color-primary text-underline fs-12 is-link">
              SALIN
            </span>
          </Tooltip>
        </CopyToClipboard>
      </div>
    </div>
  );
};

CardTransfer.propTypes = {
  payment: PropTypes.object,
};

export default CardTransfer;
