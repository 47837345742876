import { Checkbox, FormControlLabel } from "@material-ui/core";
import { isEqual } from "lodash";
import React, { createRef, PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { object } from "yup";
import {
  clear_register,
  fetch_data_ocr,
  fetch_data_sid,
  fetch_regis_1,
  register,
} from "../../../config/redux/actions/auth";
import {
  set_app_config,
  set_data_location,
} from "../../../config/redux/actions/option";
import processHelper from "../../../helper/processHelper";
import textHelper from "../../../helper/textHelper";
import validation from "../../../helper/validateHelper";
import BasicText from "../../atom/BasicText/BasicText";
import DialogAlert from "../../molecule/Alert/DialogAlert";
import SIDinput from "../../molecule/Register/SIDinput";
import BoxUpload from "../../organism/Payment/BoxUpload";
import FormRegister from "../../organism/Register/FormRegister";
import TempAuth from "../../template/TempAuth";
import RouteLeavingConfirm from "../../../config/Router/RouteLeavingConfirm";

const baseKTP = [
  "nik",
  "nama",
  "tempat_lahir",
  "tanggal_lahir",
  "alamat_ktp",
  "rt/rw",
  "desa_ktp",
  "kecamatan_ktp",
  "kota_ktp",
  "provinsi_ktp",
  "kodepos_ktp",
  "agama",
  "status_perkawinan",
  "kewarganegaraan",
  "gender",
];

const canChange = [
  "alamat_korespondensi",
  "provinsi_korespondensi",
  "kota_korespondensi",
  "kodepos_korespondensi",
  "status_perkawinan",
];

function _require(key) {
  return validation.require(null, key);
}

function nullInput(txt) {
  return txt || "";
}

const baseTrigger = {
  provinsi_ktp: {
    target: "kota_ktp",
    from: "city_list",
  },
  kota_ktp: {
    target: "kecamatan_ktp",
    from: "kecamatan_list",
  },
  kecamatan_ktp: {
    target: "desa_ktp",
    from: "kelurahan_list",
  },
  desa_ktp: {
    target: "kodepos_ktp",
    from: "kelurahan_list",
    keyValue: "postal_code",
  },
  provinsi_korespondensi: {
    target: "kota_korespondensi",
    from: "city_list",
  },
};

const dialogs = [
  { alertAppear: "both", resultName: "validateRegis" },
  { alertAppear: "both", resultName: "fetch_ocr" },
  { alertAppear: "error", resultName: "register1" },
];

const validationShema = object().shape({
  nik: validation.validateNIK(),
  nama: _require("Nama"),
  tempat_lahir: _require("Tempat lahir"),
  tanggal_lahir: _require("Tanggal lahir"),
  alamat_ktp: _require("Alamat"),
  provinsi_ktp: _require("Provinsi"),
  kota_ktp: _require("Kota"),
  kecamatan_ktp: _require("Kecamatan"),
  desa_ktp: _require("Desa/Kelurahan"),
  "rt/rw": validation.validateRTRW(),
  kodepos_ktp: validation.validatePos(),
  alamat_korespondensi: _require("Alamat"),
  provinsi_korespondensi: _require("Provinsi"),
  kota_korespondensi: _require("Kota"),
  kodepos_korespondensi: validation.validatePos(),
  agama: _require("Agama"),
  status_perkawinan: _require("Status"),
  kewarganegaraan: _require("Kewarganegaraan"),
  gender: _require("Jenis Kelamin"),
  mother_name: _require("Nama Ibu Kandung"),
});

// const fileToDataUri = (file) =>
//   new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.onload = (event) => {
//       resolve(event.target.result);
//     };
//     reader.readAsDataURL(file);
//   });

function TitleUploadKTP() {
  return (
    <div>
      <BasicText className="fs-14 font-weight-bolder color-primary mb-2">
        Upload Foto KTP Kamu
      </BasicText>
      <BasicText className="fs-14 font-weight-light gray-5">
        PASTIKAN FOTO BERADA DI DALAM BATAS AREA YANG DITENTUKAN
      </BasicText>
    </div>
  );
}

const CheckBoxKorespondensi = ({ onChange, check }) => {
  function _handleCheck({ target: { checked } }) {
    const value = checked || false;
    if (onChange) onChange(value);
  }
  return (
    <FormControlLabel
      control={
        <Checkbox
          id="checkbox-korespondensi"
          checked={check}
          onChange={_handleCheck}
          size="small"
          className="p-0 mx-2"
        />
      }
      label="Sama dengan KTP"
      className="mb-0"
      classes={{ label: "fs-12 gray-3 font-family" }}
      labelPlacement="start"
    />
  );
};

class RegisterIdentitas extends PureComponent {
  constructor(props) {
    super(props);
    this.box_ktp = createRef();
    this.inputSID = createRef();
    this.state = {
      kota_ktp: [],
      kecamatan_ktp: "",
      desa_ktp: "",
      // provinsi_korespondensi: [],
      kota_korespondensi: [],
      disabledKorenpondensi: false,
      disableds: [],
      reRegisSameKTP: true,
      loadKTPreRegis: false,
      isSID: false,
    };
  }

  componentDidMount = () => {
    const { optionReducer, set_app_config, setRegister, register } = this.props;
    const isHaveStatusRegister =
      register.registerStatus?.search("register") >= 0;
    if (isHaveStatusRegister) {
      this._setIfKTPReady();
    } else setRegister({ registerStatus: "", url_ktp: null });
    if (!optionReducer.province_list.length)
      set_app_config({ params: { with_option: 1 } });
  };

  _setIfKTPReady = async () => {
    const { set_data_location, register, token } = this.props;
    const {
      kota_ktp,
      kecamatan_ktp,
      alamat_korespondensi,
      provinsi_korespondensi,
      kota_korespondensi,
      kodepos_korespondensi,
      url_ktp,
      registration_token,
      sid,
      params,
    } = register;
    // get list kota
    set_data_location(kota_ktp);
    // get list kecamatan
    set_data_location(kecamatan_ktp);
    this._handleUpdateForm({
      alamat_korespondensi,
      provinsi_korespondensi,
      kota_korespondensi,
      kodepos_korespondensi,
      mother_name: params.mother_name,
    });
    let ktp = url_ktp;
    const isNeedHeader = ktp?.substring(ktp.length - 4, ktp.length) !== ".jpg";
    const isReregister = registration_token === "re-register";
    if (isReregister) {
      if (sid) this.inputSID.current._handleChange(sid);
      const disableds = baseKTP.filter((item) => item !== "status_perkawinan");
      await this.setState({
        loadKTPreRegis: isNeedHeader,
        isSID: true,
        disableds,
      });
      try {
        ktp = !isNeedHeader
          ? ktp
          : await fetch(`${url_ktp}/${textHelper.printRandomTime()}.jpg`, {
              headers: { Authorization: token },
            })
              .then((res) => res.blob())
              .then((e) => {
                this.setState({ loadKTPreRegis: false });
                return URL.createObjectURL(e);
              });
      } catch (e) {}
    } else if (sid) {
      this.setState({ isSID: true });
      this._SIDisExist();
      this.inputSID.current._handleChange(sid);
    }
    this.box_ktp.current?.setImage(ktp) ?? console.log();
    this._triggerInput(provinsi_korespondensi, "provinsi_korespondensi");
  };

  componentDidUpdate = (prevProps) => {
    const { optionReducer, register, history, result_stack } = this.props;
    const { city_list, province_list } = optionReducer;
    const isChangeCityList = city_list !== prevProps.optionReducer.city_list;
    if (city_list && isChangeCityList) {
      this.setState({
        kota_ktp: city_list,
        provinsi_korespondensi: province_list,
        kota_korespondensi: city_list,
      });
    } else if (!isEqual(register, prevProps.register)) {
      const { registerStatus } = register;
      if (registerStatus === "OCR" || registerStatus === "SID") {
        // if (registerStatus === "SID") this._SIDisExist();
        this._handleUpdateForm();
      }
    }
    if (result_stack !== prevProps.result_stack) {
      const data = processHelper.findResult("register1", result_stack);
      if (data.name && data.type === "success") {
        history.push("/register/profil");
      }
    }
  };

  isReregis = (status) => status === "re-register";

  componentWillUnmount = () => {
    const { register, clear_register } = this.props;
    if (this.isReregis(register.registerStatus)) clear_register();
  };

  _getKtp = async (e) => {
    const { fetch_data_ocr, register } = this.props;
    if (this.isReregis(register.registration_token)) {
      this.setState(({ reRegisSameKTP }) => ({
        reRegisSameKTP: !reRegisSameKTP,
      }));
    }
    fetch_data_ocr(e);
  };

  _handleSID = (sid) => {
    this.props.setRegister({ sid });
  };

  _handleUpdateForm = (otherData) => {
    const { setValues, setTouched } = this.refs.formik || {};
    const { register } = this.props;
    let disableds = [...this.state.disableds];
    const {
      provinsi_ktp,
      kota_ktp,
      kecamatan_ktp,
      rt_ktp,
      rw_ktp,
      registerStatus,
      // alamat_ktp,
      // kodepos_ktp,
    } = register;
    const isSID = registerStatus === "SID";
    let _res = {
      ...otherData,
    };
    const touch = {};
    baseKTP.forEach((item) => {
      if (item === "tanggal_lahir")
        _res[item] = register[item] || textHelper.printDateWithStrip();
      else if (item === "rt/rw") {
        if (nullInput(rt_ktp) && nullInput(rw_ktp))
          _res[item] = `${rt_ktp}/${rw_ktp}`;
        else _res[item] = "";
      } else _res[item] = nullInput(register[item]);
      if (_res[item]) {
        touch[item] = true;
        if (isSID && item !== "status_perkawinan") disableds.push(item);
      }
    });
    const _STATE = { kecamatan_ktp: kota_ktp, desa_ktp: kecamatan_ktp };
    if (this.state.disableds !== disableds)
      _STATE.disableds = isSID ? disableds : [];
    this.setState(_STATE);
    setValues(_res);
    this._triggerInput(provinsi_ktp, "provinsi_ktp");
    this._triggerInput(kota_ktp, "kota_ktp");
    if (this.state.disabledKorenpondensi) {
      this.setState({ disabledKorenpondensi: false });
    }
    setTimeout(() => setTouched(touch), 100);
  };

  _SIDisExist = () => {
    const { kodepos_ktp } = this.props.register;
    let disableds = [...this.state.disableds];
    if (kodepos_ktp) disableds.push("kodepos_ktp");
    Object.keys(validationShema.fields).forEach((key) => {
      if (canChange.indexOf(key) < 0) disableds.push(key);
    });
    this.setState({ disableds });
  };

  _triggerInput = (value, name) => {
    const { optionReducer, set_data_location } = this.props;
    const { setFieldValue } = this.refs.formik || { setFieldValue: () => {} };
    const { desa_ktp } = this.state;
    function _setFieldWithValueKey(target) {
      const _list = optionReducer[baseTrigger[name].from][desa_ktp];
      setFieldValue(
        target || baseTrigger[name].target,
        _list[_list.findIndex((i) => i.id === value)][
          baseTrigger[name].keyValue
        ]
      );
    }
    if (Object.keys(baseTrigger).indexOf(name) >= 0) {
      let list = optionReducer[baseTrigger[name].from];
      const fromRedux = name.charAt(0) === "k";
      if (Array.isArray(list)) {
        this._setData(list, name, value);
      } else if (fromRedux) {
        set_data_location(value);
        this.setState({ [baseTrigger[name].target]: value });
      } else if (name === "desa_ktp") _setFieldWithValueKey();
    }
    if (this.state.disabledKorenpondensi) {
      const canTriggerThis = ["alamat", "provinsi", "kota", "kodepos"];
      const _name = name.split("_")[0];
      if (canTriggerThis.indexOf(_name) >= 0) {
        const _target = `${_name}_korespondensi`;
        setFieldValue(_target, value);
      } else if (name === "desa_ktp")
        _setFieldWithValueKey("kodepos_korespondensi");
    }
  };

  _setData = (list, name, value) => {
    const { getValues, setFieldValue } = this.refs.formik || {};
    const values = getValues ? getValues() : {};
    const isCountry = name.charAt(0) === "n";
    let _list = list;
    _list = list.filter((item) => {
      const valueItem = isCountry ? "102" : item["zone_province_id"];
      return valueItem === value;
    });
    if (
      values[baseTrigger[name]] &&
      _list.findIndex((item) => item.id === values[baseTrigger]) < 0
    ) {
      setFieldValue && setFieldValue(baseTrigger[name], "");
    }
    this.setState({ [baseTrigger[name].target]: _list });
  };

  _handleSameKtp = (disabledKorenpondensi) => {
    const { setFieldValue, getValues, validateForm } = this.refs.formik || {};
    // const {alamat_ktp,provinsi_ktp,kota_ktp,kodepos_ktp, desa_ktp } = getValues()
    let { disableds } = this.state;
    const data = [
      "alamat_korespondensi",
      "provinsi_korespondensi",
      "kota_korespondensi",
      "kodepos_korespondensi",
    ];
    if (disabledKorenpondensi) {
      const { desa_ktp, ...other } = getValues();
      disableds = [...disableds, ...data];
      if (other.provinsi_ktp)
        this._triggerInput(other.provinsi_ktp, "provinsi_korespondensi");
      data.forEach((item) => {
        setFieldValue(item, other[`${item.split("_")[0]}_ktp`]);
      });
      setTimeout(() => validateForm(), 100);
    } else {
      disableds = disableds.filter((item) => data.indexOf(item) < 0);
    }
    this.setState({ disableds, disabledKorenpondensi });
  };

  _handleSubmit = (data) => {
    const { fetch_regis_1 } = this.props;
    const {
      nik,
      nama,
      tempat_lahir,
      tanggal_lahir,
      alamat_ktp,
      desa_ktp,
      kecamatan_ktp,
      kota_ktp,
      provinsi_ktp,
      kodepos_ktp,
      alamat_korespondensi,
      // negara_korespondensi,
      provinsi_korespondensi,
      kota_korespondensi,
      kodepos_korespondensi,
      agama,
      status_perkawinan,
      kewarganegaraan,
      gender,
      mother_name,
    } = data;
    const _res = {
      ktp_number: nik,
      ktp_name: nama,
      birth_place: tempat_lahir,
      birth_date: textHelper.printDateWithStrip(new Date(tanggal_lahir)),
      ktp_address: alamat_ktp,
      ktp_rtrw: data["rt/rw"],
      ktp_province_id: provinsi_ktp,
      ktp_city_id: kota_ktp,
      ktp_kecamatan_id: kecamatan_ktp,
      ktp_kelurahan_id: desa_ktp,
      ktp_postal_code: kodepos_ktp,
      gender_id: gender,
      marriage_id: status_perkawinan,
      religion_id: agama,
      citizenship: kewarganegaraan,
      mail_address: alamat_korespondensi,
      mail_country_id: "102",
      mail_city_id: kota_korespondensi,
      mail_postal_code: kodepos_korespondensi,
      provinsi_korespondensi,
      newKtp: this.state.reRegisSameKTP,
      mother_name,
    };
    fetch_regis_1(_res);
  };

  render() {
    const { loading_stack, optionReducer, history } = this.props;
    const {
      province_list,
      marriage_list,
      nationality_list,
      religion_list,
      kecamatan_list,
      kelurahan_list,
      gender_list,
    } = optionReducer;
    const {
      kota_ktp,
      kota_korespondensi,
      loadKTPreRegis,
      kecamatan_ktp,
      desa_ktp,
      disableds,
      isSID,
      disabledKorenpondensi,
    } = this.state;
    const loadBox =
      loadKTPreRegis || processHelper.findProcess("fetch_ocr", loading_stack);
    return (
      <TempAuth page="regisForm" data={{ showStepper: true }}>
        <RouteLeavingConfirm
          acceptLeave={() => history.replace("/onboarding")}
        />
        <FormRegister
          ref="formik"
          idSubmit="register-3"
          initial={{ tanggal_lahir: new Date() }}
          validation={validationShema}
          loadingSubmit={processHelper.findProcess("register1", loading_stack)}
          onSubmit={this._handleSubmit}
          functionTrigger={this._triggerInput}
          disabledInputs={disableds}
          data={[
            [{ Children: TitleUploadKTP }],
            [
              {
                Children: BoxUpload,
                ChildrenProps: {
                  ref: this.box_ktp,
                  onChange: this._getKtp,
                  loading: loadBox,
                },
              },
              {
                Children: SIDinput,
                ChildrenProps: {
                  ref: this.inputSID,
                  onChange: this._handleSID,
                  loading: processHelper.findProcess(
                    "validateRegis",
                    loading_stack
                  ),
                  disabled: isSID,
                },
              },
            ],
            {
              children: (
                <BasicText className="fs-14 font-weight-bold color-primary mb-1">
                  IDENTITAS
                </BasicText>
              ),
            },
            [
              {
                id: "input_nik",
                name: "nik",
                placeholder: "1234567888999000",
                label: "NIK",
                type: "number",
              },
            ],
            [
              {
                id: "input_nama",
                name: "nama",
                placeholder: "NAME SURNAME",
                label: "NAMA LENGKAP SESUAI KTP",
              },
            ],
            [
              {
                id: "input_tempat_lahir",
                name: "tempat_lahir",
                placeholder: "NAMA KOTA",
                label: "TEMPAT LAHIR",
              },
              {
                id: "input_tanggal_lahir",
                name: "tanggal_lahir",
                typeInput: "InputTimePicker",
                format: "yyyy-MM-dd",
                placeholder: "CITY/21011971",
                label: "TANGGAL LAHIR",
              },
            ],
            {
              id: "input_alamat_ktp",
              name: "alamat_ktp",
              placeholder: "JALAN STREET NAME NO.01",
              label: "ALAMAT SESUAI KTP",
              trigger: true,
            },
            [
              {
                id: "input_provinsi_ktp",
                name: "provinsi_ktp",
                label: "PROVINSI",
                typeInput: "SelectsInput",
                data: province_list,
                trigger: true,
              },

              {
                id: "input_kota_ktp",
                name: "kota_ktp",
                label: "KABUPATEN/KOTA",
                typeInput: "SelectsInput",
                data: kota_ktp,
                trigger: true,
              },
            ],
            [
              {
                id: "input_kecamatan_ktp",
                name: "kecamatan_ktp",
                label: "KECAMATAN",
                typeInput: "SelectsInput",
                data: kecamatan_list[kecamatan_ktp] || [],
                trigger: true,
              },
              {
                id: "input_desa_ktp",
                name: "desa_ktp",
                label: "KELURAHAN/DESA",
                typeInput: "SelectsInput",
                data: kelurahan_list[desa_ktp] || [],
                trigger: true,
              },
            ],
            [
              {
                id: "input_rt/rw",
                name: "rt/rw",
                placeholder: "001/002",
                label: "RT/RW",
              },

              {
                id: "input_kodepos_ktp",
                name: "kodepos_ktp",
                placeholder: "12345",
                label: "KODE POS",
                trigger: true,
              },
            ],
            {
              id: "input_alamat_korespondensi",
              name: "alamat_korespondensi",
              label: "ALAMAT KORESPONDENSI",
              componentSideLabel: (
                <CheckBoxKorespondensi
                  check={disabledKorenpondensi}
                  onChange={this._handleSameKtp}
                />
              ),
              propsContainLabel: { justify: "space-between" },
              cClass: "mt-4",
            },
            [
              {
                id: "input_provinsi_korespondensi",
                name: "provinsi_korespondensi",
                label: "PROVINSI",
                typeInput: "SelectsInput",
                data: province_list,
                trigger: true,
              },
              {
                id: "input_kota_korespondensi",
                name: "kota_korespondensi",
                label: "KABUPATEN/KOTA",
                typeInput: "SelectsInput",
                data: kota_korespondensi,
              },
            ],
            [
              {
                id: "input_kodepos_korespondensi",
                name: "kodepos_korespondensi",
                label: "KODE POS",
                placeholder: "12345",
                cClass: "mb-4",
              },
            ],
            [
              {
                id: "input_agama",
                name: "agama",
                label: "AGAMA",
                typeInput: "SelectsInput",
                data: religion_list,
              },
              {
                id: "input_status_perkawinan",
                name: "status_perkawinan",
                label: "STATUS PERKAWINAN",
                typeInput: "SelectsInput",
                data: marriage_list,
              },
            ],
            [
              {
                id: "input_kewarganegaraan",
                name: "kewarganegaraan",
                label: "KEWARGANEGARAAN",
                typeInput: "SelectsInput",
                data: nationality_list,
                valueItem: "name",
              },
              {
                id: "input_gender",
                name: "gender",
                label: "JENIS KELAMIN",
                typeInput: "SelectsInput",
                data: gender_list,
              },
              {
                id: "input_mother_name",
                name: "mother_name",
                label: "NAMA IBU KANDUNG",
                placeholder: "Nama ibu kandung",
              },
            ],
          ]}
        />
        {dialogs.map((item) => (
          <DialogAlert key={item.resultName} {...item} />
        ))}
      </TempAuth>
    );
  }
}

const mapStateToProps = ({ optionReducer, authReducer, stateReducer }) => ({
  optionReducer,
  register: authReducer.register,
  token: authReducer.token,
  loading_stack: stateReducer.loading_stack,
  result_stack: stateReducer.result_stack,
});

export default withRouter(
  connect(mapStateToProps, {
    fetch_data_ocr,
    set_app_config,
    set_data_location,
    fetch_data_sid,
    fetch_regis_1,
    setRegister: register,
    clear_register,
  })(RegisterIdentitas)
);
