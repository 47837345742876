import login from "./login.svg";
import knowledge from "./knowledge_center.svg";
import ojk from "./ojk.svg";
import risk from "./risk.svg";
import subs from "./subscript.svg";
import redeem from "./redeemSwitch.svg";
import sw from "./redeemSwitch.svg";
import pin from "./pin.svg";
import setting from "./setting.svg";
import emailChange from "./emailChange.svg";
import phoneChange from "./phoneChange.svg";
import passChange from "./passChange.svg";
import bankChange from "./bankChange.svg";
import otp from "./otp.svg";
import emptytxn from "./emptytxn.svg";
import emptyporto from "./emptyporto.svg";
import regisInputPhone from "./regisInputPhone.svg";
import regisValidateOtp from "./regisValidateOtp.svg";
import modalLogin from "./modalLogin.svg";
import comingSoon from "./comingSoon.svg";
import forgotPass1 from "./forgotPass1.svg";
import forgotPass2 from "./forgotPass2.svg";
import onboarding from "./onboarding.svg";
import notification from "./notification.svg";
import RateUs from "./RateUs.svg";
import statusRegister from "./statusRegister.svg";
// import modify_planner from "./modify_planner.svg";
import ReferanceInvest from "./ReferanceInvest.svg";
import successMovePorto from "./successMovePorto.svg";
import periodicInvest from "./periodicInvest.svg";
import comunity from "./comunity.svg";
import downloadFile from "./downloadFile.svg";
import backgroundScan from "./background-scan.svg";
export default {
  login,
  knowledge,
  ojk,
  risk,
  subs,
  redeem,
  sw,
  pin,
  setting,
  emailChange,
  phoneChange,
  passChange,
  bankChange,
  otp,
  emptytxn,
  emptyporto,
  regisInputPhone,
  regisValidateOtp,
  modalLogin,
  comingSoon,
  forgotPass1,
  forgotPass2,
  onboarding,
  notification,
  RateUs,
  statusRegister,
  // modify_planner,
  ReferanceInvest,
  successMovePorto,
  periodicInvest,
  comunity,
  downloadFile,
  backgroundScan
};
