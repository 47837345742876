import React, { useImperativeHandle } from "react";
import { forwardRef } from "react";
import SignaturePad from "react-signature-canvas";
import { useRef } from "react";
import { makeStyles, Button } from "@material-ui/core";
import color from "../../../assets/theme/color";
import { RotateLeft } from "@material-ui/icons";

const useStyles = makeStyles({
  pad: {
    border: `1px solid ${color.gray_6}`,
    borderRadius: 4,
    backgroundColor: "white",
    width: "100%",
    height: 165,
  },
});

const SignatureComponent = forwardRef((props, ref) => {
  const signature = useRef();
  const S = useStyles();
  useImperativeHandle(ref, () => ({
    _reset,
    _getImage,
  }));
  function _reset() {
    signature.current.clear();
  }


  function _getImage() {
    const data = signature.current.toDataURL("image/png");
    if (signature.current.isEmpty()) return null;
    else return data;
  }
  return (
    <div>
      <SignaturePad ref={signature} canvasProps={{ className: S.pad, id : 'sign-pad' }} />
      <div className="d-flex justify-content-end">
        <Button
          id="button-reset"
          startIcon={<RotateLeft />}
          className="fs-12 gray-5 align-self-end"
          onClick={_reset}
        >
          RESET
        </Button>
      </div>
    </div>
  );
});

export default SignatureComponent;
