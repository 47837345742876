import {
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Slider,
  withStyles,
} from "@material-ui/core";
import classNames from "classnames";
import { isNaN } from "formik";
import React, { useEffect, useState } from "react";
import color from "../../../assets/theme/color";
import BasicText from "../../atom/BasicText/BasicText";
import TextInput from "./TextInput";
import textHelper from "../../../helper/textHelper";
import { memo } from "react";

const useStyles = makeStyles({
  title: {
    fontSize: 12,
    color: color.blue_3,
    textTransform: "capitalize",
  },
  textInput: {
    border: "1px solid " + color.gray_4,
    borderRadius: "3px",
    padding: "0 10px",
    "& .MuiInputBase-input": {
      fontSize: 14,
      color: color.secondary,
    },
    "& input::-webkit-outer-spin-button,input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input": {
      "-moz-appearance": "textfield",
      paddingTop: 9,
      paddingBottom: 9,
      fontFamily: "Montserrat",
    },
    "&.Mui-focused": {
      border: "1px solid " + color.secondary,
    },
  },
  hideUnderLine: {
    "&.MuiInput-underline:before,.MuiInput-underline:after": {
      borderBottom: "0 !important",
      transition: "unset",
    },
    "&.MuiInput-underline.Mui-focused:after": {
      transform: "scaleX(0)",
    },
  },
  textAdorment: {
    fontSize: 14,
    color: color.secondary,
    marginRight: 5,
  },
});

const SliderSimulasi = withStyles({
  root: {
    paddingTop: 1,
  },
  track: {
    height: 2,
  },
  thumb: {
    width: 7,
    height: 7,
    marginTop: -2,
  },
  rail: {
    color: color.orange_7,
    opacity: 1,
  },
})(Slider);

const AdormentRupiah = ({ text = "Rp" }) => {
  const S = useStyles();
  return <span className={S.textAdorment}>{text}</span>;
};

const AdormentTimeComponent = ({
  state,
  setState,
  typeTimeChange,
  _handleChange,
  value,
}) => {
  const S = useStyles();
  function handleChange(e) {
    const { target } = e;
    setState({ ...state, value: target.value });
    typeTimeChange(target.value);
    if (target.value === "year" && value > state.maxYear)
      _handleChange(state.maxYear);
  }
  return (
    <Select
      value={state.value}
      onChange={handleChange}
      className={S.hideUnderLine}
    >
      {state.lists.map((list, index) => (
        <MenuItem key={index} value={list.id}>
          {list.name}
        </MenuItem>
      ))}
    </Select>
  );
};

const propsCanChange = ["value", "min", "max", "title"];

const controller = (prev, next) => {
  let _return = true;
  for (const _ of propsCanChange) {
    if (prev[_] !== next[_]) {
      _return = false;
      break;
    }
  }
  return _return;
};

const SliderWithInputTextComponent = ({
  title,
  value,
  onChange,
  max,
  min,
  className,
  typeTimeChange,
  textInputClass,
  withSlider,
  step,
}) => {
  const S = useStyles();
  const [state, setState] = useState({
    maxYear: 2,
    value: "year",
    lists: [
      { id: "year", name: "Tahun" },
      { id: "month", name: "Bulan" },
    ],
  });

  useEffect(() => {
    let _state = { ...state };
    if (typeTimeChange && state.maxYear !== max && state.value === "year") {
      _state.maxYear = max;
      setState(_state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [max]);

  function handleSlider(e, newValue) {
    if (e.cancelable) {
      e.preventDefault();
      e.stopPropagation();
    }
    _handleChange(newValue);
  }

  function handleInput(e) {
    _handleChange(e.replace(/[. ]+/g, "").trim());
  }

  function _handleChange(e) {
    if (!e) onChange(e);
    else {
      const num = parseFloat(e);
      if (typeTimeChange) {
        if (num <= max) onChange(num);
      } else onChange(e);
    }
  }
  const AdormentTime = () => (
    <AdormentTimeComponent
      setState={setState}
      state={state}
      value={value}
      _handleChange={_handleChange}
      typeTimeChange={typeTimeChange}
    />
  );
  return (
    <div className={className}>
      <Grid container alignItems="center">
        <Grid xs={6} item>
          <BasicText className={classNames(S.title, "title")}>
            {title}
          </BasicText>
        </Grid>
        <Grid xs={6} item className={textInputClass}>
          <TextInput
            inputClass={classNames(S.textInput, S.hideUnderLine)}
            startIcon={!typeTimeChange && AdormentRupiah}
            endIcon={typeTimeChange && AdormentTime}
            onChange={handleInput}
            value={textHelper.printMoneyInput(value)}
          />
        </Grid>
      </Grid>
      {withSlider && (
        <Grid xs={5} item>
          <SliderSimulasi
            max={max || min}
            min={min || 0}
            step={step}
            value={(!isNaN(parseFloat(value)) && parseFloat(value)) || min}
            onChange={handleSlider}
          />
        </Grid>
      )}
    </div>
  );
};

const SliderWithInputText = memo(SliderWithInputTextComponent, controller);

SliderWithInputText.defaultProps = {
  title: "Title",
  min: 0,
  max: 1000000,
  value: 1,
  onChange: (e) => console.log(e),
  className: "",
  withSlider: true,
  step: 1,
};

export default SliderWithInputText;
