import { Checkbox, FormControlLabel, withStyles } from "@material-ui/core";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import color from "../../../assets/theme/color";
import { set_persist } from "../../../config/redux/actions/persist";
import processHelper from "../../../helper/processHelper";
import DialogWithImage from "../../molecule/Dialog/DialogWithImage";

const useStyles = (theme) => ({
  content: {
    maxWidth: 360,
    alignSelf: "center",
  },
});

const CheckBoxLabel = withStyles({
  label: {
    fontSize: 10,
    color: color.gray_4,
    fontFamily: "Montserrat",
  },
})(FormControlLabel);

class TipsKeamanan extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      modal: props.hide_modal_login || false,
      text: "",
    };
  }

  componentDidMount = () => {
    const { text_modal_login, hide_modal_login, result_stack } = this.props;
    const res = processHelper.findResult("change_pass_change", result_stack);
    if (!hide_modal_login && !Object.keys(res).length) {
      const index = Math.floor(Math.random() * 4) + 1;
      setTimeout(
        () => this.setState({ visible: true, text: text_modal_login[index] }),
        800
      );
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { visible } = this.state;
    if (!visible && visible !== prevState.visible) {
      this._setModalPersist(true);
    }
  };

  _setModalPersist = (update) => {
    const { modal } = this.state;
    const { hide_modal_login, set_persist } = this.props;
    if (modal !== hide_modal_login) {
      setTimeout(
        () => set_persist({ hide_modal_login: modal }),
        update ? 500 : 0
      );
    }
  };

  componentWillUnmount = () => {
    this._setModalPersist();
  };

  _handleCheckBox = (e) => this.setState({ modal: e.target.checked });

  _handleClose = (visible) => this.setState({ visible });

  render() {
    const { hide_modal_login, classes } = this.props;
    const { modal, visible, text } = this.state;
    if (!hide_modal_login) {
      return (
        <DialogWithImage
          title="TIPS KEAMANAN"
          text={text}
          ilustration="modalLogin"
          open={visible}
          handleClose={this._handleClose}
          contentClass={classes.content}
        >
          <CheckBoxLabel
            control={
              <Checkbox
                id="hide-modal-persist"
                color="default"
                size="small"
                onChange={this._handleCheckBox}
                checked={modal}
              />
            }
            label="Jangan ditampilkan lagi"
            labelPlacement="start"
            className="mt-3 mb-n2"
          />
        </DialogWithImage>
      );
    } else return null;
  }
}

const mapStateToProps = ({ persistReducer, stateReducer }) => ({
  hide_modal_login: persistReducer.hide_modal_login,
  text_modal_login: persistReducer.text_modal_login,
  result_stack: stateReducer.result_stack,
});

export default compose(
  connect(mapStateToProps, { set_persist }),
  withStyles(useStyles)
)(TipsKeamanan);
