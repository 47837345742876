import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import ScrollFilter from "../organism/ProductPage/ScrollFilter";
import ProductList from "../organism/ProductPage/ProductList";
import { connect } from "react-redux";
import {
  set_product,
  product_type_props,
} from "../../config/redux/actions/product";
import { compose } from "redux";
import "../../assets/styles/productAnimate.css";
import { withRouter } from "react-router-dom";
// import SyariahFilter from "../molecule/Product/SyariahFilter";
import DialogCustom from "../organism/Container/DialogCustom";
import TempMain from "../template/TempMain";
import { profile_risk_props } from "../../config/redux/actions/profile";
import DialogAlert from "../molecule/Alert/DialogAlert";

const useStyles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100vh",
  },
  modal: {
    "& .MuiDialog-container .MuiPaper-root": {
      maxWidth: 375,
    },
    "& .content": {
      padding: "45px 66px 33px",
    },
  },
});

const modalAlert = [
  {
    title: "Masuk",
    text: "Mohon masuk terlebih dahulu untuk melanjutkan proses ini.",
    buttonText: "MASUK SEKARANG",
  },
  {
    title: <span className="color-primary">PENTING</span>,
    text: "Produk yang kamu pilih tidak sesuai dengan profil risiko kamu.",
    buttonText: "MENGERTI",
  },
  {
    title: "SUSPEND INFO",
    text: "Mohon maaf untuk semetara waktu transaksi pembelian produk ini tidak dapat dilakukan karena terdapat pembatasan nilai penawaran unit penyertaan",
    buttonText: "MENGERTI",
  },
];

class ProductPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: "",
      active: false,
      isSyariah: false,
      modal: false,
      modalType: 0,
    };
  }
  componentDidMount() {
    const {
      set_product,
      product_type_props,
      profile_risk_props,
      isLogin,
      product_type,
    } = this.props;
    set_product();
    product_type_props();
    if (isLogin) profile_risk_props();
    if (product_type.length > 0) {
      this.initialFilter();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { product_type } = this.props;
    if (prevProps.product_type !== product_type && product_type.length > 0) {
      this.initialFilter();
    }
  }

  initialFilter = () => {
    const {
      product_type,
      history: {
        location: { state },
      },
    } = this.props;
    let index = 0;
    console.log(product_type);
    if (state?.initialType) {
      index = product_type.findIndex(({ id }) => id === state.initialType);
    }
    this.setState({ filter: product_type[index].id });
  };

  onBook = (code) => {
    const { push, location } = this.props.history;
    push("/book", {
      ...location.state?.nextParam,
      products: { [code]: { amount: 100 } },
    });
  };

  onSubProduct = (code) => {
    const { push, location } = this.props.history;
    push("/subscript", {
      ...location.state?.nextParam,
      products: { [code]: { amount: 100 } },
    });
  };

  onFilter = (e) => {
    if (e !== this.state.filter) {
      this.setState({ active: true });
      setTimeout(() => this.setState({ active: false }), 500);
      setTimeout(() => this.setState({ filter: e }), 180);
      // this.setState({ filter: e });
    }
  };
  // onFilterSyariah = e => {
  //   this.setState({ active: true });
  //   setTimeout(() => this.setState({ active: false }), 800);
  //   setTimeout(() => this.setState({ isSyariah: e }), 400);
  //   this.setState({ isSyariah: e });
  // };
  handleClick = ({ link, items }) => {
    const { history, isLogin, profile_risk } = this.props;
    switch (link) {
      case "/subscript":
        if (isLogin === 1) {
          const profile_product = profile_risk.profile_risk_product || [];
          if (items.is_suspend === 1) this.handleModal(true, 2, items.code);
          else if (
            profile_product.length &&
            profile_product.findIndex(
              (rec) => rec.product_code === items.code
            ) < 0
          ) {
            this.handleModal(true, 1, items.code);
          } else this.onSubProduct(items.code);
        } else {
          this.handleModal(true, 0);
        }
        return;
      case "/book":
        this.onBook(items.code);
        return;
      default:
        history.push(link + "?product=" + items.code, {
          product: items,
        });
        return;
    }
  };
  linkTo = () => {
    const { modalType, params } = this.state;
    const { replace } = this.props.history;
    switch (modalType) {
      case 0:
        return replace("/masuk");
      case 1:
        return this.onSubProduct(params);
      case 2:
        return this.setState({ modal: false, modalType: null, params: null });
      default:
        return;
    }
  };
  handleModal = (e, modalType, params) => {
    let _res = { modal: e };
    if (modalType) _res.modalType = modalType;
    if (params) _res.params = params;
    this.setState(_res);
  };
  render() {
    const { classes, history } = this.props;
    const { filter, active, isSyariah, modal, modalType } = this.state;
    return (
      <TempMain title="PRODUK" bottomNavValue={1} initialPage>
        <div className="pt-md-4 px-md-4">
          <ScrollFilter
            onClick={this.onFilter}
            initial={history.location.state?.initialType}
          />
          <div className="margin-main">
            <div className="py-3">
              {/* <SyariahFilter
                code={this.state.filter}
                onChange={this.onFilterSyariah}
              /> */}
              {/* <div className={active ? "active-change" : ""}>
                <ProductList
                  code={filter}
                  isSyariah={isSyariah}
                  onClick={this.handleClick}
                />
              </div> */}
              <div className={active ? "active-change" : ""}>
                <ProductList
                  code={filter}
                  isSyariah={isSyariah}
                  onClick={this.handleClick}
                />
              </div>
            </div>
          </div>
        </div>
        <DialogCustom
          open={modal}
          handleClose={this.handleModal}
          {...modalAlert[modalType]}
          onClick={this.linkTo}
          maxWidth="xs"
          modalClass={classes.modal}
          className="content"
          actionClass="p-0"
          buttonClass="py-4"
        />
        <DialogAlert alertAppear="error" resultName="profile_risk" />
      </TempMain>
    );
  }
}

const mapState = (state) => ({
  isLogin: state.authReducer.isLogin,
  profile_risk: state.profileReducer.profile_risk,
  product_type: state.productReducer.product_type,
});

export default compose(
  connect(mapState, { set_product, product_type_props, profile_risk_props }),
  withRouter,
  withStyles(useStyles)
)(ProductPage);
