import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import React from "react";
import color from "../../../assets/theme/color";
import textHelper from "../../../helper/textHelper";
import ButtonContained from "../../atom/Button/ButtonContained";
import SliderWithInput from "../../molecule/MaterialInput/SliderWithInput";
import OrderResult from "./OrderResult";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
const { useCallback, useMemo } = React;

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up(768)]: {
      borderRadius: "0 0 4px 4px",
      marginTop: -1,
    },
  },
  subRoot: {
    width: 398,
    [theme.breakpoints.down(769)]: {
      width: "100%",
    },
  },
  buttonRecommend: {
    width: "calc((100% / 4) - 5px)",
  },
  labelInput: {
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      fontSize: "13px !important",
    },
  },
}));

const SubscriptInput = forwardRef(
  (
    {
      products,
      onStatus,
      initialState,
      children,
      getValue,
      sideContent,
      classContainInput,
    },
    ref
  ) => {
    const classes = useStyles();
    const isMultiple = products.length > 1;
    const maxValue = isMultiple ? 5000000 : 1000000;

    const [state, setState] = React.useState({
      active: null,
      value: initialState,
      maxInput: 0,
      minValue: 0,
      bonus: 0,
      recActive: undefined,
    });

    const { rec, fee, min } = useMemo(() => {
      const def_rec = "100000,500000,1000000,2000000";
      const prod_rec = isMultiple
        ? def_rec
        : products[0]?.recommend_sub ?? def_rec;
      const fee = products
        .map(({ fee_sub }) => Number(fee_sub))
        .reduce((a, b) => a + b, 0);
      const min = products
        .map(({ min_sub }) => Number(min_sub))
        .reduce((a, b) => a + b, 0);
      return { rec: prod_rec?.split(","), fee, min };
    }, [isMultiple, products]);

    const handleInputChange = useCallback(
      (e, other) => {
        setState((state) => ({ ...state, ...other, value: e }));
        onStatus(Boolean(e));
        getValue(e);
      },
      [getValue, onStatus]
    );

    const handleRec = useCallback(
      (e) => handleInputChange(e, { recActive: e }),
      [handleInputChange]
    );

    useImperativeHandle(
      ref,
      () => ({ validate: () => state.value < min, value: state.value }),
      [state.value, min]
    );

    return (
      <>
        <div
          className={classNames(
            "card-custom mb-3 pt-md-2 px-md-4",
            classes.root,
            classContainInput
          )}
        >
          <div className={classes.subRoot}>
            <SliderWithInput
              id="subs"
              adormentContent={() =>
                state.value !== "" ? (
                  <span className="fs-14 gray-4">Rp</span>
                ) : null
              }
              adormentPlace="start"
              sliderLabelClass={classes.labelInput}
              maxValue={maxValue}
              minValue={min}
              stepSlider={1000}
              text="Nominal Pembelian"
              value={state.value}
              onChange={(e) => handleInputChange(e, { recActive: undefined })}
              placeholder={"Min. " + textHelper.printMoney(products.min_sub)}
              unlimited
            />
            <div className="d-flex w-100 justify-content-between mt-2">
              {rec.map((el, id) => (
                <ButtonContained
                  id={`${el}-btn`}
                  key={id}
                  backgroundColor={
                    String(state.recActive) === String(el)
                      ? color.gray_4
                      : color.gray_6
                  }
                  text={textHelper.printMoneyWithoutRp(el)}
                  className={classNames("fs-12 py-1", classes.buttonRecommend)}
                  onClick={() => handleRec(el)}
                />
              ))}
            </div>
          </div>
          {sideContent}
        </div>
        {children ? children : null}
        <OrderResult value={state.value} fee={fee} />
      </>
    );
  }
);

SubscriptInput.defaultProps = {
  products: [],
  initialState: "",
};

export default SubscriptInput;
