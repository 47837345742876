import React from "react";
import Grid from "@material-ui/core/Grid";
import BasicText from "../../atom/BasicText/BasicText";
import color from "../../../assets/theme/color";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";
import makeStyles from "@material-ui/styles/makeStyles";

const MenuGoals = ({ item, active }) => {
  const { id, ref_goals_icon } = item;
  const isDesktop = useMediaQuery({ minWidth: 769 });
  const useStyles = makeStyles({
    root: { maxWidth: 180, backgroundColor: "white" },
    containIcon: {
      height: 80,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    icon: {
      width: 46.62,
    },
    containName: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      marginLeft: 5,
      marginTop: 2,
      height: "100%",
      [`#goals_planner_${id - 1}:hover &, &.active`]: {
        borderBottom: isDesktop ? `2px solid ${color.primary}` : 0,
      },
    },
  });
  const S = useStyles();

  return (
    <Grid
      container
      alignItems="stretch"
      id={`goals_planner_${id - 1}`}
      className={classNames("is-link", isDesktop ? "mx-3" : "p-3", S.root)}
    >
      <Grid xs={isDesktop ? 4 : 12} item className={S.containIcon}>
        <img
          src={ref_goals_icon.icon_web_path || ref_goals_icon.icon_path}
          alt=""
          className={S.icon}
          onDragStart={(e) => e.preventDefault()}
        />
      </Grid>
      <Grid
        xs={isDesktop ? 8 : 12}
        item
        container
        justify={isDesktop ? "flex-start" : "center"}
      >
        <Grid item className={classNames(S.containName, active && "active")}>
          <BasicText
            className={classNames(
              "fs-12 text-uppercase ws",
              !isDesktop && "text-center"
            )}
          >
            {ref_goals_icon.name}
          </BasicText>
        </Grid>
      </Grid>
    </Grid>
  );
};

MenuGoals.defaultProps = {
  item: {},
};

export default MenuGoals;
