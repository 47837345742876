import { makeStyles } from "@material-ui/core";

export default makeStyles(() => ({
  container: {
    padding: "12px 17px 12px 30px",
    "& .item-select-goals": {
      // maxWidth: 60,
    },
  },
}));
