import { Checkbox, CircularProgress, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import parser from "html-react-parser";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import color from "../../../assets/theme/color";
import { content } from "../../../config/redux/actions/option";
import ButtonContained from "../../atom/Button/ButtonContained";
import DialogCustom from "../Container/DialogCustom";

const styleCheck = {
  marginTop: -9,
  marginLeft: -9,
};

const CheckBoxApprovedTxn = React.memo(
  ({ typeApprove, prosLink, onChange, onLink, className }) => {
    const [check, setCheck] = useState(false);

    const handleCheck = () => {
      let newCheck = !check;
      setCheck(newCheck);
      onChange(newCheck);
    };

    return (
      <div className={classNames("d-flex", className)}>
        <Checkbox
          id="check-input"
          checked={check}
          color="primary"
          onChange={handleCheck}
          style={styleCheck}
          className="color-primary"
        />
        <p className="basic w-100">
          <span onClick={handleCheck} className="is-link">
            Saya telah membaca dan menyetujui {typeApprove} reksa dana ini
            sesuai dengan{" "}
          </span>
          {prosLink && (
            <a
              id="pros-btn"
              href={prosLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Prospektus,
            </a>
          )}{" "}
          <span
            id="s&k-btn"
            className="color-secondary text-underline is-link"
            onClick={onLink}
          >
            Syarat dan Ketentuan Transaksi Reksadana Online Syailendra
          </span>
        </p>
      </div>
    );
  }
);

const CheckButtonOrder = (props) => {
  const {
    handleCheck,
    handleBack,
    handleSubmit,
    loadingNext,
    disabledNext,
    buttonContainerClass,
    prosLink,
    syaratLink,
    rootClass,
    radiusTopUnset,
    content,
    optionData,
    typeApprove,
  } = props;
  const useStyles = makeStyles((theme) => ({
    root: {
      [theme.breakpoints.down(769)]: {
        backgroundColor: "unset",
        boxShadow: "unset",
        MozBoxShadow: "unset",
        WebkitBoxShadow: "unset",
      },
      "&.radiusTopUnset": {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        marginTop: -1,
      },
    },
    check: {
      maxWidth: 305,
      alignItems: "flex-start",
    },
    buttonContainer: {
      marginTop: "1.5rem",
      display: "flex",
      justifyContent: "center",
      width: "100%",
      [theme.breakpoints.up(768)]: {
        justifyContent: "start",
      },
      "& .btnNext": {
        width: 150,
        borderRadius: 6,
        fontSize: 13,
        fontWeight: 700,
        padding: "0.75rem 2rem",
        marginRight: "1rem",
        [theme.breakpoints.down(769)]: {
          marginRight: 0,
        },
      },
      "& .btnCancel": {
        width: 150,
        borderRadius: 6,
        fontSize: 13,
        fontWeight: 700,
        padding: "0.75rem 2rem",
        [theme.breakpoints.down(769)]: {
          display: "none",
        },
      },
    },
  }));
  const classes = useStyles();
  const [modal, setModal] = React.useState(false);
  const handleModal = (e) => {
    setModal(e);
  };
  const [once, setOnce] = React.useState(true);
  React.useEffect(() => {
    if (once) {
      content(syaratLink);
      setOnce(false);
    }
  }, [content, syaratLink, once]);
  const isSubs = typeApprove === "pembelian";
  return (
    <div
      className={classNames(
        // "card-custom",
        rootClass,
        classes.root,
        radiusTopUnset && "radiusTopUnset"
      )}
    >
      <CheckBoxApprovedTxn
        onChange={handleCheck}
        onLink={() => handleModal(true)}
        typeApprove={typeApprove}
        prosLink={isSubs && prosLink}
        className={classes.check}
      />
      {/* <CheckInput
        label={`Saya telah membaca dan menyetujui ${typeApprove} reksa dana ini sesuai dengan `}
        link={
          <>
            {isSubs && (
              <a href={prosLink} target="_blank" rel="noopener noreferrer">
                Prospektus,
              </a>
            )}{" "}
            <span
              className="color-secondary text-underline"
              onClick={() => handleModal(true)}
            >
              Syarat dan Ketentuan Transaksi Reksadana Online Syailendra
            </span>
          </>
        }
        checkClass="py-0 color-primary "
        labelClass="gray-4"
        className={classes.check}
        onChange={handleCheck}
        id="check"
      /> */}
      <div
        className={classNames(classes.buttonContainer, buttonContainerClass)}
      >
        <ButtonContained
          id="lanjut-btn"
          text="LANJUT"
          className="btnNext"
          radius="5px"
          disabled={disabledNext}
          onClick={handleSubmit}
          loading={loadingNext}
        />
        <ButtonContained
          id="kembali-btn"
          backgroundColor={color.gray_6}
          text="KEMBALI"
          className="btnCancel"
          radius="5px"
          onClick={handleBack}
        />
      </div>
      <DialogCustom
        open={modal}
        handleClose={handleModal}
        text={
          optionData[syaratLink] ? (
            parser(optionData[syaratLink] || "")
          ) : (
            <CircularProgress size={16} />
          )
        }
        textClass="fs-12"
        className="pl-5"
        buttonText="MENGERTI"
        onClick={() => handleModal(false)}
      />
    </div>
  );
};

CheckButtonOrder.propTypes = {
  handleCheck: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleBack: PropTypes.func,
  loadingNext: PropTypes.bool,
  disabledNext: PropTypes.bool,
  buttonContainerClass: PropTypes.string,
  prosLink: PropTypes.string,
  syaratLink: PropTypes.string,
  rootClass: PropTypes.string,
  radiusTopUnset: PropTypes.bool,
};

CheckButtonOrder.defaultProps = {
  prosLink: "/",
  syaratLink: "/",
  radiusTopUnset: false,
  typeApprove: "pembelian",
};

const mapState = (state) => ({
  optionData: state.optionReducer,
});

export default connect(mapState, { content })(CheckButtonOrder);
