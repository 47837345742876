import React from "react";
import StepperPageForm from "../../molecule/Register/StepperPageForm";
import ContainerRegister from "../../organism/Container/ContainerRegister";

const TempRegisFormDesktop = ({ children, data }) => {
  const { active, title, showStepper } = data;
  return (
    <ContainerRegister title={title}>
      {showStepper && <StepperPageForm active={active || 0} className="mb-4" />}
      {children}
    </ContainerRegister>
  );
};

TempRegisFormDesktop.defaultProps = {
  data: {},
};

export default TempRegisFormDesktop;
