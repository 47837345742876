import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  root: {
    padding: 16,
    "& .select-goal-button": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingBottom: 5,
      marginBottom: 8,
      borderBottom: `1px solid ${theme.palette.secondary[5]}`,
      "&.select-mode .icon": {
        rotate: 90,
      },
    },
  },
  txtInputUnit: {
    "& .MuiOutlinedInput-root": {
      height: 30,
    },
  },
  btnMove: {
    "& .MuiButton-contained.Mui-disabled": {
      color: "white",
      backgroundColor: theme.palette.primary.lighter,
    },
  },
}));
