import React from "react";
import Grid from "@material-ui/core/Grid";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  bubleFilter: {
    border: "1px solid " + theme.palette.secondary[4],
    padding: "6px 0",
    borderRadius: 14,
    display: "grid",
    placeItems: "center",
    "& p": { fontSize: 10 },
    "&.active": {
      backgroundColor: theme.palette.secondary[7],
    },
  },
}));

const ItemTypeFilter = ({ name, label, data, active, onChange }) => {
  const classes = useStyles();
  return (
    <>
      <p className="basic fs-10 mb-2">{label}</p>
      <Grid container spacing={1}>
        {data.map((item, index) => (
          <Grid item xs={3} key={item.key || index}>
            <div
              id={`${name}-${item.title}`.toLowerCase()}
              className={classNames(
                classes.bubleFilter,
                active === item.key && "active",
                "is-link"
              )}
              onClick={() => onChange(item.key, name)}
            >
              <p className="basic">{item.title}</p>
            </div>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

ItemTypeFilter.defaultProps = {
  data: [],
  onChange: () => {},
};

export default ItemTypeFilter;
