import { makeStyles } from "@material-ui/core";
import color from "../../../../assets/theme/color";
export default makeStyles((theme) => ({
  root: {
    borderRadius: "4px",
    padding: "21px 29px 16px 28px",
  },
  borderTitle: {
    borderBottom: `1px solid ${theme.palette.secondary[7]}`,
  },
  borderDot: {
    borderBottom: `2px dotted ${theme.palette.secondary[4]}`,
  },
  flag: {
    alignSelf: "flex-start",
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 20,
    textAlign: "center",
    maxWidth: 100,
    fontSize: 10,
    color: theme.palette.secondary.main,
    padding: "4px 6px",
    "&.R": {
      backgroundColor: theme.palette.secondary[8],
    },
    "&.D": {
      backgroundColor: theme.palette.secondary[7],
    },
  },
  icon_goals: {
    width: 40,
    marginRight: 7,
  },
  notice: {
    backgroundColor: color.gray_5,
    padding: "12px 16px",
    marginBottom: 13,
  },
}));
