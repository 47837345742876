import * as types from "./types";

export const set_notif = (payload) => {
  return {
    type: types.SET_NOTIF,
    payload,
  };
};
export const fetch_notif_list = (payload) => ({
  type: types.FETCH_NOTIF_LIST,
  payload,
});
