import * as types from "../actions/types";

const initial = {
  loading_stack: [],
  result_stack: [],
  result_data_stack: {},
  tmp_key: "",
};

const initial_backup = { ...initial };

const stateReducer = (state = initial, action) => {
  switch (action.type) {
    case types.SET_LOADING_STACK:
      let loading_stack = [...state.loading_stack, action.payload];
      return { ...state, loading_stack };
    case types.REMOVE_LOADING_STACK:
      let result = state.loading_stack.filter((el) => el !== action.payload);
      return { ...state, loading_stack: result };
    case types.SET_RESULT:
      let result_stack = [...state.result_stack, action.payload];
      result_stack = result_stack.filter(
        (item, index) =>
          result_stack.findIndex(({ name }) => name === item.name) === index
      );
      return { ...state, result_stack };
    case types.REMOVE_RESULT:
      return { ...state, result_stack: action.payload };
    case types.SET_RESULT_DATA:
      return {
        ...state,
        result_data_stack: { ...state.result_data_stack, ...action.payload },
      };
    case types.LOG_OUT:
      return initial_backup;
    case types.SET_TMP_KEY:
      return { ...state, tmp_key: action.payload };
    default:
      return state;
  }
};
export default stateReducer;
