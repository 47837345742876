import * as types from "../actions/types";

const initial = {
  top_product: [],
  product: [],
  trending: [],
  product_type: [],
  history: []
};

const productReducer = (state = initial, action) => {
  switch (action.type) {
    case types.SET_TOP_PRODUCT_LIST:
      return { ...state, top_product: action.payload };
    case types.SET_PRODUCT_LIST:
      return { ...state, product: action.payload };
    case types.SET_TRENDING:
      return { ...state, trending: action.payload };
    case types.PRODUCT_TYPE:
      return { ...state, product_type: action.payload };
    case types.PRODUCT_HISTORY:
      return { ...state, history: action.payload };
    case types.CLEAR_PRODUCT_HISTORY:
      return { ...state, history: [] };
    default:
      return state;
  }
};

export default productReducer;
