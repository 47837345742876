import withStyles from "@material-ui/styles/withStyles";
import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { portofolio_list_props } from "../../config/redux/actions/portofolio";
import { get_acc_bank_props } from "../../config/redux/actions/profile";
import { set_redeem_props } from "../../config/redux/actions/transaction";
import processHelper from "../../helper/processHelper";
import textHelper from "../../helper/textHelper";
import DialogAlert from "../molecule/Alert/DialogAlert";
import InputSellMulti from "../molecule/Sell/InputSellMulti";
import RowTotal from "../molecule/Sell/RowTotal";
import SellResult from "../molecule/Sell/SellResult";
import ContainerWithLoader from "../organism/Container/ContainerWithLoader";
import DialogCustom from "../organism/Container/DialogCustom";
import CheckButtonOrder from "../organism/Order/CheckButtonOrder";
import PortoConclusion from "../organism/Order/PortoConclusion";
import SellBankAccount from "../organism/Order/SellBankAccount";
import SellInput from "../organism/Order/SellInput";
import DialogPin from "../organism/PinInput/DialogPin";
import TempMain from "../template/TempMain";

const useStyles = (theme) => ({
  modalInfo: {
    width: 389,
    padding: 30,
  },
});

class OrderToSell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      modalCot: false,
      user_account: "",
      check: false,
      each_product_value: {},
      modalPin: false,
      data: {},
      portoData: [],
      fee_red: 0,
      is_all: false,
    };
    this.rootRef = React.createRef();
    this.multiInput = React.createRef();
  }
  componentDidMount() {
    this.rootRef.current.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps, prevState) {
    const { result_stack, list, history } = this.props;
    if (list !== prevProps.list && list.length > 0) {
      this.initialData(list);
    }
    if (result_stack !== prevProps.result_stack) {
      let data = processHelper.findResult(
        ["redeem", "validate_redeem"],
        result_stack
      );
      if (Object.keys(data).length > 0) {
        if (data.name === "redeem") {
          if (data.type === "success") {
            const txn = data.data.data;
            history.replace("/finish-redeem", { ids: txn.map(({ id }) => id) });
          } else {
            this.handleModal(false, "modalPin");
          }
        } else {
          if (data.type === "success") {
            this.handleModal(true, "modalPin");
            let tmp = { ...this.state.data };
            delete tmp.is_validating;
            this.setState({ data: tmp });
          }
        }
      }
    }
  }

  initialData = (list) => {
    const { state } = this.props.history.location;
    if (state === undefined) this.props.history.goBack();
    else {
      let tmp = [];
      let fee_red = 0;
      let each_product_value = {};
      state.forEach((id) => {
        const index = list.findIndex((item) => String(item.id) === String(id));
        if (index >= 0) {
          const el = list[index];
          fee_red += parseFloat(el.product.fee_red);
          tmp.push(list[index]);
          each_product_value = {
            ...each_product_value,
            [id]: { value: "", is_all: 0 },
          };
        }
      });
      this.setState({ portoData: tmp, fee_red, each_product_value });
    }
  };
  handleCheck = (e) => {
    this.setState({ check: e });
  };

  handleInput = (e, portoData, is_all) => {
    this.handleValue(e);
    this.setState((state) => ({
      each_product_value: {
        ...state.each_product_value,
        [portoData.id]: {
          ...state.each_product_value[portoData.id],
          value: e,
          is_all,
        },
      },
    }));
  };

  handleSelected = (e) => this.setState({ user_account: e });

  handleModal = (e, name) => {
    this.setState({ [name]: e });
  };

  // MULTIPLE
  handleSubmit = () => {
    const { is_all, value, user_account } = this.state;
    const { portofolio, allocations_redeem } = this.props.location.state;
    const total = value;
    const params = {};
    portofolio.forEach(({ portofolio_sub }, index) => {
      portofolio_sub.forEach(({ id, product }) => {
        params[`txn[${index}][portfolio_sub_id]`] = id;
        params[`txn[${index}][user_account]`] = user_account;
        if (is_all) params[`txn[${index}][is_all]`] = 1;
        else {
          const amounts =
            Number(total) *
            (allocations_redeem.find(
              (item) => item.code === product.display_code
            )?.allocation /
              100);
          params[`txn[${index}][amounts]`] = isNaN(amounts) ? 0 : amounts;
        }
      });
    });
    this.setState({ data: params });
    this.handleModal(true, "modalCot");
  };

  //SINGLE
  handleSubmitSingle = () => {
    const { porto } = this.props.location.state || {};
    const { user_account, each_product_value } = this.state;
    const { value, is_all } = each_product_value[porto.id];
    let tmp = {
      "txn[0][portfolio_sub_id]": porto.id,
      "txn[0][user_account]": user_account,
    };
    if (Boolean(is_all)) tmp["txn[0][is_all]"] = is_all;
    else tmp["txn[0][amounts]"] = value;
    this.setState({ data: tmp });
    this.handleModal(true, "modalCot");
  };

  handleCot = () => {
    this.handleModal(false, "modalCot");
    this.props.set_redeem_props(this.state.data);
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  calculateValue = () => {
    const { is_follow_robo } = this.props.location.state;
    const { each_product_value } = this.state;
    if (is_follow_robo) return this.state.value;
    let value = 0;
    Object.keys(each_product_value).forEach((e) => {
      value += parseFloat(each_product_value[e].value);
    });
    return value;
  };

  handleValue = (value) => this.setState({ value });

  handleIsAll = (is_all) => this.setState({ is_all });

  render() {
    const {
      modalPin,
      modalCot,
      user_account,
      check,
      data,
      portoData,
      fee_red,
      value,
      is_all,
    } = this.state;
    const {
      bank_account,
      set_redeem_props,
      classes,
      loading_stack,
      location: { state },
    } = this.props;

    return (
      <TempMain title="PENJUALAN">
        <ContainerWithLoader processName="portofolio_list" loadingHeight="80vh">
          <div className="margin-main pt-4 px-3" ref={this.rootRef}>
            {state.is_follow_robo ? (
              <>
                <InputSellMulti
                  {...state}
                  onIsAll={this.handleIsAll}
                  onChange={this.handleValue}
                  value={value}
                />
                {value
                  ? state?.portofolio.map(({ portofolio_sub }, index) => (
                    <div key={index}>
                      {portofolio_sub.map((data) => {
                        const alloc =
                          state?.allocations_redeem[
                            state?.allocations_redeem?.findIndex(
                              ({ code }) => code === data.product.display_code
                            )
                          ]?.allocation ?? 0;
                        return (
                          <PortoConclusion
                            data={data}
                            key={data.id}
                            marginClass="mb-3"
                            borderBottom={false}
                          >
                            <RowTotal
                              title={"Nominal Unit Dijual"}
                              classesTitle="fs-14 fw-700"
                              classesChildren="fs-13 fw-600"
                            >
                              {is_all
                                ? data.fr_value
                                : textHelper.printMoney(
                                  value * (alloc / 100)
                                )}
                            </RowTotal>
                          </PortoConclusion>
                        );
                      })}
                    </div>
                  ))
                  : null}
              </>
            ) : (
              <div
                className={classNames(
                  classes.redeemInputSection,
                  "mb-3 card-custom"
                )}
              >
                <PortoConclusion
                  marginClass="mb-0 p-0 no-shadow"
                  data={state?.porto ?? {}}
                />
                <SellInput
                  portoData={state?.porto ?? {}}
                  onChange={this.handleInput}
                />
              </div>
            )}
            <SellResult value={value} fee={fee_red} />
            {value ? (
              <SellBankAccount
                data={bank_account}
                onChange={this.handleSelected}
                handleCheck={this.handleCheck}
                handleSubmit={this.handleSubmit}
                handleBack={this.handleBack}
                value={this.calculateValue()}
                user_account={user_account}
                check={check}
              />
            ) : null}
            <CheckButtonOrder
              typeApprove="penjualan"
              handleCheck={this.handleCheck}
              handleSubmit={
                state.is_follow_robo
                  ? this.handleSubmit
                  : this.handleSubmitSingle
              }
              handleBack={this.handleBack}
              buttonContainerClass="pl-0 pl-md-5"
              radiusTopUnset={true}
              syaratLink="syarat_red"
              loadingNext={processHelper.findProcess(
                "validate_redeem",
                loading_stack
              )}
              prosLink={
                portoData.length > 0
                  ? portoData[0].product.product_file
                    ? portoData[0].product.product_file.path
                    : "/"
                  : "/"
              }
              disabledNext={
                this.calculateValue() > 0 && check && user_account !== ""
                  ? false
                  : true
              }
            />
          </div>
        </ContainerWithLoader>
        <DialogPin
          modal={modalPin}
          onClose={(e) => this.handleModal(e, "modalPin")}
          onSubmit={set_redeem_props}
          dataSubmit={data}
          processName="redeem"
        />
        <DialogCustom
          open={modalCot}
          handleClose={(e) => this.handleModal(e, "modalCot")}
          title={<span className="fs-14 gray-3 fw-700">CUT-OFF TIME</span>}
          text="Cut-off Time transaksi reksa dana adalah Batasan waktu penerimaan transaksi setiap harinya. Cut-off time transaksi berlangsung hingga pukul 13.00 WIB setiap hari bursa. Transaksi yang dilakukan setelah waktu transaksi yang telah ditentukan akan diproses setelah satu hari kerja bursa. "
          buttonText="OK"
          onClick={this.handleCot}
          className={classes.modalInfo}
        />
        <DialogAlert
          resultName={["redeem", "validate_redeem"]}
          buttonText="OK"
        />
      </TempMain>
    );
  }
}

const mapState = (state) => ({
  list: state.portoReducer.portofolio_list,
  bank_account: state.profileReducer.bank_account?.filter?.(({ status }) => status === "APPROVED"),
  result_stack: state.stateReducer.result_stack,
  loading_stack: state.stateReducer.loading_stack,
});

export default compose(
  connect(mapState, {
    portofolio_list_props,
    get_acc_bank_props,
    set_redeem_props,
  }),
  withRouter,
  withStyles(useStyles)
)(OrderToSell);
