import { useCallback, useEffect, useState, useRef } from "react";

const useStateFetch = (initial, effect = () => {}) => {
  const [state, setState] = useState(initial);
  const unmount = useRef(false);

  const success = useCallback((data) => !unmount.current && setState(data), [
    unmount,
  ]);

  useEffect(() => {
    effect(success);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => (unmount.current = true);
  }, []);

  return [state, success];
};

export default useStateFetch;
