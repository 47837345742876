import { Card, Grid, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { useHistory } from "react-router-dom";
import BasicText from "../../atom/BasicText/BasicText";
import ButtonContained from "../../atom/Button/ButtonContained";
import CloseButton from "../../atom/CloseButton";
import GoalIcon from "../../molecule/GoalIcon";
import TempMain from "../../template/TempMain";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 8,
    "& .item-goal .container-image.checked": {
      backgroundColor: theme.palette.secondary[8],
      borderRadius: 4,
    },
  },
  btnOk: {
    width: 207,
    height: 48,
    borderRadius: "6px !important",
    margin: "60px 0px",
  },
}));

const ChangeIconPage = ({ item, onChecked, onSubmit, checked, loading }) => {
  const { goBack } = useHistory();
  const classes = useStyles();
  return (
    <TempMain title="UBAH PORTOFOLIO" bottomNavValue={3}>
      <Card className={classNames("m-4 p-4 shadow-card", classes.root)}>
        <div className="d-flex justify-content-between align-items-center mb-5">
          <BasicText className="fs-18 font-weight-medium gray-3">
            Pilih Icon Portofolio
          </BasicText>
          <CloseButton onClick={goBack} id="close-btn" />
        </div>
        <div
          style={{ width: "80%" }}
          className="pl-5 d-flex flex-column align-items-center"
        >
          <Grid container spacing={3}>
            {item.map((el, id) => (
              <Grid key={id} item xs={2}>
                <GoalIcon
                  item={el}
                  checked={checked.includes(el.id)}
                  onChecked={onChecked}
                />
              </Grid>
            ))}
          </Grid>
          <ButtonContained
            id="ok-btn"
            text="OK"
            onClick={onSubmit}
            className={classes.btnOk}
            loading={loading}
          />
        </div>
      </Card>
    </TempMain>
  );
};

export default ChangeIconPage;
