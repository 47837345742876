import { createLogic } from "redux-logic";
import * as types from "../actions/types";
import {
  set_loading,
  remove_loading,
  set_result,
  // set_result_data
} from "../actions/state";
import { requestPostAxios, requestAxios } from "../../API/request";
import {
  set_payment,
  set_subscript,
  get_invoice,
  set_proof,
  get_history,
  check_bonus,
} from "../actions/transaction";
import url from "../../API/url";

export const subsLogic = createLogic({
  type: types.SET_SUBSCRIPT_PROPS,
  latest: true,
  async process({ action }, dispatch, done) {
    dispatch(set_loading("subscript"));
    await requestPostAxios(url.TXN.subscript, action.payload)
      .then((e) => {
        dispatch(set_subscript(e.data));
      })
      .catch((err) => {
        console.log(err);
        let error = {
          name: "subscript",
          data: { type: "error", msg: err.message },
        };
        dispatch(set_result(error));
      })
      .then(() => {
        dispatch(remove_loading("subscript"));
        done();
      });
  },
});

export const checkBonus = createLogic({
  type: types.CHECK_BONUS_PROPS,
  latest: true,
  process({ action }, dispatch, done) {
    const name = "check_bonus";
    dispatch(set_loading(name));
    const data = {
      "product_code[]": action.payload,
    };
    requestAxios(url.TXN.tier_subs, data)
      .then((e) => {
        console.log(e);
        let tmp = {};
        if (Array.isArray(e.data)) {
          action.payload.forEach((e) => {
            tmp = { ...tmp, [e]: [] };
          });
          dispatch(check_bonus(tmp));
        } else {
          action.payload.forEach((el) => {
            if (e.data.hasOwnProperty(el)) {
              tmp = {
                ...tmp,
                [el]: e.data[el].sort((a, b) => b.min_sub - a.min_sub),
              };
            } else {
              tmp = { ...tmp, [el]: [] };
            }
          });
          dispatch(check_bonus(tmp));
        }
      })
      .catch((err) => {
        let error = {
          name: name,
          data: { type: "error", msg: err.message },
        };
        dispatch(set_result(error));
      })
      .then(() => {
        dispatch(remove_loading(name));
        done();
      });
  },
});

export const setPayment = createLogic({
  type: types.SET_PAYMENT_PROPS,
  latest: true,
  process({ action }, dispatch, done) {
    dispatch(set_loading("set_payment"));
    let data = {
      "product_code[]": [],
    };
    action.payload.forEach((e) => {
      data = { ...data, "product_code[]": [...data["product_code[]"], e.code] };
    });
    requestAxios(url.TXN.available_payment, data)
      .then((resp) => {
        console.log(resp);
        const { data } = resp;
        let result = {};
        Object.keys(data).forEach((el) => {
          if (Array.isArray(action.payload)) {
            action.payload.forEach((el2) => {
              if (el2.code === el) {
                data[el] = { ...data[el], code: el2.code };
                result = { ...result, [el2.display_name]: data[el] };
              }
            });
          } else {
            data[el] = { ...data[el], code: action.payload.code };
            result = { ...result, [action.payload.display_name]: data[el] };
          }
        });
        dispatch(set_payment(result));
      })
      .catch((err) => {
        let error = {
          name: "set_payment",
          data: { type: "error", msg: err.message },
        };
        dispatch(set_result(error));
      })
      .then(() => {
        dispatch(remove_loading("set_payment"));
        done();
      });
  },
});

export const getInvoice = createLogic({
  type: types.GET_INVOICE_PROPS,
  latest: true,
  async process({ action }, dispatch, done) {
    dispatch(set_loading("get_invoice"));
    let data = {
      id: action.payload,
    };
    await requestAxios(url.TXN.history, data)
      .then((e) => {
        console.log(e);
        dispatch(get_invoice(e.data));
      })
      .catch((err) => {
        let error = {
          name: "get_invoice",
          data: { type: "error", msg: err.message },
        };
        dispatch(set_result(error));
      })
      .then(() => {
        dispatch(remove_loading("get_invoice"));
        done();
      });
  },
});

export const setProof = createLogic({
  type: types.SET_PROOF_PROPS,
  latest: true,
  async process({ action }, dispatch, done) {
    dispatch(set_loading("set_proof"));
    console.log(action.payload);
    await requestPostAxios(url.TXN.confirm_payment, action.payload)
      .then((e) => {
        console.log(e);
        dispatch(set_proof(e));
      })
      .catch((err) => {
        let error = {
          name: "set_proof",
          data: { type: "error", msg: err.message },
        };
        dispatch(set_result(error));
      })
      .then(() => {
        dispatch(remove_loading("set_proof"));
        done();
      });
  },
});

export const getHistory = createLogic({
  type: types.GET_HISTORY_PROPS,
  latest: true,
  async process({ action }, dispatch, done) {
    dispatch(set_loading("get_history"));
    await requestAxios(url.TXN.history, { page: 1, per_page: 300 })
      .then((e) => {
        console.log(e);
        let tab = {
          history: { PROCESSED: [], APPROVED: [], DECLINED: [] },
          pending: { WAITING: [] },
        };
        // let tab = { history: null, pending: null };
        const { data } = e.data;
        // Object.keys(data).forEach(e => {
        //   if (data[e].txn_status_code === "WAITING") {
        //     tab = {
        //       ...tab,
        //       pending: {
        //         ...tab.pending,
        //         [data[e].txn_status_code]: []
        //       }
        //     };
        //   } else {
        //     tab = {
        //       ...tab,
        //       history: {
        //         ...tab.history,
        //         [data[e].txn_status_code]: []
        //       }
        //     };
        //   }
        // });
        Object.keys(data).forEach((e) => {
          if (data[e].txn_status_code === "WAITING") {
            tab = {
              ...tab,
              pending: {
                ...tab.pending,
                [data[e].txn_status_code]: [
                  ...tab.pending[data[e].txn_status_code],
                  data[e],
                ],
              },
            };
          } else {
            tab = {
              ...tab,
              history: {
                ...tab.history,
                [data[e].txn_status_code]: [
                  ...tab.history[data[e].txn_status_code],
                  data[e],
                ],
              },
            };
          }
        });
        Object.keys(tab).forEach((e1) => {
          Object.keys(tab[e1]).forEach((e2) => {
            tab[e1][e2] = tab[e1][e2].sort(
              (a, b) => new Date(b.traded_at) - new Date(a.traded_at)
            );
          });
        });
        dispatch(get_history(tab));
      })
      .catch((err) => {
        let error = {
          name: "get_history",
          data: { type: "error", msg: err.message },
        };
        dispatch(set_result(error));
      })
      .then(() => {
        dispatch(remove_loading("get_history"));
        done();
      });
  },
});

export const redeemLogic = createLogic({
  type: types.SET_REDEEM_PROPS,
  latest: true,
  async process({ action }, dispatch, done) {
    let processName = "validate_redeem";
    if (action.payload.hasOwnProperty("pin")) {
      processName = "redeem";
    }
    dispatch(set_loading(processName));
    requestPostAxios(url.TXN.redeem, action.payload)
      .then((e) => {
        const success = {
          name: processName,
          data: { type: "success", msg: e.message, data: e.data },
        };
        dispatch(set_result(success));
      })
      .catch((err) => {
        console.log(err);
        const error = {
          name: processName,
          data: { type: "error", msg: err.message },
        };
        dispatch(set_result(error));
      })
      .then(() => {
        dispatch(remove_loading(processName));
        done();
      });
    // let validate = false;
    // await requestPostAxios(url.TXN.redeem, {
    //   is_validating: 1,
    //   ...action.payload
    // })
    //   .then(e => {
    //     validate = true;
    //     console.log(e);
    //   })
    //   .catch(err => {
    //     console.log(err);
    //     const error = {
    //       name: "redeem",
    //       data: { type: "error", msg: err.message }
    //     };
    //     validate = false;
    //     dispatch(set_result(error));
    //     dispatch(remove_loading("redeem"));
    //     done();
    //   });
    // if (validate) {
    //   console.log("tes");
    //   requestPostAxios(url.TXN.redeem, action.payload)
    //     .then(e => {
    //       const success = {
    //         name: "redeem",
    //         data: { type: "success", msg: e.message, data: e.data }
    //       };
    //       dispatch(set_result(success));
    //     })
    //     .catch(err => {
    //       console.log(err);
    //       const error = {
    //         name: "redeem",
    //         data: { type: "error", msg: err.message }
    //       };
    //       dispatch(set_result(error));
    //     })
    //     .then(() => {
    //       dispatch(remove_loading("redeem"));
    //       done();
    //     });
    // }
  },
});

export const switchLogic = createLogic({
  type: types.SET_SWITCH_PROPS,
  latest: true,
  async process({ action }, dispatch, done) {
    let processName = "validate_switch";
    if (action.payload.hasOwnProperty("pin")) {
      processName = "switch";
    }
    dispatch(set_loading(processName));
    requestPostAxios(url.TXN.switch, action.payload)
      .then((e) => {
        console.log(e);
        const success = {
          name: processName,
          data: { type: "success", msg: e.message, data: e.data },
        };
        dispatch(set_result(success));
      })
      .catch((err) => {
        console.log(err);
        const error = {
          name: processName,
          data: { type: "error", msg: err.message },
        };
        dispatch(set_result(error));
      })
      .then(() => {
        dispatch(remove_loading(processName));
        done();
      });
  },
});
