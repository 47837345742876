import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/styles/makeStyles";
import BasicText from "../BasicText/BasicText";
import ButtonBase from "@material-ui/core/ButtonBase";
import classNames from "classnames";
const ButtonIcon = (props) => {
  const { icon, alt, text, onClick, screen, widthMobile, params, id, state } =
    props;
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: widthMobile,
      [theme.breakpoints.up(769)]: {
        flexDirection: "row",
        width: 175,
      },
    },
    text: {
      [theme.breakpoints.up(769)]: {
        textAlign: "left",
      },
    },
    icon: {
      height: "45px",
      marginBottom: "5px",
      [theme.breakpoints.up(768)]: {
        marginRight: 10,
      },
    },
  }));
  const classes = useStyles();
  const handleClick = () => {
    onClick(screen, text, params, state);
  };
  return (
    <ButtonBase
      id={id}
      className="d-flex flex-column align-items-center justify-content-between mx-auto mx-md-0"
      onClick={handleClick}
    >
      <div className={classes.root}>
        <img src={icon} alt={alt} className={classes.icon} />
        <BasicText className={classNames(classes.text, "fs-12")}>
          {text}
        </BasicText>
      </div>
    </ButtonBase>
  );
};

ButtonIcon.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  widthMobile: PropTypes.number,
};

ButtonIcon.defaultProps = {
  onClick: () => null,
  widthMobile: 65,
  alt: "",
};

export default ButtonIcon;
