import * as types from "../actions/types";

const initial = {
  available_payment: null,
  subscript_result: null,
  invoice: null,
  proof_result: null,
  history: null,
  bonus_sub: {},
  order_data: {},
  confirm_payment_data: {},
};

const initial_backup = { ...initial };

const transactionReducer = (state = initial, action) => {
  switch (action.type) {
    case types.SET_TXN_RD:
      return { ...state, ...action.payload };
    case types.SET_PAYMENT:
      return { ...state, available_payment: action.payload };
    case types.SET_SUBSCRIPT:
      return { ...state, subscript_result: action.payload };
    case types.GET_INVOICE:
      return { ...state, invoice: action.payload };
    case types.SET_PROOF:
      return { ...state, proof_result: action.payload };
    case types.GET_HISTORY:
      return { ...state, history: action.payload };
    case types.CHECK_BONUS:
      return { ...state, bonus_sub: action.payload };
    case types.ORDER_DATA:
      return { ...state, order_data: action.payload };
    case types.CONFIRM_PAYMENT_DATA:
      return { ...state, confirm_payment_data: action.payload };
    case types.LOG_OUT:
      return { ...state, ...initial_backup };
    default:
      return state;
  }
};
export default transactionReducer;
