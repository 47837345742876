import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import processHelper from "../../../helper/processHelper";
import { makeStyles } from "@material-ui/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";

const ContainerWithLoader = (props) => {
  const { processName, children, loadingHeight, component, loading } = props;
  const useStyles = makeStyles({
    loadingContainer: {
      height: loadingHeight,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  });
  const classes = useStyles();
  const loading_stack = useSelector(
    (state) => state.stateReducer.loading_stack
  );
  if (processHelper.findProcess(processName, loading_stack) || loading) {
    return (
      <Box component={component} className={classes.loadingContainer}>
        <CircularProgress />
      </Box>
    );
  } else {
    return <>{children}</>;
  }
};

ContainerWithLoader.propTypes = {
  processName: PropTypes.any,
  loadingHeight: PropTypes.string,
  component: PropTypes.string,
};

ContainerWithLoader.defaultProps = {
  loadingHeight: "100vh",
  component: "div",
};

export default ContainerWithLoader;
