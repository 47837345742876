import DateFnsUtils from "@date-io/date-fns";
import { Grid, makeStyles, withStyles } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import classNames from "classnames";
import debounce from "lodash/debounce";
import React, { PureComponent } from "react";
import { useSelector } from "react-redux";
import MediaQuery, { useMediaQuery } from "react-responsive";
import color from "../../../assets/theme/color";
import { getYearLater, simulate } from "../../../helper/calculateSimulasi";
import processHelper from "../../../helper/processHelper";
import textHelper from "../../../helper/textHelper";
import BasicText from "../../atom/BasicText/BasicText";
import ButtonContained from "../../atom/Button/ButtonContained";
import ContainerContentLevel1 from "../../molecule/Container/ContainerContentLevel1";
import ContainerLevel1 from "../../molecule/Container/ContainerLevel1";
import DialogSimulasi from "../../molecule/Dialog/DialogSimulasi";
import SliderWithInput from "../../molecule/MaterialInput/SliderWithInput";
import TextInput from "../../molecule/MaterialInput/TextInput";
import GraphAlocationProduct from "../../molecule/SmartPlan/GraphAlocationProduct";
import RowResultInput from "../../molecule/SmartPlan/RowResultInput";
import MenuGroupGoals from "./MenuGroupGoals";

const useStylesClass = () => ({
  root: {},
  inputNamePorto: {
    "& .MuiInputBase-input": {
      padding: "10px !important",
      fontSize: 14,
    },
  },
});

const useStyles = makeStyles({
  datePicker: {
    border: `1px solid ${color.gray_6}`,
    borderRadius: 3,
    paddingLeft: 10,
    marginRight: "30%",
    "& input": {
      color: color.secondary,
      fontSize: 14,
      marginRight: -10,
      padding: "9px 0",
      fontFamily: "Montserrat",
    },
    "& .MuiInput-underline:before, .MuiInput-underline:after": {
      borderBottom: "0 !important",
      transition: "unset",
    },
    "& .MuiInput-underline.Mui-focused:after": {
      transform: "scaleX(0)",
    },
    "& button": {
      padding: 8,
      marginRight: -6,
    },
  },
  buttonSubmit: {
    padding: "13px 23px",
    alignSelf: "center",
    borderRadius: 6,
  },
});

const RowTimePlanner = ({ title, value, onChange, className }) => {
  const S = useStyles();
  return (
    <Grid container alignItems="center" className={className}>
      <Grid xs={6} item>
        <BasicText className="fs-12 color-secondary text-capitalize">
          {title}
        </BasicText>
      </Grid>
      <Grid xs={6} item>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            id="date-input"
            className={S.datePicker}
            openTo="year"
            views={["year", "month"]}
            format="MM/yyyy"
            value={value}
            onChange={onChange}
            minDate={new Date()}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
  );
};

const ContentSimulasi = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 769 });
  return <div className={isDesktop ? "" : "px-3 py-2"}>{children}</div>;
};

const ButtonInvest = ({ onClick }) => {
  const S = useStyles();
  const isDesktop = useMediaQuery({ minWidth: 769 });
  const loading = useSelector((state) =>
    processHelper.findProcess(
      "update-planner",
      state.stateReducer.loading_stack
    )
  );
  return (
    <ButtonContained
      id="inves-btn"
      loading={loading}
      text="INVESTASI SEKARANG"
      className={classNames(S.buttonSubmit, isDesktop ? " mt-5" : " mt-2")}
      radius="6px"
      onClick={onClick}
    />
  );
};

class SimulasiGoalKamu extends PureComponent {
  constructor(props) {
    super(props);
    const { id_tab, ...other } = props.locationState || {};
    this.state = this.getValueGoals(this.getIndexInitial(), other);
    this.debounceCalc = debounce(this._calcInvest, 300);
  }

  getIndexInitial = () => {
    const { goals, locationState: { id_tab } = {} } = this.props;
    return Math.max(
      goals?.findIndex(
        ({ goal_id }) => Number(goal_id) === Number(id_tab) ?? 0
      ),
      0
    );
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { goals, ekspekReturn, getRiskGoals } = this.props;
    if (this.state !== prevState) {
      getRiskGoals(this.state.profile_risk_point);
    }
    if (goals !== prevProps.goals && goals.length) {
      const { id_tab, ...other } = this.props.locationState || {};
      this._handleChangeSelected(this.getIndexInitial(), other);
    } else if (ekspekReturn && ekspekReturn !== prevProps.ekspekReturn)
      this._calcInvest();
  };

  getValueGoals = (selectedMenu, newParams) => {
    const {
      name,
      first_amount,
      target_amount,
      monthly_amount,
      target_date,
      profile_risk_point,
    } = this.props.goals[selectedMenu] || {};
    return {
      selectedMenu,
      profile_risk_point,
      name: name || "",
      investAwal: first_amount || 100000,
      biayaTotal: target_amount || 1000000,
      investasiBulanan: monthly_amount || 0,
      monthSelect: target_date
        ? textHelper.getDateGoals(target_date)
        : getYearLater(),
      ...newParams,
    };
  };

  _handleChangeSelected = (selectedMenu, newParams) => {
    this.setState(this.getValueGoals(selectedMenu, newParams));
  };

  _handleChangeMonth = (res) => {
    const monthSelect = textHelper.getDateGoals(res);
    this.setState({ monthSelect }, this._calcInvest);
  };

  handleChangeInput = (obj) => {
    this.setState(obj);
    this.debounceCalc();
  };

  _calcInvest = () => {
    const { selectedMenu, monthSelect, investAwal, biayaTotal } = this.state;
    const { goals, ekspekReturn } = this.props;
    let obj = {
      biayaTotal: biayaTotal,
      goalsInflasi: goals[selectedMenu],
      ekspekReturn,
      dateFinish: monthSelect,
      investAwal: investAwal,
    };
    let investasiBulanan = simulate(obj);
    this.setState({ investasiBulanan: textHelper.roundUp(investasiBulanan) });
  };

  _handleInvest = () => {
    const {
      selectedMenu,
      investAwal,
      investasiBulanan,
      biayaTotal,
      monthSelect,
      name,
    } = this.state;
    const { goals, handleSubmit } = this.props;
    const { id, ref_goals_icon_id } = goals[selectedMenu];
    const params = {
      id,
      name,
      is_follow_robo: 1,
      first_amount: investAwal,
      monthly_amount: investasiBulanan,
      target_amount: biayaTotal,
      ref_goals_icon_id,
      target_date: monthSelect
        .split("/")
        .filter((_, index) => index !== 1)
        .join("/"),
    };

    handleSubmit(
      params,
      goals[selectedMenu].id,
      Number(investAwal) + Number(investasiBulanan)
    );
  };

  render() {
    const {
      selectedMenu,
      biayaTotal,
      investAwal,
      monthSelect,
      investasiBulanan,
      name,
    } = this.state;
    const { goals, data, classes } = this.props;
    const { ref_goals_icon } = goals[selectedMenu] || {};
    const nameGoals = ref_goals_icon?.name?.toLowerCase() ?? "";

    return (
      <ContainerLevel1
        DesktopClass={classNames("pb-5 br-8", classes.root, "shadow-card")}
      >
        <MenuGroupGoals
          data={goals}
          onChange={this._handleChangeSelected}
          selected={selectedMenu}
        />
        <ContentSimulasi>
          <ContainerContentLevel1
            title={nameGoals}
            titleClass="text-capitalize fs-14 fw-700 gray-3"
            titleOnTopCardMobile
            className="mb-3 pb-1"
          >
            <Grid container alignItems="center" className="mb-3">
              <Grid xs={6} item>
                <p className="basic fs-12">Nama Portofolio:</p>
              </Grid>
              <Grid xs={6} item>
                <TextInput
                  id="portoName-input"
                  variant="outlined"
                  value={name}
                  onChange={(name) => this.setState({ name })}
                  inputClass={classNames(
                    "basic fs-14 gray-3",
                    classes.inputNamePorto
                  )}
                />
              </Grid>
            </Grid>
            <SliderWithInput
              id="target"
              adormentContent={() => <span className="fs-14 gray-3">Rp</span>}
              adormentPlace="start"
              text="Perkiraan Keperluan Biaya:"
              color={color.gray_3}
              minValue={100000}
              maxValue={300000000}
              stepSlider={1000}
              unlimited
              value={biayaTotal}
              sliderLabelClass="fs-12"
              onChange={(biayaTotal) => this.handleChangeInput({ biayaTotal })}
            />
            <RowTimePlanner
              title={`Target Waktu Pencapaian:`}
              value={monthSelect}
              onChange={this._handleChangeMonth}
            />
          </ContainerContentLevel1>
          <MediaQuery maxWidth={768}>
            <GraphAlocationProduct {...data} force />
          </MediaQuery>
          <ContainerContentLevel1 containClass="mt-2">
            <SliderWithInput
              id="setor"
              adormentContent={() => <span className="fs-14 gray-3">Rp</span>}
              adormentPlace="start"
              text="Setoran Awal:"
              color={color.gray_3}
              minValue={100000}
              maxValue={50000000}
              unlimited
              stepSlider={1000}
              value={investAwal}
              sliderLabelClass="fs-12"
              onChange={(investAwal) => this.handleChangeInput({ investAwal })}
            />
            <RowResultInput
              title="Investasi Bulanan:"
              value={investasiBulanan}
              textInputClass="textInputResultGoalKamu"
              inputClass="font-weight-semibold color-white rounded-1"
            />
          </ContainerContentLevel1>
        </ContentSimulasi>
        <ButtonInvest onClick={this._handleInvest} />
        <DialogSimulasi />
      </ContainerLevel1>
    );
  }
}

SimulasiGoalKamu.defaultProps = {
  goals: [],
  handleInvest: () => {},
  data: {},
  ekspekReturn: 9.4,
};

export default withStyles(useStylesClass)(SimulasiGoalKamu);
