import React from "react";
import PropTypes from "prop-types";
import ilustrate from "../../../assets/img/ilustration";
import BasicText from "../../atom/BasicText/BasicText";
import MediaQuery from "../../organism/MediaQuery/MediaQuery";

function LoginIlustrate(props) {
  const { title } = props;
  return (
    <div>
      <MediaQuery device="mobile">
        <div className="mb-4">
          <div className="w-100 text-center">
            <img src={ilustrate.login} alt="" className="mb-2 w-100 h-auto" />
          </div>
          <BasicText className="font-weight-bold fs-16" color="primary">
            {title}
          </BasicText>
        </div>
      </MediaQuery>
      <MediaQuery device="desktop">
        <img src={ilustrate.login} alt="" className="w-100 h-auto" />
      </MediaQuery>
    </div>
  );
}

LoginIlustrate.propTypes = {
  title: PropTypes.string
};
LoginIlustrate.defaultProp = {
  title: ""
};

export default LoginIlustrate;
