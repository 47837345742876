import React from "react";
import Info from "@material-ui/icons/Info";
import classNames from "classnames";
import Popover from "@material-ui/core/Popover";
import BasicText from "../BasicText/BasicText";
import "./styles.css";
const { useState, useCallback } = React;

const PopOver = ({ className, children, title, size, style }) => {
  const [open, setopen] = useState(null);
  const handleOpen = useCallback((e) => setopen(e?.currentTarget ?? null), []);
  return (
    <>
      <Info
        id={`info${title && `-${title.replace(/ /g,"-").toLowerCase()}`}`}
        style={style}
        className={classNames("anchor-popup is-link", `fs-${size}`, className)}
        fontSize="small"
        onClick={handleOpen}
      />
      <Popover
        open={Boolean(open)}
        onClose={() => handleOpen(undefined)}
        anchorEl={open}
        PaperProps={{ className: "container-popup" }}
      >
        {title && (
          <BasicText className="fs-10 font-weight-bold mb-2">{title}</BasicText>
        )}
        {children && <BasicText className="fs-10">{children}</BasicText>}
      </Popover>
    </>
  );
};

PopOver.defaultProps = {
  size: 16,
  title : ""
};

export default PopOver;
