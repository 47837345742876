import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
    container_graph: {
        padding: '17px 55px 20px 16px',
        borderRadius: 6
    },
    chart: {
        height: 145,
        marginBottom: 17,
        marginTop: 14,
        display: "flex",
        justifyContent: "center",
    },
    rowDetail: {
        "& .detail-name": {
            fontSize: 10,
            marginLeft: 8,
            fontWeight: "600",
        },
        "& .detail-allocation": {
            fontWeight: 'bold',
            fontSize: 14,
            color: theme.palette.primary.main
        }
    }
}))