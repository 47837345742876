import Card from "@material-ui/core/Card";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Switch from "@material-ui/core/Switch";
import React from "react";
import textHelper from "../../../helper/textHelper";
import CalenderPeriodic from "./CalenderPeriodic";
import FieldInputPeriodic from "./FieldInputPeriodic";
import useStyles from "./useStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import classNames from "classnames";
const { useCallback, useState, useMemo } = React;

// const validation = object().shape({
//   is_active: number().required(),
//   invest_cycle: string().required(),
//   make_payment_on: string().required(),
//   is_continous: string().required(),
//   is_auto_debet: string().required(),
// });

const base_continous = [
  { value: 1, label: "Tanpa Batas Waktu" },
  { value: 2, label: "Pilih Batas Waktu" },
];

const CardInvestBerkala = ({
  initialActive,
  onSubmit,
  statePeriodic,
  loading,
}) => {
  const classes = useStyles();
  const { show_periodic, can_autodebet, autodebet_month } = statePeriodic;
  const [stateAutoDebet, setStateAutoDebet] = useState(false);
  const [state, setState] = useState({
    is_active: initialActive ? 1 : 0,
    invest_cycle: "monthly",
    make_payment_on: 1,
    is_continous: 1,
    is_auto_debet: "",
  });
  const [calScreen, setCalScreen] = useState(false);
  const [end_date, setdate] = useState(
    textHelper.printDateFormat(new Date(), "MM/DD/YYYY")
  );
  const base = useMemo(
    () => [
      {
        label: "Siklus",
        name: "invest_cycle",
        config: [
          !stateAutoDebet && { value: "daily", label: "Harian" },
          !stateAutoDebet && { value: "weekly", label: "Mingguan" },
          { value: "monthly", label: "Bulanan" },
        ].filter((val) => val),
      },
      {
        label: "Tanggal",
        name: "make_payment_on",
        param: "invest_cycle",
        config: {
          daily: [
            // { value: 1, label: "Setiap Hari", otherProps: { disabled: true } },
            { value: 1, label: "Setiap Hari" },
          ],
          weekly: [
            { value: 1, label: "Minggu" },
            { value: 2, label: "Senin" },
            { value: 3, label: "Selasa" },
            { value: 4, label: "Rabu" },
            { value: 5, label: "Kamis" },
            { value: 6, label: "Jum'at" },
            { value: 7, label: "Sabtu" },
          ],
          monthly: stateAutoDebet
            ? autodebet_month.map((num) => ({ value: num, label: num }))
            : [
                { value: 1, label: "1" },
                { value: 2, label: "2" },
                { value: 3, label: "3" },
                { value: 4, label: "4" },
                { value: 5, label: "5" },
                { value: 6, label: "6" },
                { value: 7, label: "7" },
                { value: 8, label: "8" },
                { value: 9, label: "9" },
                { value: 10, label: "10" },
                { value: 11, label: "11" },
                { value: 12, label: "12" },
                { value: 13, label: "13" },
                { value: 14, label: "14" },
                { value: 15, label: "15" },
                { value: 16, label: "16" },
                { value: 17, label: "17" },
                { value: 18, label: "18" },
                { value: 19, label: "19" },
                { value: 20, label: "20" },
                { value: 21, label: "21" },
                { value: 22, label: "22" },
                { value: 23, label: "23" },
                { value: 24, label: "24" },
                { value: 25, label: "25" },
                { value: 26, label: "26" },
                { value: 27, label: "27" },
                { value: 28, label: "28" },
                { value: 29, label: "29" },
                { value: 30, label: "30" },
                { value: 31, label: "31" },
              ],
        },
      },
      {
        label: "Masa Berlaku",
        name: "is_continous",
        config: [
          ...base_continous,
          (!state.is_continous && { value: 0, label: end_date }) || null,
        ].filter((val) => val),
      },
    ],
    [autodebet_month, end_date, state.is_continous, stateAutoDebet]
  );

  const handleChecked = (event) => {
    setState((state) => ({
      ...state,
      is_active: event.target.checked ? 1 : 0,
    }));
  };

  const handleCal = useCallback(() => setCalScreen((prev) => !prev), []);

  const handleSelect = (event, name) => {
    const { value } = event.target;
    // jika flow yang lama ganti 0 jadi 2
    if (name === "is_continous" && value === 2) {
      handleCal();
    } else {
      setState((prev) => {
        const state = { ...prev };
        if (name === "invest_cycle") state.make_payment_on = 1;
        if (name === "is_continous" && value === 1) delete state.end_date;
        return { ...state, [name]: value, is_auto_debet: "" };
      });
    }
  };

  const handleSubmitCal = useCallback(
    (end_date) => {
      setdate(end_date);
      setState((prev) => ({ ...prev, is_continous: 0, is_auto_debet: "" }));
      handleCal();
    },
    [handleCal]
  );

  const handleSubmit = useCallback(
    (params) => {
      if (!params.is_continous)
        params.end_date = textHelper.printDateWithStrip(end_date);
      // if (params.invest_cycle === "daily") delete params.make_payment_on;
      onSubmit(params);
    },
    [end_date, onSubmit]
  );

  const handleSend = (event) => {
    const is_auto_debet = event.target.value;
    const newStateAutoDebet = Boolean(Number(event.target.value));
    let params = { ...state, is_auto_debet };
    setStateAutoDebet(newStateAutoDebet);
    const isNewChangeToDebet = !stateAutoDebet && newStateAutoDebet;
    if (isNewChangeToDebet) {
      params.make_payment_on = "";
      params.invest_cycle = "monthly";
    }
    setState(params);
    if (!isNewChangeToDebet) {
      handleSubmit(params);
    }
  };

  // useEffect(() => {
  //   _isFieldValid(validation, state).then((res) => res && handleSubmit(state));
  // }, [handleSubmit, state]);

  if (!show_periodic) {
    return null;
  }
  return (
    <Card className={classNames(classes.root, "position-relative shadow-card")}>
      {loading && (
        <div className={classNames(classes.contentLoading, "cont-center")}>
          <CircularProgress />
        </div>
      )}
      {calScreen ? (
        <CalenderPeriodic
          initial={end_date}
          onClose={handleCal}
          onSubmit={handleSubmitCal}
        />
      ) : (
        <>
          <div className="d-flex align-items-center header-form">
            <Switch
              checked={Boolean(state.is_active)}
              onChange={handleChecked}
              color="primary"
              name="checkedB"
              inputProps={{ "aria-label": "primary checkbox" }}
              className="mr-2"
            />
            <p className="basic fs-12 font-weight-bold gray-4">
              Investasi Berkala Untuk Pembelian Ini
            </p>
          </div>
          {state.is_active ? (
            <div className={classes.contentForm}>
              {base.map((el, id) => {
                let data = el.config;
                if (!Array.isArray(data)) data = el.config[state[el.param]];
                return (
                  <FieldInputPeriodic
                    key={el.name}
                    name={el.name}
                    label={el.label}
                    value={state[el.name]}
                    data={data}
                    onClick={handleSelect}
                  />
                );
              })}
              {/* {!state.is_continous && (
                <FieldInputPeriodic
                  name="end_date"
                  label="Batas Waktu"
                  value={0}
                  data={[{ value: 0, label: end_date }]}
                  onOpen={handleCal}
                />
              )} */}

              <RadioGroup
                aria-label="type"
                name="type"
                value={state.is_auto_debet}
                onChange={handleSend}
              >
                <div className="d-flex mt-3">
                  <FormControlLabel
                    value="0"
                    control={<Radio color="primary" />}
                    label="Manual"
                    classes={{ label: "basic fs-12" }}
                  />
                  <FormControlLabel
                    value="1"
                    disabled={!can_autodebet}
                    control={<Radio color="primary" />}
                    label="Auto Debet"
                    className="mx-auto"
                    classes={{ label: "basic fs-12" }}
                  />
                </div>
              </RadioGroup>

              {/* 
              //DEPRECATED
              {state.is_continous === 2 && (
                <div className="p-3">
                  <BasicText>Akhir Periode Pembelian</BasicText>
                  <InputTimePicker
                    style={{ width: 300 }}
                    inputVariant="standard"
                    format="dd MMMM yyyy"
                    onChange={setdate}
                    value={end_date}
                  />
                </div>
              )} */}
            </div>
          ) : null}
        </>
      )}
    </Card>
  );
};

CardInvestBerkala.defaultProps = {
  initialActive: false,
  onSubmit: () => {},
  statePeriodic: {},
};

export default CardInvestBerkala;
