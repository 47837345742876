import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/styles/makeStyles";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles(theme => ({
  root: {
    display: "grid",
    gridTemplateColumns: "64% 1% 35%",
    [theme.breakpoints.down(1025)]: {
      gridTemplateColumns: "100%"
    }
  }
}));

const ContainerDesktopCard = props => {
  const classes = useStyles();
  const { left, right, bottom } = props;
  return (
    <div className="margin-main pt-4 px-4">
      <div className="card-custom p-0">
        <div className={classes.root}>
          {left}
          <div className="d-flex justify-content-center">
            <Divider orientation="vertical" />
          </div>
          {right}
        </div>
        {bottom}
      </div>
    </div>
  );
};

ContainerDesktopCard.propTypes = {
  left: PropTypes.any,
  right: PropTypes.any,
  bottom: PropTypes.any
};

export default ContainerDesktopCard;
