/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { ic_mail, ic_wa } from "../../../assets/icons";
import ilustration from "../../../assets/img/ilustration";
import TempDialogFull from "../../template/TempDialogFull";
import classNames from "classnames";
const { memo, useCallback } = React;

const selection = [
  { key: "wa", title: "Whatsapp", icon: ic_wa },
  { key: "sms", title: "SMS", icon: ic_mail },
];

const DialogOTPSelection = ({ number, onClickItem, modal, onClose }) => {
  const classes = useStyles();

  const onSelect = useCallback((key) => {
    onClickItem(key);
    onClose();
  }, []);

  const _renderSelection = useCallback(
    () =>
      selection.map(({ key, title, icon }, index) => (
        <div
          onClick={() => onSelect(key)}
          id={`option-${key}`}
          key={key}
          className={classNames(
            classes.contentSelection,
            "is-link",
            index || "mt-0"
          )}
        >
          <img src={icon} alt="" />
          <p className="basic fs-14 ml-1">
            Melalui <b>{title}</b> {number && `ke ${number}`}
          </p>
        </div>
      )),

    [number]
  );

  return (
    <TempDialogFull modal={modal} onClose={onClose} withClose>
      <div className={classes.container}>
        <img src={ilustration["otp"]} alt="" />
        <p className="basic fs-14 color-gray-4 my-4 py-2 text-center">
          Pilih metode verifikasi dibawah ini untuk mendapatkan kode OTP
        </p>
        {_renderSelection()}
      </div>
    </TempDialogFull>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    placeItems: "center",
    margin: "58px 75px",
    maxWidth: 342,
  },
  contentSelection: {
    border: `1px solid ${theme.palette.secondary[6]}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 316,
    height: 56,
    width: "100%",
    borderRadius: 4,
    marginTop: 12,
  },
}));

const memoize = (prev, next) => prev.modal === next.modal;

DialogOTPSelection.defaultProps = {
  onClickItem: () => { },
  number: "",
};

export default memo(DialogOTPSelection, memoize);
