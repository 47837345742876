import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import stateCallApi from "../../../../config/API/stateCallApi";
import { set_auth } from "../../../../config/redux/actions/auth";
import { gbl_act_lc } from "../../../../config/redux/actions/state";
import processHelper from "../../../../helper/processHelper";
import GenerateHorizontal from "../../../template/generate/GenerateHorizontal";
import DialogCustom from "../../Container/DialogCustom";
import CardCollection from "./cards/CardCollection";
const { Suspense, lazy } = React;
const PopOver = lazy(() => import("../../../atom/PopOver/PopOver"));

const DashboardList = ({
  title,
  info,
  data,
  onClick,
  loading,
  rowId,
  ...other
}) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [dataBanner, setdataBanner] = useState([]);
  const [counter, setcounter] = useState(0);
  const loading_state = useSelector(({ stateReducer }) =>
    processHelper.findProcess(loading, stateReducer.loading_stack)
  );
  const modalBanner = useSelector(
    ({ authReducer }) => authReducer.modalBannerOnce
  );
  const data_list = useMemo(
    () =>
      data?.map((item, index) => ({
        ...item,
        key: `${rowId}-${item.id || index}`,
      })),
    [data, rowId]
  );

  const handleBanner = () => {
    const routeBanner = {
      goals_planner: "/goals-planner",
      risk_profile: "/profil-risiko",
      // profile_risk_result: "",
      dashboard: "/",
      product_list: "/produk",
      product_detail: "/detail-produk?product=",
      txn_list: "/transaksi",
      txn_detail: "/transaksi/detail?id=",
      profile: "/profil/data-profil",
      coming_soon: "/coming-soon",
      smart_plan: "/smart-plan",
      article: "/article?id=",
    };
    const name = dataBanner[counter]?.app_screen?.name;
    switch (name) {
      case "goals_planner":
        const paramPlanner = {
          name: dataBanner[counter]?.param?.name,
          biayaTotal: dataBanner[counter]?.param?.perkiraan,
          id_tab: dataBanner[counter]?.param?.id,
        };
        push(routeBanner[name], paramPlanner);
        break;
      case "txn_detail":
        const paramTxn = dataBanner[counter]?.param?.id;
        push(routeBanner[name] + paramTxn);
        break;
      case "product_detail":
        const paramProduct = dataBanner[counter]?.param?.product_code;
        push(routeBanner[name] + paramProduct);
        break;
      case "market_insight":
        const urlMarket = dataBanner[counter]?.param?.url;
        window.open(urlMarket, "_blank");
        break;
      case "marketing_event":
        const urlMarketing = dataBanner[counter]?.param?.url;
        window.open(urlMarketing, "_blank");
        break;
      case "article":
        const paramArticle = dataBanner[counter]?.param?.blog_id;
        push(routeBanner[name] + paramArticle)
        break;
      default:
        if (routeBanner[name]) push(routeBanner[name]);
        else console.log("Error Banner: ", name);
        break;
    }
    dispatch(set_auth({ modalBannerOnce: false }));
  };

  useEffect(() => {
    dispatch(
      gbl_act_lc({
        ...stateCallApi.popup_banner,
        callback: (data) => {
          setdataBanner(data);
        },
      })
    );
  }, [dispatch]);

  const handleCloseBanner = () => {
    const isNextDataAvailable = dataBanner[counter + 1];
    if (isNextDataAvailable) setcounter(counter + 1);
    else dispatch(set_auth({ modalBannerOnce: false }));
  };

  const handleClick = useCallback(
    (id, appendId, newParams) => {
      onClick(
        data_list[data_list.findIndex(({ key }) => String(key) === String(id))],
        `${rowId}${appendId ? `-${appendId}` : ""}`,
        newParams
      );
    },
    [data_list, onClick, rowId]
  );
  if (!data.length) {
    return "";
  }
  const isModalOpenandDataAvailable = modalBanner && !!dataBanner.length;
  return (
    <div className="mb-3">
      <p className="basic fs-14 fw-700 d-inline">{title}</p>
      <Suspense fallback="">
        {info && (
          <PopOver title={title} className="ml-1">
            {info}
          </PopOver>
        )}
      </Suspense>
      <GenerateHorizontal
        className="mt-3 mx-n4"
        loading={loading_state}
        render={CardCollection}
        options={{
          itemProps: { render: rowId, onClickItem: handleClick },
          spacing: 4,
          key: "key",
          paddingStart: 24,
          paddingEnd: 24,
        }}
        data={data_list}
        onClickItem={handleClick}
        {...other}
      />
      <DialogCustom
        open={isModalOpenandDataAvailable}
        handleClose={handleCloseBanner}
        title=""
        className="p-0 rounded-0 color-transparent"
        dialogProps={{ style: { zIndex: 1200 }, fullScreen: true }}
        borderRadius={0}
        backgroundColor="transparent"
        textClass="cont-center h-100"
        text={
          <div style={{ position: "relative" }}>
            <IconButton
              style={{
                position: "absolute",
                right: 5,
                top: 5,
                backgroundColor: "black",
                outline: "white 2px solid",
              }}
              onClick={handleCloseBanner}
            >
              <CloseIcon className="color-white" />
            </IconButton>
            <img
              key={(dataBanner && dataBanner[counter]?.web_image_url) || "key"}
              src={dataBanner && dataBanner[counter]?.web_image_url}
              alt=""
              style={{ maxHeight: "98vh", maxWidth: "100vw" }}
              className="is-link"
              onClick={handleBanner}
            />
          </div>
        }
      />
    </div>
  );
};

DashboardList.defaultProps = {
  title: "",
  data: [],
  onClick: () => {},
};

export default DashboardList;
