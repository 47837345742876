import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import nav from "../../../assets/img/nav";
import BasicText from "../../atom/BasicText/BasicText";
import classNames from "classnames";
const useStyles = makeStyles(theme => ({
  contentButton: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #dee2e6!important",
    paddingBottom: "1rem",
    marginBottom: "1rem",
    [theme.breakpoints.up(768)]: {
      marginBottom: 31,
      paddingBottom: "0.5rem"
    }
  },
  chevronDisplay: {
    [theme.breakpoints.up(768)]: {
      display: "none"
    }
  },
  textLinkDisplay: {
    color: `${theme.palette.secondary.light} !important`,
    textDecoration: "underline",
    fontSize: "10px !important",
    [theme.breakpoints.down(769)]: {
      display: "none"
    }
  },
  linkUbah: {
    color: `${theme.palette.primary.lighter} !important`
  }
}));

const ButtonSetting = props => {
  const classes = useStyles();
  const { text, onClick, textLink } = props;
  return (
    <div id={`menu-${text.replace(/ /g,"-").toLowerCase()}`} className={classes.contentButton} onClick={() => onClick()}>
      <BasicText className="fs-14" color="secondary">
        {text}
      </BasicText>
      <img src={nav.chevronRight} alt="" className={classes.chevronDisplay} />
      <BasicText
        className={classNames(
          classes.textLinkDisplay,
          classes.linkUbah,
          "is-link"
        )}
      >
        {textLink}
      </BasicText>
    </div>
  );
};

ButtonSetting.propTypes = {
  textLink: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func
};

export default ButtonSetting;
