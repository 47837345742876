import React from "react";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ContainerWithLoader from "../../organism/Container/ContainerWithLoader";
import TempMain from "../../template/TempMain";
import parse from "html-react-parser";
import "./styles.css";
import BasicButton from "../../atom/Button/BasicButton";
import { memo } from "react";
import { useCallback } from "react";
import useSubsWithPromo from "../PromoPage/useSubsWithPromo";
import useDetailPromo from "./useDetailPromo";
import textHelper from "../../../helper/textHelper";

const PromoDetailPage = () => {
  const { push } = useHistory();
  const { state } = useLocation();
  const createState = useSubsWithPromo();
  const { data, getData, name } = useDetailPromo(state?.id);

  const subWithPromo = useCallback(
    () =>
      push(
        "/subscript",
        createState(state?.id, data?.product_code, data?.min_sub)
      ),
    [createState, data, push, state]
  );

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <TempMain
      title="PROFIL"
      backgroundColor="white"
      showBottom={false}
      bottomNavValue={4}
      page="profile"
      data={{ menuValue: 4 }}
    >
      <p className="fs-14 font-weight-bold color-primary mb-4 pb-1">
        DETAIL PROMO
      </p>
      <ContainerWithLoader processName={name} loadingHeight="100%">
        {!!data ? (
          <>
            <img
              className="banner"
              src={data.image_url}
              alt={`banners-${data.code}`}
            />
            <div className="color-secondary">
              <h3 className="mb-2">LEBARAN BERSAMA SBOF</h3>
              <div className="justify-content-between d-flex fs-14 row-price">
                Max&nbsp;
                {textHelper.printMoney(
                  data.bonus_percentage_amount_max || data.bonus_fixed_amount
                )}
                <span className="gray-5">EXP {data.fr_end_date}</span>
              </div>
              <h6 className="mb-3">Syarat & Ketentuan</h6>
              {parse(data.tnc)}
              <h6 className="mb-3 mt-4 pt-1">Cara Menggunakan</h6>
              {parse(data.how_to_use)}
              <BasicButton
                text="GUNAKAN PROMO"
                onClick={subWithPromo}
                className="mt-5 py-3 px-4"
                disabled={!data.is_active}
              />
            </div>
          </>
        ) : (
          <div className="w-100 h-100 cont-center gray-4">
            voucher tidak di temukan
          </div>
        )}
      </ContainerWithLoader>
    </TempMain>
  );
};

export default memo(PromoDetailPage);
