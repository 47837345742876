import React from "react";
import { useMediaQuery } from "react-responsive";
import classNames from "classnames";
import { Card, Grid } from "@material-ui/core";

const ContainerLevel1 = ({ children, DesktopClass, MobileClass }) => {
  const isDesktop = useMediaQuery({ minWidth: 769 });
  const Container = isDesktop ? Card : Grid;
  return (
    <Container
      className={classNames(
        "d-flex flex-column",
        isDesktop ? DesktopClass : MobileClass
      )}
    >
      {children}
    </Container>
  );
};

export default ContainerLevel1;
