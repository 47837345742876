import React from "react";
import BasicText from "../../atom/BasicText/BasicText";
import { Grid } from "@material-ui/core";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ButtonContained from "../../atom/Button/ButtonContained";
import color from "../../../assets/theme/color";
import IconRobo from "../../../components/atom/IconRobo/IconRobo";
import classNames from "classnames";
import PopOver from "../../atom/PopOver/PopOver";

const PortoSummaryNumber = (props) => {
  const {
    data,
    onHideClick,
    hideButtonText,
    onAction,
    className,
    title = 0,
  } = props;
  const {
    fr_total_portofolio,
    fr_total_unrealized_gain,
    fr_total_unrealized_gain_percent,
    fr_smartplan_value,
    is_follow_robo,
    fr_total_units,
    smartplan_value,
  } = data;

  const btnRobo = [
    {
      txt: "TOP UP",
      bgColor: color.primary,
      className: "fs-11 font-weight-semibold mr-1",
      type: "subs",
    },
    {
      txt: "JUAL",
      bgColor: color.gray_6,
      className: "fs-11 font-weight-semibold ",
      type: "red",
      disabled: fr_total_units === 0,
    },
  ];

  const titleBase = ["SALDO PORTOFOLIO", "TOTAL BALANCE"];

  return (
    <div className={className}>
      <div className={classNames("d-flex justify-content-between mb-3")}>
        <BasicText className="my-auto fs-14 font-weight-bold color-secondary">
          {titleBase[title]}
        </BasicText>
        {onHideClick && (
          <ButtonContained
            id="hide-btn"
            text={hideButtonText}
            className="fs-9 p-1"
            onClick={onHideClick}
            radius="20px"
            backgroundColor={color.orange_3}
          />
        )}
      </div>
      <BasicText className="fs-22 color-secondary mb-2">
        {fr_total_portofolio}
      </BasicText>
      <Grid container className="mb-3">
        <Grid item xs={8}>
          <BasicText className="fs-10 gray-4 mb-1">TOTAL EARNINGS</BasicText>
          <BasicText className="fs-14 color-secondary">
            {fr_total_unrealized_gain}
          </BasicText>
        </Grid>
        <Grid item xs={4}>
          <div className="d-flex align-items-center">
            <BasicText className="fs-10 gray-4">RETURN</BasicText>
            <PopOver title="RETURN" className="ml-1" size={11}>
              Return adalah imbal hasil investasi yang di hitung sejak awal
              investasi hingga saat ini
            </PopOver>
          </div>
          <BasicText className="fs-14 color-secondary">
            {fr_total_unrealized_gain_percent}
            {""}
            {data.total_unrealized_gain_percent >= 0 ? (
              <ArrowDropUpIcon
                style={{ height: 18 }}
                className="color-primary"
              />
            ) : (
              <ArrowDropDownIcon
                style={{ height: 18 }}
                className="color-primary"
              />
            )}
          </BasicText>
        </Grid>
      </Grid>
      {is_follow_robo ? (
        <>
          <IconRobo />
          <div className="d-flex justify-content-end my-3">
            {btnRobo.map((el, id) => (
              <ButtonContained
                id={`${el.type}-goal`}
                key={id}
                style={{ width: 100 }}
                backgroundColor={el.bgColor}
                className={el.className}
                text={el.txt}
                radius="5px"
                onClick={() => onAction(el.type)}
              />
            ))}
          </div>
        </>
      ) : null}
      {smartplan_value ? (
        <BasicText className="fs-12 color-secondary" key={0}>
          <span className="font-weight-bold">Saldo SMART Plan:</span>{" "}
          {fr_smartplan_value}
        </BasicText>
      ) : null}
    </div>
  );
};

export default PortoSummaryNumber;
