import React from "react";
import { makeStyles } from "@material-ui/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import MenuProfile from "../../organism/ProfilePage/MenuProfile";
import ContentType from "../../organism/Content/ContentType";
import DialogCustom from "../../organism/Container/DialogCustom";
import { log_out_props } from "../../../config/redux/actions/auth";
import { connect } from "react-redux";
import HelpIcon from "@material-ui/icons/Help";
import processHelper from "../../../helper/processHelper";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
    display: "grid",
    gridTemplateColumns: "75% 1px 25%",
  },
  content: {
    padding: "40px 38px",
  },
  logoutModal: {
    width: 375,
  },
}));

const TempProfileDesktop = (props) => {
  const { children, data,loading } = props;
  const { component1, menuValue } = data;
  const [modal, setModal] = React.useState(false);
  const classes = useStyles();
  const [display, setDisplay] = React.useState(false);
  const handleClose = () => {
    document.getElementsByTagName("body")[0].style.overflowY = "visible";
    setDisplay(false);
  };
  const handleOpen = () => {
    document.getElementsByTagName("body")[0].style.overflowY = "hidden";
    window.scrollTo(0, 0);
    setDisplay(true);
  };
  const handleModal = (e) => {
    setModal(e);
  };
  const openModal = (e) => {
    handleModal(true);
  };
  const handleModalClick = () => {
    props.log_out_props();
  };
  return (
    <div className="pt-4 px-4 margin-main">
      <div className={classes.root}>
        <div className={classes.content}>
          {component1} {children}
        </div>
        <div className="d-flex justify-content-center">
          <Divider orientation="vertical" />
        </div>
        <div className="pt-3">
          <MenuProfile
            marginClass="mb-3 pb-2"
            menuValue={menuValue}
            glossary={handleOpen}
            logoutModal={openModal}
          />
        </div>
      </div>
      <ContentType
        display={display}
        contentKey="glossary"
        onClose={handleClose}
      />
      <DialogCustom
        open={modal}
        handleClose={handleModal}
        title={loading ? <CircularProgress /> :  <HelpIcon className="color-primary fs-40" />}
        titleClass="text-center mb-3"
        text={!loading ? "Apakah Anda akan keluar?" : ""}
        buttonText="OK"
        disabled={loading}
        onClick={handleModalClick}
        onCancel={() => handleModal(false)}
        className={classes.logoutModal}
        actionClass='p-0'
        textClass='fs-14 text-center mb-3'
        buttonClass='py-3 br-unset'
      />
    </div>
  );
};

const mapStateToProps = state => ({
    loading : processHelper.findProcess('log-out-process',state.stateReducer.loading_stack)
  })

export default connect(mapStateToProps, { log_out_props })(TempProfileDesktop);
