import { Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ilus from "../../assets/img/ilustration";
import BasicText from "../atom/BasicText/BasicText";
import TempMain from "../template/TempMain";

const useStyles = makeStyles({
  contain: {
    height: "80vh",
    backgroundColor: "#fff",
    margin:'1.5rem 1rem'
  }
});

const ComingSoonPage = () => {
  const location = useLocation();
  const [title, setTitle] = useState("COMING SOON");
  useEffect(() => {
    const { title } = location.state || {};
    if (title) setTitle(title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const S = useStyles();
  return (
    <TempMain title={title} isCloseButton showBottom={false}>
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        className={S.contain}
      >
        <img src={ilus.comingSoon} alt="" />
        <BasicText className="fs-14 gray-3 mt-4">
          Fitur ini akan segera hadir untuk kamu!
        </BasicText>
      </Grid>
    </TempMain>
  );
};

export default ComingSoonPage;
