import React, { Component } from "react";
import { connect } from "react-redux";
import color from "../../assets/theme/color";
import { compose } from "redux";
import BasicText from "../atom/BasicText/BasicText";
import PaymentList from "../organism/Payment/PaymentList";
import {
  set_payment_props,
  set_subscript_props,
  order_data,
} from "../../config/redux/actions/transaction";
import { withRouter } from "react-router-dom";
import ButtonContained from "../atom/Button/ButtonContained";
import processHelper from "../../helper/processHelper";
import ContainerWithLoader from "../organism/Container/ContainerWithLoader";
import DialogAlert from "../molecule/Alert/DialogAlert";
import TempMain from "../template/TempMain";
import withStyles from "@material-ui/styles/withStyles";
import { set_profile_data } from "../../config/redux/actions/profile";
// import RouteLeavingConfirm from "../../config/Router/RouteLeavingConfirm";

const useStyles = (theme) => ({
  root: {
    minHeight: 400,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.up(768)]: {
      justifyContent: "start",
    },
    "& .btnNext": {
      fontSize: 13,
      fontWeight: 700,
      padding: "0.75rem 2rem",
      marginRight: "1rem",
      [theme.breakpoints.down(769)]: {
        marginRight: 0,
      },
    },
    "& .btnCancel": {
      fontSize: 13,
      fontWeight: 700,
      padding: "0.75rem 2rem",
      [theme.breakpoints.down(769)]: {
        display: "none",
      },
    },
  },
});

class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payment_type: {},
      product_length: 0,
    };
  }
  componentDidMount() {
    const { product } = this.props.order;
    this.initialData(product);
  }

  initialData = (product) => {
    this.setState({ product_length: product.length });
    this.props.set_payment_props(product);
  };

  componentDidUpdate(prevProps) {
    const { subscript_result, order } = this.props;
    if (
      subscript_result !== prevProps.subscript_result &&
      subscript_result !== null
    ) {
      // this.props.set_profile_data({
      //   txn_count: subscript_result[0].new_count.waiting,
      // });
      // localStorage.setItem("txn_count", subscript_result[0].new_count.waiting);
      console.log(order);
      this.props.history.replace("/invoice", {
        ...subscript_result,
        totalBonus: Object.values(order.bonus).reduce(
          (total, data) => total + data?.bonus ?? 0,
          0
        ),
      });
    }
  }

  handleBack = () => {
    this.props.history.goBack();
  };

  onChange = (value, key) => {
    this.setState({
      payment_type: { ...this.state.payment_type, [key]: value },
    });
  };

  onSubmit = () => {
    const { set_subscript_props, order } = this.props;
    const { product, bonus, promo, products, ...other } = order;
    const { payment_type } = this.state;
    const data = { ...other };
    product.forEach((item, index) => {
      data[`txn[${index}][product_code]`] = item.code;
      data[`txn[${index}][amount]`] = item.amount;
      data[`txn[${index}][payment_code]`] = payment_type[item.code];
      if (promo[item.code]) {
        data[`txn[${index}][voucher_id]`] = promo[item.code];
      }
      if (bonus[item.code])
        data[`txn[${index}][expected_bonus]`] = bonus[item.code]?.bonus;
    });
    set_subscript_props(data);
  };

  //DEPRECATED
  onSubmite = () => {
    const { product, total_amount, bonus } = this.props.order;
    const { payment_type } = this.state;
    let data = {
      "product_code[]": [],
      "amount[]": [],
      "payment_type[]": [],
      "expected_bonus[]": [],
      product_variety: "",
    };
    product.forEach((e) => {
      data = {
        ...data,
        "product_code[]": [...data["product_code[]"], e.code],
        "amount[]": [...data["amount[]"], e.amount],
        product_variety: e.type,
        "expected_bonus[]": [...data["expected_bonus[]"], bonus[e.code]],
        "payment_type[]": [...data["payment_type[]"], payment_type[e.code]],
      };
      if (e.hasOwnProperty("goalId")) {
        data = { ...data, goals_id: e.goalId };
      }
    });
    data = { ...data, total_amount };
    // console.log(data);
    this.props.set_subscript_props(data);
  };

  acceptLeave = () => {
    this.props.history.go(-2);
  };
  componentWillUnmount() {
    this.props.order_data({});
  }
  render() {
    const { available_payment, loading_stack, classes } = this.props;
    const { payment_type, product_length } = this.state;
    console.log(this.state);
    return (
      <TempMain title="PEMBAYARAN" bottomNavValue={1}>
        {/* <RouteLeavingConfirm acceptLeave={this.acceptLeave} /> */}
        <div className="margin-main pt-4 px-4">
          <BasicText className="fs-14 font-weight-bold mb-3">
            Pilih Metode Pembayaran
          </BasicText>
          <ContainerWithLoader processName="set_payment" loadingHeight="70vh">
            {available_payment !== null && (
              <div className={classes.root}>
                <PaymentList
                  data={available_payment}
                  onChange={this.onChange}
                  marginClass="mb-5"
                />
                <div className={classes.buttonContainer}>
                  <ButtonContained
                    className="btnNext"
                    radius="5px"
                    text="BAYAR"
                    loading={processHelper.findProcess(
                      "subscript",
                      loading_stack
                    )}
                    onClick={this.onSubmit}
                    disabled={
                      Object.keys(payment_type).length !== product_length
                    }
                  />
                  <ButtonContained
                    backgroundColor={color.gray_6}
                    text="KEMBALI"
                    className="btnCancel"
                    radius="5px"
                    onClick={this.handleBack}
                  />
                </div>
              </div>
            )}
          </ContainerWithLoader>
        </div>
        <DialogAlert resultName="subscript" buttonText="OK" />
      </TempMain>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    available_payment: state.transactionReducer.available_payment,
    loading_stack: state.stateReducer.loading_stack,
    subscript_result: state.transactionReducer.subscript_result,
    order: state.transactionReducer.order_data,
  };
};

export default compose(
  connect(mapStateToProps, {
    set_payment_props,
    set_subscript_props,
    set_profile_data,
    order_data,
  }),
  withRouter,
  withStyles(useStyles)
)(Payment);
