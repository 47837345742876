import { makeStyles, withStyles } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import { withRouter } from "react-router-dom";
import ilustration from "../../assets/img/ilustration";
import logo from "../../assets/img/logo";
import color from "../../assets/theme/color";
import BasicText from "../atom/BasicText/BasicText";
import ButtonContained from "../atom/Button/ButtonContained";
import { connect } from "react-redux";
import { set_persist } from "../../config/redux/actions/persist";
import config from "../../config/constant/config";
const { useCallback, useEffect, useState } = React;

const useStyles = makeStyles({
  logo: {
    width: 140,
    marginTop: 30,
    marginLeft: 113,
  },
  img: {
    width: "calc(100% + 2px)",
    margin: "0 -1px -1px",
  },
  containAction: {
    flex: 1,
    backgroundColor: color.gray_4,
    textAlign: "center",
  },
  buttonRegis: {
    minWidth: 180,
    margin: "13px 0",
    padding: "13px 0",
  },
});

const SelectType = withStyles((theme) => ({
  root: {
    padding: "8px 15px",
    backgroundColor: "white",
    minWidth: 135,
    textAlign: "left",
    fontSize: 14,
    color: theme.palette.primary.main,
    fontWeight: "600",
  },
  select: {
    "&:focus": {
      backgroundColor: "white",
      borderRadius: 4,
    },
  },
  icon: {
    color: theme.palette.primary.main,
  },
}))(Select);

const OnBoarding = ({ history, onboarding, set_persist }) => {
  const classes = useStyles();
  const [listType] = useState([
    { id: "indi", text: "individual" },
    {
      id: "corp",
      text: "corporate",
      redirect: config.URL_CORP,
      masuk: "login",
    },
  ]);
  const [type, setType] = useState(0);

  useEffect(() => {
    // if (onboarding) history.replace("/masuk");
    // return () => set_persist({ onboarding: true });
  }, [history, onboarding, set_persist]);

  const handleType = useCallback(({ target }) => setType(target.value), []);

  const goTo = useCallback(
    (key) => {
      const use_type = listType[type];
      // NOTE: CORPORATE UNDEFINED
      if (type === 1) {
        return history.push('/coming-soon', { hideSidebar: true })
      }

      let des = key;
      if (use_type.hasOwnProperty(key)) des = use_type[key];
      if (use_type.redirect)
        window.location.href = `${use_type.redirect}/${des}`;
      else history.push(`/${des}`);
    },
    [history, listType, type]
  );

  return (
    <div
      className="d-flex flex-column h-min-full"
      style={{ overflow: "hidden" }}
    >
      <>
        <img src={logo.logo} className={classes.logo} alt="" />
      </>
      <img src={ilustration.onboarding} className={classes.img} alt="" />
      <div className={classes.containAction}>
        <div className="d-flex flex-column align-items-center">
          <SelectType
            id="select-dest"
            value={type}
            onChange={handleType}
            variant="outlined"
            className="text-capitalize"
          >
            {listType.map(({ id, text }, index) => (
              <MenuItem
                key={id}
                className="font-weight-semibold color-primary text-capitalize"
                value={index}
              >
                {text}
              </MenuItem>
            ))}
          </SelectType>
          <ButtonContained
            id="button-register"
            text="REGISTRASI"
            radius="4px"
            className={classes.buttonRegis}
            onClick={() => goTo("register")}
          />
        </div>
        <BasicText className="mt-2 mb-4" color="common" colorLevel="white">
          Sudah punya akun?
          <span
            id="link-login"
            className="ml-2 pl-1 text-underline is-link"
            onClick={() => goTo("masuk")}
          >
            SIGN IN DI SINI
          </span>
        </BasicText>
      </div>
    </div>
  );
};

const mapStateToProps = ({ persistReducer }) => ({
  onboarding: persistReducer.onboarding,
});

const mapDispatchToProps = {
  set_persist,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OnBoarding)
);
