import makeStyles from "@material-ui/styles/makeStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import { Close } from "@material-ui/icons";
import classNames from "classnames";
import React from "react";
import ilus from "../../../assets/img/ilustration";
import color from "../../../assets/theme/color";
import BasicText from "../../atom/BasicText/BasicText";
import { useMediaQuery } from "react-responsive";

const dialogStyle = {
  // borderRadius: 3,
  border: "1px solid " + color.blue_6,
  boxShadow: "0 20px 20px rgba(38, 153, 251,.1)",
};
const otherStyles = {
  contain: {
    display: "flex",
    flexDirection: "column",
    padding: "0 !important",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "9px 24px 23px",
  },
};

// const useStyles = makeStyles({
//   dialog: {
//     "& .MuiDialog-paper": {
//       borderRadius: 3,
//       border: "1px solid " + color.blue_6,
//       boxShadow: "0 20px 20px rgba(38, 153, 251,.1)",
//     },
//   },
//   contain: {
//     display: "flex",
//     flexDirection: "column",
//     padding: "0 !important",
//   },
//   content: {
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     padding: "9px 24px 23px",
//   },
// });

const DialogWithImage = ({
  title,
  withClose,
  ilustration,
  image,
  text,
  children,
  maxWidth,
  handleClose,
  contentClass,
  open,
  className,
}) => {
  const isDesktop = useMediaQuery({ minWidth: 769 });
  const useStyles = makeStyles({
    dialog: {
      "& .MuiDialog-paper": { ...dialogStyle, borderRadius: isDesktop ? 3 : 0 },
    },
    ...otherStyles,
  });
  const S = useStyles();
  function _handleOpen() {
    handleClose(!open);
  }
  return (
    <Dialog
      className={S.dialog}
      open={open}
      onClose={_handleOpen}
      aria-labelledby="customized-dialog"
      maxWidth={maxWidth}
      fullWidth
    >
      <DialogContent className={classNames(S.contain, className)}>
        {withClose && (
          <IconButton
            id="close-button"
            color="secondary"
            className="mb-3 align-self-end"
            onClick={_handleOpen}
          >
            <Close />
          </IconButton>
        )}
        <div className={classNames(S.content, S.contentTop, contentClass)}>
          {title && (
            <BasicText className="fs-14 font-weight-bold color-secondary mb-3 pb-1">
            {title}
          </BasicText>
          )}
          {ilustration ? <img alt="" src={ilus[ilustration]} /> : image}
        </div>
        <Divider color={color.blue_8} />
        <div className={classNames(S.content, "py-4", contentClass)}>
          <BasicText className="fs-14 color-secondary text-center mb-2">
            {text}
          </BasicText>
          {children}
        </div>
      </DialogContent>
    </Dialog>
  );
};

DialogWithImage.defaultProps = {
  title: "Title",
  withClose: true,
  text: "Text Modal Here",
  maxWidth: "xs",
  handleClose: () => {},
  open: false,
};

export default DialogWithImage;
