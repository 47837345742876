import React from "react";
import { withRouter } from "react-router-dom";
import ilustrate from "../../assets/img/ilustration";
import BasicText from "../atom/BasicText/BasicText";
import ButtonContained from "../atom/Button/ButtonContained";
import TempMain from "../template/TempMain";

const FinishOrder = (props) => {
  const { type, history } = props;
  const handleClick = () => {
    // const { idTxn } = history.location.state;
    // // let link = "/detail-transaksi/" + txn_reff;
    // // if (txn_reff === 0) link = "/transaksi";
    // // props.history.replace(link);
    // let baseUrl = "/transaksi/detail?";
    // if (Array.isArray(idTxn))
    //   idTxn.forEach((el, id) =>
    //     id ? (baseUrl += "&id=" + el) : (baseUrl += "id=" + el)
    //   );
    // else baseUrl += "id=" + idTxn;
    // // props.history.replace("/transaksi/detail?id=" + idTxn);
    // props.history.replace(baseUrl);

    const { ids } = history.location.state;
    let link = "/transaksi";
    console.log(ids, "ids");
    if (ids?.length ?? false) {
      link = "/transaksi/detail?";
      ids.forEach((id, index) => {
        link += `${index ? "&" : ""}id=${id}`;
      });
    }
    props.history.replace(link, type === "book" ? "booking" : undefined);
  };

  const header = {
    default: "TERIMA KASIH",
    sw: "PENUKARAN",
    book: "KONFIRMASI PEMESANAN",
  };

  const tx1 = {
    book: "Terima kasih sudah melakukan pemesanan!",
    subs: "Terima kasih sudah melakukan pembayaran!",
    redeem: "Penjualan Reksa Dana kamu sedang diproses",
    sw: (
      <>
        Konversi Reksa Dana kamu <br /> sedang diproses!
      </>
    ),
  };
  const tx2 = {
    book: "Pemesanan sedang diproses, untuk mengetahui status transaksi silahkan lihat detail transaksi kamu.",
    subs: "Pembayaran sedang diproses, untuk mengetahui status transaksi silahkan lihat detail transaksi kamu.",
    redeem:
      "Untuk mengetahui status transaksi silahkan lihat detail transaksi kamu. ",
    sw: (
      <>
        Untuk mengetahui status konversi silahkan lihat
        <br /> detail transaksi kamu.{" "}
      </>
    ),
  };
  return (
    <TempMain
      title={header[type] || header.default}
      showBottom={false}
      backgroundColor="#fff"
    >
      <div className="pt-4 px-4">
        <div className="card-custom container-center-basic">
          <img
            src={ilustrate[type] || ilustrate["subs"]}
            alt=""
            className="mb-4"
          />
          <BasicText
            className="text-center fs-14 font-weight-bold w-90 mb-4"
            color="primary"
          >
            {tx1[type]}
          </BasicText>
          <BasicText
            className="text-center fs-14 mb-5"
            colorLevel="light"
            style={{ maxWidth: 440 }}
          >
            {tx2[type]}
          </BasicText>
          <ButtonContained
            text="LIHAT TRANSAKSI KAMU"
            radius="4px"
            className="fs-14 font-weight-medium px-4 py-2"
            onClick={handleClick}
          />
        </div>
      </div>
    </TempMain>
  );
};

export default withRouter(FinishOrder);
