import React from "react";
import BasicText from "../../atom/BasicText/BasicText";
import TempDialogFull from "../TempDialogFull";
import DialogHeader from "../../molecule/Header/DialogHeader";
import ContainerWithLoader from "../../organism/Container/ContainerWithLoader";
import ContentSimulation from "../../organism/ProfileRiskPage/ContentSimulation";

const TempProfileRiskMobile = props => {
  const { children, data } = props;
  const {
    cardProfileRisk,
    product_list,
    modal,
    handleModal,
    profile_risk,
    chartColor,
    onSubscript
  } = data;
  return (
    <ContainerWithLoader processName="profile_risk" loadingHeight="80vh">
      {Object.keys(profile_risk).length > 0 && (
        <div className="pt-4 px-3 margin-main">
          <BasicText className="fs-14 font-weight-bold color-secondary px-2 mb-3">
            Pilihan produk reksa dana yang sesuai dengan profil risiko kamu:
          </BasicText>
          {cardProfileRisk}
          {product_list}
        </div>
      )}
      {children}
      <TempDialogFull modal={modal} onClose={e => handleModal(e, "modal")}>
        <DialogHeader
          title="SESUAI PROFIL"
          onClickClose={() => handleModal(false, "modal")}
        />
        <ContentSimulation
          profileRiskData={profile_risk}
          color={chartColor}
          onSubscript={onSubscript}
        />
      </TempDialogFull>
    </ContainerWithLoader>
  );
};

export default TempProfileRiskMobile;
