import { goals_rd, goals_list_rd, goals_user_rd } from "../redux/actions/goals";
import { get_data_option } from "../redux/actions/option";
import { set_profile_data, periodic_rd } from "../redux/actions/profile";
import { get_history, set_txn_rd } from "../redux/actions/transaction";

const stateCallApi = {
  goals_list: {
    path: "GOAL.list",
    key: "goals",
    redux_action: goals_list_rd,
    pathOldData: "goalReducer.list",
  },
  //deprecated
  goals_user: {
    path: "GOAL.user",
    key: "goals-user",
    redux_action: goals_rd,
    redux_path: "user",
  },
  goals_user_list: {
    key: "goals-user",
    path: "GOAL.user",
    redux_action: goals_user_rd,
    pathOldData: "goalReducer.user",
  },
  goals_user_update: {
    path: "GOAL.user_update",
    key: "goals-user-update",
    method: "post",
  },
  goals_user_delete: {
    path: "GOAL.user_delete",
    key: "goals-user-delete",
    method: "post",
  },
  goals_user_sub: {
    path: "GOAL.user_all_sub",
    key: "goals-user-sub",
  },
  goals_user_sw: {
    path: "GOAL.user_switch",
    key: "goal-sw",
    method: "post",
  },
  goals_user_track: {
    path: "GOAL.user_track",
    key: "goal-track",
    // method: "post",
  },
  goals_user_track_recalculate: {
    path: "GOAL.user_track_recalculate",
    key: "goal-track-recalculate",
    method: "post",
  },
  porto_summary: {
    path: "GOAL.porto_summary",
    key: "porto_summary",
    redux_action: goals_rd,
    redux_path: "porto_summary",
  },
  planner_list: {
    path: "GOAL.planner",
    key: "goal-planner",
    redux_action: goals_rd,
    redux_path: "planner",
    // validate: (getState) => !getState().goalReducer.planner.data.length,
  },
  planner_add_bulk: {
    path: "GOAL.planner_add_bulk",
    key: "add-planner",
    method: "post",
  },
  planner_add_single: {
    path: "GOAL.planner_add_single",
    key: "add-planner",
    method: "post",
  },
  planner_update: {
    path: "GOAL.planner_update",
    key: "update-planner",
    method: "post",
  },
  planner_replace: {
    path: "GOAL.planner_replace",
    key: "replace-planner",
    method: "post",
  },
  planner_delete: {
    path: "GOAL.planner_delete",
    key: "delete-planner",
    method: "post",
  },
  periodic_add: {
    path: "GOAL.periodic_add",
    key: "periodic-add",
    method: "post",
    appear: true,
    msg: "Berhasil mengatur investasi berkala",
  },
  periodic_list: {
    path: "GOAL.periodic_list",
    key: "periodic-list",
    redux_action: periodic_rd,
    pathOldData: "profileReducer.periodic_invest",
  },
  periodic_delete: {
    path: "GOAL.periodic_delete",
    key: "periodic_delete",
    method: "post",
    appear: true,
    msg: "Berhasil berhenti investasi berkala",
  },
  periodic_notif: {
    path: "GOAL.periodic_notif",
    key: "periodic-notif",
  },
  booking_list: {
    key: "booking-list",
    path: "TXN.booking",
    // redux_action: get_history,
  },
  txn_list: {
    key: "txn-list",
    path: "TXN.list",
    redux_action: get_history,
  },
  txn_list_set_confiem_payment: {
    key: "txn-list",
    path: "TXN.list",
    redux_action: set_txn_rd,
    redux_path: "confirm_payment_data",
  },
  profile_risk_base_point: {
    key: "profile_risk_base_point",
    path: "GOAL.goal_robo_alc",
    redux_action: goals_rd,
    redux_path: "base_robo_alc",
    validate: (getState) =>
      !Boolean(getState().goalReducer.base_robo_alc.length),
  },
  update_periodic: {
    path: "GOAL.update_periodic_invest",
    key: "update-periodic",
    method: "post",
  },
  popup_banner: {
    path: "other.popup_banner",
    key: "popup_banner",
    method: "get",
  },
  tax_report: {
    key: "tax_report",
    path: "PROFILE.tax_report",
    redux_action: set_profile_data,
    redux_path: "tax_report",
  },
  dividend_report: {
    key: "dividend_report",
    path: "PROFILE.dividend_report",
    redux_action: set_profile_data,
    redux_path: "dividend_report",
  },
  dividend_report_download: {
    key: "dividend_report_download",
    path: "PROFILE.dividend_report_download",
    method: "get",
  },
  document_report: {
    key: "document_report",
    path: "PROFILE.document_report",
    redux_action: set_profile_data,
    redux_path: "document_report",
  },
  crypt: {
    key: "crypt",
    path: "CONTENT.crypt",
    method: "post",
  },
  updateBank: {
    path: "PROFILE.user_bank.update",
    key: "update-bank",
    method: "post",
    appear: true,
    msg: "Update akun bank berhasil diajukan. Mohon tunggu beberapa saat.",
  },
  voucherMerchantList: {
    path: "VOUCHER.merchant.list",
    key: "voucher-merchant-list",
    redux_action: get_data_option,
    redux_path: "voucher_merchant",
  },
  voucherMerchantCategory: {
    path: "VOUCHER.merchant.categories",
    key: "voucher-merchant-categories",
  },
  voucherMerchantDetail: {
    path: "VOUCHER.merchant.detail",
    key: "voucher-merchant-detail",
  },
  voucherMerchantScan: {
    path: "VOUCHER.merchant.redeem",
    key: "voucher-merchant-redeem",
    method: "post",
  },
  ultraVoucher: {
    path: "PARTNER.ultra_voucher",
    key: "ultra-voucher",
  },
  deleteAccount: {
    path: "AUTH.delete_account",
    key: "delete_account",
    method: "post",
  },
  updateMotherName: {
    path: "AUTH.update_mother_name",
    key: "update_mother_name",
    method: "post",
    appear: true,
    msg: "Berhasil update nama ibu kandung",
  },
};

export default stateCallApi;
