import { withStyles } from "@material-ui/core";
import { Email, Lock } from "@material-ui/icons";
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import * as yup from "yup";
import processHelper from "../../../helper/processHelper";
// import GenerateForm from "../../template/generate/GenerateForm";
import FormRegister from "../Register/FormRegister";

const useStyles = (theme) => ({
  input: {
    paddingLeft: "12px !important",
  },
  button: {
    fontSize: 14,
    padding: "12px 55px",
    marginTop: 34,
    width: "unset",
    alignSelf: "flex-start",
  },
});

const mapState = (state) => {
  return {
    loading_stack: state.stateReducer.loading_stack,
  };
};

const schema = yup.object().shape({
  identity: yup
    .string()
    .email("Email tidak valid")
    .required("Email harus diisi"),
  password: yup.string().required("Password harus diisi"),
});

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initial: { identity: "", password: "" },
      formData: [
        {
          inputClass: "fs-16",
          variant: "outlined",
          target: "identity",
          placeholder: "email",
          marginClass: "mb-3",
          startIcon: Email,
          type: "text",
        },
        {
          inputClass: "fs-16",
          variant: "outlined",
          target: "password",
          placeholder: "password",
          marginClass: "mb-4",
          startIcon: Lock,
          type: "password",
          passVisible: true,
        },
      ],
    };
  }
  onSubmit = (e) => {
    this.props.onSubmit(e);
  };
  render() {
    const { initial } = this.state;
    const { classes, loading, loading_stack } = this.props;
    return (
      <FormRegister
        ref="form"
        initial={initial}
        validation={schema}
        onSubmit={this.onSubmit}
        idSubmit="login"
        textSubmit="LOGIN"
        submitOnEnter
        submitClass={classes.button}
        loadingSubmit={processHelper.findProcess(loading, loading_stack)}
        data={[
          {
            name: "identity",
            id : "identity",
            placeholder: "email",
            StartIcon: Email,
            startIconClass: "gray-5",
            type: "email",
            className: classes.input,
          },
          {
            name: "password",
            id: "password",
            placeholder: "password",
            StartIcon: Lock,
            startIconClass: "gray-5",
            type: "password",
            className: classes.input,
            cClass: "mt-n1",
          },
        ]}
      />
    );
    // return (
    //   <GenerateForm
    //     formData={formData}
    //     buttonText="LOGIN"
    //     buttonClass={classes.button}
    //     initial={initial}
    //     schema={schema}
    //     onSubmit={this.onSubmit}
    //     loading={processHelper.findProcess(
    //       this.props.loading,
    //       this.props.loading_stack
    //     )}
    //   />
    // );
  }
}

export default compose(connect(mapState), withStyles(useStyles))(LoginForm);
