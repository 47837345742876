import { Grid, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { useRef } from "react";
import BasicText from "../../atom/BasicText/BasicText";
import ProductRecommendChart from "./ProductRecommendChart";
import RecomColorDesc from "./RecomColorDesc";
import { ModalType } from "./TypeContent";
const useStyles = makeStyles({
  chart: {
    width: 200,
    height: 200,
  },
});

const CardRiskProduct2 = ({
  profileRiskProduct,
  chartColor,
  className,
  loading,
}) => {
  const S = useStyles();
  const modal = useRef();
  function handleOpenModal(e) {
    modal.current.handleOpen(e);
  }
  return (
    <Grid className={className}>
      <BasicText className="fs-14 font-weight-bolder gray-3 mb-3 pb-3">
        Rekomendasi produk:
      </BasicText>
      <Grid container alignItems="center">
        <Grid item xs={4}>
          {loading ? (
            <Grid container justify="center">
              <Skeleton variant="circle" height={140} width={140} />
            </Grid>
          ) : (
            <ProductRecommendChart
              containerClass={S.chart}
              chartColor={chartColor}
              profileRiskProduct={profileRiskProduct}
              update={true}
              idChart="ChartRiskModal"
            />
          )}
        </Grid>
        <Grid item xs={6} className="ml-5">
          {loading ? (
            <div>
              <Skeleton className="mb-2" />
              <Skeleton />
            </div>
          ) : (
            <RecomColorDesc
              color={chartColor}
              profileRiskProduct={profileRiskProduct}
              nameClass="text-capitalize fs-13"
              alokasiClass="color-secondary mb-n3"
              onClick={handleOpenModal}
            />
          )}
          <ModalType ref={modal} />
        </Grid>
      </Grid>
    </Grid>
  );
};

CardRiskProduct2.defaultProps = {
  profileRiskProduct: [],
  chartColor: [],
};

export default CardRiskProduct2;
