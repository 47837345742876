import * as types from "../actions/types";

const initial = {
  user_type: null,
  can_txn: 0,
  syailendra_point: 0,
  notification_count: 0,
  txn_count: null,
  my_voucher_count: 0,
  profile: {},
  profile_risk: {},
  risk_survey: [],
  bank_account: [],
  sid: null,
  regis_status: false,
  updateMotherNameStatus: false,
  alert: [],
  is_show_porto: false,
  tax_report: [],
  dividend_report: [],
  document_report: [],
  profile_risk_base_point: [],
  periodic_invest: {
    data: [],
  },
};

const initial_backup = { ...initial };

const profileReducer = (state = initial, action) => {
  switch (action.type) {
    case types.SET_PROFILE_DATA: {
      return { ...state, ...action.payload };
    }
    case types.GET_ACC_BANK: {
      return { ...state, bank_account: action.payload };
    }
    case types.PROFILE_RISK: {
      return { ...state, profile_risk: action.payload };
    }
    case types.RISK_SURVEY: {
      return { ...state, risk_survey: action.payload };
    }
    case types.LOG_OUT: {
      return { ...state, ...initial_backup };
    }
    default:
      return state;
  }
};
export default profileReducer;
