import parse, { domToReact } from "html-react-parser";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import {
  chart_color_props,
  fetch_ins_benefit,
  get_insurance_info,
} from "../../config/redux/actions/option";
import {
  clear_product_history_props,
  product_history_props,
  set_product,
} from "../../config/redux/actions/product";
import {
  profile_risk_props,
  risk_result_props,
} from "../../config/redux/actions/profile";
import BasicText from "../atom/BasicText/BasicText";
import DialogCustom from "../organism/Container/DialogCustom";
import SimulasiSmartPlan from "../organism/SmartPlan/SimulasiSmartPlan";
import TempMain from "../template/TempMain";

const TnCSmartplan = ({ tncData }) => {
  const value = tncData?.value || "";
  const options = {
    replace: (domNode) => {
      if (domNode.attribs && domNode.name === "ol") {
        return (
          <ol style={{ paddingLeft: 20 }}>
            {domToReact(domNode.children, options)}
          </ol>
        );
      }
    },
  };
  return (
    <div className="p-3">
      <BasicText className="mb-3 fs-14 fw-700">SYARAT DAN KETENTUAN</BasicText>
      <div>{parse(value, options)}</div>
      <BasicText
        className="my-3 fs-14 color-primary is-link"
        onClick={() => window.open(tncData?.file_path, "_blank")}
      >
        LIHAT LEBIH LANJUT
      </BasicText>
    </div>
  );
};
export class SmartPlanPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dataProduct: {
        allocation: 100,
        product_code: "MSEOF",
        product: {},
      },
      modalSmartplan: false,
      modalTnCSmartplan: false,
      totalAmount: 0,
    };
  }
  componentDidMount = () => {
    const {
      products,
      set_product,
      product_history_props,
      chart_color,
      chart_color_props,
      profile_risk_props,
      fetch_ins_benefit,
      get_insurance_info,
    } = this.props;
    const isHaveProduct = products.length;
    const isHaveChartColor = chart_color.length;
    window.scrollTo(0, 0);
    const { product_code } = this.state.dataProduct;
    if (!isHaveProduct) set_product();
    else this._setDataProduct(products);
    get_insurance_info({ product_variety_code: "CG" });
    fetch_ins_benefit({ code: "CG" });
    product_history_props({
      code: product_code,
      display: "all",
      type: "monthly",
    });
    if (!isHaveChartColor) chart_color_props();
    profile_risk_props();
  };

  componentWillUnmount() {
    this.props.clear_product_history_props();
  }

  componentDidUpdate = (prevProps) => {
    const { products } = this.props;
    const isProductChangeAndProductArray =
      products !== prevProps.products && Array.isArray(products);
    if (isProductChangeAndProductArray) this._setDataProduct(products);
  };

  _setDataProduct = (products) => {
    const { product_code } = this.state.dataProduct;
    const product =
      products[products.findIndex(({ code }) => code === product_code)];
    const isProductAvailable = product !== undefined;
    if (isProductAvailable) {
      this.setState((state) => {
        let { dataProduct } = state;
        dataProduct.product = product;
        return { dataProduct };
      });
    } else {
      this.setState({ modalSmartplan: true });
    }
  };

  _handleInvest = (total_amount) => {
    const { product_code } = this.state.dataProduct;
    this.props.history.push(`/subscript`, {
      product_variety: "CG",
      total_amount,
      products: { [product_code]: { amount: 100 } },
    });
  };

  handleChangeRisk = (data) => {
    this.props.risk_result_props({
      point: data.profile_risk_point,
      mode: "save_risk_result",
    });
  };

  handleBacktoDashboard = () => {
    this.props.history.push("/");
  };

  render() {
    const { dataProduct } = this.state;
    const { product_history, ins_benefit } = this.props;
    const dataOption = {
      contentProduct: {
        data: [dataProduct],
        id: "graph-smart-plan",
      },
      handleInvest: this._handleInvest,
      simulasi: (
        <SimulasiSmartPlan
          history={product_history}
          suport={ins_benefit}
          handleInvest={(total_amount) => {
            this.setState({
              totalAmount: total_amount,
              modalTnCSmartplan: true,
            });
          }}
        />
      ),
    };
    return (
      <>
        <TempMain
          title="SMART PLAN"
          data={dataOption}
          page="smartPlan"
          showBottom={false}
        />
        <DialogCustom
          open={this.state.modalSmartplan}
          handleClose={this.handleBacktoDashboard}
          onClick={this.handleBacktoDashboard}
          title="SMARTPLAN"
          text="Akun ini tidak dapat mengakses Smartplan"
          buttonText="Keluar"
        />
        <DialogCustom
          open={this.state.modalTnCSmartplan}
          handleClose={() => this.setState({ modalTnCSmartplan: false })}
          onClick={() => this._handleInvest(this.state.totalAmount)}
          showCloseButton
          title=""
          textClass="fs-12"
          text={<TnCSmartplan tncData={this.props.ins_info} />}
          buttonText="SETUJU"
        />
      </>
    );
  }
}

const mapStateToProps = ({
  productReducer,
  profileReducer,
  optionReducer,
  stateReducer,
}) => ({
  products: productReducer.product,
  product_history: productReducer.history,
  profile_risk: profileReducer.profile?.profile_risk ?? null,
  chart_color: optionReducer.chart_color,
  loading_stack: stateReducer.loading_stack,
  ins_benefit: optionReducer.ins_benefit,
  ins_info: optionReducer.ins_info,
});

export default compose(
  connect(mapStateToProps, {
    set_product,
    product_history_props,
    clear_product_history_props,
    get_insurance_info,
    chart_color_props,
    risk_result_props,
    profile_risk_props,
    fetch_ins_benefit,
  }),
  withRouter
)(SmartPlanPage);
