import { isEqual } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { risk_result_props } from "../../../config/redux/actions/profile";
import { remove_result_l } from "../../../config/redux/actions/state";
import processHelper from "../../../helper/processHelper";
import ModalProfileRisk from "../../organism/Register/ModalProfileRisk";
import CardRisk from "../CardRisk";
import ListProduct from "../ListProduct";
const { PureComponent } = React;
class GraphAlocationProduct extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data1: {},
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { result_stack, remove_result_l } = this.props;
    const res = processHelper.findResult("save_risk_result", result_stack);
    if (prevState !== this.state) {
      this.props.getData(this.state.data1);
    }
    if (result_stack !== prevProps.result_stack && res.type === "success") {
      this.handleModalRisk();
      remove_result_l("save_risk_result");
    }
  };

  handleClickProduct = ({ link, items }) => {
    const product = items;
    this.props.history.push(`${link}?product=${product.code}`, { product });
  };

  handleModalRisk = () => this.refs.modal_risk.handleVisible();

  handleChangeRisk = (newRisk) => {
    const { profile_risk, risk_result_props } = this.props;
    if (!isEqual(newRisk, profile_risk)) {
      risk_result_props({
        point: newRisk.profile_risk_point,
        mode: "save_risk_result",
      });
    } else this.handleModalRisk();
  };

  handleDataFilterRisk = (data1) => {
    this.setState((prevState) => ({
      ...prevState,
      data1,
    }));
  };

  render() {
    const { chart_color, id, loading_stack, base_robo_alc, riskPoint } =
      this.props;
    const { data1 } = this.state;
    return (
      <div>
        <CardRisk
          colors={chart_color}
          riskPoint={riskPoint}
          data1={base_robo_alc}
          idChart={id}
          getData1={this.handleDataFilterRisk}
        />
        <ListProduct
          data={Object.keys(data1).length ? data1.profile_risk_product : []}
          onClick={this.handleClickProduct}
        />
        <ModalProfileRisk
          ref="modal_risk"
          onSubmit={this.handleChangeRisk}
          propsButton={{
            loading: processHelper.findProcess(
              "save_risk_result",
              loading_stack
            ),
          }}
        />
      </div>
    );
  }
}
GraphAlocationProduct.defaultProps = {
  data: [],
  colors: [],
  profile_risk: {},
  changeRisk: () => {},
};

const mapStateToProps = ({
  optionReducer,
  profileReducer,
  stateReducer,
  goalReducer,
}) => ({
  chart_color: optionReducer.chart_color,
  profile_risk: profileReducer.profile_risk,
  loading_stack: stateReducer.loading_stack,
  result_stack: stateReducer.result_stack,
  base_robo_alc: goalReducer.base_robo_alc,
});
const mapDispatchToProps = {
  risk_result_props,
  remove_result_l,
};
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(GraphAlocationProduct);
