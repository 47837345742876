import { withStyles } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { set_faq } from "../../../config/redux/actions/option";
import TabCustom from "../../molecule/Tabs/TabCustom";
import FaqMenuList from "../../organism/FaqPage/FaqMenuList";

const useStyles = (theme) => {
  const isDesktop = theme.breakpoints.up(769);
  const isMobile = theme.breakpoints.down(768);
  return {
    containerInfo: {
      padding: "0 35px 0 0",
      [isMobile]: {
        padding: "43px 21px 33px 26px",
        backgroundColor: theme.palette.secondary[8],
      },
      marginBottom: 28,
    },

    tabs: {
      width: "unset !important",
      [isDesktop]: {
        marginRight: 29,
        marginTop: 25,
        "& .item-tab-0": {
          marginLeft: 39,
        },
      },
    },
    content: {
      marginTop: "12px !important",
      [isDesktop]: {
        margin: "0 39px",
      },
    },
  };
};

class KnowledgeCenterPage extends React.PureComponent {
  constructor(props) {
    super(props);
    const { tab } = props.location.state || { tab: 0 };
    this.state = {
      tab,
      modal: false,
      titleInfo: "KNOWLEDGE CENTER",
    };
    this.tabs = ["Pengenalan Dasar Reksadana", "F.A.Q Reksadana"];
  }
  componentDidMount() {
    this.props.set_faq("knowledge_center");
  }
  handleChangeTabs = (e) => {
    this.setState({ tab: e });
  };
  handleClick = (e, title) => {
    this.props.history.push(
      `/knowledge-center/${e.title.toLowerCase().replace(/ /g, "-")}`,
      e
    );
  };

  render() {
    const { knowledge_center, faq, headerMode, headerSearch, classes } =
      this.props;
    const { tab } = this.state;
    return (
      <>
        <TabCustom
          data={this.tabs}
          onChange={this.handleChangeTabs}
          className={classes.tabs}
          active={tab}
        />
        <div className={classes.content}>
          <div className={classes.containerInfo}>
            <p className="basic fs-14">
              Ingin belajar lebih banyak tentang investasi? Yuk cari tahu lebih
              lanjut!
            </p>
          </div>

          <FaqMenuList
            knowledgeCenter={knowledge_center}
            faq={faq}
            value={tab}
            onChange={this.handleChangeTabs}
            onClick={this.handleClick}
            search={headerSearch}
            mode={headerMode}
          />
        </div>
      </>
    );
  }
}

const mapState = (state) => ({
  knowledge_center: state.optionReducer.knowledge_center,
  faq: state.optionReducer.faq,
  headerMode: state.optionReducer.headerMode,
  headerSearch: state.optionReducer.headerSearch,
});

export default compose(
  connect(mapState, { set_faq }),
  withRouter,
  withStyles(useStyles)
)(KnowledgeCenterPage);
