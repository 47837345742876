import * as types from "../actions/types";
import { createLogic } from "redux-logic";
import { set_article, set_article_content } from "../actions/article";
import { set_loading, remove_loading, set_result } from "../actions/state";
import { requestAxios } from "../../API/request";
import url from "../../API/url";
import { batch } from "react-redux";

export const set_content_article = createLogic({
  type: types.SET_ARTICLE_CONTENT_PROPS,
  latest: true,
  validate({ action, getState }, allow, reject) {
    const { article } = getState().articleReducer;
    console.log(action);
    const { id, slug } = action.payload;
    let res = { blog_id: id || 0 };
    if (article && slug && !id) {
      const data = article.data || [];
      res.blog_id = data[data.findIndex((item) => item.slug === slug)]?.id ?? 0;
    }
    action.payload = res;
    allow(action);
  },
  process({ action, getState }, dispatch, done) {
    const { article } = getState().articleReducer;
    const { payload } = action;
    if (!article) {
      dispatch(set_article("artikel"));
      done();
    } else {
      const name = "article-content";
      dispatch(set_loading(name));
      requestAxios(url.BLOG.content, { ...payload, html_content: 1 })
        .then((res) => {
          batch(() => {
            dispatch(remove_loading(name));
            dispatch(set_article_content(res.message));
          });
        })
        .catch((err) => {
          batch(() => {
            dispatch(remove_loading(name));
            dispatch(
              set_result({ name, data: { type: "error", msg: err.message } })
            );
          });
        })
        .then(done);
    }
  },
});
