import React, { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { gbl_act_lc } from "../../config/redux/actions/state";
import stateCallApi from "../../config/API/stateCallApi";
import TempMain from "../template/TempMain";
import BasicText from "../atom/BasicText/BasicText";
import parse from "html-react-parser";
import { Button, makeStyles } from "@material-ui/core";
import classNames from "classnames";

const VoucherMerchantDetailPage = ({ location, history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [data, setData] = useState(null);
  useEffect(() => {
    const voucherId = new URLSearchParams(location.search).get("id");
    if (!voucherId) {
      return;
    }
    dispatch(
      gbl_act_lc({
        ...stateCallApi.voucherMerchantDetail,
        params: { id: voucherId },
        callback: setData,
      })
    );
  }, [dispatch, location.search]);

  return (
    <TempMain
      bottomNavValue={0}
      goBackButton
      showBottom={false}
      initialPage
      title="DETAIL VOUCHER"
    >
      {data && (
        <div className="pb-5 d-flex flex-column">
          <img
            style={{ minHeight: 151, width: "100%" }}
            alt={`banner-${data.title}`}
            src={data.image_url}
          />
          <div className="bg-white py-4 px-5">
            <BasicText className="pb-3 fs-16 fw-700">{data.title}</BasicText>
            <BasicText className="fs-12">{data.fr_expired_at}</BasicText>
          </div>

          <div
            className={classNames(
              classes.contentList,
              "card-custom mx-3 basic fs-12 my-3"
            )}
          >
            <BasicText className="fs-14 fw-700 mb-3">
              Syarat & Ketentuan
            </BasicText>
            {parse(data.terms_and_condition)}
          </div>

          <div
            className={classNames(
              classes.contentList,
              "card-custom mx-3 basic fs-12 mb-5"
            )}
          >
            <BasicText className="fs-14 fw-700 mb-3">
              Cara Menggunakan
            </BasicText>
            {parse(data.how_to_use)}
          </div>
          <Button
            variant="contained"
            color="primary"
            className="align-self-center"
            size="large"
            onClick={() => {
              history.push(
                `/voucher-merchant/scan?merchant-id=${data.merchant.id}`
              );
            }}
          >
            Gunakan Promo
          </Button>
        </div>
      )}
    </TempMain>
  );
};

const useStyles = makeStyles({
  contentList: {
    "& ol": {
      marginLeft: 14,
    },
  },
});

export default withRouter(memo(VoucherMerchantDetailPage));
