import React from "react";
import TempMain from "../../template/TempMain";
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import KnowledgeCenterPage from "./KnowledgeCenterPage";
import DetailKnowledge from "./DetailKnowledge";
import { makeStyles } from "@material-ui/core";
import CloseButton from "../../atom/CloseButton";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    paddingBottom: 55,
    "&.detail": {
      margin: 24,
      backgroundColor: "#fff",
      borderRadius: 4,
      overflow: "hidden",
      boxShadow: "1px 2px 1px rgba(0, 0, 0, 0.1)",
    },
    [theme.breakpoints.up(769)]: {
      margin: 24,
      backgroundColor: "#fff",
      borderRadius: 4,
      overflow: "hidden",
      boxShadow: "1px 2px 1px rgba(0, 0, 0, 0.1)",
    },
  },
  closeButton: {
    position: "absolute",
    top: 3,
    right: 3,
    [theme.breakpoints.down(768)]: {
      display: "none",
    },
  },
}));

const KnowledgeRouter = () => {
  const classes = useStyles();
  const { path, params } = useRouteMatch();
  const { goBack } = useHistory();
  return (
    <TempMain
      title="KNOWLEDGE CENTER"
      isCloseButton
      showBottom={false}
      backgroundColor="white"
      withSearch
    >
      <div
        className={classNames(classes.container, params && `detail ${params}`)}
      >
        <CloseButton
          id="close-btn"
          onClick={goBack}
          className={classes.closeButton}
        />
        <Switch>
          <Route path={path} exact>
            <KnowledgeCenterPage />
          </Route>
          <Route path={`${path}/:id`}>
            <DetailKnowledge />
          </Route>
        </Switch>
      </div>
    </TempMain>
  );
};

export default React.memo(KnowledgeRouter);
