import React from "react";
import useStyles from "./useStyles";
import classNames from "classnames";
import parse from "html-react-parser";
import textHelper from "../../../../helper/textHelper";
import ButtonLink2 from "../../../atom/Button/ButtonLink2";
import ButtonContained from "../../../atom/Button/ButtonContained";
import { useMediaQuery } from "react-responsive";
import Grid from "@material-ui/core/Grid";
import IconTxnGroupSuccess from "../../../../assets/icons/IconTxnGroupSuccess.svg";

const GroupingTxn = ({ data = {}, onClick }) => {
  const classes = useStyles();
  const isDesktop = useMediaQuery({ minWidth: 769 });
  const {
    id,
    fr_transaction_name_retail,
    txn_type_code,
    fr_traded_at,
    user_goal,
    transactions,
    show_upload_button,
    fr_total_amount,
  } = data;
  console.log(data)
  return (
    <>
      <div
        className={classNames(
          "d-flex align-items-center pb-2 mb-2",
          classes.borderDot
        )}
      >
        <img
          alt=" "
          src={
            user_goal?.ref_goals_icon?.icon_web_path ||
            user_goal?.ref_goals_icon?.icon_path
          }
          className={classes.icon_goals}
        />
        <p className="basic font-weight-semibold text-uppercase mr-auto">
          {user_goal?.goal_name}
        </p>
        <div className={classNames(classes.flag, txn_type_code)}>
          {fr_transaction_name_retail}
        </div>
      </div>

      <div className="d-flex">
        <p className="basic fs-10 gray-4 mr-auto">{fr_traded_at}</p>
        <p className="fs-12 basic mr-5 pr-md-4 pr-0">Total</p>
        <p className="basic fs-14 font-weight-bold color-primary">
          {fr_total_amount}
        </p>
      </div>

      <div className="mt-2">
        {transactions?.map(
          ({
            product_code,
            product,
            fr_transaction_status,
            txn_reff,
            txn_status_code,
          }) => (
            <div key={product_code}>
              <div className={classNames(classes.borderTitle, "pb-1 pt-2")}>
                <Grid container alignItems="center" spacing={3}>
                  <Grid item xs={12} md={3}>
                    <p className="basic fs-12 font-weight-semibold">
                      {parse(
                        textHelper.printProduct2Line(product.fr_product_name)
                      )}
                    </p>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <p className="basic fs-12 mr-auto">
                      Nomor Transaksi: {txn_reff}
                    </p>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={3}
                    className="d-flex align-items-center"
                  >
                    {txn_status_code === "APPROVED" && (
                      <>
                        <p className="basic fs-12 gray-4">
                          Konfirmasi Bank Kustodian
                        </p>
                        <img
                          alt=""
                          src={IconTxnGroupSuccess}
                          className="ml-3"
                          onDragStart={(e) => e.preventDefault()}
                        />
                      </>
                    )}
                  </Grid>
                  {isDesktop && (
                    <Grid className="text-right" item xs={12} md={3}>
                      <p className="basic fs-12 gray-4">
                        {fr_transaction_status}
                      </p>
                    </Grid>
                  )}
                </Grid>
              </div>
              {!isDesktop && (
                <p className="basic fs-12 gray-4 mt-1">
                  {fr_transaction_status}
                </p>
              )}
            </div>
          )
        )}
      </div>

      <div
        style={{ paddingTop: 15 }}
        className={classNames(
          "d-flex align-items-start justify-content-end pb-1"
        )}
      >
        {show_upload_button && (
          <ButtonContained
            id={`confirm-payment-${id}`}
            className="mx-auto"
            text="UPLOAD BUKTI TRANSFER"
            radius="4px"
            onClick={() => onClick("confirm-payment")}
          />
        )}

        <ButtonLink2
          id={`detail-${id}`}
          text="DETAIL"
          className="fs-12 text-underline is-link color-secondary"
          onClick={() => onClick("detail")}
        />
      </div>
    </>
  );
};

export default GroupingTxn;
