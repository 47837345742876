import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { profile_risk_props } from "../../../config/redux/actions/profile"
import useChartColors from "./useChartColors"

const useProfileRiskData = () => {
    const chart_color = useChartColors()
    const profile_risk = useSelector(state => state.profileReducer.profile_risk)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(profile_risk_props())
    }, [dispatch])

    return [chart_color, profile_risk]
}

export default useProfileRiskData