import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/styles/withStyles";
import classNames from "classnames";
import parse from "html-react-parser";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import color from "../../assets/theme/color";
import stateCallApi from "../../config/API/stateCallApi";
import {
  get_acc_bank_props,
  set_profile_data,
} from "../../config/redux/actions/profile";
import { gbl_act_lc } from "../../config/redux/actions/state";
import {
  confirm_payment_data,
  set_proof_props,
} from "../../config/redux/actions/transaction";
import processHelper from "../../helper/processHelper";
import textHelper from "../../helper/textHelper";
import BasicText from "../atom/BasicText/BasicText";
import ButtonContained from "../atom/Button/ButtonContained";
import DialogAlert from "../molecule/Alert/DialogAlert";
import BoxUpload from "../organism/Payment/BoxUpload";
import TempMain from "../template/TempMain";
const { Component, memo } = React;
const useStyles = (theme) => {
  const { secondary } = theme?.palette ?? {};
  return {
    contentHeader: {
      borderBottom: `2px dotted ${secondary?.[4]}`,
      paddingBottom: 20,
      marginBottom: 20,
    },
    boxAccount: {
      maxWidth: 425,
    },
    boxUpload: {
      maxWidth: 345,
      marginBottom: "1rem",
    },
    contentArea: {
      height: "calc(100vh - 72px - 99px)",
    },
  };
};

const RenderAmount = ({ total }) => {
  return (
    <div className="d-flex align-items-center justify-content-between">
      <p className="basic fs-12 font-weight-bold mr-4">Total Pembayaran</p>
      <p className="basic fs-14 font-weight-semibold color-primary">{total}</p>
    </div>
  );
};

const InputBank = memo(({ title = "", value = "" }) => {
  return (
    <div className="mt-3">
      <p className="basic fs-12 mb-1">{title}</p>
      <div
        style={{ borderBottom: `1px solid ${color.secondary}` }}
        className="basic py-2 px-0 col-12 col-md-11 fs-14"
      >
        {value}
      </div>
    </div>
  );
});

class ConfirmPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payment_proof: {},
      id: "",
    };
  }
  componentDidMount() {
    const { location, history, get_acc_bank_props, gbl_act_lc } = this.props;
    get_acc_bank_props();
    gbl_act_lc({
      ...stateCallApi.txn_list_set_confiem_payment,
      params: { "query[id][]": location.state },
      callback: { fail: history.goBack },
    });
  }

  componentDidUpdate(prevProps) {
    const { proof_result, confirm_payment } = this.props;
    if (prevProps.proof_result !== proof_result && proof_result.status) {
      // this.props.set_profile_data({
      //   txn_count: proof_result.data.new_count.waiting,
      // });
      // localStorage.setItem("txn_count", proof_result.data.new_count.waiting);
      const idTxn = [];
      confirm_payment.data.forEach((el) => idTxn.push(el.id));
      const needFileDebet = confirm_payment.data.some(
        ({ payment }) => payment.transaction_auto_debet?.document
      );
      let direction = "/finish-confirm";
      if (needFileDebet) direction = "/file-auto-debet";
      this.props.history.replace(direction, {
        ids: idTxn,
      });
      // this.props.history.replace("/finish-confirm", {
      //   txn_reff: confirm_payment.data[0].txn_reff,
      // });
    }
  }

  componentWillUnmount() {
    this.props.confirm_payment_data({});
  }

  onChange = (e, id) => {
    this.setState((prev) => ({
      ...prev,
      payment_proof: { ...prev.payment_proof, [id]: e },
    }));
  };

  onSubmit = () => {
    const { confirm_payment, bank_account } = this.props;
    const { payment_proof } = this.state;
    const { data } = confirm_payment;
    const params = {};
    data.forEach(({ id }, index) => {
      params[`payment[${index}][id]`] = id;
      params[`payment[${index}][account_from]`] = bank_account.id;
      params[`payment[${index}][proof]`] = payment_proof[id];
    });
    this.props.set_proof_props(params);
  };

  render() {
    const {
      confirm_payment,
      bank_account,
      classes,
      loading_stack,
    } = this.props;
    const { data } = confirm_payment || {};
    const { payment_proof } = this.state;
    const loading = processHelper.findProcess("txn-list", loading_stack);

    return (
      <TempMain title="PEMBAYARAN" showBottom={false}>
        {loading ? (
          <div className={classNames("cont-center", classes.contentArea)}>
            <CircularProgress />
          </div>
        ) : (
          <div className="px-4 pt-5 mb-3 margin-main">
            <BasicText className="fs-14 font-weight-bold mb-3">
              Silahkan lanjutkan konfirmasi pembayaran melalui bank transfer
              kamu di sini
            </BasicText>

            {Array.isArray(data) &&
              data.map(
                ({
                  id,
                  txn_reff,
                  nett_txn,
                  fr_amount,
                  amount_fee,
                  product,
                  payment,
                }) => (
                  <div key={txn_reff}>
                    {/* <OrderResult
                      value={nett_txn}
                      fee={amount_fee}
                      product={product}
                      detail={false}
                      className="mb-3"
                      mode="invoice"
                      radius="7px!important"
                    /> */}
                    <div className="card-custom mb-3">
                      <Grid container className={classes.contentHeader}>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          container
                          justify="space-between"
                        >
                          <p className="basic fs-12">
                            {parse(
                              textHelper.printProduct2Line(
                                product.fr_product_name
                              )
                            )}
                          </p>
                          <RenderAmount total={fr_amount} />
                        </Grid>
                      </Grid>
                      <div className={classes.boxAccount}>
                        <InputBank
                          title="REKENING ASAL"
                          value={bank_account?.account_number}
                        />
                        <InputBank
                          title="REKENING TUJUAN"
                          value={payment?.account_number}
                        />
                      </div>
                    </div>
                    <p className="basic fs-14 mb-2 ml-md-4 ml-3">
                      UPLOAD BUKTI TRANSFER
                    </p>
                    <BoxUpload
                      marginClass={classes.boxUpload}
                      onChange={(e) => this.onChange(e, id)}
                    />
                  </div>
                )
              )}

            <div className="w-100 text-center text-md-left">
              <ButtonContained
                radius="4px"
                text="KIRIM"
                className="px-5 padding-button style fs-14 font-weight-bold ml-md-4 ml-3"
                onClick={this.onSubmit}
                loading={processHelper.findProcess("set_proof", loading_stack)}
                disabled={Object.keys(payment_proof).length !== data?.length}
              />
            </div>
          </div>
        )}
        <DialogAlert resultName="set_proof" />
      </TempMain>
    );
  }
}

const mapState = ({ stateReducer, transactionReducer, profileReducer }) => {
  const { bank_account } = profileReducer;
  return {
    loading_stack: stateReducer.loading_stack,
    proof_result: transactionReducer.proof_result,
    confirm_payment: transactionReducer.confirm_payment_data,
    bank_account:
      bank_account[
        Math.max(
          bank_account.findIndex(({ is_priority }) => is_priority === 1),
          0
        )
      ],
  };
};

export default compose(
  connect(mapState, {
    set_proof_props,
    set_profile_data,
    gbl_act_lc,
    confirm_payment_data,
    get_acc_bank_props,
  }),
  withStyles(useStyles),
  withRouter
)(ConfirmPayment);
