import React from "react";
import BasicText from "../../../../atom/BasicText/BasicText";
import ButtonContained from "../../../../atom/Button/ButtonContained";
import IconRobo from "../../../../atom/IconRobo/IconRobo";
import { useHistory } from "react-router-dom";
import { useCallback } from "react";
import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import useLongPress from "../../../../../helper/useLongClick";

const useStyles = makeStyles({
  container: {
    padding: 17,
    paddingTop: 9.3,
    borderRadius: 4,
    marginBottom: 3,
    backgroundColor: "white",
    boxShadow: "1px 2px 1px rgba(0,0,0,0.1)",
    "& .icon-goals": {
      width: 46,
    },
    "& .contain-name": {
      padding: "0 7px",
    },
  },
  containerPorto: {
    padding: 17,
    paddingTop: 9.3,
    borderRadius: 8,
    backgroundColor: "white",
    boxShadow: "1px 2px 1px rgba(0,0,0,0.1)",
    "& .icon-goals": {
      width: 46,
    },
    "& .contain-name": {
      padding: "0 7px",
    },
  },
});

const defaultOptions = {
  shouldPreventDefault: true,
  delay: 200,
};

const CardUserGoals = ({ item, style }) => {
  const classes = useStyles();
  const {
    push,
    location: { pathname },
  } = useHistory();
  const {
    id,
    goal_name,
    fr_total_portofolio,
    fr_total_unrealized_gain,
    fr_total_unrealized_gain_percent,
    is_follow_robo,
    total_funds,
    goal,
    ref_goals_icon,
    monthly_amount,
    profile_risk_product,
  } = item;
  const summary = [
    { title: "NILAI SAAT INI", data: fr_total_portofolio },
    { title: "RETURN", data: fr_total_unrealized_gain },
    { data: fr_total_unrealized_gain_percent, classData: "color-primary" },
  ];
  const longPressEvent = useLongPress(
    () => {},
    () => push(`/portofolio-goals?goals=${id}`),
    defaultOptions
  );

  const handleTopUp = useCallback(() => {
    let des = "subscript";
    let param = {};
    if (goal.product_variety_code === "CG") des = "smart-plan";
    else if (!Number(is_follow_robo)) {
      des = "produk";
      param = { nextParam: { user_goal_id: id } };
    } else
      param = {
        user_goal_id: id,
        is_follow_robo: 1,
        total_amount: monthly_amount,
        products: profile_risk_product.reduce(
          (res, { product_code, allocation }) => ({
            ...res,
            [product_code]: { amount: allocation },
          }),
          {}
        ),
      };
    push(`/${des}`, param);
  }, [
    goal.product_variety_code,
    id,
    is_follow_robo,
    monthly_amount,
    profile_risk_product,
    push,
  ]);

  const isPortoPage = pathname === "/portofolio";
  return (
    <div
      className={isPortoPage ? classes.containerPorto : classes.container}
      style={{ minWidth: 282, ...style }}
      id={`${id}-goal`}
    >
      <div
        className={classNames("is-link", isPortoPage && "mt-1")}
        {...longPressEvent}
      >
        <div
          className={classNames(
            "d-flex align-items-center",
            isPortoPage ? "mb-3" : "mb-2"
          )}
          style={{ height: 48 }}
        >
          <img
            onDragStart={(e) => e.preventDefault()}
            className="icon-goals"
            src={ref_goals_icon.icon_web_path || ref_goals_icon.icon_path}
            alt=""
          />
          <div className="contain-name">
            <p className="basic font-weight-bold text-uppercase">{goal_name}</p>
            <p className="basic fs-10 gray-4">{total_funds} Reksa Dana</p>
          </div>
          {is_follow_robo ? <IconRobo className="ml-auto" /> : undefined}
        </div>

        <div className="d-flex justify-content-between align-items-end">
          {summary.map(({ title, data, classData }, index) => (
            <div
              key={title || index}
              className={index === summary.length - 1 ? "text-right" : "mr-3"}
            >
              <BasicText className="fs-12 mb-1" colorLevel="light">
                {title}
              </BasicText>
              <BasicText className={classData}>{data}</BasicText>
            </div>
          ))}
        </div>
      </div>
      <div className="cont-center mt-3">
        {!isPortoPage && (
          <ButtonContained
            id={`topup-${id}-goal`}
            className="py-1"
            classes={{ label: "mx-4 fs-10 font-weight-bold" }}
            text="TOP UP"
            onClick={handleTopUp}
            radius="4px"
          />
        )}
      </div>
    </div>
  );
};

CardUserGoals.defaultProps = {
  style: {},
};

export default CardUserGoals;
