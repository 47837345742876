import React from "react";
import { Snackbar } from "@material-ui/core";
import { connect } from "react-redux";
import processHelper from "../../../helper/processHelper";
import { remove_result_l } from "../../../config/redux/actions/state";
import PropTypes from "prop-types";

const mapState = (state) => {
  return {
    result_stack: state.stateReducer.result_stack,
  };
};

const SnackAlert = (props) => {
  const { name, result_stack, remove_result_l, alertAppear } = props;
  const [state, setState] = React.useState({
    message: "",
    open: false,
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState({ ...state, open: false });
  };
  React.useEffect(() => {
    let data = processHelper.findResult(name, result_stack);
    console.log("snackAlert", data, name, result_stack);
    if (Object.keys(data).length > 0) {
      if (
        (alertAppear === "error" && data.type === "error") ||
        (alertAppear === "success" && data.type === "success") ||
        alertAppear === "both"
      ) {
        setState((state) => {
          return { ...state, message: data.msg, open: true };
        });
      }
      remove_result_l(data.name);
    }
  }, [result_stack, name, remove_result_l, alertAppear]);
  return (
    <Snackbar
      open={state.open}
      onClose={handleClose}
      autoHideDuration={5000}
      message={state.message}
    />
  );
};

SnackAlert.propTypes = {
  name: PropTypes.any,
  alertAppear: PropTypes.string,
};

SnackAlert.defaultProps = {
  name: "",
  alertAppear: "error",
};

export default connect(mapState, { remove_result_l })(SnackAlert);
