import * as types from "../actions/types";

const initial = {
  portofolio_list: [],
  summary: {},
  porto_detail: [],
  statement: [],
};

const initial_backup = { ...initial };

const portoReducer = (state = initial, action) => {
  switch (action.type) {
    case types.SET_PORTOFOLIO_LIST:
      return { ...state, portofolio_list: action.payload };
    case types.SET_PORTOFOLIO_SUMMARY:
      return { ...state, summary: action.payload };
    case types.PORTO_DETAIL:
      return { ...state, porto_detail: action.payload };
    case types.STATEMENT:
      return { ...state, statement: action.payload };
    case types.LOG_OUT:
      return { ...state, ...initial_backup };
    default:
      return state;
  }
};
export default portoReducer;
