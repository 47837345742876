import React from "react";
import BasicText from "../../atom/BasicText/BasicText";
import parse from "html-react-parser";
import { Skeleton } from "@material-ui/lab";
const PolicyAndGoals = (props) => {
  const { product } = props;
  const { tujuan, kebijakan } = product || {};
  return Object.keys(product).length === 0 ? (
    <div>
      <div className="mb-3">
        <Skeleton width="117px" height="18px" className="mb-3" />
        <Skeleton width="100%" height="18px" />
        <Skeleton width="100%" height="18px" />
        <Skeleton width="100%" height="18px" />
      </div>
      <div className="mb-4">
        <Skeleton width="117px" height="18px" className="mb-3" />
        <Skeleton width="100%" height="18px" />
        <Skeleton width="100%" height="18px" />
        <Skeleton width="100%" height="18px" />
      </div>
    </div>
  ) : (
    <>
      {(tujuan && tujuan !== "0") && (
        <div className="mb-3">
          <BasicText colorLevel="light" className="fs-14 font-weight-bold mb-3">
            Tujuan Investasi
          </BasicText>
          <div className="gray-5 fs-12">{parse(tujuan)}</div>
        </div>
      )}
      {(kebijakan && kebijakan !== "0") && (
        <div className="mb-4">
          <BasicText colorLevel="light" className="fs-14 font-weight-bold mb-3">
            Kebijakan Investasi
          </BasicText>
          <div className="gray-5 fs-12">{parse(kebijakan)}</div>
        </div>
      )}
    </>
  );
};

export default PolicyAndGoals;
