import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Close from "@material-ui/icons/Close";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ButtonContained from "../../atom/Button/ButtonContained";
import classNames from "classnames";
import useStyles from "./useStyles";
const { useState, useCallback } = React;

const months = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MEI",
  "JUN",
  "JUL",
  "AGU",
  "SEP",
  "OKT",
  "NOV",
  "DES",
];

const CalenderPeriodic = ({
  onSubmit = () => {},
  initial,
  onClose = () => {},
}) => {
  const classes = useStyles();
  const [state, setState] = useState(() => {
    const currentDate = new Date();
    const date = new Date(initial);
    return {
      minYear: currentDate.getFullYear(),
      minMonth: currentDate.getMonth() + 1,
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };
  });
  const isThisYear = state.year === state.minYear;

  const handleYear = useCallback((state) => {
    setState((prev) => {
      const newState = { ...prev, year: prev.year - 1 };
      if (state === "+") newState.year = prev.year + 1;
      if (
        newState.year === newState.minYear &&
        newState.month < newState.minMonth
      ) {
        newState.month = newState.minMonth;
      }
      return newState;
    });
  }, []);

  const handleMonth = useCallback(
    (month) => setState((prev) => ({ ...prev, month })),
    []
  );

  const handleSubmit = useCallback(() => {
    const { month, year } = state;
    onSubmit(`${month}/01/${year}`);
  }, [onSubmit, state]);

  return (
    <div className="pt-4 mt-4 mb-5 position-relative">
      <IconButton className={classes.buttonClose} onClick={onClose}>
        <Close />
      </IconButton>
      <p className="basic fs-14 mb-2">Pilih Batas Waktu</p>
      <div
        className={classNames(
          "d-flex align-items-center justify-content-between",
          classes.contentCal
        )}
      >
        <IconButton
          className="p-1"
          onClick={() => handleYear("-")}
          disabled={isThisYear}
        >
          <ChevronLeft color="primary" />
        </IconButton>

        <p className="basic fs-14">{state.year}</p>
        <IconButton className="p-1" onClick={() => handleYear("+")}>
          <ChevronRight color="primary" />
        </IconButton>
      </div>
      <Grid container className="mt-2 mb-4" spacing={1}>
        {months.map((month, index) => {
          const position = index + 1;
          return (
            <Grid item xs={2} key={month}>
              <Button
                className={classNames(
                  classes.contentCal,
                  "rounded-0 fs-10 w-100 month",
                  position === state.month && "active"
                )}
                disabled={isThisYear && position < state.minMonth}
                onClick={() => handleMonth(position)}
              >
                {month}
              </Button>
            </Grid>
          );
        })}
      </Grid>
      <div className="cont-center pt-4">
        <ButtonContained text="SIMPAN" radius="4px" onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default CalenderPeriodic;
