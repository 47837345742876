import LoginPage from "./LoginPage";
import MainPage from "./MainPage";
import productPage from "./ProductPage";
import OrderToBuy from "./OrderToBuy";
import Payment from "./Payment";
import InvoicePage from "./InvoicePage";
import ConfirmPayment from "./ConfirmPayment";
import FinishOrder from "./FinishOrder";
import TxnPage from "./TxnPage";
import DetailTxnPage from "./DetailTxnPage";
import ProfilePage from "./ProfilePage";
import IdentityPage from "./IdentityPage";
import SettingPage from "./SettingPage";
import ChangePage from "./ChangePage";
import OtpInputPage from "./Otp/OtpInputPage";
import OrderToSell from "./OrderToSell";
import PortoPage from "./PortoPage";
import DetailProduct from "./DetailProduct";
import SimulasiProduct from "./SimulasiProduct";
import HelpCenterPage from "./HelpCenterPage";
import SmartPlanPage from "./SmartPlanPage";
import OrderToSwitch from "./OrderToSwitch";
import ProfileRiskPage from "./ProfileRiskPage";
import RiskSurveyPage from "./RiskSurveyPage";
import RiskResultPage from "./RiskResultPage";
import GoalKamuPage from "./GoalKamuPage";
import RegisterInputPhone from "./Register/Regis1";
import Regis2 from "./Register/Regis2";
import RegisterIdentitas from "./Register/RegisterIdentitas";
import ArticlePage from "./ArticlePage";
import KnowledgeCenterPage from "./KnowledgePage";
import ComingSoonPage from "./ComingSoonPage";
import ForgotPassPage from "./ForgotPass";
import RegisterProfil from "./Register/RegisterProfil";
import RegisSignature from "./Register/RegisSignature";
import OnBoarding from "./OnBoarding";
import EStatementPage from "./EStatementPage";
import TipsKeamananPage from "./TipsKeamananPage";
import NotificationPage from "./NotificationPage";
import RateUsPage from "./RateUsPage";
import ModifyPlannerPage from "./ModifyPlannerPage";
import PortoGoalsPage from "./PortoGoalsPage";
import SwitchPortoPage from "./SwitchPortoPage";
import SuccessSwitchPortoPage from "./SuccessSwitchPortoPage";
import AutoDebetFilePage from "./AutoDebetFilePage";
import DetailPeriodicPage from "./DetailPeriodicPage";
import RiskPage from "./RiskPage";
import RiskChangePage from "./RiskChangePage";
import StopAutoDebetFile from "./StopAutoDebetFile";
import VoucherMerchantPage from "./VoucherMerchantPage";
import VoucherMerchantDetailPage from "./VoucherMerchantDetailPage";
import VoucherMerchantScanPage from "./VoucherMerchantScanPage";
import GlossaryPage from "./GlossaryPage";
import PromoPage from "./PromoPage";
import PromoDetailPage from "./PromoDetailPage";
import DocumentPage from "./DocumentPage";
import UltraVoucherLoadPage from "./UltraVoucherLoadPage";
import DeleteAccountPage from "./DeleteAccountPage";
import ProfileUpdateMotherName from "./ProfileUpdateMotherName";

export default {
  LoginPage,
  MainPage,
  productPage,
  Payment,
  InvoicePage,
  ConfirmPayment,
  FinishOrder,
  TxnPage,
  DetailTxnPage,
  ProfilePage,
  IdentityPage,
  SettingPage,
  ChangePage,
  OtpInputPage,
  OrderToBuy,
  OrderToSell,
  OrderToSwitch,
  PortoPage,
  DetailProduct,
  SimulasiProduct,
  HelpCenterPage,
  ProfileRiskPage,
  RiskSurveyPage,
  RiskResultPage,
  SmartPlanPage,
  GoalKamuPage,
  RegisterInputPhone,
  Regis2,
  RegisterIdentitas,
  ArticlePage,
  KnowledgeCenterPage,
  ComingSoonPage,
  ForgotPassPage,
  RegisterProfil,
  RegisSignature,
  OnBoarding,
  EStatementPage,
  TipsKeamananPage,
  NotificationPage,
  RateUsPage,
  ModifyPlannerPage,
  PortoGoalsPage,
  SwitchPortoPage,
  SuccessSwitchPortoPage,
  AutoDebetFilePage,
  DetailPeriodicPage,
  RiskPage,
  RiskChangePage,
  StopAutoDebetFile,
  VoucherMerchantPage,
  VoucherMerchantDetailPage,
  VoucherMerchantScanPage,
  GlossaryPage,
  PromoPage,
  PromoDetailPage,
  DocumentPage,
  UltraVoucherLoadPage,
  DeleteAccountPage,
  ProfileUpdateMotherName,
};
