import React, { memo, useCallback, useEffect, useMemo, useRef } from "react";
import TempMain from "../template/TempMain";
import { IconButton, makeStyles } from "@material-ui/core";
import ButtonOutline from "../atom/Button/ButtonOutline";
import classNames from "classnames";
import TextInput from "../molecule/MaterialInput/TextInput";
import SearchIcon from "@material-ui/icons/Search";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BasicText from "../atom/BasicText/BasicText";
import { debounce } from "lodash";
import { set_faq } from "../../config/redux/actions/option";
import CloseIcon from "@material-ui/icons/Close";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  container: {
    zIndex: 1,
    position: "fixed",
    overflow: "auto",
    backgroundColor: "white",
    top: 70,
    bottom: 0,
    width: "100%",
    padding: 24,
    "&>div:first-child": {
      overflowX: "auto",
      "&::-webkit-scrollbar": {
        width: 0,
        height: 0,
      },
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: 250,
    },
  },
  search: {
    position: "sticky",
    left: 0,
    [theme.breakpoints.down("lg")]: {
      marginBottom: 24,
    },
  },
  inputSearch: {
    minWidth: 180,
    width: "100%",
    borderRadius: 0,
    "&.MuiOutlinedInput-adornedStart": {
      paddingLeft: 7,
    },
    "& input": {
      padding: "8px",
      fontSize: 12,
      color: theme.palette.secondary.light,
    },
  },
  btnGlossary: {
    minWidth: 29,
    height: 29,
    padding: "0 0.25rem",
    fontSize: 14,
    fontWeight: 700,
    marginRight: 6,
    "&.big": {
      minWidth: 50,
    },
    "&.active": {
      backgroundColor: "#FDF5F2",
    },
  },
}));

const GlossaryPage = memo((props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const letter = useRef(
    Array(26)
      .fill("")
      .map((_, i) => (i + 10).toString(36).toUpperCase())
  ).current;
  const allData = useSelector((state) => state.optionReducer.glossary);
  const [search, setSearch] = useState("az");
  const [type, setType] = useState("az");

  const onSearch = useCallback(debounce(setSearch, 300), []);

  const data = useMemo(() => {
    if (search === "az" || !search) {
      return allData;
    }
    if (search.length === 1) {
      return allData.filter(
        (el) => el.title[0].toLowerCase() === search.toLowerCase()
      );
    }
    return allData?.filter(
      (el) => el.title.toLowerCase().indexOf(search) !== -1
    );
  }, [allData, search]);

  const onClickAlphabet = useCallback((e) => {
    setSearch(e);
    setType(e);
  }, []);

  useEffect(() => {
    dispatch(set_faq("glossary"));
  }, [dispatch]);

  return (
    <>
      <TempMain
        title="GLOSSARY"
        page="profile"
        backgroundColor="#fff"
        showBottom={false}
        bottomNavValue={4}
        data={{ menuValue: 6 }}
      />
      <div className={classes.container}>
        <div className="text-right p-3 d-none d-lg-block">
          <IconButton
            id="button-close"
            edge="start"
            color="inherit"
            onClick={props.history.goBack}
            aria-label="close"
          >
            <CloseIcon className="color-secondary" />
          </IconButton>
        </div>

        <div
          className="d-flex flex-lg-row flex-column-reverse mb-5"
          style={{ overflowX: "auto", overflowY: "hidden" }}
        >
          <div className="d-flex mr-2" style={{ height: 35 }}>
            <ButtonOutline
              id="menu-a-z"
              text="A-Z"
              value="az"
              className={classNames(
                classes.btnGlossary,
                "big",
                search === "az" && "active"
              )}
              onClick={onClickAlphabet}
            />
            {letter.map((el, id) => (
              <ButtonOutline
                id={`menu-${el}`}
                text={el}
                key={id}
                value={el}
                className={classNames(
                  classes.btnGlossary,
                  search === el && "active"
                )}
                onClick={onClickAlphabet}
              />
            ))}
          </div>
          <div className={classes.search}>
            <TextInput
              key={type}
              id="search-glossary"
              inputClass={classes.inputSearch}
              placeholder="Cari"
              variant="outlined"
              startIcon={() => <SearchIcon className="fs-18 gray-5" />}
              onChange={onSearch}
            />
          </div>
        </div>
        {data?.length ? (
          data.map((el, id) => (
            <div
              className="mb-3"
              key={id}
              id={`glossary-${el.title?.replace(/ /g, "-").toLowerCase()}`}
            >
              <BasicText className="fs-12 font-weight-semibold mb-1">
                {el.title}
              </BasicText>
              <BasicText className="fs-12">{el.content}</BasicText>
            </div>
          ))
        ) : (
          <BasicText className="fs-12 font-weight-semibold">
            Data tidak ditemukan
          </BasicText>
        )}
      </div>
    </>
  );
});

export default withRouter(GlossaryPage);
