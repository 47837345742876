import { Card, Divider, Grid, makeStyles } from "@material-ui/core";
import { KeyboardArrowDownRounded } from "@material-ui/icons";
import classNames from "classnames";
import parse from "html-react-parser";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ceklisMovePorto from "../../../assets/icons/ceklisMovePorto.svg";
import stateCallApi from "../../../config/API/stateCallApi";
import { gbl_act_lc } from "../../../config/redux/actions/state";
import processHelper from "../../../helper/processHelper";
import textHelper from "../../../helper/textHelper";
import BasicText from "../../atom/BasicText/BasicText";
import ButtonContained from "../../atom/Button/ButtonContained";
import useStateFetch from "../../atom/hooks/useStateFetch";
import DialogAlert from "../../molecule/Alert/DialogAlert";
import CardHeaderGoal from "../../molecule/CardHeaderGoal";
import TextInput from "../../molecule/MaterialInput/TextInput";
import PortoSummaryNumber from "../../molecule/Porto/PortoSummaryNumber";
import RiskIndicator from "../../molecule/Product/RiskIndicator";
import RowTotal from "../../molecule/Sell/RowTotal";
import InputTxn from "../../organism/Order/InputTxn/InputTxn";
import GenerateHorizontal from "../../template/generate/GenerateHorizontal";
import TempMain from "../../template/TempMain";
import useStyles from "./useStyles";
const { useEffect } = React;

function propsImgGoal(ref_goals_icon) {
  return {
    alt: ref_goals_icon?.name,
    src: ref_goals_icon?.icon_web_path || ref_goals_icon?.icon_path,
    onDragStart: (e) => e.preventDefault(),
  };
}

const GoalItem = ({ item }) => {
  const { ref_goals_icon, goal_name } = item;
  return (
    <div id={`${item.id}-goal`} className="text-center is-link">
      <img alt="" {...propsImgGoal(ref_goals_icon)} style={{ width: 46 }} />
      <p className="basic fs-10">{goal_name}</p>
    </div>
  );
};

const PortoConclusionSwitch = ({ data, marginClass }) => {
  const useStyles2 = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      marginBottom: 8,
      "& .boxLeft": {
        flex: 1,
        marginRight: 17,
        minWidth: "30%",
      },
      "& .boxRight": {
        minWidth: "50%",
        justifyContent: "space-between",
        "& .contentBox": {
          marginRight: 9,
          marginLeft: 9,
        },
        "& .btnMore": {
          marginRight: -21,
          marginTop: -8,
          alignSelf: "flex-start",
        },
      },
    },
  }));
  const {
    fr_value,
    fr_initial_amount,
    fr_unrealized_gain,
    fr_unrealized_gain_percent,
    fr_unit,
    product,
  } = data;
  const summary_txn = useMemo(
    () => [
      {
        title: "Nilai Saat Ini",
        children: fr_value,
        classesChildren: "color-primary fs-14 fw-600",
      },
      { title: "Investasi Awal", children: fr_initial_amount },
      { title: "Total Earnings", children: fr_unrealized_gain },
      {
        title: "Return",
        children: fr_unrealized_gain_percent,
        classesChildren: "color-primary fs-14 fw-600",
      },
    ],
    [
      fr_initial_amount,
      fr_unrealized_gain,
      fr_unrealized_gain_percent,
      fr_value,
    ]
  );
  const classes2 = useStyles2();

  return (
    <Card
      className={classNames(
        marginClass,
        classes2.root,
        "d-block br-8 p-3 shadow-card"
      )}
      style={{ height: "100%" }}
    >
      <div className={"d-flex justify-content-between align-items-center mb-3"}>
        <BasicText className="fs-12">
          {Object.keys(data).length > 0 &&
            parse(
              textHelper.printProduct2Line(
                `${data.product.display_name} (${data.product.display_code})`
              )
            )}
        </BasicText>
        <div>
          <BasicText className={classNames("fs-10 gray-4 mb-2 text-left")}>
            Risiko Produk
          </BasicText>
          <RiskIndicator
            value={product?.product_risk || 0}
            classCont={"mt-2"}
            height={8}
          />
        </div>
      </div>
      <div>
        {summary_txn.map(
          ({ title, children, ...otherProps }, id) =>
            id < 4 && (
              <RowTotal title={title} key={id} {...otherProps}>
                {children}
              </RowTotal>
            )
        )}
      </div>
      <hr className="mb-0" style={{ borderTop: "2px dotted #666666" }} />
      <RowTotal title={"Total Unit"}>{fr_unit}</RowTotal>
    </Card>
  );
};

const SwitchPortoPage = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loading = useSelector((state) =>
    processHelper.findProcess(state.stateReducer.loading_stack, "goal-sw")
  );
  const [mode, setMode] = useState(false);
  const [{ amount, units, isAll }, setState] = useState({
    amount: 0,
    units: 0,
    isAll: 0,
  });
  const [selectGoal, setSelectGoal] = useState(undefined);
  const { location, replace, goBack } = useHistory();
  const [goal_user] = useStateFetch([], (callback) => {
    dispatch(
      gbl_act_lc({
        ...stateCallApi.goals_user_sub,
        callback: (data) =>
          callback(data.filter((el) => location.state?.user_goal_id !== el.id)),
      })
    );
  });

  useEffect(() => {
    if (!location.state) goBack();
  }, [goBack, location.state]);

  const handleMode = useCallback(() => setMode((prev) => !prev), []);

  const handleSelectGoal = useCallback(
    (id) => {
      setSelectGoal(id);
      handleMode();
    },
    [handleMode]
  );

  const data_goal_select = useMemo(
    () =>
      goal_user[
        goal_user?.findIndex(({ id }) => Number(id) === Number(selectGoal)) ?? 0
      ],
    [goal_user, selectGoal]
  );

  const {
    id,
    user_goal_id,
    goal,
    fr_value,
    fr_unrealized_gain,
    fr_unrealized_gain_percent,
    product,
    value,
    fr_unit,
    ref_goals_icon,
    goal_name,
    target_amount,
    fr_target_date,
  } = location.state || {};

  const handleAmount = useCallback(
    (amount) => {
      setState((state) => ({
        ...state,
        amount,
        units: parseFloat(
          Number(amount) / Number(product.nav_per_unit)
        ).toFixed(2),
      }));
    },
    [product]
  );

  const handleIsAll = (isAll) => setState((state) => ({ ...state, isAll }));

  const handleSubmit = useCallback(() => {
    const params = {
      src_portofolio_sub_id: id,
      dst_user_goal_id: selectGoal,
    };
    if (isAll) params.is_all = 1;
    else params.units = units;
    dispatch(
      gbl_act_lc({
        ...stateCallApi.goals_user_sw,
        params,
        callback: () =>
          replace("/switch-porto/success", { goal_id: selectGoal }),
      })
    );
  }, [dispatch, id, isAll, replace, selectGoal, units]);

  const isShowGoals = mode || selectGoal;

  return (
    <TempMain title="PINDAH PORTOFOLIO">
      <div className={classes.root}>
        <Card className="mb-4 p-4 shadow-card" style={{ height: 250 }}>
          <div style={{ width: 260 }}>
            <CardHeaderGoal
              className="mb-2"
              data={{
                id: user_goal_id,
                ref_goals_icon,
                goal_name,
                target_amount,
                fr_target_date,
                goal,
              }}
              isShow={false}
            />
            <PortoSummaryNumber
              title="0"
              data={{
                fr_total_portofolio: fr_value,
                fr_total_unrealized_gain: fr_unrealized_gain,
                fr_total_unrealized_gain_percent: fr_unrealized_gain_percent,
                fr_smartplan_value: "Rp0",
                is_follow_robo: 0,
                fr_total_units: fr_unit,
                smartplan_value: 0,
              }}
            />
          </div>
        </Card>

        <Grid container spacing={3}>
          <Grid item xs={4}>
            <PortoConclusionSwitch data={location.state} marginClass="w-100" />
          </Grid>
          <Grid item xs={4}>
            <Card className="p-3 br-8 shadow-card" style={{ height: "100%" }}>
              <p className="basic fs-14 font-weight-bold mb-2">Pindahkan ke</p>
              <div
                id="select-goal"
                className="select-goal-button mb-3 is-link"
                onClick={handleMode}
              >
                <p className="basic fs-10 gray-4">Pilih Portofolio</p>
                <KeyboardArrowDownRounded className="icon" />
              </div>
              {isShowGoals &&
                (mode ? (
                  <GenerateHorizontal
                    data={goal_user}
                    render={GoalItem}
                    onClickItem={handleSelectGoal}
                    options={{ spacing: 3 }}
                  />
                ) : (
                  <div className="d-flex align-items-center">
                    <img
                      alt=""
                      {...propsImgGoal(data_goal_select?.ref_goals_icon)}
                      className="mr-2"
                      style={{ width: 46 }}
                    />
                    <div className="flex">
                      <p className="basic font-weight-semibold">
                        {data_goal_select.goal_name}
                      </p>
                    </div>
                    <div className="icon-check-border">
                      <img src={ceklisMovePorto} alt="" />
                    </div>
                  </div>
                ))}
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card
              className={classNames(
                "p-3 br-8 shadow-card",
                classes.txtInputUnit
              )}
              style={{ height: "100%" }}
            >
              <InputTxn
                id="move"
                min={product?.min_red}
                max={value}
                all={value}
                value={amount}
                onIsAll={handleIsAll}
                onChange={handleAmount}
                recommend={[100000, 500000, 1000000, 2000000]}
                title="Pindahkan Unit Senilai"
                labelCheck="Pindahkan Semua"
                BtnRecProps={{
                  style: {
                    height: 22,
                    marginTop: 30,
                  },
                }}
                CheckInputProps={{
                  labelClass: "fs-10 gray-3",
                }}
                SliderWithInputProps={{
                  xsTitle: 7,
                  xsInput: 5,
                }}
              >
                <Grid container className="align-items-center">
                  <Grid
                    item
                    xs={7}
                    container
                    className="pr-3 justify-content-end"
                  >
                    <BasicText className="basic fs-10">Jumlah Unit</BasicText>
                  </Grid>
                  <Grid item xs={5} className={classes.txtInputUnit}>
                    <TextInput
                      disabled
                      value={units}
                      onChange={() => {}}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </InputTxn>
            </Card>
          </Grid>
        </Grid>
        <Divider className="my-5" />
        <div
          className={classNames(
            "d-flex justify-content-center",
            classes.btnMove
          )}
        >
          <ButtonContained
            id="lanjut-btn"
            loading={loading}
            disabled={!selectGoal || !Number(units)}
            text="PINDAHKAN"
            onClick={handleSubmit}
            className="rounded-3 fs-14 fw-600"
            style={{ width: 155, height: 48 }}
          />
        </div>
      </div>
      <DialogAlert resultName="goal-sw" alertAppear="error" />
    </TempMain>
  );
};

SwitchPortoPage.defaultProps = {};

export default SwitchPortoPage;
