import { Grid, Toolbar, makeStyles, IconButton } from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";
import { useLocation } from "react-router-dom";
// import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import icon from "../../../assets/img/icon/profile/Profile.svg";
import color from "../../../assets/theme/color";
import BasicText from "../../atom/BasicText/BasicText";
import ButtonContained from "../../atom/Button/ButtonContained";
import ButtonOutline from "../../atom/Button/ButtonOutline";
import { memo } from "react";
import { useMemo } from "react";
import classNames from "classnames";
import { ChevronLeft } from "@material-ui/icons";

const useStyles = makeStyles({
  profileRisk: {
    backgroundColor: color.orange_2,
    borderRadius: 6,
    width: "fit-content",
    padding: "2px 6px 3px 6px",
  },
  profileRiskMoz: {
    width: "-moz-fit-content",
  },
  header: { zIndex: 10 },
});

const DesktopHeader = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const isHelpCenter = useMemo(() => location.pathname === "/help-center", [
    location.pathname,
  ]);
  const {
    title,
    name,
    profile_risk,
    isLogin,
    onClick,
    withBack,
    onBack,
  } = props;
  const handleClick = (e) => {
    onClick(e);
  };
  return (
    <div className={classNames("header", classes.header)}>
      <Toolbar className="w-100 px-4 py-3" style={{ maxWidth: 1146 }}>
        <div
          className="d-flex justify-content-between w-100"
        >
          <div className="d-flex align-align-items-center">
            {withBack && (
              <IconButton id="header-back" onClick={onBack} className="p-1 mr-2 ml-n3">
                <ChevronLeft
                  className="color-white"
                  style={{ width: 32, height: 32 }}
                />
              </IconButton>
            )}

            <BasicText id="header-title" className="fs-16 text-white my-auto font-weight-medium">
              {title}
            </BasicText>
          </div>

          <div
            style={{
              display: "flex",
              minWidth: 360,
              justifyContent: "space-between",
            }}
          >
            {isLogin === 1 ? (
              <div
                id="header-profile"
                className="d-flex is-link"
                onClick={() => handleClick("/profil")}
              >
                <img src={icon} alt="" />
                <div className="ml-3">
                  {name && profile_risk ? (
                    <>
                      <BasicText className="fs-11 font-weight-bold text-white mb-1">
                        {name}
                      </BasicText>
                      <BasicText
                        className={classNames(
                          "fs-10 text-white mb-1",
                          classes.profileRisk,
                          classes.profileRiskMoz
                        )}
                      >
                        {`Investor ${profile_risk}`}
                      </BasicText>
                    </>
                  ) : (
                    <>
                      <Skeleton
                        width={150}
                        height={16}
                        className="mb-1"
                        style={{ backgroundColor: "rgba(255,255,255,0.3)" }}
                      />
                      <Skeleton
                        width={150}
                        height={16}
                        className="mb-1"
                        style={{ backgroundColor: "rgba(255,255,255,0.3)" }}
                      />
                    </>
                  )}
                </div>
              </div>
            ) : (
              <Grid
                container
                spacing={2}
                className="mr-2"
                alignItems="center"
                direction="row"
              >
                <Grid item xs className="text-center">
                  <ButtonOutline
                    text="REGISTRASI"
                    borderColor="common"
                    className="w-100 fs-10"
                    radius="5px"
                    onClick={() => handleClick("/register")}
                  />
                </Grid>
                <Grid item xs className="text-center">
                  <ButtonContained
                    text="LOGIN "
                    className="my-auto fs-10 w-100 text-white"
                    backgroundColor="#fff"
                    transparent={true}
                    onClick={() => handleClick("/masuk")}
                    radius="5px"
                  />
                </Grid>
              </Grid>
            )}
            <div className="d-flex my-auto">
              {/* <SearchIcon className="text-white fs-24 mr-3" /> */}
              {!isHelpCenter && (
                <HelpOutlineIcon
                  id="button-help-center"
                  className="text-white fs-24 is-link"
                  onClick={() => handleClick("/help-center")}
                />
              )}
            </div>
          </div>
        </div>
      </Toolbar>
    </div>
  );
};

export default memo(DesktopHeader);
