import classNames from "classnames";
import React from "react";
import textHelper from "../../../../helper/textHelper";
import BasicText from "../../../atom/BasicText/BasicText";

const SellResult = ({ value, fee }) => {
  const base = [
    {
      title: "Komisi Penjualan",
      value: "Gratis",
      className: {
        title: "fs-12 gray-3",
        value: "fs-14 fw-600 color-primary",
      },
    },
    {
      title: "Perkiraan Biaya Transfer",
      value: textHelper.printMoney(fee),
      className: {
        title: "fs-12 gray-3",
        value: "fs-14 gray-3",
      },
    },
    {
      title: "Cashback Biaya Transfer",
      value: textHelper.printMoney(fee),
      className: {
        title: "fs-12 gray-4",
        value: "fs-14 gray-4",
      },
    },
  ];
  return (
    <div className="card-custom mb-3">
      {value
        ? base.map((el, id) => (
            <div
              key={id}
              className="d-flex justify-content-between mb-1"
              style={{ width: "45%" }}
            >
              <BasicText className={el.className.title}>{el.title}</BasicText>
              <BasicText className={el.className.value}>{el.value}</BasicText>
            </div>
          ))
        : null}
      <div
        className={classNames(
          "d-flex justify-content-between",
          value && "mt-3"
        )}
        style={{ width: "45%" }}
      >
        <BasicText className="fs-12 fw-700 gray-3">
          Perkiraan Nilai Penjualan
        </BasicText>
        <BasicText className="fs-14 fw-600 color-primary">
          {textHelper.printMoney(value)}
        </BasicText>
      </div>
    </div>
  );
};

export default SellResult;
