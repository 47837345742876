import React from "react";
import BasicText from "../../atom/BasicText/BasicText";
import Countdown from "../../molecule/Countdown/Countdown";
import { Grid, CircularProgress } from "@material-ui/core";
import OtpInputNumber from "../../molecule/Otp/OtpInputNumber";
import BasicButton from "../../atom/Button/BasicButton";
import { useSelector } from "react-redux";
import processHelper from "../../../helper/processHelper";
import PropTypes from "prop-types";
const OtpInput = (props) => {
  const [state, setState] = React.useState({
    timeout: false,
  });
  const { onSubmit, onResendClick, processName, countState, number } = props;
  const loading_stack = useSelector(
    (state) => state.stateReducer.loading_stack
  );
  const otpReff = React.useRef(null);
  const handleClick = () => {
    onSubmit(otpReff.current.getValue());
  };
  const handleTimout = (e) => {
    setState((state) => ({ ...state, timeout: e }));
  };
  const handleResend = () => {
    onResendClick();
  };
  return (
    <div className="d-flex flex-column h-50vh justify-content-between">
      <div>
        <BasicText className="mb-1 color-secondary fs-14 font-weight-bold">
          Masukkan Kode OTP
        </BasicText>
        <BasicText className="mb-1 color-secondary fs-14">
          KODE OTP DIKIRIM MELALUI WHATSAPP KE NOMOR {number}
        </BasicText>
        <Grid container className="mt-4 mb-3" spacing={2}>
          <Grid item xs={6}>
            <OtpInputNumber ref={otpReff} />
          </Grid>
          <Grid item xs={6} className="pl-4">
            {processHelper.findProcess("resend_otp", loading_stack) ? (
              <CircularProgress size={15} />
            ) : (
              <Countdown
                countTime={countState ? 120 : 0}
                className="fs-14"
                onTimeout={handleTimout}
              />
            )}
          </Grid>
        </Grid>
        {state.timeout && (
          <p
            className="mb-1 fs-12 text-underline gray-5"
            onClick={handleResend}
          >
            KIRIM ULANG KODE OTP
          </p>
        )}
      </div>
      <div className="w-100 px-4 text-center">
        <BasicButton
          text="VERIFIKASI"
          className="fs-14 font-weight-bold py-3 px-4"
          onClick={handleClick}
          loading={processHelper.findProcess(processName, loading_stack)}
        />
      </div>
    </div>
  );
};

OtpInput.propTypes = {
  onSubmit: PropTypes.func,
  onResendClick: PropTypes.func,
  processName: PropTypes.any,
  countState: PropTypes.bool,
};

export default OtpInput;
