import React, { useState, useCallback, memo } from "react";
import SingleOTPInput from "./SingleOTPInput";
import { Grid } from "@material-ui/core";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";

const OTPInput = forwardRef(
  (
    {
      length,
      isNumberInput,
      autoFocus,
      disabled,
      onChange,
      inputClassName,
      inputStyle,
      id,
      ...other
    },
    ref
  ) => {
    const [active, setActive] = useState(0);
    const [value, setValue] = useState(Array.from(Array(length), (_) => ""));

    useImperativeHandle(ref, () => ({ resetOTP }));

    function resetOTP() {
      setValue(Array.from(Array(length), (_) => ""));
      onChange("");
    }

    // Helper to return OTP from inputs
    const handleOtpChange = useCallback(
      (otp) => {
        const otpValue = otp.join("");
        onChange(otpValue);
      },
      [onChange]
    );

    // Helper to return value with the right type: 'text' or 'number'
    const getRightValue = useCallback(
      (str) => {
        let changedValue = str;
        if (!isNumberInput) {
          return changedValue;
        }
        return !changedValue || /\d/.test(changedValue) ? changedValue : "";
      },
      [isNumberInput]
    );

    // Change OTP value at focussing input
    const changeCodeAtFocus = useCallback(
      (str) => {
        const updatedOTPValues = [...value];
        updatedOTPValues[active] = str[0] || "";
        setValue(updatedOTPValues);
        handleOtpChange(updatedOTPValues);
      },
      [active, handleOtpChange, value]
    );

    // Focus `inputIndex` input
    const focusInput = useCallback(
      (inputIndex) => {
        const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
        setActive(selectedIndex);
      },
      [length]
    );

    const focusPrevInput = useCallback(() => {
      focusInput(active - 1);
    }, [active, focusInput]);

    const focusNextInput = useCallback(() => {
      focusInput(active + 1);
    }, [active, focusInput]);

    // Handle onFocus input
    const handleOnFocus = useCallback(
      (index) => () => {
        focusInput(index);
      },
      [focusInput]
    );

    // Handle onChange value for each input
    const handleOnChange = useCallback(
      (e) => {
        const val = getRightValue(e.currentTarget.value);
        if (!val) {
          e.preventDefault();
          return;
        }
        changeCodeAtFocus(val);
        focusNextInput();
      },
      [changeCodeAtFocus, focusNextInput, getRightValue]
    );

    const onBlur = useCallback(() => {
      setActive(-1);
    }, []);

    // Handle onKeyDown input
    const handleOnKeyDown = useCallback(
      (e) => {
        switch (e.key) {
          case "Backspace":
          case "Delete": {
            e.preventDefault();
            if (value[active]) {
              changeCodeAtFocus("");
            } else {
              focusPrevInput();
            }
            break;
          }
          case "ArrowLeft": {
            e.preventDefault();
            focusPrevInput();
            break;
          }
          case "ArrowRight": {
            e.preventDefault();
            focusNextInput();
            break;
          }
          case " ": {
            e.preventDefault();
            break;
          }
          default:
            break;
        }
      },
      [active, changeCodeAtFocus, focusNextInput, focusPrevInput, value]
    );

    const handleOnPaste = useCallback(
      (e) => {
        e.preventDefault();
        const pastedData = e.clipboardData
          .getData("text/plain")
          .trim()
          .slice(0, length - active)
          .split("");
        if (pastedData) {
          let nextFocusIndex = 0;
          const updatedOTPValues = [...value];
          updatedOTPValues.forEach((val, index) => {
            if (index >= active) {
              const changedValue = getRightValue(pastedData.shift() || val);
              if (changedValue) {
                updatedOTPValues[index] = changedValue;
                nextFocusIndex = index;
              }
            }
          });
          setValue(updatedOTPValues);
          setActive(Math.min(nextFocusIndex + 1, length - 1));
        }
      },
      [active, getRightValue, length, value]
    );
    return (
      <Grid container alignItems="stretch" {...other}>
        {Array(length)
          .fill("")
          .map((_, index) => (
            <Grid
              key={`SingleInput-${index}`}
              item
              xs={Math.floor(12 / length)}
            >
              <SingleOTPInput
                id={`${id}-${index}`}
                focus={active === index}
                autoFocus={autoFocus}
                value={value && value[index]}
                onFocus={handleOnFocus(index)}
                onChange={handleOnChange}
                onKeyDown={handleOnKeyDown}
                onBlur={onBlur}
                onPaste={handleOnPaste}
                style={inputStyle}
                className={inputClassName}
                disabled={disabled}
              />
            </Grid>
          ))}
      </Grid>
    );
  }
);

OTPInput.defaultProps = {
  length: 4,
  onChange: () => {},
  autoFocus: true,
  isNumberInput: true,
};

export default memo(OTPInput);
