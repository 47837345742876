import * as types from "./types";

export const set_option_props = (payload) => {
  return {
    type: types.SET_OPTION_PROPS,
    payload,
  };
};
export const get_data_option = (payload) => {
  return {
    type: types.GET_DATA_OPTION,
    payload,
  };
};
export const set_menu_props = () => {
  return {
    type: types.SET_MENU_PROPS,
  };
};
export const set_menu = (payload) => {
  return {
    type: types.SET_MENU,
    payload,
  };
};
export const set_faq = (payload) => ({
  type: types.SET_FAQ,
  payload,
});
export const set_app_config = (payload) => ({
  type: types.SET_APP_CONFIG,
  payload,
});
export const set_goals = (payload) => ({
  type: types.SET_GOALS,
  payload,
});
export const chart_color_props = () => ({ type: types.CHART_COLOR_PROPS });
export const chart_color = (payload) => ({ type: types.CHART_COLOR, payload });
export const header_search_props = (payload) => ({
  type: types.HEADER_SEARCH_PROPS,
  payload,
});
export const header_search = (payload) => ({
  type: types.HEADER_SEARCH,
  payload,
});

export const set_data_location = (payload) => ({
  type: types.SET_DATA_LOCATION,
  payload,
});

export const set_data_option_child = (payload) => ({
  type: types.SET_DATA_OPTION_CHILD,
  payload,
});
export const content = (payload) => ({ type: types.CONTENT, payload });

export const fetch_rate_us = (payload) => ({
  type: types.FETCH_RATE_US,
  payload,
});
export const get_rate_us = (payload) => ({
  type: types.FETCH_GET_RATE_US,
  payload,
});
export const fetch_ins_benefit = (payload) => ({
  type: types.FETCH_INS_BENEFIT,
  payload,
});
export const get_insurance_info = (payload) => ({
  type: types.GET_INSURANCE_INFO,
  payload,
});
export const upload = (payload) => ({ type: types.UPLOAD, payload });
export const fetch_restrict_image = (payload) => ({
  type: types.FETCH_RESTRICT_IMAGE,
  payload,
});
