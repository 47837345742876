import Card from "@material-ui/core/Card";
import Skeleton from "@material-ui/lab/Skeleton";
import React, { memo } from "react";
import CardHeaderGoal from "../CardHeaderGoal";
import useStyles from "./useStyles";
import classNames from "classnames";
import GenerateHorizontal from "../../template/generate/GenerateHorizontal";
import mainMenu from "../../../assets/img/icon/mainMenu";
import parse from "html-react-parser";
import textHelper from "../../../helper/textHelper";
import url from "../../../config/API/url";
const { useCallback, useState, useMemo } = React;

const ItemGoalSelect = ({ item, style }) => {
  const { id, ref_goals_icon, goal_name } = item || {};
  return (
    <div
      id={`${id}-goal`}
      style={style}
      className="text-center is-link item-select-goals"
      key={id}
    >
      <img
        alt={ref_goals_icon.name}
        onDragStart={(e) => e.preventDefault()}
        src={ref_goals_icon?.icon_web_path || ref_goals_icon?.icon_path}
        style={{ width: 50 }}
      />
      <p
        className={`basic fs-10 text-uppercase ${
          goal_name.includes(" ") && "ws"
        }`}
      >
        {goal_name.length > 8
          ? parse(textHelper.printProduct2Line(goal_name))
          : goal_name}
      </p>
    </div>
  );
};

const ContentSelectGoals = ({
  onChange,
  value,
  goal_list,
  isSmartPlan,
  isRDP,
}) => {
  const classes = useStyles();
  const [mode, setMode] = useState(false);
  const resData = useMemo(() => {
    if (isSmartPlan) {
      return {
        goal_name: "Smart Plan",
        ref_goals_icon: {
          icon_web_path: mainMenu.smart_plan,
        },
      };
    }
    if (isRDP) {
      return {
        goal_name: "REKSA DANA TERPROTEKSI",
        ref_goals_icon: {
          icon_web_path: `${url}/v3/storage/asset/icon/goals-rdp.png`,
        },
      };
    }
    return goal_list.find((item) => Number(item.id) === Number(value)) || {};
  }, [goal_list, isRDP, isSmartPlan, value]);

  const handleChangeMode = useCallback(() => {
    setMode((prev) => !prev);
    onChange(null);
  }, [onChange]);

  const handleSelect = useCallback(
    (id) => {
      handleChangeMode();
      onChange(id);
    },
    [handleChangeMode, onChange]
  );
  return (
    <Card className={classNames("temp-card mb-3", classes.container)}>
      {goal_list.length === 0 ? (
        <Skeleton />
      ) : mode ? (
        <div className="ml-2 mt-1">
          <p className="basic fs-12 font-weight-bold mb-2">Pilih Portofolio</p>
          <div style={{ marginLeft: -38, marginRight: -17 }}>
            <GenerateHorizontal
              data={goal_list}
              render={ItemGoalSelect}
              onClickItem={handleSelect}
              options={{ paddingStart: 30, paddingEnd: 17, spacing: 3 }}
            />
          </div>
        </div>
      ) : (
        <CardHeaderGoal
          data={resData}
          onClick={handleChangeMode}
          isShow={!(isSmartPlan || isRDP)}
        />
      )}
    </Card>
  );
};

ContentSelectGoals.defaultProps = {
  goal_list: [],
};

export default memo(ContentSelectGoals);
