import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import parse from "html-react-parser";
import React from "react";
import textHelper from "../../../helper/textHelper";
import BasicText from "../../atom/BasicText/BasicText";
import HorizontalStepper from "../../molecule/Stepper/HorizontalStepper";
import VerticalStepper from "../../molecule/Stepper/VerticalStepper";

const useStyles = makeStyles((theme) => ({
  stepperBorder: {
    paddingBottom: "22px",
    "& .vertical": {
      [theme.breakpoints.up(768)]: {
        display: "none",
      },
    },
    "& .horizontal": {
      [theme.breakpoints.down(769)]: {
        display: "none",
      },
    },
    [theme.breakpoints.up(768)]: {
      backgroundColor: theme.palette.common.white,
    },
    borderBottom: `2px dotted ${theme.palette.secondary[3]}`,
  },
  boxDetail: {
    [theme.breakpoints.up(768)]: {
      backgroundColor: theme.palette.common.white,
    },
  },
  continerArrow: {
    display: "grid",
    placeItems: "center",
    width: "100%",
    backgroundColor: theme.palette.secondary[8],
    marginTop: 30,
    padding: "4px 0",
  },
}));

const BoxDetail = ({ className, data, dataKey, type_code }) => {
  const { product, fr_amount } = data;
  return (
    <div className="mt-4">
      <div className="w-fit mb-2 pb-1">
        <p className="basic fs-12 font-weight-semibold">
          {parse(textHelper.printProduct2Line(product.fr_product_name))}
        </p>
      </div>
      <Grid container>
        <Grid item xs={12} md={6}>
          <div className="d-flex justify-content-between w-100">
            <div className="d-flex flex-column justify-content-between">
              <BasicText className="fs-12 my-auto" color="secondary">
                Total Pemesanan
              </BasicText>
              <BasicText className="fs-12 my-auto" color="secondary">
                Total Biaya
              </BasicText>
              <BasicText className="fs-12 my-auto" color="secondary">
                Total Yang Harus Dibayarkan
              </BasicText>
            </div>
            <div className="ml-md-3 d-flex flex-column justify-content-between align-items-end">
              <p className="basic fs-14 my-2 font-weight-bold color-primary">
                {fr_amount}
              </p>
              <p className="basic fs-14 my-2 font-weight-bold color-primary">
                {"-"}
              </p>
              <p className="fs-14 my-2 font-weight-bold basic color-primary">
                {fr_amount}
              </p>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const DetailBooking = (props) => {
  const { data, className } = props;
  const classes = useStyles();
  const step = 3;
  const { txn_reff, fr_amount, created_at } = data;
  return (
    <>
      <Grid className="card-custom mb-3" container>
        <Grid
          item
          xs={12}
          md={6}
          container
          alignItems="center"
          justify="space-between"
        >
          <div>
            <p className="basic fs-12">
              Nomor Transaksi: <b>{txn_reff}</b>
            </p>
            <p className="basic fs-10 gray-4">
              {textHelper.printDateTimeShortMonth(created_at)}
            </p>
          </div>
          <p className="basic fs-12 color-primary">{fr_amount}</p>
        </Grid>
      </Grid>

      <div className={classNames(className, "card-custom")}>
        <div className={classNames("p-3", classes.stepperBorder)}>
          <div className="vertical">
            <VerticalStepper
              step={step}
              text={[
                "Pemesanan Diterima",
                "Dalam Proses",
                "Pemesanan Berhasil",
              ]}
              subText={[
                "Konfirmasi pemesanan diterima",
                "Pemesanan kamu sedang dalam proses",
                "Pemesanan kamu berhasil",
              ]}
            />
          </div>
          <div className="horizontal">
            <HorizontalStepper
              step={step}
              text={[
                "Pemesanan Diterima",
                "Dalam Proses",
                "Pemesanan Berhasil",
              ]}
              subText={[
                "Konfirmasi pemesanan diterima",
                "Pemesanan kamu sedang dalam proses",
                "Pemesanan kamu berhasil",
              ]}
            />
          </div>
        </div>

        {/* {true ? ( */}
        <BoxDetail
          className="p-3"
          data={data}
          dataKey="product"
          // type_code={type_code}
        />
        {/* ) : (
          <div>
            <BoxDetail
              data={data}
              className="p-3"
              dataKey="product"
              type_code={type_code}
            />
            <Grid container>
              <Grid item xs={12} md={6}>
                <div className={classes.continerArrow}>
                  <ArrowDownwardOutlinedIcon fontSize="small" color="primary" />
                </div>
              </Grid>
            </Grid>

            <BoxDetail
              data={data?.destination_txn}
              className="p-3"
              dataKey="product_to"
              type_code={type_code}
            />
          </div>
        )} */}
      </div>
    </>
  );
};

export default DetailBooking;
