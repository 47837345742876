export const simulateInvest = (obj) => {
  let {
    log,
    durasiInvest,
    firstInvest,
    monthlyInvest,
    multiple,
    timeInvestType,
  } = obj;
  // console.log(obj);
  // MM-DD-YYYY
  let now = new Date();
  // let dateNow = "09/25/2019";
  let dateNow = now.getMonth() + 1 + "/01/" + now.getFullYear();
  dateNow = dateNow.split("/");

  // Ambil Bulan dan Tahun saat ini (dikurangi 1 bulan, agar NAV pasti ada)
  let monthGet = dateNow[0] - 1 === 0 ? 12 : dateNow[0] - 1;
  let yearGet = monthGet === 12 ? dateNow[2] - 1 : dateNow[2];

  // Durasi invest ditambah 1 bulan jadi (tahun*12)+1
  let durasiInvestMonth =
    timeInvestType === "year" ? durasiInvest * 12 : durasiInvest;
  durasiInvestMonth++;
  let logs = log[0];
  // if (multiple) {
  //   logs = log[0];
  // }
  let { arrResult, chartData } = calcSimulateInvest({
    log: logs,
    durasiInvestMonth,
    monthGet,
    yearGet,
    firstInvest: multiple ? firstInvest * (multiple[0] / 100) : firstInvest,
    monthlyInvest: multiple
      ? monthlyInvest * (multiple[0] / 100)
      : monthlyInvest,
  });
  // arrResult = arrResult.filter(Boolean);
  // console.log(arrResult,chartData);
  if (Array.isArray(multiple) && multiple[1]) {
    logs = log[1];
    let res = calcSimulateInvest({
      log: logs,
      durasiInvestMonth,
      monthGet,
      yearGet,
      firstInvest: multiple ? firstInvest * (multiple[1] / 100) : firstInvest,
      monthlyInvest: multiple
        ? monthlyInvest * (multiple[1] / 100)
        : monthlyInvest,
    });

    res.arrResult.forEach((val, index) => {
      let cResult = arrResult[index] || {};
      arrResult[index] = {
        ...cResult,
        investasi: cResult.investasi + val.investasi,
        jumlahUnit: cResult.jumlahUnit + val.jumlahUnit,
        nav: cResult.nav + val.nav,
        pertumbuhanInvest: cResult.pertumbuhanInvest + val.pertumbuhanInvest,
        ret: cResult.ret + val.ret,
        totalInvest: cResult.totalInvest + val.totalInvest,
        totalInvestReturn: cResult.totalInvestReturn + val.totalInvestReturn,
        unit: cResult.unit + val.unit,
      };
    });
    res.chartData.data.invest.forEach((val, index) => {
      let cDataInvest = chartData.data.invest[index];
      chartData.data.invest[index] = cDataInvest + val;
    });

    res.chartData.data.modal.forEach((val, index) => {
      let cDatamodal = chartData.data.modal[index];
      chartData.data.modal[index] = cDatamodal + val;
    });
  }
  let result = {
    investLog: arrResult,
    investResult: arrResult[arrResult.length - 1],
    chartData,
  };

  return result;
};

const calcSimulateInvest = (obj) => {
  let {
    log,
    durasiInvestMonth,
    monthGet,
    yearGet,
    firstInvest,
    monthlyInvest,
  } = obj;
  // Indikator data mulai diambil/masukan kedalam array
  let fetchCount = 0;
  let arrLogs = [];
  // Looping data Logs

  try {
    log.forEach((value) => {
      // Jika fetchCount > durasi investasi bulan, looping selesai
      if (fetchCount > durasiInvestMonth) {
        return;
      }

      // Jika loop saat ini bulan & tahunnya sudah under bulan & tahun yg ditentukan, Ubah indikator mulai ambil data
      if (value.year <= yearGet && fetchCount === 0) {
        if (value.year === yearGet && value.month <= monthGet) {
          fetchCount = 1;
        } else if (value.year <= yearGet) {
          fetchCount = 1;
        }
      }

      // Jika indikator sudah > 0, mulai ambil data sebanyak durasiInvestMount-kali
      if (fetchCount > 0 && fetchCount <= durasiInvestMonth) {
        // Penyimpanan data ke array di-Reverse (Utk kebutuhan Graph)
        arrLogs[durasiInvestMonth - fetchCount] = value;
        fetchCount++;
      }
    });
  } catch (e) {}

  // console.log(arrLogs);
  let arrResult = [];
  let jumlahUnit = 0;
  let totalInvest = 0;

  // Utk Data Chart
  let chartData = {
    categories: [],
    data: {
      modal: [],
      invest: [],
    },
  };

  // Looping Logs yg telah didapat utk kalkulasi investasi
  arrLogs.forEach((v, k) => {
    // Jika looping pertama, nilai investasi = firstInvest
    let investasi = k === 0 ? firstInvest : monthlyInvest;
    let nav = v.nav_per_unit;
    let unit = investasi / nav;

    // Jumlah unit & totalInvestasi akan terus bertambah
    jumlahUnit += unit;
    totalInvest += investasi;

    // Total Investasi Saat ini
    let totalInvestReturn = jumlahUnit * nav;

    // Return saat ini
    let ret = totalInvestReturn - totalInvest;

    // Jika looping pertama, pertumbuhan = 0
    // Satuan Persen
    let pertumbuhanInvest = k === 0 ? 0 : (ret / totalInvest) * 100;

    chartData.categories[k] = v.created_at;
    chartData.data.modal[k] = totalInvest;
    chartData.data.invest[k] = totalInvestReturn;

    arrResult[k] = {
      date: v.created_at,
      investasi,
      nav,
      unit,
      jumlahUnit,
      totalInvest,
      totalInvestReturn,
      ret,
      pertumbuhanInvest,
    };
  });

  arrResult = arrResult.filter(Boolean);
  chartData = {
    ...chartData,
    categories: chartData.categories.filter(Boolean),
    data: {
      modal: chartData.data.modal.filter(Boolean),
      invest: chartData.data.invest.filter(Boolean),
    },
  };

  return { arrResult, chartData };
};

export function simulate(obj) {
  let { goalsInflasi, ekspekReturn, biayaTotal, dateFinish, investAwal } = obj;
  // Variable Non-Input
  var now = new Date();
  var dateNow = new Date(now.getMonth() + 1 + "/01/" + now.getFullYear()); // Date Saat Ini
  var durasiInvest = getYearDiff(dateFinish, dateNow); // Selisih Finish dan Sekarang | Satuan Tahun

  // Inflasi yg digunakan tergantung durasiInvest
  var inflasi = 0;
  if (durasiInvest <= 1) inflasi = goalsInflasi?.inflasi_y_1 || 6.5;
  else if (durasiInvest <= 2) inflasi = goalsInflasi?.inflasi_y_2 || 6.5;
  else inflasi = goalsInflasi?.inflasi_y_3 || 6.5;

  // Bagi 100 biar jadi percentage
  inflasi = inflasi / 100;
  ekspekReturn = ekspekReturn / 100;
  var zOnePercent = 0.00001 / 100; // Nilai toleransi ?

  // biayaTotal setelah dihitung dengan inflasi
  var biayaSetelahInflasi = biayaTotal * Math.pow(1 + inflasi, durasiInvest);
  // Hasil investasi investAwal setelah durasiInvest (Tahun)
  var nilaiPengembanganInvestAwal =
    investAwal * Math.pow(1 + ekspekReturn, durasiInvest);

  // Kalkulasi investasi Bulanan yg harus dikeluarkan
  var a =
    (biayaSetelahInflasi - nilaiPengembanganInvestAwal) *
    (ekspekReturn - inflasi + zOnePercent);
  var b =
    (Math.pow(1 + ekspekReturn, durasiInvest) -
      Math.pow(1 + inflasi - zOnePercent, durasiInvest)) *
    12;

  console.log(
    "nilaiPengembanganInvestAwal",
    a,
    b,
    Math.pow(1 + ekspekReturn, durasiInvest),
    Math.pow(1 + inflasi - zOnePercent, durasiInvest),
    ekspekReturn,
    durasiInvest
  );
  // Rupiah
  var investBulanan = a / b;
  return investBulanan;
}

const getYearDiff = (dateFinish, dateStart) => {
  var timeDateFinish = new Date(dateFinish);
  var timeDateStart = new Date(dateStart);

  var diffTime = timeDateFinish.getTime() - timeDateStart.getTime();

  var diffDays = diffTime / (1000 * 3600 * 24);
  var diffYear = diffDays / 365;
  return diffYear;
};

export const getYearLater = () => {
  var now = new Date();
  var monthNow = now.getMonth() + 1;
  monthNow = monthNow < 10 ? "0" + monthNow : monthNow;
  return `${monthNow}/01/${now.getFullYear() + 1}`;
};
