import React from "react";
import { Card, makeStyles } from "@material-ui/core";
import BasicText from "../../atom/BasicText/BasicText";
import logo from "../../../assets/img/logo";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";

const Text = ({ children, className }) => (
  <BasicText className={classNames("fs-12 gray-3", className)}>
    {children}
  </BasicText>
);

const TextTop = ({ className }) => (
  <BasicText className={classNames("fs-14 font-weight-bold", className)}>
    Keunggulan Ekstra SMART Plan
  </BasicText>
);

const useStyles = makeStyles({
  contain: {
    padding: "18px 16px 23px",
    boxShadow: "unset",
  },
});

const KeunggulanSmartPlan = ({ className }) => {
  const isDesktop = useMediaQuery({ minWidth: 769 });
  const S = useStyles();
  return (
    <div className={isDesktop ? "mb-3" : "mb-2"}>
      <TextTop className="mb-2" />
      <Card
        className={classNames(S.contain, "card-custom", !isDesktop && "br-8")}
      >
        <img src={logo.logoSmartPlan} alt="" style={{ marginBottom: 12 }} />

        <Text className='mb-3'>
          SMART Plan merupakan program yang akan memberikan manfaat ekstra
          berupa asuransi jiwa tanpa premi tambahan untuk nasabah dengan nilai
          investasi sesuai syarat saldo investasi yang berlaku.
        </Text>
        <Text>
          SMART Plan merupakan program yang akan memberikan keuntungan ekstra berupa asuransi jiwa tanpa premi tambahan untuk nasabah dengan nilai investasi minimal sepuluh juta rupiah, sehingga manfaat investasi reksa dana dapat kamu rasakan tidak hanya untuk masa depan tetapi juga di saat keadaan darurat.
        </Text>
      </Card>
    </div>
  );
};

export default KeunggulanSmartPlan;
