import { CircularProgress, Grid } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import classNames from "classnames";
import React, { forwardRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import stateCallApi from "../../../config/API/stateCallApi";
import { chart_color_props } from "../../../config/redux/actions/option";
import { gbl_act_lc } from "../../../config/redux/actions/state";
import textHelper from "../../../helper/textHelper";
import BasicText from "../../atom/BasicText/BasicText";
import IconRobo from "../../atom/IconRobo/IconRobo";
import DialogAlert from "../../molecule/Alert/DialogAlert";
import CardRisk from "../../molecule/CardRisk";
import ListProduct from "../../molecule/ListProduct";
import SliderWithInput from "../../molecule/MaterialInput/SliderWithInput";
import useStyles from "./useStyles";
const { useState, useEffect, useRef } = React;

const ContentROBO = forwardRef((props, ref) => {
  const dispatch = useDispatch();

  const chart_color = useSelector((state) => state.optionReducer.chart_color);
  const listBaseRoboAlc = useSelector(
    (state) => state.goalReducer.base_robo_alc
  );
  const riskPointProfile = useSelector(
    (state) => state.profileReducer.profile.profile_risk_point
  );

  useEffect(() => {
    dispatch(chart_color_props());
    dispatch(gbl_act_lc(stateCallApi.profile_risk_base_point));
  }, [dispatch]);

  return (
    <div>
      {!listBaseRoboAlc.length ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: 250 }}
        >
          <CircularProgress size={20} />
        </div>
      ) : (
        <>
          <CardRisk
            colors={chart_color}
            data1={listBaseRoboAlc}
            idChart="chartLainnyaPage"
            getData1={props.getData1}
            className="p-0 no-shadow"
            riskPoint={props.riskPoint || Number(riskPointProfile)}
          />
        </>
      )}
    </div>
  );
});

const AddPlannerRiskPage = ({
  onChangeFirst,
  valueFirst,
  valueMonth,
  getRobo,
}) => {
  const { push, location } = useHistory();
  const [robo, setRobo] = useState(1);
  const [data1, setdata1] = useState();
  const isRefRobo = useRef();
  const classes = useStyles();

  useEffect(() => {
    !location.state && push("/");
    getRobo(
      robo === 0
        ? { is_follow_robo: 0 }
        : { is_follow_robo: 1, profile_risk_point: data1?.profile_risk_point }
    );
  }, [data1, getRobo, location.state, push, robo]);

  const handleClickProduct = ({ link, items }) => {
    const product = items;
    push(`${link}?product=${product.code}`, { product });
  };

  const LIST_ROBO = useMemo(
    () => [
      {
        title: "Reksa Dana Pilihan Kamu",
        children: (
          <BasicText
            className="fs-13 gray-3 mb-2"
            style={{ margin: "15px 30px" }}
          >
            Pembelian Reksa Dana sesuai dengan pilihan sendiri
          </BasicText>
        ),
      },
      {
        title: "Rekomendasi ROBO",
        Icon: IconRobo,
        children: (
          <ContentROBO
            ref={isRefRobo}
            getData1={setdata1}
            riskPoint={data1?.profile_risk_point}
          />
        ),
        children2: (
          <>
            <ListProduct
              onClick={handleClickProduct}
              data={data1?.profile_risk_product || []}
              className="br-8"
            />
            <div className="mt-3 card-custom br-8">
              <SliderWithInput
                id="lainnyaPlan"
                adormentContent={() => <span className="fs-14 gray-4">Rp</span>}
                adormentPlace="start"
                sliderLabelClass="fs-12 gray-4"
                text="Setoran Awal:"
                onChange={onChangeFirst}
                value={valueFirst}
                textInputClass="br-unset"
              />
              <Grid container className="d-flex justify-content-between">
                <Grid item xs={6} className="d-flex align-items-center">
                  <BasicText className="fs-12 gray-4">
                    Investasi Bulanan:
                  </BasicText>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.areaInvest}>
                    <BasicText className="color-white fs-14 rounded-1">
                      {textHelper.printMoney(valueMonth)}
                    </BasicText>
                  </div>
                </Grid>
              </Grid>
            </div>
          </>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data1, onChangeFirst, valueFirst, valueMonth]
  );

  return (
    <div className={classes.root}>
      <RadioGroup
        value={robo}
        onChange={({ target }) => setRobo(Number(target.value))}
      >
        {LIST_ROBO.map(({ title, Icon, children, children2 }, index) => (
          <>
            <Card
              key={title}
              className={classNames(
                "br-8 p-3 shadow-card",
                index !== 1 && "mb-3"
              )}
            >
              <div className="d-flex align-items-center">
                <Radio id={`${index}-radio`} value={index} className="p-0" />
                <p className="basic mx-2 fw-700 gray-3">{title}</p>
                {Icon && <Icon />}
              </div>
              {robo === index && children}
            </Card>
            {data1 ? robo === index && children2 : null}
          </>
        ))}
      </RadioGroup>
      <DialogAlert resultName="add-planner" alertAppear="error" />
    </div>
  );
};

AddPlannerRiskPage.defaultProps = {};

export default AddPlannerRiskPage;
