import React, { PureComponent } from "react";
import TempMain from "../template/TempMain";
import TempProfileWithImage from "../template/TempProfileWithImage";
import BasicText from "../atom/BasicText/BasicText";
import { fetch_notif_list } from "../../config/redux/actions/notification";
import { connect } from "react-redux";
import CardNotif from "../molecule/Profile/CardNotif";
import textHelper from "../../helper/textHelper";
import InputTimePicker from "../molecule/MaterialInput/InputTimePicker";
import { withStyles } from "@material-ui/core";
import { compose } from "redux";
import processHelper from "../../helper/processHelper";
import { Skeleton } from "@material-ui/lab";

const useStyles = (theme) => ({
  datePicker: {
    "& input": {
      padding: "13px 0 10px 13px",
    },
    "& fieldset": {
      borderRadius: 0,
    },
  },
});

class NotificationPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      date: textHelper.getYear_Month(),
    };
  }

  componentDidMount = () => {
    this.props.fetch_notif_list({ date: this.state.date });
  };

  _handleChangeTime = (_e) => {
    const date = textHelper.getYear_Month(new Date(_e));
    this.setState({ date });
    this.props.fetch_notif_list({ date });
  };

  render() {
    const { notification_list, classes, loading_stack } = this.props;
    const load = processHelper.findProcess("notif-list", loading_stack);
    return (
      <TempMain
        title="PROFIL"
        backgroundColor="white"
        showBottom={false}
        bottomNavValue={4}
        page="profile"
        data={{ menuValue: 9 }}
      >
        <TempProfileWithImage image="notification" className="pr-4">
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ marginBottom: 60 }}
          >
            <BasicText color="primary" className="fs-14 font-weight-bold">
              NOTIFIKASI
            </BasicText>
            <div className="d-flex align-items-center">
              <BasicText className="fs-12 mr-2 pr-1">Periode</BasicText>
              <InputTimePicker
                value={this.state.date}
                onChange={this._handleChangeTime}
                views={["year", "month"]}
                containClass={classes.datePicker}
                format="MMMM yyyy"
                maxDate={new Date()}
              />
            </div>
          </div>

          {load ? (
            Array(4)
              .fill("")
              .map((_, index) => <Skeleton height="87px" key={index} />)
          ) : notification_list.length > 0 ? (
            notification_list.map((_, index) => (
              <CardNotif item={_} key={`notif-${index}`} />
            ))
          ) : (
            <BasicText>Tidak Ada notifikasi bulan ini</BasicText>
          )}
        </TempProfileWithImage>
      </TempMain>
    );
  }
}

const mapStateToProps = ({ notifReducer, stateReducer }) => ({
  notification_list: notifReducer.notification_list,
  loading_stack: stateReducer.loading_stack,
});
const mapDispatchToProps = {
  fetch_notif_list,
};
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(useStyles)
)(NotificationPage);
