import React from "react";
import { Grid, Divider } from "@material-ui/core";
import ProfileRiskNameDesc from "../../molecule/Profile/ProfileRiskNameDesc";
import ProductRecommendChart from "../../molecule/Profile/ProductRecommendChart";
import { makeStyles } from "@material-ui/styles";
import RecomColorDesc from "../../molecule/Profile/RecomColorDesc";
import ButtonContained from "../../atom/Button/ButtonContained";
import color from "../../../assets/theme/color";
import BasicText from "../../atom/BasicText/BasicText";

const useStyles = makeStyles((theme) => ({
  chart: {
    width: 150,
    height: 150,
    margin: "0 auto",
  },
  threeButton: {
    [theme.breakpoints.up(768)]: {
      display: "none",
    },
  },
  changeButton: {
    width: "100%",
    textAlign: "right",
    [theme.breakpoints.down(769)]: {
      display: "none",
    },
  },
}));

const CardProfileRisk = (props) => {
  const classes = useStyles();
  const { chartColor, profileRiskData, onClick } = props;
  const { profile_risk_type, profile_risk_product } = profileRiskData;
  const handleClick = (e) => {
    onClick(e);
  };

  return (
    <div className="card-custom mb-3">
      <ProfileRiskNameDesc profileRiskType={profile_risk_type} />
      <Divider className="my-3" />
      <BasicText className="fs-12 mb-1">Rekomendasi produk:</BasicText>
      <ProductRecommendChart
        containerClass={classes.chart}
        chartColor={chartColor}
        profileRiskProduct={profile_risk_product}
      />
      <RecomColorDesc
        profileRiskProduct={profile_risk_product}
        color={chartColor}
        onClick={handleClick}
      />
      <Grid container spacing={1} className={classes.threeButton}>
        <Grid item xs={4}>
          <ButtonContained
            text="BELI"
            className="w-100 fs-11 py-2"
            radius="5px"
            backgroundColor={color.primary}
            onClick={() => handleClick("subscript")}
          />
        </Grid>
        <Grid item xs={4}>
          <ButtonContained
            text="UBAH"
            className="w-100 fs-11 py-2"
            radius="5px"
            backgroundColor={color.gray_6}
            onClick={() => handleClick("ubah")}
          />
        </Grid>
        <Grid item xs={4}>
          <ButtonContained
            text="SIMULASI"
            className="w-100 fs-11 py-2"
            radius="5px"
            backgroundColor={color.secondary}
            onClick={() => handleClick("simulasi")}
          />
        </Grid>
      </Grid>
      <div className={classes.changeButton}>
        <BasicText
          className="fs-12 text-underline is-link"
          onClick={() => handleClick("ubah")}
          colorLevel="lighter"
        >
          UBAH
        </BasicText>
      </div>
    </div>
  );
};

export default CardProfileRisk;
