import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
// import { useEffect } from "react";
// import chartHelper from "../../../helper/chartHelper";

const ChartSimulasi = ({ categories, data }) => {
  const options = {
    title: { text: "" },
    subtitle: {},
    yAxis: {
      labels: {
        formatter: function () {
          if (this.value > 0) {
            return this.value / 1000000 + "Jt";
          } else {
            return 0;
          }
        },
      },
      title: { text: "" },
    },
    xAxis: {
      labels: {
        formatter: function () {
          var tooltip = Highcharts.dateFormat(
            "%m/%y",
            new Date(this.value + " UTC")
          );
          return tooltip;
          // return 'Y' + this.value;
        },
        step: 20,
      },
      categories: ["06/30/2019"],
    },
    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      formatter: function (d) {
        var tooltip = "";
        // var tooltip = "Waktu : "
        tooltip += Highcharts.dateFormat(
          "%b %e, %Y",
          new Date(this.x + " UTC")
        );

        [1, 0].forEach((val) => {
          let point = this.points[val];

          let money = String(point.y);
          if (money === null) {
            return "0";
          } else {
            let data = money.toString().split(".");
            let koma = data[1] ? "," + data[1].substring(0, 2) : "";
            money =
              data[0].toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.") +
              koma;
          }

          tooltip +=
            '<br><span style="color:' +
            point.series.userOptions.lineColor +
            '">' +
            point.series.userOptions.name +
            "</span>: <b>Rp. " +
            money +
            "</b>";
        });
        return tooltip;
      },
      // pointFormat:
      //   '<span style="color:{series.color}">{series.name}</span>: <b>Rp. {point.y}</b> ({point.change})<br/>',
      valueDecimals: 2,
      split: true,
    },
    series: [
      {
        name: "Modal",
        lineColor: "#B2B2B2",
        data: [0],
        marker: {
          fillColor: "#B2B2B2",
        },
      },
      {
        name: "Hasil Investasi",
        lineColor: "#EF6431",
        data: [0],
        marker: {
          symbol:
            "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgOSA5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8Y2lyY2xlIGN4PSI0LjUiIGN5PSI0LjUiIHI9IjQiIGZpbGw9IndoaXRlIiBzdHJva2U9IiNFRjY0MzEiLz4KPC9zdmc+Cg==)",
          radius: 4,
          fillColor: "#EF6431",
        },
      },
    ],
    responsive: {
      rules: [
        {
          condition: { maxWidth: 500 },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
  };
  if (categories && data) {
    options.xAxis.categories = categories;
    options.xAxis.labels.step = Math.round(categories.length / 3);
    data.forEach((val, idx) => {
      options.series[idx].data = val.data;
      options.series[idx].name = val.name;
    });
  }
  // useEffect(() => {
  //   chartHelper.createSimulasiChart(data, "test");
  // }, [data]);
  // return <div id={"test"}></div>;
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default ChartSimulasi;
