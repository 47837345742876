import React from "react";
import { useSelector } from "react-redux";
import textHelper from "../../../helper/textHelper";
import ShortProfile from "../../molecule/Profile/ShortProfile";

const ShortProfiles = (props) => {
  const profileReducer = useSelector((state) => state.profileReducer);
  const [state, setState] = React.useState({
    name: "",
    profile_risk: null,
    sid: "",
  });
  React.useEffect(() => {
    const { profile, sid } = profileReducer;
    console.log(profile);
    if (Object.keys(profile).length > 0) {
      setState((state) => ({
        ...state,
        name: textHelper.printFullname(profile),
        sid,
        profile_risk: profile.profile_risk?.name,
      }));
    }
  }, [profileReducer]);
  const handleDetail = () => {
    props.onDetailClick();
  };
  const { name, profile_risk, sid } = state;
  return (
    <ShortProfile
      name={name}
      profile_risk={profile_risk}
      sid={sid}
      onDetailClick={handleDetail}
      detailState={props.detailState}
    />
  );
};

export default ShortProfiles;
