import { isEqual } from "lodash";
import { useEffect, useState } from "react";

const useCompareDidUpdate = (value) => {
  const [state, setstate] = useState(value);
  useEffect(() => {
    if (!isEqual(value, state)) {
      setstate(value);
    }
    return () => setstate(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  return !isEqual(value, state);
};

export default useCompareDidUpdate;
