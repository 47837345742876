import { ThemeProvider } from "@material-ui/styles";
import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
import "./assets/styles/common.css";
import "./assets/styles/template.css";
import { theme } from "./assets/theme/theme";
import Page from "./components/page";
import AddPlannerPage from "./components/page/AddPlannerPage";
import AddPlannerRiskPage from "./components/page/AddPlannerRiskPage";
import CryptPage from "./components/page/CryptPage";
import PrivateRoute from "./config/Router/PrivateRoute";
import PublicRoute from "./config/Router/PublicRoute";

class App extends React.Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Router>
          <LastLocationProvider>
            <Switch>
              <PublicRoute exact path="/masuk">
                <Page.LoginPage />
              </PublicRoute>
              <PrivateRoute exact path="/">
                <Page.MainPage />
              </PrivateRoute>
              <PrivateRoute exact path="/produk">
                <Page.productPage />
              </PrivateRoute>
              <PrivateRoute exact path="/detail-produk">
                <Page.DetailProduct />
              </PrivateRoute>
              <PrivateRoute exact path="/simulasi-produk">
                <Page.SimulasiProduct />
              </PrivateRoute>
              <PrivateRoute exact path="/help-center">
                <Page.HelpCenterPage />
              </PrivateRoute>
              <Route exact path="/article">
                <Page.ArticlePage />
              </Route>
              <Route path="/knowledge-center">
                <Page.KnowledgeCenterPage />
              </Route>
              <Route exact path="/coming-soon">
                <Page.ComingSoonPage />
              </Route>
              <PublicRoute path="/forgot-password">
                <Page.ForgotPassPage />
              </PublicRoute>
              <PublicRoute exact path="/register">
                <Page.RegisterInputPhone />
              </PublicRoute>
              <PublicRoute exact path="/register/validate-otp">
                <Page.Regis2 />
              </PublicRoute>
              <PublicRoute exact path="/register/identity">
                <Page.RegisterIdentitas />
              </PublicRoute>
              <PublicRoute exact path="/register/profil">
                <Page.RegisterProfil />
              </PublicRoute>
              <PublicRoute exact path="/register/tanda-tangan">
                <Page.RegisSignature />
              </PublicRoute>
              <PrivateRoute exact path="/smart-plan">
                <Page.SmartPlanPage />
              </PrivateRoute>
              <PrivateRoute exact path="/goals-planner">
                <Page.GoalKamuPage />
              </PrivateRoute>
              <PrivateRoute exact path="/subscript">
                <Page.OrderToBuy />
              </PrivateRoute>
              <PrivateRoute exact path="/book">
                <Page.OrderToBuy />
              </PrivateRoute>
              <PrivateRoute exact path="/payment">
                <Page.Payment />
              </PrivateRoute>
              <PrivateRoute exact path="/invoice">
                <Page.InvoicePage />
              </PrivateRoute>
              <PrivateRoute exact path="/confirm-payment">
                <Page.ConfirmPayment />
              </PrivateRoute>
              <PrivateRoute exact path="/finish-book">
                <Page.FinishOrder type="book" />
              </PrivateRoute>
              <PrivateRoute exact path="/finish-confirm">
                <Page.FinishOrder type="subs" />
              </PrivateRoute>
              <PrivateRoute exact path="/finish-redeem">
                <Page.FinishOrder type="redeem" />
              </PrivateRoute>
              <PrivateRoute exact path="/finish-switch">
                <Page.FinishOrder type="sw" />
              </PrivateRoute>
              <PrivateRoute exact path="/file-auto-debet">
                <Page.AutoDebetFilePage />
              </PrivateRoute>
              <PrivateRoute exact path="/file-stop-auto-debet">
                <Page.StopAutoDebetFile />
              </PrivateRoute>
              <PrivateRoute exact path="/transaksi">
                <Page.TxnPage />
              </PrivateRoute>
              <PrivateRoute exact path="/transaksi/detail">
                <Page.DetailTxnPage />
              </PrivateRoute>
              <PrivateRoute exact path="/detail-transaksi/:id">
                <Page.DetailTxnPage />
              </PrivateRoute>
              <PrivateRoute exact path="/portofolio">
                <Page.PortoPage />
              </PrivateRoute>
              <PrivateRoute exact path="/portofolio-goals">
                <Page.PortoGoalsPage />
              </PrivateRoute>
              <PrivateRoute exact path="/profil">
                <Page.ProfilePage />
              </PrivateRoute>
              <PrivateRoute exact path="/profil/data-profil">
                <Page.IdentityPage />
              </PrivateRoute>
              <PrivateRoute exact path="/profil/setting">
                <Page.SettingPage />
              </PrivateRoute>
              <PrivateRoute exact path="/profil/detail-periodic-invest">
                <Page.DetailPeriodicPage />
              </PrivateRoute>
              <PrivateRoute exact path="/profil/setting/:id">
                <Page.ChangePage />
              </PrivateRoute>
              <PrivateRoute exact path="/profil/estatement">
                <Page.EStatementPage />
              </PrivateRoute>
              <PrivateRoute exact path="/profil/document">
                <Page.DocumentPage />
              </PrivateRoute>
              <PrivateRoute exact path="/profil/promo">
                <Page.PromoPage />
              </PrivateRoute>
              <PrivateRoute exact path="/profil/promo/:code">
                <Page.PromoDetailPage />
              </PrivateRoute>
              <PrivateRoute exact path="/profil/tips-keamanan">
                <Page.TipsKeamananPage />
              </PrivateRoute>
              <PrivateRoute exact path="/profil/notification">
                <Page.NotificationPage />
              </PrivateRoute>
              <PrivateRoute exact path="/profil/rate-us">
                <Page.RateUsPage />
              </PrivateRoute>
              <PrivateRoute exact path="/profil/glossary">
                <Page.GlossaryPage />
              </PrivateRoute>
              <PrivateRoute exact path="/redeem">
                <Page.OrderToSell />
              </PrivateRoute>
              <PrivateRoute exact path="/switch">
                <Page.OrderToSwitch />
              </PrivateRoute>
              <PrivateRoute exact path="/switch-porto">
                <Page.SwitchPortoPage />
              </PrivateRoute>
              <PrivateRoute exact path="/switch-porto/success">
                <Page.SuccessSwitchPortoPage />
              </PrivateRoute>
              <PrivateRoute exact path="/profil-risiko">
                <Page.RiskPage />
              </PrivateRoute>
              <PrivateRoute exact path="/profil-risiko/change">
                <Page.RiskChangePage />
              </PrivateRoute>
              <PrivateRoute exact path="/profil-risikov2">
                <Page.ProfileRiskPage />
              </PrivateRoute>
              <PrivateRoute
                exact
                path={[
                  "/reference-invest",
                  "/modify-planner",
                  "/create-planner",
                  "/modify-goal-icon",
                ]}
              >
                <Page.ModifyPlannerPage />
              </PrivateRoute>
              <PrivateRoute exact path="/create-planner/plan">
                <AddPlannerPage type="planner" />
              </PrivateRoute>
              <PrivateRoute exact path="/change-goal">
                <AddPlannerPage type="goal" />
              </PrivateRoute>
              <PrivateRoute exact path="/create-planner/recommend">
                <AddPlannerRiskPage />
              </PrivateRoute>
              <PrivateRoute exact path="/voucher-merchant">
                <Page.VoucherMerchantPage />
              </PrivateRoute>
              <PrivateRoute exact path="/voucher-merchant/detail">
                <Page.VoucherMerchantDetailPage />
              </PrivateRoute>
              <PrivateRoute exact path="/voucher-merchant/scan">
                <Page.VoucherMerchantScanPage />
              </PrivateRoute>
              <Route exact path="/survey-risiko">
                <Page.RiskSurveyPage />
              </Route>
              <Route exact path="/hasil-profil-risiko">
                <Page.RiskResultPage />
              </Route>
              {/* <Route exact path="/tes">
                <TestInput />
              </Route> */}
              <PublicRoute exact path="/onboarding">
                <Page.OnBoarding />
              </PublicRoute>
              <PrivateRoute exact path="/generator">
                <CryptPage />
              </PrivateRoute>
              <PrivateRoute exact path="/ultra-voucher">
                <Page.UltraVoucherLoadPage />
              </PrivateRoute>
              <PrivateRoute exact path="/profil/delete-account">
                <Page.DeleteAccountPage />
              </PrivateRoute>
              <PrivateRoute exact path="/profil/update/mother-name">
                <Page.ProfileUpdateMotherName />
              </PrivateRoute>
              <Route exact path="*">
                <Redirect to="/onboarding" />
              </Route>
            </Switch>
          </LastLocationProvider>
        </Router>
      </ThemeProvider>
    );
  }
}

export default App;
