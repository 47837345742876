import React, { Component } from "react";
import { ErrorMessage, connect, getIn } from "formik";
import { makeStyles, Grid } from "@material-ui/core";
import classNames from "classnames";
import { isEqual } from "lodash";
import InputText from "../../molecule/MaterialInput/InputText";
import InputTimePicker from "../../molecule/MaterialInput/InputTimePicker";
import SelectsInput from "../../molecule/MaterialInput/SelectsInput";
import { useMediaQuery } from "react-responsive";
import { KeyboardArrowDown } from "@material-ui/icons";

const inputs = { InputText, InputTimePicker, SelectsInput };

const useStyles = makeStyles(theme => ({
  containError: {
    color: "red",
    fontSize: ".75rem",
    position: "relative",
  },
  chevron : {
    fontSize :30,
    color : theme.palette.secondary[6],
    position : 'absolute',
    right : 16,
  },
}));

const ChevronSelect = () => {
  const classes = useStyles()
  return <KeyboardArrowDown className={classes.chevron} />
}

const Container = ({ children, xs, className, ...other }) => {
  const isDesktop = useMediaQuery({ minWidth: 769 });
  const classes = useStyles();
  const _xs = isDesktop ? xs : 12;
  return (
    <Grid
      item
      xs={_xs}
      className={classNames(className, classes.containError, !isDesktop && "mb-2")}
    >
      {children}
    </Grid>
  );
};

function _getStateField(formik, name) {
  const { values } = formik;
  const _res = {
    value: getIn(values, name),
    // touched: getIn(touched, name),
    // error: getIn(errors.name),
  };
  return name ? _res : "";
}

class RenderInputRegister extends Component {
  shouldComponentUpdate = (nextProps) => {
    const { formik, item } = this.props;
    const prev = _getStateField(formik, item.name);
    const next = _getStateField(nextProps.formik, item.name);
    return (
      (item.name !== undefined || item.ChildrenProps !== undefined) &&
      !(isEqual(prev, next) && isEqual(item, nextProps.item))
    );
  };

  _triggerChange = async (value) => {
    const { onTrigger, item, formik } = this.props;
    const { name } = item;
    await formik.setFieldValue(name, value);
    onTrigger(value, name);
  };

  _renderChild = () => {
    const { item, formik } = this.props;
    const { name, children } = item;
    const _child = typeof children === "function" ? children(formik) : children;
    return <Grid className={name ? "mt-3" : undefined}>{_child}</Grid>;
  };

  defaultPropsInput = (typeInput) => {
    let params = {}
    switch(typeInput) {
      case 'SelectsInput':
        params = {variant : "outlined"}
        params.IconComponent = ChevronSelect
        params.className = 'z-index-1'
        break
      case "InputText":
        params.contentInputClass = "input-register"
          break
      default:
        break
    }
    return params
  }

  render() {
    const { item, containProps, formik } = this.props;
    const {
      typeInput,
      name,
      children,
      Children,
      ChildrenProps,
      className,
      hasParent,
      cClass,
      cEClass,
      trigger,
      ...otherProps
    } = item;
    const { handleChange, values, setFieldTouched } = formik;
    const Component = inputs[typeInput || "InputText"];
    return (
      <Container {...containProps} className={cClass}>
        {name && (
          <div>
            <Component
              labelClass="mb-2"
              value={values[name]}
              onChange={trigger ? this._triggerChange : handleChange(name)}
              onFocus={() => setFieldTouched(name)}
              className={classNames("pl-4", className)}
              {...this.defaultPropsInput(typeInput || "InputText")}
              {...otherProps}
            />
            <span className={classNames(cEClass, "errorInput")}>
              <ErrorMessage name={name} />
            </span>
          </div>
        )}
        {Children ? (
          <Grid className={name ? "mt-3" : undefined}>
            <Children {...ChildrenProps} />
          </Grid>
        ) : (
          children && this._renderChild()
        )}
      </Container>
    );
  }
}

RenderInputRegister.defaultProps = {
  item: {
    typeInput: "InputText",
  },
  onTrigger: () => { },
};

export default connect(RenderInputRegister);
