import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  rowFile: {
    display: "flex",
    alignItems: "center",
    "&.multi": {
      borderTop: `1px solid ${theme.palette.secondary[7]}`,
      paddingTop: 11,
      marginTop: 11,
    },
  },
}));
