import React, { useCallback } from "react";
import { connect } from "react-redux";
import { set_profile } from "../../config/redux/actions/profile";
import TempMain from "../template/TempMain";
import IdentityProfile from "../organism/ProfilePage/IdentityProfile";
import ShortProfiles from "../organism/ProfilePage/ShortProfiles";
import DialogPin from "../organism/PinInput/DialogPin";
import { useMediaQuery } from "react-responsive";
import ButtonContained from "../atom/Button/ButtonContained";
import { set_app_config } from "../../config/redux/actions/option";
import DialogAlert from "../molecule/Alert/DialogAlert";

const mapState = (state) => {
  return {
    profile: state.profileReducer.profile,
    province_list: state.optionReducer.province_list,
  };
};

const IdentityPage = (props) => {
  const condition = useMediaQuery({ maxWidth: 768 });
  const { profile, set_profile, set_app_config, province_list } = props;
  const [state, setState] = React.useState({
    modal: false,
    detail: false,
    once: true,
  });
  const { modal, detail, once } = state;
  React.useEffect(() => {
    if (province_list?.length === 0)
      set_app_config({ params: { with_option: 1 } });
    if (once && condition && !detail) {
      setState((state) => ({ ...state, modal: true, once: false }));
    }
  }, [
    profile,
    set_profile,
    once,
    condition,
    detail,
    set_app_config,
    province_list,
  ]);
  const handleModal = (e) => {
    setState((state) => ({ ...state, modal: e }));
  };
  const handleDetail = () => {
    handleModal(true);
  };
  const handleResult = useCallback((e) => {
    set_profile({
      ...e,
      callback: () => {
        handleModal(false);
        setState((state) => ({ ...state, detail: true }));
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (e) => {
    console.log(e);
    if (e === "cancel") {
      setState((state) => ({ ...state, detail: false }));
    }
  };
  return (
    <TempMain
      title="PROFIL"
      backgroundColor="#fff"
      showBottom={false}
      bottomNavValue={4}
      page="profile"
      data={{
        component1: (
          <ShortProfiles onDetailClick={handleDetail} detailState={detail} />
        ),
        menuValue: 0,
      }}
    >
      {detail && <IdentityProfile profile={profile} onClick={handleClick} />}
      {condition && !detail && (
        <div className="text-center py-5">
          <ButtonContained
            text="Lihat Detail"
            className="px-4 py-2"
            radius="5px"
            onClick={() => handleModal(true)}
          />
        </div>
      )}
      <DialogPin
        modal={modal}
        onClose={handleModal}
        onSubmit={handleResult}
        processName="set_profile"
      />
      <DialogAlert resultName="set_profile" alertAppear="error" />
    </TempMain>
  );
};

export default connect(mapState, { set_profile, set_app_config })(IdentityPage);
