import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { chart_color_props } from '../../../config/redux/actions/option'

const useChartColors = () => {
    const chart_color = useSelector(state => state.optionReducer.chart_color)
    const dispatch = useDispatch()
    useEffect(() => {
        if (!chart_color?.length) {
            dispatch(chart_color_props())
        }
    }, [chart_color, dispatch])

    return chart_color
}

export default useChartColors
