import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/styles/makeStyles";
import classNames from "classnames";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import color from "../../../assets/theme/color";
import validation, { _isValid } from "../../../helper/validateHelper";
import BasicText from "../../atom/BasicText/BasicText";
import Countdown from "../Countdown/Countdown";
import OtpInputNumber from "../Otp";

const v_otp = validation.validateOTP();

const useStyles = makeStyles({
  otpInput: {
    width: "2em !important",
    padding: "13px 8px",
    borderRadius: 2,
    border: "1px solid " + color.gray_6,
    marginRight: 8,
    outline: "none",
  },
  otpInputM: {
    border: 0,
    borderBottom: "1px solid " + color.gray_6,
  },
  errorText: {
    color: "red !important",
    position: "absolute",
    top: 52,
  },
});

const InputOtp = forwardRef(
  (
    {
      className,
      id,
      inputClass,
      isNumber,
      onChange,
      error,
      withCountdown,
      countTime,
      onTimeOut,
      underline,
      validation,
    },
    ref
  ) => {
    const countDown = useRef();
    const otp = useRef();
    const [state, setState] = useState({ error: "" });
    useImperativeHandle(
      ref,
      () => {
        return {
          ...countDown.current,
          error: state.error,
          ...otp.current,
        };
      },
      [state.error]
    );
    function _handleChange(e) {
      if (validation) {
        _isValid(v_otp, e)
          .then(() => setError(""))
          .catch((e) => setError(e))
          .then(() => onChange(e));
      } else onChange(e);
    }
    function setError(error) {
      setState({ ...state, error });
    }
    const _error = error || state.error;
    const S = useStyles();
    return (
      <Grid container alignItems="center">
        <Grid className="position-relative">
          <OtpInputNumber
            ref={otp}
            id={id}
            onChange={_handleChange}
            className={className}
            inputClassName={classNames(
              S.otpInput,
              inputClass,
              underline && S.otpInputM
            )}
          />
          {_error && <BasicText className={S.errorText}>{_error}</BasicText>}
        </Grid>
        {withCountdown && (
          <Countdown
            ref={countDown}
            countTime={countTime}
            className="ml-3 gray-5"
            onTimeOut={onTimeOut}
          />
        )}
      </Grid>
    );
  }
);

InputOtp.defaultProps = {
  onChange: () => {},
  isNumber: false,
  error: "",
  withCountdown: false,
  countTime: 180,
  onTimeout: () => {},
  underline: false,
  validation: false,
};

export default InputOtp;
