import React from "react";
import { Card } from "@material-ui/core";

const TempDetailProductMobile = props => {
  const { data, children } = props;
  const {
    titleAndRisk,
    perform,
    allocation,
    graph,
    goals,
    detailInfo,
    twoButton,
    threeButton
  } = data;
  return (
    <div className="px-3 pt-4 margin-main">
      <Card className="px-4 py-3">
        {titleAndRisk}
        <div className="mb-3">{perform}</div>
        <div className="mb-3">{allocation}</div>

        {graph}
        {goals}
        {detailInfo}
        {twoButton}
        {threeButton}
      </Card>
      {children}
    </div>
  );
};

export default TempDetailProductMobile;
