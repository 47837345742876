import React from "react";
import Grid from "@material-ui/core/Grid";
import ilustration from "../../assets/img/ilustration";
import { useMediaQuery } from "react-responsive";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  contain: {
    [theme.breakpoints.down(769)]: {
      padding: "21px 36px 40px",
    },
  },
  containImage: {
    [theme.breakpoints.down(769)]: {
      marginBottom: 34,
    },
  },
}));

const ImageComponent = ({ children, ...other }) => {
  if (typeof children === "string") {
    return <img {...other} src={ilustration[children]} alt="" />;
  } else return children;
};

const TempProfileWithImage = ({
  children,
  image,
  className,
  imageClass,
  imgProps,
}) => {
  const S = useStyles();
  const isDekstop = useMediaQuery({ minWidth: 769 });
  return (
    <Grid
      container
      alignItems="flex-start"
      direction={isDekstop ? "row-reverse" : "column"}
      className={S.contain}
    >
      <Grid
        item
        xs={12}
        sm={4}
        container
        direction="column"
        alignItems="center"
        className={S.containImage}
      >
        {image && (
          <ImageComponent className={imageClass} {...imgProps}>
            {image}
          </ImageComponent>
        )}
      </Grid>
      <Grid item xs={12} sm={8} className={className}>
        {children}
      </Grid>
    </Grid>
  );
};

export default TempProfileWithImage;
