import React, { useEffect } from "react";
import BasicText from "../../atom/BasicText/BasicText";
import { FormGroup, FormControlLabel, Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  label: {
    fontSize: 13,
  },
});

const SurveyQuestion = (props) => {
  const classes = useStyles();
  const { risk_survey, step, onChange, value } = props;
  //   const [state, setState] = React.useState({
  //     value: {}
  //   });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleClick = (event, point, page) => {
    onChange({ [page]: { [event.target.name]: point } });
    // setState(state => ({
    //   ...state,
    //   value: { ...state.value, [page]: { [event.target.name]: point } }
    // }));
  };
  //   const { value } = state;
  return (
    <div>
      <BasicText className="font-weight-bold color-secondary mb-3">
        {step}. {risk_survey[step - 1].question}
      </BasicText>
      <FormGroup>
        {risk_survey[step - 1].profile_risk_answer.map((el, id) => (
          <FormControlLabel
          id={`${step}-${id}`}
            key={id}
            control={
              <Checkbox
                name={el.id + ""}
                checked={
                  value.hasOwnProperty(step - 1 + "")
                    ? value[step - 1].hasOwnProperty(el.id + "")
                      ? true
                      : false
                    : false
                }
                onChange={(e) => handleClick(e, el.point, step - 1)}
              />
            }
            classes={{ label: classes.label }}
            label={el.answer}
          />
        ))}
      </FormGroup>
    </div>
  );
};

export default SurveyQuestion;
