import { Button, Grid, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import classNames from "classnames";
import { isEqual } from "lodash";
import React, { PureComponent } from "react";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { number, object, string, boolean } from "yup";
import { fetch_regis_2, register } from "../../../config/redux/actions/auth";
import {
  chart_color_props,
  set_app_config,
} from "../../../config/redux/actions/option";
import {
  profile_risk,
  risk_result_by_point_props,
} from "../../../config/redux/actions/profile";
import { remove_result_l } from "../../../config/redux/actions/state";
import { decrypt, encrypt } from "../../../helper/EncryptHelper";
import processHelper from "../../../helper/processHelper";
import validation, { requireKey } from "../../../helper/validateHelper";
import BasicText from "../../atom/BasicText/BasicText";
import ButtonContained from "../../atom/Button/ButtonContained";
import DialogAlert from "../../molecule/Alert/DialogAlert";
import FormRegister from "../../organism/Register/FormRegister";
import ModalProfileRisk from "../../organism/Register/ModalProfileRisk";
import { checkStepRegister } from "../../organism/Register/registerHelper";
import TempAuth from "../../template/TempAuth";
import pageToURL from "../../../helper/pageToUrlHelper";
import BoxUpload from "../../organism/Payment/BoxUpload";
import {
  upload,
  fetch_restrict_image,
} from "../../../config/redux/actions/option";
import RouteLeavingConfirm from "../../../config/Router/RouteLeavingConfirm";

const nameFieldBank = ["nama_bank", "no_rek"];

function _rekening2(rekening) {
  return rekening >= 2;
}

function _rekening3(rekening) {
  return rekening >= 3;
}

function _getNameIndex(index) {
  return index ? `_${index + 1}` : "";
}

function _setBlackListBank(list, i) {
  return list.filter((item, index) => index !== i);
}

const BoxSelfie = ({ marginClass, onChange }) => {
  const ref = React.useRef(null);
  const loading_stack = useSelector(
    (state) => state.stateReducer.loading_stack
  );
  const selfie = useSelector((state) => state.authReducer.register.selfie);
  const resultError = useSelector(state => processHelper.findResult("upload",state.stateReducer.result_stack))
  React.useEffect(() => {
    if(selfie || resultError?.type === "error")
    ref.current.setImage(selfie || "null");
  }, [selfie, resultError]);
  return (
    <div className={marginClass}>
      <BasicText className="fs-14 font-weight-bolder color-primary mb-3 pb-3">
        Upload Foto Selfie dengan KTP Kamu
      </BasicText>
      <BoxUpload
        onChange={onChange}
        ref={ref}
        loading={processHelper.findProcess(
          ["upload", "fetch_restrict_image"],
          loading_stack
        )}
      />
    </div>
  );
};

const validationSchema = object().shape({
  no_hp: requireKey("Nomor HP"),
  email: validation.validateEmail(),
  re_regis: boolean(),
  password: string().when("re_regis", {
    is: true,
    then: string(),
    otherwise: validation.validatePasswordRegister(),
  }),
  password_confirm: string().when("re_regis", {
    is: true,
    otherwise: string()
      .required("Password harus diisi")
      .test(
        "password-match",
        "Password yang anda masukkan tidak sesuai",
        function (value) {
          return this.parent.password === value;
        }
      ),
    then: string(),
  }),
  pekerjaan: requireKey("Pekerjaan"),
  bidang_usaha: requireKey("Bidang Usaha"),
  pendidikan: requireKey("Pendidikan"),
  pendapatan: requireKey("Pendapatan"),
  sumber_pendapatan: requireKey("Sumber Pendapatan"),
  profile_risk: requireKey("Profile Risk"),
  ahli_waris: requireKey("Ahli Waris"),
  hubungan: requireKey("Hubungan Ahli Waris"),
  jabatan: requireKey("Jabatan"),
  rekening: number(),
  nama_bank: requireKey("Nama Bank"),
  no_rek: validation.validateNoRek(),
  nama_bank_2: string().when("rekening", {
    is: _rekening2,
    then: requireKey("Nama Bank 2"),
    otherwise: string(),
  }),
  no_rek_2: string().when("rekening", {
    is: _rekening2,
    then: validation.validateNoRek(),
    otherwise: string(),
  }),
  nama_bank_3: string().when("rekening", {
    is: _rekening3,
    then: requireKey("Nama Bank 3"),
    otherwise: string(),
  }),
  no_rek_3: string().when("rekening", {
    is: _rekening3,
    then: validation.validateNoRek(),
    otherwise: string(),
  }),
});

const useStyles = makeStyles({
  text : {
  fontSize : 14,
  fontWeight : 'bold',
  }
})

const Text = ({ children, className }) => {
  const classes = useStyles()
  return (
    <p className={classNames(classes.text, className)}>
      {children}
    </p>
  );
};

const NoticePassword = () => {
  const lists = [
    "• Menggunakan karakter huruf kapital dan huruf kecil.",
    "• Menggunakan karakter numerik/angka.",
    "• Panjang Minimal 8 karakter.",
  ];
  return (
    <Grid>
      <BasicText className="fs-10 font-weight-bold gray-4 mb-1">
        Password kamu harus:
      </BasicText>
      <ul className="gray-4 list-unstyled">
        {lists.map((item) => (
          <li key={item} className="fs-10">
            {item}
          </li>
        ))}
      </ul>
    </Grid>
  );
};

class RegisterProfil extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initial: null,
      disabled: [],
      rekening: [],
      validations: validationSchema,
      bank_list: [],
      next: true,
      profile_risk: {},
      blackList: [],
      sameSelfie: true,
      passwordField: [
        [{ id : "input-password",name: "password", label: "PASSWORD", type: "password" }],
        [
          {
            id : "input-password_confirm",
            name: "password_confirm",
            label: "KONFIRMASI PASSWORD",
            type: "password",
            Children: NoticePassword,
            cClass: "mb-4",
          },
          // {
          //   Children: BoxSelfie,
          //   ChildrenProps: {
          //     // selfie: this.props.register.selfie,
          //     onChange: this._getSefie,
          //     marginClass: "box-selfie",
          //   },
          // },
        ],
      ],
    };
  }

  componentDidMount = async () => {
    const {
      set_app_config,
      chart_color_props,
      location,
      register,
      history,
      set_profile_risk,
    } = this.props;
    checkStepRegister(
      location.pathname,
      history.replace,
      register.registerStatus
    );
    if (typeof register.profile_risk === "object")
      await set_profile_risk(register.profile_risk);
    window.scrollTo(0, 0);
    set_app_config({ params: { with_option: 1 } });
    chart_color_props();
    this._setInitial();
  };

  _setInitial = () => {
    const { register, profile_risk, fetch_restrict_image } = this.props;
    const {
      no_hp,
      email,
      pekerjaan,
      bidang_usaha,
      pendidikan,
      pendapatan,
      sumber_pendapatan,
      tujuan,
      user_account,
      password,
      password_confirm,
      ahli_waris,
      hubungan,
      jabatan,
      registration_token,
      url_selfie,
    } = register;
    const isReRegis = registration_token === "re-register";
    if (isReRegis) {
      fetch_restrict_image({ urlImg: url_selfie, key: "selfie" });
    }
    let disabled = ["no_hp", "profile_risk"];
    const accounts = user_account.length
      ? user_account
      : [{ id: "", account_number: "" }];
    let rekening = [];
    const { name } = profile_risk.profile_risk_type || { name: "" };
    const gembok = email && email.split("@")[0];
    let initial = {
      re_regis: isReRegis,
      no_hp: parseInt(no_hp),
      email,
      pekerjaan,
      bidang_usaha,
      pendidikan,
      pendapatan,
      sumber_pendapatan,
      tujuan,
      password: gembok ? decrypt(password, gembok) : password,
      password_confirm: gembok
        ? decrypt(password_confirm, gembok)
        : password_confirm,
      ahli_waris,
      hubungan,
      jabatan,
      rekening: accounts.length,
      profile_risk: name,
    };
    let bank_list = [];
    accounts.forEach((item, index) => {
      const indexName = _getNameIndex(index);
      const name_bank = `nama_bank${indexName}`;
      const no_rek = `no_rek${indexName}`;
      initial[name_bank] = item.bank_branch_id || item.id;
      initial[no_rek] = item.account_number;
      rekening.push(this.renderFieldRekening(index));
      if (item.id !== "") bank_list.push({ id: item.id, name: item.name });
    });
    const _state = { initial, rekening, bank_list, next: false };
    if (isReRegis) {
      disabled.push("email");
      _state.passwordField = [];
    }
    if (isReRegis || register.sid)
      disabled = [...disabled];
    this.setState({ ..._state, disabled });
  };

  renderFieldRekening = (n) => {
    const index = _getNameIndex(n);
    const name = `nama_bank${index}`;
    const no_rek_name = `no_rek${index}`;
    return [
      { name, label: " NAMA BANK", typeInput: "SelectsInput" },
      { name: no_rek_name, label: " NOMOR REKENING", type: "number" },
    ];
  };

  componentDidUpdate = (prevProps) => {
    const { result_stack, optionReducer, history } = this.props;
    const { bank_list } = optionReducer;
    if (result_stack !== prevProps.result_stack) {
      const _res = processHelper.findResult("register2", result_stack);
      if (_res.name && _res.type === "success") {
        this._saveDataPersist(true);
        this.setState({ next: true }, () =>
          history.push("/register/tanda-tangan")
        );
      }
    } else if (
      bank_list.length !== prevProps.optionReducer.bank_list.length ||
      bank_list.length !== this.state.bank_list.length
    ) {
      this._updateBankList();
    }
  };

  _updateBankList = () => {
    const { bank_list } = this.props.optionReducer;
    const _res = bank_list.filter(
      (item) => this.state.bank_list.findIndex((it) => it.id === item.id) < 0
    );
    if (_res.length)
      this.setState((state) => ({
        bank_list: [...state.bank_list, ..._res],
      }));
  };

  _getSefie = (e) => {
    if (this.state.sameSelfie) {
      this.setState({ sameSelfie: false });
    }
    this.props.upload({ file: e, key: "selfie" });
  };

  _checkUpdateRisk = (data) => {
    const { profile_risk, set_profile_risk } = this.props;
    const { name } = data.profile_risk_type || {};
    const { setFieldValue } = this.refs.formik;
    if (!isEqual(data, profile_risk)) {
      setFieldValue("profile_risk", name);
      set_profile_risk(data);
    }
  };

  _handleSurveyRisk = () => {
    if (this.props.profile_risk.profile_risk_type)
      this.refs.modal.handleVisible();
    else this._goToSurvey();
  };

  _saveDataPersist = (withRisk) => {
    const { setRegister,register } = this.props;
    const { bank_list } = this.state;
    const { getValues } = this.refs.formik;
    const {
      profile_risk,
      no_hp,
      nama_bank,
      no_rek,
      nama_bank_2,
      no_rek_2,
      nama_bank_3,
      no_rek_3,
      password,
      password_confirm,
      ...otherValues
    } = getValues();
    let rekening = [nama_bank, nama_bank_2, nama_bank_3];
    let user_account = [];
    rekening.forEach((item, index) => {
      const i = _getNameIndex(index);
      if (item)
        user_account.push({
          ...register.user_account.find((account) => Number(account.id)  === Number(item)),
          ...bank_list.find((bank) => Number(bank.id)  === Number(item)),
          account_number: getValues()[`no_rek${i}`],
        });
      else return;
    });
    const _profile_risk = withRisk ? this.props.profile_risk : undefined;
    const gembok = otherValues.email && otherValues.email.split("@")[0];
    setRegister({
      ...otherValues,
      password: gembok ? encrypt(password, gembok) : password,
      password_confirm: gembok
        ? encrypt(password_confirm, gembok)
        : password_confirm,
      user_account,
      profile_risk: _profile_risk,
    });
  };

  _goToSurvey = () => {
    this._saveDataPersist();
    this.setState({ next: true }, () =>
      this.props.history.push(pageToURL.survey_risk, { fromRegister: true })
    );
  };

  _handleCustomBank = (action) => {
    let rekening = [...this.state.rekening];
    const { setFieldValue, setFieldTouched } = this.refs.formik;
    const isAdd = action === "add";
    const isMin = action === "min";
    let length = rekening.length;
    if (isAdd && length < 3) {
      rekening.push(this.renderFieldRekening(length));
      length++;
    } else if (isMin && length > 1) {
      length--;
      const indexName = _getNameIndex(length);
      rekening = rekening.filter((item, index) => index !== length);
      nameFieldBank.forEach((item) => {
        setFieldValue(`${item}${indexName}`, undefined);
        setFieldTouched(`${item}${indexName}`, false);
      });
    }
    setFieldValue("rekening", length);
    this.setState({ rekening });
  };

  _triggerBank = (value, name) => {
    if (name.search("nama_bank") !== -1) {
      const blackList = [...this.state.blackList];
      const index = name.split("_")[2];
      const _index = index ? index - 1 : 0;
      blackList[_index] = parseInt(value);
      this.setState({ blackList });
    }
  };

  _handleSubmit = (data) => {
    const { profile_risk, register } = this.props;
    const { profile_risk_point, profile_risk_type } = profile_risk;
    const {
      email,
      password,
      password_confirm,
      pekerjaan,
      bidang_usaha,
      pendidikan,
      pendapatan,
      sumber_pendapatan,
      tujuan,
      // profile_risk,
      ahli_waris,
      hubungan,
      jabatan,
    } = data;
    const isSameSelfie =
      this.state.sameSelfie && register.registration_token === "re-register";
    const _res = {
      email,
      password,
      password_confirm,
      job_id: pekerjaan,
      job_category_id: bidang_usaha,
      education_id: pendidikan,
      income_id: pendapatan,
      source_income_id: sumber_pendapatan,
      investment_objective_id: tujuan,
      "bank_id[]": [],
      "account_number[]": [],
      profile_risk_id: profile_risk_type.id,
      profile_risk_point,
      waris_name: ahli_waris,
      relation_id: hubungan,
      job_role_id: jabatan,
      selfie: isSameSelfie ? "same" : this.props.register.selfie,
    };
    for (let index = 0; index < 3; index++) {
      const indexName = _getNameIndex(index);
      const cond = data[`no_rek${indexName}`] !== undefined;
      if (cond) {
        _res["bank_id[]"].push(data[`nama_bank${indexName}`]);
        _res["account_number[]"].push(data[`no_rek${indexName}`]);
      } else break;
    }
    this.props.fetch_regis_2(_res);
  };

  render() {
    const {
      initial,
      disabled,
      rekening,
      bank_list,
      blackList,
      passwordField,
    } = this.state;
    const { optionReducer, loading_stack, register: {sid} } = this.props;
    const {
      job_list,
      job_role_list,
      waris_list,
      job_category_list,
      education_list,
      income_list,
      income_source_list,
      investment_objective_list,
    } = optionReducer;
    let rekeningField = [];
    rekening.forEach((item, index) => {
      rekeningField.push([
        {
          ...item[0],
          id : `bank-${index}`,
          data: bank_list,
          trigger: true,
          blackList: _setBlackListBank(blackList, index),
        },
        {
          ...item[1],
          id : `no-rek-${index}`
        }
        
      ]);
    });
    const length = rekening.length;
    const actionRekening = [
      { cond: length > 1, id: "del-bank", text: "Urungkan", code: "min", Icon: RemoveIcon },
      { cond: length < 3, id: "add-bank", text: "Tambah Rekening", code: "add", Icon: AddIcon },
    ];
    return (
      <TempAuth page="regisForm" data={{ active: 1, showStepper: true }}>
        {/* <Prompt
          when={!next}
          message="Yakin Keluar dari Registrasi?, apa bila anda keluar maka anda akan mengulang reisgtrasi dari awal"
        /> */}
        <RouteLeavingConfirm
          acceptLeave={() => this.props.history.go(-2)}
          showConfirmation={false}
        />
        <Grid>
          {initial && (
            <FormRegister
              ref="formik"
              idSubmit="register-4"
              initial={initial}
              disabledInputs={disabled}
              loadingSubmit={processHelper.findProcess(
                "register2",
                loading_stack
              )}
              onSubmit={this._handleSubmit}
              validation={validationSchema}
              functionTrigger={this._triggerBank}
              data={[
                [
                  {
                    Children: BoxSelfie,
                    ChildrenProps: {
                      // selfie: this.props.register.selfie,
                      onChange: this._getSefie,
                    },
                  },
                ],
                [
                  {
                    children : (
                      <Text className="gray-3 mb-3">
                        Isi data kamu yang sebenarnya
                      </Text>
                  )
                  }
                ],
                [
                  {
                    children : <Text className="color-primary mb-0">PROFIL</Text>
                  }
                ],
                [
                  {
                     id: "input_no_hp",
                     name: "no_hp",
                    placeholder: "081234567890",
                    label: "NOMOR HANDPHONE",
                    type: "number",
                  },
                  {
                    id: "input_email",
                    name: "email",
                    placeholder: "surename@email.com",
                    label: "EMAIL",
                    type: "email",
                    cClass: "mb-4",
                  },
                ],
                ...passwordField,
                [
                  {
                    id: "input_pekerjaan",
                    name: "pekerjaan",
                    label: "PEKERJAAN",
                    typeInput: "SelectsInput",
                    data: job_list,
                  },
                  {
                    id: "input_jabatan",
                    name: "jabatan",
                    label: "JABATAN",
                    typeInput: "SelectsInput",
                    data: job_role_list,
                  },
                ],
                [
                  {
                    id: "input_bidang_usaha",
                    name: "bidang_usaha",
                    label: "BIDANG USAHA TEMPAT BEKERJA",
                    typeInput: "SelectsInput",
                    data: job_category_list,
                  },
                  {
                    id: "input_pendidikan",
                    name: "pendidikan",
                    label: "PENDIDIKAN",
                    typeInput: "SelectsInput",
                    data: education_list,
                  },
                ],
                [
                  {
                    id: "input_pendapatan",
                    name: "pendapatan",
                    label: "PENDAPATAN",
                    typeInput: "SelectsInput",
                    data: income_list,
                  },
                  {
                    id: "input_sumber_pendapatan",
                    name: "sumber_pendapatan",
                    label: "SUMBER PENDAPATAN",
                    typeInput: "SelectsInput",
                    data: income_source_list,
                  },
                ],
                [
                  {
                    id: "input_tujuan",
                    name: "tujuan",
                    label: "TUJUAN INVESTASI",
                    typeInput: "SelectsInput",
                    data: investment_objective_list,
                  },
                  {
                    id: "input_ahli_waris",
                    name: "ahli_waris",
                    label: "AHLI WARIS",
                  },
                ],
                [
                  {
                    id: "input_hubungan",
                    name: "hubungan",
                    label: "HUBUNGAN AHLI WARIS",
                    typeInput: "SelectsInput",
                    data: waris_list,
                  },
                ],
                [
                  {
                    id: "input_profile_risk",
                    name: "profile_risk",
                    label: "PROFIL RISIKO",
                    cClass: "mb-4",
                  },
                  {
                    children: (
                      <ButtonContained
                        id="button-profile-risk"
                        onClick={this._handleSurveyRisk}
                        text="CEK/UBAH PROFIL RISIKOMU"
                        style={{ height: 46, marginTop: 25.2 }}
                        className="fs-12 w-80"
                        radius="4px"
                      />
                    ),
                    cClass: "",
                  },
                ],
                {
                  children: (
                    <BasicText className="fs-14 color-primary font-weight-bold">
                      INFORMASI REKENING BANK
                    </BasicText>
                  ),
                },
                ...rekeningField,
                {
                  children: ( 
                    !sid &&
                    <Grid container justify="flex-end">
                      {actionRekening.map((item, index) => {
                        const { cond, code, Icon, text,id, } = item;
                        return (
                          cond && (
                            <Button
                              id={id}
                              key={index}
                              onClick={() => this._handleCustomBank(code)}
                              className="fs-14 gray-4 text-capitalize ml-2"
                              endIcon={<Icon className="fs-14" />}
                              disabled={initial.re_regis}
                            >
                              {text}
                            </Button>
                          )
                        );
                      })}
                    </Grid>
                  ),
                },
              ]}
            />
          )}
        </Grid>
        <ModalProfileRisk
          ref="modal"
          onSubmit={this._checkUpdateRisk}
          onSurvey={this._goToSurvey}
          onSubmitBlur
        />
        <DialogAlert resultName={["register2","upload"]} alertAppear="error" />
      </TempAuth>
    );
  }
}

const mapStateToProps = ({
  authReducer,
  stateReducer,
  optionReducer,
  profileReducer,
}) => ({
  register: authReducer.register,
  loading_stack: stateReducer.loading_stack,
  optionReducer,
  profile_risk: profileReducer.profile_risk,
  result_stack: stateReducer.result_stack,
});
const mapDispatchToProps = {
  set_app_config,
  setRegister: register,
  chart_color_props,
  remove_result_l,
  fetch_regis_2,
  risk_result_by_point_props,
  set_profile_risk: profile_risk,
  upload,
  fetch_restrict_image,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RegisterProfil)
);
