import React, { useCallback } from "react";
import Grid from "@material-ui/core/Grid";
import ItemTypeFilter from "./ItemTypeFilter";
import { useMediaQuery } from "react-responsive";
import ButtonLink2 from "../../../atom/Button/ButtonLink2";
import DialogFilterTxn from "./DialogFilterTxn";
import { useRef } from "react";
const { useMemo, useState } = React;

const ContentFilter = ({ onSubmit }) => {
  const isDesktop = useMediaQuery({ minWidth: 769 });
  const refDialog = useRef();
  const [state, setState] = useState({
    txn_type_code: undefined,
    txn_status_code: undefined,
    period: undefined,
  });
  const BASE_FILTER = useMemo(
    () => ({
      txn_type_code: [
        { title: "Semua", key: undefined },
        { title: "Pembelian", key: "S" },
        { title: "Penjualan", key: "R" },
        { title: "Konversi", key: "D" },
      ],
      txn_status_code: [
        { title: "Semua", key: undefined },
        { title: "Berhasil", key: "APPROVED" },
        { title: "Pending", key: "WAITING" },
        { title: "Gagal", key: "DECLINED" },
      ],
      period: [
        { title: "1 Tahun", key: "1y" },
        { title: "6 Bulan", key: "6m" },
        { title: "3 Bulan", key: "3m" },
        { title: "1 Bulan", key: "1m" },
      ],
    }),
    []
  );

  const handleSubmit = useCallback(
    (newState) => {
      let state = {};
      setState((prev) => {
        state = { ...prev, ...newState };
        return state;
      });
      onSubmit(state);
    },
    [onSubmit]
  );

  const handleChange = useCallback(
    (value, name) => {
      handleSubmit({ [name]: value });
    },
    [handleSubmit]
  );

  if (isDesktop)
    return (
      <div className="card-custom d-flex mb-3">
        <p className="fs-12 basic font-weight-bold mr-4">Filter</p>
        <Grid container spacing={4}>
          <Grid xs={12} lg={4} item>
            <ItemTypeFilter
              name="txn_type_code"
              label="Transaksi"
              data={BASE_FILTER["txn_type_code"]}
              onChange={handleChange}
              active={state["txn_type_code"]}
            />
          </Grid>
          <Grid xs={12} lg={4} item>
            <ItemTypeFilter
              name="txn_status_code"
              label="Status"
              data={BASE_FILTER["txn_status_code"]}
              onChange={handleChange}
              active={state["txn_status_code"]}
            />
          </Grid>
          <Grid xs={12} lg={4} item>
            <ItemTypeFilter
              name="period"
              label="Periode"
              data={BASE_FILTER["period"]}
              onChange={handleChange}
              active={state["period"]}
            />
          </Grid>
        </Grid>
      </div>
    );

  return (
    <div className="d-flex justify-content-end mb-2">
      <ButtonLink2 text="Filter" onClick={() => refDialog.current.onOpen()} />
      <DialogFilterTxn
        ref={refDialog}
        onSubmit={handleSubmit}
        defaultValue={state}
      />
    </div>
  );
};

export default ContentFilter;
