import { withStyles } from "@material-ui/core";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import stateCallApi from "../../config/API/stateCallApi";
import { get_acc_bank_props } from "../../config/redux/actions/profile";
import { gbl_act_lc } from "../../config/redux/actions/state";
import { confirm_payment_data } from "../../config/redux/actions/transaction";
import processHelper from "../../helper/processHelper";
import SnackAlert from "../molecule/Alert/SnackAlert";
import CardInvestBerkala from "../molecule/CardInvestBerkala";
import CardTransfer from "../molecule/Payment/CardTransfer";
import KetentuanInvoice from "../molecule/Payment/KetentuanInvoice";
import ContainerWithLoader from "../organism/Container/ContainerWithLoader";
import OrderResult from "../organism/Order/OrderResult";
import TempMain from "../template/TempMain";

const styles = (theme) => ({
  rowSummaryTxn: {
    marginBottom: 11,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .text": {
      fontSize: 12,
    },
  },
});

export class InvoicePage extends Component {
  constructor(props) {
    super(props);
    this.isRDP = props.location.state?.data.some(
      ({ product }) => product.is_rdp
    );
    this.state = {
      summaryTxn: [],
      periodic: {},
    };
  }
  componentDidMount() {
    get_acc_bank_props();
    const { summary, data } = this.props.location.state || {};
    const summaryTxn = [
      { title: "Total Pembelian", value: summary.fr_amount },
      {
        title: "Total Biaya",
        value:
          data
            ?.map(({ amount_fee }) => amount_fee)
            .reduce((a, b) => a + b, 0) ?? 0,
      },
      {
        title: "Total Pembayaran",
        value: summary.fr_amount,
        itemClass: {
          title: "font-weight-bold",
          value: "fs-14 font-weight-medium color-primary",
        },
      },
    ];
    this.setState({ summaryTxn });
  }

  addPeriodicInvest = (other) => {
    const { gbl_act_lc, location } = this.props;
    const { txn_group_id, user_goal_id } = location.state.summary;
    gbl_act_lc({
      ...stateCallApi.periodic_add,
      params: { ...other, user_goal_id, txn_group_id },
    });
  };

  onSubmit = () => {
    let { bank_account, history, location } = this.props;
    bank_account = bank_account.filter((e) => e.is_priority === 1);
    console.log(`bank_account`, bank_account);
    history.push(
      "/confirm-payment",
      location.state.data.map(({ id }) => id)
    );
  };
  // DEPRECATED
  // handlePeriodic = (data) => {
  //   const {
  //     location: { state },
  //   } = this.props;
  //   const {
  //     is_active,
  //     invest_cycle,
  //     make_payment_on,
  //     is_continous,
  //     end_date,
  //   } = data;
  //   let resData = {};
  //   if (is_active) {
  //     resData = {
  //       user_goal_id: state.data[0]?.user_goal_id || null,
  //       is_active: 1,
  //       is_auto_debet: 0,
  //       invest_cycle,
  //       is_continous: is_continous === 2 ? 0 : 1,
  //       [is_continous === 2 ? "end_date" : undefined]:
  //         is_continous === 2 ? end_date : undefined,
  //       [invest_cycle === "daily" ? undefined : "make_payment_on"]:
  //         invest_cycle === "daily" ? undefined : make_payment_on,
  //     };
  //   }
  //   this.setState((state) => ({ ...state, periodic: resData }));
  // };

  linkTo = () => {
    this.props.history.replace("/transaksi", { tab: 1 });
  };

  handleBack = () => {
    this.props.history.replace("/product");
  };

  render() {
    const { location, loading_stack } = this.props;
    const { data, periodicState, totalBonus, summary } = location.state || {};
    const loadingAdd = processHelper.findProcess("periodic-add", loading_stack);
    return (
      <TempMain title="PEMBAYARAN" showBottom={false}>
        <ContainerWithLoader processName={"get_acc_bank"} loadingHeight="70vh">
          <div className="pt-3 px-3 pt-md-4 px-md-4 margin-main">
            {Array.isArray(data) &&
              data.map((el, id) => (
                <Fragment key={id}>
                  {el.payment && <CardTransfer payment={el.payment} />}
                  <OrderResult
                    product={el.product}
                    value={el.nett_txn}
                    fee={el.amount_fee}
                    cashback={totalBonus}
                    className="mb-3"
                    mode="invoice"
                  />
                </Fragment>
              ))}
            {!this.isRDP && (
              <CardInvestBerkala
                initialActive={periodicState}
                statePeriodic={summary.periodic_investment}
                loading={loadingAdd}
                // onChange={this.handlePeriodic}
                onSubmit={this.addPeriodicInvest}
              />
            )}
            <KetentuanInvoice
              style={{ marginTop: 33 }}
              onSubmit={this.onSubmit}
              onBack={this.handleBack}
              loading={loadingAdd}
            />
            <SnackAlert name="get_invoice" />
            <SnackAlert name="periodic-add" alertAppear="both" />
          </div>
        </ContainerWithLoader>
      </TempMain>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bank_account: state.profileReducer.bank_account,
    loading_stack: state.stateReducer.loading_stack,
  };
};

const mapDispatchToProps = {
  get_acc_bank_props,
  confirm_payment_data,
  gbl_act_lc,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStyles(styles)
)(InvoicePage);
