import React from "react";
import { useTheme } from "@material-ui/core";
import classNames from "classnames";

const styleDot = {
  width: 13,
  height: 13,
};

const Dot = React.memo(
  ({ active, onClick, index }) => {
    const theme = useTheme();
    return (
      <div
        id={`page-${index}`}
        className={`rounded-1 is-link ${index ? "ml-2" : ""}`}
        style={{
          ...styleDot,
          backgroundColor: active
            ? theme.palette.primary.main
            : theme.palette.secondary[7],
        }}
        onClick={() => onClick(index)}
      />
    );
  },
  (prev, next) => prev.active === next.active
);

const PaginationDot = ({ page, pages, onChange, className }) => {
  return (
    <div className={classNames("d-flex justify-content-center", className)}>
      {Array(pages)
        .fill("")
        .map((_, index) => (
          <Dot
            key={`pagination-${index}`}
            index={index}
            active={index === page}
            onClick={onChange}
          />
        ))}
    </div>
  );
};

PaginationDot.defaultProps = {
  onChange: () => {},
};

export default React.memo(
  PaginationDot,
  (prev, next) => prev.page === next.page && prev.pages === next.pages
);
