import { Formik } from "formik";
import React, { forwardRef, useRef } from "react";
import { useImperativeHandle } from "react";
import { object } from "yup";

const FormProvider = forwardRef(
  ({ children, initialValues, validationSchema, onSubmit }, ref) => {
    const formikRef = useRef();
    useImperativeHandle(ref, () => {
      const { handleSubmit, setFieldValue } = formikRef.current;
      return {
        handleSubmit,
        setFieldValue,
        getValues,
      };
    });
    function getValues() {
      return formikRef.current.values;
    }
    return (
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {children}
      </Formik>
    );
  }
);

FormProvider.defaultProps = {
  initialValues: {},
  validationSchema: object().shape({}),
  onSubmit: () => {},
};

export default FormProvider;
