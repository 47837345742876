import makeStyles from "@material-ui/styles/makeStyles";
import PropTypes from "prop-types";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import icon from "../../../assets/img/icon/profile/examplebank.svg";
import processHelper from "../../../helper/processHelper";
import BasicText from "../../atom/BasicText/BasicText";
import ButtonContained from "../../atom/Button/ButtonContained";
import ButtonOutline from "../../atom/Button/ButtonOutline";
const useStyles = makeStyles((theme) => {
  const isMobile = theme.breakpoints.down("md");
  return {
    imgBank: { width: 60 },
    content: {
      display: "flex",
      flexDirection: "column-reverse",
      [isMobile]: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      },
    },
    contectAction: {
      display: "flex",
      alignItems: "center",
      marginTop: 8,
    },
    buttonAction: {
      padding: "3px 13px",
      fontSize: 10,
      marginRight: 6,
    },
  };
});

const AccountBankItem = ({
  name,
  account_number,
  behalf,
  logo,
  onClick,
  id,
  prior,
  acc_bank,
  status,
}) => {
  const classes = useStyles();

  const handleClick = (event) => {
    onClick(event, id, prior);
  };

  const isPriority = prior === 1;
  const isSelected = acc_bank === id;
  const isPending = status === "PENDING";

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div>{renderContent(name, account_number, behalf)}</div>
        <div>
          <img
            src={logo !== null ? logo : icon}
            alt="logo"
            className={classes.imgBank}
          />
        </div>
      </div>
      <div className={classes.contectAction}>
        {isPending ? (
          <ButtonContained
            text="MENUNGGU APPROVAL"
            className={classes.buttonAction}
            radius="20px"
            color="secondary"
          />
        ) : isPriority ? (
          <ButtonOutline
            borderColor="primary"
            className={classes.buttonAction}
            text="REKENING UTAMA"
            radius="20px"
          />
        ) : (
          <RenderNonPrimaryAction
            onClick={handleClick}
            isSelected={isSelected}
          />
        )}
      </div>
    </div>
  );
};

// #region - render
const renderContent = (name, account_number, behalf) => {
  return (
    <>
      <BasicText className="fs-14 font-weight-medium" color="secondary">
        {name}
      </BasicText>
      <BasicText className="fs-14" color="secondary" colorLevel="light">
        {account_number}
      </BasicText>
      <BasicText className="fs-14" color="secondary" colorLevel="light">
        a.n <span className="text-capitalize">{behalf?.toLowerCase()}</span>
      </BasicText>
    </>
  );
};

const RenderNonPrimaryAction = ({ onClick, isSelected }) => {
  const loadingPriority = useSelector((state) =>
    processHelper.findProcess(
      "set_priority_bank",
      state.stateReducer.loading_stack
    )
  );
  const loadingDelete = useSelector((state) =>
    processHelper.findProcess("del_acc_bank", state.stateReducer.loading_stack)
  );
  const classes = useStyles();
  return (
    <>
      <ButtonOutline
        borderColor="secondary"
        className={classes.buttonAction}
        text="JADIKAN UTAMA"
        loading={loadingPriority && isSelected}
        onClick={() => onClick("prior")}
        radius="20px"
      />
      <ButtonOutline
        borderColor="secondary"
        className={classes.buttonAction}
        text="EDIT"
        onClick={() => onClick("update")}
        radius="20px"
      />
      <ButtonContained
        text="HAPUS"
        onClick={() => onClick("delete")}
        className={classes.buttonAction}
        loading={loadingDelete && isSelected}
        radius="20px"
        color="secondary"
      />
    </>
  );
};

// #endregion

AccountBankItem.propTypes = {
  name: PropTypes.string,
  account_number: PropTypes.string,
  behalf: PropTypes.string,
  logo: PropTypes.string,
};

export default memo(AccountBankItem);
