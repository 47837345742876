import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import makeStyles from "@material-ui/styles/makeStyles";
import classNames from "classnames";
import React from "react";
import { useHistory } from "react-router-dom";
// import BasicText from "../../atom/BasicText/BasicText";
import logo from "../../../assets/img/logo";
import color from "../../../assets/theme/color";
import BasicText from "../../atom/BasicText/BasicText";
import MediaQuery from "../../organism/MediaQuery/MediaQuery";

const useStyles = makeStyles({
  icon: {
    position: "absolute",
    top: "25px",
    right: "10px",
    fontSize: "21px",
    color: color.primary,
  },
});

function BasicHeader(props) {
  const { push } = useHistory();
  const classes = useStyles();
  const { marginClass, title } = props;
  return (
    <div className={classNames(marginClass, "w-100")}>
      <MediaQuery device="mobile">
        <div className="position-relative text-center py-4 mb-3">
          <img src={logo.logo} alt="" style={{ height: "21px" }} />{" "}
          <HelpOutlineIcon className={classes.icon} />
        </div>
      </MediaQuery>
      <MediaQuery device="desktop">
        <div className="d-flex justify-content-between">
          <img
            src={logo.logo}
            alt=""
            style={{ height: 30, cursor: "pointer" }}
            onClick={() => push("/masuk")}
          />
          {typeof title === "string" ? (
            <BasicText
              className="fs-16 font-weight-medium align-self-end"
              color="primary"
            >
              {title || ""}
            </BasicText>
          ) : (
            title
          )}
        </div>
        <hr className="divider-dotted" />
      </MediaQuery>
    </div>
  );
}

BasicHeader.defaultProps = {
  title: "",
};

export default BasicHeader;
