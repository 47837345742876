import React, { useCallback, useEffect, useMemo, useState } from "react";
import TempMain from "../template/TempMain";
import BasicText from "../atom/BasicText/BasicText";
import InputText from "../molecule/MaterialInput/InputText";
import { useDispatch, useSelector } from "react-redux";
import { selectorGetVoucherMerchant } from "../../config/redux/reducer/optionReducer";
import TabCustom from "../molecule/Tabs/TabCustom";
import { Grid, makeStyles } from "@material-ui/core";
import { gbl_act_lc } from "../../config/redux/actions/state";
import stateCallApi from "../../config/API/stateCallApi";
import { Search } from "@material-ui/icons";

const VoucherMerchant = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const allVoucher = useSelector(selectorGetVoucherMerchant);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(0);
  const [search, setSearch] = useState("");

  const vouchers = useMemo(() => {
    function handleFilter(item) {
      return (
        item.merchant.merchant_category_id === categories[category].id &&
        !(search && !item.code.includes(search.toUpperCase()))
      );
    }
    return categories.length ? allVoucher.filter(handleFilter) : allVoucher;
  }, [allVoucher, categories, category, search]);

  const renderItem = useCallback(
    (item) => (
      <Grid key={item.id} item xs={12} sm={6} lg={4}>
        <div className="card-custom p-0">
          <div className="d-flex justify-content-between align-items-center py-3 px-4">
            <img alt="" src={item.merchant?.logo_url} width={32} height={32} />
            <div className="flex pl-3">
              <BasicText className="fs-12 fw-700 mb-3">{item.title}</BasicText>
              <BasicText className="fs-12">KODE: {item.code}</BasicText>
            </div>
          </div>
          <hr className="divider-dashed" />
          <div className="d-flex justify-content-between align-items-center px-4 py-3">
            <BasicText className="fs-12">{item.fr_expired_at}</BasicText>
            <a
              className={classes.linkSeeAll}
              href={`/voucher-merchant/detail?id=${item.id}`}
            >
              Gunakan Sekarang
            </a>
          </div>
        </div>
      </Grid>
    ),
    [classes.linkSeeAll]
  );

  useEffect(() => {
    dispatch(
      gbl_act_lc({
        ...stateCallApi.voucherMerchantCategory,
        callback: setCategories,
      })
    );
  }, [dispatch, setCategories]);

  useEffect(() => {
    if (!allVoucher.length) {
      dispatch(gbl_act_lc(stateCallApi.voucherMerchantList));
    }
  }, [allVoucher.length, dispatch]);

  return (
    <TempMain
      bottomNavValue={0}
      goBackButton
      initialPage
      title="VOUCHER"
      showBottom={false}
    >
      <div className="m-md-4 mb-5">
        <div className="card-custom">
          <BasicText className="fs-13 fw-700 mb-3">
            Temukan Kode Voucher
          </BasicText>
          <InputText
            placeholder="Ketik kode voucher"
            containClass={classes.inputSearch}
            StartIcon={Search}
            startIconClass="gray-4"
            value={search}
            onChange={setSearch}
          />
        </div>
        {categories.length ? (
          <TabCustom
            data={categories.map(({ name }) => name)}
            active={category}
            onChange={setCategory}
            className="my-md-4"
          />
        ) : null}
        <div className="m-4 m-md-0">
          <Grid container spacing={4}>
            {vouchers.map(renderItem)}
          </Grid>
        </div>
      </div>
    </TempMain>
  );
};

const useStyles = makeStyles((theme) => ({
  inputSearch: {
    "& fieldset": {
      borderRadius: 0,
      border: "unset",
      borderBottom: `1px solid ${theme.palette.secondary.light}`,
    },
  },
  linkSeeAll: {
    textDecoration: "unset !important",
    fontWeight: "700",
    fontSize: 12,
    color: `${theme.palette.primary.main} !important`,
  },
}));

export default VoucherMerchant;
