import * as types from "./types";

export const set_auth = (payload) => {
  return {
    type: types.SET_AUTH,
    payload,
  };
};
export const log_in = (payload) => {
  return {
    type: types.LOG_IN,
    payload,
  };
};

export const log_out_props = () => ({
  type :types.LOG_OUT_PROPS
})

export const log_out = () => {
  return {
    type: types.LOG_OUT,
  };
};
export const register = (payload) => {
  return {
    type: types.REGISTER,
    payload,
  };
};
export const syaiacc = (payload) => {
  return {
    type: types.SYAIACC,
    payload,
  };
};
export const clear_register = (payload) => ({
  type: types.CLEAR_REGISTER,
  payload,
});
export const set_user = () => {
  return {
    type: types.SET_USER,
  };
};
export const validate_pin_props = (payload) => {
  return {
    type: types.VALIDATE_PIN_PROPS,
    payload,
  };
};
export const change_pass_props = (payload) => {
  return {
    type: types.CHANGE_PASS_PROPS,
    payload,
  };
};
export const request_otp_props = (payload) => {
  return {
    type: types.REQUEST_OTP_PROPS,
    payload,
  };
};
export const resend_otp_props = () => {
  return {
    type: types.RESEND_OTP_PROPS,
  };
};
export const validate_otp_props = (payload) => {
  return {
    type: types.VALIDATE_OTP_PROPS,
    payload,
  };
};
export const change_pin_props = (payload) => {
  return {
    type: types.CHANGE_PIN_PROPS,
    payload,
  };
};
export const forgot_pin_props = (payload) => {
  return {
    type: types.FORGOT_PIN_PROPS,
    payload,
  };
};
export const fetch_regis_valid = (payload) => ({
  type: types.FETCH_REGIS_VALIDATION,
  payload,
});
export const regis_request_otp = (payload) => ({
  type: types.FETCH_REGIS_REQUEST_OTP,
  payload,
});
export const regis_validate_otp = (payload) => ({
  type: types.FETCH_REGIS_VALIDATE_OTP,
  payload,
});
export const fetch_data_ocr = (payload) => ({
  type: types.FETCH_DATA_OCR,
  payload,
});
export const fetch_data_sid = (payload) => ({
  type: types.FETCH_DATA_SID,
  payload,
});
export const fetch_forgot_pass = (payload) => ({
  type: types.FETCH_FORGOT_PASS,
  payload,
});
export const fetch_regis_1 = (payload) => ({
  type: types.FETCH_REGIS_1,
  payload,
});
export const fetch_regis_2 = (payload) => ({
  type: types.FETCH_REGIS_2,
  payload,
});
export const fetch_regis_3 = (payload) => ({
  type: types.FETCH_REGIS_3,
  payload,
});
export const fetch_regis_4 = (payload) => ({
  type: types.FETCH_REGIS_4,
  payload,
});
export const set_temp_regis = (payload) => ({
  type: types.SET_TEMP_REGIS,
  payload,
});

export const resend_verif_email = (payload) => ({
  type: types.FETCH_RESEND_VERIF_EMAIL,
  payload,
});

export const re_regis = (payload) => ({
  type: types.RE_REGIS,
  payload,
});

export const fetch_re_regis = (payload) => ({
  type: types.FETCH_RE_REGIS,
  payload,
});
