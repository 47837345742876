import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import BasicText from "../../atom/BasicText/BasicText";
import textHelper from "../../../helper/textHelper";
import classNames from "classnames";
import { useMemo } from "react";

const SwitchResult = ({ portoData, value, productData, products }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      marginBottom: 8,
      [theme.breakpoints.up(768)]: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
      "& .subRoot": {
        // marginBottom: 10,
        // [theme.breakpoints.up(768)]: {
        //   maxWidth: 420,
        //   marginBottom: 10,
        // },
      },
    },
  }));
  const classes = useStyles();
  const result = useMemo(() => {
    if (Object.keys(productData).length) {
      const { fee_swout } =
        products[
        products.findIndex((item) => item.code === portoData.product_code)
        ];
      const { fee_swin, nav_per_unit } = productData;
      const tmp =
        (Number(value) - Number(fee_swout) - Number(fee_swin)) /
        Number(nav_per_unit);
      return tmp;
    }
  }, [portoData, productData, products, value]);

  return (
    <Grid container className={classNames(classes.root, "card-custom")}>
      <Grid item xs={12} md={7} lg={5}>
        <div className="d-flex justify-content-between  subRoot">
          <BasicText
            className="font-weight-bold fs-12 my-auto"
          >
            {/* Perkiraan Unit Hasil Konversi */}
            Perkiraan Nilai Penjualan
          </BasicText>
          <BasicText className="fs-14 font-weight-semibold color-primary ml-md-3">
            {textHelper.printUnits(result)}
          </BasicText>
        </div>
      </Grid>
    </Grid>
  );
};

export default SwitchResult;
