import React from "react";
import { formatUrl } from "../../../helper/textHelper";

const CardTrending = (props) => {
  const { items, className, style } = props;
  const { image, id } = items;
  return (
    <div id={`trending-${id}`} className={className} style={style}>
      <div
        className="rounded-2 shadow-card mb-1"
        style={{
          backgroundPosition: "center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundColor: "white",
          backgroundImage: `url(${formatUrl(image)})`,
          height: 252,
          width: 295,
        }}
      ></div>
    </div>
  );
};

export default CardTrending;
