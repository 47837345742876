import { makeStyles } from "@material-ui/styles";
import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import {
  change_pass_props,
  request_otp_props,
} from "../../../config/redux/actions/auth";
import processHelper from "../../../helper/processHelper";
import BasicText from "../../atom/BasicText/BasicText";
import DialogAlert from "../../molecule/Alert/DialogAlert";
import GenerateForm from "../../template/generate/GenerateForm";
// import DialogPin from "../PinInput/DialogPin";
const schema = yup.object().shape({
  old_password: yup
    .string()
    .min(8, "Min. 8 karakter")
    .required("Password lama harus diisi"),
  password: yup
    .string()
    .min(8, "Min. 8 karakter")
    .required("Password baru harus diisi"),
  password_confirm: yup
    .string()
    .oneOf([yup.ref("password"), null], "Password Tidak Sama"),
});
const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.up(768)]: {
      justifyContent: "start",
    },
    "& .btnNext": {
      fontSize: 14,
      fontWeight: 600,
      padding: "12px",
      flex: 1,
      marginRight: 20,
      [theme.breakpoints.down(769)]: {
        marginRight: 0,
        width: "100%",
      },
    },
    "& .btnCancel": {
      fontSize: 14,
      flex: 1,
      fontWeight: 600,
      padding: "12px 0",
      [theme.breakpoints.down(769)]: {
        display: "none",
      },
    },
  },
  inputText: {
    marginBottom: "1.5rem",
    maxWidth: 345,
  },
  container: {
    minHeight: "55vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.up(768)]: {
      minHeight: "60vh",
    },
  },
}));
const ChangePassword = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [form] = React.useState({
    old_password: "",
    password: "",
    password_confirm: "",
  });
  // const [state, setState] = React.useState({
  //   modal: false,
  // });
  const formData = [
    {
      target: "old_password",
      marginClass: classes.inputText,
      type: "password",
      inputClass: "fs-16",
      label: "PASSWORD SAAT INI",
      labelClass: "fs-14 color-secondary",
      passVisible: true,
      variant: "outlined",
    },
    {
      target: "password",
      marginClass: classes.inputText,
      type: "password",
      inputClass: "fs-16",
      label: "PASSWORD BARU",
      labelClass: "fs-14 color-secondary",
      passVisible: true,
      variant: "outlined",
    },
    {
      target: "password_confirm",
      marginClass: classes.inputText,
      type: "password",
      inputClass: "fs-16",
      label: "KONFIRMASI PASSWORD",
      labelClass: "fs-14 color-secondary",
      passVisible: true,
      variant: "outlined",
    },
  ];
  const {
    change_pass_props,
    loading_stack,
    result_stack,
    onClickCancel,
  } = props;
  // const { modal } = state;
  // const handleModal = (e) => {
  //   setState((state) => ({ ...state, modal: e }));
  // };
  const handleSubmit = (e) => {
    // console.log(e);
    change_pass_props(e);
    // setForm({ ...form, ...e });
    // setState({ ...state, modal: true });
  };
  React.useEffect(() => {
    // let data = processHelper.findResult("change_pass", result_stack);
    // if (Object.keys(data).length > 0) {
    //   setState((state) => ({ ...state, modal: false }));
    // }
  }, [result_stack]);
  function _handleSuccess() {
    history.goBack();
  }
  return (
    <div>
      <BasicText className="fs-14 mb-4 font-weight-bold" color="primary">
        UBAH PASSWORD
      </BasicText>
      <GenerateForm
        formData={formData}
        initial={form}
        schema={schema}
        containerClass={classes.container}
        buttonClass="btnNext"
        buttonText="UBAH PASSWORD"
        buttonCancelText="BATAL"
        buttonCancelClass="btnCancel"
        buttonContainerClass={classes.buttonContainer}
        onClickCancel={onClickCancel}
        onSubmit={handleSubmit}
        loading={processHelper.findProcess("change_pass", loading_stack)}
      />
      {/* <DialogPin
        modal={modal}
        onClose={handleModal}
        onSubmit={change_pass_props}
        dataSubmit={form}
        processName="change_pass"
      /> */}
      <DialogAlert
        resultName="change_pass"
        alertAppear="both"
        buttonText="OK"
        submitIfSuccess={_handleSuccess}
      />
    </div>
  );
};

const mapState = (state) => {
  return {
    result_stack: state.stateReducer.result_stack,
    loading_stack: state.stateReducer.loading_stack,
  };
};

export default connect(mapState, { request_otp_props, change_pass_props })(
  ChangePassword
);
