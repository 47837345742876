import { makeStyles } from "@material-ui/styles";
import React, { useCallback } from "react";
import { withRouter } from "react-router-dom";
import ilustration from "../../assets/img/ilustration";
import BasicText from "../atom/BasicText/BasicText";
import useValidationPin from "../atom/hooks/useValidationPin";
import ButtonSetting from "../molecule/Profile/ButtonSetting";
import ContentType from "../organism/Content/ContentType";
import DialogPin from "../organism/PinInput/DialogPin";
import TempMain from "../template/TempMain";
const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up(768)]: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row-reverse",
      maxWidth: 700,
      margin: "0 auto",
    },
    "& .menuSettingBox": {
      [theme.breakpoints.up(768)]: {
        width: 392,
      },
    },
    "& .textSetting": {
      fontSize: 14,
      fontWeight: 700,
      marginBottom: "28px!important",
      color: theme.palette.primary.main,
      [theme.breakpoints.down(768)]: {
        display: "none",
      },
    },
  },
}));

const SettingPage = (props) => {
  const [state, setState] = React.useState({
    display: false,
    contentKey: "kebijakan_privasi",
  });
  const { display, contentKey } = state;
  const classes = useStyles();
  const handleClose = () => {
    document.getElementsByTagName("body")[0].style.overflowY = "visible";
    setState((state) => ({ ...state, display: false }));
  };

  const dataButton = [
    { text: "ALAMAT EMAIL", link: "email", textLink: "UBAH" },
    { text: "NOMOR HANDPHONE", link: "phone", textLink: "UBAH" },
    { text: "PASSWORD", link: "password", textLink: "UBAH" },
    { text: "PIN", link: "pin", textLink: "UBAH" },
    { text: "REKENING BANK", link: "bank", secure: true, textLink: "UBAH" },
    {
      text: "ATUR INVESTASI BERKALA",
      link: "periodic-invest",
      textLink: "UBAH",
    },
    {
      text: "SYARAT & KETENTUAN",
      link: "syarat_ketentuan",
      textLink: "LIHAT",
    },
    {
      text: "KEBIJAKAN PRIVASI",
      link: "kebijakan_privasi",
      textLink: "LIHAT",
    },
    { text: "TENTANG APP", link: "about_app", textLink: "LIHAT" },
  ];

  const handleRedirect = useCallback((params) => {
    props.history.push("/profil/setting/" + params);
  }, [props.history])

  const { handleOpen, ...propsModalDialog } = useValidationPin(handleRedirect)

  const handleClick = ({ link, secure }) => {
    const arr = ["syarat_ketentuan", "kebijakan_privasi", "about_app"];
    if (arr.indexOf(link) === -1) {
      if (secure) handleOpen(link)
      else handleRedirect(link)
    } else {
      document.getElementsByTagName("body")[0].style.overflowY = "hidden";
      window.scrollTo(0, 0);
      setState((state) => ({ ...state, display: true, contentKey: link }));
    }
  };
  return (
    <TempMain
      title={display ? "SETTING" : "PROFIL"}
      backgroundColor="#fff"
      showBottom={false}
      bottomNavValue={4}
      page="profile"
      data={{ menuValue: 1 }}
    >
      <div className={classes.root}>
        <div className="text-center py-3">
          <img src={ilustration.setting} alt="" />
        </div>
        <div className="px-4 py-3 menuSettingBox">
          <BasicText className="textSetting">SETTING</BasicText>
          {dataButton.map((e, id) => (
            <ButtonSetting
              text={e.text}
              onClick={() => handleClick(e)}
              textLink={e.textLink}
              key={id}
            />
          ))}
        </div>
      </div>
      <DialogPin {...propsModalDialog} />
      <ContentType
        display={display}
        contentKey={contentKey}
        onClose={handleClose}
      />
    </TempMain>
  );
};

export default withRouter(SettingPage);
