import { Grid, makeStyles } from '@material-ui/core'
import { Form, Formik } from 'formik'
import { debounce } from 'lodash'
import React, { useCallback } from 'react'
import { useSelector } from "react-redux";
import { object } from 'yup'
import processHelper from '../../../helper/processHelper'
import validation from '../../../helper/validateHelper'
import ButtonContained from '../../atom/Button/ButtonContained'
import ButtonLink from '../../atom/Button/ButtonLink'
import ButtonLink2 from '../../atom/Button/ButtonLink2'
import InputOtp from '../../molecule/MaterialInput/InputOtp'
const { memo, useRef, useState } = React

const useStyles = makeStyles((theme) => ({
    linkMethod: {
        marginTop: 10,
        marginBottom: 30,
        fontSize: 12,
        color: theme.palette.primary.main
    },
    buttonSubmit: {
        alignSelf: "center",
        padding: "14px 36px",
        borderRadius: 6,
    }
}))

const validationSchema = object().shape({
    otp: validation.validateOTP(),
});

const initial = { otp: "" };

const FormOtp = ({ loading: loadingRedux, id, onResend, onSubmit }) => {
    // #region - const
    const refForm = useRef()
    const [expire, setExpire] = useState(false)
    const classes = useStyles()
    const refcd = useRef()
    const loading = useSelector(state => processHelper.findProcess(loadingRedux, state.stateReducer.loading_stack))
    // #endregion

    // #region - func
    const setId = (section) => `${id ? `${id}-` : ""}${section}`

    const handleExpire = () => setExpire(prev => !prev)

    const handleSubmit = debounce(({ otp }) => onSubmit(otp))

    const handleResend = useCallback((changeDst = false) => {
        function cb() {
            refcd.current?.resetOTP() ?? Math.random();
            setTimeout(() => refForm.current?.setErrors({}), 10)

        }
        onResend(cb, changeDst)
    }, [onResend])

    // #endregion

    const isDisabled = loading || expire
    return (
        <Formik
            innerRef={refForm}
            initialValues={initial}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ values, handleChange, handleSubmit, errors }) => (
                <Form id={setId('form')} className="d-flex flex-column flex-grow-1 justify-content-between w-100 mt-1">
                    <Grid>
                        <InputOtp
                            id="otp-regis"
                            onChange={handleChange("otp")}
                            value={values.otp}
                            error={errors.otp}
                            withCountdown
                            ref={refcd}
                            onTimeOut={handleExpire}
                        />
                        <ButtonLink
                            id={setId('link-reset')}
                            onClick={() => handleResend()}
                            loading={processHelper.findProcess("r_request_otp", loading)}
                            disabled={isDisabled}
                            text="KIRIM ULANG KODE OTP"
                            className="gray-5"
                            containClass="mt-4 pt-2"
                        />
                        <ButtonLink2
                            id={setId('link-change-dst')}
                            onClick={() => handleResend(true)}
                            loading={processHelper.findProcess("r_request_otp", loading)}
                            disabled={isDisabled}
                            text="GUNAKAN METODE LAIN"
                            className={classes.linkMethod}
                        />
                    </Grid>
                    <ButtonContained
                        id={setId('submit')}
                        text="VERIFIKASI"
                        onClick={handleSubmit}
                        radius="4px"
                        type="submit"
                        disabled={isDisabled}
                        className={classes.buttonSubmit}
                        loading={loading}
                    />
                </Form>
            )}
        </Formik>
    )
}

const memoize = (prev, next) => prev.id === next.id

FormOtp.defaultProps = {
    id: "",
    loading: 'redux'
}

export default memo(FormOtp, memoize)
