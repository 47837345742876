import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { clear_register } from "../redux/actions/auth";
const mapState = (state) => {
  return {
    onboarding: state.persistReducer.onboarding,
    authReducer: state.authReducer,
  };
};
const mapDispatch = {
  clear_register,
};
const registerPage = [
  "/register/validate-otp",
  "/register/identity",
  "/register/profil",
  "/register/tanda-tangan",
];
const needOtpToken = ["/register/validate-otp"];
const PublicRoute = (props) => {
  const { children, authReducer, onboarding, clear_register, ...rest } = props;
  const { isLogin, register, otp_token } = authReducer;
  const { registration_token } = register;
  useEffect(() => {
    // console.log(rest.location.pathname);
    if (
      registerPage.indexOf(rest.location.pathname) <= 0 &&
      register.registration_token
    ) {
      clear_register();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest.location]);
  return (
    <Route
      {...rest}
      render={({ location }) => {
        const { pathname } = location;
        const conditionHaveToken =
          needOtpToken.indexOf(pathname) >= 0 && otp_token;
        const indexRegister = registerPage.indexOf(pathname);
        // const conditionCanRegis = indexRegister >= 0 && registration_token;
        if (indexRegister >= 0) {
          if (registration_token || !indexRegister) return children;
          else return <Redirect to="/masuk" />;
        } else if (conditionHaveToken) {
          return children;
        } else if (isLogin === 1) {
          return <Redirect to="/" />;
        } else {
          return children;
        }
      }}
    />
  );
};

export default connect(mapState, mapDispatch)(PublicRoute);
