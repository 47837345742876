import { Divider, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import BasicText from "../../atom/BasicText/BasicText";
import CardHeaderGoal from "../../molecule/CardHeaderGoal";
import PortoChart from "../../molecule/Porto/PortoChart";
import PortoColorDesc from "../../molecule/Porto/PortoColorDesc";
import PortoSummaryNumber from "../../molecule/Porto/PortoSummaryNumber";

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: "25px 30px 30px 30px",
  },
  txt: {
    textDecoration: "underline",
  },
  nameGoals: {
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  portoSummary: {
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    borderRadius: "4px",
    [theme.breakpoints.up(900)]: {
      display: "grid",
      gridTemplateColumns: "26% 1% auto",
    },
  },
  chart: {
    width: 120,
    height: 120,
    [theme.breakpoints.up(768)]: {
      width: 140,
      height: 140,
    },
  },
  chartContainer: {
    height: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit,minmax(140px,150px))",
  },
}));

const PortoSummary = ({ summary, title }) => {
  const classes = useStyles();
  const { goal_name, id, is_follow_robo, allocations } = summary;
  const { push } = useHistory();

  const handleAction = useCallback(
    (action) => {
      switch (action) {
        case "red":
          push("/redeem", summary);
          break;
        case "subs":
          const listProduct = {};
          allocations.forEach(
            (el) => (listProduct[el.product_code] = { amount: el.allocation })
          );
          push("/subscript", {
            products: listProduct,
            user_goal_id: id,
            is_follow_robo: is_follow_robo || 0,
          });
          break;
        default:
          break;
      }
    },
    [allocations, id, is_follow_robo, push, summary]
  );

  const base = [
    {
      title: "Konvensional/Syariah",
      category: "type",
    },
    {
      title: "Alokasi Kelas Aset",
      category: "asset",
    },
  ];

  const isDanaTabunganOrSmartplan =
    summary.goal?.goal_code === "DT" ||
    summary.goal?.product_variety_code === "CG";
  const isPortoHaveAmount = summary?.total_portofolio > 0;
  return (
    <div className={classNames(classes.portoSummary, "shadow-card mb-2")}>
      <div className={classNames(classes.padding, "pr-3")}>
        {goal_name ? (
          <>
            <CardHeaderGoal
              data={summary}
              isShow
              isMore
              className="mb-2"
              moreProps={{
                ubah: {
                  className: "p-0",
                  isShow: !isDanaTabunganOrSmartplan,
                },
                hapus: {
                  className: "p-0",
                  isShow: !isPortoHaveAmount,
                },
              }}
            />
          </>
        ) : null}
        <PortoSummaryNumber
          data={summary}
          onAction={handleAction}
          title={title}
        />
      </div>
      <Divider orientation="vertical" flexItem />
      {Object.keys(summary).length > 0 ? (
        <Grid
          container
          spacing={2}
          className={classNames(classes.padding, "pl-md-3")}
        >
          {base.map((el, id) => (
            <Grid key={id} item xs={6}>
              <BasicText className="fs-12 font-weight-bold color-secondary mb-2">
                {el.title}
              </BasicText>
              <div className={classes.chartContainer}>
                <PortoChart
                  summary={summary}
                  category={el.category}
                  id={el.category}
                  containerClass={classes.chart}
                />
                <div className="mt-2 mt-md-3 pr-4">
                  <PortoColorDesc summary={summary} category={el.category} />
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      ) : (
        <div>no Data</div>
      )}
    </div>
  );
};

export default PortoSummary;
