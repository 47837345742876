import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import {
  fetch_regis_valid,
  regis_request_otp,
} from "../../../config/redux/actions/auth";
import InputRegis1 from "../../organism/Register/InputRegis1";
import TempAuth from "../../template/TempAuth";

class Regis1 extends PureComponent {
  componentDidUpdate = (prevProps, prevState) => {
    const { otp_token: otpObj, history } = this.props;
    if (otpObj && otpObj !== prevProps.otp_token) {
      history.replace(`/register/validate-otp?val=${otpObj.otp_token}`);
    }
  };

  render() {
    const { fetch_regis_valid, regis_request_otp, loading_stack } = this.props;
    return (
      <TempAuth
        page="regisInputPhone"
        data={{
          title: "REGISTRASI",
          ilustration: {
            image: "regisInputPhone",
            title: "Masukkan Nomor Handphone Kamu",
          },
        }}
      >
        <InputRegis1
          validate={fetch_regis_valid}
          onSubmit={regis_request_otp}
          loading={loading_stack}
        />
      </TempAuth>
    );
  }
}

const mapStateToProps = ({ stateReducer, authReducer }) => ({
  loading_stack: stateReducer.loading_stack,
  otp_token: authReducer.otp_token,
});

export default compose(
  connect(mapStateToProps, { fetch_regis_valid, regis_request_otp }),
  withRouter
)(Regis1);
