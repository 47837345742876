import React from "react";
import { useTheme } from "@material-ui/core";
import DashboardList from "../DashboardList/DashboardList";
import ButtonAddList from "../../../molecule/ButtonAddList/ButtonAddList";

const PlannerList = ({ data, onClickItem, onClickAdd, withComponent }) => {
  const theme = useTheme();
  const [focus, setFocus] = React.useState(0);
  const handleFocus = React.useCallback(({ translate }) => {
    setFocus(Math.floor((translate * -1 + 24) / 363));
  }, []);

  const stylePagination = (condition) => {
    const { secondary } = theme.palette;
    return {
      width: 25,
      height: 6,
      backgroundColor: condition ? secondary.main : secondary[6],
      marginRight: 6,
      borderRadius: 3,
    };
  };

  return (
    <>
      <DashboardList
        data={data}
        title="Rencana Investasi Kamu"
        loading="goals-user"
        rowId="planner"
        onClick={onClickItem}
        onUpdate={handleFocus}
        component={{
          footer: withComponent && <ButtonAddList onClick={onClickAdd} />,
        }}
      />
      <div className="d-flex align-items-center justify-content-center mb-3">
        {data.length
          ? data.map((_, index) => (
              <div key={index} style={stylePagination(focus === index)} />
            ))
          : ""}
      </div>
    </>
  );
};

PlannerList.defaultProps = {
  withComponent: false,
};

export default PlannerList;
