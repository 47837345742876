import isEqual from "lodash/isEqual";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { chart_color_props } from "../../config/redux/actions/option";
import {
  clear_product_history_props,
  product_history_props,
} from "../../config/redux/actions/product";
import {
  profile_risk_props,
  risk_result_props,
} from "../../config/redux/actions/profile";
import { remove_result_l } from "../../config/redux/actions/state";
import processHelper from "../../helper/processHelper";
import CardProduct from "../molecule/Product/CardProduct";
import { AllocContent, TypesContent } from "../molecule/Profile/TypeContent";
import DialogCustom from "../organism/Container/DialogCustom";
import CardProfileRisk from "../organism/ProfileRiskPage/CardProfileRisk";
import ModalProfileRisk from "../organism/Register/ModalProfileRisk";
import TempMain from "../template/TempMain";
import DialogAlert from "../molecule/Alert/DialogAlert";

class ProfileRiskPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modalInfo: false,
      infoContent: "types",
    };
  }
  componentDidMount() {
    this.props.profile_risk_props();
    this.props.chart_color_props();
  }
  componentWillUnmount() {
    this.props.clear_product_history_props();
  }
  componentDidUpdate(prevProps) {
    const {
      profile_risk,
      product_history_props,
      result_stack,
      // history,
      // remove_result_l,
    } = this.props;
    if (
      profile_risk !== prevProps.profile_risk &&
      Object.keys(profile_risk).length > 0
    ) {
      const { profile_risk_product } = profile_risk;
      let params = {
        display: "all",
        type: "monthly",
        "code[]": [],
      };
      profile_risk_product.forEach((e) => {
        params = {
          ...params,
          "code[]": [...params["code[]"], e.product_code],
        };
      });
      product_history_props(params);
    } else if (result_stack !== prevProps.result_stack) {
      const res = processHelper.findResult("save_risk_result", result_stack);
      if (res.name && res.type === "success") {
        //there
        // remove_result_l("save_risk_result");
        // history.goBack();
        this.refs.result_risk.handleVisible();
      }
    }
  }

  handleClick = (e, data) => {
    if (e === "subscript") {
      const { profile_risk_product } = this.props.profile_risk;
      let url = "/subscript";
      const tmp = {
        total_amount: Number(data.firstInvest) + Number(data.monthlyInvest),
        products: {},
      };
      profile_risk_product.forEach((e) => {
        tmp.products[e.product_code] = {
          amount: e.allocation,
          amount_value: e.allocation,
        };
      });
      console.log(tmp);
      this.props.history.push(url, tmp);
    } else if (e === "simulasi") {
      this.handleModal(true, "modal");
    } else if (e === "ubah") {
      this.refs.result_risk.handleVisible();
      // this.props.history.push("/hasil-profil-risiko");
    } else if (e === "types" || e === "alloc") {
      this.setState({ infoContent: e });
      this.handleModal(true, "modalInfo");
    }
  };
  handleModal = (e, key) => {
    this.setState({ [key]: e });
  };
  handleClickDetail = (e) => {
    this.props.history.push("/detail-produk?product=" + e.items.code);
  };

  handleChangeRisk = (data) => {
    const { profile_risk, risk_result_props } = this.props;
    if (!isEqual(data, profile_risk))
      risk_result_props({
        point: data.profile_risk_point,
        mode: "save_risk_result",
      });
    else this.refs.result_risk.handleVisible();
  };

  render() {
    const { profile_risk, chart_color, loading_stack } = this.props;
    const { modal, modalInfo, infoContent } = this.state;
    console.log(profile_risk, "profile_risk")
    return (
      <TempMain
        showBottom={false}
        bottomNavValue={4}
        title="PROFIL RISIKOMU"
        page="profileRisk"
        data={{
          cardProfileRisk: (
            <CardProfileRisk
              profileRiskData={profile_risk}
              chartColor={chart_color}
              onClick={this.handleClick}
            />
          ),
          product_list:
            Object.keys(profile_risk).length > 0 &&
            profile_risk.profile_risk_product.map((el, id) => (
              <CardProduct
                items={el.product}
                className="mb-3"
                needButton={false}
                key={id}
                isMobileDesign={true}
                onClick={this.handleClickDetail}
              />
            )),
          chartColor: chart_color,
          modal,
          handleModal: this.handleModal,
          profile_risk,
          onSubscript: this.handleClick,
        }}
      >
        <ModalProfileRisk
          ref="result_risk"
          onSubmit={this.handleChangeRisk}
          propsButton={{
            loading: processHelper.findProcess(
              "save_risk_result",
              loading_stack
            ),
          }}
        />
        <DialogCustom
          open={modalInfo}
          showCloseButton={true}
          handleClose={(e) => this.handleModal(e, "modalInfo")}
          backgroundColor="#fff"
          textColorClass="gray-3"
          title={infoContent === "types" ? "JENIS REKSADANA" : "ALOKASI"}
          content={
            infoContent === "types" ? <TypesContent /> : <AllocContent />
          }
        />
        <DialogAlert
          resultName="save_risk_result"
          alertAppear="both"
          submitSuccessOnBlur
        />
      </TempMain>
    );
  }
}

const mapStateToProps = (state) => ({
  profile_risk: state.profileReducer.profile_risk,
  product_history: state.productReducer.history,
  chart_color: state.optionReducer.chart_color,
  result_stack: state.stateReducer.result_stack,
  loading_stack: state.stateReducer.loading_stack,
});

const mapDispatchToProps = {
  profile_risk_props,
  product_history_props,
  clear_product_history_props,
  chart_color_props,
  risk_result_props,
  remove_result_l,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(ProfileRiskPage);
