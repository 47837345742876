import React from "react";
import PropTypes from "prop-types";
import { Slide, Dialog } from "@material-ui/core";
import MediaQuery from "../organism/MediaQuery/MediaQuery";
import CloseButton from "../atom/CloseButton/CloseButton";
import { makeStyles } from "@material-ui/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TempDialogFull = (props) => {
  const classes = useStyles();
  const { modal, onClose, children, withClose, maxWidth, paperClass } = props;
  const handleClose = () => {
    onClose(false);
  };
  return (
    <div>
      <MediaQuery device="mobile">
        <Dialog
          fullScreen
          open={Boolean(modal)}
          onClose={handleClose}
          TransitionComponent={Transition}
          disableBackdropClick={true}
        >
          {children}
        </Dialog>
      </MediaQuery>
      <MediaQuery device="desktop">
        <Dialog
          open={Boolean(modal)}
          onClose={handleClose}
          disableBackdropClick={true}
          TransitionComponent={Transition}
          classes={{ paper: paperClass }}
          maxWidth={maxWidth}
        >
          {withClose && (
            <CloseButton onClick={handleClose} className={classes.close} />
          )}
          {children}
        </Dialog>
      </MediaQuery>
    </div>
  );
};

const useStyles = makeStyles({
  close: {
    position: "absolute",
    top: 2,
    right: 2,
    maxWidth: "sm",
  },
});

TempDialogFull.propTypes = {
  modal: PropTypes.bool,
  onClose: PropTypes.func,
};

export default TempDialogFull;
