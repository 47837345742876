import React from "react";
import BasicText from "../../atom/BasicText/BasicText";
import ColorIndicator from "../../../assets/img/icon/porto/ColorIndicator";
import textHelper from "../../../helper/textHelper";
import ProductChart from "../../molecule/Product/ProductChart";

const chartColorBackup = [
  "#EF6431",
  "#999999",
  "#CCCCCC",
  "#EF6431",
  "#F2F2F2",
  "#EF6431",
  "#666666",
];

const ProductAllocation = (props) => {
  const { product, chartColor } = props;
  const { product_allocation } = product;
  let data = [];
  for (let i = 0; i < product_allocation.length; i++) {
    const tmp = {
      name: product_allocation[i].product_allocation_investment.name,
      y: product_allocation[i].value,
      color: chartColor.length ? chartColor[i] : chartColorBackup[i],
    };
    data.push(tmp);
  }
  return (
    <div className="d-flex">
      <div className="mr-1 mr-md-0">
        <BasicText colorLevel="light" className="fs-14 font-weight-bold mb-3">
          Alokasi Aset
        </BasicText>
        <BasicText colorLevel="light" className="fs-10  mb-3">
          (Berdasarkan FFS Terakhir)
        </BasicText>
        <div className="mb-3">
          {data.map((el, id) => (
            <div className="d-flex" key={id}>
              <ColorIndicator
                style={{ margin: 1, fontSize: 11, color: el.color }}
              />
              <BasicText className="fs-10 gray-4 mx-1">
                {el.name} {textHelper.printPercent(el.y)}
              </BasicText>
            </div>
          ))}
        </div>
      </div>
      <ProductChart
        data={data}
        color={chartColor}
        id="alloc_chart"
        width={180}
        height={180}
      />
    </div>
  );
};

export default ProductAllocation;
