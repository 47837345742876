import React, { Component } from "react";
import { connect } from "react-redux";
import { portofolio_list_props } from "../../config/redux/actions/portofolio";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import TempMain from "../template/TempMain";
import ContainerWithLoader from "../organism/Container/ContainerWithLoader";
import PortoConclusion from "../organism/Order/PortoConclusion";
import SwitchInput from "../organism/Order/SwitchInput";
import { set_product } from "../../config/redux/actions/product";
import SwitchResult from "../organism/Order/SwitchResult";
import DialogPin from "../organism/PinInput/DialogPin";
import { set_switch_props } from "../../config/redux/actions/transaction";
import DialogCustom from "../organism/Container/DialogCustom";
import DialogAlert from "../molecule/Alert/DialogAlert";
import processHelper from "../../helper/processHelper";
import CheckButtonOrder from "../organism/Order/CheckButtonOrder";
import { Grid } from "@material-ui/core";
export class OrderToSwitch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      productSelect: {},
      checkAgreement: false,
      modalPin: false,
      modalCot: false,
      data: {},
      is_all: 0,
    };
    this.rootRef = React.createRef();
  }
  componentDidMount() {
    this.rootRef.current.scrollTo(0, 0);
    this.props.set_product();
  }

  componentDidUpdate(prevProps, prevState) {
    const { result_stack, history } = this.props;
    if (result_stack !== prevProps.result_stack) {
      let data = processHelper.findResult(
        ["switch", "validate_switch"],
        result_stack
      );
      if (Object.keys(data).length > 0) {
        if (data.name === "switch") {
          if (data.type === "success") {
            const txn = data.data.data;
            history.replace("/finish-switch", {
              ids: txn.map(({ id }) => id),
            });
          } else {
            this.handleModal(false, "modalPin");
          }
        } else {
          if (data.type === "success") {
            this.handleModal(true, "modalPin");
            let tmp = { ...this.state.data };
            delete tmp.is_validating;
            this.setState({ data: tmp });
          }
        }
      }
    }
  }

  handleInputChange = (e, is_all) => {
    this.setState({ value: e, is_all });
  };
  handleSelectChange = (e) => {
    this.setState({ productSelect: e });
  };
  handleCheck = (e) => {
    this.setState({ checkAgreement: e });
  };
  handleSubmit = () => {
    const { id } = this.props.location.state;
    const { value, productSelect, is_all } = this.state;
    const data = {
      portfolio_sub_id: id,
      product_code_to: productSelect.code,
    };
    if (is_all) data.is_all = 1;
    else data.amounts = Number(value);
    this.setState({ data }, () => this.handleModal(true, "modalCot"));
  };

  handleModal = (e, name) => {
    this.setState({ [name]: e });
  };
  handleCot = () => {
    this.handleModal(false, "modalCot");
    this.props.set_switch_props(this.state.data);
  };
  handleBack = () => {
    this.props.history.replace("/portofolio");
  };
  render() {
    const { product, set_switch_props, history, loading_stack } = this.props;
    const { state } = history.location;
    const { value, productSelect, checkAgreement, modalPin, modalCot } =
      this.state;
    return (
      <TempMain title="KONVERSI">
        <ContainerWithLoader processName="product_list" loadingHeight="80vh">
          <div className="pt-4 px-4 margin-main" ref={this.rootRef}>
            <PortoConclusion marginClass="mb-3" data={state} />
            <Grid container className={"card-custom mb-3"}>
              <Grid item xs={12} md={7} lg={5}>
                <div>
                  <SwitchInput
                    portoData={state}
                    onChange={this.handleInputChange}
                    productData={product}
                    onSelectChange={this.handleSelectChange}
                  />
                </div>
              </Grid>
            </Grid>
            <SwitchResult
              value={value}
              productData={productSelect}
              portoData={state}
              products={product}
            />

            <Grid container spacing={2}>
              <Grid
                item={true}
                xs={12}
                md={7}
                lg={5}
                container
                alignContent="stretch"
              >
                <CheckButtonOrder
                  rootClass="w-100 d-flex justify-content-between mt-4 flex-column"
                  handleSubmit={this.handleSubmit}
                  handleCheck={this.handleCheck}
                  handleBack={this.handleBack}
                  buttonContainerClass="justify-content-start pl-4 mb-2"
                  syaratLink="syarat_sw"
                  typeApprove={"konversi"}
                  loadingNext={processHelper.findProcess(
                    "validate_switch",
                    loading_stack
                  )}
                  prosLink={
                    state.product.product_file
                      ? state.product.product_file.path
                      : "/"
                  }
                  disabledNext={
                    checkAgreement &&
                    value > 0 &&
                    Object.keys(productSelect).length > 0
                      ? false
                      : true
                  }
                />
              </Grid>
            </Grid>
          </div>
        </ContainerWithLoader>
        <DialogPin
          modal={modalPin}
          onClose={(e) => this.handleModal(e, "modalPin")}
          onSubmit={set_switch_props}
          dataSubmit={this.state.data}
          processName="switch"
        />
        <DialogCustom
          open={modalCot}
          handleClose={(e) => this.handleModal(e, "modalCot")}
          title="PENTING"
          text="Kamu akan mengkonversikan produk reksa dana yang kamu miliki ke produk reksa dana lainnya sesuai pilihan, transaksi konversi ini mengikuti
CUT-OFF TIME yang ditetapkan oleh
PT Syailendra Capital dan perkiraan unit yang akan kamu terima adalah berdasarkan NAV penutupan di hari bursa transaksi kamu berlaku. "
          buttonText="MENGERTI"
          onClick={this.handleCot}
        />
        <DialogAlert
          resultName={["switch", "validate_switch"]}
          buttonText="OK"
        />
      </TempMain>
    );
  }
}

const mapStateToProps = (state) => ({
  list: state.portoReducer.portofolio_list,
  product: state.productReducer.product,
  result_stack: state.stateReducer.result_stack,
  loading_stack: state.stateReducer.loading_stack,
});

const mapDispatchToProps = {
  portofolio_list_props,
  set_product,
  set_switch_props,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(OrderToSwitch);
