import React from "react";
import PropTypes from "prop-types";
import ilustration from "../../../assets/img/ilustration";

const TempProfileEditMobile = props => {
  const { children, data } = props;
  const { img } = data;
  return (
    <div>
      <div className="w-100 text-center py-3">
        <img src={ilustration[img]} alt="" />
      </div>
      <div className="px-4 py-3">{children}</div>
    </div>
  );
};

TempProfileEditMobile.propTypes = {
  img: PropTypes.string
};

export default TempProfileEditMobile;
