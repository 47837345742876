import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import color from "../../../assets/theme/color";
import CloseIcon from "@material-ui/icons/Close";
import BasicText from "../../atom/BasicText/BasicText";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/styles/makeStyles";
// import logo from "../../../assets/img/logo";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: color.secondaryLight,
    minHeight: "100vh",
  },
  appBar: {
    position: "relative",
    [theme.breakpoints.up(768)]: {
      display: "none",
    },
  },
  desktopBar: {
    [theme.breakpoints.down(769)]: {
      display: "none",
    },
  },
  rowTitle: {
    padding: "1.5rem",
    borderBottom: "2px dotted lightgrey",
  },
  ilustration: {
    backgroundColor: "#fff",
    paddingBottom: "15px",
  },
  input: {
    padding: 25,
  },
  logo: {
    height: 28,
    cursor: "pointer",
  },
  closeBar: {
    textAlign: "right",
    padding: "10px",
  },
}));

const DialogHeader = (props) => {
  const classes = useStyles();
  const handleClose = () => {
    props.onClickClose();
  };
  return (
    <>
      <AppBar className={classes.appBar} color="primary">
        <Toolbar className="w-100 container-body px-1">
          <Grid container className="w-100" justify="space-between">
            <Grid item className="my-auto pl-2">
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item className="my-auto text-center">
              <BasicText className="fs-14 font-weight-bold text-white">
                {props.title}
              </BasicText>
            </Grid>
            <Grid item className="my-auto text-right pr-1">
              <HelpOutlineIcon className="text-white fs-30" />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.desktopBar}>
        <div className={classes.closeBar}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        {/* <Grid
          container
          alignItems="center"
          justify="space-between"
          className={classes.rowTitle}
        >
          <img src={logo.logo} alt="" className={classes.logo} />
          <BasicText className="fs-14 color-primary font-weight-medium">
            {props.title}
          </BasicText>
        </Grid> */}
      </div>
    </>
  );
};

DialogHeader.propTypes = {
  title: PropTypes.string,
  onClickClose: PropTypes.func,
};

export default DialogHeader;
