import { Grid, withStyles } from "@material-ui/core";
import classNames from "classnames";
import React, { PureComponent } from "react";
import { object } from "yup";
import color from "../../../assets/theme/color";
import processHelper from "../../../helper/processHelper";
import validation from "../../../helper/validateHelper";
import ButtonContained from "../../atom/Button/ButtonContained";
import DialogAlert from "../../molecule/Alert/DialogAlert";
import InputText from "../../molecule/MaterialInput/InputText";
import SelectedNumberId from "../../molecule/Register/SelectedNumberId";
import FormProvider from "../FormProvider";
import RenderInput from "../../molecule/MaterialInput/RenderInput";
import PopOver from "../../atom/PopOver/PopOver";
import DialogOTPSelection from "../../molecule/Dialog/DialogOTPSelection";

const validationSchema = object().shape({
  number: validation.validatePhone(),
});
const dialogs = [
  { resultName: "validateRegis", alertAppear: "both" },
  { resultName: "r_request_otp", alertAppear: "error" },
];
const useStyles = (theme) => ({
  inputNumber: {
    flex: 1,
    paddingLeft: 5,
    "& div fieldset": {
      height: "100%",
      top: 0,
    },
    "& .errorInput": {
      position: "absolute",
      top: 50,
    },
  },
});

const Initial = {
  numberId: "62",
  number: "",
  refferal: "",
};

class InputRegis1 extends PureComponent {
  constructor(q) {
    super(q);
    this.state = {
      modal: false,
      number: "",
    };
    this.data = {};
  }
  _checkReff = () => {
    const { formik } = this.refs;
    const { refferal } = formik.getValues();
    if (refferal) {
      this.props.validate({ type: "referral_code", value: refferal });
    }
  };

  _onCloseModal = (modal) => this.setState({ modal });

  _handleSubmit = (data) => {
    const { numberId, number, refferal } = data;
    const _res = {
      phone_number: numberId + number,
    };
    if (number.charAt(0) === "0")
      _res.phone_number = numberId + number.substr(1);
    if (refferal) _res.referral_code = refferal;
    this.data = _res;
    this.setState({ modal: true, number: _res.phone_number });
  };

  _handleSelectDst = (otp_dst) => {
    this.props.onSubmit({ ...this.data, otp_dst });
  };

  ButtonCheckReff = ({ className, onClick }) => (
    <ButtonContained
      id="button-check-reff"
      text="GUNAKAN"
      backgroundColor={color.gray_5}
      // onClick={this._checkReff}
      onClick={onClick}
      className={classNames("mb-2 fs-10 font-weight-bold", className)}
      radius="4x"
      loading={processHelper.findProcess("validateRegis", this.props.loading)}
    />
  );

  render() {
    const { classes, loading } = this.props;
    return (
      <>
        <FormProvider
          ref={"formik"}
          initialValues={Initial}
          validationSchema={validationSchema}
          onSubmit={this._handleSubmit}
        >
          {({
            values,
            handleChange,
            setFieldValue,
            handleSubmit,
            errors,
            setFieldTouched,
          }) => {
            function _changeNumberId(value) {
              setFieldValue("numberId", value);
            }
            return (
              <div className="d-flex flex-column justify-content-between flex-grow-1">
                <Grid>
                  <Grid
                    container
                    alignItems="flex-start"
                    style={{ marginBottom: 23 }}
                  >
                    <SelectedNumberId
                      id="register-1"
                      value={values.numberId}
                      onChange={_changeNumberId}
                    />
                    <RenderInput
                      id="input-number-register-1"
                      name="number"
                      className="pl-2 ml-1 fs-18"
                      cClass={classes.inputNumber}
                      placeholder="8123456789"
                      type="number"
                      value={values["number"]}
                      onChange={handleChange("number")}
                      onFocus={() => setFieldTouched("number")}
                    />
                  </Grid>
                  <Grid
                    container
                    alignItems="flex-end"
                    justify="space-between"
                    className="flex-nowrap"
                  >
                    <InputText
                      id="input-code-refferal"
                      value={values.refferal}
                      onChange={handleChange("refferal")}
                      containClass="flex-grow-1 mr-3"
                      label="MASUKKAN KODE MARKETING"
                      labelClass="mb-2"
                      textLabelClass="fs-12"
                      labelChild={
                        <PopOver title="KODE MARKETING" className="ml-1">
                          Adalah kode unik yang diberikan oleh para sales yang
                          merekomendasikan kamu untuk registrasi di aplikasi
                          ini, ataupun dari channel promosi lainnya
                        </PopOver>
                      }
                      className="pl-2 ml-1 fs-18"
                    />
                    {this.ButtonCheckReff({
                      className: "mb-2",
                      onClick: () => this._checkReff(),
                    })}
                  </Grid>
                </Grid>
                <ButtonContained
                  id="submit-register"
                  text="LANJUT"
                  onClick={handleSubmit}
                  radius="4px"
                  className="py-2 px-5 align-self-center mt-3"
                  disabled={Object.keys(errors).length ? true : false}
                  loading={processHelper.findProcess(
                    dialogs[1].resultName,
                    loading
                  )}
                />
                {dialogs.map((dialog, index) => (
                  <DialogAlert key={index} {...dialog} buttonText="OK" />
                ))}
              </div>
            );
          }}
        </FormProvider>
        <DialogOTPSelection
          {...this.state}
          onClose={this._onCloseModal}
          onClickItem={this._handleSelectDst}
        />
      </>
    );
  }
}

InputRegis1.defaultProps = {
  validate: () => { },
  onSubmit: () => { },
};

export default withStyles(useStyles)(InputRegis1);
