import React, { createContext, useContext, useState } from "react";
import BasicText from "../../atom/BasicText/BasicText";
import DialogCustom from "../../organism/Container/DialogCustom";
import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import ButtonInfo from "../../atom/Button/ButtonInfo";

const useStyles = makeStyles({
  modalDisclaimer: {
    padding: "35px !important",
  },
});

const DialogSimulasiContext = createContext({
  _handleVisible : () => {}
})

const IconDisclimer = () => {
  const {_handleVisible} = useContext(DialogSimulasiContext)
  return (
    <div className="d-flex justify-content-end">
      <ButtonInfo id="info-disclaimer" onClick={_handleVisible} title="Disclaimer" />
    </div>
  )
}

const DialogSimulasi = ({ className,children }) => {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);
  function _handleVisible() {
    setVisible(!visible);
  }
  return (
    <DialogSimulasiContext.Provider value={{_handleVisible}}>
      {children || <BasicText
        className={classNames("mt-5 fs-10 ml-4 w-55", className)}
        colorLevel="light"
      >
        Pengguna wajib membaca dan menyetujui{" "}
        <span
          id="link-disclaimer"
          className="text-underline gray-3 is-link"
          onClick={_handleVisible}
        >
          Syarat dan Ketentuan, serta Disclaimer Simulasi Reksadana Online
          Syailendra
        </span>
      </BasicText>}
      <DialogCustom
        open={visible}
        handleClose={_handleVisible}
        title="DISCLAIMER SIMULASI REKSADANA ONLINE SYAILENDRA"
        text="Perhitungan di atas merupakan simulasi berdasarkan kinerja historis dan bukan merupakan indikasi atau jaminan terhadap kinerja di masa datang. Investasi melalui reksa dana mengandung risiko. Investorwajib membaca dan memahami prospektus sebelum berinvestasi di reksa dana."
        titleClass="fs-10 font-weight-bold mb-2 pb-1 text-center"
        textClass="fs-10"
        maxWidth="xs"
        className={classes.modalDisclaimer}
      />
    </DialogSimulasiContext.Provider>
  );
};

DialogSimulasi.Disclimer = IconDisclimer

export default DialogSimulasi;
