import React from "react";
import TempMain from "../TempMain";
import StepperPageForm from "../../molecule/Register/StepperPageForm";
import { Grid, makeStyles } from "@material-ui/core";
import ilus from "../../../assets/img/ilustration";
import color from "../../../assets/theme/color";
import OJKText from "../../atom/OJKText";

const useStyles = makeStyles({
  containOjk: {
    backgroundColor: color.blue_8,
    padding: "14px 0",
    marginBottom: 14,
  },
});

const TempRegisFormMobile = ({ children, data }) => {
  const { active } = data;
  const S = useStyles();
  return (
    <TempMain title={"REGISTRASI"} backgroundColor="white" showBottom={false}>
      <div className="mx-4 mt-3">
        <StepperPageForm active={active || 0} className="px-0 pb-3 mx-n2" />
        <Grid
          container
          alignItems="center"
          justify="center"
          className={S.containOjk}
        >
          <Grid className="flex-flex-grow-1">
            <OJKText />
          </Grid>
          <Grid>
            <img alt="" src={ilus.ojk} />
          </Grid>
        </Grid>
        {children}
      </div>
    </TempMain>
  );
};

TempRegisFormMobile.defaultProps = {
  data: {},
};

export default TempRegisFormMobile;
