import React, { memo } from "react";
import BasicText from "../../atom/BasicText/BasicText";
import textHelper from "../../../helper/textHelper";
import { Skeleton } from "@material-ui/lab";
import CloseButton from "../../atom/CloseButton/CloseButton";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 4,
    marginBottom: 16,
    position: "relative",
  },
  title: {
    marginBottom: "31px !important",
    fontSize: "14px !important",
    fontWeight: "bold !important",
  },
  closeButton: {
    position: "absolute",
    top: -16,
    right: -10,
    [theme.breakpoints.down(1025)]: {
      display: "none",
    },
  },
}));

const ProductDetailInfo = (props) => {
  const classes = useStyles();
  const { goBack } = useHistory();
  const { product } = props;
  const {
    product_type,
    launched_at,
    valuta,
    custody,
    fee_sub,
    fee_red,
    product_allocation,
    is_rdp,
    min_sub,
    product_rdp,
  } = product;
  const {
    due_date,
    return_distribution,
    return_distribution_method,
    book_period_start,
    book_period_end,
    offer_period_start,
    offer_period_end,
    emission_date,
  } = product_rdp || {};
  let alloc = "";
  if (Object.keys(product).length > 0)
    product_allocation.forEach((el, i) => {
      let tmp = el.value + "% " + el.product_allocation_investment.name;
      if (i + 1 < product_allocation.length) {
        tmp += ", ";
      }
      alloc += tmp;
    });
  return Object.keys(product).length === 0 ? (
    <div>
      <Skeleton width="117px" height="18" className="mb-3" />
      <Skeleton width="80%" height="18" />
      <Skeleton width="70%" height="18" />
      <Skeleton width="85%" height="18" />
      <Skeleton width="75%" height="18" />
      <Skeleton width="80%" height="18" />
    </div>
  ) : (
    <div className={classes.container}>
      <CloseButton
        id="close"
        onClick={goBack}
        className={classes.closeButton}
      />
      <BasicText className={classes.title}>Informasi Produk</BasicText>
      {is_rdp ? (
        <>
          <Row
            title="Jatuh Tempo"
            value={textHelper.printDate(due_date, "DD MMM YYYY")}
          />
          <Row
            title="Minimal Pembelian"
            value={textHelper.printMoney(min_sub)}
          />
          <Row title="Pembagian Return" value={return_distribution} />
          <Row
            title="Cara Pembagian Return"
            value={return_distribution_method}
          />
        </>
      ) : (
        <>
          <Row title="Jenis Reksadana" value={product_type.name} />
          <Row title="Strategi Investasi" value={alloc} />
          <Row
            title="Tanggal Peluncuran"
            value={textHelper.printDate(launched_at)}
          />
        </>
      )}

      <Row title="Manajer Investasi" value="PT. Syailendra Capital" />
      <Row title="Mata Uang" value={valuta} />
      <Row title="Wilayah Investasi" value="Indonesia" />
      <Row title="Bank Kustodian" value={custody?.name ?? "-"} />
      {is_rdp ? (
        <>
          <Row
            title="Book Building"
            value={textHelper.printDateStartEnd(
              book_period_start,
              book_period_end
            )}
          />
          <Row
            title="Masa Penawaran"
            value={textHelper.printDateStartEnd(
              offer_period_start,
              offer_period_end
            )}
          />
          <Row
            title="Tanggal Emisi"
            value={textHelper.printDate(emission_date, "DD MMM YYYY")}
          />
        </>
      ) : (
        <>
          <Row title="Pembelian" value="Harian" />
          <Row title="Biaya Pembelian" value={textHelper.printMoney(fee_sub)} />
          <Row title="Penjualan" value="Harian" />
          <Row title="Biaya Penjualan" value={textHelper.printMoney(fee_red)} />
        </>
      )}
    </div>
  );
};

const Row = memo(({ title, value }) => (
  <BasicText className="fs-14 mb-1">
    <span className="gray-5">{title}:</span>{" "}
    <span className="color-primary">{value}</span>
  </BasicText>
));

export default ProductDetailInfo;
