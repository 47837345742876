import React, { PureComponent } from "react";
import TempMain from "../template/TempMain";
import TempProfileWithImage from "../template/TempProfileWithImage";
import BasicText from "../atom/BasicText/BasicText";
import { makeStyles } from "@material-ui/core";
import color from "../../assets/theme/color";
import ButtonContained from "../atom/Button/ButtonContained";
import Rating from "react-star-rating-component";
import MenuProfileIcon from "../../assets/img/icon/profile/MenuProfileIcon";
import classNames from "classnames";
import DialogAlert from "../molecule/Alert/DialogAlert";
import { connect } from "react-redux";
import { fetch_rate_us } from "../../config/redux/actions/option";
import processHelper from "../../helper/processHelper";
import { set_result } from "../../config/redux/actions/state";

const _state = {
  message: "",
  star: 0,
  disabled: false,
};

const useStyles = makeStyles({
  comment: {
    borderColor: color.gray_6,
    borderRadius: 4,
    height: 165,
    width: 345,
    maxWidth: "100%",
    padding: "14px 16px",
    fontSize: 14,
    color: color.secondary,
  },
});

const ColumnComment = ({ onChange, value, disabled }) => {
  const S = useStyles();
  function _handleChange({ target }) {
    onChange(target.value);
  }
  return (
    <textarea
      id="area=input"
      value={value}
      disabled={disabled}
      onChange={_handleChange}
      className={classNames(S.comment, "font-family")}
      placeholder="Comments"
    ></textarea>
  );
};

const Star = () => {
  return (
    <MenuProfileIcon
      icon="starRating"
      viewBox="0 0 31.45 30"
      style={{
        marginRight: 10,
        height: 30,
        width: 31.45,
      }}
    />
  );
};

class RateUsPage extends PureComponent {
  state = _state;

  componentDidMount = () => {
    this.props.fetch_rate_us();
  };

  componentDidUpdate = (prevProps) => {
    const { rate } = this.props;
    if (rate !== prevProps.rate) this.setState({ ...rate, disabled: true });
  };

  _handleComment = (message) => {
    this.setState({ message });
  };
  _handleRating = (star) => {
    this.setState({ star });
  };
  _handleSubmit = () => {
    const { set_result, fetch_rate_us } = this.props;
    let canSubmit = true;
    for (const keys of Object.keys(this.state)) {
      if (!this.state[keys] && keys !== "disabled") {
        canSubmit = false;
        break;
      }
    }
    if (canSubmit) fetch_rate_us(this.state);
    else
      set_result({
        name: "rate-us",
        data: {
          type: "error",
          msg: "anda belum memasukan bintang dan ulasan",
        },
      });
  };
  render() {
    const { star, message, disabled } = this.state;
    return (
      <TempMain
        title="PROFIL"
        backgroundColor="white"
        showBottom={false}
        bottomNavValue={4}
        page="profile"
        data={{ menuValue: 10 }}
      >
        <TempProfileWithImage
          image="RateUs"
          className="d-flex flex-column align-items-start"
        >
          <BasicText
            className="fs-14 font-weight-bold"
            style={{ marginBottom: 27 }}
            color="primary"
          >
            RATE US
          </BasicText>
          <BasicText className="fs-14 mb-3">
            Bagaimana penilaian Anda terhadap YO! Inves?
          </BasicText>
          <Rating
            id="rating-input"
            name="rating"
            onStarClick={this._handleRating}
            value={star}
            starCount={5}
            starColor={color.primary}
            emptyStarColor={color.orange_7}
            renderStarIcon={Star}
            editing={!disabled}
          />
          <BasicText className="fs-14 mb-2" style={{ marginTop: 69 }}>
            Bagikan pengalaman kamu dalam menggunakan YO! Inves!
          </BasicText>
          <ColumnComment
            onChange={this._handleComment}
            value={message}
            disabled={disabled}
          />
          <ButtonContained
            id="kirim-btn"
            className="default-py-button px-5 mt-5"
            text="KIRIM"
            disabled={disabled}
            loading={processHelper.findProcess(
              "rate-us",
              this.props.loading_stack
            )}
            radius="6px"
            onClick={this._handleSubmit}
          />
        </TempProfileWithImage>
        <DialogAlert resultName="rate-us" alertAppear="both" />
      </TempMain>
    );
  }
}

const mapStateToProps = ({ stateReducer, optionReducer }) => ({
  loading_stack: stateReducer.loading_stack,
  result_stack: stateReducer.result_stack,
  rate: optionReducer.rate,
});
const mapDispatchToProps = {
  set_result,
  fetch_rate_us,
};
export default connect(mapStateToProps, mapDispatchToProps)(RateUsPage);
