import React from "react";
import useStyles from "./useStyles";
import classNames from "classnames";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import IconRobo from "../../atom/IconRobo/IconRobo";
import CheckCircleOutlined from "@material-ui/icons/CheckCircleOutlined";
import parse from "html-react-parser";
import textHelper from "../../../helper/textHelper";
import RiskIndicator from "../../molecule/Product/RiskIndicator";
import ButtonContained from "../../atom/Button/ButtonContained";

const RowProduct = ({
  name,
  risk,
  allocation,
  invest_type,
  fr_monthly_amount,
  buy_date,
  fr_buy_date,
  payment_method,
  typePayment,
  payment_logo,
  end_date,
  fr_end_date,
}) => {
  return (
    <div className="container-item-product">
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <div className="d-flex align-items-center item-product">
            <p className="basic fs-12 name">
              {parse(textHelper.printProduct2Line(name))}
            </p>
            <div>
              <p className="basic fs-12 mb-2">Risiko Produk</p>
              <RiskIndicator value={risk} height={12}/>
            </div>
            {allocation ? (
              <p className="basic font-weight-bold allocation">{allocation}</p>
            ) : null}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="content-summary d-flex flex-column flex-md-row justify-content-between">
            <SummaryData
              title={invest_type}
              value={fr_monthly_amount}
              classes={{ value: "color-primary font-weight-semibold" }}
            />
            <SummaryData
              title={buy_date}
              value={fr_buy_date?.replace("Tanggal ", "")}
            />
            <SummaryData title={end_date} value={fr_end_date} />
            <SummaryData
              title={payment_method}
              value={typePayment}
              classes={{ value: "text-capitalize" }}
              valueComponent={
                <img
                  src={payment_logo}
                  alt="logo"
                  className="payment-method"
                  onDragStart={(e) => e.preventDefault()}
                />
              }
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const SummaryData = ({ title, value, classes = {}, valueComponent }) => {
  return (
    <div
      className={classNames(
        "d-flex flex-md-column align-items-center justify-content-between mb-1 mb-md-0",
        classes.container
      )}
    >
      <p className="basic fs-10 mb-1 gray-4 text-center">{title}</p>
      <div className="d-flex justify-content-center align-items-center">
        <p className={classNames("basic fs-14", classes.value)}>
          {value || "-"}
        </p>
        {valueComponent && <div className="ml-2">{valueComponent}</div>}
      </div>
    </div>
  );
};

const CardPeriodic = ({
  item = {},
  onClick,
  isFollowRobo,
  typePayment,
  className,
}) => {
  const classes = useStyles();
  const { allocations } = item;
  const defaultPropsButton = {
    classes: { label: "fs-11 font-weight-semibold" },
    radius: "4px",
  };
  return (
    <Card className={classNames("shadow-card", classes.card, className)}>
      <div className="header-card d-flex align-items-center">
        {isFollowRobo ? <IconRobo /> : null}
        <p className="ml-auto mr-2 basic fs-12 color-primary">TERJADWAL</p>
        <CheckCircleOutlined color="primary" />
      </div>
      <Grid
        className="body-card"
        container
        alignItems="flex-end"
        justify="space-between"
      >
        <Grid item xs={12} className="product">
          {allocations.map((item, index) => {
            const {
              product_info,
              fr_allocation,
              meta,
              fr_monthly_amount,
              fr_buy_date,
              fr_end_date,
              payment_logo,
            } = item;
            const { invest_type, buy_date, end_date, payment_method } = meta;
            return (
              <RowProduct
                key={index}
                name={product_info.fr_product_name}
                allocation={fr_allocation}
                risk={product_info.product_risk}
                fr_monthly_amount={fr_monthly_amount}
                fr_buy_date={fr_buy_date}
                fr_end_date={fr_end_date}
                payment_logo={payment_logo}
                invest_type={invest_type}
                buy_date={buy_date}
                end_date={end_date}
                payment_method={payment_method}
                typePayment={typePayment}
              />
            );
          })}
        </Grid>
        <Grid item xs={12} container className="content-action mt-3">
          <ButtonContained
            id="button-ubah"
            text="UBAH"
            onClick={() => onClick("edit", item)}
            {...defaultPropsButton}
            className="bg-gray-6 mr-md-3 mr-1"
          />
          <ButtonContained
            id="button-delete"
            onClick={() => onClick("delete", item)}
            text="BERHENTI"
            {...defaultPropsButton}
            className="bg-primary-system"
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default CardPeriodic;
