import { makeStyles } from "@material-ui/core";

export default makeStyles(theme=> ({
    containPoint : {
        padding : "16px 0 16px 30px",
    },
    contentPoint : {
        display : 'flex',
        paddingLeft : 18,
        "& .point" : {
            border : `1px solid ${theme.palette.primary.main}`,
            borderRadius : 4,
            width : 60,
            color : theme.palette.primary.main,
            marginRight : 27,
        }
    },
    containDetail : {
        padding : "16px 30px 22px 30px",
        "& .spacing" : {display : "none"},
        [theme.breakpoints.up("md")] : {
            padding : "13px 0 47px 30px",
            "& .spacing" : {display : "block"},
                "& .detail-risk" : {
                paddingLeft : 30
            }
        }
    }
}))