import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { remove_result_l } from "../config/redux/actions/state";
import { getResult } from "../config/redux/selector";

export default function useDataResponse(key) {
    const dispatch = useDispatch()
    const [state, setState] = useState()
    const result = useSelector(getResult(key));

    useEffect(() => {
        if (result) {
            setState(result.data)
            dispatch(remove_result_l(key))
        }
    }, [dispatch, key, result])

    return state
}