import React from "react";
import { makeStyles, Grid } from "@material-ui/core";
import color from "../../../assets/theme/color";
import logos from "../../../assets/img/logo";
import BasicText from "../../atom/BasicText/BasicText";
import DesktopFooter from "../../molecule/Footer/DesktopFooter";

const useStyles = makeStyles({
  contain: {
    width: 907,
    maxWidth: "90%",
    borderRadius: 4,
    border: `1px solid ${color.blue_7}`,
    backgroundColor: "white",
    overflow: "hidden",
    marginTop: "12.5vh",
    marginBottom: "10vh",
    paddingBottom: 55,
  },
  logo: {
    width: 90,
    margin: "13px 21px",
  },
  footer: {
    position: "relative !important",
    "& .copy-right-text": {
      color: `${color.primary} !important`,
    },
  },
});

const Header = ({ title }) => {
  const S = useStyles();
  return (
    <Grid container alignItems="stretch">
      <Grid>
        <img src={logos.logo} alt="" className={S.logo} />
      </Grid>
      <Grid className="bg-primary-system px-3 d-flex align-items-center flex-grow-1">
        <BasicText className="fs-16 font-weight-medium text-white">
          {title}
        </BasicText>
      </Grid>
    </Grid>
  );
};

const ContainerRegister = ({ children, title }) => {
  const S = useStyles();
  return (
    <Grid
      container
      alignItems="center"
      direction="column"
      className="background-desktop"
      justify="space-between"
      style={{ minHeight: "100vh" }}
    >
      <Grid className={S.contain}>
        <Header title={title} />
        <Grid className="mx-auto w-80 mt-4">{children}</Grid>
      </Grid>
      <DesktopFooter width="100%" className={S.footer} />
    </Grid>
  );
};

ContainerRegister.defaultProps = {
  title: "REGISTRASI",
};

export default ContainerRegister;
