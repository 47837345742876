import {
  makeStyles,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  withStyles,
} from "@material-ui/core";
import React from "react";
import color from "../../../assets/theme/color";
import BasicText from "../../atom/BasicText/BasicText";

const steps = ["IDENTITAS", "PROFIL", "TANDA TANGAN"];

const useStyles = makeStyles({
  stepper: {
    width: 12,
    height: 12,
    borderRadius: 6,
    border: `1px solid ${color.primary}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 20,
    backgroundColor: "white",
  },
  completeStepper: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: color.primary,
  },
  containStepper: {
    "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
      marginTop: 10,
    },
  },
});

function IconStep({ active, completed }) {
  const S = useStyles();
  const isCompleted = active || completed;
  return (
    <div className={S.stepper}>
      {isCompleted && <div className={S.completeStepper} />}
    </div>
  );
}

const Connector = withStyles({
  alternativeLabel: {
    top: 4,
    left: "calc(-61% + 20px)",
    right: "calc(39% + 20px)",
  },
  line: {
    height: 4,
    border: 0,
    backgroundColor: color.gray_7,
  },
})(StepConnector);

const StepperPageForm = ({ active, className }) => {
  const S = useStyles();
  return (
    <Stepper
      activeStep={active}
      alternativeLabel
      connector={<Connector />}
      className={className}
    >
      {steps.map((step, index) => (
        <Step key={index} className="px-0">
          <StepLabel StepIconComponent={IconStep} className={S.containStepper}>
            <BasicText className="fs-12 font-weight-bold color-secondary">
              {step}
            </BasicText>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

StepperPageForm.defaultProps = {
  active: 0,
};

export default StepperPageForm;
