import React from "react";
import ButtonOutline from "../../atom/Button/ButtonOutline";
import { makeStyles } from "@material-ui/core";
import TextInput from "../../molecule/MaterialInput/TextInput";
import SearchIcon from "@material-ui/icons/Search";
import BasicText from "../../atom/BasicText/BasicText";
import classNames from "classnames";
import debounce from "lodash/debounce";
const useStyles = makeStyles((theme) => ({
  btnGlossary: {
    minWidth: "22px!important",
    height: "22px!important",
    padding: "0 0.25rem",
    fontSize: 14,
    fontWeight: 700,
    marginRight: 6,
    "&.active": {
      backgroundColor: "#FDF5F2",
    },
  },
  input: {
    borderRadius: 0,
    "&.MuiOutlinedInput-adornedStart": {
      paddingLeft: 7,
    },
    "& input": {
      padding: "8px",
      fontSize: 12,
      color: theme.palette.secondary.light,
    },
  },
}));

const Glossary = (props) => {
  const [state, setState] = React.useState({
    value: "",
    search: "",
    data: [],
    active: "",
    letter: [],
    once: true,
  });
  const classes = useStyles();
  const { search, data, active, letter, once, value } = state;
  const { glossary } = props;
  const debounceSearch = React.useCallback(
    debounce(
      (e) => setState((state) => ({ ...state, search: e.toLowerCase() })),
      300
    ),
    []
  );
  const handleSearch = (e) => {
    if (active !== "") setState((state) => ({ ...state, active: "" }));
    setState((state) => ({ ...state, value: e }));
    // setState((state) => ({ ...state, search: e.toLowerCase() }));
    debounceSearch(e);
  };
  const handleClick = (e) => {
    setState((state) => ({ ...state, active: e }));
    if (e === "az")
      setState((state) => ({
        ...state,
        data: glossary,
      }));
    else {
      setState((state) => ({
        ...state,
        data: glossary.filter(
          (el) => el.title[0].toLowerCase() === e.toLowerCase()
        ),
      }));
    }
  };
  React.useEffect(() => {
    let data = glossary?.filter(
      (el) => el.title.toLowerCase().indexOf(search) !== -1
    );
    setState((state) => ({ ...state, data }));
    if (once) {
      const letter = [];
      for (let i = 0; i < 26; i++) {
        letter.push((i + 10).toString(36).toUpperCase());
      }
      setState((state) => ({ ...state, letter, once: false }));
    }
  }, [glossary, search, once]);
  return (
    <>
      <div className="d-flex justify-content-between mb-3">
        <div className="d-flex my-auto">
          <ButtonOutline
            id="menu-a-z"
            text="A-Z"
            className={classNames(
              classes.btnGlossary,
              active === "az" && "active"
            )}
            value="az"
            onClick={handleClick}
          />
          {letter.map((el, id) => (
            <ButtonOutline
              id={`menu-${el}`}
              text={el}
              key={id}
              className={classNames(
                classes.btnGlossary,
                active === el && "active"
              )}
              value={el}
              onClick={handleClick}
            />
          ))}
        </div>
        <TextInput
          id="search-glossary"
          inputClass={classes.input}
          placeholder="Cari"
          variant="outlined"
          value={value}
          startIcon={() => <SearchIcon className="fs-18 gray-5" />}
          onChange={handleSearch}
        />
      </div>
      {data?.length ? data.map((el, id) => (
        <div
          className="mb-3"
          key={id}
          id={`glossary-${el.title?.replace(/ /g, "-").toLowerCase()}`}
        >
          <BasicText className="fs-12 font-weight-semibold mb-1">
            {el.title}
          </BasicText>
          <BasicText className="fs-12">{el.content}</BasicText>
        </div>
      )) : <BasicText className="fs-12 font-weight-semibold">
        Data tidak ditemukan
      </BasicText>}
    </>
  );
};

export default Glossary;
