import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
const { memo } = React;

const CloseButton = ({ iconProps, ...props }) => {
  return (
    <IconButton {...props}>
      <Close {...iconProps} />
    </IconButton>
  );
};

CloseButton.defaultProps = {};

export default memo(CloseButton, () => true);
