import React from "react";
import chartHelper from "../../../helper/chartHelper";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

const ProductChart = props => {
  const { data, color, id, width, height } = props;
  const useStyles = makeStyles(theme => ({
    root: {
      width,
      height
    }
  }));
  const [state, setState] = React.useState({
    once: true
  });
  const { once } = state;
  const classes = useStyles();
  React.useEffect(() => {
    if (once && color.length > 0) {
      chartHelper.createPiChart(color, data, id);
      setState(state => ({ ...state, once: false }));
    }
  }, [once, color, data, id]);
  return <div id={id} className={classes.root}></div>;
};

ProductChart.propTypes = {
  data: PropTypes.array,
  color: PropTypes.array,
  id: PropTypes.string
};

export default ProductChart;
