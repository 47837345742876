import { Button, makeStyles } from "@material-ui/core";
import ChevronRightRounded from "@material-ui/icons/ChevronRightRounded";
import { Skeleton } from "@material-ui/lab";
import classNames from "classnames";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import stateCallApi from "../../../config/API/stateCallApi";
import { gbl_list_lc } from "../../../config/redux/actions/state";
import processHelper from "../../../helper/processHelper";
const { useCallback, useEffect } = React;

const useStyles = makeStyles((theme) => ({
  rowItem: {
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid" + theme.palette.secondary[5],
    paddingBottom: 7,
    marginBottom: 13,
    "& .icon-goals": {
      width: 42,
      marginRight: 10,
    },
  },
}));

const propsLoader = {
  variant: "rect",
  width: "100%",
  height: "55px",
  className: "mt-2",
};

const PeriodicInvest = () => {
  const classes = useStyles();
  const page = useRef(0);
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { data, meta } = useSelector(
    (state) => state.profileReducer.periodic_invest
  );
  const loading = useSelector((state) =>
    processHelper.findProcess("periodic-list", state.stateReducer.loading_stack)
  );

  const handleLoad = useCallback(() => {
    dispatch(
      gbl_list_lc({
        ...stateCallApi.periodic_list,
        option: { current_page: page.current + 1 },
        callback: ({ meta }) => {
          console.log(meta);
          page.current = meta.current_page;
        },
      })
    );
  }, [dispatch]);

  useEffect(() => {
    handleLoad();
  }, [handleLoad]);

  const handleClick = useCallback(
    (index) => {
      push("/profil/detail-periodic-invest", { id: data[index]?.id });
    },
    [data, push]
  );

  if (!loading && !data.length) {
    return "Data tidak di temukan";
  }

  return (
    <div>
      {loading && (
        <>
          {Array(4)
            .fill("")
            .map((_, i) => (
              <Skeleton key={i} {...propsLoader} />
            ))}
        </>
      )}
      {!loading &&
        data?.map(
          (
            { id, goal_name, ref_goals_icon: { icon_web_path, icon_path } },
            index
          ) => {
            return (
              <div
                onClick={() => handleClick(index)}
                key={id}
                id={`button-goal-${id}`}
                className={classNames(classes.rowItem, "is-link")}
              >
                <img
                  className="icon-goals"
                  alt={goal_name}
                  src={icon_web_path || icon_path}
                />
                <p className="basic fs-14 flex">{goal_name}</p>
                <ChevronRightRounded />
              </div>
            );
          }
        )}
      {meta?.can_load_more && (
        <div className="cont-center mt-3">
          <Button onClick={handleLoad}>load more</Button>
        </div>
      )}
    </div>
  );
};

export default PeriodicInvest;
