import * as types from "./types";
import { temp_return_list } from "../../../helper/processHelper";

export const set_profile = (payload) => {
  return {
    type: types.SET_PROFILE,
    payload,
  };
};
export const set_profile_data = (payload) => {
  return {
    type: types.SET_PROFILE_DATA,
    payload,
  };
};
export const set_risk_profile_questions = (payload) => {
  return {
    type: types.SET_RISK_PROFILE_QUESTIONS,
    payload,
  };
};
export const clear_profile = (payload) => {
  return {
    type: types.CLEAR_REGISTER,
    payload,
  };
};
export const get_acc_bank_props = () => {
  return {
    type: types.GET_ACC_BANK_PROPS,
  };
};
export const get_acc_bank = (payload) => {
  return {
    type: types.GET_ACC_BANK,
    payload,
  };
};
export const add_acc_bank_props = (payload) => {
  return {
    type: types.ADD_ACC_BANK_PROPS,
    payload,
  };
};
export const del_acc_bank_props = (payload) => {
  return {
    type: types.DEL_ACC_BANK_PROPS,
    payload,
  };
};
export const change_number_props = (payload) => {
  return {
    type: types.CHANGE_NUMBER_PROPS,
    payload,
  };
};
export const change_email_props = (payload) => {
  return {
    type: types.CHANGE_EMAIL_PROPS,
    payload,
  };
};
export const set_priority_bank_props = (payload) => {
  return {
    type: types.SET_PRIORITY_BANK_PROPS,
    payload,
  };
};
export const profile_risk_props = () => {
  return {
    type: types.PROFILE_RISK_PROPS,
  };
};
export const profile_risk = (payload) => {
  return {
    type: types.PROFILE_RISK,
    payload,
  };
};
export const risk_survey_props = () => {
  return {
    type: types.RISK_SURVEY_PROPS,
  };
};
export const risk_survey = (payload) => {
  return {
    type: types.RISK_SURVEY,
    payload,
  };
};
export const risk_result_props = (payload) => {
  return {
    type: types.RISK_RESULT_PROPS,
    payload,
  };
};

export const risk_result_by_point_props = (payload) => ({
  type: types.RISK_RESULT_BY_POINT_PROPS,
  payload,
});

export const fetch_update_profile = (payload) => ({
  type: types.FETCH_UPDATE_PROFILE,
  payload,
});

export const periodic_rd = (response) => {
  const payload = temp_return_list(response, "periodic_invest");
  return {
    type: types.SET_PROFILE_DATA,
    payload,
  };
};
