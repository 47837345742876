import * as types from "../actions/types";
import { createLogic } from "redux-logic";
import { remove_loading, set_loading, set_result } from "../actions/state";
import { requestAxios, requestPostAxios } from "../../API/request";
import url from "../../API/url";
import { batch } from "react-redux";

export const voucherList = createLogic({
    type: types.VOUCHER_LIST,
    latest: true,
    process({ action }, dispatch, done) {
        const name = 'get_voucher_list'
        dispatch(set_loading(name))
        requestAxios(url.PROFILE.voucher.list, action?.payload?.params)
            .then(e =>
                dispatch(
                    set_result({
                        name,
                        data: { type: 'success', msg: e.message, data: e.data }
                    })
                )
            )
            .catch((e) =>
                dispatch(
                    set_result({
                        name,
                        data: { type: 'error', msg: e.message, data: e.data }
                    })
                )
            )
            .finally(() => {
                dispatch(remove_loading(name))
                done()
            })
    }
})

export const voucherDetail = createLogic({
    type: types.VOUCHER_DETAIL,
    latest: true,
    process({ action }, dispatch, done) {
        const name = 'get_voucher_detail-' + action.payload?.params.id
        dispatch(set_loading(name))
        requestAxios(url.PROFILE.voucher.detail, action?.payload?.params)
            .then(e =>
                dispatch(
                    set_result({
                        name,
                        data: { type: 'success', msg: e.message, data: e.data }
                    })
                )
            )
            .catch((e) =>
                dispatch(
                    set_result({
                        name,
                        data: { type: 'error', msg: e.message }
                    })
                )
            )
            .finally(() => {
                dispatch(remove_loading(name))
                done()
            })
    }
})

export const checkVoucher = createLogic({
    type: types.VOUCHER_CHECK,
    latest: true,
    debounce: 300,
    process({ action }, dispatch, done) {
        const baseName = 'check_voucher'
        const name = baseName + '-' + action.payload?.params?.voucher_id
        batch(() => {
            dispatch(set_loading(name))
            dispatch(set_loading(baseName))
        })

        requestPostAxios(url.PROFILE.voucher.check, action?.payload?.params)
            .then(e =>
                dispatch(
                    set_result({
                        name,
                        data: { type: 'success', msg: e.message, data: e.data }
                    })
                )
            )
            .catch((e) =>
                batch(() => {
                    dispatch(
                        set_result({
                            name,
                            data: { type: 'success', msg: e.message, data: {} }
                        })
                    )
                    dispatch(
                        set_result({
                            name,
                            data: { type: 'error', msg: e.message }
                        })
                    )
                })

            )
            .finally(() => {
                batch(() => {
                    dispatch(remove_loading(name))
                    dispatch(remove_loading(baseName))
                })
                done()
            })
    }
})