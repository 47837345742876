import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { log_in, set_auth } from "../../config/redux/actions/auth";
import { set_persist } from "../../config/redux/actions/persist";
import ButtonLink2 from "../atom/Button/ButtonLink2";
import DialogAlert from "../molecule/Alert/DialogAlert";
import SnackAlert from "../molecule/Alert/SnackAlert";
import LoginForm from "../organism/LoginForm/LoginForm";
import TipsKeamanan from "../organism/LoginForm/TipsKeamanan";
import TempAuth from "../template/TempAuth";
import { gbl_act_lc } from "../../config/redux/actions/state";
import stateCallApi from "../../config/API/stateCallApi";
const mapState = (state) => {
  return {
    isLogin: state.authReducer.isLogin,
    planner: state.goalReducer.planner,
    tmp_key: state.stateReducer.tmp_key, //get status success after login
  };
};

class LoginPage extends Component {
  onSubmit = (e) => {
    this.props.log_in({ ...e, identity: e.identity.toLowerCase() });
  };
  componentDidUpdate = (prevProps, prevState) => {
    const { set_persist, gbl_act_lc, tmp_key, set_auth, history } = this.props;
    if (tmp_key !== prevProps.tmp_key && tmp_key === "success") {
      gbl_act_lc({
        ...stateCallApi.planner_list,
        redux_action: undefined,
        callback: ({ data }) => {
          set_auth({ isLogin: 1, modalBannerOnce: true });
          set_persist({ onboading: true });
          if (!data.length) history.push("/reference-invest", { status: true });
        },
      });
    }
  };

  _handleForgotPass = () => {
    this.props.history.push("/forgot-password");
  };

  render() {
    return (
      <TempAuth
        data={{
          title: "SIGN IN",
          // headerText: "SyRD Web",
          ilustration: {
            title: "Selamat Datang Kembali",
          },
        }}
        page="Login"
      >
        <LoginForm
          onSubmit={this.onSubmit}
          loading={["login", "goal-planner"]}
        />
        <ButtonLink2
          id="button-lupa-pass"
          text="LUPA PASSWORD?"
          className="fs-12 gray-5 mb-3 mt-auto align-self-start"
          onClick={this._handleForgotPass}
        />
        <SnackAlert name="login" />
        <TipsKeamanan />
        <DialogAlert resultName="change_pass_change" alertAppear="success" />
      </TempAuth>
    );
  }
}

export default compose(
  connect(mapState, { log_in, set_persist, gbl_act_lc, set_auth }),
  withRouter
)(LoginPage);
