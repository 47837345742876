// import PropTypes from 'prop-types'
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import processHelper from "../../../helper/processHelper";
import CardFilter from "../../molecule/Product/CardFilter";
import GenerateHorizontal from "../../template/generate/GenerateHorizontal";
import GenerateLoaderHorizontal from "../../template/generate/GenerateLoaderHorizontal";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "#fff",
    [theme.breakpoints.up(768)]: {
      paddingBottom: "1.5rem",
      borderRadius: 4,
    },
    "& .subRoot": {
      paddingTop: "1rem",
      [theme.breakpoints.up(768)]: {
        borderBottom: `1px solid ${theme.palette.common.border_1}`,
        paddingTop: 29,
      },
    },
  },
}));

const ScrollFilter = (props) => {
  const isDesktop = useMediaQuery({ minWidth: 769 });
  const product_type = useSelector(
    (state) => state.productReducer.product_type
  );
  const loading_stack = useSelector(
    (state) => state.stateReducer.loading_stack
  );
  const [state, setState] = React.useState({
    type: [],
    active: "",
  });
  React.useEffect(() => {
    if (product_type.length > 0) {
      let list_tmp = [];
      product_type.forEach((el) => {
        const tmp = {
          title: el.name,
          id: el.id,
          icon: el.icon_web,
        };
        list_tmp.push(tmp);
      });
      setState((state) => ({
        ...state,
        type: list_tmp,
        active: props.initial || list_tmp[0].id,
      }));
    }
  }, [product_type, props.initial]);
  const { type, active } = state;
  const classes = useStyles();
  const onClick = (id) => {
    setState((state) => ({ ...state, active: id }));
    props.onClick(id);
  };

  const paddingStart = isDesktop ? 41 : 16;

  return (
    <div className={classNames(classes.root, "shadow-card")}>
      <div className="subRoot">
        {processHelper.findProcess("product_type", loading_stack) ? (
          <GenerateLoaderHorizontal
            count={6}
            size={{ width: 100, height: 76 }}
            style={{ marginLeft: paddingStart }}
          />
        ) : (
          <GenerateHorizontal
            data={type}
            render={CardFilter}
            active={active}
            onClickItem={onClick}
            options={{
              spacing: 3,
              paddingStart,
              paddingEnd: isDesktop ? 28 : 16,
            }}
          />
        )}
      </div>
    </div>
  );
};

// ScrollFilter.propTypes = {

// }

export default ScrollFilter;
