import React from "react";
import { TextField, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
    borderRadius: 10,
    "& .MuiInputBase-root input": {
      padding: "10px 14px"
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "inherit"
    }
  }
});

const Searchbox = props => {
  const classes = useStyles();
  const handleChange = e => {
    props.onChange(e.target.value);
  };
  const handleClear = () => {
    props.onClear("normal");
  };
  return (
    <div>
      <TextField
        classes={{ root: classes.root }}
        variant="outlined"
        onChange={handleChange}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment>
              <SearchIcon className="color-secondary" />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment>
              <CloseIcon className="color-secondary" onClick={handleClear} />
            </InputAdornment>
          )
        }}
      />
    </div>
  );
};

export default Searchbox;
