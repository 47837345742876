import React from "react";
import { connect } from "react-redux";
import { Divider, Grid } from "@material-ui/core";
import PortoSummaryNumber from "../../molecule/Porto/PortoSummaryNumber";
import BasicText from "../../atom/BasicText/BasicText";
import PortoChart from "../../molecule/Porto/PortoChart";
import PortoColorDesc from "../../molecule/Porto/PortoColorDesc";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import CardStatusRegister from "./CardStatusRegister";
import illustration from "../../../assets/img/ilustration";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    overflow: "hidden",
    borderRadius: 4,
    maxHeight: "1000px",
    transition: "max-height 0.5s",
    "&.hidePorto": {
      maxHeight: "50px",
    },
  },
  chart: {
    width: 120,
    height: 120,
  },
}));

const CardPortoMain = (props) => {
  const {
    porto_summary,
    regis_status: { is_register_complete, is_show_porto } = {},
  } = props;
  const classes = useStyles();
  const [hide, setHide] = React.useState(!is_show_porto);
  const handleHide = () => {
    setHide(!hide);
  };
  return [
    !is_register_complete && <CardStatusRegister key="regis_state" />,
    is_show_porto && (
      <div
        key="porto"
        className={classNames(
          "py-3 px-4 shadow-card",
          classes.root,
          hide && "hidePorto"
        )}
      >
        <PortoSummaryNumber
          title="1"
          data={porto_summary}
          hideButtonText={hide ? "UNHIDE" : "HIDE"}
          onHideClick={handleHide}
        />
        <Divider className="my-3" />
        {Object.keys(porto_summary).length > 0 &&
          (porto_summary.total_initial_amount > 0 ? (
            <>
              <Grid container spacing={1} className="mb-2">
                <Grid item xs={6}>
                  <BasicText className="fs-12 font-weight-bold">
                    Konvensional/
                    <br />
                    Syariah
                  </BasicText>
                  <PortoChart
                    summary={porto_summary}
                    category="type"
                    id="types"
                    containerClass={classes.chart}
                  />
                  <div className="mt-2 pr-4">
                    <PortoColorDesc summary={porto_summary} category="type" />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <BasicText className="fs-12 font-weight-bold mb-3">
                    Alokasi Aset
                  </BasicText>
                  <PortoChart
                    summary={porto_summary}
                    category="asset"
                    id="assets"
                    containerClass={classes.chart}
                  />
                  <div className="mt-2">
                    <PortoColorDesc summary={porto_summary} category="asset" />
                  </div>
                </Grid>
              </Grid>
              <BasicText className="fs-12 font-weight-bold mb-2">
                Alokasi Produk
                <br />
                Reksa Dana
              </BasicText>
              <PortoChart
                summary={porto_summary}
                category="product"
                id="products"
                containerClass={classes.chart}
              />
              <div className="mt-2 pr-5 mr-5">
                <PortoColorDesc summary={porto_summary} category="product" />
              </div>
            </>
          ) : (
            <div className="text-center mt-4" style={{ marginBottom: 200 }}>
              <img src={illustration["emptytxn"]} alt="" className="mb-3" />
              <BasicText
                className="fs-14 mx-4 font-weight-bold"
                color="primary"
              >
                Kamu belum memiliki saldo portofolio. Segera lakukan transaksi
                untuk memulai investasimu
              </BasicText>
            </div>
          ))}
      </div>
    ),
  ];
};

const mapState = ({ portoReducer, profileReducer, goalReducer }) => ({
  summary: portoReducer.summary,
  regis_status: profileReducer.regis_status,
  is_show_porto: profileReducer.is_show_porto,
  porto_summary: goalReducer.porto_summary,
});

export default connect(mapState)(CardPortoMain);
