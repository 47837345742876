import React from "react";
import { Checkbox, makeStyles } from "@material-ui/core";
import BasicText from "../../atom/BasicText/BasicText";
import PropTypes from "prop-types";
import classNames from "classnames";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles((theme) => ({
  content: {
    width: "max-content",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

function CheckInput(props) {
  const classes = useStyles();
  const [check, setCheck] = React.useState({
    [props.id]: props.state,
  });
  const {
    label,
    id,
    onChange,
    link,
    reverse,
    className,
    colorCheck,
    checkClass,
    forceFalse,
    labelClass,
    disabled,
  } = props;
  React.useEffect(() => {
    if (forceFalse) {
      setCheck((check) => {
        return { ...check, [id]: false };
      });
    }
    if (props.forceSet) {
      setCheck((check) => {
        return { ...check, [id]: props.state };
      });
    }
  }, [forceFalse, id, props.forceSet, props.state]);
  const checkHandler = (name) => (e) => {
    setCheck({
      ...check,
      [name]: e.target.checked,
    });
    onChange(e.target.checked);
  };
  return (
    <FormControlLabel
      className={classNames(
        "d-flex",
        className,
        classes.content,
        reverse && "flex-row-reverse"
      )}
      control={
        <Checkbox
          id={id}
          checked={check[id]}
          onChange={checkHandler(id)}
          name={id}
          color={colorCheck}
          className={checkClass}
          disabled={disabled}
        />
      }
      label={
        <BasicText className={classNames("text-left", labelClass)}>
          {label}
          {link}
        </BasicText>
      }
    />
  );
}

CheckInput.defaultProps = {
  state: false,
  reverse: false,
  colorCheck: "primary",
  forceFalse: false,
  disabled: false,
  labelClass: "fs-12",
};

CheckInput.propTypes = {
  state: PropTypes.bool,
  label: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  colorCheck: PropTypes.string,
  checkClass: PropTypes.string,
  forceFalse: PropTypes.bool,
  labelClass: PropTypes.string,
  disabled: PropTypes.bool,
};

export default CheckInput;
