import { makeStyles } from "@material-ui/core";
export default makeStyles(theme => ({
  root: {
    "& p.label": {
      fontSize: 12,
      color: theme.palette.secondary.main,
      marginBottom: "3px !important",
    },
    "& .container-input": {
      display: "flex",
      alignItems: "center",
      padding: "9px 12px",
      border: `1px solid ${theme.palette.secondary[6]}`,
      borderRadius: 4,
      "&:hover": {
        borderColor: "rgba(0, 0, 0, 0.87)",
      },
      "& .input": {
        flex: 1,
        border: "unset",
        "&:focus": { outline: "unset" },
        "&::placeholder": { color: theme.palette.secondary.lighter },
      },
    },
  },
}));
