import React from "react";
import BasicText from "../../atom/BasicText/BasicText";
import { Hidden } from "@material-ui/core";

const ProfileRiskNameDesc = (props) => {
  const { name, desc } = props.profileRiskType || {};
  return (
    <div className="px-1">
      <Hidden mdDown>
        <BasicText className="fs-14 font-weight-bold text-center mx-3 mb-3">
          Pilihan produk reksa dana yang sesuai dengan profil risiko kamu:
        </BasicText>
      </Hidden>
      <BasicText
        className="fs-26 mb-2 text-center font-weight-medium text-uppercase"
        color="primary"
      >
        {name}
      </BasicText>
      <BasicText className="text-center mx-auto fs-14" style={{ width: 205 }}>
        {desc}
      </BasicText>
    </div>
  );
};

ProfileRiskNameDesc.defaultProps = {
  profileRiskType: {},
};

export default ProfileRiskNameDesc;
