import React from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import classNames from "classnames";
import makeStyles from "@material-ui/styles/makeStyles";
import PropTypes from "prop-types";

function ButtonOutline(props) {
  const {
    id,
    onClick,
    className,
    type,
    text,
    loading,
    disabled,
    borderColor,
    radius,
    value,
  } = props;
  const useStyles = makeStyles((theme) => ({
    root: {
      position: "relative",
      textTransform: "none",
      borderRadius: radius ? radius : "unset",
      borderColor: theme.palette[borderColor].main,
      color: theme.palette[borderColor].main,
    },
    buttonProgress: {
      color: theme.palette.primary.dark,
      position: "absolute",
    },
  }));
  const classes = useStyles();
  return (
    <Button
      id={id}
      type={type}
      variant="outlined"
      // color={borderColor}
      className={classNames(classes.root, className)}
      onClick={() => onClick(value || null)}
      disabled={disabled || loading}
    >
      {text}
      {loading && (
        <CircularProgress size={18} className={classes.buttonProgress} />
      )}
    </Button>
  );
}

ButtonOutline.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  radius: PropTypes.string,
  borderColor: PropTypes.string,
  value: PropTypes.string,
};

ButtonOutline.defaultProps = {
  onClick: () => null,
  className: "",
  type: "button",
  text: "",
  loading: false,
  disabled: false,
  borderColor: "secondary",
};

export default ButtonOutline;
