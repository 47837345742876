import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import BasicText from "../../atom/BasicText/BasicText";
import ButtonContained from "../../atom/Button/ButtonContained";
import color from "../../../assets/theme/color";
import classNames from "classnames";
const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down(769)]: {
      backgroundColor: "unset",
      boxShadow: "unset",
      MozBoxShadow: "unset",
      WebkitBoxShadow: "unset",
    },
    "& .subRoot": {
      [theme.breakpoints.up(768)]: {
        maxWidth: 404,
      },
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.up(768)]: {
      justifyContent: "start",
    },
    "& .btnNext": {
      fontSize: 13,
      fontWeight: 700,
      padding: "0.75rem 2rem",
      marginRight: "1rem",
      [theme.breakpoints.down(769)]: {
        marginRight: 0,
      },
    },
    "& .btnCancel": {
      fontSize: 13,
      fontWeight: 700,
      padding: "0.75rem 2rem",
      [theme.breakpoints.down(769)]: {
        display: "none",
      },
    },
  },
}));

const KetentuanInvoice = (props) => {
  const { onSubmit, onBack, loading, style } = props;
  const classes = useStyles();
  return (
    <div
      style={style}
      className={classNames("mb-3 ml-3 ml-md-4", classes.root)}
    >
      <div className="mb-5 subRoot">
        <BasicText className="fs-14 font-weight-bold mb-3">
          Ketentuan Pembayaran
        </BasicText>
        <BasicText className="fs-12 mb-3">
          Verifikasi pembayaran dengan Transfer Bank
        </BasicText>
        <BasicText className="fs-12 mb-3">
          Pembayaran yang kami verifikasi sebelum jam 13.00 akan diproses di
          hari yang sama.
        </BasicText>
        <BasicText className="fs-12">
          Pembayaran yang kami verifikasi setelah jam 13.00 akan diproses di
          hari berikutnya.
        </BasicText>
      </div>
      <div className={classes.buttonContainer}>
        <ButtonContained
          className="btnNext"
          text={"UPLOAD BUKTI TRANSFER"}
          radius="5px"
          onClick={onSubmit}
          loading={loading}
        />
        <ButtonContained
          backgroundColor={color.gray_6}
          text="KEMBALI"
          className="btnCancel"
          radius="5px"
          onClick={onBack}
        />
      </div>
    </div>
  );
};

export default KetentuanInvoice;
