import React from "react";
import TempMain from "../template/TempMain";
import TempProfileWithImage from "../template/TempProfileWithImage";
import BasicText from "../atom/BasicText/BasicText";

const text = [
  "Gunakan jaringan internet yang menggunakan kata kunci dan tidak disarankan menggunakan akses wi-fi di tempat umum.",
  "Ganti password secara berkala dengan kombinasi karakter huruf dan angka yang unik dan tidak mudah ditebak.",
  "Selalu waspada terhadap upaya penipuan dari oknum yang mengaku sebagai karyawan Syailendra yang menanyakan data pribadi termasuk kode akses.",
  "Jika menerima pesan yang menyatakan bahwa aplikasi/sertifikat/layanan tidak sah, jangan melanjutkan akses perbankan dan segera hubungi kami.",
  "Jika kehilangan handphone, segera hubungi kami untuk melakukan pengamanan akun.",
];

const TipsKeamananPage = () => {
  return (
    <TempMain
      title="PROFIL"
      backgroundColor="white"
      showBottom={false}
      bottomNavValue={4}
      page="profile"
      data={{ menuValue: 8 }}
    >
      <TempProfileWithImage
        image="modalLogin"
        className="pr-4"
        imgProps={{ style: { width: 169 } }}
      >
        <BasicText
          color="primary"
          className="font-weight-bolder pb-1 mb-4 fs-14"
        >
          TIPS KEAMANAN
        </BasicText>
        {text.map((item, index) => (
          <BasicText key={index} className="fs-14 mb-3">
            {item}
          </BasicText>
        ))}
      </TempProfileWithImage>
    </TempMain>
  );
};

export default TipsKeamananPage;
