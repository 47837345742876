import React from "react";
import PropTypes from "prop-types";

import classNames from "classnames";
import makeStyles from "@material-ui/styles/makeStyles";

const BasicText = (props) => {
  const {
    children,
    className,
    color,
    colorLevel,
    letterSpacing,
    style,
    onClick,
    id,
  } = props;
  const useStyles = makeStyles((theme) => ({
    root: {
      color: color ? theme.palette[color][colorLevel] : "",
      fontWeight: "normal",
      fontSize: "13px",
      margin: "0",
      lineHeight: "normal",
      letterSpacing: letterSpacing || "0",
    },
  }));
  const classes = useStyles();
  return (
    <p
      id={id}
      onClick={onClick}
      style={style}
      className={classNames(className, classes.root)}
    >
      {children}
    </p>
  );
};

BasicText.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  colorLevel: PropTypes.oneOf(["main", "light", "lighter", "white", "black"]),
  color: PropTypes.oneOf(["primary", "secondary", "common"]),
  style: PropTypes.object,
  onClick: PropTypes.func,
  letterSpacing: PropTypes.string,
};

BasicText.defaultProps = {
  children: "",
  className: "",
  colorLevel: "main",
  color: "secondary",
};

export default BasicText;
