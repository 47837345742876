import * as types from "./types";

export const set_article = (payload) => {
  return {
    type: types.SET_ARTICLE,
    payload,
  };
};
export const set_article_list = (payload) => {
  return {
    type: types.SET_ARTICLE_LIST,
    payload,
  };
};
export const set_promo = (payload) => {
  return {
    type: types.SET_PROMO,
    payload,
  };
};
export const set_promo_list = (payload) => {
  return {
    type: types.SET_PROMO_LIST,
    payload,
  };
};
export const set_article_content_props = (payload) => ({
  type: types.SET_ARTICLE_CONTENT_PROPS,
  payload,
});
export const set_article_content = (payload) => ({
  type: types.SET_ARTICLE_CONTENT,
  payload,
});
