import React from "react";
import { makeStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import BasicText from "../../atom/BasicText/BasicText";
import InfoIcon from "@material-ui/icons/Info";
import textHelper from "../../../helper/textHelper";
import ChartSimulasi from "../../molecule/Chart/ChartSimulasi";
import { simulateInvest } from "../../../helper/calculateSimulasi";
import ButtonContained from "../../atom/Button/ButtonContained";
import DialogSimulasi from "../../molecule/Dialog/DialogSimulasi";
import { Popover } from "@material-ui/core";
import SimulationInput from "./SimulationInput";
import color from "../../../assets/theme/color";
import DialogCustom from "../Container/DialogCustom";
const { useState, useCallback, useEffect } = React;
const useStyles = makeStyles((theme) => ({
  containerSliderInput: {
    width: "100%",
    [theme.breakpoints.up(1366)]: {
      width: "70%",
    },
  },
}));
const CardSimulation = (props) => {
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  const [state, setState] = useState({
    firstInvest: "100000",
    monthlyInvest: "100000",
    timeInvest: "1",
    timeInvestType: "year",
    predictInvestValue: 0,
    normalInvestValue: 0,
    maxYear: null,
    timeList: [
      { id: "year", name: "Tahun" },
      { id: "month", name: "Bulan" },
    ],
    chartData: {
      categories: [],
      data: {
        modal: [],
        invest: [],
      },
    },
    popOver: null,
  });

  const {
    firstInvest,
    monthlyInvest,
    timeInvest,
    timeList,
    timeInvestType,
    predictInvestValue,
    normalInvestValue,
    chartData,
    maxYear,
    popOver,
  } = state;

  const {
    history,
    profileRiskProduct,
    onSubscript,
    containerClass,
    onClickback,
    is_suspend,
    disabled_sub,
  } = props;

  const handlePopOver = (e) => {
    setState((state) => ({ ...state, popOver: e }));
  };

  const handleChange = (e, name) => {
    setState((state) => ({ ...state, [name]: e }));
  };

  const handleTime = useCallback(
    (timeInvestType = "year") => {
      let maxYear;
      let minLogs = 0;
      if (history.length > 0) {
        history.forEach((e) => {
          if (minLogs === 0) {
            minLogs = e.length;
          } else if (minLogs > e.length) {
            minLogs = e.length;
          }
        });
        maxYear = minLogs - 1;
      }
      if (timeInvestType === "year") maxYear = Math.floor(maxYear / 12);
      if (timeInvest > maxYear)
        setState((state) => ({ ...state, timeInvest: maxYear }));
      setState((state) => ({ ...state, maxYear }));
    },
    [history, timeInvest]
  );

  const handleModal = useCallback(() => setModal((prev) => !prev), []);

  const handleSub = useCallback(() => {
    if (is_suspend) handleModal();
    else
      onSubscript("subscript", {
        firstInvest,
        monthlyInvest,
        predictInvestValue,
      });
  }, [
    firstInvest,
    handleModal,
    is_suspend,
    monthlyInvest,
    onSubscript,
    predictInvestValue,
  ]);

  useEffect(() => {
    let multiple = [];
    if (profileRiskProduct)
      profileRiskProduct.forEach((e) => {
        multiple.push(e.allocation);
      });
    else multiple = null;
    let data = {
      log: history,
      durasiInvest: parseFloat(timeInvest),
      firstInvest: parseFloat(firstInvest),
      monthlyInvest: parseFloat(monthlyInvest),
      multiple,
      timeInvestType,
    };
    if (history.length > 0) {
      let res = simulateInvest(data);
      if (res.investResult) {
        setState((state) => ({
          ...state,
          predictInvestValue: res.investResult.totalInvestReturn,
          normalInvestValue: res.investResult.totalInvest,
          chartData: res.chartData,
        }));
      }
      if (res.investResult) {
        handleTime(timeInvestType);
      }
    }
  }, [
    history,
    profileRiskProduct,
    timeInvest,
    firstInvest,
    monthlyInvest,
    timeInvestType,
    maxYear,
    handleTime,
  ]);

  return (
    <div className="card-custom">
      <div className={containerClass}>
        <div className={classes.containerSliderInput}>
          <BasicText className="fs-14 mb-4 font-weight-bold" color="primary">
            Simulasi Investasi
          </BasicText>
          <SimulationInput
            onChange={handleChange}
            timeInvestType={timeInvestType}
            timeList={timeList}
            maxYear={maxYear}
          />
        </div>
        <div className="mt-3 mb-4">
          <BasicText className="fs-12 mb-2">
            Perkiraan Nilai Investasi di Masa Depan{" "}
            <InfoIcon
              className="fs-14 gray-4 is-link"
              onClick={(e) => handlePopOver(e.currentTarget)}
            />
          </BasicText>
          <div className="d-flex flex-column flex-md-row">
            <BasicText id="return-invest" className="fs-22 mb-2 mb-md-0">
              {textHelper.printMoney(predictInvestValue)}
            </BasicText>
            <BasicText className="fs-14 mb-2 ml-md-3 mr-md-1 mt-md-auto mb-md-0">
              vs Tabungan Biasa
            </BasicText>
            <BasicText id="return-save" className="fs-14 mt-md-auto">
              {textHelper.printMoney(normalInvestValue)}
            </BasicText>
          </div>
        </div>
        <ChartSimulasi
          categories={chartData.categories}
          data={[
            { name: "Modal", data: chartData.data.modal },
            { name: "Investasi", data: chartData.data.invest },
          ]}
        />
        <div
          className={
            onClickback ? "d-flex mt-5 pl-2" : "w-100 pt-4 text-center"
          }
        >
          <ButtonContained
            id="button-invest-now"
            className="px-3 py-2"
            text="INVESTASI SEKARANG"
            radius="5px"
            disabled={disabled_sub}
            backgroundColor={color[`${is_suspend ? "gray_6" : "primary"}`]}
            onClick={handleSub}
          />
          {onClickback && (
            <ButtonContained
              id="button-back"
              backgroundColor={color.gray_6}
              text="KEMBALI"
              className="py-2 px-5 ml-3"
              radius="5px"
              onClick={onClickback}
            />
          )}
        </div>
      </div>
      <DialogSimulasi />
      <Popover
        open={Boolean(popOver)}
        onClose={() => handlePopOver(null)}
        anchorEl={popOver}
        PaperProps={{ style: { width: 375 }, className: "p-4" }}
      >
        <BasicText className="fs-10 font-weight-bold mb-3">
          PERKIRAAN NILAI INVESTASI
        </BasicText>
        <BasicText className="fs-10 mb-4">
          Perkiraan nilai investasi pada simulasi ini menggunakan produk reksa
          dana yang direkomendasikan berdasarkan profil risiko kamu. Simulasi
          ini berdasarkan kinerja produk reksa dana di masa lalu dan tidak
          mencerminkan kinerja di masa depan.
        </BasicText>
        <BasicText className="fs-10 font-weight-bold mb-3">
          TABUNGAN KONVENSIONAL
        </BasicText>
        <BasicText className="fs-10">
          Potensi keuntungan dari tabungan konvensional yang dapat kamu raih
          berdasarkan pertumbuhan bunga bank XXX sebesar XXX % per tahun.
          (sumber: Bank XXX Kebijakan suku bunga tahun xxx)
        </BasicText>
      </Popover>
      <DialogCustom
        open={modal}
        handleClose={handleModal}
        title="SUSPEND INFO"
        text="Mohon maaf untuk semetara waktu transaksi pembelian produk ini tidak dapat dilakukan karena terdapat pembatasan nilai penawaran unit penyertaan"
        buttonText="MENGERTI"
        onClick={handleModal}
      />
    </div>
  );
};

const mapState = (state) => ({ history: state.productReducer.history });

export default connect(mapState)(CardSimulation);
