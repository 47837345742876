import React from "react";
import PropTypes from "prop-types";
import { Select, MenuItem, makeStyles } from "@material-ui/core";
import BasicText from "../../atom/BasicText/BasicText";
import LabelInput from "../../atom/BasicText/LabelInput";
import { ExpandMore } from "@material-ui/icons";
import classNames from "classnames";
import color from "../../../assets/theme/color";

const useStyles = makeStyles((theme) => ({
  containSelect: {
    "& .Mui-disabled": {
      background: "rgba(0,0,0,0.02)",
    },
  },
  select: {
    '& .MuiSelect-select': {
      height: 16,
    }
  },
  disabled: {
    "& .Mui-disabled p": {
      color: `${color.gray_4} !important`,
    },
  },
  input: {
    padding: "15px 14px",
    fontFamily: "Montserrat",
    fontSize: 14,
  },
  selectIcon: {
    color: color.gray_5,
    position: "absolute",
    right: 0,
    zIndex: -1,
  },
  paper: {
    border: `1px solid ${theme.palette.common.border}`,
  },
}));

const IconMore = () => {
  const S = useStyles();
  return <ExpandMore className={S.selectIcon} />;
};

const _defaultRender = (name) => (
  <BasicText className="fs-13 ellipsis1row ">{name}</BasicText>
);

const dataNull = [{ id: 1, name: "" }]

const SelectsInput = (props) => {
  const {
    id,
    name,
    onChange,
    data,
    inputClass,
    label,
    labelClass,
    onFocus,
    value,
    defaultValue,
    className,
    containClass,
    IconComponent,
    renderItem,
    valueItem,
    placeholder,
    blackList,
    disabledStyle,
    ...other
  } = props;
  const classes = useStyles();

  const handleChange = ({ target: { value, name } }) => {
    onChange(value.toString(), name);
  };
  const filteredList = data.filter(
    (item) => blackList.indexOf(item[valueItem]) === -1
  );
  const listUsed = blackList.length > 0 ? filteredList : data || dataNull;
  return (
    <div className={containClass}>
      <LabelInput text={label} className={labelClass} />
      <Select
        id={id}
        value={value}
        onFocus={onFocus}
        defaultValue={defaultValue}
        // variant="outlined"
        displayEmpty
        IconComponent={IconComponent}
        onChange={handleChange}
        className={classNames(
          classes.select,
          classes.disabled,
          disabledStyle && classes.containSelect,
          inputClass
        )}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          getContentAnchorEl: null,
          elevation: 0,
          classes: { paper: classes.paper },
        }}
        inputProps={{
          name: name,
          className: classNames(classes.input, className),
        }}
        // SelectDisplayProps={{ className: "tes123" }}
        fullWidth
        {...other}
      >
        {placeholder && (
          <MenuItem value="" disabled>
            {placeholder}
          </MenuItem>
        )}
        {listUsed.map((el, id) => (
          <MenuItem
            value={el[valueItem]}
            key={id}
            // className={inputClass}
          >
            {renderItem ? renderItem(el) : _defaultRender(el.name)}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

SelectsInput.propTypes = {
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  data: PropTypes.array,
  IconComponent: PropTypes.any,
  renderItem: PropTypes.func,
  valueItem: PropTypes.string,
  label: PropTypes.string,
  blackList: PropTypes.array,
  placeholder: PropTypes.any,
};

SelectsInput.defaultProps = {
  value: "",
  defaultValue: "",
  data: [],
  IconComponent: IconMore,
  valueItem: "id",
  blackList: [],
  disabledStyle: true,
};

export default SelectsInput;
