export const registerPageData = {
  "/register/identity": {
    regisStatus: undefined,
  },
  "/register/profil": {
    regisStatus: ["register-1", "register-2", "register-3"],
  },
  "/register/tanda-tangan": {
    regisStatus: ["register-2", "register-3"],
  },
};

export const checkStepRegister = (pathname, goTo, regisStatus) => {
  const _regisStatus = registerPageData[pathname].regisStatus;
  if (_regisStatus) {
    if (_regisStatus.indexOf(regisStatus) < 0) {
      const arr = Object.keys(registerPageData);
      goTo(arr[arr.indexOf(pathname) - 1]);
    }
  }
};

export function _getNameIndex(index) {
  return index ? `_${index + 1}` : "";
}

export function renderFieldRekening(n) {
  const index = _getNameIndex(n);
  const name = `nama_bank${index}`;
  const no_rek_name = `no_rek${index}`;
  return [
    { name, label: " NAMA BANK", typeInput: "SelectsInput" },
    { name: no_rek_name, label: " NOMOR REKENING", type: "number" },
  ];
}
