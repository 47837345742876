import React from "react";
// import PropTypes from "prop-types";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import RepeatIcon from "@material-ui/icons/Repeat";
import BusinessCenterOutlinedIcon from "@material-ui/icons/BusinessCenterOutlined";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import makeStyles from "@material-ui/styles/makeStyles";
import { withRouter } from "react-router-dom";

const MobileBottom = props => {
  const matches = useMediaQuery("(min-width:500px)");
  const useStyles = makeStyles({
    root: {
      maxWidth: matches ? 600 : 500,
      minWidth: "auto",
      margin: "0 auto"
    },
    container: {
      position: "fixed",
      left: 0,
      bottom: 0,
      width: "100%",
      borderTop: "1px solid rgba(0, 0, 0, .1)",
      backgroundColor: "#fff"
    },
    action: {
      minWidth: "40px",
      "& .MuiBottomNavigationAction-wrapper": {
        "& .Mui-selected": {
          fontSize: "0.75rem!important"
        }
      }
    }
  });
  const classes = useStyles();
  const [state, setState] = React.useState(0);
  const onChange = (event, newValue) => {
    if (newValue !== props.value) {
      switch (newValue) {
        case 0:
          props.history.push("/");
          break;
        case 1:
          props.history.push("/produk");
          break;
        case 2:
          props.history.push("/transaksi");
          break;
        case 3:
          props.history.push("/portofolio");
          break;
        case 4:
          props.history.push("/profil");
          break;
        default:
          props.history.push("/");
          break;
      }
    }
    setState(newValue);
  };
  return (
    <div className={classes.container}>
      <BottomNavigation
        onChange={onChange}
        value={props.value || state}
        showLabels
        className={classes.root}
      >
        <BottomNavigationAction
          className={classes.action}
          label="Dashboard"
          icon={<DashboardOutlinedIcon />}
        />
        <BottomNavigationAction
          className={classes.action}
          label="Produk"
          icon={<EqualizerIcon />}
        />
        <BottomNavigationAction
          className={classes.action}
          label="Transaksi"
          icon={<RepeatIcon />}
        />
        <BottomNavigationAction
          className={classes.action}
          label="Portofolio"
          icon={<BusinessCenterOutlinedIcon />}
        />
        <BottomNavigationAction
          className={classes.action}
          label="Profil"
          icon={<PersonOutlineOutlinedIcon />}
        />
      </BottomNavigation>
    </div>
  );
};

// MobileBottom.propTypes = {};

export default withRouter(MobileBottom);
