import { IconButton, makeStyles, MenuItem } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import classNames from "classnames";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import moreIcon from "../../../assets/icons/moreIcon.svg";
import color from "../../../assets/theme/color";
import stateCallApi from "../../../config/API/stateCallApi";
import { gbl_act_lc } from "../../../config/redux/actions/state";
import ButtonContained from "../../atom/Button/ButtonContained";
import ButtonLink2 from "../../atom/Button/ButtonLink2";
import DialogCustom from "../../organism/Container/DialogCustom";
import DialogAlert from "../Alert/DialogAlert";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    padding: "25px 30px 10px 30px",
  },
  nameGoals: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  btnMore: {
    width: 150,
    borderRadius: "8px !important",
    margin: "3px 0",
  },
}));

const CardHeaderGoal = ({
  data,
  onClick,
  isShow,
  className,
  isMore,
  moreProps,
}) => {
  const { ref_goals_icon, goal_name, target_amount, fr_target_date, id } = data;
  const { push, replace, go } = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalHapus, setmodalHapus] = useState(false);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = () => {
    setmodalHapus(false);
    dispatch(
      gbl_act_lc({
        ...stateCallApi.goals_user_delete,
        appear: true,
        msg: "Berhasil hapus portofolio.",
        params: { id },
      })
    );
  };
  const defaultClick = () =>
    push("/change-goal", {
      name: goal_name,
      target_amount,
      target_date: fr_target_date,
      id,
      ref_goals_icon,
    });
  return (
    <div className={classNames(classes.nameGoals, className)}>
      <div className="d-flex align-items-center">
        <img
          src={ref_goals_icon?.icon_web_path || ref_goals_icon?.icon_path}
          alt={"Icon " + goal_name}
          style={{ height: "40px" }}
        />
        <p className="ml-3 basic font-weight-semibold text-uppercase">
          {goal_name}
        </p>
      </div>
      {isShow ? (
        isMore ? (
          <>
            {Object.entries(moreProps).filter((_, { isShow }) => isShow)
              .length ? (
              <IconButton onClick={handleClick}>
                <img
                  id={`moreIcon`}
                  className="is-link"
                  src={moreIcon}
                  alt=""
                />
              </IconButton>
            ) : null}

            <Popover
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{ className: "p-2" }}
            >
              {moreProps.ubah.isShow ? (
                <MenuItem onClick={defaultClick} {...moreProps.ubah}>
                  <ButtonContained text="UBAH" className={classes.btnMore} />
                </MenuItem>
              ) : undefined}
              {moreProps.hapus.isShow && (
                <MenuItem
                  {...moreProps.hapus}
                  onClick={() => {
                    setmodalHapus(true);
                    handleClose();
                  }}
                >
                  <ButtonContained
                    text="HAPUS"
                    className={classNames(classes.btnMore, "color-white")}
                    backgroundColor={color.gray_6}
                  />
                </MenuItem>
              )}
            </Popover>
          </>
        ) : (
          <ButtonLink2
            id="ubah-btn"
            text="UBAH"
            classes={{ label: "fs-12 gray-3" }}
            onClick={onClick || defaultClick}
            className="ml-auto mb-auto fs-12"
          />
        )
      ) : null}
      <DialogCustom
        open={modalHapus}
        handleClose={() => setmodalHapus(false)}
        text="HAPUS PORTOFOLIO INI?"
        buttonText="YA"
        buttonTextCancel="BATAL"
        onCancel={() => setmodalHapus(false)}
        onClick={() => handleDelete()}
      />
      <DialogAlert
        resultName="goals-user-delete"
        alertAppear="both"
        submitIfSuccess={() => {
          replace("/portofolio");
          go(0);
        }}
      />
    </div>
  );
};

export default CardHeaderGoal;
