import TempProfileEditMobile from "./TempProfileEditMobile";
import TempDashboardMobile from "./TempDashboardMobile";
import TempLoginMobile from "./TempLoginMobile";
import TempDetailProductMobile from "./TempDetailProductMobile";
import TempProfileRiskMobile from "./TempProfileRiskMobile";
import TempSmartPlanMobile from "./TempSmartPlanMobile";
import TempGoalsKamuMobile from "./TempGoalsKamuMobile";
import TempRegis1Mobile from "./TempRegis1Mobile";
import TempRegisFormMobile from "./TempRegisFormMobile";
import TempProfileMobile from "./TempProfileMobile";
import TempRiskPage from "../Desktop/TempRiskPage";
export default {
  Login: TempLoginMobile,
  ProfileEdit: TempProfileEditMobile,
  Dashboard: TempDashboardMobile,
  detailProduct: TempDetailProductMobile,
  profileRisk: TempProfileRiskMobile,
  smartPlan: TempSmartPlanMobile,
  goalsKamu: TempGoalsKamuMobile,
  regisInputPhone: TempRegis1Mobile,
  regisForm: TempRegisFormMobile,
  profile: TempProfileMobile,
  risk: TempRiskPage,
};
