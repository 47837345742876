import React from "react";
import SliderWithInput from "../../molecule/MaterialInput/SliderWithInput";
import CheckInput from "../../molecule/MaterialInput/CheckInput";
import { makeStyles } from "@material-ui/styles";
import ButtonContained from "../../atom/Button/ButtonContained";
import textHelper from "../../../helper/textHelper";
import classNames from "classnames";
import color from "../../../assets/theme/color";
import BasicText from "../../atom/BasicText/BasicText";
import SelectsInput from "../../molecule/MaterialInput/SelectsInput";
import Grid from "@material-ui/core/Grid";
import parse from "html-react-parser";
import debounce from "lodash/debounce";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "1rem",
    [theme.breakpoints.up(768)]: {
      marginBottom: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  selectPorto: {
    "&.MuiInput-underline:before": {
      borderBottom: `1px solid ${theme.palette.secondary[5]}`,
    },
  },
  inputSwitch: {
    "& input": {
      color: color.gray_4,
      fontFamily: "Montserrat",
    },
  },
  buttonRecommend: {
    width: "calc((100% / 4) - 5px)",
  },
}));

const SwitchInput = (props) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    portoProductCode: "",
    value: "",
    maxValue: 1000000,
    minValue: 0,
    recommend: ["50000", "100000", "500000", "1000000"],
    productSelect: "",
    isAll: 0,
  });
  const {
    value,
    maxValue,
    minValue,
    recommend,
    portoProductCode,
    productSelect,
    isAll,
  } = state;
  const { portoData, onChange, productData, onSelectChange } = props;
  const handleChange = React.useCallback(debounce(onChange, 300), []);

  React.useEffect(() => {
    if (Object.keys(portoData).length) {
      const { value, product, product_code } = portoData;
      setState((prev) => ({
        ...prev,
        recommend: [100000, 200000, 500000, 1000000],
        maxValue: value,
        minValue: product.min_red,
        portoProductCode: product_code,
      }));
    }
  }, [portoData]);

  const handleInputChange = (value) => {
    const valueNumber = Number(value);
    const isSliderMax = parseFloat(valueNumber) === maxValue;
    const valIsAll = isSliderMax ? 1 : 0;
    setState((state) => ({ ...state, value: valueNumber, isAll: valIsAll }));
    handleChange(valueNumber, valIsAll);
  };

  const handleSelectChange = (value, name) => {
    setState((state) => ({ ...state, productSelect: value }));
    let productSelected = {};
    productData.forEach((e) => {
      if (e.display_code === value) {
        productSelected = { ...e };
      }
    });
    onSelectChange(productSelected);
  };

  const menuItem = (e) => {
    return (
      <Grid id={`${e.id}-goal`} container alignItems="center">
        <Grid item xs={2}>
          <BasicText className="fs-14 font-weight-medium">{e.id}</BasicText>
        </Grid>
        <Grid item xs={10}>
          <BasicText className="fs-10  ellipsis1row">
            {parse(textHelper.printProduct2Line(e.name))}
          </BasicText>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className={classNames(classes.root)}>
      <div className="mb-4">
        <BasicText className="font-weight-bold mb-3">
          Konversi ke Produk
        </BasicText>
        <SelectsInput
          id="select-goal"
          data={productData
            .filter(({ code }) => code !== portoProductCode)
            .map(({ display_code, display_name }) => ({
              id: display_code,
              name: display_name,
            }))}
          name="product"
          onChange={handleSelectChange}
          value={productSelect}
          renderItem={menuItem}
          placeholder={
            <span className="fs-12 font-weight-medium gray-4">
              Pilih Produk
            </span>
          }
          inputClass={classes.selectPorto}
        />
      </div>
      <SliderWithInput
        id="switch"
        adormentContent={() => <span className="fs-14 gray-4">Rp</span>}
        adormentPlace="start"
        maxValue={maxValue}
        minValue={minValue}
        stepSlider={1000}
        text="Nominal Unit Dijual"
        value={value}
        onChange={handleInputChange}
        sliderClass="pb-0"
        placeholder="0"
        disabled={productSelect === ""}
        textInputClass={classes.inputSwitch}
      />
      <div className="w-100 d-flex justify-content-end mt-1">
        <CheckInput
          id="all"
          reverse={true}
          label="Jual semua"
          className="align-items-center mr-0"
          colorCheck="secondary"
          checkClass="py-0 pr-0"
          forceFalse={parseFloat(value) !== portoData.value}
          onChange={(isChecked) => handleInputChange(isChecked ? maxValue : 0)}
          disabled={productSelect === ""}
          state={isAll ? true : false}
          forceSet={true}
        />
      </div>
      <div className="d-flex w-100 justify-content-between mt-2">
        {recommend.map((rec, id) => {
          const isSelected = Number(value) === Number(rec);
          const isAvailable =
            parseFloat(maxValue) < parseFloat(rec) || productSelect === "";
          return (
            <ButtonContained
              id={`${rec}-btn`}
              key={id}
              backgroundColor={isSelected ? color.gray_3 : color.gray_6}
              text={textHelper.printMoneyWithoutRp(rec)}
              className={classNames("fs-12 py-1", classes.buttonRecommend)}
              onClick={() => handleInputChange(rec)}
              disabled={isAvailable ? true : false}
            />
          );
        })}
      </div>
    </div>
  );
};

export default SwitchInput;
