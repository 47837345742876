import { Grid, IconButton, makeStyles } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import Phone from "@material-ui/icons/Phone";
import WhatsApp from "@material-ui/icons/WhatsApp";
import classNames from "classnames";
import React, { useEffect } from "react";
import { connect, useDispatch, useStore } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import color from "../../assets/theme/color";
import { set_app_config, set_faq } from "../../config/redux/actions/option";
import BasicText from "../atom/BasicText/BasicText";
import TempMain from "../template/TempMain";
import parse from "html-react-parser";

const base = {
  whatsapp: WhatsApp,
  phone: Phone,
};

function handleClick(type, data) {
  if (type === "whatsapp") {
    if (data) window.location.href = `https://wa.me/${data}`;
    else alert("Nomor WhatsApp belum tersedia");
  }
}

const useStyles = makeStyles((theme) => ({
  contain: {
    margin: "30px 31px 100px 25px",
    padding: "40px 44px 36px",
    borderRadius: 4,
    backgroundColor: "white",
    position: "relative",
  },
  row: {
    margin: "0 179px 32px 0",
    [theme.breakpoints.down(768)]: {
      marginRight: 0,
    },
  },
  closeButton: {
    position: "absolute",
    top: 12,
    right: 15,
  },
  foot: {
    backgroundColor: color.orange_8,
    padding: "33px 44px",
    marginTop: 36,
  },
}));

const HelpCenterPage = () => {
  const {
    help_center,
    config: { suport },
  } = useStore().getState().optionReducer;
  const isDesktop = useMediaQuery({ minWidth: 769 });
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(set_faq("help_center"));
    dispatch(set_app_config());
  }, [dispatch]);
  const S = useStyles();
  return (
    <TempMain title="BANTUAN" isCloseButton showBottom={false}>
      <Grid className={S.contain}>
        <IconButton
          id="close-btn"
          className={S.closeButton}
          onClick={() => history.goBack()}
        >
          <Close className="gray-4" />
        </IconButton>
        <BasicText className="fs-14 font-weight-bold mb-4" color="primary">
          FAQ
        </BasicText>
        {help_center.map((_, index) => (
          <div className={S.row} key={index}>
            <BasicText className="fs-14 font-weight-medium mb-3">
              {_.title}
            </BasicText>
            <div className="basic fs-12">{parse(_.content)}</div>
          </div>
        ))}
        <Grid
          container
          alignItems="flex-end"
          className={S.foot}
          style={{ bottom: 0 }}
        >
          <BasicText color="primary" className="fs-14 font-weight-bold mb-1">
            Anda butuh bantuan?
            <br />
            Silahkan hubungi Customer Service kami
            <br />
            pada hari kerja, jam 09.00 - 17.00 WIB melalui:
          </BasicText>
          <div className={classNames("d-flex", isDesktop && "px-5")}>
            {suport &&
              Object.keys(base).map((_, index) => {
                const Icon = base[_];
                const keyRedux = `support_${_}`;
                return (
                  <Grid
                    id={`${_}-btn`}
                    container
                    alignItems="center"
                    key={index}
                    className={index ? "ml-4" : "is-link"}
                    style={{ width: "unset" }}
                    onClick={() => handleClick(_, suport[keyRedux])}
                  >
                    <Icon color="primary" className="mr-2" />
                    <BasicText
                      className="fs-16 font-weight-bold"
                      color="primary"
                    >
                      {suport[keyRedux]}
                    </BasicText>
                  </Grid>
                );
              })}
          </div>
        </Grid>
      </Grid>
    </TempMain>
  );
};
// class HelpCenterPage extends PureComponent {
//   state = {
//     expand: false,
//   };
//   componentDidMount = () => {
//     const { set_faq, set_app_config } = this.props;
//     set_faq("help_center");
//     set_app_config();
//   };

//   _handleExpand = (expand) => this.setState({ expand });

//   _handleWhatsApp = () => {
//     const { support_whatsapp } = this.props.suport || {};
//     if (support_whatsapp)
//       window.location.href = `https://wa.me/${support_whatsapp}`;
//     else alert("Nomor WhatsApp belum tersedia");
//   };

//   render() {
//     const { expand } = this.state;
//     const { help_center, identity } = this.props;
//     return (
//       <TempMobile
//         title="HELP CENTER"
//         showBottom={false}
//         backgroundColor="white"
//         isCloseButton
//         // withSearch
//       >
//         <Grid item className="p-4 fs-14 color-secondary bg-blue-8">
//           Hai
//           {identity !== undefined && (
//             <span>
//               {" "}
//               "<span className="font-weight-bold">{identity}</span>"
//             </span>
//           )}
//           , Kamu membutuhkan bantuan seputar YO! Inves?
//         </Grid>
//         <Grid item className="flex-grow-1 bg-white">
//           {help_center.map((item) => (
//             <PanelCollapse
//               key={item.id}
//               expand={expand}
//               title={item.title}
//               detail={item.content}
//               id={item.id}
//               handleChange={this._handleExpand}
//               withIconExpand
//             />
//           ))}
//         </Grid>
//         <Grid
//           container
//           alignItems="center"
//           className="p-4 position-fixed bg-blue-8"
//           style={{ bottom: 0 }}
//         >
//           <BasicText className="fs-10 font-weight-bolder color-secondary flex-grow-1">
//             Silahkan Hubungi Customer Service Kami.
//           </BasicText>
//           <WhatsApp
//             color="primary"
//             fontSize="small"
//             onClick={this._handleWhatsApp}
//           />
//         </Grid>
//       </TempMobile>
//     );
//   }
// }

const mapStateToProps = ({ optionReducer, authReducer }) => ({
  help_center: optionReducer.help_center,
  identity: authReducer.identity,
  suport: optionReducer.config.suport,
});

export default connect(mapStateToProps, { set_faq, set_app_config })(
  HelpCenterPage
);
