import React from "react";
import PropTypes from "prop-types";
import ColorIndicator from "../../../assets/img/icon/porto/ColorIndicator";
import BasicText from "../../atom/BasicText/BasicText";
import textHelper from "../../../helper/textHelper";
import { Grid } from "@material-ui/core";

const PortoColorDesc = (props) => {
  const { summary, category } = props;
  return (
    <div>
      {Object.keys(summary).length > 0 &&
        Object.keys(summary.chart).length > 0 &&
        summary.chart[category].map((el, id) => (
          <Grid container key={id} className="mb-2">
            <Grid item xs={9} className="d-flex">
              <ColorIndicator
                style={{
                  marginRight: "0.3rem",
                  fontSize: 11,
                  color: el.color_web,
                }}
              />
              <BasicText className="fs-10">{el.name}</BasicText>
            </Grid>
            <Grid item xs={3} className="mt-auto text-center">
              <BasicText className="fs-10 fw-600">
                {textHelper.printPercentWithoutOperator(el.percent)}
              </BasicText>
            </Grid>
          </Grid>
        ))}
    </div>
  );
};

PortoColorDesc.propTypes = {
  summary: PropTypes.object,
  category: PropTypes.string,
};

export default PortoColorDesc;
