import React from "react";
// import PropTypes from 'prop-types'
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import BasicText from "../../atom/BasicText/BasicText";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 90,
    position: "relative",
    [theme.breakpoints.up(768)]: {
      maxWidth: "150px",
      minHeight: 65,
    },
    "& .container-name-tabs": {
      [theme.breakpoints.up(768)]: {
        maxHeight: 40,
      },
    },
    "&.active::before,&:hover::before": {
      [theme.breakpoints.up(768)]: {
        content: `''`,
        width: "calc(100% - 50px)",
        height: 2,
        position: "absolute",
        right: 0,
        bottom: 0,
        backgroundColor: theme.palette.primary.main,
      },
    },
    [theme.breakpoints.down(768)]: {
      "& .container-image-tabs": {
        height: 46,
        width: 46,
        overflow: "hidden",
        display: "grid",
        placeItems: "center",
      },
      "&.active .container-image-tabs": {
        backgroundColor: theme.palette.secondary[6],
        borderRadius: 4,
      },
    },

    "&:hover": {
      cursor: "pointer",
    },
  },
  iconTabs: {
    [theme.breakpoints.down(768)]: {
      display: "grid",
      placeItems: "center",
    },
  },
  image: {
    width: "40px",
    height: "40px",
    [theme.breakpoints.up(768)]: {
      marginRight: 10,
    },
  },
  text: {
    textAlign: "center",
    fontSize: "12px !important",
    textTransform: "uppercase",
    [theme.breakpoints.up(768)]: {
      textAlign: "left",
    },
  },
}));

const CardFilter = (props) => {
  const { className, item, active, style } = props;
  const { title, id, icon } = item;
  const classes = useStyles();
  return (
    <div
      className={classNames(classes.root, className, active && "active")}
      style={style}
      id={`tab-${id}`}
    >
      <Grid container justify="space-between">
        <Grid item xs={12} md={4} className={classes.iconTabs}>
          <div className="container-image-tabs mb-2 mb-md-0">
            <img src={icon} alt="" className={classes.image} />
          </div>
        </Grid>
        <Grid item xs={12} md={8} className="cont-center container-name-tabs">
          <BasicText
            className={classNames(classes.text, "ws")}
            color="secondary"
          >
            {title}
          </BasicText>
        </Grid>
      </Grid>
    </div>
  );
};

CardFilter.propTypes = {};

export default CardFilter;
