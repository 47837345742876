import { Checkbox, makeStyles } from "@material-ui/core";
import React, { useCallback } from "react";
import { useRouteMatch } from "react-router-dom";
import { formatUrl } from "../../../helper/textHelper";

const useStyles = makeStyles((theme) => ({
  withCheck: {
    display: "flex",
    alignItems: "center",
    "& p": {
      marginLeft: 10,
      marginTop: "0px !important",
    },
  },
}));

const GoalIcon = ({ item, withCheck, checked, onChecked }) => {
  const classes = useStyles();
  const { path } = useRouteMatch();
  const { ref_goals_icon, name, id, goal_code } = item;
  const isGoal = path === "/modify-goal-icon";
  const handleChecked = useCallback(
    () => onChecked(id, ref_goals_icon),
    [id, onChecked, ref_goals_icon]
  );
  return (
    <div id={`${goal_code}-icon`} className="d-flex flex-column item-goal">
      <div className={withCheck && classes.withCheck}>
        <div
          className={`container-image ${
            checked && !withCheck && "checked"
          } d-flex justify-content-center`}
          onClick={!withCheck ? handleChecked : () => {}}
        >
          <img
            alt={ref_goals_icon?.name ?? ""}
            src={formatUrl(
              ref_goals_icon?.icon_web_path || ref_goals_icon?.icon_path
            )}
            width={70}
            height={70}
          />
        </div>
        {isGoal ? null : (
          <p
            className={`basic fs-10 gray-4 mt-2 ${!withCheck && "text-center"}`}
          >
            {name}
          </p>
        )}
      </div>
      {withCheck && (
        <Checkbox
          id={`check-${goal_code}-icon`}
          checked={checked}
          onChange={handleChecked}
        />
      )}
    </div>
  );
};

export default GoalIcon;
