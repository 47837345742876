import { useCallback } from "react";
import { useLocation } from "react-router-dom";

export default function () {
    const { state } = useLocation()

    const createState = useCallback((id_promo, product_code, min_sub) => {
        let subState = {
            ...state?.subState,
            promo: {
                ...state?.subState?.promo,
                [product_code]: id_promo
            }
        }
        if (!subState.products) {
            subState.products = {
                [product_code]: {
                    amount: 100
                }
            }
        }
        if (min_sub && !subState.total_amount) {
            subState.total_amount = min_sub
        }
        return subState
    }, [state])

    return createState
}