import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { gbl_act_lc } from "../../config/redux/actions/state";
import stateCallApi from "../../config/API/stateCallApi";
import { CircularProgress } from "@material-ui/core";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

const UltraVoucherLoadPage = ({ history }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      gbl_act_lc({
        ...stateCallApi.ultraVoucher,
        callback: (data) => {
          if (data?.webview_url) {
            window.open(data.webview_url, "_blank");
            history.goBack();
          }
        },
      })
    );
  }, [dispatch, history]);

  return (
    <div className="cont-center" style={{ minHeight: "100dvh" }}>
      <CircularProgress />
    </div>
  );
};

export default withRouter(UltraVoucherLoadPage);
