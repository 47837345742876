import * as types from "../actions/types";

const initial = {
  isLogin: 0,
  modalBannerOnce: false,
  is_register_complete: 0,
  isSyaiAcc: 0,
  token: null,
  otp_token: null,
  syaiAcc: {
    name: null,
    email: null,
    password: null,
    password_confirm: null,
    no_hp: null,
    no_ktp: null,
    ktp: null,
    selfie: null,
    signature: null,
  },
  register: {
    registerStatus: "",
    registration_token: null,
    sid: null,
    url_ktp: null,
    url_selfie: null,
    url_ttd: null,
    nik: null,
    nama: null,
    tempat_lahir: null,
    tanggal_lahir: null,
    gender: null,
    agama: "",
    status_perkawinan: "",
    kewarganegaraan: "",
    alamat_ktp: "",
    provinsi_ktp: "",
    kota_ktp: "",
    kecamatan_ktp: "",
    desa_ktp: "",
    rt_ktp: "",
    rw_ktp: "",
    kodepos_ktp: "",
    alamat_korespondensi: "",
    negara_korespondensi: "102",
    provinsi_korespondensi: "",
    kota_korespondensi: "",
    kodepos_korespondensi: "",
    pendidikan: "",
    pekerjaan: "",
    bidang_usaha: "",
    jabatan: "",
    pendapatan: "",
    sumber_pendapatan: "",
    ahli_waris: "",
    hubungan: "",
    tujuan: "",
    profile_risk: "",
    user_account: [],
    password: "",
    password_confirm: "",
    email: "",
    pin: "",
    confirm_pin: "",
    params: {},
    selfie: null,
    no_hp: "",
  },
  otp: {},
};

const stateBackup = { ...initial };
const registBackup = { ...initial.register };

const authReducer = (state = initial, action) => {
  switch (action.type) {
    case types.SET_AUTH:
      return { ...state, ...action.payload };
    case types.LOG_OUT:
      return { ...state, ...stateBackup };
    case types.SYAIACC:
      return { ...state, syaiAcc: { ...state.syaiAcc, ...action.payload } };
    case types.REGISTER:
      return { ...state, register: { ...state.register, ...action.payload } };
    case types.CLEAR_REGISTER:
      return {
        ...state,
        register: { ...state.register, ...registBackup, ...action.payload },
      };
    case types.SET_TEMP_REGIS: {
      return {
        ...state,
        register: {
          ...state.register,
          params: { ...state.register.params, ...action.payload },
        },
      };
    }
    default:
      return state;
  }
};

export default authReducer;
