import { makeStyles } from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import classNames from "classnames";
import React from "react";

const useStyles = makeStyles({
  container: {
    width: 322,
    borderRadius: 8,
  },
});

const ButtonAddList = ({ iconProps, className, ...props }) => {
  const classes = useStyles();
  return (
    <div
      id="add-btn"
      className={classNames(
        "h-100 bg-gray-7 cont-center is-link",
        classes.container,
        className
      )}
      {...props}
    >
      <AddCircleOutline className="fs-32 gray-4" {...iconProps} />
    </div>
  );
};

export default ButtonAddList;
