import { useCallback, useMemo, useState } from "react"

const useModal = (intialValue) => {
    const [state, setState] = useState(intialValue || false)

    const handleModal = useCallback((val) => setState(val), [])

    const result = useMemo(() => ({ modal: state, onClose: handleModal }), [handleModal, state])

    return result
}

export default useModal
