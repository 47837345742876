import React, { useState } from "react";
import ButtonContained from "../atom/Button/ButtonContained";
import InputText from "../molecule/MaterialInput/InputText";
import TempAuth from "../template/TempAuth";
import { useDispatch } from "react-redux";
import { gbl_act_lc } from "../../config/redux/actions/state";
import stateCallApi from "../../config/API/stateCallApi";
import DialogAlert from "../molecule/Alert/DialogAlert";
import { useHistory } from "react-router";
import { set_profile } from "../../config/redux/actions/profile";

const ProfileUpdateMotherName = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [motherName, setmotherName] = useState("");

  const onSubmit = () => {
    dispatch(
      gbl_act_lc({
        ...stateCallApi.updateMotherName,
        params: { mother_name: motherName },
        callback: () => dispatch(set_profile()),
      })
    );
  };

  return (
    <TempAuth
      page="regisInputPhone"
      data={{ title: "", ilustration: { subTitle: "PERBAHARUI NAMA IBU" } }}
    >
      <InputText
        label="NAMA IBU KANDUNG"
        labelClass="mb-2"
        value={motherName}
        onChange={setmotherName}
      />
      <ButtonContained
        text="PERBAHARUI"
        radius={4}
        className="mt-auto"
        disabled={!motherName}
        onClick={onSubmit}
      />
      <DialogAlert
        resultName="update_mother_name"
        alertAppear="both"
        submitIfSuccess={() => history.replace("/")}
      />
    </TempAuth>
  );
};

export default ProfileUpdateMotherName;
