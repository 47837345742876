import React from "react";
import useStyles from "./useStyles";
import TempMain from "../../template/TempMain";
import ilustration from "../../../assets/img/ilustration";
import BasicButton from "../../atom/Button/BasicButton";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";

const SuccessSwitchPortoPage = (props) => {
  const { goBack, replace, location } = useHistory();
  const classes = useStyles();

  const handleClick = useCallback(() => {
    if (location.state?.goal_id)
      replace(`/portofolio-goals?goals=${location.state?.goal_id}`);
    else goBack();
  }, [goBack, location.state, replace]);

  return (
    <TempMain title="BERHASIL" backgroundColor="#fff" className={classes.root}>
      <div className="pt-4 px-4">
        <div className="card-custom container-center-basic">
          <img src={ilustration.successMovePorto} alt="" className="mb-4" />
          <p
            style={{ width: 224 }}
            className="p text-center fs-14 font-weight-bold  mb-5 gray-3"
            color="primary"
          >
            Pemindahan Unit Reksa Dana kamu berhasil diproses!
          </p>
          <BasicButton
            text="LIHAT PORTOFOLIO KAMU"
            style={{ width: 237, height: "48px !important" }}
            className="fs-14 fw-600 px-4 py-2 no-shadow mt-5"
            onClick={handleClick}
          />
        </div>
      </div>
    </TempMain>
  );
};

SuccessSwitchPortoPage.defaultProps = {};

export default SuccessSwitchPortoPage;
