import React from "react";
import ContainerDesktop from "../../organism/Container/ContainerDesktop";

const TempGoalKamuDesktop = ({ data, children, childrenRight }) => {
  return (
    <ContainerDesktop
      left={<div className="mx-sm-4 my-4">{children}</div>}
      right={<div className="mx-sm-4 my-4">{childrenRight}</div>}
    />
  );
};

export default TempGoalKamuDesktop;
