import * as authLogic from "../logic/authLogic";
import * as stateLogic from "../logic/stateLogic";
import * as dashboardLogic from "../logic/dashboardLogic";
import * as productLogic from "../logic/productLogic";
import * as txnLogic from "../logic/txnLogic";
import * as profileLogic from "../logic/profileLogic";
import * as optionLogic from "../logic/optionLogic";
import * as portoLogic from "../logic/portoLogic";
import * as articleLogic from "./articleLogic";
import * as promoLogic from "./promoLogic";
import notifLogic from "./notifLogic";
export default [
  authLogic.loginLogic,
  authLogic.logoutLogic,
  authLogic.validatePin,
  authLogic.changePass,
  authLogic.requestOTP,
  authLogic.resendOTP,
  authLogic.validateOTP,
  authLogic.changePin,
  authLogic.forgotPin,
  authLogic.regisValidation,
  authLogic.regisRequestOTP,
  authLogic.regisValidateOTP,
  authLogic.fetchOCR,
  authLogic.fetchSID,
  authLogic.forgotPassword,
  authLogic.register1,
  authLogic.register2,
  authLogic.register3,
  authLogic.registerPIN,
  authLogic.reSendVerfication,
  authLogic.re_regis,
  authLogic.submit_re_regis,
  stateLogic.removeError,
  stateLogic.setResult,
  productLogic.products,
  productLogic.topProduct,
  productLogic.setTrending,
  productLogic.productType,
  productLogic.productHistory,
  productLogic.clearProductHistory,
  txnLogic.subsLogic,
  txnLogic.setPayment,
  txnLogic.getInvoice,
  txnLogic.setProof,
  txnLogic.getHistory,
  txnLogic.redeemLogic,
  txnLogic.switchLogic,
  txnLogic.checkBonus,
  profileLogic.getAccBank,
  profileLogic.setProfile,
  profileLogic.addAccBank,
  profileLogic.delAccBank,
  profileLogic.changeNumber,
  profileLogic.changeEmail,
  profileLogic.setPriorityBank,
  profileLogic.profileRisk,
  profileLogic.riskSurvey,
  profileLogic.riskResult,
  profileLogic.riskResultByPoint,
  profileLogic.update_profile,
  optionLogic.getOption,
  optionLogic.setMenu,
  portoLogic.portofolioList,
  portoLogic.portoSummary,
  portoLogic.portoDetail,
  portoLogic.estatementList,
  portoLogic.estatement,
  portoLogic.openStatement,
  optionLogic.faq,
  optionLogic.fetchAppConfig,
  optionLogic.getGoals,
  optionLogic.chartColor,
  optionLogic.headerSearch,
  optionLogic.dataLocation,
  optionLogic.setContent,
  optionLogic.rate_us,
  articleLogic.set_content_article,
  optionLogic.ins_benefit,
  optionLogic.ins_info,
  optionLogic.upload,
  optionLogic.fetchRestrictImage,
  ...notifLogic,
  dashboardLogic.userLogic,
  dashboardLogic.getBlogs,
  dashboardLogic.dash,
  stateLogic.global_action,
  stateLogic.global_action_list,
  stateLogic.encrypt_data,
  ...Object.values(promoLogic)
];
