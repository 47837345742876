import numeral from "numeral";
import momentid from "moment/locale/id";
import moment from "moment";
moment.updateLocale("id", momentid);

numeral.register("locale", "id", {
  delimiters: {
    thousands: ".",
    decimal: ",",
  },
  abbreviations: {
    thousand: "Rb",
    million: "Jt",
    billion: "M",
    trillion: "T",
  },
  ordinal: function (number) {
    return number === 1 ? "1" : "1";
  },
  currency: {
    symbol: "Rp",
  },
});

numeral.locale("id");

const textHelper = {
  printCapitalize: (text) => {
    let result = "";
    text = text.toLowerCase();
    text = text.split(" ");
    text.forEach((e, id) => {
      result += e[0].toUpperCase() + e.slice(1);
      if (id !== text.length - 1) {
        result += " ";
      }
    });
    return result;
  },
  compareArrays: (arr1, arr2) => {
    if (arr1 !== null && arr2 !== null) {
      if (JSON.stringify(arr1) === JSON.stringify(arr2)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  },
  printPercent: (value) => {
    let number = parseFloat(value) / 100;
    let check = number.toString().split(".");
    let data = "";
    if (check[1]) {
      if (value < 100) {
        data = numeral(number).format("0.00%");
      } else {
        data = numeral(number).format("0.0%");
      }
    } else {
      data = numeral(number).format("0,0%");
    }
    if (number >= 0) {
      data = "+" + data;
    }
    return data;
  },
  printPercentWithoutOperator: (value) => {
    let number = parseFloat(value) / 100;
    let check = number.toString().split(".");
    let data = "";
    if (check[1]) {
      if (value < 100) {
        data = numeral(number).format("0.00%");
      } else {
        data = numeral(number).format("0.0%");
      }
    } else {
      data = numeral(number).format("0,0%");
    }
    // if (number >= 0) {
    //   data = "+" + data;
    // }
    return data;
  },
  printMoney: (money) => {
    return numeral(money).format("$0,0[.]00");
  },
  printMoneyWithoutRp: (money) => {
    return numeral(money).format("0,0[.]00");
  },
  printMoneyAbbrivation: (money) => {
    return numeral(money).format("0a");
  },
  printMoneyInput: (money, prefix) => {
    let separator = "";
    if (money !== "") {
      money = numeral(money).format("0.[00]");
    }
    let number_string = money.replace(/[^,\d]/g, "").toString();
    let split = number_string.split(",");
    let sisa = split[0].length % 3;
    let rupiah = split[0].substr(0, sisa);
    let ribuan = split[0].substr(sisa).match(/\d{3}/gi);

    if (ribuan) {
      separator = sisa ? "." : "";
      rupiah += separator + ribuan.join(".");
    }

    rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah;
    return prefix === undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
  },
  printUnits: (unit) => {
    if (unit === 0) {
      return 0;
    } else {
      return numeral(unit).format("0.[0000]");
    }
  },
  hexToRGBA: (hex, alpha) => {
    if (hex.charAt(0) === "#") {
      hex = hex.substr(1);
    }
    if (hex.length < 2 || hex.length > 6) {
      return false;
    }
    var values = hex.split("");
    let r;
    let g;
    let b;
    if (hex.length === 2) {
      r = parseInt(values[0].toString() + values[1].toString(), 16);
      g = r;
      b = r;
    } else if (hex.length === 3) {
      r = parseInt(values[0].toString() + values[0].toString(), 16);
      g = parseInt(values[1].toString() + values[1].toString(), 16);
      b = parseInt(values[2].toString() + values[2].toString(), 16);
    } else if (hex.length === 6) {
      r = parseInt(values[0].toString() + values[1].toString(), 16);
      g = parseInt(values[2].toString() + values[3].toString(), 16);
      b = parseInt(values[4].toString() + values[5].toString(), 16);
    } else {
      return false;
    }
    return "rgba(" + r + "," + g + "," + b + "," + alpha + ")";
  },
  searchWithStart: (arr, search) => {
    let result = false;
    for (let i = 0; i < arr.length; i++) {
      if (search.search(arr[i]) !== -1) {
        result = true;
        break;
      } else {
        result = false;
      }
    }
    return result;
  },
  printDateTime: (date) => {
    return moment(date).format("DD MMMM YYYY HH:mm:ss");
  },
  printDateTimeShortMonth: (date) => {
    return moment(date).format("DD MMM YYYY HH:mm:ss");
  },
  printDate: (date, format = "DD MMMM YYYY") => {
    return moment(date).format(format);
  },

  printDateStartEnd: (start, end, format = "DD MMM YYYY") => {
    return `${moment(start).format(format)} - ${moment(end).format(format)}`;
  },

  printDateWithSlash: (date) => {
    return moment(date).format("L");
  },
  printDateWithStrip: (date) => {
    return moment(date).format("YYYY-MM-DD");
  },
  printDateFormat: (date, format = "YYYY") => {
    return moment(date).format(format);
  },
  printDateSubtractFormat: (
    date,
    subType = "days",
    subNom = 1,
    format = "YYYY"
  ) => {
    return moment(date).subtract(subNom, subType).format(format);
  },
  getDiffDate: (prev, next) => moment(prev).diff(moment(next), "months", true),
  compareArray: (arr1, arr2) => {
    if (JSON.stringify(arr1) === JSON.stringify(arr2)) {
      return true;
    } else {
      return false;
    }
  },
  printFullname: (data) => {
    const { first_name, middle_name, last_name } = data;
    return `${first_name} ${middle_name || ""} ${last_name || ""}`;
  },
  parseParams: (params) => {
    if (params) {
      params = params.replace("?", "");
      let arr1 = params.split("&");
      let arr2 = [];
      arr1.forEach((el) => {
        let tmp = el.split("=");
        arr2 = [...arr2, { [tmp[0]]: tmp[1] }];
      });
      return arr2;
    } else {
      return "";
    }
  },
  secondTomin: (num) => {
    let min = num / 60;
    min = "0" + Math.floor(min);
    let det = "0" + (num % 60);
    return min.slice(-2) + ":" + det.slice(-2);
  },
  secondToHour: (value) => {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let days = Math.floor(sec / 86400);
    let hours = Math.floor((sec - days * 86400) / 3600); // get hours
    let minutes = Math.floor((sec - hours * 3600 - days * 86400) / 60); // get minutes
    let seconds = sec - hours * 3600 - minutes * 60 - days * 86400; //  get seconds
    // let hours = Math.floor(sec / 3600); // get hours
    // let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
    // let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
    // add 0 if value < 10
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds;
  },
  userAccountPriority: (userAcc, type) => {
    let result = "";
    userAcc.forEach((el) => {
      if (el.is_priority === 1) {
        if (type === "number") result = el.bank.name + " " + el.account_number;
        else result = el.behalf;
      }
    });
    return result;
  },
  getDateMonthYear: (tgl) => {
    return moment(tgl).format("DD MMM YYYY");
  },
  getMonthYear: (tgl) => {
    return moment(tgl).format("MMMM YYYY");
  },
  getMMYYYY: (tgl) => {
    return moment(tgl).format("MM/YYYY");
  },
  getYear_Month: (tgl) => {
    return moment(tgl).format("YYYY-MM");
  },
  getDateGoals: (tgl) => {
    return moment(tgl).format("MM/DD/YYYY");
  },
  getFirstAndLastDayInMount: (tgl) => {
    return `Dari 01 sampai ${moment(tgl).endOf("month").format("DD MMM")} `;
  },
  isAfterDate: (tgl, compare) => moment(tgl).isAfter(compare),
  getTglNotif: (tgl) => {
    return moment(tgl).format("DD MMMM YYYY H:mm:ss");
  },
  printProduct2Line: (str = "") => {
    var strs = str.split(" ");
    var str_length = strs.length;
    let x = 0;

    let strFirst = ""; // Str baris pertama
    let strLast = ""; // Str baris kedua
    let xFirst = 0;
    let xLast = str_length - 1;
    if (str_length === 1) {
      return str;
    }
    for (x = 0; x < strs.length - 1; x++) {
      if (x === 0) {
        strFirst += " " + strs[xFirst];
        strLast = strs[xLast] + " " + strLast;
        xFirst++;
        xLast--;
      } else {
        if (strFirst.length < strLast.length) {
          // Kalau size str pertama < str barus kedua, tambahin str selanjutnya ke baris pertama
          strFirst += " " + strs[xFirst];
          xFirst++;
        } else {
          strLast = strs[xLast] + " " + strLast;
          xLast--;
        }
      }
    }
    return `<span>${strFirst}</span> <br/> <span>${strLast}</span>`.trim();
  },
  printRandomTime: () => {
    return moment().format("MMHssYYDD");
  },
  roundUp: (number) => {
    return Math.round(number / 100) * 100;
  },
  txnProgress: (type, data) => {
    let label = {
      S: ["Menunggu Pembayaran", "Dalam Proses", "Transaksi Berhasil"],
      R: [
        "Konfirmasi Penjualan diterima",
        "Dalam Proses",
        "Transaksi Berhasil",
      ],
      D: [
        "Konfirmasi Penukaran diterima",
        "Dalam Proses",
        "Transaksi Berhasil",
      ],
    };

    let subLabel = {
      S: [
        "Menunggu pembayaran ",
        "Transaksi kamu sedang dalam proses",
        "Transaksi kamu telah selesai",
      ],
      R: [
        "Konfirmasi penjualan unit ",
        "Penghitungan NAB/Unit terkonfirmasi",
        "Transaksi kamu telah selesai",
      ],
      D: [
        "Konfirmasi penukaran unit ",
        "Penghitungan NAB/Unit terkonfirmasi",
        "Transaksi kamu telah selesai",
      ],
    };
    if (data !== null) {
      const {
        txn_status_code,
        payment,
        declined_reason,
        txn_from,
        is_expired,
      } = data;

      if (txn_status_code === "DECLINED") {
        label[type][2] = "Transaksi Ditolak";
        subLabel[type][2] = declined_reason;
      }
      if (txn_from === "PRODUCT_PROMO") {
        if (txn_status_code === "WAITING") {
          if (is_expired) {
            label[type][0] = "Transaksi utama kedaluwarsa";
            subLabel[type][0] = "Transaksi utama kedaluwarsa";
          } else {
            label[type][0] = "Menunggu transaksi utama";
            subLabel[type][0] = "Menunggu transaksi utama diproses";
          }
        } else {
          label[type][0] = "Konfirmasi transaksi utama";
          subLabel[type][0] = "Konfirmasi transaksi utama berhasil";
        }
      }
      if (payment !== null) {
        if (payment.payment_status_code === "pending") {
          if (payment.is_expired) {
            label["S"][0] = "Transaksi Kedaluwarsa";
            subLabel["S"][0] =
              "Transksi kedaluwarsa pada " +
              textHelper.printDateTime(payment.expired_at);
          } else {
            if (payment.payment_type !== null)
              subLabel["S"][0] += "dengan metode " + payment.payment_type.type;
          }
        } else if (payment.payment_status_code === "pending2") {
          label["S"][0] = "Bukti Pembayaran Telah diunggah";
          subLabel["S"][0] = "Menunggu konfirmasi sistem";
        } else {
          label["S"][0] = "Pembayaran diterima";
          subLabel["S"][0] =
            "Konfirmasi pembayaran dengan metode " + payment.payment_type.type;
        }
      }
    }
    return { label: label[type], subLabel: subLabel[type] };
  },
};

export function generateId(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function codeDstOtp(code = "sms") {
  if (code === "wa") return "whatsapp";
  return String(code);
}

export const formatUrl = (url) => url.replace(/\s/g, "%20");

export default textHelper;
