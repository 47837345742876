import React from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import BasicText from "../BasicText/BasicText";
import classNames from "classnames";

const ButtonLink = ({
  text,
  loading,
  onClick,
  containClass,
  className,
  disabled,
  id
}) => {
  const isCantClick = loading || disabled;
  return (
    <Grid container alignItems="center" className={containClass}>
      <BasicText
        id={id}
        className={classNames("fs-12 gray-4 text-underline is-link", className)}
        onClick={isCantClick ? () => {} : onClick}
      >
        {text}
      </BasicText>
      {loading && (
        <CircularProgress size={14} color="secondary" className="ml-2" />
      )}
    </Grid>
  );
};

ButtonLink.defaultProps = {
  text: "Link",
  onClick: () => {},
  disabled: false,
};

export default ButtonLink;
