import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import stateCallApi from "../../config/API/stateCallApi";
import { get_acc_bank_props } from "../../config/redux/actions/profile";
import { gbl_act_lc, gbl_list_lc } from "../../config/redux/actions/state";
import {
  confirm_payment_data,
  get_history_props,
} from "../../config/redux/actions/transaction";
import DialogAlert from "../molecule/Alert/DialogAlert";
import TxnTabs from "../molecule/Txn/TxnTabs";
import HistoryList from "../organism/Txn/HistoryList";
import TempMain from "../template/TempMain";

class TxnPage extends Component {
  constructor(props) {
    super(props);
    this.once = false;
    this.state = {
      tab: 0,
      BOOKING: null,
      WAITING: null,
      PROCESSED: null,
      APPROVED: null,
      DECLINED: null,
    };
  }

  componentDidMount() {
    const tab = this.props.location.state?.tab ?? 0;
    if (tab) this.setState({ tab });
    this.fetchingData(tab);
    // this.props.get_history_props();
    // this.props.get_acc_bank_props();
  }

  componentDidUpdate(prevProps, prevState) {
    const { tab } = this.state;
    if (tab !== prevState.tab) this.fetchingData(tab);
  }

  fetchGrouping = (key) => {
    this.props.gbl_act_lc({
      key: `txn-group-${key}`,
      path: "TXN.grouping_list",
      params: { type: key },
      callback: (data) => this.setState({ [key]: data }),
      validate: !Array.isArray(this.state[key]),
    });
  };

  fetchingData = (tab) => {
    const orders = ["PROCESSED", "APPROVED", "DECLINED"];
    switch (tab) {
      case 0: // Booking Tab
        this.props.gbl_list_lc({
          ...stateCallApi.booking_list,
          callback: ({ data }) => this.setState({ BOOKING: data }),
          validate: !Array.isArray(this.state.BOOKING),
        });
        break;
      case 1: // Order Tab
        orders.forEach(this.fetchGrouping);
        break;
      case 2: // Pending Tab
        this.fetchGrouping("WAITING"); 
        break;
      default: // History Tab
        this.props.gbl_list_lc({
          ...stateCallApi.txn_list,
          validate: !this.once,
        });
        this.once = true;
        break;
    }
  };

  onChangeTab = (tab) => this.setState({ tab });

  handleClick = (e) => {
    console.log(e);
    if (e.link === "/confirm-payment") {
      let { bank_account } = this.props;
      bank_account = bank_account.filter((e) => e.is_priority === 1);
      // const arr = Object.keys(bank_account);
      e = {
        ...e,
        txn: {
          ...e.txn,
          account_id_from: bank_account[0].id,
          account_from: bank_account[0].account_number,
        },
      };
      this.props.confirm_payment_data(e.txn);
      // this.props.history.push(e.link);
    } else {
      this.props.history.push(e.link, e.txn);
    }
  };

  render() {
    console.log('dev txnPage', this.state)
    return (
      <TempMain title="TRANSAKSI" bottomNavValue={2} initialPage>
        <div className="pt-md-4 px-md-4">
          <TxnTabs onChange={this.onChangeTab} tab={this.state.tab} />
          <div className="pt-3 px-3 px-md-0 margin-main">
            <HistoryList
              {...this.state}
              // onClick={this.handleClick}
            />
          </div>
        </div>
        <DialogAlert resultName={`txn-group-PROCESSED`} alertAppear="error" />
      </TempMain>
    );
  }
}

const mapState = (state) => {
  return {
    bank_account: state.profileReducer.bank_account,
  };
};

export default compose(
  connect(mapState, {
    get_history_props,
    get_acc_bank_props,
    confirm_payment_data,
    gbl_list_lc,
    gbl_act_lc,
  }),
  withRouter
)(TxnPage);
