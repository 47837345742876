import React from "react";
import ContainerDesktop from "../../organism/Container/ContainerDesktop";

const TempPlannerLainnyaDesktop = ({ data, children, childrenRight }) => {
  return (
    <ContainerDesktop
      left={<div className="">{children}</div>}
      right={<div className="mx-sm-4 my-4">{childrenRight}</div>}
    />
  );
};

export default TempPlannerLainnyaDesktop;
