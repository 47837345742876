import IconButton from '@material-ui/core/IconButton';
import InfoIcon from "@material-ui/icons/Info";
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
    root: {
        fontSize: 12,
        color: theme.palette.secondary[5],
        display: 'flex',
        alignItems: 'center',
        "& svg": {
            color: theme.palette.secondary[5],
            fontSize: 14,
        },
        "& p": {
            marginBottom: 0
        }
    }
}))

const ButtonInfo = ({ onClick, title, classes,id }) => {
    const classesInside = useStyles()
    return (
        <div className={classNames(classesInside.root, classes.root)}>
            <p className={classes.title}>{title}</p>
            <IconButton id={id} onClick={onClick} className={classes.buttonIcon}>
                <InfoIcon className={classes.icon} />
            </IconButton>
        </div>
    )
}

ButtonInfo.defaultProps = {
    classes: {}
}

export default ButtonInfo
