import {
  Card,
  CircularProgress,
  Divider,
  LinearProgress,
} from "@material-ui/core";
import React from "react";
import BasicText from "../../../atom/BasicText/BasicText";
import iconTarget from "../../../../assets/icons/iconTarget.svg";
import textHelper from "../../../../helper/textHelper";

const CardProgressGoalsNR = ({ track }) => {
  const { target_amount, progress } = track;
  return (
    <Card className="p-3 shadow-card">
      {Object.keys(track).length ? (
        <>
          <div className="d-flex align-items-center mb-3 w-50">
            <BasicText className="mr-2 fs-12">Progres</BasicText>
            <BasicText className="mr-2 fs-10">{progress}%</BasicText>
            <LinearProgress
              className="w-100"
              variant="determinate"
              value={progress}
            />
          </div>
          <Divider className="my-3" />
          <div className="d-flex" style={{ height: "83%" }}>
            <img
              src={iconTarget}
              alt=""
              style={{ height: "26px" }}
              className="m-2"
            />
            <div className="ml-2">
              <BasicText className="mb-2 fs-12">Target Investasi</BasicText>
              <BasicText className="fs-14 gray-3">
                {textHelper.printMoney(target_amount)}
              </BasicText>
            </div>
          </div>
        </>
      ) : (
        <div className="d-flex justify-content-center">
          <CircularProgress />
        </div>
      )}
    </Card>
  );
};

export default CardProgressGoalsNR;
