import React from "react";
import debounce from "lodash/debounce";
import SliderWithInput from "../../molecule/MaterialInput/SliderWithInput";
import SelectsInput from "../../molecule/MaterialInput/SelectsInput";
import classNames from "classnames";
import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles((theme) => ({
  hideUnderLine: {
    "&.MuiInput-underline:before,.MuiInput-underline:after": {
      borderBottom: "0 !important",
      transition: "unset",
    },
    "&.MuiInput-underline.Mui-focused:after": {
      transform: "scaleX(0)",
    },
  },
  borderSelect: {
    "& fieldset": {
      border: 0,
    },
  },
}));
const SimulationInput = (props) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    firstInvest: "100000",
    monthlyInvest: "100000",
    timeInvest: "1",
  });
  const { firstInvest, monthlyInvest, timeInvest } = state;
  const debounceChange = React.useCallback(debounce(props.onChange, 300), []);
  const handleChange = (e, key) => {
    if (key === "timeInvestType") {
      if (parseInt(timeInvest) === parseInt(props.maxYear) && e === "year") {
        setState((state) => ({
          ...state,
          timeInvest: Math.floor(timeInvest / 12),
        }));
      }
    }
    setState((state) => ({ ...state, [key]: e }));
    debounceChange(e, key);
  };
  return (
    <>
      <SliderWithInput
        id="investasi-awal"
        adormentContent={() => <span className="fs-14 gray-4">Rp</span>}
        adormentPlace="start"
        maxValue={300000000}
        minValue={100000}
        text="Investasi Awal"
        stepSlider={1000}
        value={firstInvest}
        sliderLabelClass="fs-12"
        onChange={(e) => handleChange(e, "firstInvest")}
      />
      <SliderWithInput
        id="investasi-bulanan"
        adormentContent={() => <span className="fs-14 gray-4">Rp</span>}
        adormentPlace="start"
        maxValue={300000000}
        minValue={100000}
        text="Investasi Bulanan"
        stepSlider={1000}
        value={monthlyInvest}
        sliderLabelClass="fs-12"
        onChange={(e) => handleChange(e, "monthlyInvest")}
      />
      <SliderWithInput
        id="jangka-waktu"
        adormentContent={() => (
          <SelectsInput
            id="type-jangka-waktu"
            defaultValue={props.timeInvestType}
            name="timeInvestType"
            data={props.timeList}
            onChange={handleChange}
            inputClass={classNames(classes.hideUnderLine, classes.borderSelect)}
            value={props.timeInvestType}
          />
        )}
        adormentPlace="end"
        maxValue={props.maxYear}
        minValue={1}
        text="Jangka Waktu"
        stepSlider={1}
        value={timeInvest}
        sliderLabelClass="fs-12"
        onChange={(e) => handleChange(e, "timeInvest")}
      />
    </>
  );
};

export default SimulationInput;
