import {
  CircularProgress,
  Grid,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import chartHelper from "../../../helper/chartHelper";
import processHelper from "../../../helper/processHelper";
import BasicText from "../../atom/BasicText/BasicText";
import IconRobo from "../../atom/IconRobo/IconRobo";
import TitleSecondary from "../../atom/TitleSecondary";
import RecomColorDesc from "../Profile/RecomColorDesc";
import { ModalType } from "../Profile/TypeContent";
import color from "../../../assets/theme/color";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { useRouteMatch } from "react-router";

const useStyles = makeStyles((theme) => ({
  cardChart: {
    padding: "19px 15px 20px",
    backgroundColor: "white",
    borderRadius: 4,
  },
  chart: {
    height: 145,
    marginBottom: 17,
    marginTop: 14,
    display: "flex",
    justifyContent: "center",
  },
  errorIcon: {
    marginLeft: 4,
    color: color.gray_5,
    fontSize: 14,
    transform: "rotate(180deg)",
  },
  colorReksaDana: {
    height: 12,
    width: 12,
    marginRight: 5,
  },
  contentRiskName: {
    width: "100%",
  },
  boxRiskPoint: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 3,
    padding: "3px 12px",
    margin: "0px 5px",
  },
}));

const CardRisk = ({
  colors,
  idChart,
  data1,
  getData1,
  className,
  riskPoint,
}) => {
  const { path } = useRouteMatch();
  const modal = useRef();
  const isDesktop = useMediaQuery({ minWidth: 769 });
  const S = useStyles();
  const [point, setpoint] = useState(riskPoint);
  const [state, setstate] = useState({});
  const isCreateLainnya = path === "/create-planner/plan";
  const loading = useSelector((state) =>
    processHelper.findProcess(
      "profile_risk_base_point",
      state.stateReducer.loading_stack
    )
  );

  useEffect(() => {
    let arr = [];
    let dataRisk = data1.filter((el) => el.profile_risk_point === point);
    if (dataRisk.length) {
      setstate(dataRisk[0]);
      getData1(dataRisk[0]);
      dataRisk[0].profile_risk_product.forEach((el2) => {
        arr.push({ name: el2.product_code, y: Number(el2.allocation) });
      });
    }
    if (colors.length && arr.length)
      chartHelper.createPiChart(colors, arr, idChart);
  }, [colors, idChart, data1, point, getData1]);

  useEffect(() => {
    setpoint(riskPoint);
  }, [riskPoint]);

  function handleInfo(e) {
    modal.current.handleOpen(e);
  }
  const handlePoint = (type) => {
    setpoint((prevState) => (type === "min" ? prevState - 1 : prevState + 1));
  };
  return (
    <Grid
      container
      direction="column"
      className={classNames(
        S.cardChart,
        !isDesktop && "br-8",
        "shadow-card",
        className
      )}
    >
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <CircularProgress />
        </div>
      ) : (
        <>
          <Grid
            container
            alignItems="center"
            item
            className={S.contentRiskName}
          >
            <TitleSecondary>Profile Resiko Kamu</TitleSecondary>
            <div className="d-flex align-items-center ml-auto">
              <TitleSecondary className="font-weight-bold fs-13 color-primary text-center mx-2">
                {state?.profile_risk_type?.name}
              </TitleSecondary>
              <IconButton
                id="min-btn"
                disabled={point <= 1}
                onClick={() => handlePoint("min")}
                className="p-0"
                style={{ height: 24 }}
              >
                <RemoveIcon color={point > 1 ? "primary" : "secondary"} />
              </IconButton>
              <div className={S.boxRiskPoint}>
                <BasicText className="color-primary">{isNaN(point) ? "" : point}</BasicText>
              </div>
              <IconButton
                id="plus-btn"
                disabled={point >= 10}
                onClick={() => handlePoint("plus")}
                className="p-0"
                style={{ height: 24 }}
              >
                <AddIcon color={point < 10 ? "primary" : "secondary"} />
              </IconButton>
            </div>
          </Grid>
          {isCreateLainnya ? null : (
            <>
              <TitleSecondary className="mt-2">
                Rekomendasi ROBO:
              </TitleSecondary>
            </>
          )}
          <div style={{ position: "relative" }}>
            <div style={{ position: "absolute", top: "10px" }}>
              {isCreateLainnya ? null : <IconRobo />}
            </div>
            <div id={idChart} className={S.chart} />
          </div>
          <RecomColorDesc
            className="mb-0"
            data={state}
            color={colors}
            onClick={handleInfo}
            nameClass="color-secondary text-uppercase font-weight-medium"
          />
        </>
      )}
      <ModalType ref={modal} />
    </Grid>
  );
};

export default CardRisk;
