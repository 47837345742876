const pageToURL = {
  product_detail: "/detail-produk?product=",
  article: "/article?id=",
  goals_planner: "/goals-planner",
  smart_plan: "/smart-plan",
  profile_risk_result: "/profil-risiko",
  product_list: "/produk",
  coming_soon: "/coming-soon",
  survey_risk: "/survey-risiko",
  ultra_voucher: "/ultra-voucher",
  register: {
    requestOTP: "/register",
    validateOTP: "/register/validate",
    identity: "/register/identity",
  },
};

export default pageToURL;
