import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { chart_color_props } from "../../../../config/redux/actions/option";
import IconRobo from "../../../atom/IconRobo/IconRobo";
import InputTxn from "../../../organism/Order/InputTxn/InputTxn";

const useStylesComponent = makeStyles((theme) => ({
  rowItem: {
    paddingTop: 8,
    marginTop: 8,
    borderTop: `1px solid ${theme.palette.secondary[7]}`,
    "&.first": { borderTop: "none" },
    "& .row-bottom": {
      marginLeft: 24,
      marginTop: 1,
    },
    "& .block-color": {
      width: 16,
      height: 16,
      backgroundColor: "black",
      marginRight: 8,
    },
  },
  dividerDot: {
    borderTop: `2px dotted ${theme.palette.secondary[4]}`,
    margin: "14px 0",
  },
}));

const InputSellMulti = ({
  portofolio = [],
  fr_total_units,
  total_portofolio,
  fr_total_initial_amount,
  allocations_redeem = [],
  onChange,
  onIsAll,
  value,
  ...other
}) => {
  const classes = useStylesComponent();
  const dispatch = useDispatch();
  const chart_color = useSelector((state) => state.optionReducer.chart_color);
  useEffect(() => {
    dispatch(chart_color_props());
  }, [dispatch]);

  const summary = useMemo(
    () => [
      { title: "Total Unit", value: `${fr_total_units} Unit` },
      { title: "Investasi Awal", value: fr_total_initial_amount },
    ],
    [fr_total_initial_amount, fr_total_units]
  );

  const options = useMemo(() => {
    return {
      max: total_portofolio,
      min: allocations_redeem
        .map(({ min_red }) => min_red)
        .reduce((a, b) => a + b, 0),
    };
  }, [allocations_redeem, total_portofolio]);

  return (
    <div className="card-custom mb-3">
      <div style={{ width: "45%" }}>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <p className="basic fs-12" style={{ width: 235 }}>
            Penjualan akan ditentukan oleh ROBO agar alokasi tetap terjaga
          </p>
          <IconRobo className="ml-2" />
        </div>
        {portofolio.map(({ product_type, info, portofolio_sub }, index) => (
          <div
            key={index}
            className={classNames(classes.rowItem, !index && "first")}
          >
            <div className="d-flex align-items-center row-top mb-2">
              <div
                className="block-color"
                style={{ backgroundColor: chart_color[index] }}
              />
              <p className="basic text-uppercase">{product_type.name}</p>
              {portofolio_sub?.map(({ product }) => (
                <p className="basic font-weight-semibold ml-auto">
                  {
                    allocations_redeem.find(
                      (item) => item.code === product.display_code
                    )?.fr_allocation
                  }
                </p>
              ))}
            </div>
            {portofolio_sub?.map(({ id, product, fr_unit }) => (
              <div
                key={id}
                className="row-bottom d-flex align-items-center justify-content-between"
              >
                <p className="fs-10 gray-4 basic">{product.fr_product_name}</p>
                <p className="fs-10 basic">{fr_unit} Unit</p>
              </div>
            ))}
          </div>
        ))}
        <div className={classes.dividerDot} />
        {summary.map(({ title, value }) => (
          <div
            key={title}
            className="d-flex align-items-center justify-content-between mb-1"
          >
            <p className="basic fs-10 gray-4">{title}</p>
            <p className="basic fs-14">{value}</p>
          </div>
        ))}
        <InputTxn
          id="redeem"
          title="Nominal Unit Dijual"
          all={total_portofolio}
          onChange={onChange}
          onIsAll={onIsAll}
          value={value}
          recommend={[500000, 1000000, 2000000, 5000000]}
          className={{ root: "mt-3" }}
          {...options}
        />
      </div>
    </div>
  );
};

export default InputSellMulti;
